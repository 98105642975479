import * as React from "react";
import { FC, useState, useCallback } from "react";
import { chartVideoRegistrationStyle } from "../../../helperStyle";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  Typography,
  useMediaQuery,
  Theme,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import DialogueBox from "../../DialogueBox";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
}

const useStyles = makeStyles(chartVideoRegistrationStyle);

const ChartVideoRegistration: FC<Props> = (data) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data.data || {};
  const data2Obj: any = data.data2 || {};
  const loading = data.isLoading;

  const object2: any = {
    "Non Registered Employees": "deRegisteredEmployee",
    "Registered Employees": "registeredEmployee",
  };

  let dataBySlice = data2Obj[object2[activeSlice]];

  const objLabel: any = {
    deRegisteredEmployeeCnt: "Non Registered Employees",
    registeredEmployeeCnt: "Registered Employees",
  };
  const keys = Object.keys(dataObj);
  const values = Object.values(dataObj);
  const dataArr: any = [];
  keys.forEach((key, index) => {
    const newLabel = !!objLabel[key] ? objLabel[key] : key;
    dataArr.push({
      name: newLabel,
      value: values[index],
    });
  });
  const COLORS = ["#D63330", "#1E77B4", "#FFBB28", "#FF8042"];
  const headerArr = [
    { label: "Name", key: "name" },
    { label: "Code", key: "employeeCode" },
    { label: "Email", key: "email" },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = useCallback((data: any, index: any) => {
    setActiveIndex(index);
    setOpen(true);
    setActiveSlice(data.name);
    handleDefaultPage(0);
    handleRowsPerPage(10);
  }, []);
  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <div className={isXSmall ? classes.mobileCard : classes.card}>
      <Typography component="h6" className={classes.title}>
        {translate("pos.dashboard.chart_vid_reg")}
      </Typography>
      <DialogueBox
        dialogueData={dataBySlice}
        open={open}
        handleClose={handleClose}
        activeSlice={activeSlice}
        headerArr={headerArr}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={[]}
        isExport={false}
      />
      {loading ? (
        <span
          style={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <div style={isXSmall ? { marginLeft: "10%" } : { marginLeft: "10%" }}>
          {dataArr && dataArr.length > 0 ? (
            <ResponsiveContainer width={300} height={200}>
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={dataArr}
                  fill="#8884d8"
                  label
                  outerRadius={55}
                  onClick={handleClickOpen}
                >
                  {dataArr.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <h4
              style={{
                width: "87%",
                height: "200px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              No Entry Found
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default ChartVideoRegistration;
