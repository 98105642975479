import { Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

export const EmployeeAllotmentStyle: Styles<Theme, any> = {
  company: { display: "inline-block" },
  employee: { display: "inline-block", marginLeft: 32 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
};
export const CountryStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  divInline: { display: "inline" },
};
export const importStyle: Styles<Theme, any> = {
  title: {
    fontSize: 18,
    fontWeight: 700,
    paddingLeft: 15,
    paddingBottom: 0,
    color: "#000",
    textTransform: "capitalize",
  },
  label: {
    display: "inline-block",
    fontSize: 14,
    marginRight: 10,
    fontWeight: 500,
    color: "#000",
  },
  value: { display: "inline-block", fontWeight: 700 },
  myPopup: { display: "inline-block" },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  closeButton: { position: "absolute", top: 5, right: 0 },
  inlineButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
};
export const stateStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  smallName: { display: "inline-block" },
  smallCode: { display: "inline-block", marginLeft: 32 },
};
export const cityStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 16 },
};
export const employeeTypeStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
};
export const streamStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  ml32: { marginLeft: 32 },
  flexDiv: { display: "flex" },
  flexCol: { display: "flex", width: "100%" },
  inlineDiv: { display: "inline" },
  text: {
    display: "flex",
    fontSize: 25,
    backgroundColor: "#fff",
    padding: 10,
  },
  employee: { display: "inline-block", marginLeft: 32 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  tablePrint: {
    maxWidth: "100%",
    minWidth: "320px",
    width: "500px",
    margin: "auto",
  },
  isSmallTable: {
    minWidth: 300,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
    overflowX: "auto",
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  isBreakWidth: {
    minWidth: "18em",
  },

  headTitle: {
    padding: "0 16px",
  },
  pb0: {
    paddingBottom: 0,
  },
  dialogContentDiv: {
    // width: 100,
  },
  inlineButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    padding: 16,
  },
  mobileInlineButton: { justifyContent: "center" },
  iconCancelColor: {
    color: "#181818cf",
  },
  radioButton: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
  },
  email: { display: "inline-block", width: 544 },
  emailPublic: { display: "inline-block", width: 544 },
  semiWidth: { width: "545px" },

  root: { textAlign: "right" },

  captureButton: { height: "31px", marginLeft: "10px" },

  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
    width: "1000px",
  },
  wmMobile: { maxWidth: window.screen.width / 1.47, margin: "auto" },
  formWrapper: {
    maxWidth: "50%",
    margin: "auto",
    paddingBottom: 5,
  },
};
export const smFormHeaderStyle: Styles<Theme, any> = {
  text: {
    display: "flex",
    fontSize: 25,
    backgroundColor: "#fff",
    padding: 10,
  },
  textList: {
    height: 55,
    fontSize: 30,
    backgroundColor: "#fff",
    padding: 10,
  },
};

export const employeeStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  date: { background: "whitesmoke", marginTop: 8 },
  nameSmall: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  codeSmall: { width: 220, display: "inline-block" },
  workplace: { width: 220, display: "inline-flex", marginLeft: 32 },
  email: { display: "inline-block", width: 544 },
  phone: { width: 544 },
  selectFix: { maxWidth: 300, display: "inline-flex", flexWrap: "inherit" },
  select: {
    maxWidth: 300,
    display: "inline-flex",
    marginLeft: 32,
    flexWrap: "inherit",
  },
  mobileSelect: { maxWidth: 220, flexWrap: "inherit" },
  address: { display: "inline-block", width: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  inlineButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  mobileInlineButton: { justifyContent: "center" },
  imageClass: { width: "50%" },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
  inputConfirm: {
    display: "inline-block",
    marginLeft: "75px",
  },
};

export const leaveStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  date: { background: "whitesmoke", marginTop: 8 },
  referenceArray: {
    display: "inline-block",
    marginLeft: 32,
    minWidth: 250,
    width: 250,
  },
  autocompleteArray: {
    minWidth: 220,
  },
  email: { width: 544 },
  address: {
    display: "inline-block",
    marginLeft: 32,
    maxWidth: 544,
    minWidth: 544,
    width: 544,
  },
  reason: {
    display: "inline-block",
    marginLeft: 0,
    maxWidth: 544,
    minWidth: 544,
    width: 544,
  },
  description: {
    display: "inline-block",
    marginLeft: 0,
    maxWidth: 544,
    minWidth: 544,
    width: 724,
  },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  hidden: { visibility: "hidden" },
  text: { color: "#3f51b5", fontWeight: 800 },
  imgContainer: {
    // display: "inline-block",
    width: "200px",
    height: "200px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
  },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
};

export const clientStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  referenceArray: {
    display: "inline-block",
    marginLeft: 32,
    minWidth: 250,
    width: 250,
  },
  autocompleteArray: {
    minWidth: 220,
  },
  email: { width: 544 },
  mobileAddress: {
    display: "inline-block",
    marginLeft: 0,
    maxWidth: 220,
    minWidth: 220,
    width: 220,
  },
  address: {
    display: "inline-block",
    marginLeft: 0,
    maxWidth: 544,
    minWidth: 544,
    width: 544,
  },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const companyMasterStyle: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  referenceArray: {
    display: "inline-block",
    marginLeft: 32,
    minWidth: 250,
    width: 250,
  },
  autocompleteArray: {
    minWidth: 220,
  },
  email: { width: 544 },
  address: {
    display: "inline-block",
    maxWidth: 544,
    minWidth: 544,
    width: 544,
  },
  addressLarge: {
    display: "inline-block",
    maxWidth: 544,
    minWidth: 544,
    width: 544,
    marginLeft: 32,
  },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
};

export const leaveTypestyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  referenceArray: {
    display: "inline-block",
    marginLeft: 32,
    minWidth: 250,
    width: 250,
  },
  autocompleteArray: {
    minWidth: 220,
  },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
  name10: {
    width: "10px",
    display: "inline-block",
    minWidth: "10px",
    maxWidth: "10px",
  },
};

export const requestStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  referenceArray: {
    display: "inline-block",
    marginLeft: 32,
    minWidth: 250,
    width: 250,
  },
  autocompleteArray: {
    minWidth: 220,
  },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const visitorStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { display: "inline-block", width: 544 },
  phone: { width: 544 },
  address: { display: "inline-block", width: 544, marginLeft: 32 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const emailTempstyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 834 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const employeeDataRightsStyles: Styles<Theme, any> = {
  name: {
    display: "inline-block",
    maxWidth: "45%",
    paddingRight: 20,
    minWidth: 500,
  },
  code: {
    display: "inline-block",
    maxWidth: "45%",
    paddingLeft: 20,
    minWidth: 500,
  },
};

export const departmentstyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  text: {
    display: "flex",
    fontSize: 18,
    color: "#3f51b5",
    backgroundColor: "#fff",
    padding: 10,
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
  removeButton: {
    display: "none",
  },
};

export const designationstyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const shiftStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const workPlaceStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { display: "inline-block", width: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  inlineButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const attendanceStyles: Styles<Theme, any> = {
  name: { display: "inline-block", minWidth: "253px" },
  employeeDesgin: { minWidth: "235px" },
  code: { display: "inline-block", marginLeft: 32, minWidth: "253px" },
  mainCode: { display: "inline-block", marginLeft: 32, minWidth: "253px" },
  email: { width: 544 },
  phone: { width: 544 },
  address: { display: "inline-block", width: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const notificationStyles: Styles<Theme, any> = {
  name: { display: "inline-block", maxWidth: "500px" },
  code: { display: "inline-block", marginLeft: 32, minWidth: "253px" },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const rolesStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  hide: { display: "none" },
};

export const clinetstyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { display: "inline-block", width: 544, marginLeft: 32 },
  phone: { width: 544 },
  address: { display: "inline-block", width: 544, marginLeft: 32 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  tab: { minWidth: "100%" },
  customSize: { width: 832 },
  edgeUrl: { display: "inline-block", width: 544 },
  dropZone: {
    border: "1px dashed #171e52a6",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "#28359300",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0px",
  },
  pTag: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "red",
  },
};

export const devicesStyles: Styles<Theme, any> = {
  textInput: { display: "inline-block", minWidth: "253px" },
  textInputMargin: { display: "inline-block", marginLeft: 32 },
  formdataInput: { display: "inline-block" },
  edgeUrl: { display: "inline-block", width: 544 },
  cameraUrl: { display: "inline-block", width: 544, marginLeft: 32 },
  flexDiv: {
    display: "flex",
    // width: "100%",
  },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  editAllotmentBtn: {
    fontSize: 12,
    border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
  },

  disFlexjusEnd: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "end",
  },
  allotmentBtn: { display: "inline-block" },
};

export const profileStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

export const settingsStyles: Styles<Theme, any> = {
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { display: "inline-block", width: 544, marginLeft: 32 },
  phone: { width: 544 },
  customSize: { width: 832 },
  address: { display: "inline-block", width: 544, marginLeft: 32 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  tab: { minWidth: "100%" },
  emailButton: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    marginRight: "76px",
  },
};

export const attendanceSummaryStyle: Styles<Theme, any> = {
  tableCell: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
  },
  tableCellEmp: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "125px",
  },

  noRecordFound: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
    width: "100px",
  },
  filter: {
    width: "89%",
  },
  table: {
    borderCollapse: "inherit",
  },
  root: { textAlign: "right" },
  childBlock: {
    display: "flex",

    // height: "calc(100vh - 100px)",
    // margin: 10
  },
  code: { display: "inline-block", marginLeft: 32 },

  Blockreport: {
    maxWidth: "100%",
    display: "none",
    overflow: "hidden",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  rtable: {
    maxWidth: "100%",
    display: "block",
    overflowX: "auto",
  },

  flexCol: {
    display: "flex",
    width: "100%",
  },
  ButtonflexCol: {
    margin: 10,
    display: "flex",
    width: "100vh",
  },
  mobileFlexCol: {
    margin: 10,
    width: "100vh",
  },
  flexColTop: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
  },
  mleft32: {
    marginLeft: 32,
  },
  savemleft32: {
    marginLeft: 500,
  },
  viewBtn: {
    fontSize: 14,
    height: 35,
    marginTop: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    marginBottom: 15,
    alignItems: "center",
    margin: "auto",
    width: "10%",
    position: "sticky",
  },
  downloadBtn: {
    fontSize: 14,
    height: 35,
    marginTop: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    marginBottom: 15,
    width: "15%",
    alignItems: "center",
    margin: "auto",
  },
  container: {
    height: "calc(100vh - 190px)",
    maxWidth: "calc(100vw - 268px)",
  },
  menuCloseContainer: {
    maxHeight: 550,
    maxWidth: "calc(114vw - 268px)",
  },
  smallContainer: {
    maxHeight: 550,
    maxWidth: "100%",
  },
  header: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    // position: "sticky",
    zIndex: 10,
    top: 0,
  },
  headerSticky: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    position: "sticky",
    zIndex: 10,
    top: 0,
  },
  header100: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    position: "sticky",
    zIndex: 10,
    top: 0,
    width: "100px",
    minWidth: "100px",
    maxWidth: "100px",
  },
  tableHead: {
    border: "solid 1px lightgrey",
    textAlign: "center",
    position: "sticky",
    // zIndex: 9999,
    // top: "3.5rem",
    // minWidth: "100px",
  },
  margin: {
    display: "flex",
    marginRight: "20px",
    marginTop: "7px",
  },
  semiWidth: { width: "545px" },
  w50: { width: "50%" },
  textColor: { color: "#283593" },
  tableButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // margin: "-35px 0px 20px 0px",
  },
};
export const attendanceExtraDuty: Styles<Theme, any> = {
  tableCell: {
    border: "solid 1px lightgrey",
    borderRight: "solid 1px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
  },
  tableHeadCell: {
    border: "solid 1px lightgrey",
    textAlign: "center",
    fontSize: 12,
    padding: 10,
  },
  noRecordFound: {
    border: "solid 1px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
  },
  table: {
    boarderCollapse: "collapse",
  },

  table_cell: {
    display: "tableCell",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  table_first: {
    borderCollapse: "collapse",
    fontSize: "14px",
    flex: "0 0 42%",
  },
  table_Lowerfirst: {
    borderCollapse: "collapse",
    fontSize: "14px",
    flex: "0 0 42%",
  },
  table_second: {
    // marginLeft: 'auto',
    borderCollapse: "collapse",
    fontSize: "14px",
    flex: "0 0 100%",
  },
  table_first_cell: {
    padding: "4px",
    border: "1px solid #333",
    wordBreak: "break-word",
  },
  tablerow: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: 'spaceBetween',
  },
  col6: {
    flex: "0 0 50%",
    maxWidth: "50%",
  },
  reportBlock: {
    maxWidth: "100%",
    display: "flex",
    overflow: "hidden",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "20px",
  },
  childBlock: {
    // display: 'flex',
    // margin: 5
  },
  rtable: {
    maxWidth: "100%",
    display: "block",
    overflowX: "auto",
  },
  flexCol: {
    display: "flex",
    width: "100vh",
  },

  flexColTop: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  activeColTop: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
  },
  flexColTop50Left: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-start",
  },
  flexColTop54Left: {
    display: "flex",
    width: "59%",
    justifyContent: "flex-start",
  },
  flexColTop50Right: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-end",
  },
  mleft32: {
    marginLeft: 32,
  },
  mright32: {
    marginRight: 32,
  },
  buttonClass: {
    marginLeft: 32,
    marginTop: 12,
  },
  container: {
    maxHeight: 550,
    maxWidth: window.screen.width / 1.22,
    width: window.screen.width / 1.22,
    // maxWidth: "calc(100vw - 268px)",
  },
  finalContainer: {
    // maxHeight: 550,
    maxWidth: window.screen.width / 1.22,
    width: window.screen.width / 1.22,
    // maxWidth: "calc(100vw - 268px)",
  },
  publicReportcontainer: {
    maxHeight: 550,
    maxWidth: window.screen.width - 15,
    width: window.screen.width - 15,
    // maxWidth: "calc(100vw - 268px)",
  },

  closeSideBarcontainer: {
    maxHeight: 550,
    maxWidth: window.screen.width - 55,
    width: window.screen.width - 55,
    // maxWidth: "calc(100vw - 268px)",
  },
  header: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    position: "sticky",
    // zIndex: 10,
    top: 0,
  },
  viewBtn: {
    fontSize: 14,
    height: 35,
    marginTop: 15,
    marginBottom: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    alignItems: "center",
  },
  downloadBtn: {
    marginLeft: 32,
    fontSize: 14,
    height: 35,
    marginTop: 15,
    marginBottom: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    alignItems: "center",
  },
  tableHead: {
    border: "solid 1px lightgrey",
    textAlign: "center",
    position: "sticky",
    top: "2.5rem",
    minWidth: "100px",
  },
  margin: {
    marginRight: "20px",
    marginBottom: 15,
  },
  actionButton: {
    width: "100%",
    border: "1px solid lightgrey",
    color: "#000",
    fontWeight: "bold",
    marginRight: 10,
    fontSize: 10,
  },
  smallActionButton: {
    display: "flex",
    width: "25vh",
    border: "1px solid lightgrey",
    color: "#000",
    fontWeight: "bold",
    marginRight: 5,
    fontSize: 7,
  },

  actionButton2: {
    width: "100%",
    border: "1px solid lightgrey",
    color: "#000",
    fontWeight: "bold",
    marginLeft: 32,
    fontSize: 10,
  },
  downloadButton: {
    width: "100%",
    border: "1px solid lightgrey",
    color: "#000",
    fontWeight: "bold",
    marginLeft: 32,
    fontSize: 10,
  },
  button: {
    border: "1px solid lightgrey",
    color: "#000",
    marginRight: 10,
    fontSize: 14,
    textTransform: "capitalize",
  },
  publicButton: {
    backgroundColor: "#808080c7",
    color: "white",
    pointerEvents: "none",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
};

const drawerWidth = 350;
export const workPlaceStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    width: "100%",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  searchInputClass: {
    width: "100%",
  },
  mobImportIcon: {
    position: "absolute",
    right: 120,
  },
  importIcon: {
    position: "absolute",
    right: 375,
  },
  flexrow: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0,
  },
  editAllotmentBtn: {
    fontSize: 22,
    // border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
    cursor: "pointer",
  },
  rejectAllotmentBtn: {
    fontSize: 22,
    // border: "1px solid #3f51b5",
    color: "red",
    fontWeight: 500,
    marginRight: "8px",
    cursor: "pointer",
  },
  disableApproveBtn: {
    fontSize: 22,
    // border: "1px solid #3f51b5",
    color: "#dddddd",
    fontWeight: 500,
    marginRight: "8px",
    cursor: "pointer",
  },
  removeAllotmentBtn: {
    fontSize: 23,
    // border: "1px solid red",
    color: "red",
    fontWeight: 500,
    marginRight: "8px",
    cursor: "pointer",
  },
  qrButton: {
    top: "47px",
    color: "#00008b",
    right: "35px",
    width: "25px",
    height: "25px",
    position: "absolute",
    maxWidth: "40px",
    minWidth: "initial",
    marginTop: "-176px",
    borderRadius: "30%",
    cursor: "pointer",
  },
});

export const maleFemaleCardStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    flex: "1",
    marginRight: "1em",
    // marginTop: 20,
  },
  mobileRoot: {
    // flex: "1",
    marginRight: "1em",
    // marginTop: 20,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  card: {
    flex: 1,
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    width: "33%",
    height: "300px",
    minHeight: 52,
  },

  cardImg: {
    flex: 1,
    // float: "left",
    overflow: "inherit",
    textAlign: "center",
    marginTop: "10%",
    width: "100%",
    height: "100%",
    // minHeight: 52,
  },
  mobileCardImg: {
    flex: 1,

    // float: "left",
    overflow: "inherit",
    textAlign: "center",
    // marginTop: '10%',
    width: window.screen.width - 28,
    height: "100%",
    // minHeight: 52,
  },
  title: {
    width: "100%",
    // display: 'flex',
    padding: "20px",
    fontSize: "1.5rem",
  },
  smallRoot: {
    width: theme.spacing(50),
  },
});

export const chartVideoRegistrationStyle: Styles<Theme, any> = (
  theme: any
) => ({
  main: {
    flex: "1",
    width: "50%",
    // marginRight: "1em",
    marginTop: 20,
  },
  textMerge: {
    width: "150px",
    height: "auto",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  card: {
    // display: "flex",
    width: "33%",
    height: "300px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  smallRoot: {
    width: theme.spacing(50),
    height: "300px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  mobileCard: {
    // display: "flex",
    width: window.screen.width - 28,
    height: "300px",
    marginRight: "1em",
    // marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  title: {
    // width: "100%",
    display: "flex",
    flex: 1,
    padding: "20px",
    fontSize: "1.5rem",
    // height: "0px",
  },
  closeButton: { position: "absolute", top: 5, right: 0 },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  myPopup: { display: "inline-block" },
  loadingDiv: {
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const leavesCardStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    // flex: 1,
    width: "97%",
    padding: 16,
    marginRight: "1em",
    height: "205px",
    marginTop: "1em",
  },
  closeRoot: {
    width: "97%",
    padding: 16,
    marginRight: "1em",
    height: "205px",
    marginTop: "1em",
  },
  smallRoot: {
    // flex: 1,
    width: theme.spacing(50),
    padding: 16,
    marginRight: "1em",
    height: "15em",
  },
  mobileRoot: {
    // flex: 1,
    width: window.screen.width - 28,
    padding: 16,
    marginRight: "1em",
    height: "15em",
  },
  card: {
    flex: 1,
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    width: "100%",
    marginRight: "1em",

    // minHeight: 52,
  },
  cardImg: {
    flex: 1,
    float: "left",
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    paddingTop: 60,
    height: "100%",
    width: "40%",
    // minHeight: 52,
  },
  title: {
    width: "100%",
    display: "flex",
    paddingBottom: 0,
    // padding: "15px",
    fontSize: "1.5rem",
  },
});

export const todayTotalLateEntriesStyle: Styles<Theme, any> = (theme: any) => ({
  card: {
    // display: "flex",
    width: "33%",
    height: "300px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  smallCard: {
    width: theme.spacing(50),
    height: "300px",
    marginRight: "1em",
    // marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  mobileCard: {
    // display: "flex",
    width: window.screen.width - 28,
    height: "300px",
    marginRight: "1em",
    // marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  title: {
    // width: "100%",
    display: "flex",
    flex: 1,
    padding: "20px",
    fontSize: "1.5rem",
    // height: "0px",
  },
  pieDivSmall: {
    marginLeft: "10vw",
  },
  pieDivMobile: {
    marginLeft: "10vw",
  },
  pieDiv: {
    marginLeft: "10%",
  },
});

export const leaveTypeDonatChartStyle: Styles<Theme, any> = (theme: any) => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    // display: "flex",
    width: "97%",
    height: "246px",
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    display: "inline-grid",
  },
  mobileCard: {
    // display: "flex",
    width: window.screen.width - 28,
    height: "15em",
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  smallCard: {
    // display: "flex",
    width: theme.spacing(50),
    height: "15em",
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  title: {
    display: "flex",
    paddingTop: "20px",
    paddingLeft: "20px",
    fontSize: "1.5rem",
    height: 0,
  },
  smallContainer: {
    width: "auto",
    overflow: "auto",
    overflowY: "hidden",
    maxHeight: 180,
    marginLeft: 35,
  },
  container: {
    width: "91%",
    overflow: "auto",
    overflowY: "hidden",
    maxHeight: 180,
    marginLeft: 35,
    marginRight: 35,
  },
});

export const listUpcomingEventsStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    // flex: 1,
    width: "33%",
    padding: 16,
    height: "465px",
    marginRight: "1em",
    marginLeft: "-0.3rem",
  },
  mobileRoot: {
    // flex: 1,
    width: window.screen.width - 28,
    padding: 16,
    marginRight: "1em",
  },
  smallRoot: {
    // flex: 1,
    width: theme.spacing(50),
    padding: 16,
    marginRight: "1em",
  },
  cost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
  },
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  title: {
    display: "flex",
    width: "100%",
    // padding: "20px",
    fontSize: "1.5rem",
  },
  list: {
    maxHeight: 360,
    overflow: "auto",
  },
});

export const employeeBehaviourStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    flex: 1,
    // display: 'flex',
    width: "100%",
    // padding: 16,
    marginRight: "1em",
    justifyContent: "center",
    alignItems: "center",
    height: "30em",
  },
  mobileRoot: {
    // flex: 1,
    width: window.screen.width - 28,
    // padding: 16,
    marginRight: "1em",
    overflowX: "scroll",
    height: "34em",
  },
  smallRoot: {
    // flex: 1,
    width: theme.spacing(50),
    // padding: 16,
    marginRight: "1em",
    overflowX: "scroll",
    height: "34em",
  },
  title: {
    display: "flex",
    width: "100%",
    // padding: "20px",
    fontSize: "1.5rem",
  },
  smallDateDiv: { marginTop: "63px" },
  dateDiv: {
    marginTop: "9px",
  },
  selectDatePos: {
    marginLeft: "100px",
  },
  selectDatePosSmall: {
    marginLeft: "0px",
  },
});

export const requestCardStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    flex: 1,
    // width: "30%",
    padding: 5,
    marginRight: "1em",
  },
  mobileRoot: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  mobileRootAttedance: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  card: {
    flex: 1,
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    // width: "40%",
    minHeight: 52,
  },
  cardImg: {
    flex: 1,
    float: "left",
    overflow: "inherit",
    textAlign: "right",
    padding: 20,
    // width: "40%",
    minHeight: 52,
    marginTop: 2,
  },
  cardImgReq: {
    flex: 1,
    float: "left",
    overflow: "inherit",
    textAlign: "right",
    padding: 20,
    width: "40%",
    minHeight: 52,
    marginTop: 2,
  },
  cardImgMed: {
    flex: 1,
    float: "left",
    overflow: "inherit",
    textAlign: "right",
    padding: 20,
    width: "25%",
    minHeight: 52,
    marginTop: 2,
  },
  cardimgSmall: {
    flex: 1,
    float: "left",
    overflow: "inherit",
    textAlign: "right",
    padding: 20,
    width: "30%",
    minHeight: 52,
    marginTop: 2,
  },
  title: {
    // width: "100%",
    display: "flex",
    flex: 1,
    padding: "20px",
    fontSize: "1.5rem",
    // height: "0px",
  },
});

export const assignTimeslotShowStyle: Styles<Theme, any> = {
  rootDiv: {
    width: "100%",
    overflow: "auto",
    marginTop: "15px",
  },
  smRootDiv: {
    width: "100%",
    overflow: "auto",
    marginTop: 0,
  },
  tableBody: {
    justifyContent: "center",
  },
  flexDiv: { display: "flex" },
  inlineDiv: { display: "inline" },
  tableCell: {
    width: "100px !important",
    border: "solid 1px lightgrey",
    padding: 0,
    position: "sticky",
    fontSize: 14,
    minWidth: "100px !important",
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    fontWeight: 580,
    height: 82,
  },
  tablecellTextColor: {
    fontSize: 13,
    // fontFamily: "Roboto", "Helvetica", "Arial", sans-serif,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    color: "white",
  },
  breakTextColor: {
    border: 0,
    cursor: "pointer",
    margin: 0,
    outline: 0,
    position: "relative",
    userSelect: "none",
    borderRadius: 0,
    "-moz-appearance": "none",
    textDecoration: "none",
    backgroundColor: "#e7505a",
    "-webkit-appearance": "none",
    "-webkit-tap-highlight-color": "#ffffff",
    width: "100%",
    height: "100%",
    color: "white",
    fontWeight: 600,
  },
  loadDivColor: {
    color: "aliceblue",
  },
  noRecordFound: {
    textAlign: "center",
    border: "1px solid",
    marginTop: "2em",
    width: "98.5%",
  },
  smNoRecordFound: {
    textAlign: "center",
    border: "1px solid",
    marginTop: "3em",
    width: "53%",
  },
  table: {
    borderCollapse: "inherit",
    border: "1px solid lightgrey",
    marginTop: "2em",
  },
  mtTable: {
    borderCollapse: "inherit",
    border: "1px solid lightgrey",
  },
  code: { display: "inline-block", marginLeft: 32 },
  name: { display: "inline-block" },
  rtable: {
    maxWidth: "100%",
    display: "block",
    overflowX: "auto",
  },
  flexCol: {
    display: "flex",
    width: "100%",
  },
  mobileFlexCol: {
    margin: 10,
    width: "100vh",
  },
  mleft32: {
    marginLeft: 32,
  },
  container: {
    maxHeight: 550,
    maxWidth: "calc(100vw - 268px)",
  },
  header: {
    top: 0,
    flex: 1,
    width: "110px !important",
    border: "solid 1px lightgrey",
    zIndex: 999,
    position: "sticky",
    minWidth: "110px !important",
    background: "#f0f0f0 !important",
    textAlign: "center",
    fontWeight: 600,
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
  },

  textColor: {
    border: 0,
    cursor: "pointer",
    margin: 0,
    outline: 0,
    position: "relative",
    userSelect: "none",
    borderRadius: 0,
    "-moz-appearance": "none",
    textDecoration: "none",
    backgroundColor: "#3f51b5",
    "-webkit-appearance": "none",
    "-webkit-tap-highlight-color": "#ffffff",
    width: "100%",
    color: "white",
    fontWeight: 600,
  },
  csvSpan: { paddingLeft: "0.5em", display: "inline-block !important" },

  smCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
  },
  lgCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.850rem !important",
    alignContent: "right !important",
    // width: window.screen.width / 1.22,
    // minWidth: "126px",
  },
  flexrow: {
    display: "flex !important",
    alignItems: "center",
    // justifyContent: "flex-start",
    padding: 0,
    maxWidth: "70em",
    justifyContent: "flex-end",
    position: "absolute",
    right: 10,
  },
  smFlexrow: {
    display: "flex !important",
    alignItems: "center",
    // justifyContent: "flex-start",
    padding: 0,
    maxWidth: "99%",
    justifyContent: "flex-end",
  },
  exSmFlexrow: {
    display: "flex !important",
    alignItems: "center",
    // justifyContent: "flex-start",
    padding: 0,
    maxWidth: "53%",
    justifyContent: "flex-end",
  },
  iconCancel: {
    position: "absolute",
    right: 0,
    display: "none",
    top: 0,
  },

  timeText: {
    width: "100%",
    border: 0,
    cursor: "pointer",
    margin: 0,
    outline: 0,
    position: "relative",
    fontWeight: 600,
    userSelect: "none",
    borderRadius: 0,
    "-moz-appearance": "none",
    textDecoration: "none",
    backgroundColor: "#ffffff",
    "-webkit-appearance": "none",
    "-webkit-tap-highlight-color": "#ffffff",
    color: "#474747",
  },
  noteTextColor: { color: "red", marginTop: "18px" },
};

export const timeslotStyle: Styles<Theme, any> = {
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block", marginLeft: 32 },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  employee2: { display: "inline-block" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentAfterBreakBtn: { marginLeft: 32 },
  allotmentBtn: { display: "inline-block" },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  breakWidth: {
    minWidth: 100,
  },
  textInput: { display: "inline-block", minWidth: "253px" },
  headTitle: {
    padding: "0 16px",
  },
  pb0: {
    paddingBottom: 0,
  },
};

export const collegeReportStyle: Styles<Theme, any> = {
  rootDiv: {
    width: "100%",
    overflow: "auto",
    marginTop: "15px",
  },
  smRootDiv: {
    width: "100%",
    overflow: "auto",
    marginTop: 0,
  },
  flexDiv: { display: "flex" },
  tableBody: {
    justifyContent: "center",
  },
  smTableDiv: { width: "200vh", overflow: "scroll" },
  smtableContainer: {
    overflow: "auto",
    width: "28%",
  },
  tableCell: {
    width: "100px !important",
    border: "solid 1px lightgrey",
    padding: 0,
    position: "sticky",
    fontSize: 14,
    minWidth: "100px !important",
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    fontWeight: 580,
    height: 82,
  },
  serialTableCell: {
    width: "20px !important",
    border: "solid 1px lightgrey",
    padding: 0,
    position: "sticky",
    fontSize: 14,
    minWidth: "20px !important",
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    fontWeight: 600,
    height: 82,
  },

  blueTableCell: {
    width: "20px !important",
    border: "solid 1px lightgrey",
    padding: 0,
    position: "sticky",
    fontSize: 14,
    minWidth: "20px !important",
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    fontWeight: 600,
    height: 82,
    background: "#3f51b5",
  },
  greyTableCell: {
    width: "20px !important",
    border: "solid 1px lightgrey",
    padding: 0,
    position: "sticky",
    fontSize: 14,
    minWidth: "20px !important",
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    fontWeight: 600,
    height: 82,
    background: "#E6E6E6",
    cursor: "pointer",
  },

  noRecordFound: {
    textAlign: "center",
    border: "1px solid",
    marginTop: "2em",
    width: "98.5%",
  },

  table: {
    borderCollapse: "inherit",
    border: "1px solid lightgrey",
    // marginTop: "2em",
  },
  code: { display: "inline-block", marginLeft: 32 },
  name: { display: "inline-block" },
  rtable: {
    maxWidth: "100%",
    display: "block",
    overflowX: "auto",
  },
  flexCol: {
    display: "flex",
    width: "100%",
  },
  mobileFlexCol: {
    margin: 10,
    width: "100vh",
  },
  container: {
    maxHeight: 550,
    maxWidth: "calc(100vw - 268px)",
    marginTop: "2em",
  },
  dateContainer: {
    maxHeight: 550,
    maxWidth: "calc(100vw - 268px)",
    marginTop: "1em",
  },
  header: {
    top: 0,
    flex: 1,
    width: "110px !important",
    border: "solid 1px lightgrey",
    zIndex: 999,
    position: "sticky",
    minWidth: "110px !important",
    background: "#f0f0f0 !important",
    textAlign: "center",
    fontWeight: 600,
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
  },
  dataHeader: {
    top: 0,
    flex: 1,
    width: "110px !important",
    border: "solid 1px lightgrey",
    zIndex: 10,
    position: "sticky",
    minWidth: "110px !important",
    background: "#f0f0f0 !important",
    textAlign: "center",
    fontWeight: 600,
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
  },
  dateHeader: {
    top: 0,
    flex: 1,
    width: "110px !important",
    border: "solid 1px lightgrey",
    position: "sticky",
    minWidth: "110px !important",
    background: "#f0f0f0 !important",
    textAlign: "center",
    fontWeight: 600,
    borderRight: "solid 0px lightgrey",
    borderTop: 0,
    zIndex: 10,
  },
  tablecellTextColor: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    color: "white",
  },
  whiteTableCell: {
    color: "white",
  },

  tableHead: {
    border: "solid 1px lightgrey",
    textAlign: "center",
    position: "sticky",
    top: "2.5rem",
    minWidth: "100px",
    background: "#f0f0f0 !important",
    zIndex: 100,
  },
  noteTextColor: { color: "red", marginTop: "18px" },
  left0: {
    left: 0,
  },
  left110: {
    left: 110,
  },
};

export const mobileGridStyle: Styles<Theme, any> = (theme: any) => ({
  root: { margin: "1em" },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  cardTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContent: {
    ...theme.typography.body1,
    display: "flex",
    flexDirection: "column",
  },
});
export const filterStyle: Styles<Theme, any> = (theme: any) => ({
  root: {
    width: "100%",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  divForm: {
    marginLeft: 15,
  },
});
export const listActionsStyle: Styles<Theme, any> = (theme: any) => ({
  filterButton: {
    padding: "3px 0px 0px 0px",
    fontSize: "13px",
  },
  filterIcon: {
    marginRight: "0.2em",
  },
  exportButton: {
    padding: "0 !important",
    fontSize: "0.8125rem",
    marginTop: "0.3em",
  },
  exportIcon: {
    fontSize: 20,
  },
  descriptionIcon: {
    transform: "rotate(180deg)",
    fontSize: 20,
  },
  csvSpan: { paddingLeft: "0.5em" },
  smCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
  },
  lgCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  flexrow: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0,
  },
  plusIcon: {
    fontWeight: 700,
    fontSize: "18px",
    color: "#3f51b5",
    marginLeft: "22px",
  },
});
