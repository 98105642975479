import HolidaysList from "./HolidaysList";
import HolidayEdit from "./HolidayEdit";
import HolidayCreate from "./HolidayCreate";
import { HolidayIcon } from "../svgIcon";

export default {
  list: HolidaysList,
  create: HolidayCreate,
  edit: HolidayEdit,
  icon: HolidayIcon,
};
