import React from "react";
import PropTypes from "prop-types";
import Footer from "./components/Footer";
import Header from "./components/Header";

import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "./themes";

interface Props {}

const TurnoutEdge = (props: Props) => {
  var container: any = null;
  var advantageboxRef1 = React.useRef(null);

  const handleMouseEnter = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.add("active");
    container = null;
  };

  const handleMouseOut = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.remove("active");
    container = null;
  };
  return (
    <React.Fragment>
      <div className="inner-page dropdown-page">
        {/* <div id="preloader">
        <div classNameName="preload-content">
          <div id="dream-load"></div>
        </div>
      </div> */}
        <Header />
        <section className="breadcrumb-area">
          <div className="breadcrumb-shape"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inn">
                  <div
                    className="section-title wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <h2>
                      <span>TURNOUT E (EDGE)</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-left">
                  <h2>
                    <span className="underline">Attendance Management</span>{" "}
                    <span></span>
                  </h2>
                  <h3 className="mb-3">
                    <span className="underline">AI Based Solution</span>
                  </h3>
                  <p>
                    <b>
                      Due to COVID-19 impact a Touchless technology is the
                      future.
                    </b>
                  </p>
                  <p>
                    Turnout is an AI based Face Recognition solution which uses
                    machine learning algorithms to mark the attendance of the
                    employees. We developed AI powered Face recognition
                    attendance system for improving efficiency of the system and
                    a secure way to taking attendance.
                  </p>
                  <p>
                    Our smart Face Recognition solution works on a touchless
                    technology and that improves employee security and safety.
                    Our smart system used to calculate attendance automatically
                    by recognizing the facial dimensions. The system gives
                    Real-time data for quick processing and reporting.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <img src="assets/img/Turnout-E-1.png" alt="shape" />
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-section  turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">Key Features</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage1")}
                  onMouseOut={() => handleMouseOut("advantage1")}
                  id="advantage1"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-face-recognition"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Face Recognition</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage2")}
                  onMouseOut={() => handleMouseOut("advantage2")}
                  id="advantage2"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-deep-learning"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Deep Learning Based Computer Vision</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                  onMouseOver={() => handleMouseEnter("advantage3")}
                  onMouseOut={() => handleMouseOut("advantage3")}
                  id="advantage3"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-secure-cloud"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Secure Cloud Storage</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                  onMouseOver={() => handleMouseEnter("advantage4")}
                  onMouseOut={() => handleMouseOut("advantage4")}
                  id="advantage4"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-workplace"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>
                      On-Premise And Remote Workplace Attendance Management
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  onMouseOver={() => handleMouseEnter("advantage5")}
                  onMouseOut={() => handleMouseOut("advantage5")}
                  id="advantage5"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-pricing"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Pay As You Use Pricing Model</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
                  onMouseOver={() => handleMouseEnter("advantage6")}
                  onMouseOut={() => handleMouseOut("advantage6")}
                  id="advantage6"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-integration"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Easy Integration With Existing HRMS</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.7s"
                  onMouseOver={() => handleMouseEnter("advantage7")}
                  onMouseOut={() => handleMouseOut("advantage7")}
                  id="advantage7"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-surveillance"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Easy Integration With Surveillance System</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.8s"
                  onMouseOver={() => handleMouseEnter("advantage8")}
                  onMouseOut={() => handleMouseOut("advantage8")}
                  id="advantage8"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-automated"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Automated Alerts, Reports and Analytics</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.9s"
                  onMouseOver={() => handleMouseEnter("advantage9")}
                  onMouseOut={() => handleMouseOut("advantage9")}
                  id="advantage9"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-visitor"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Employee Management</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">How It Works</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <img src="assets/img/how-it-works-e.png" alt="How it works" />
              </div>
            </div>
          </div>
        </section> */}

        <section
          id="works"
          className="works services-area services-bg section_100"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div
                  className="section-title wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h2>
                    {" "}
                    <span>How it Works?</span>{" "}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12 mb-30 hover">
                <div
                  ref={advantageboxRef1}
                  onMouseOver={() => handleMouseEnter("id1")}
                  onMouseOut={() => handleMouseOut("id1")}
                  className="s-single-services text-center"
                  id="id1"
                >
                  <div className="services-icon">
                    <img alt="" src="assets/img/Works1.svg" />
                  </div>
                  <div className="second-services-content">
                    <h5>Person Detail Registration</h5>
                    <p>
                      A company admin creates the person’s record by filling
                      his/her details
                    </p>
                    <a>
                      <span>1</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 mb-30">
                <div
                  ref={advantageboxRef1}
                  onMouseOver={() => handleMouseEnter("id2")}
                  onMouseOut={() => handleMouseOut("id2")}
                  className="s-single-services text-center "
                  id="id2"
                >
                  <div className="services-icon">
                    <img
                      alt=""
                      src="assets/img/Works2.svg"
                      style={{ marginTop: "-4px" }}
                    />
                  </div>
                  <div className="second-services-content">
                    <h5>Facial Features Registration</h5>
                    <p>
                      Device captures person's facial data from live video or
                      saved local video.
                    </p>
                    <a>
                      <span>2</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 mb-30">
                <div
                  ref={advantageboxRef1}
                  onMouseOver={() => handleMouseEnter("id3")}
                  onMouseOut={() => handleMouseOut("id3")}
                  className="s-single-services text-center "
                  id="id3"
                >
                  <div className="services-icon">
                    <img
                      alt=""
                      src="assets/img/Works3.svg"
                      style={{ marginTop: "-4px" }}
                    />
                  </div>
                  <div className="second-services-content">
                    <h5>Facial Recognition</h5>
                    <p>
                      Device scans and compares the face against the stored
                      photos.
                    </p>
                    <a>
                      <span>3</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 mb-30">
                <div
                  ref={advantageboxRef1}
                  onMouseOver={() => handleMouseEnter("id4")}
                  onMouseOut={() => handleMouseOut("id4")}
                  className="s-single-services text-center "
                  id="id4"
                >
                  <div className="services-icon">
                    <img alt="" src="assets/img/Works4.svg" />
                  </div>
                  <div className="second-services-content">
                    <h5>Capture Real Time Attendance</h5>
                    <p>
                      Capture real-time attendance data on cloud and sync it
                      with different HRM functionalities.
                    </p>
                    <a>
                      <span>4</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-section benefits turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2 className="text-white">
                    <span className="underline">Benefits</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage10")}
                  onMouseOut={() => handleMouseOut("advantage10")}
                  id="advantage10"
                >
                  <div className="web-icon tai-icon-center">
                    <i className="turnout1-enhanced-workforce"></i>
                  </div>
                  <div className="service-title text-center text-capitalize">
                    <h3>enhance workplace productivity </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage11")}
                  onMouseOut={() => handleMouseOut("advantage11")}
                  id="advantage11"
                >
                  <div className="web-icon tai-icon-center">
                    <i className="turnout1-minimize-payroll"></i>
                  </div>
                  <div className="service-title text-center text-capitalize">
                    <h3>minimize payroll leakage traceability</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage12")}
                  onMouseOut={() => handleMouseOut("advantage12")}
                  id="advantage12"
                >
                  <div className="web-icon tai-icon-center">
                    <i className="turnout1-secure-and-scalable"></i>
                  </div>
                  <div className="service-title text-center text-capitalize">
                    <h3>secure and scalable </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage13")}
                  onMouseOut={() => handleMouseOut("advantage13")}
                  id="advantage13"
                >
                  <div className="web-icon tai-icon-center">
                    <i className="turnout1-transparency"></i>
                  </div>
                  <div className="service-title text-center text-capitalize">
                    <h3>transparency in salary payouts</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="turnout-s section_100 wow fadeInUp position-relative"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">Industry Application</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/healthcare.png"
                      alt="Healthcare"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Healthcare</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/Real-Estate.png"
                      alt="Real Estate"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Real Estate</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/manufacturing.png"
                      alt="Manufacturing"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Manufacturing</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/tourism.png"
                      alt="Travel and Tourism"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Travel and Tourism</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/insurance.png"
                      alt="Insurance"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Insurance</h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/offices.png"
                      alt="Offices"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Offices </h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/education.png"
                      alt="Education"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Education</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/retail.png"
                      alt="Retail"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Retail</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/agency.png"
                      alt="Agency"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Agency</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/bank.png"
                      alt="Banking"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Banking</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

TurnoutEdge.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const TurnoutEdgeWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <TurnoutEdge {...props} />
  </ThemeProvider>
);

export default TurnoutEdgeWithTheme;
