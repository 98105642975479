import React, { useState, useEffect, useCallback, FC } from "react";
import { useVersion, useDataProvider, useNotify } from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import ChartVideoRegistration from "./components/hr/ChartVideoRegistration";
import ListUpcomingEvents from "./components/hr/ListUpcomingEvents";
import MaleFemaleCard from "./components/hr/MaleFemaleCard";
import LeaveTypeDonatChart from "./components/hr/LeaveTypeDonutChart";
import LeaveCard from "./components/hr/LeavesCard";
import TodayLateEntry from "./components/hr/TodayTotalLateEnties";
import { Event, PendingRegistration } from "../types";
import EmployeeBehaviour from "./components/hr/EmployeeBehaviour";
import { toastError } from "../components/toast";
import authProvider from "../authProvider";
import { useLocation } from "react-router";

interface State {
  Headcounts?: number;
  PendingLeavesCount?: number;
  PendingRequestCount?: number;
  PendingVidRegCount?: number;
  TotalLeaveTodayCount?: number;
  EmployeesChartData?: {};
  EmployeesVisitorChartData?: {};
  LeavesChartData?: {};
  VidRegChartData?: {};
  recentEventList?: Event[];
  ListUpcomingLeaveData?: [];
  ListPendingVideoRegData?: PendingRegistration[];
  ListTodayAttendanceData?: [];
  data?: [];
  MaleFemaleEmpCount?: {};
  CountOfAllRequests?: {};
  TodayEmpAttendanceCount?: {};
  LeaveRequests?: {};
  EmployeeLeaveType?: [];
  TodayattendenceData?: [];
  LateEntryData?: {};
  EmployeeAttendenceData?: {};
  EmployeeRegistrationData?: {};
  AttendanceCardData?: [];
}

const styles = {
  flex: { display: "flex" },
  flexCol: {
    flex: 1,
    display: "flex",
    marginTop: "1em",
    // maxWidth: "calc(100% - 360px)",
  },
  flexXSmall: { display: "flex", marginRight: "15px", marginLeft: "15px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: {
    flex: 1,
    // marginRight: "1em"
  },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
};

const HrDashboard: FC = () => {
  const [state, setState] = useState<State>({});
  const [diagramLoading, setDiagramLoading] = useState(false);
  const [tableListLoading, settableListLoading] = useState(false);
  const [leaveBalanceLoading, setLeaveBalanceLoading] = useState(false);
  const version = useVersion();
  const location = useLocation();
  // const notify = useNotify();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const fetchCardsCount = useCallback(async () => {
    await dataProvider
      .callCustom("dashboard/cardsCount", {})
      .then((response: any) => {
        setState((state) => ({
          ...state,
          Headcounts:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.headcounts
              ? response.data.item.headcounts
              : 0,
          PendingLeavesCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.pendingLeaveCount
              ? response.data.item.pendingLeaveCount
              : 0,
          PendingRequestCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.pendingRequestCount
              ? response.data.item.pendingRequestCount
              : 0,
          PendingVidRegCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.pendingVideoRegistrationCount
              ? response.data.item.pendingVideoRegistrationCount
              : 0,
          TotalLeaveTodayCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.todayLeaveCount
              ? response.data.item.todayLeaveCount
              : 0,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            localStorage.clear();

            window.location.replace("/");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchLeaveAppData = useCallback(async () => {
    setLeaveBalanceLoading(true);
    await dataProvider
      .callCustom("dashboard/employee-leave-data", {})
      .then((response: any) => {
        setLeaveBalanceLoading(false);
        const leaveData = response.data.item || {};
        setState((state) => ({
          ...state,
          AttendanceCardData:
            response && response.data && response.data.item
              ? response.data.item
              : [],
        }));

        const leaveAssigned = response.data.item || [];
        setState((state) => ({
          ...state,
          LeaveAssignedData: leaveAssigned,
        }));
      })
      .catch((err: any) => {
        setLeaveBalanceLoading(false);
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            localStorage.clear();

            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchDiagramDahboardData = useCallback(async () => {
    setDiagramLoading(true);
    await dataProvider
      .callCustom("dashboard/diagramDahboardData", {})
      .then((response: any) => {
        setDiagramLoading(false);
        setState((state) => ({
          ...state,
          EmployeesChartData:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.employees
              ? response.data.item.employees
              : 0,
          // EmployeesVisitorChartData:
          //   response &&
          //     response.data &&
          //     response.data.item !== undefined &&
          //     response.data.item.users
          //     ? response.data.item.users
          //     : 0,
          // LeavesChartData:
          //   response &&
          //     response.data &&
          //     response.data.item !== undefined &&
          //     response.data.item.leaves
          //     ? response.data.item.leaves
          //     : 0,
          VidRegChartData:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.registration
              ? response.data.item.registration
              : {},
          MaleFemaleEmpCount:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.employeesGenderWise
              ? response.data.item.employeesGenderWise
              : {},
          CountOfAllRequests:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.requestCount
              ? response.data.item.requestCount
              : {},
          TodayEmpAttendanceCount:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.totalEmployee
              ? response.data.item.totalEmployee
              : {},
          LeaveRequests:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.requestData
              ? response.data.item.requestData
              : {},
          EmployeeRegistrationData:
            response &&
            response.data &&
            response.data.item !== undefined &&
            response.data.item.registrationData
              ? response.data.item.registrationData
              : [],
        }));
      })
      .catch((err: any) => {
        setDiagramLoading(false);
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            localStorage.clear();

            window.location.replace("/");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchCompanyListDahboardData = useCallback(async () => {
    settableListLoading(true);
    await dataProvider
      .callCustom("dashboard/dashboardTableListing", {
        requestLimit: 10,
        requestSkip: 0,
        eventSkip: 0,
        eventLimit: 10,
      })
      .then((response: any) => {
        settableListLoading(false);
        setState((state) => ({
          ...state,
          recentEventList:
            response &&
            response.data.upcoming_events &&
            response.data.upcoming_events.item
              ? response.data.upcoming_events.item
              : [],
          ListUpcomingLeaveData:
            response &&
            response.data.upcoming_leave &&
            response.data.upcoming_leave.item
              ? response.data.upcoming_leave.item
              : [],
          ListPendingVideoRegData:
            response &&
            response.data.pending_video_registration &&
            response.data.pending_video_registration.item
              ? response.data.pending_video_registration.item
              : [],
          ListTodayAttendanceData:
            response &&
            response.data.today_attendence_report &&
            response.data.today_attendence_report.item
              ? response.data.today_attendence_report.item
              : [],
          EmployeeLeaveType:
            response && response.data.employeeLeaveType
              ? response.data.employeeLeaveType
              : [],
          EmployeeAttendenceData:
            response && response.data.employeeAttendenceData
              ? response.data.employeeAttendenceData
              : [],
          TodayattendenceData:
            response && response.data.attendenceData
              ? response.data.attendenceData
              : [],
          LateEntryData:
            response &&
            response.data &&
            response.data !== undefined &&
            response.data.lateEntry
              ? response.data.lateEntry
              : {},
        }));
      })
      .catch((err: any) => {
        settableListLoading(false);
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            localStorage.clear();

            window.location.replace("/");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  useEffect(() => {
    fetchCardsCount();
    fetchDiagramDahboardData();
    fetchCompanyListDahboardData();
    fetchLeaveAppData();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    VidRegChartData,
    recentEventList,
    MaleFemaleEmpCount,
    CountOfAllRequests,
    TodayEmpAttendanceCount,
    LeaveRequests,
    EmployeeLeaveType,
    LateEntryData,
    EmployeeAttendenceData,
    EmployeeRegistrationData,
    AttendanceCardData,
  } = state;

  return isXSmall ? (
    <div style={styles.flexXSmall}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          {/* <TreeChart /> */}
          <TodayLateEntry
            isLoading={tableListLoading}
            data={LateEntryData}
            data2={EmployeeAttendenceData}
          />
        </div>
        <div style={styles.flexCol}>
          <MaleFemaleCard
            isLoading={diagramLoading}
            data={MaleFemaleEmpCount}
          />
        </div>
        <div style={styles.flexCol}>
          <ChartVideoRegistration
            isLoading={diagramLoading}
            data={VidRegChartData}
            data2={EmployeeRegistrationData}
          />
        </div>
        {!authProvider.isHiddenField(
          location?.pathname,
          "leaveTypeDistribution",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <LeaveTypeDonatChart
              isLoading={tableListLoading}
              data={EmployeeLeaveType}
              data2={AttendanceCardData}
            />
          </div>
        )}
        {!authProvider.isHiddenField(
          location?.pathname,
          "leaves",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <LeaveCard
              isLoading={tableListLoading}
              data={CountOfAllRequests}
              data2={TodayEmpAttendanceCount}
              data3={LeaveRequests}
            />
          </div>
        )}
        {!authProvider.isHiddenField(
          location?.pathname,
          "upcomingEvents",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <ListUpcomingEvents
              isLoading={tableListLoading}
              events={recentEventList}
            />
          </div>
        )}
        <div style={styles.flexCol}>
          <EmployeeBehaviour />
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexXSmall}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          {/* <TreeChart /> */}
          <TodayLateEntry
            isLoading={tableListLoading}
            data={LateEntryData}
            data2={EmployeeAttendenceData}
          />
        </div>
        <div style={styles.flexCol}>
          <MaleFemaleCard
            isLoading={diagramLoading}
            data={MaleFemaleEmpCount}
          />
        </div>
        <div style={styles.flexCol}>
          <ChartVideoRegistration
            isLoading={diagramLoading}
            data={VidRegChartData}
            data2={EmployeeRegistrationData}
          />
        </div>
        {!authProvider.isHiddenField(
          location?.pathname,
          "leaveTypeDistribution",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <LeaveTypeDonatChart
              isLoading={tableListLoading}
              data={EmployeeLeaveType}
              data2={AttendanceCardData}
            />
          </div>
        )}
        {!authProvider.isHiddenField(
          location?.pathname,
          "leaves",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <LeaveCard
              isLoading={tableListLoading}
              data={CountOfAllRequests}
              data2={TodayEmpAttendanceCount}
              data3={LeaveRequests}
            />
          </div>
        )}
        {!authProvider.isHiddenField(
          location?.pathname,
          "upcomingEvents",
          "isHide"
        ) && (
          <div style={styles.flexCol}>
            <ListUpcomingEvents
              isLoading={tableListLoading}
              events={recentEventList}
            />
          </div>
        )}
        <div style={styles.flexCol}>
          <EmployeeBehaviour />
        </div>
      </div>
    </div>
  ) : (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          {/* <TotalHeads value={Headcounts} /> */}
          {/* <PendingRequest value={PendingRequestCount} /> */}
          {/* <TreeChart /> */}
          <TodayLateEntry
            isLoading={tableListLoading}
            data={LateEntryData}
            data2={EmployeeAttendenceData}
          />
          <MaleFemaleCard
            isLoading={diagramLoading}
            data={MaleFemaleEmpCount}
          />
          <ChartVideoRegistration
            isLoading={diagramLoading}
            data={VidRegChartData}
            data2={EmployeeRegistrationData}
          />
        </div>

        <div style={styles.flexCol}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "66%",
            }}
          >
            {!authProvider.isHiddenField(
              location?.pathname,
              "leaveTypeDistribution",
              "isHide"
            ) && (
              <div>
                <LeaveTypeDonatChart
                  isLoading={tableListLoading}
                  data={EmployeeLeaveType}
                  data2={AttendanceCardData}
                />
              </div>
            )}
            {!authProvider.isHiddenField(
              location?.pathname,
              "leaves",
              "isHide"
            ) && (
              <div>
                <LeaveCard
                  isLoading={tableListLoading}
                  data={CountOfAllRequests}
                  data2={TodayEmpAttendanceCount}
                  data3={LeaveRequests}
                />
              </div>
            )}
          </div>
          {!authProvider.isHiddenField(
            location?.pathname,
            "upcomingEvents",
            "isHide"
          ) && (
            <ListUpcomingEvents
              isLoading={tableListLoading}
              events={recentEventList}
            />
          )}
        </div>

        {/* <div style={styles.flexCol}>
              <ChartVisitorEmployee
                data={EmployeesVisitorChartData}
              ></ChartVisitorEmployee>
              <ChartLeaves data={LeavesChartData}></ChartLeaves>

            </div> */}
        <div style={styles.flexCol}>
          <EmployeeBehaviour />
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
