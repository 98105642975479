import * as React from "react";
import { FC } from "react";
import {
  TextField,
  Datagrid,
  List,
  Pagination,
  downloadCSV,
  FormDataConsumer,
  required,
  TopToolbar,
  ExportButton,
  Filter,
  DateInput,
  FunctionField,
} from "react-admin";
import {
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Theme,
  IconButton,
  Card as MuiCard,
  withStyles,
} from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { Button, Box } from "@material-ui/core";
import { Form } from "react-final-form";
import helperFunc from "../helperFuncs";
import { ListComponentProps } from "../types";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import TimeField from "./timeField";
import OutTimeField from "./outTimeField";
import StayTimeField from "./stayTimeFIeld";
import TotalTimeField from "./totaltimeField";
import moment from "moment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import AttandanceShow from "./AttendanceShow";

import EmployeeSelectInput from "../components/EmployeeSelectInput";
import authProvider from "../authProvider";

const PostFilterForm = (data: any, props: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.fromDate) {
        values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      }
      if (values.toDate) {
        values.toDate = moment(values.toDate).format("YYYY-MM-DD");
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters(
      {
        fromDate: helperFunc.startOfMonth(0),
        toDate: helperFunc.addDays(0, "forReport"),
        company: helperFunc.selectedCompany(),
      },
      []
    );
    data.handleDrawerClose("fromFilter");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={data.filterValues}
            validate={(values) => {
              const errors: any = {};

              if (
                helperFunc.getUserData() &&
                helperFunc.getUserData().type === "super" &&
                !values.company
              ) {
                errors.company = "Required";
              }
              if (values.toDate && new Date(values.toDate) > new Date()) {
                errors.toDate = "To Date must be smaller than current Date";
              }

              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <CompanyReferenceInput
                            helperText={false}
                          ></CompanyReferenceInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <EmployeeSelectInput
                          helperText={false}
                          filter={{
                            company: formData.company,
                            isActive: true,
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root}>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          // defaultValue={helperFunc.startOfMonth(0)}
                          validate={[required()]}
                          source="fromDate"
                          label="label.fromDate"
                          alwaysOn
                          helperText={false}
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          // defaultValue={helperFunc.addDays(1, "forReport")}
                          validate={[required()]}
                          source="toDate"
                          label="label.toDate"
                          alwaysOn
                          helperText={false}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <TopToolbar>
        <Button
          style={{
            padding: "3px 0px 0px 0px",
            fontSize: "13px",
          }}
          color="primary"
          component="span"
          onClick={() => handleDrawerOpen()}
        >
          <FilterListIcon style={{ marginRight: "0.2em" }} />
          {isSmall ? null : "Filter"}
        </Button>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
        {/* <RegenrationReport /> */}
        {/* <ImportButton basePath={basePath} {...props} /> */}
      </TopToolbar>
    </>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};
const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  root: {},
  employee: {
    minWidth: "150px",
  },
  name: { display: "inline-block" },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const mode: any = {
      mobile: "Mobile",
      edge: "Edge",
      manual: "Manual",
      cron: "Cron",
      auto: "Auto",
    };
    const {
      company,
      dateStr,
      department,
      departmentData,
      designation,
      designationData,
      early,
      employee,
      employeeData,
      employeeType,
      employeeTypeData,
      inTime,
      isAbsent,
      late,
      outTime,
      role,
      shift,
      shiftData,
      stayTime,
      totalTime,
      _id,
      id,
      geoPoints,
      inLoc,
      outLoc,
      outMode,
      inMode,
      ...dataToExport
    } = record; // omit fields

    dataToExport["Employee Name"] =
      employeeData &&
      employeeData.name + " " + "(" + employeeData.employeeCode + ")";

    dataToExport["Date"] = helperFunc.commonDateFormat(dateStr);
    dataToExport["In"] = inTime ? moment(inTime).format("hh:mm:ss A") : "N/A";
    dataToExport["Out"] = outTime
      ? moment(outTime).format("hh:mm:ss A")
      : "N/A";
    dataToExport["Spent Hours"] = helperFunc.secondsToHms(stayTime);
    dataToExport["Total Hours"] = helperFunc.secondsToHms(totalTime);
    dataToExport["Absent"] = isAbsent ? "Yes" : "No";
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Team Attendance History"); // download as 'xxxx.csv` file
    }
  );
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const TeamAttendanceReportList: FC<ListComponentProps<{ id: string }>> = (
  props: any
) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["fromDate"] = helperFunc.subtractDaysFilter(7);
    myFilters["toDate"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div className={classes.root}>
      <>
        {isXSmall ? (
          <h2
            style={{
              height: 40,
              fontSize: 30,
              backgroundColor: "#fff",
              padding: 10,
            }}
          >
            Team History
          </h2>
        ) : null}
        <div
          className={open === true ? "overlay" : ""}
          onClick={() => handleDrawerClose("fromlist")}
        />

        <div
          style={
            isXSmall
              ? {
                  width: window.screen.width,
                  overflowX: "scroll",
                }
              : {}
          }
        >
          <List
            className="list-view2"
            bulkActionButtons={false}
            title="Team History"
            exporter={exporter}
            actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
            {...props}
            filterDefaultValues={filterData()}
            perPage={20}
            pagination={
              <Pagination
                labelRowsPerPage="Records per page"
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            }
            sort={{ field: "name", order: "ASC" }}
            filters={
              <AttendanceFilter
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            }
          >
            <Datagrid optimized>
              <TextField
                source="employeeData.name"
                sortBy="employeeData.name"
                label="resources.customers.fields.name"
              />
              <TextField label="Date" source="dateStr" sortBy="dateStr" />

              <FunctionField
                label="In"
                sortBy="inTime"
                render={(record: any) =>
                  `${
                    record["inTime"]
                      ? moment(record.inTime).format("hh:mm:ss A")
                      : "N/A"
                  }  `
                }
              />
              <FunctionField
                label="Out"
                sortBy="outTime"
                render={(record: any) =>
                  `${
                    record["outTime"]
                      ? moment(record.outTime).format("hh:mm:ss A")
                      : "N/A"
                  }  `
                }
              />

              {authProvider?.isHiddenField(
                props?.location?.pathname,
                "stayTime",
                "isHide"
              ) === false && <StayTimeField label="Spent Hours" />}
              <TotalTimeField label="Total Hours" />
              <AttandanceShow {...props} />
            </Datagrid>
          </List>
        </div>
      </>
    </div>
  );
};

export default TeamAttendanceReportList;
