import * as React from "react";
import { FC, useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  // Link,
  useMediaQuery,
  Theme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  makeStyles,
  Dialog,
  DialogTitle,
  CircularProgress,
  Button,
  Box,
} from "@material-ui/core";
// import { useTheme } from "@material-ui/core/styles";
import { useTranslate, Link } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import helperFunc from "../../helperFuncs";
import DialogueBox from "../DialogueBox";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
  isloading?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    // width: "30%",
    padding: 5,
    marginRight: "1em",
  },
  mobileRoot: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  card: {
    flex: 1,
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    // width: "40%",
    minHeight: 52,
  },
  cardImg: {
    // flex: 1,
    float: "left",
    overflow: "inherit",
    // textAlign: "right",
    padding: 19,
    // width: "43%",
    minHeight: 52,
    marginTop: 19,
    // marginTop: 8,
  },
  title: {
    // width: "100%",
    display: "flex",
    flex: 1,
    padding: "20px",
    fontSize: "1.5rem",
    // height: "0px",
  },
  closeButton: { position: "absolute", top: 5, right: 0 },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  myPopup: { display: "inline-block" },
}));

const GeneralDetailCard: FC<Props> = (data: any) => {
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const [state, setState] = React.useState({
    holidays: 0,
    offdays: 0,
    requests: 0,
    teamRequest: 0,
  });
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");
  const dataObj: any = data.data2 || {};
  const translate = useTranslate();
  const classes = useStyles();
  const isloading = data.isLoading;

  const headerArr = [
    { label: "Date", key: "dateStr" },
    { label: "Date", key: "name" },
  ];

  const object: any = {
    Holiday: "holidays",
    "Off Days": "offdays",
  };
  let dataBySlice = dataObj[object[activeSlice]];

  React.useEffect(() => {
    setState(data.data);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = useCallback((data: any) => {
    // setActiveIndex(index);
    setOpen(true);
    setActiveSlice(data);
    setPage(0);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <Card className={isXSmall ? classes.mobileRoot : classes.root}>
      <CardHeader title={translate("pos.dashboard.general_detail")} />
      {isloading ? (
        <span
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <>
          <Box className={classes.cardImg}>
            <img
              src="icons/general_details.png"
              title={translate("pos.dashboard.general_detail")}
            ></img>
          </Box>

          <DialogueBox
            dialogueData={dataBySlice}
            open={open}
            handleClose={handleClose}
            activeSlice={activeSlice}
            headerArr={headerArr}
            page={page}
            handleDefaultPage={handleDefaultPage}
            rowsPerPage={rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            exportData={[]}
            isExport={false}
          />

          <CardContent className={classes.card}>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to="/holidayList"
                onClick={() => handleClickOpen("Holiday")}
              >
                Holiday({state && state.holidays ? state.holidays : 0})
              </Link>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to="/weekOffList"
                onClick={() => handleClickOpen("Off Days")}
              >
                Off Days({state && state.offdays ? state.offdays : 0})
              </Link>
            </Typography>
            {/* <Typography variant="subtitle1" color="textSecondary">
          <Link href="#" onClick={preventDefault}>
            Events({dataObj.events})
          </Link>
        </Typography> */}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default GeneralDetailCard;
