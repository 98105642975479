import * as React from "react";
import { FC } from "react";
import { Button, useDataProvider, useRefresh } from "react-admin";
import { FieldProps, Country } from "../types";
import RestoreIcon from "@material-ui/icons/Restore";
import { toastError, toastInfo } from "../components/toast";

const RestoreField: FC<FieldProps<Country>> = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const handelRestore = (record: any) => {
    dataProvider
      .callCustom(`${props?.resource}/restore/${record?._id}`)
      .then((responseLocal: any) => {
        if (responseLocal.data.success === true) {
          toastInfo("Employee has been restored Successfully.");
          // notify("Data has been imported Successfully.");
          refresh();
          // e.target.files[0] = null;
          // data = new FormData();
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        refresh();
        // e.target.files[0] = null;
        // data = new FormData();
      });
  };
  return (
    <Button onClick={() => handelRestore(props?.record)} label="Restore">
      <RestoreIcon />
    </Button>
  );
};

RestoreField.defaultProps = {
  source: "name",
  label: "Employee Name",
  addLabel: true,
};

export default RestoreField;
