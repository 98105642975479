import React, { useState, Fragment, useEffect } from "react";
import { useDataProvider, FormDataConsumer } from "react-admin";
import {
  Box,
  Button,
  useMediaQuery,
  Theme,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-final-form";
import { validate } from "../helperValidate";
import { styleData } from "../helperConst";
import { collegeReportStyle } from "../helperStyle";
import { toastError } from "../components/toast";
import { SmListHeader } from "../components/SmFormHeader";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const TodayTimetableReport = (props: any): any => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(collegeReportStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  let container: any = null;
  const [responseData, setResponseData] = useState([]);
  const [viewlLoading, setViewLoading] = useState<any>(null);
  const [streamChange, setStreamChange] = useState(Boolean);
  const [dateArr, setDateArr] = useState([]);

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  const dateValidate = (value: any) => {
    const errors: any = {};
    if (value.toDate && new Date(value.fromDate) > new Date(value.toDate)) {
      errors.toDate = "To Date must be  greater than from Date";
    } else if (value.toDate && new Date(value.toDate) > new Date()) {
      errors.toDate = "To Date must be  smaller than current Date";
    }
    return errors;
  };

  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Today Timetable Report";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  // const onSubmit = (value: any) => {};
  const onSubmit = (formdata: any) => {
    if (!formdata.stream) {
      toastError("Please Select Stream");
    } else if (!formdata.course) {
      toastError("Please Select Course");
    } else {
      setViewLoading(true);
      const resourcePath = `college-report/report5`;
      dataProvider
        .callCustom(resourcePath, {
          stream: formdata && formdata.stream ? formdata.stream : null,
          course: formdata && formdata.course ? formdata.course : null,
        })
        .then((response: any) => {
          setViewLoading(false);
          setResponseData(response && response.data && response.data.finalData);
          setDateArr(response && response.data && response.data.semesterData);
        })
        .catch((err: any) => {
          setViewLoading(false);
          toastError(err);
        });
    }
  };

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <SmListHeader {...props} />
      <Form onSubmit={onSubmit} validate={dateValidate}>
        {(props: any) => (
          <form>
            <Box
              component="div"
              className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
            >
              <CompanyReferenceInput></CompanyReferenceInput>
            </Box>
            <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
              <div>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <StreamSelectInput
                      labelName="Stream *"
                      dataManage={streamData}
                    ></StreamSelectInput>
                  )}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => {
                    return (
                      <CourseSelectInput
                        filter={{ stream: formData.stream, isActive: true }}
                        validate={validate}
                        formClassName={classes.code}
                        streamChange={streamChange}
                      ></CourseSelectInput>
                    );
                  }}
                </FormDataConsumer>
              </div>
            </div>
            <Box>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(formData)}
                  >
                    {viewlLoading && (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        style={{ color: "aliceblue" }}
                      />
                    )}
                    VIEW
                  </Button>
                )}
              </FormDataConsumer>
            </Box>
          </form>
        )}
      </Form>

      {(responseData && responseData.length === 0) ||
      responseData === undefined ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smTableDiv : classes.rtable}>
          <Fragment>
            <TableContainer
              className={
                isXSmall ? classes.smtableContainer : classes.container
              }
            >
              <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header} rowSpan={2}>
                      Sr.No
                    </TableCell>
                    <TableCell className={classes.header}>Timeslot</TableCell>
                    {dateArr
                      ? dateArr &&
                        dateArr.map((semester: string, index: number) => {
                          return (
                            <TableCell className={classes.header}>
                              {semester}
                            </TableCell>
                          );
                        })
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody style={{ justifyContent: "center" }}>
                  {responseData &&
                    responseData.map((data: any, rowIndex: number) => {
                      let timeslotName = Object.keys(data)[0];
                      let timeslotObj = Object.values(data)[0] as any;
                      return (
                        <Fragment>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.serialTableCell}
                            >
                              {rowIndex + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {data ? timeslotName : "N/A"}
                            </TableCell>
                            {dateArr
                              ? dateArr &&
                                dateArr.map((semester: any, index: number) => {
                                  return (
                                    <Fragment key={index + 1}>
                                      <TableCell
                                        className={
                                          timeslotObj &&
                                          timeslotObj[semester] &&
                                          timeslotObj[semester][0] &&
                                          timeslotObj[semester][0].subject
                                            ? classes.blueTableCell
                                            : classes.tableCell
                                        }
                                        align="center"
                                      >
                                        <Typography
                                          className={classes.tablecellTextColor}
                                        >
                                          {timeslotObj &&
                                          timeslotObj[semester] &&
                                          timeslotObj[semester][0]
                                            ? timeslotObj[semester][0].subject
                                            : ""}
                                        </Typography>
                                        <Typography
                                          className={
                                            timeslotObj &&
                                            timeslotObj[semester] &&
                                            timeslotObj[semester][0] &&
                                            timeslotObj[semester][0].faculty
                                              ? classes.whiteTableCell
                                              : ""
                                          }
                                        >
                                          {timeslotObj &&
                                          timeslotObj[semester] &&
                                          timeslotObj[semester][0]
                                            ? timeslotObj[semester][0].faculty
                                            : "N/A"}
                                        </Typography>
                                        <Typography
                                          className={classes.whiteTableCell}
                                        >
                                          {timeslotObj &&
                                          timeslotObj[semester] &&
                                          timeslotObj[semester][0]
                                            ? `(${timeslotObj[semester][0].classroom})`
                                            : ""}
                                        </Typography>
                                      </TableCell>
                                    </Fragment>
                                  );
                                })
                              : null}
                          </TableRow>
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </div>
      )}
    </div>
  );
};
export default TodayTimetableReport;
