import AttendanceList from "./VehicleAttendanceList";
import AttendanceEdit from "./VehicleAttendanceEdit";
import AttendanceCreate from "./VehicleAttendanceCreate";
import { VehicleAttendanceIcon } from "../svgIcon";

export default {
  list: AttendanceList,
  create: AttendanceCreate,
  edit: AttendanceEdit,
  icon: VehicleAttendanceIcon,
};
