import * as React from "react";
import { Edit } from "react-admin";

import CommonForm from "./commonForm";
import { PostEditToolbar } from '../components/PostEditToolbar';


// const PostEditToolbar = (props: any) => (
//   <Toolbar {...props} >
//     <SaveButton />
//     <DeleteButtonWithConfirmation undoable={false} titleSource="leaveName" />
//   </Toolbar>
// );

const WeekOffEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.sundayOffList.edit_title"} {...props}>
      <CommonForm toolbar={<PostEditToolbar titleSource="name" />} {...props} />
    </Edit>
  );
};

export default WeekOffEdit;
