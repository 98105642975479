import * as React from "react";
import { FC, memo, useState } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";

interface State {
    regions?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
    value?: number;
    filter?: {};
}

const MultiSelectRegionInput: FC<Props> = ({ filter }) => {
    const [state, setState] = useState<State>({});
    const dataProvider = useDataProvider();
    useDeepCompareEffect(() => {
        dataProvider
            .getSearchList("regions/multiple-search", filter)
            .then((response: any) => {
                setState((state) => ({
                    ...state,
                    regions: response && response.data,
                }));
            });
    }, [filter, dataProvider])


    const { regions } = state;

    return (
        <AutocompleteArrayInput
            resettable
            label="Regions"
            source="dataRights.regionId"
            choices={regions}
        />
        // <ReferenceArrayInput
        //     resettable
        //     label="Regions"
        //     source="regionId"
        //     reference="regions"
        //     choices={regions}
        // // filter={{ company: formData.company, isActive: true }}
        // // validate={requiredValidate}
        // // formClassName={classes.referenceEmpArray}
        // >
        //     <AutocompleteArrayInput resettable />
        // </ReferenceArrayInput>
    );
};
MultiSelectRegionInput.defaultProps = {
    source: "states",
    label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectRegionInput);
