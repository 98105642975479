import * as React from "react";

import { FC, useState } from "react";
import Card from "@material-ui/core/Card";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import CardIcon from "../../CardIcon";
import DialogueBox from "../../DialogueBox";

interface Props {
  value?: number;
  data?: JSON;
  icon?: any;
  title?: string;
  bgColor?: string;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    // borderBottom: `4px solid #4caf50`,
  },
  title: {},
});

const RegEmpCard: FC<Props> = ({ value, icon, title, bgColor, data }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dataObj = data || {};
  let dataArr: any = data || [];
  const headerArr: any = [
    { label: "Name", key: "name" },
    { label: "Code", key: "employeeCode" },
    { label: "Email", key: "email" },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setGender(data);
    handleDefaultPage(0);
    handleRowsPerPage(10);
  };

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <div className={classes.main}>
      <CardIcon Icon={icon} bgColor={bgColor ? bgColor : "#4caf50"} />
      <Card
        onClick={() => handleClickOpen()}
        className={classes.card}
        style={{ borderBottom: `4px solid ${bgColor}` }}
      >
        <Typography className={classes.title} color="textSecondary">
          {translate(title)}
        </Typography>
        <Typography variant="h5" component="h2">
          {value ? value : 0}
        </Typography>
      </Card>
      <DialogueBox
        dialogueData={dataArr?.registeredEmployee}
        open={open}
        handleClose={handleClose}
        activeSlice={"Registered Employee"}
        headerArr={headerArr}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={[]}
        isExport={false}
      />
    </div>
  );
};

export default RegEmpCard;
