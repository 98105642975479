import * as React from "react";
import { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Company } from "../types";
import Box from "@material-ui/core/Box";
// import { url } from "inspector";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  root: {
    height: "140px",
    // width: "243px",
    // textAlign: "center",
    // display: "flex",
    // objectFit: "contain",

    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    margin: "5px 10px",
  },

  media: {
    margin: "auto",
    width: "inherit",
    height: "inherit",
    objectFit: "contain",
  },
});

const CompanyLogo: FC<FieldProps<Company>> = ({ record }) => {
  const classes = useStyles();

  return record && record.settings && record.settings.company_logo ? (
    <Box
      className={classes.root}
      style={{
        backgroundImage: `url(${uploadApiUrl + record.settings.company_logo} )`,
      }}
    >
      {/* <img
        src={record.settings.company_logo.src}
        className={classes.media}
        alt={record.name}
      /> */}
    </Box>
  ) : (
    <Box className={classes.root}></Box>
  );
};

export default CompanyLogo;
