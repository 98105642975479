import * as React from "react";
import { FC, memo, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldProps, Visitor, Role } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  departments?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  helperText?: boolean;
}

const DepartmentSelectInput: FC<Props> = ({ helperText, validate, filter }) => {

  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("departments/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          departments: response && response.data,
        }));
      });
  }, [filter])

  const { departments } = state;

  return (
    <SelectInput
      resettable
      label="Departments"
      className={classes.root}
      source="department"
      validate={validate}
      choices={departments}
      helperText={helperText}
    />
  );
};

DepartmentSelectInput.defaultProps = {
  source: "departments",
  label: "resources.customers.fields.name",
};

export default memo<Props>(DepartmentSelectInput);
