
import DepartmentIcon from "@material-ui/icons/SpeakerGroup";
import ClientAdminCreate from "./ClientAdminCreate";
import ClientAdminEdit from "./ClientAdminEdit";
import ClientAdminList from "./ClientAdminList";

export default {
  list: ClientAdminList,
  create: ClientAdminCreate,
  edit: ClientAdminEdit,
  icon: DepartmentIcon,
};
