import * as React from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  TextInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  useDeleteMany,
  Button as ReactButton,
  DateInput,
} from "react-admin";
import { regex } from "ra-core";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import ImportButton from "../components/import";
import DescriptionIcon from "@material-ui/icons/Description";
import { isActive } from "../helperChoice";
import CityLinkField from "./CityLinkField";
import helperFunc from "../helperFuncs";
import MobileGrid from "./MobileGrid";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import classnames from "classnames";
import ActionDelete from "@material-ui/icons/Delete";
import MultiDeleteTable from "../components/ErrorTable";

const BulkDeleteButton = ({ resource, selectedIds }: any) => {
  const [deleteMany, { data, loading, error }] = useDeleteMany(
    resource,
    selectedIds
  );
  return (
    <>
      <MultiDeleteTable data={data} resource={resource} />
      <ReactButton
        disabled={loading}
        align="right"
        onClick={deleteMany}
        label={"Delete"}
        className={classnames("ra-delete-button")}
        key="button"
      >
        <ActionDelete />
      </ReactButton>
    </>
  );
};

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <TextInput
                      source="code"
                      helperText={false}
                      resettable
                      validate={[
                        regex(/.*\S.*/, "Only whitespaces are not allowed"),
                      ]}
                      format={helperFunc.codeFormatter}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      allowEmpty={true}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                      resettable
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      label="label.createdAt"
                      source="createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const user = helperFunc.getUserData();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {user.type === "super" || user.type === "company" ? (
        <ImportButton {...props} />
      ) : null}
      {user.type === "super" || user.type === "company" ? (
        <Button
          className="btnfilter"
          color="primary"
          component="span"
          onClick={SampleExporter}
          style={
            isSmall
              ? {
                  fontSize: "0.8125rem",
                }
              : {
                  fontSize: "0.8125rem",
                  minWidth: "126px",
                }
          }
        >
          <DescriptionIcon
            style={{ transform: "rotate(180deg)", fontSize: 20 }}
          />
          {!isSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>Sample CSV</span>
          ) : null}
        </Button>
      ) : null}
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const CityFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const SampleExporter = () => {
  const records = [
    {
      name: "NameExample",
      code: "CodeExample",
      status: "true",
      state: "gujarat",
    },
    {
      name: "NameExample1",
      code: "CodeExample1",
      status: "true",
      state: "gujarat",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record; // omit fields

    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: ["name", "code", "status", "state"], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "sampleCityCsv"); // download as 'xxxx.csv` file
    }
  );
};
const exporter = (records: any) => {
  const user = helperFunc.getUserData();
  const recordsForExport = records.map((record: any) => {
    const {
      isDeleted,
      isActive,
      name,
      code,
      company,
      _id,
      createdBy,
      createdAt,
      updatedAt,
      country,
      id,
      state,
      updatedBy,
      __v,
      ...dataToExport
    } = record; // omit fields
    // dataToExport["Country"] = country && country.name;
    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["State"] = state && state.name;
    dataToExport["City"] = name;
    dataToExport["Code"] = code;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Status"] = isActive ? "Yes" : "No"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Cities Report"); // download as 'xxxx.csv` file
    }
  );
};

const PostBulkActionButtons = (props: any) => (
  <>
    <BulkDeleteButton undoable={false} {...props} />
  </>
);

const CityList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const user = helperFunc.getUserData();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Cities
        </h2>
      ) : null}
      <List
        className="list-view2"
        exporter={exporter}
        {...props}
        filters={
          isSmall ? (
            <CityFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <CityFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        // aside={<CityListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <CityLinkField />
            <TextField source="code" format={helperFunc.codeFormatter} />
            {user.type === "super" ? (
              <TextField label="Company" source="company.name" />
            ) : null}
            <TextField source="state.name" label="label.state" />
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField source="isActive" label="label.status" />
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default CityList;
