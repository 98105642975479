import * as React from "react";
import { FC, memo, useState } from "react";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  country?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const CountrySelectInput: FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("countries/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          country: response && response.data,
        }));
      });
  }, [dataProvider]);

  const { country } = state;

  return (
    <SelectInput
      label="Country"
      className={classes.root}
      source="country"
      validate={requiredValidate}
      choices={country}
    />
  );
};
const requiredValidate = [required()];
CountrySelectInput.defaultProps = {
  source: "country",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CountrySelectInput);
