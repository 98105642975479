import * as React from "react";
import { Link, useDataProvider } from "react-admin";

import { PopOver } from "../common";
import { toastError, toastInfo } from "../components/toast";
import ReasonDialogueBox from "../teamRequests/reasonDialogueBox";

const RequestLinkField = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recordId, setRecordId] = React.useState(null);
  const [dialogueOpen, setDialogueOpen] = React.useState(false);
  const [status, setStatus] = React.useState() as any;
  const dataProvider = useDataProvider();
  const approvalStatus: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };

  const options = [
    { label: "Approved", isPermission: true },
    { label: "Reject", isPermission: true },
  ];

  const handleDialogueOpen = () => {
    setDialogueOpen(true);
  };
  const handleDialogueClose = () => {
    setDialogueOpen(false);
  };

  const handleClick = (event: any, record: any) => {
    setAnchorEl(event.currentTarget);
    setRecordId(record);
  };

  const handleClose = (option: any) => {
    setAnchorEl(null);
    if (typeof option !== "object") {
      if (option === "Approved") {
        handleApproval(recordId, "approve", {});
      } else {
        handleDialogueOpen();
      }
    }
  };

  const handleApproval = (record: any, status: any, formData: any) => {
    let payload;

    if (status === "reject") {
      payload = { requestStatus: status, reason: formData.reason };
    } else {
      payload = {
        requestStatus: status,
      };
    }

    dataProvider
      .callCustomLocalOne(
        "attendenceRequestLog/updatestatus/" + record?._id,
        payload
      )
      .then((response: any) => {
        setStatus(response?.data);

        if (response?.data?.requestStatus === "reject") {
          handleDialogueClose();
          toastError("Rejected");
        } else {
          toastInfo("Approved Successfully");
        }
      });
  };

  return (
    <>
      {status?.requestStatus ? (
        approvalStatus[status?.requestStatus]
      ) : props?.record?.requestStatus === "pending" ? (
        <Link onClick={(event: any) => handleClick(event, props.record)}>
          {approvalStatus[props?.record?.requestStatus]}
        </Link>
      ) : (
        approvalStatus[props?.record?.requestStatus]
      )}

      <PopOver
        options={options}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <ReasonDialogueBox
        open={dialogueOpen}
        close={handleDialogueClose}
        recordId={recordId}
        handleApproval={handleApproval}
      />
    </>
  );
};

RequestLinkField.defaultProps = {
  source: "requestStatus",
};

export default RequestLinkField;
