import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  email,
  FileInput,
  ImageField,
  PasswordInput,
  number,
  SaveButton,
  Button,
  Toolbar,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  FormDataConsumer,
  useEditController,
  NumberInput,
  useDataProvider,
} from "react-admin";
import { regex } from "ra-core";
import { maxValue, minValue } from "../TurnoutAdmin";

import EmailIcon from "@material-ui/icons/Email";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import RichTextInput from "ra-input-rich-text";
import { settingsStyles } from "../helperStyle";
import { ActiveDirectoryConfig } from "../companies/components";
import SMTP from "./SMTPConfig";
import helperFunc from "../helperFuncs";
import { toastError, toastInfo } from "../components/toast";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const validatePasswords = ({
  contactEmail,
  email,
  password,
  confirm_password,
}: {
  contactEmail: string;
  email: string;
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;

  if (contactEmail) {
    //eslint-disable-next-line
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contactEmail)) {
      errors.contactEmail = "Must be a valid email";
    }
  }

  if (email) {
    //eslint-disable-next-line
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.email = "Must be a valid email";
    }
  }

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const PostEditToolbar = (props: any) => (
  <Toolbar alwaysEnableSaveButton {...props}>
    <SaveButton disabled={false} />
  </Toolbar>
);

const useStyles = makeStyles(settingsStyles);
const SettingEdit = (props: any) => {
  const classes = useStyles();
  const controllerProps = useEditController(props);

  const introScreen =
    controllerProps &&
    controllerProps?.record &&
    controllerProps?.record?.introScreen
      ? uploadApiUrl + controllerProps?.record?.introScreen
      : "";
  const companyLogo =
    controllerProps &&
    controllerProps.record &&
    controllerProps?.record?.settings?.company_logo
      ? uploadApiUrl + controllerProps?.record?.settings?.company_logo
      : "";
  const newsContentImage =
    controllerProps &&
    controllerProps?.record &&
    controllerProps?.record?.newsContent &&
    controllerProps?.record?.newsContent?.image
      ? uploadApiUrl + controllerProps?.record?.newsContent?.image
      : "";

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();

  const dataProvider = useDataProvider();

  return isSmall ? (
    <Edit undoable={false} title="pos.settings" {...props}>
      <SimpleForm
        toolbar={<PostEditToolbar />}
        redirect={false}
        validate={validatePasswords}
      >
        <div style={{ display: "inline" }}>
          <TextInput
            fullWidth
            autoFocus
            source="name"
            formClassName={classes.customSize}
            validate={requiredValidate}
          />
          <SectionTitle label="" />
          <TextInput
            source="phone"
            disabled
            // fullWidth
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            type="email"
            disabled
            source="email"
            validation={{ email: true }}
            fullWidth
            formClassName={classes.email}
            validate={[required(), email()]}
          />
          <SectionTitle label="" />
          <TextInput
            label="label.contact_phone"
            source="metaInfo.contact.phone"
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            label="label.contact_email"
            type="email"
            source="contactEmail"
            validation={{ email: true }}
            fullWidth
            formClassName={classes.email}
            validate={[required(), email()]}
          />
          <SectionTitle label="" />
          <TextInput
            // disabled
            label="label.employee_size"
            source="numberOfMaxUser"
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            // disabled
            label="label.visitor_size"
            source="numberOfMaxVisitor"
            formClassName={classes.code}
            validate={requiredValidate}
          />
          <TextInput
            multiline
            label="label.company_Address"
            source="metaInfo.address.lines"
            formClassName={classes.code}
            validate={requiredValidate}
          />
          <SectionTitle label="" />
          <TextInput
            label="label.latitude"
            source="metaInfo.address.lat"
            formClassName={classes.name}
            validate={latitudeValidation}
          />
          <TextInput
            label="label.longitude"
            source="metaInfo.address.lng"
            formClassName={classes.code}
            validate={longitudeValidation}
          />
          <TextInput
            label="label.timezone"
            source="metaInfo.timezoneOffset"
            formClassName={classes.code}
            validate={requiredValidate}
          />
          {/* <SectionTitle label="resources.companies.fieldGroups.contact" />

        <SectionTitle label="" />


        <SectionTitle label="resources.companies.fieldGroups.credential" />


        <SectionTitle label="resources.customers.fieldGroups.password" /> */}
          <SectionTitle label="" />
          {user?.isLDAP ? null : (
            <>
              <PasswordInput
                source="password"
                formClassName={classes.password}
              />
              <PasswordInput
                source="confirm_password"
                formClassName={classes.confirm_password}
              />
            </>
          )}
        </div>
        <TabbedShowLayout
          tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
          className={classes.tab}
        >
          <Tab label="White Label">
            {/* <SectionTitle label="resources.companies.fieldGroups.white_label" /> */}
            <div style={{ display: "inline" }}>
              <TextInput
                fullWidth
                label="label.titleTag"
                source="settings.heading_name"
                validate={requiredValidate}
              />

              <TextInput
                fullWidth
                label="label.header_text"
                source="settings.heading_text"
                validate={requiredValidate}
              />

              {/* <TextInput
          fullWidth
          label="Footer text"
          source="settings.footer_text"
          validate={requiredValidate}
        /> */}

              <FileInput
                source="introScreen"
                label="label.introScreen"
                accept="image/*"
                // placeholder={<p>Drop your file here</p>}
                multiple={false}
                validate={requiredValidate}
              >
                <ImageField source="src" title="title" />
              </FileInput>

              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) =>
                  formData &&
                  formData.introScreen &&
                  !formData.introScreen.src ? (
                    <img
                      style={{
                        width: 350,
                        height: 200,
                        background: "#adadada3",
                      }}
                      src={introScreen}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FileInput
                label="label.logo"
                multiple={false}
                resource="profile"
                source="settings.company_logo"
                accept="image/*"
                validate={requiredValidate}
              >
                <ImageField source="src" title="title" />
              </FileInput>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) =>
                  formData &&
                  formData.settings &&
                  formData.settings.company_logo &&
                  !formData?.settings?.company_logo?.src ? (
                    <img
                      style={{
                        width: 350,
                        height: 200,
                        background: "#adadada3",
                      }}
                      src={companyLogo}
                    />
                  ) : null
                }
              </FormDataConsumer>
            </div>
          </Tab>
          <Tab label="Privacy Policy">
            <div style={{ display: "inline" }}>
              {/* <SectionTitle label="resources.companies.fieldGroups.privacy" /> */}
              <RichTextInput
                label="label.privacy_Policy"
                source="metaInfo.company.policy"
                validate={requiredValidate}
              />
            </div>
          </Tab>
          <Tab label="label.license_of_User">
            {/* <SectionTitle label="resources.companies.fieldGroups.license" /> */}
            <div style={{ display: "inline" }}>
              <RichTextInput
                label="label.license_of_User"
                source="metaInfo.company.license"
                validate={requiredValidate}
              />
            </div>
          </Tab>
          <Tab label="About Us">
            {/* <SectionTitle label="resources.companies.fieldGroups.about" /> */}
            <div style={{ display: "inline" }}>
              <RichTextInput
                label="label.about_us"
                source="metaInfo.company.about"
                validate={requiredValidate}
              />
            </div>
          </Tab>
          <Tab label="News Feed">
            {/* <SectionTitle label="resources.companies.fieldGroups.news_feed" /> */}
            <div style={{ display: "inline" }}>
              <TextInput
                fullWidth
                label="label.title"
                source="newsContent.title"
              />

              <TextInput
                fullWidth
                multiline
                label="label.description"
                source="newsContent.description"
              />

              <FileInput
                label="label.image"
                multiple={false}
                source="newsContent.image"
                accept="image/*"
              >
                <ImageField source="src" title="title" />
              </FileInput>
              <FormDataConsumer>
                {({ formData }: { formData: any }) =>
                  formData &&
                  formData.newsContent &&
                  formData.newsContent.image &&
                  !formData.newsContent.image.src ? (
                    <img
                      style={{
                        width: 350,
                        height: 200,
                        background: "#adadada3",
                      }}
                      src={newsContentImage}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <BooleanInput
                source="isNewsFeed"
                label="label.news_Feed"
                defaultValue={false}
              />
            </div>
          </Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  ) : (
    <Edit
      undoable={false}
      alwaysEnableSaveButton
      title="pos.settings"
      {...props}
    >
      <SimpleForm
        toolbar={<PostEditToolbar />}
        redirect={false}
        validate={validatePasswords}
      >
        <TextInput
          fullWidth
          autoFocus
          source="name"
          formClassName={classes.customSize}
          validate={requiredValidate}
        />
        <SectionTitle label="" />
        <TextInput
          source="phone"
          disabled
          // fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          type="email"
          disabled
          source="email"
          validation={{ email: true }}
          fullWidth
          formClassName={classes.email}
          validate={[required(), email()]}
        />
        <SectionTitle label="" />
        <TextInput
          label="label.contact_phone"
          source="metaInfo.contact.phone"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          label="label.contact_email"
          type="email"
          source="contactEmail"
          validation={{ email: true }}
          fullWidth
          formClassName={classes.email}
          validate={[required(), email()]}
        />
        <SectionTitle label="" />
        <TextInput
          // disabled
          label="label.employee_size"
          source="numberOfMaxUser"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          // disabled
          label="label.visitor_size"
          source="numberOfMaxVisitor"
          formClassName={classes.code}
          validate={requiredValidate}
        />
        <TextInput
          multiline
          label="label.company_Address"
          source="metaInfo.address.lines"
          formClassName={classes.code}
          validate={requiredValidate}
        />
        <SectionTitle label="" />
        <TextInput
          label="label.latitude"
          source="metaInfo.address.lat"
          formClassName={classes.name}
          validate={latitudeValidation}
        />
        <TextInput
          label="label.longitude"
          source="metaInfo.address.lng"
          formClassName={classes.code}
          validate={longitudeValidation}
        />
        <TextInput
          label="label.timezone"
          source="metaInfo.timezoneOffset"
          formClassName={classes.code}
          validate={requiredValidate}
        />
        {/* <SectionTitle label="resources.companies.fieldGroups.contact" />

      <SectionTitle label="" />


      <SectionTitle label="resources.companies.fieldGroups.credential" />


      <SectionTitle label="resources.customers.fieldGroups.password" /> */}
        <SectionTitle label="" />
        {user?.isLDAP ? null : (
          <>
            <PasswordInput source="password" formClassName={classes.password} />
            <PasswordInput
              source="confirm_password"
              formClassName={classes.confirm_password}
            />
          </>
        )}

        <TabbedShowLayout className={classes.tab}>
          <Tab label="White Label">
            {/* <SectionTitle label="resources.companies.fieldGroups.white_label" /> */}
            <TextInput
              fullWidth
              label="label.titleTag"
              source="settings.heading_name"
              validate={requiredValidate}
            />

            <TextInput
              fullWidth
              label="label.header_text"
              source="settings.heading_text"
              validate={requiredValidate}
            />

            {/* <TextInput
        fullWidth
        label="Footer text"
        source="settings.footer_text"
        validate={requiredValidate}
      /> */}

            <FileInput
              source="introScreen"
              label="label.introScreen"
              accept="image/*"
              // placeholder={<p>Drop your file here</p>}
              multiple={false}
              validate={requiredValidate}
            >
              <ImageField source="src" title="title" />
            </FileInput>

            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) =>
                formData &&
                formData.introScreen &&
                !formData.introScreen.src ? (
                  <img
                    style={{ width: 350, height: 200, background: "#adadada3" }}
                    src={introScreen}
                  />
                ) : null
              }
            </FormDataConsumer>
            <FileInput
              label="label.logo"
              multiple={false}
              resource="profile"
              source="settings.company_logo"
              accept="image/*"
              validate={requiredValidate}
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) =>
                formData &&
                formData.settings &&
                formData.settings.company_logo &&
                !formData?.settings?.company_logo?.src ? (
                  <img
                    style={{ width: 350, height: 200, background: "#adadada3" }}
                    src={companyLogo}
                  />
                ) : null
              }
            </FormDataConsumer>
          </Tab>
          <Tab label="Privacy Policy">
            {/* <SectionTitle label="resources.companies.fieldGroups.privacy" /> */}
            <RichTextInput
              label="label.privacy_Policy"
              source="metaInfo.company.policy"
              validate={requiredValidate}
            />
          </Tab>
          <Tab label="label.license_of_User">
            {/* <SectionTitle label="resources.companies.fieldGroups.license" /> */}
            <RichTextInput
              label="label.license_of_User"
              source="metaInfo.company.license"
              validate={requiredValidate}
            />
          </Tab>
          <Tab label="About Us">
            {/* <SectionTitle label="resources.companies.fieldGroups.about" /> */}
            <RichTextInput
              label="label.about_us"
              source="metaInfo.company.about"
              validate={requiredValidate}
            />
          </Tab>
          <Tab label="News Feed">
            {/* <SectionTitle label="resources.companies.fieldGroups.news_feed" /> */}
            <TextInput
              fullWidth
              label="label.title"
              source="newsContent.title"
            />

            <TextInput
              fullWidth
              multiline
              label="label.description"
              source="newsContent.description"
            />

            <FileInput
              label="label.image"
              multiple={false}
              source="newsContent.image"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData }: { formData: any }) =>
                formData &&
                formData.newsContent &&
                formData.newsContent.image &&
                !formData.newsContent.image.src ? (
                  <img
                    style={{ width: 350, height: 200, background: "#adadada3" }}
                    src={newsContentImage}
                  />
                ) : null
              }
            </FormDataConsumer>
            <BooleanInput
              source="isNewsFeed"
              label="label.news_Feed"
              defaultValue={false}
            />
          </Tab>
          <Tab label="SMTP Config">
            <SMTP />
          </Tab>
          <Tab label="Active Directory Config">
            <ActiveDirectoryConfig />
          </Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  );
};

const requiredValidate = [
  required(),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const latitudeValidation = [
  // requiredValidate,
  number(),
  maxValue(90),
  minValue(-90),
];
const longitudeValidation = [
  // requiredValidate,
  number(),
  maxValue(180),
  minValue(-180),
];

export default SettingEdit;

/*import React from 'react';
import { Edit, TextInput, SimpleForm, required } from 'react-admin';

const ProfileEdit = ({ staticContext, ...props }: { staticContext: any, props: any }) => {
  return (
    <Edit id="my-profile" resource="profile" redirect={false} {...props} >
      <SimpleForm>
        <TextInput source="nickname" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;*/
