import React, { useState } from "react";
import {
  TextInput,
  BooleanInput,
  required,
  FormDataConsumer,
} from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ActiveTypeSelectInput from "../../components/ActiveTypeSelectInput";
import { LDAP, OpenId, SAML } from "./index";

type Props = {};

const useStyles = makeStyles({
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
});

const ActiveDirectoryConfig = (props: Props) => {
  const classes = useStyles();
  const [activeTypeObj, setActiveTypeObj] = useState({ id: "ldap" }) as any;

  const activeTypeData = (data: any) => {
    setActiveTypeObj(data);
  };

  return (
    <div>
      <FormDataConsumer>
        {({ formData }: { formData: any }) =>
          formData?.activeDirectory?.isActive && (
            <div>
              <ActiveTypeSelectInput
                dataManage={activeTypeData}
                defaultValue="ldap"
              />
              {activeTypeObj && activeTypeObj.id === "ldap" && (
                <div className={classes.code}>
                  <TextInput
                    label="Domain"
                    source="activeDirectory.domain"
                    validate={requiredValidate}
                    style={{ width: "256px" }}
                  />
                </div>
              )}

              {activeTypeObj && activeTypeObj.id === "saml" ? (
                <SAML />
              ) : activeTypeObj && activeTypeObj.id === "openId" ? (
                <OpenId />
              ) : activeTypeObj && activeTypeObj.id === "ldap" ? (
                <LDAP />
              ) : (
                <LDAP />
              )}
            </div>
          )
        }
      </FormDataConsumer>

      <BooleanInput
        formClassName={classes.name}
        defaultValue={false}
        label="Status"
        source="activeDirectory.isActive"
      />
    </div>
  );
};

const requiredValidate = [required()];

export default ActiveDirectoryConfig;
