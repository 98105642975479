import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    maxWidth: "240px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));

interface State {
  semesters?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  helperText?: boolean;
  labelName?: string;
  courseChange?: any;
  streamChange?: any;
  objSemester?: any;
  emptyValue?: any;
  textWrap?: boolean;
}

const SemesterSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  propsUrl,
  helperText,
  labelName,
  courseChange,
  streamChange,
  objSemester,
  emptyValue,
  textWrap,
}) => {
  const form = useForm();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const searchUrl = "semester/search";
  // let data = [];
  useEffect(() => {
    if (filter && filter.course)
      dataProvider
        .getSearchList(propsUrl ? propsUrl : searchUrl, filter)
        .then((response: any) => {
          response &&
            response.data.forEach((item: any) => {
              item.name = `${item.name}`;
            });
          setState((state) => ({
            ...state,
            semesters: response && response.data,
            // newDeparments: response && response.data
          }));
        });
  }, [filter.course]);

  if (streamChange || courseChange) {
    form.change("semester", undefined);
  }

  useEffect(() => {
    if (objSemester && Object.keys(objSemester).length == 0)
      form.change("semester", undefined);
  }, [filter]);

  const { semesters } = state;
  const check = (value: any) => {
    semesters?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "semester");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Semester"}
      disabled={record && record.mode === "auto" ? true : false}
      source="semester"
      validate={validate}
      choices={semesters}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
      className={textWrap ? classes.root : ""}
      id="SemesterSelectInput"
    />
  );
};

SemesterSelectInput.defaultProps = {
  source: "semester",
  label: "resources.customers.fields.name",
};

export default memo<Props>(SemesterSelectInput);
