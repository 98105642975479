import React from "react";
import { Edit } from "react-admin";
import CommonForm from '../regions/commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';

const RegionEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.regions.page.edit"} {...props} >
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};
export default RegionEdit;
