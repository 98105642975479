import * as React from "react";
import { FC, useState, useEffect, useCallback } from "react";
import { useDataProvider } from "react-admin";
import { FieldProps, Visitor } from "../types";

interface State {
  images?: string;
}
const VisitorLogo: FC<FieldProps<Visitor>> = ({ record }) => {
  const dataProvider = useDataProvider();

  const [state, setState] = useState<State>({});
  const fetchCardsCount = useCallback(
    async (record: any) => {
      await dataProvider
        .callCustomLocalOne("public/employee-image/" + record._id, {})
        .then((response: any) => {
          setState((state) => ({
            ...state,
            images:
              response &&
              response.data &&
              response.data.item &&
              response.data.item.profile_image,
          }));
        });

      setState((state) => ({ ...state }));
    },
    [dataProvider]
  );

  useEffect(() => {
    fetchCardsCount(record);
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps
  const { images } = state;

  return images ? (
    <img
      src={images}
      alt="Employee"
      style={{ width: "100%", height: "inherit" }}
    />
  ) : null;
};

export default VisitorLogo;
