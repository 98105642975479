import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Sidebar, useDataProvider } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "./themes";
import { AppState } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import FireAlertDialogueBox from "../components/FireAlert";

const CustomSidebar = (props: any) => (
  // <div className="right-side-menubar">
  <Sidebar {...props} size={200} />
  // </div>
);

export default (props: any) => {
  const theme = useSelector((state: AppState) =>
    state.theme === "dark" ? darkTheme : lightTheme
  );

  const menuOpen = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

  return (
    // <div className="content-left">
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
      className={!menuOpen ? "content-left" : ""}
    >
      <div className="main-content"> {props.children}</div>
      <FireAlertDialogueBox />
    </Layout>

    // </div>
  );
};
