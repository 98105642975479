import { AttendanceIcon } from "../svgIcon";
import TeamAttendanceList from "./TeamAttendanceList";
import { TeamAttendanceCommon } from "./teamAttendanceCommon";

export default {
  list: TeamAttendanceList,
  create: TeamAttendanceCommon,
  edit: TeamAttendanceCommon,
  icon: AttendanceIcon,
};
