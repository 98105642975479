import BranchList from "./BranchList";
import BranchCreate from "./BranchCreate";
import BranchEdit from "./BranchEdit";
import { BranchIcon } from "../svgIcon";

export default {
  list: BranchList,
  create: BranchCreate,
  edit: BranchEdit,
  icon: BranchIcon,
};
