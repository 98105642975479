import React, { useState, Fragment } from "react";
import {
  useMediaQuery,
  Theme,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { collegeReportStyle } from "../helperStyle";
import CommonReportForm from "../components/commonReportForm";

const StudentwiseAttendanceReport = (props: any): any => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(collegeReportStyle);
  const classes = useStyles();
  const [responseData, setResponseData] = useState([]);
  const [dateArr, setDateArr] = useState([]);

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <CommonReportForm
        resource={props.resource}
        apiName={"report1"}
        responseData={setResponseData}
        dateArr={setDateArr}
      />

      {(responseData && responseData.length === 0) ||
      responseData === undefined ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smTableDiv : classes.rtable}>
          <React.Fragment>
            <TableContainer
              className={
                isXSmall ? classes.smtableContainer : classes.container
              }
            >
              <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      style={{
                        left: 0,
                      }}
                      className={classes.header}
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      style={{
                        left: 110,
                      }}
                      className={classes.header}
                      rowSpan={2}
                    >
                      Student Name
                    </TableCell>

                    {dateArr
                      ? dateArr &&
                        dateArr.map((date: string, index: number) => {
                          if (date === "Total_present") {
                            return (
                              <TableCell className={classes.dateHeader}>
                                Total Present
                              </TableCell>
                            );
                          } else if (date === "Total_absent") {
                            return (
                              <TableCell className={classes.dateHeader}>
                                Total Absent
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell className={classes.dateHeader}>
                                {new Date(date).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                })}
                                {"-"}
                                {new Date(date).toLocaleDateString("en-GB", {
                                  month: "short",
                                })}
                              </TableCell>
                            );
                          }
                        })
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody style={{ justifyContent: "center" }}>
                  {responseData &&
                    responseData.map((data: any, rowIndex: number) => {
                      return (
                        <Fragment>
                          <TableRow hover role="checkbox">
                            <TableCell
                              align="center"
                              className={classes.dataHeader}
                              style={{
                                left: 0,
                              }}
                            >
                              {rowIndex + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.dataHeader}
                              style={{
                                left: 110,
                              }}
                            >
                              {data.name}
                            </TableCell>
                            {dateArr &&
                              dateArr.map((item: any, index: any) => {
                                return (
                                  <Fragment>
                                    <TableCell
                                      align="center"
                                      className={classes.tableCell}
                                    >
                                      {data[item]}
                                    </TableCell>
                                  </Fragment>
                                );
                              })}
                          </TableRow>
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};
export default StudentwiseAttendanceReport;
