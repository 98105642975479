import * as React from "react";

import Menu, { MenuProps } from "@material-ui/core/Menu";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, NavLink } from "react-router-dom";
import MobileNavBar from "../../components/MobileNavBar";
import { withStyles } from "@material-ui/core/styles";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import { Button, useMediaQuery, Theme } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {},
  name: {
    color: "#000",
    fontSize: "14px",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Poppins",
    color: "#212529",
    fontSize: "16px",
    padding: "5px 10px",
    textTransform: "capitalize",
    fontWeight: "normal",
  },
  expandBox: {
    boxShadow: "none",
  },
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ));

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {isSmall ? (
        <header className="header-area animated " style={{ marginTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12  d-flex align-items-center justify-content-between">
                <div className="logo-area scroll-hide-logo">
                  <a href="index.html">
                    <img
                      src="assets/img/Turnout-logo-white.png"
                      alt="logo"
                      className="img-fluid turnout-logo"
                    />
                  </a>

                  <div className="menu-area d-flex justify-content-end align-items-center">
                    {/* <div className="mobile-menu"></div> */}
                  </div>
                  <div style={{ position: "absolute", right: -8, top: -1 }}>
                    <Button
                      id="demo-positioned-button"
                      aria-controls="demo-positioned-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MenuIcon color="primary" />
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        {" "}
                        <Link className="dropdown-item" to="/">
                          HOME
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        {" "}
                        <Link className="dropdown-item" to="/turnout-system">
                          TURNOUT SYSTEM
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <div className={classes.root}>
                          <ExpansionPanel className={classes.expandBox}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Typography className={classes.heading}>
                                SOLUTION
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography>
                                <Link
                                  className="dropdown-item"
                                  to="/turnout-edge"
                                >
                                  Attendance Management
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/visitor-management"
                                >
                                  Visitor Management
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/access-control"
                                >
                                  Access Control
                                </Link>
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      </MenuItem>

                      <MenuItem onClick={handleClose}>
                        {" "}
                        <Link className="dropdown-item" to="/contact">
                          CONTACT
                        </Link>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="right-bar-icon ml-4 d-flex">
                  <Link to="/login" className="theme-btn btn-login-small ">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="header-area animated">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="logo-area scroll-hide-logo">
                  <a href="index.html">
                    <img
                      src="assets/img/Turnout-logo-white.png"
                      alt="logo"
                      className="img-fluid turnout-logo"
                    />
                  </a>
                </div>

                {/* <div className="logo-area scroll-show-logo">
                <a href="index.html">
                  <img src="assets/img/Turnout-logo.png" alt="logo" />
                </a>
              </div> */}
                <div className="menu-area d-flex justify-content-end align-items-center">
                  <div className="mobile-menu"></div>

                  <div>
                    <div className="main-menu">
                      <nav className="menu-box">
                        <ul className="nav-menu" id="mobile-menu">
                          <li>
                            <NavLink
                              activeClassName="active-nav-link"
                              exact
                              to="/"
                            >
                              Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active-nav-link"
                              exact
                              to="/turnout-system"
                            >
                              Turnout System
                            </NavLink>
                          </li>
                          <li>
                            <a
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              // variant="contained"
                              // color="primary"
                              style={{
                                color: "white",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              onClick={handleClick}
                            >
                              SOLUTION
                              <ExpandMoreIcon />
                            </a>

                            <StyledMenu
                              id="customized-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              // className="dropdown"
                              style={{ padding: 0, top: 2 }}
                            >
                              {/* <StyledMenuItem> */}
                              {/* <ListItemIcon> */}
                              <Link
                                className="dropdown-item"
                                to="/turnout-edge"
                              >
                                Attendance Management
                              </Link>
                              {/* </ListItemIcon> */}
                              {/* <ListItemText primary="Sent mail" /> */}
                              {/* </StyledMenuItem> */}
                              {/* <StyledMenuItem> */}
                              <Link
                                className="dropdown-item"
                                to="/visitor-management"
                              >
                                Visitor Management
                              </Link>
                              {/* </StyledMenuItem> */}
                              {/* <StyledMenuItem> */}
                              <Link
                                className="dropdown-item"
                                to="/access-control"
                              >
                                Access Control
                              </Link>
                              {/* </StyledMenuItem> */}
                            </StyledMenu>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active-nav-link"
                              exact
                              to="/contact"
                            >
                              Contact
                            </NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className="right-bar-icon ml-4 d-flex">
                    <Link to="/login" className="theme-btn btn-login ">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </React.Fragment>
  );
};

export default Header;
