import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from '../branches/commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';

const BranchEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.branches.page.edit"} {...props} >
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};
export default BranchEdit;
