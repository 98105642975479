import * as React from "react";
import { FC } from "react";
import { Button } from "react-admin";

import { FieldProps, Visitor } from "../types";
import AttendanceDialogueBox from "./AttendanceDialogueBox";

const RemarkButtonField = (props: any) => {
  const [dialogueOpen, setDialogueOpen] = React.useState(false);
  const [clickedEmp, setClickedEmp] = React.useState();

  const handleDialogueOpen = (clickedData: any) => {
    setClickedEmp(clickedData);
    setDialogueOpen(true);
  };
  const handleDialogueClose = () => {
    setDialogueOpen(false);
  };

  return (
    <>
      {props?.record?.remark ? (
        props?.record?.remark
      ) : (
        <Button
          label="Remark"
          onClick={() => handleDialogueOpen(props?.record)}
        ></Button>
      )}

      <AttendanceDialogueBox
        open={dialogueOpen}
        activeMenu={"Remarks"}
        handleCloseClick={handleDialogueClose}
        clickedEmp={clickedEmp}
      ></AttendanceDialogueBox>
    </>
  );
};

RemarkButtonField.defaultProps = {
  source: "remark",
  label: "Remarks",
};

export default RemarkButtonField;
