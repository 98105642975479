import * as React from "react";
import { FC, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useDataProvider,
} from "react-admin";
import { FieldProps, Employee } from "../types";
import DeleteIcon from "@material-ui/icons/Delete";
import { toastError, toastInfo } from "../components/toast";

const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right", marginTop: "2em" },
  mobileRoot: { textAlign: "left", marginTop: "2em" },

  name: { display: "inline-block" },
  button: { backgroundColor: "#f44336" },
  updateButton: { marginRight: "0.5em" },

  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
});

interface State {
  device?: string;
}

const CustomActionToolbar: FC<FieldProps<Employee>> = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [newopen, setNewOpen] = useState(false);
  const [newDelete, setDeleteOpen] = useState(false);
  const refresh = useRefresh();
  // const notify = useNotify();
  const [state, setState] = useState<State>({});
  const handleDialogClose = () => setOpen(false);
  const handleUpdateModelsDialogClose = () => setNewOpen(false);
  const handleDeleteSessionModelsDialogClose = () => setDeleteOpen(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  // const handleClick = (record: any) => {
  //   setOpen(true);
  //   setState((state) => ({
  //     ...state,
  //     device: record,
  //   }));
  // };

  // const handleClickUpdateModels = (record: any) => {
  //   setNewOpen(true);
  //   setState((state) => ({
  //     ...state,
  //     device: record,
  //   }));
  // };

  const handleClickDeleteSessionModels = (record: any) => {
    setDeleteOpen(true);
    setState((state) => ({
      ...state,
      device: record,
    }));
  };

  const handleConfirmUpdateModels = (record: any) => {
    // const { device } = state;

    dataProvider
      .callCustomLocal(record && record.edgeURL + "/UpdateModels", {})
      .then((responseLocal: any) => {
        if (
          responseLocal &&
          responseLocal.data &&
          responseLocal.data.success === true
        ) {
          var recordData = {
            isUpToDate: true,
          };

          dataProvider
            .update("devices", { id: record.id, data: recordData })
            .then((responseLocalEMP: any) => {
              if (
                responseLocalEMP &&
                responseLocalEMP.data &&
                responseLocalEMP.data.success === true
              ) {
                toastInfo(responseLocal.data.msg);
                // notify(responseLocal.data.msg);
                refresh();
              } else {
                toastError(responseLocal.data.msg);
                // notify(responseLocal.data.msg, "warning");
              }
            });
        } else {
          toastError(
            responseLocal && responseLocal.data && responseLocal.data.msg
          );
          // notify(
          //   responseLocal && responseLocal.data && responseLocal.data.msg,
          //   "warning"
          // );
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
      });
    setNewOpen(false);
  };

  const handleConfirmDeleteSession = (record: any) => {
    // const { device } = state;
    dataProvider
      .callCustomLocal(`${apiUrl}/sessions/delete`, { company: record.id })
      .then((responseLocal: any) => {
        if (
          responseLocal &&
          responseLocal.data &&
          responseLocal.data.success === true
        ) {
          toastInfo("Device Session has been deleted succesfully");
          // notify("Device Session has been deleted succesfully");
          refresh();
        } else {
          toastError(responseLocal.data.msg);
          // notify(responseLocal.data.msg, "warning");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
      });
    setDeleteOpen(false);
  };

  const handleConfirm = (record: any) => {
    // const { device } = state;

    dataProvider
      .callCustomLocal(record && record.edgeURL + "/restartDeviceConfig", {})
      .then((responseLocal: any) => {
        if (
          responseLocal &&
          responseLocal.data &&
          responseLocal.data.success === true
        ) {
          toastInfo(responseLocal.data.msg);
          // notify(responseLocal.data.msg);
          refresh();
        } else {
          toastError(responseLocal.data.msg);
          // notify(responseLocal.data.msg, "warning");
        }
      });
    setOpen(false);
  };

  // const validateField = ({ values }: { values: any }) => {
  //   const errors = {} as any;
  //   // if (password && confirm_password && password !== confirm_password) {
  //   //   errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  //   // }

  //   return errors;
  // };

  return record ? (
    <div className={isXsmall ? classes.mobileRoot : classes.root}>
      {isXsmall ? (
        <DeleteIcon
          style={{ fontSize: 50, color: "red" }}
          onClick={handleClickDeleteSessionModels}
          className={classes.icon}
        />
      ) : (
        <Button
          variant="contained"
          color="primary"
          label="Delete Session"
          onClick={handleClickDeleteSessionModels}
          className={classes.updateButton}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      )}
      {/* <Button
        variant="contained"
        color="primary"
        label="Update Models"
        onClick={handleClickUpdateModels}
        className={classes.updateButton}
      >
        <UpdateIcon className={classes.icon} />
      </Button> */}
      {/* <Button
        variant="contained"
        color="primary"
        label="Restart"
        onClick={handleClick}
        className={classes.button}
      >
        <SyncIcon className={classes.icon} />
      </Button> */}
      <Confirm
        isOpen={open}
        title="Restart Device"
        content="Are you sure you wish to Restart this Device ?"
        confirm="Confirm"
        cancel="Cancel"
        onConfirm={() => handleConfirm(record)}
        onClose={handleDialogClose}
      />
      <Confirm
        isOpen={newopen}
        title="Update Models"
        content="Are you sure you wish to Update Models in this Device ?"
        confirm="Confirm"
        cancel="Cancel"
        onConfirm={() => handleConfirmUpdateModels(record)}
        onClose={handleUpdateModelsDialogClose}
      />
      <Confirm
        isOpen={newDelete}
        title="Delete Session"
        content="Are you sure you wish to Delete Session ?"
        confirm="Confirm"
        cancel="Cancel"
        onConfirm={() => handleConfirmDeleteSession(record)}
        onClose={handleDeleteSessionModelsDialogClose}
      />
    </div>
  ) : null;
};

// const requiredValidate = [required()];

export default CustomActionToolbar;
