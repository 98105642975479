import { AuthProvider } from "ra-core";
import helperFunc from "./helperFuncs";

const axios = require("axios");

const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;
const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
const user = helperFunc.getUserData();
const clientConfig = user?.company?.customConfiguration;
// const clientConfig = {} as any;
const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const response = axios
      .post(
        `${apiUrl}/auth/signin`,
        { username: username, password: password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response: any) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        const data = response && response.data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", username);
        localStorage.setItem("childCompany", data.item.childCompanyId);
        localStorage.setItem("employeeId", data.item.employeeId);
        const modules = data.item.moduleName ? data.item.moduleName : [];

        data.item.moduleName = [];
        modules.length > 0 &&
          modules.forEach((item: any) => {
            data.item.moduleName.push({
              name: item.name,
              id: item.id,
              moduleId: item?.moduleId,
              subModuleId: item?.subModuleId,
              permissions: [
                {
                  id: "list",
                  permissionLabel: "List",
                },
                {
                  id: "create",
                  permissionLabel: "Create",
                },
                {
                  id: "update",
                  permissionLabel: "Update",
                },
                {
                  id: "delete",
                  permissionLabel: "Delete",
                },
              ],
            });
          });

        const userdata = {
          name: data?.item?.name,
          company: data?.item?.company,
          clientDetail: data?.item?.clientDetail,
          designation: data?.item?.designation,
          type: data?.item?.type?.toLowerCase(),
          permissions: data?.item?.permissions,
          email: data?.item?.email,
          userType: data?.item?.userType,
          employeeType: data?.item?.employeeType,
          modules: data?.item?.moduleName,
          subModules: data?.item?.subModules,
          isReportingManager: data?.item?.isReportingManager,
          isCollege: data?.item?.isCollege,
          isClientConfiguration: data?.item?.isClientConfigration,
          roleModuleName: data?.item?.modules,
          employeeId: data?.item?._id,

          isAttendanceRemark: data?.item?.company?.isAttendanceRemark
            ? data?.item?.company?.isAttendanceRemark
            : data?.item?.isAttendanceRemark,
          isChangeAttendance: data?.item?.company?.isChangeAttendance
            ? data?.item?.company?.isChangeAttendance
            : data?.item?.isChangeAttendance,

          isUnknownAttendance: data?.item?.company?.isUnknownAttendance,
          isDocumentManagement: data?.item?.company?.isDocumentManagement
            ? data?.item?.company?.isDocumentManagement
            : data?.item?.isDocumentManagement,
          isSyncAttendance: data?.item?.isSyncAttendance,
          isLDAP: data?.item?.isLDAP,
        };
        const clientSettings = data?.item?.clientSettings || "";
        if (
          !!data.item.clientSettings &&
          clientSettings !== "" &&
          userdata.type !== "super"
        ) {
          const clientSetting = {
            header_text:
              clientSettings.heading_name !== null
                ? clientSettings["heading_name"]
                : "",
            header_title:
              clientSettings.heading_text !== null
                ? clientSettings["heading_text"]
                : "",
            logo:
              clientSettings.company_logo !== null
                ? uploadApiUrl + clientSettings["company_logo"]
                : "",
            footer_text:
              clientSettings.footer_text !== null
                ? clientSettings["footer_text"]
                : "",
            turnoutDevice:
              clientSettings.turnoutDevice !== null
                ? clientSettings["turnoutDevice"]
                : "",
          };
          localStorage.setItem("clientSettings", JSON.stringify(clientSetting));
          helperFunc.setWhiteLabeling(clientSetting);
        }
        localStorage.setItem("userdata", JSON.stringify(userdata));
        return Promise.resolve();
      })
      .then((data: any) => {
        return Promise.resolve();
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          throw new Error(error.msg);
        });
      });
    return Promise.resolve(response);
  },

  otpRequest: (username: any) => {
    const response = axios
      .post(
        `${apiUrl}/auth/otp-request`,
        { username: username },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response: any) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        const data = response.data;
        localStorage.setItem("token", data.token);
        return Promise.resolve();
      })
      .then((data: any) => {
        return Promise.resolve();
      });
    return Promise.resolve(response);
  },

  resetPassword: (value: any) => {
    const response = axios
      .post(
        `${apiUrl}/auth/reset-password`,
        {
          confirmPassword: value.password,
          otp: value.otp,
          password: value.password,
          username: "admin@rydotinfotech.com",
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response: any) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        const data = response.data;
        localStorage.setItem("token", data.token);
        return Promise.resolve();
      })
      .then((data: any) => {
        return Promise.resolve();
      });
    return Promise.resolve(response);
  },

  logout: () => {
    const loginCheck = process.env.REACT_APP_DATA_PROVIDER_IS_LOGIN_SCREEN;

    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("userdata");
    localStorage.removeItem("clientSettings");

    localStorage.clear();
    return loginCheck ? Promise.resolve("/login") : Promise.resolve("/");
  },
  checkError: (error) => {
    return Promise.resolve(error);
  },
  checkAuth: () => {
    if (localStorage.getItem("username")) {
      const whileLableData = helperFunc.getWhiteLabeling();
      const setWhilteLablr = helperFunc.setWhiteLabeling(whileLableData);
      return Promise.resolve();
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      localStorage.removeItem("userdata");
      localStorage.removeItem("clientSettings");
      return Promise.reject({ redirectTo: "/login" });
      // return Promise.reject(<Redirect to="/login" />);
      // return (<Redirect to="/route/to/redirect" />);
    }
  },

  getPermissions: () => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);
      const role = userdata.type.toLowerCase();
      return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.reject();
  },
  showDetailsToUser: (userType: any) => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);

      if (userdata && userdata.type.toLowerCase() === userType.toLowerCase()) {
        return true;
        // Promise.resolve();
      }
    }

    return false;
    // Promise.reject();
  },
  showDetailsToUserByDesignation: (designationType: any) => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);
      if (
        userdata &&
        userdata.designation.toLowerCase() === designationType.toLowerCase()
      ) {
        return true;
        // Promise.resolve();
      }
    }

    return false;
    // Promise.reject();
  },
  isMenuShow: (moduleName: any, type: any) => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);

      if (userdata && userdata.type.toLowerCase() === "super") {
        return true;
      }
      // if (
      //   userdata &&
      //   userdata.type.toLowerCase() === "company" &&
      //   moduleName !== "employeeTask" &&
      //   moduleName !== "company"
      // ) {
      //   return true;
      // }
      // if (
      //   userdata &&
      //   userdata.type.toLowerCase() === "employee" &&
      //   moduleName === "employeeTask"
      // ) {
      //   return true;
      // }

      const permissions =
        userdata && userdata.permissions ? userdata.permissions : [];
      if (type) {
        if (permissions && permissions.length > 0) {
          if (
            permissions.filter((el: any) => el === `${moduleName}.${type}`)
              .length > 0
          ) {
            return true;
          }
        }
      } else {
        if (permissions && permissions.length > 0) {
          if (
            permissions.filter((el: any) => el === `${moduleName}.list`)
              .length > 0
          ) {
            return true;
          }
        }
      }
    }

    return false;
  },
  isPermitted: (moduleName: any, actionName: string) => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);
      const permissions =
        userdata && userdata.permissions ? userdata.permissions : [];
      if (permissions && permissions.length > 0) {
        if (
          permissions.filter((el: any) => el === `${moduleName}.` + actionName)
            .length > 0
        ) {
          return true;
        }
      }
    }

    return false;
  },
  isActionPermitted: (moduleName: any, actionName: string) => {
    if (localStorage.getItem("userdata")) {
      const localUserData = localStorage.getItem("userdata") || "";
      const userdata = JSON.parse(localUserData);
      if (
        userdata &&
        userdata.type.toLowerCase() === "super" &&
        moduleName !== "employeeTask" &&
        moduleName !== "dashboard"
      ) {
        return true;
      }
      if (
        userdata &&
        userdata.type.toLowerCase() === "company" &&
        moduleName !== "employeeTask" &&
        moduleName !== "company"
      ) {
        return true;
      }
      const permissions =
        userdata && userdata.permissions ? userdata.permissions : [];
      if (permissions && permissions.length > 0) {
        if (
          permissions.filter((el: any) => el === `${moduleName}.` + actionName)
            .length > 0
        ) {
          return true;
        }
      }
    }

    return false;
  },
  isHiddenField: (
    moduleName: any,
    fieldName: string,
    fieldType: any,
    fieldData: any
  ) => {
    const newData = moduleName?.slice(1)?.split("/");
    const user = helperFunc.getUserData();

    console.log("moduleName", moduleName);

    let clientConfig: any;
    if (fieldData && Object.keys(fieldData)?.length > 0) {
      clientConfig = fieldData?.company?.customConfiguration;
    } else {
      clientConfig = user?.company?.customConfiguration;
    }

    var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
    const updatedData =
      newData &&
      newData?.length > 0 &&
      newData?.map((ele: any) => {
        if (checkForHexRegExp.test(ele)) {
          return "create";
        } else return ele;
      });

    console.log("updatedData", updatedData);

    let temp = {} as any;
    updatedData?.forEach((element: any, ind: any) => {
      if (ind === 0) {
        temp =
          clientConfig && clientConfig[element] ? clientConfig[element] : {};
      } else {
        temp = temp[element] ? temp[element] : {};
      }
    });

    return clientConfig &&
      Object.keys(clientConfig)?.length > 0 &&
      temp !== undefined
      ? fieldType && fieldName
        ? temp && temp[fieldName] && temp[fieldName][fieldType]
        : temp
      : false;
  },
};

export default authProvider;
