import React from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { stringify } from "query-string";
import { IconButton } from "@material-ui/core";

type Props = { record?: any };

const HistoryLink = (props: Props) => {
  return (
    <IconButton
      aria-label="Show"
      color="primary"
      component={Link}
      to={{
        pathname: "/notification-history",
        search: stringify({
          // skip: 0,
          // limit: 20,
          // sort: "asc",
          // sortBy: "name",
          filter: JSON.stringify({
            title: props?.record?.title,
          }),
        }),
      }}
      // onClick={handleClick}
    >
      <VisibilityIcon />
    </IconButton>
  );
};

export default HistoryLink;
