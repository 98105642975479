import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  EmployeeLeaveType?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  disabled?: any;
  helperText?: boolean;
  required?: boolean;
}

const LeaveApproverSelectInput: FC<Props> = ({
  filter,
  disabled,
  formClassName,
  helperText,
  required,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("employees/leave-approver", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          EmployeeLeaveType: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { EmployeeLeaveType } = state;

  return (
    <SelectInput
      resettable
      label="Approver"
      className={formClassName ? formClassName : classes.root}
      source="approverId"
      // validate={requiredValidate}
      choices={EmployeeLeaveType}
      disabled={disabled ? disabled : false}
      helperText={helperText}
    />
  );
};
const requiredValidate = [required()];
LeaveApproverSelectInput.defaultProps = {
  source: "leaveApprover",
  label: "resources.customers.fields.name",
};

export default memo<Props>(LeaveApproverSelectInput);
