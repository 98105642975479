import * as React from "react";
import { Edit, SaveButton, Toolbar } from "react-admin";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";
import CommonForm from "./CommonForm";

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButtonWithConfirmation undoable={false} titleSource="name" />
  </Toolbar>
);

const AlertEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Alert"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
      </CommonForm>
    </Edit>
  );
};

export default AlertEdit;
