import * as React from "react";
import { FC, memo, useState } from "react";
import {
  required,
  SelectInput,
  useDataProvider,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  role?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
  value?: number;
  filter?: {};
}

const RoleSelectInput: FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();

  useDeepCompareEffect(() => {
    dataProvider.getSearchList("roles/search", filter).then((response: any) => {
      setState((state) => ({
        ...state,
        role: response && response.data,
      }));
    });
  }, [filter])



  const { role } = state;

  return (
    <SelectInput
      resettable
      label="Role"
      className={classes.root}
      source="role"
      validate={requiredValidate}
      choices={role}
    />
  );
};
const requiredValidate = [required()];
RoleSelectInput.defaultProps = {
  source: "company",
  label: "resources.customers.fields.name",
};

export default memo<Props>(RoleSelectInput);
