import ClientMasterList from "./ClientMasterList";

import { ClientMasterIcon } from "../svgIcon";
import { ClientFormCommon } from "./ClientFormCommon";

export default {
  list: ClientMasterList,
  create: ClientFormCommon,
  edit: ClientFormCommon,
  icon: ClientMasterIcon,
};
