import React, { useState, useEffect } from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  required,
  email,
  RadioButtonGroupInput,
  FormDataConsumer,
  BooleanInput,
  useDataProvider,
} from "react-admin";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ReportingManagerInput from "../components/ReportingManager";
import DesignationSelectInput from "../components/DesignationSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { Employee, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import RegistrationToolbar from "./RegistrationToolbar";
import DeRegistrationToolbar from "./DeRegistrationToolbar";
import { employeeStyle } from "../helperStyle";
import { gender } from "../helperChoice";
import RoleSelectInput from "../components/RoleSelectInput";
import {
  codeValidate,
  mobValidate,
  nameAlphaValidate,
} from "../helperValidate";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const validatePasswords = ({
  marriageDate,
  birthdate,
  email,
  password,
  confirm_password,
}: {
  marriageDate: string;
  birthdate: string;
  email: string;
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;

  if (marriageDate && new Date(marriageDate) > new Date()) {
    errors.marriageDate = "Marriage Date Should be smaller then current date";
  }

  if (birthdate && birthdate > helperFunc.addDays(18 * 365)) {
    errors.birthdate = " Age should be 18 or greater then 18 years";
  }

  if (email) {
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      errors.email = "Must be a valid email";
    }
  }
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {label && translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(employeeStyle);
const CommonForm = (props: any) => {
  const [designationVal, setDesignation] = useState(String);

  const [isLoad, setLoader] = useState(false);
  const [URL, setURL] = useState(String);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const user = helperFunc.getUserData();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const isMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (props.record && props.record.designation) {
      setDesignation(props.record.designationCode);
    }
  }, [props.record]);

  const dataManage = (data: any, type: any) => {
    setDesignation(data.code);
  };

  return (
    <>
      {/* <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/sub-admin/create"
          ? "Update Employee"
          : "Add Employee"}
      </h2> */}
      <SimpleForm {...props} validate={validatePasswords}>
        <Box
          component="div"
          className={
            isXsmall ? classes.mobileInlineButton : classes.inlineButton
          }
        >
          {props && Object.keys(props.record).length !== 0 ? (
            <EmployeeRegistration
              record={props && props.record}
            ></EmployeeRegistration>
          ) : (
            ""
          )}
        </Box>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
              validate={[required()]}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <SectionTitle label="resources.companies.fieldGroups.basic" />

        <TextInput
          autoFocus
          source="name"
          formClassName={classes.name}
          // validate={nameAlphaValidate}
        />
        <TextInput
          source="employeeCode"
          formClassName={isXsmall ? null : classes.code}
          validate={codeValidate}
          label="Employee Code/Username"
        />
        {/* <SectionTitle label="" /> */}
        <TextInput
          label="label.mobile"
          source="phone"
          formClassName={classes.code}
          validate={mobValidate}
        />
        <RadioButtonGroupInput
          validate={requiredValidate}
          source="gender"
          formClassName={isXsmall ? null : classes.name}
          fullWidth
          choices={gender}
        />

        <SectionTitle label="label.employeeRights" />
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <RoleSelectInput
              filter={{ company: formData.company, isActive: true }}
            ></RoleSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <DesignationSelectInput
              dataManage={dataManage}
              validate={[required()]}
              filter={{ company: formData.company, isActive: true }}
            ></DesignationSelectInput>
          )}
        </FormDataConsumer>

        {/* {user.isReportingManager ? ( */}
        <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
          {({ formData }: { formData: any }) => {
            return (
              <ReportingManagerInput
                filter={{
                  company: formData.company,
                  isActive: true,
                  designation: formData.designation,
                }}
              ></ReportingManagerInput>
            );
          }}
        </FormDataConsumer>
        {/* ) : null} */}

        <SectionTitle label="" />

        <SectionTitle label="resources.companies.fieldGroups.credential" />
        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          fullWidth={isXsmall ? null : true}
          formClassName={isXsmall ? null : classes.email}
          validate={[email(), required()]}
        />

        <SectionTitle label="" />
        {user?.isLDAP ? null : (
          <>
            {props && Object.keys(props.record).length !== 0 ? (
              <BooleanInput
                source="reserPass"
                label="Reset Password"
                defaultValue={false}
              />
            ) : null}
            <FormDataConsumer>
              {({ formData }: { formData: any }) =>
                formData && formData.reserPass ? (
                  <PasswordInput
                    source="password"
                    validate={[required()]}
                    formClassName={classes.name}
                  />
                ) : null
              }
            </FormDataConsumer>
            {props && Object.keys(props.record).length === 0 ? (
              <PasswordInput
                source="password"
                validate={[required()]}
                formClassName={classes.name}
              />
            ) : null}
            {props && Object.keys(props.record).length === 0 ? (
              <PasswordInput
                source="confirm_password"
                validate={[required()]}
                formClassName={isXsmall ? null : classes.code}
              />
            ) : null}
          </>
        )}
      </SimpleForm>
    </>
  );
};

const EmployeeRegistration: FC<FieldProps<Employee>> = ({ record }) => {
  return record && record.isRegistred ? (
    <DeRegistrationToolbar record={record} />
  ) : (
    <RegistrationToolbar record={record} />
  );
};

const requiredValidate = [required()];

export default CommonForm;
