import React, { useState, useEffect } from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  required,
  email,
  AutocompleteInput,
  RadioButtonGroupInput,
  maxLength,
  minLength,
  number,
  ImageField,
  ImageInput,
  FormDataConsumer,
  BooleanInput,
  DateInput,
  usePermissions,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import WorkplaceSelectInput from "../components/WorkplaceSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ReportingManagerInput from "../components/ReportingManager";
import RoleSelectInput from "../components/RoleSelectInput";
import EmployeeTypeSelectInput from "../components/EmployeeTypeSelectInput";
import DepartmentSelectInput from "../components/DepartmentSelectInput";
import DesignationSelectInput from "../components/DesignationSelectInput";
import ZoneSelectInput from "../components/ZoneSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import BranchSelectInput from "../components/BranchSelectInput";
import RegionSelectInput from "../components/RegionSelectInput";
import CitiesSelectInput from "../components/CitiesSelectInput";
import FullNameField from "./FullNameField";
import EmployeeAllotment from "../workplaces/EmployeeAllotment";
import MultiSelectZoneInput from "../components/MultiSelectZoneInput";
import MultiSelectRegionInput from "../components/MultiSelectRegionInput";
import MultiSelectStateInput from "../components/MultiSelectStateInput";
import MultiSelectCityInput from "../components/MultiSelectCityInput";
import MultiSelectBranchInput from "../components/MultiSelectBranchInput";
import CountrySelectInput from "../components/CountrySelectinput";
import StateSelectInput from "../components/StateSelectInput";
import ReportingManagerSelectInput from "../components/ReportingManagerSelectInput";
import { Employee, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import RegistrationToolbar from "./RegistrationToolbar";
import DeRegistrationToolbar from "./DeRegistrationToolbar";
import { employeeStyle } from "../helperStyle";
import { gender, bloodGroup, maritalStatus } from "../helperChoice";
// import { DateInput } from "react-admin-date-inputs2";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import authProvider from "../authProvider";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const validatePasswords = ({
  marriageDate,
  birthdate,
  email,
  password,
  confirm_password,
}: {
  marriageDate: string;
  birthdate: string;
  email: string;
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;

  if (marriageDate && new Date(marriageDate) > new Date()) {
    errors.marriageDate = "Marriage Date Should be smaller then current date";
  }

  if (birthdate && birthdate > helperFunc.addDays(18 * 365)) {
    errors.birthdate = " Age should be 18 or greater then 18 years";
  }

  if (email) {
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      errors.email = "Must be a valid email";
    }
  }
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {/* {label} */}
      {label && translate(label)}
    </Typography>
  );
};
interface State {
  designationVal?: String;
}
const useStyles = makeStyles(employeeStyle);
const CommonForm = (props: any) => {
  const [designationVal, setDesignation] = useState(String);
  const [selectedChildCompany, setSelectedChildCompany] = useState(String);
  const classes = useStyles();
  const user = helperFunc.getUserData();
  const { permissions } = usePermissions();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

  const isMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  // const isLarge = useMediaQuery<Theme>((theme) =>
  //     theme.breakpoints.down("lg")
  // );
  const isXLarge = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xl")
  );
  useEffect(() => {
    if (props.record && props.record.designation) {
      setDesignation(props.record.designationCode);
    }
  }, [props.record]);

  const dataManage = (data: any, type: any) => {
    setDesignation(data.code);
  };

  return (
    <>
      {/* <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/employees/create"
          ? "Update Employee"
          : "Add Employee"}
      </h2> */}
      <div className="form-content">
        {" "}
        <SimpleForm {...props} validate={validatePasswords}>
          <Box
            component="div"
            className={
              isXsmall ? classes.mobileInlineButton : classes.inlineButton
            }
          >
            {props && Object.keys(props.record).length !== 0 ? (
              <EmployeeRegistration
                record={props && props.record}
              ></EmployeeRegistration>
            ) : null}
            {props && Object.keys(props.record).length !== 0 ? (
              <EmployeeAllotment
                type="employee"
                record={props && props.record}
                childCompany={selectedChildCompany}
              ></EmployeeAllotment>
            ) : null}
          </Box>
          <CompanyReferenceInput></CompanyReferenceInput>
          <SectionTitle label="resources.companies.fieldGroups.basic" />
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Employee Name"
          />

          <TextInput
            label="Employee Code"
            source="employeeCode"
            formClassName={isXsmall ? null : classes.code}
            validate={codeValidate}
            autoComplete="off"
          />

          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "gender",
            "isHide"
          ) ? (
            <RadioButtonGroupInput
              validate={requiredValidate}
              source="gender"
              formClassName={isXsmall ? null : classes.code}
              fullWidth
              choices={gender}
            />
          ) : (
            ""
          )}

          <SectionTitle label="" />
          <TextInput
            label="label.mobile"
            source="phone"
            formClassName={classes.name}
            validate={mobValidate}
          />
          <TextInput
            label="label.alternateMobile"
            source="alternatePhone"
            formClassName={isXsmall ? null : classes.code}
            validate={altmobValidate}
          />
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "maritalStatus",
            "isHide"
          ) && (
            <DateInput
              label="Joining Date"
              source="joiningDate"
              validate={[required()]}
              formClassName={isXsmall ? null : classes.code}
              // className={classes.date}
              // options={{
              //   format: "DD/MM/YYYY",
              //   // ampm: true,
              //   clearable: true,
              // }}
              inputProps={{
                min: "1900-08-10",
                max: "3000-08-20",
              }}
              providerOptions={{ utils: MomentUtils }}
            />
          )}

          <SectionTitle label="" />
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "birthdate",
            "isHide"
          ) && (
            <DateInput
              // className={classes.date}
              label="label.dateOfBirth"
              source="birthdate"
              formClassName={isXsmall ? null : classes.name}
              // validate={requiredValidate}
              // options={{
              //   format: "DD/MM/YYYY",
              //   clearable: true,
              // }}
              inputProps={{
                min: "1900-08-10",
                max: "3000-08-20",
              }}
            />
          )}
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "bloodGroup",
            "isHide"
          ) && (
            <AutocompleteInput
              source="bloodGroup"
              formClassName={isXsmall ? null : classes.code}
              choices={bloodGroup}
              // validate={requiredValidate}
            />
          )}
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "maritalStatus",
            "isHide"
          ) && (
            <AutocompleteInput
              source="maritalStatus"
              formClassName={isXsmall ? null : classes.code}
              choices={maritalStatus}
              // validate={
              //   authProvider?.isRequiredField(
              //     "employees",
              //     "create",
              //     "maritalStatus"
              //   ) === true
              //     ? [required()]
              //     : false
              // }
            />
          )}
          <FormDataConsumer
            formClassName={isXsmall ? null : open ? classes.name : classes.code}
          >
            {({ formData }: { formData: any }) =>
              !authProvider?.isHiddenField(
                props?.location?.pathname,
                "maritalStatus",
                "isHide"
              ) && formData.maritalStatus === "married" ? (
                <DateInput
                  reference="employees"
                  source="marriageDate"
                  label="Marriage Date"
                  formClassName={isXsmall ? null : classes.code}
                  validate={[required()]}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                />
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />

          {/* <div className="d-flex"> */}
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "isConfirmEmployee",
            "isHide"
          ) && (
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <div className={classes.name}>
                  <BooleanInput
                    label="Is Confirm Employee"
                    source="isConfirmEmployee"
                    // resource="leaves"

                    // formClassName={classes.name}
                  />
                </div>
              )}
            </FormDataConsumer>
          )}

          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "isConfirmEmployee",
            "isHide"
          ) && (
            <FormDataConsumer
              formClassName={
                isXsmall ? null : open ? classes.inputConfirm : classes.code
              }
            >
              {({ formData }: { formData: any }) =>
                formData.isConfirmEmployee ? (
                  <DateInput
                    // reference="employees"
                    source="confirmationDate"
                    label="Confirmation Date"
                    // formClassName={isXsmall ? null : classes.code}
                    validate={[required()]}
                    options={{
                      format: helperFunc.datePickerFormat(),
                      clearable: true,
                    }}
                    inputProps={{
                      min: "1900-08-10",
                      max: "3000-08-20",
                    }}
                  />
                ) : null
              }
            </FormDataConsumer>
          )}

          {/* <FormDataConsumer>
              {({ formData }: { formData: any }) =>
                formData?.isBasedOnGender?.status && (
                  <div
                    style={{
                      marginLeft: "50px",
                      width: "500px",
                      minWidth: "500px",
                    }}
                  >
                    <AutocompleteArrayInput
                      resettable
                      label="label.gender"
                      source="isBasedOnGender.availableFor"
                      choices={genderArr}
                      fullWidth
                      className="leaveType-input"
                    />
                  </div>
                )
              }
            </FormDataConsumer> */}
          {/* </div> */}
          {/* <Box className="d-flex">
            <BooleanInput
              source="isConfirmEmployee"
              label="Is Confirm Employee"
              defaultValue={false}
            />

            <FormDataConsumer
              formClassName={
                isXsmall ? null : open ? classes.code : classes.code
              }
            >
              {({ formData }: { formData: any }) =>
                formData.isConfirmEmployee ? (
                  <DateInput
                    // reference="employees"
                    source="confirmationDate"
                    label="Confirmation Date"
                    formClassName={isXsmall ? null : classes.code}
                    validate={[required()]}
                    options={{
                      format: helperFunc.datePickerFormat(),
                      clearable: true,
                    }}
                    inputProps={{
                      min: "1900-08-10",
                      max: "3000-08-20",
                    }}
                  />
                ) : null
              }
            </FormDataConsumer>
          </Box> */}

          <SectionTitle label="label.otherDetails" />
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                filter={{ isActive: true, company: formData.company }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>

          <SectionTitle label="" />
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => {
              setSelectedChildCompany(formData.childCompanyId);

              return (
                <DepartmentSelectInput
                  filter={{ company: formData.company, isActive: true }}
                ></DepartmentSelectInput>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
            {({ formData }: { formData: any }) => (
              <RoleSelectInput
                filter={{ company: formData.company, isActive: true }}
              ></RoleSelectInput>
            )}
          </FormDataConsumer>

          <SectionTitle label="label.employeeRights" />
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <DesignationSelectInput
                dataManage={dataManage}
                validate={[required()]}
                filter={{ company: formData.company, isActive: true }}
              ></DesignationSelectInput>
            )}
          </FormDataConsumer>
          {permissions === "super" || user?.isReportingManager ? (
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
              {({ formData }: { formData: any }) => {
                return (
                  <ReportingManagerInput
                    filter={{
                      company: formData.company,
                      isActive: true,
                      designation: formData?.designation?._id
                        ? formData?.designation?._id
                        : formData?.designation,
                    }}
                  ></ReportingManagerInput>
                );
              }}
            </FormDataConsumer>
          ) : null}
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "employeeType",
            "isHide"
          ) && (
            <EmployeeTypeSelectInput
              formClassName={isXsmall ? undefined : classes.code}
              filter={{ isActive: true }}
            ></EmployeeTypeSelectInput>
          )}

          <SectionTitle label="" />
          <FormDataConsumer
            formClassName={
              isXsmall || isSmall ? classes.mobileSelect : classes.selectFix
            }
          >
            {({ formData }: { formData: any }) =>
              designationVal &&
              ["ZM", "RM", "OM/MDM", "OM", "MDM", "FO"].includes(
                designationVal
              ) ? (
                <MultiSelectZoneInput
                  filter={{ isActive: true }}
                ></MultiSelectZoneInput>
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer
            formClassName={
              isXsmall || isSmall ? classes.mobileSelect : classes.select
            }
          >
            {({ formData }: { formData: any }) =>
              designationVal &&
              ["RM", "OM/MDM", "OM", "MDM", "FO"].includes(designationVal) ? (
                <MultiSelectRegionInput
                  filter={{
                    isActive: true,
                    zoneId: formData.dataRights && formData.dataRights.zoneId,
                  }}
                ></MultiSelectRegionInput>
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer
            formClassName={
              isXsmall || isSmall ? classes.mobileSelect : classes.select
            }
          >
            {({ formData }: { formData: any }) =>
              designationVal &&
              ["RM", "OM/MDM", "OM", "MDM", "FO"].includes(designationVal) ? (
                <MultiSelectStateInput
                  filter={{
                    isActive: true,
                    regionId:
                      formData.dataRights && formData.dataRights.regionId,
                  }}
                ></MultiSelectStateInput>
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />
          <FormDataConsumer
            formClassName={
              isXsmall || isSmall ? classes.mobileSelect : classes.selectFix
            }
          >
            {({ formData }: { formData: any }) =>
              designationVal &&
              ["OM/MDM", "OM", "MDM", "FO"].includes(designationVal) ? (
                <MultiSelectCityInput
                  filter={{
                    isActive: true,
                    state: formData.dataRights && formData.dataRights.state,
                  }}
                ></MultiSelectCityInput>
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer
            formClassName={
              isXsmall || isSmall ? classes.mobileSelect : classes.select
            }
          >
            {({ formData }: { formData: any }) =>
              designationVal &&
              ["OM/MDM", "OM", "MDM", "FO"].includes(designationVal) ? (
                <MultiSelectBranchInput
                  filter={{
                    isActive: true,
                    city: formData.dataRights && formData.dataRights.city,
                    designations: formData._id,
                  }}
                ></MultiSelectBranchInput>
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />

          <SectionTitle label="label.employeeLocation" />
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ZoneSelectInput filter={{ isActive: true }}></ZoneSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
            {({ formData }: { formData: any }) => (
              <RegionSelectInput
                filter={{ zoneId: formData.zoneId, isActive: true }}
              ></RegionSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
            {({ formData }: { formData: any }) => (
              <StateSelectInput
                filter={{ regionId: formData.regionId, isActive: true }}
              ></StateSelectInput>
            )}
          </FormDataConsumer>

          <SectionTitle label="" />

          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <CitiesSelectInput
                filter={{ state: formData.state, isActive: true }}
              ></CitiesSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
            {({ formData }: { formData: any }) => (
              <BranchSelectInput
                filter={{
                  city:
                    formData.city && formData.city._id
                      ? formData.city._id
                      : formData.city,
                  isActive: true,
                }}
              ></BranchSelectInput>
            )}
          </FormDataConsumer>

          <SectionTitle label="" />
          <TextInput
            // formClassName={classes.code}
            fullWidth={isXsmall ? null : true}
            // multiline
            label="label.address"
            source="metaInfo.address.lines"
            formClassName={classes.address}
            validate={addressValidate}
          />

          <SectionTitle label="" />
          <SectionTitle label="resources.companies.fieldGroups.credential" />
          <TextInput
            type="email"
            source="email"
            validation={{ email: true }}
            fullWidth={isXsmall ? null : true}
            formClassName={isXsmall ? null : classes.email}
            validate={[email()]}
          />

          <SectionTitle label="" />
          {user?.isLDAP ? null : (
            <>
              {props && Object.keys(props.record).length !== 0 ? (
                <BooleanInput
                  source="reserPass"
                  label="Reset Password"
                  defaultValue={false}
                />
              ) : null}
              <FormDataConsumer>
                {({ formData }: { formData: any }) =>
                  formData && formData.reserPass ? (
                    <PasswordInput
                      source="password"
                      validate={[required()]}
                      formClassName={classes.name}
                    />
                  ) : null
                }
              </FormDataConsumer>
              {props && Object.keys(props.record).length === 0 ? (
                <PasswordInput
                  source="password"
                  validate={[required()]}
                  formClassName={classes.name}
                />
              ) : null}
              {props && Object.keys(props.record).length === 0 ? (
                <div className={isXsmall ? "" : classes.code}>
                  <PasswordInput
                    source="confirm_password"
                    validate={[required()]}
                    formClassName={classes.code}
                  />
                </div>
              ) : null}
            </>
          )}

          <BooleanInput
            source="isActive"
            label="label.status"
            defaultValue={true}
          />
          {!authProvider?.isHiddenField(
            props?.location?.pathname,
            "signatureUpload",
            "isHide"
          ) && (
            <>
              <ImageInput
                formClassName={isXsmall ? classes.imageClass : null}
                style={{ width: "50%" }}
                label="Signature Upload"
                multiple={false}
                source="signatureUpload"
                accept="image/*"
                placeholder={
                  <p>
                    Drop a picture to{" "}
                    <span style={{ color: "blue" }}>upload </span>, or{" "}
                    <span style={{ color: "blue" }}>click </span>to select it.
                  </p>
                }
                classes={{ dropZone: classes.dropZone, p: classes.pTag }}
              >
                <ImageField
                  formClassName={isXsmall ? classes.imageClass : null}
                  source="src"
                  title="title"
                  style={{ width: "100px", height: "100px" }}
                />
              </ImageInput>
              <FormDataConsumer>
                {({ formData }: { formData: any }) =>
                  formData.signatureUpload && !formData.signatureUpload.src ? (
                    <div style={{ width: "100px", height: "100px" }}>
                      <img
                        style={{
                          width: isXsmall ? 150 : 100,
                          height: isXsmall ? 100 : 100,
                        }}
                        src={
                          props && props.record && props.record.signatureUpload
                            ? uploadApiUrl + props.record.signatureUpload
                            : ""
                        }
                      />
                    </div>
                  ) : null
                }
              </FormDataConsumer>
            </>
          )}
        </SimpleForm>
      </div>
    </>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const addressValidate = [
  required(),
  maxLength(200),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const mobValidate = [required(), number()];
// const aadharNoValidate = [required(), maxLength(15), number(), minLength(10)];
const altmobValidate = [number()];
// const EmployeeTitle: FC<FieldProps<Employee>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

const EmployeeRegistration: FC<FieldProps<Employee>> = ({ record }) => {
  return record && record.isRegistred ? (
    <DeRegistrationToolbar record={record} />
  ) : (
    <RegistrationToolbar record={record} />
  );
};

const requiredValidate = [required()];

export default CommonForm;
