import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from '../employeeAllotments/commonForm';
import { SaveButton, Toolbar } from "react-admin";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";
// import { PostEditToolbar } from '../components/PostEditToolbar';
const PostEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton disabled={false} />
        <DeleteButtonWithConfirmation undoable={false} titleSource={props.titleSource ? props.titleSource : "name"} />
    </Toolbar>
)

const EmployeeAllotmentEdit = (props: any) => {
 
  return (
    <Edit undoable={false} title={"resources.employeeAllotments.page.edit"} {...props} >
      <CommonForm toolbar={<PostEditToolbar />}  {...props} />
    </Edit>
  );
};
export default EmployeeAllotmentEdit;
