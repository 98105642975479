import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import {
  useDataProvider,
  showNotification,
  useRefresh,
  useNotify,
} from "react-admin";
import {
  useMediaQuery,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { importStyle } from "../helperStyle";
import CloseIcon from "@material-ui/icons/Close";
import { toastError, toastInfo } from "./toast";
interface State {
  isOpen: false;
  errorData: [];
  responseData: {
    totalCount: 0;
    successCount: 0;
    dataError: 0;
  };
}
const useStyles = makeStyles(importStyle);
const ImportButtonShift = (props: any) => {
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("text-button-file1")
        : null;
  }
  // const version = useVersion();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const [page, setPage] = React.useState(0);
  let [errorData, setErrorData] = useState([]);
  let [responseData, setResponseData] = useState({
    totalCount: 0,
    successCount: 0,
    dataError: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const refresh = useRefresh();
  // const notify = useNotify();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let { resource, label, entryPoint } = props;
  let newres = "";
  if (entryPoint) {
    newres = entryPoint;
    entryPoint = undefined;
  }

  const handleChangeShift = (e: any) => {
    // let { startPoint } = props;
    let file = e.target.files && e.target.files[0];
    let data = new FormData();
    data.append("file", file);
    // container.value = "";
    dataProvider
      .callCustom(newres ? "shift" + "/import" : "shift" + "/import", data)
      .then((responseLocal: any) => {
        data = new FormData();
        if (responseLocal.data.success === true) {
          if (responseLocal.data.dataError > 0) {
            setIsOpen(true);
            setResponseData({
              totalCount: responseLocal.data.totalCount,
              successCount: responseLocal.data.successCount,
              dataError: responseLocal.data.dataError,
            });
            setErrorData(responseLocal.data.mainErrorData);
          } else {
            toastInfo("Data has been imported Successfully.");
            // notify("Data has been imported Successfully.");
          }
          refresh();
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        if (e.target.files) {
          e.target.files[0] = null;
        }
        // e.target.files[0] = null;
        data = new FormData();
        refresh();
      });
    // }
  };
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <>
      <input
        type="file"
        id="text-button-file1"
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleChangeShift}
      />
      <label
        htmlFor="text-button-file1"
        style={{
          display: "inline-flex",

          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          component="span"
          style={{
            padding: "3px 0px 0px 0px",
            fontSize: "13px",
          }}
        >
          <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: 20 }} />
          {/* {!isXSmall ?  */}
          <span style={{ paddingLeft: "0.5em" }}>Shift </span>
          {/* // : null} */}
        </Button>
      </label>
      <Dialog
        fullScreen={fullScreen}
        className={classes.myPopup}
        aria-labelledby="simple-dialog-title"
        onClose={handleClose}
        open={isOpen}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">Shift Data Import</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={classes.close} />
          </IconButton>
        </DialogTitle>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Total :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.totalCount}
            </span>
          </div>
          <div className={classes.label}>
            Success:{" "}
            <span className={classes.label} style={{ color: "green" }}>
              {responseData.successCount}
            </span>
          </div>
          <div className={classes.label}>
            Error :{" "}
            <span className={classes.label} style={{ color: "red" }}>
              {responseData.dataError}
            </span>
          </div>
        </Box>
        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.theadFCol}>Sr no</TableCell>
                  <TableCell className={classes.theadCol}>Name</TableCell>
                  <TableCell className={classes.theadCol}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data: any, index: number) => (
                    <TableRow key={index + 1}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {data ? data.name : ""}
                      </TableCell>
                      <TableCell align="left">
                        {data
                          ? data.reason.map((item: any) => {
                              return <li>{item}</li>;
                            })
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={errorData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </>
  );
};

export default ImportButtonShift;
