import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
  ImageInput,
  ImageField,
  useDataProvider,
  FileInput,
  Button,
  useRedirect,
  FileField,
  Toolbar,
  SaveButton,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { departmentstyles } from "../helperStyle";
import { SelectInput } from "../common";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import helperFunc from "../helperFuncs";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { toastError } from "../components/toast";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import DialogueBox from "../leave/dialogueBox";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(departmentstyles);

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
const CommonForm = (props: any) => {
  const { resource, id, location, basePath } = props;
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = React.useState(false);
  const [index, setIndex] = React.useState(Number);
  const [imageData, setImageData] = React.useState({});

  const [fileData, setFileData] = React.useState();

  const handleClick = (e: any, employeeId: any) => {
    let imgData = new FormData();
    imgData?.append("file", e, e?.name);

    if (employeeId !== undefined) {
      dataProvider
        .callCustom(`employee-document/upload/${employeeId}`, imgData)
        .then((response: any) => {
          setFileData(response?.data?.item?.file);
        })
        .catch((err: any) => console.log(err));
    } else {
      toastError("Please Select Employee");
    }
  };

  const handleViewDocs = (fileUrl: any) => {
    window.open(`${fileUrl}`, "_blank");
  };

  let checkString: any;

  if (props.record.fileUrl?.match(".doc")) {
    checkString = "doc";
  } else if (props.record.fileUrl?.match(".pdf")) {
    checkString = "pdf";
  }

  const saveForm = (value: any) => {
    delete value?.fileUrl;

    const resourcePath =
      location && location.pathname === `/${resource}/create`
        ? `${resource}/create`
        : `${resource}/update/${id}`;
    dataProvider
      .callCustom(resourcePath, {
        ...value,
        fileUrl: fileData ? fileData : props?.record?.fileUrl,
      })
      .then((response: any) => redirect(basePath))
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  const handleOpenClick = (data: any) => {
    setShowDialog(true);
    setImageData(data);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const remove = async (data: any) => {
    props.record.fileUrl = "";
    data.fileUrl = "";
  };

  return (
    <div className="form-content">
      <SimpleForm {...props} save={saveForm}>
        <CompanyReferenceInput></CompanyReferenceInput>

        {helperFunc.getChildCompany() === "true" ? (
          <FormDataConsumer alwaysOn formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                helperText={false}
                filter={{
                  isActive: true,
                  company: formData.company,
                }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>
        ) : null}

        <FormDataConsumer
          formClassName={
            helperFunc.getChildCompany() === "true"
              ? classes.code
              : classes.name
          }
        >
          {({ formData }: { formData: any }) => (
            <EmployeeSelectInput
              filter={{
                isActive: true,
                childCompanyId: formData.childCompanyId,
                company: formData.company,
              }}
              validate={nameValidate}
              source="employeeId"
            ></EmployeeSelectInput>
          )}
        </FormDataConsumer>

        <SectionTitle label="" />

        <div className="d-flex ">
          <SelectInput
            filter={{ isActive: true }}
            label="Category"
            source="documentCategoryId"
            apiName="document-category"
          />

          <div style={{ marginLeft: "32px" }}>
            <SelectInput
              filter={{ isActive: true }}
              label="Document Type"
              source="documentTypeId"
              apiName="document-type"
            />
          </div>
        </div>

        <SectionTitle label="" />
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <FileInput
                multiple={false}
                source="fileUrl"
                label="Images/Documents (Max. 20MB)"
                accept="image/png,image/jpg,image/jpeg,application/pdf,.doc,.docx,.csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                placeholder={
                  <p>
                    Drop a picture to{" "}
                    <span style={{ color: "blue" }}>upload </span>, or{" "}
                    <span style={{ color: "blue" }}>click </span>to select it.
                  </p>
                }
                onChange={(e: any) => handleClick(e, formData?.employeeId)}
                classes={{
                  dropZone: classes.dropZone,
                  p: classes.pTag,
                  removeButton: classes.removeButton,
                }}
              >
                <FileField source="src" title="title" />
              </FileInput>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            let checkString2: any;

            if (formData?.fileUrl?.title?.match(".doc")) {
              checkString2 = "doc";
            } else if (formData?.fileUrl?.title?.match(".pdf")) {
              checkString2 = "pdf";
            }

            return formData.fileUrl ? (
              <div style={{ display: "inline" }}>
                <div style={{ margin: "5px" }} className="card-actions">
                  <div className="card-action-icon">
                    <div>
                      <RemoveCircleOutlineIcon
                        onClick={() => handleOpenClick(formData)}
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </div>

                    <GetAppIcon
                      onClick={() =>
                        helperFunc.imageDownloader(
                          `${
                            formData?.fileUrl?.src
                              ? formData?.fileUrl?.src
                              : uploadApiUrl + formData?.fileUrl
                          }`,
                          formData?.fileUrl?.src
                        )
                      }
                      style={{ color: "blue", cursor: "pointer" }}
                    />
                  </div>
                  {checkString || checkString2 === "doc" ? (
                    <DescriptionIcon
                      style={{
                        width: "100px",
                        height: "100px",
                        color: "#293799",
                      }}
                      // onClick={() => handleViewDocs(formData?.fileUrl)}
                    />
                  ) : checkString || checkString2 === "pdf" ? (
                    <PictureAsPdfIcon
                      style={{
                        width: "100px",
                        height: "100px",
                        color: "#293799",
                      }}
                    />
                  ) : (
                    <img
                      className="action-img"
                      // alt={el}
                      style={{
                        height: "150px",
                        width: "inherit",
                        objectFit: "contain",
                      }}
                      src={
                        formData?.fileUrl?.src
                          ? formData?.fileUrl?.src
                          : uploadApiUrl + formData?.fileUrl
                      }
                    />
                  )}
                </div>
              </div>
            ) : null;
          }}
        </FormDataConsumer>

        {/* <FormDataConsumer formClassName={classes.name}>
      {({ formData }: { formData: any }) =>
        formData && formData.fileUrl && !formData.fileUrl.src ? (
          checkString === "doc" ? (
            <DescriptionIcon
              style={{
                cursor: "pointer",
                width: "100px",
                height: "100px",
                color: "#293799",
              }}
              onClick={() => handleViewDocs(props.record.fileUrl)}
            />
          ) : checkString === "pdf" ? (
            <PictureAsPdfIcon
              style={{
                cursor: "pointer",
                width: "100px",
                height: "100px",
                color: "#293799",
              }}
              onClick={() => handleViewDocs(props.record.fileUrl)}
            />
          ) : (
            <>
              <img
                style={{ width: 350, height: 200 }}
                alt={
                  props && props.record && props.record.fileUrl
                    ? uploadApiUrl + props.record.fileUrl
                    : ""
                }
                onClick={() => handleViewDocs(props.record.fileUrl)}
                src={
                  props && props.record && props.record.fileUrl
                    ? uploadApiUrl + props.record.fileUrl
                    : ""
                }
              />
            </>
          )
        ) : null
      }
    </FormDataConsumer> */}

        <SectionTitle label="" />

        <BooleanInput
          label="label.status"
          source="isActive"
          resource="departments"
          defaultValue={true}
        />

        <DialogueBox
          handleCloseClick={handleCloseClick}
          open={showDialog}
          remove={() => remove(imageData)}
          // allotmentDialog={allotmentDialog}
        />
      </SimpleForm>
    </div>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
