import React, { useState, useEffect } from "react";
import { FC } from "react";
import Webcam from "react-webcam";
import { Loader } from "semantic-ui-react";
import {
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  required,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  useDataProvider,
  useRedirect,
  Button,
  DeleteWithConfirmButton,
  FileInput,
  FileField,
} from "react-admin";
import { Theme, useMediaQuery, Box } from "@material-ui/core";
import { Employee, FieldProps } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import { streamStyle } from "../helperStyle";
import {
  mobValidate,
  nameAlphaValidate,
  SectionTitle,
  validate,
  validateEmail,
} from "../helperValidate";
import { styleData } from "../helperConst";
import moment from "moment";

import { gender, vehicleType } from "../helperChoice";
import { CaptureDialogueBox } from "./captureDialogueBox";
import { OtpDialogueBox } from "./otpDialogueBox";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import SearchableEmployeeSelectInput from "../components/SearchableEmployeeSelectInput";
import { toastError } from "../components/toast";
import helperFunc from "../helperFuncs";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import RegistrationToolbar from "./RegistrationToolbar";
import DeRegistrationToolbar from "./DeRegistrationToolbar";
import SearchableEmployeeDefaultSelectInput from "../components/SearchableEmployeeDefaultSelectInput";
import ChildCompanyDefaultSelectInput from "../components/ChildCompanyDefaultSelectInput";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";
import authProvider from "../authProvider";
// import { uploadApiUrl } from "../dist/config.dev";

const useStyles = makeStyles(streamStyle);

const CommonForm = (props: any) => {
  const { resource, id, location, basePath } = props;
  const [childCompanyChange, setChildCompanyChange] = useState(Boolean);
  const [imgSrc, setImgSrc] = useState() as any;
  const [visitorId, setVisitorId] = useState();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openCaptureDialogue, setOpenCaptureDialogue] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [fileData, setFileData] = React.useState();

  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const [count, setCount] = useState(
    Number(localStorage.getItem("printCount"))
  );
  const redirect = useRedirect();

  const user = helperFunc.getUserData();

  const fromDate = new Date();

  const toDate = moment(new Date());
  toDate.add(3, "h");

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const childCompanyData = (data: any) => {
    setChildCompanyChange(true);
    setTimeout(function () {
      setChildCompanyChange(false);
    }, 1000);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };

  const saveForm = (value: any) => {
    setIsLoad(true);

    const resourcePath =
      location && location.pathname === `/${resource}/create`
        ? `${resource}/create`
        : `${resource}/update/${id}`;
    dataProvider
      .callCustom(resourcePath, { ...value, visitorDocument: fileData })
      .then((response: any) => {
        setIsLoad(false);
        setVisitorId(response?.data?.item?._id);
        if (user.company.isVisitorVerificationRequired === true) {
          return setOpenDialogue(true);
        } else {
          if (user?.company?.isVisitorGatepass === true) {
            saveOtpForm(response?.data?.item?._id);
          } else {
            redirect(basePath);
          }
        }
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  const handleOpenCaptureDialogue = () => {
    setOpenCaptureDialogue(true);
  };

  const saveOtpForm = (data: any) => {
    if (data) {
      localStorage.setItem("printCount", (count + 1).toString());
      window.open(
        `/view-invite-visitor/${data}`,
        "sharegplus",
        "toolbar=0,status=0,height=600,width=1200"
      );
      redirect("list", basePath);
    }
  };

  const handleClick = (e: any, employeeId: any) => {
    let imgData = new FormData();
    imgData?.append("file", e, e?.name);

    if (employeeId !== undefined) {
      dataProvider
        .callCustom(`employee-document/upload/${employeeId}`, imgData)
        .then((response: any) => {
          setFileData(response?.data?.item?.file);
        })
        .catch((err: any) => console.log(err));
    } else {
      toastError("Please Select Employee");
    }
  };

  const handleViewDocs = (fileUrl: any) => {
    window.open(`${uploadApiUrl}${fileUrl}`, "_blank");
  };

  return (
    <>
      {isLoad ? (
        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="form-content">
          <h2
            style={{
              display: "flex",
              fontSize: 25,
              backgroundColor: "#fff",
              padding: 10,
            }}
          ></h2>
          <Box
            style={{ display: "flex" }}
            component="div"
            className={
              isXsmall ? classes.mobileInlineButton : classes.inlineButton
            }
          >
            {props && Object.keys(props.record).length !== 0 ? (
              <EmployeeRegistration
                record={props && props.record}
              ></EmployeeRegistration>
            ) : null}

            {/* <Button
              variant="contained"
              label="CAPTURE IMAGE"
              onClick={handleOpenCaptureDialogue}
              align="right"
              className={classes.captureButton}
            >
              <CameraAltIcon className={classes.icon} />
            </Button> */}
          </Box>
          {/* <Box
            component="div"
            className={
              isXsmall ? classes.mobileInlineButton : classes.inlineButton
            }
          >
            {props && Object.keys(props.record).length !== 0 ? (
              <EmployeeRegistration
                record={props && props.record}
              ></EmployeeRegistration>
            ) : null}
            {isXsmall ? (
              "Capture Image"
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenCaptureDialogue}
                // className={classes.button}
              >
                Capture Image
              </Button>
            )}
          </Box> */}
          {/* {props && Object.keys(props.record).length !== 0 ? (
            <EmployeeRegistration
              record={props && props.record}
            ></EmployeeRegistration>
          ) : null} */}

          {/* <div className="col-12">
            <div className={isXsmall ? classes.mobileRoot : classes.root}>
              {isXsmall ? (
                "Capture Image"
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCaptureDialogue}
                  className={classes.button}
                >
                  Capture Image
                </Button>
              )}
            </div>
          </div> */}

          <CaptureDialogueBox
            open={openCaptureDialogue}
            setOpenCaptureDialogue={setOpenCaptureDialogue}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
          />

          <div className="d-flex w-100 img-form">
            <SimpleForm {...props} save={saveForm}>
              <div>
                <CompanyReferenceInput></CompanyReferenceInput>
                <div style={{ display: "flex" }}>
                  {helperFunc.getChildCompany() === "true" && (
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.name}
                    >
                      {({ formData }: { formData: any }) => (
                        <ChildCompanyDefaultSelectInput
                          filter={{ isActive: true, company: formData.company }}
                          validate={[required()]}
                          dataManage={childCompanyData}
                          // defaultValue={user?.company?._id}
                        ></ChildCompanyDefaultSelectInput>
                      )}
                    </FormDataConsumer>
                  )}
                  <div
                    className={
                      helperFunc.getChildCompany() === "true"
                        ? classes.code
                        : classes.name
                    }
                  >
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.code}
                    >
                      {({ formData }: { formData: any }) => {
                        return location &&
                          location.pathname === `/${resource}/create` ? (
                          <SearchableEmployeeDefaultSelectInput
                            source="invitedEmployeeId"
                            label="Meeting With"
                            filter={{
                              isActive: true,
                              childCompanyId: formData.childCompanyId,
                            }}
                            childCompanyChange={childCompanyChange}
                            validate={[required()]}
                            className={classes.ml32}
                            defaultValue={
                              formData.childCompanyId === undefined
                                ? user?.employeeId
                                : ""
                            }
                          ></SearchableEmployeeDefaultSelectInput>
                        ) : (
                          <SearchableEmployeeSelectInput
                            source="invitedEmployeeId"
                            label="Meeting With*"
                            filter={{
                              isActive: true,
                              childCompanyId: formData.childCompanyId,
                            }}
                          ></SearchableEmployeeSelectInput>
                        );
                      }}
                    </FormDataConsumer>
                  </div>
                </div>

                <SectionTitle label="" />

                <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
                  <TextInput
                    source="name"
                    label="Visitor Name"
                    formClassName={classes.name}
                    validate={nameAlphaValidate}
                    helperText={false}
                  />
                  <div style={isSmall || isXsmall ? undefined : styleData}>
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.code}
                    >
                      {({ formData }: { formData: any }) => (
                        <TextInput
                          label="label.mobile"
                          source="phone"
                          formClassName={classes.code}
                          validate={mobValidate}
                        />
                      )}
                    </FormDataConsumer>
                  </div>
                  <div style={isSmall || isXsmall ? undefined : styleData}>
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.code}
                    >
                      {({ formData }: { formData: any }) => (
                        <TextInput
                          label="Company Email Address"
                          source="email"
                          formClassName={classes.code}
                          validate={validateEmail}
                        />
                      )}
                    </FormDataConsumer>
                  </div>
                </div>
                <div className={isSmall ? classes.inlineDiv : classes.flexCol}>
                  <TextInput
                    label="Company"
                    source="from"
                    formClassName={classes.name}
                    validate={[required()]}
                  />

                  {authProvider?.isHiddenField(
                    props?.location?.pathname,
                    "totalPerson",
                    "isHide"
                  ) === false && (
                    <div style={isSmall || isXsmall ? undefined : styleData}>
                      <NumberInput
                        source="totalPerson"
                        label="Total No Of Person"
                        formClassName={classes.code}
                        // validate={validate}
                        helperText={false}
                      />
                    </div>
                  )}
                </div>

                {authProvider?.isHiddenField(
                  props?.location?.pathname,
                  "vehicleType",
                  "isHide"
                ) ? (
                  <>
                    <BooleanInput
                      source="isWithVehicle"
                      label="Is Carry Vehicle"
                      defaultValue={false}
                    />
                    {/* <div style={isSmall || isXsmall ? undefined : styleData}> */}
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.name}
                    >
                      {({ formData }: { formData: any }) =>
                        formData?.isWithVehicle === true ? (
                          <TextInput
                            label="License Number"
                            source="vehicleNumber"
                            formClassName={classes.code}
                            validate={[required()]}
                            // className={isXsmall ? "" : "ml-5"}
                          />
                        ) : (
                          ""
                        )
                      }
                    </FormDataConsumer>
                    {/* </div> */}
                  </>
                ) : (
                  <div
                    className={isSmall ? classes.inlineDiv : classes.flexCol}
                  >
                    <FormDataConsumer
                      formClassName={isXsmall ? null : classes.name}
                    >
                      {({ formData }: { formData: any }) => (
                        <RadioButtonGroupInput
                          source="vehicleType"
                          choices={vehicleType}
                          helperText={false}
                          // validate={validate}
                          style={{ width: "290px" }}
                        />
                      )}
                    </FormDataConsumer>
                    <div style={isSmall || isXsmall ? undefined : styleData}>
                      <FormDataConsumer
                        formClassName={isXsmall ? null : classes.name}
                      >
                        {({ formData }: { formData: any }) =>
                          formData?.vehicleType === "fourWheeler" ||
                          formData?.vehicleType === "twoWheeler" ? (
                            <TextInput
                              label="Vehicle Number"
                              source="vehicleNumber"
                              formClassName={classes.code}
                              validate={[required()]}
                              // className={isXsmall ? "" : "ml-5"}
                            />
                          ) : (
                            ""
                          )
                        }
                      </FormDataConsumer>
                    </div>
                  </div>
                )}

                {authProvider?.isHiddenField(
                  props?.location?.pathname,
                  "gender",
                  "isHide"
                ) ? (
                  ""
                ) : (
                  <div>
                    <RadioButtonGroupInput
                      source="gender"
                      formClassName={isXsmall ? null : classes.name}
                      fullWidth
                      choices={gender}
                      helperText={false}
                      validate={validate}
                      style={{ width: "280px" }}
                    />
                  </div>
                )}
                <SectionTitle label="Entry Validity Duration" />

                <div className={isSmall ? classes.inlineDiv : classes.flexCol}>
                  <FormDataConsumer
                    formClassName={isXsmall ? null : classes.name}
                  >
                    {({ formData }: { formData: any }) => (
                      <DateTimeInput
                        source="fromDate"
                        resource="visitor-qr-code"
                        formClassName={classes.name}
                        validate={validate}
                        defaultValue={fromDate}
                      />
                    )}
                  </FormDataConsumer>
                  <div style={isSmall || isXsmall ? undefined : styleData}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <DateTimeInput
                          source="toDate"
                          resource="visitor-qr-code"
                          defaultValue={toDate}
                          // className={classes.employee}
                          validate={validate}
                        />
                      )}
                    </FormDataConsumer>
                  </div>
                </div>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => (
                    <TextInput
                      source="purpose"
                      resource="visitor-qr-code"
                      label="Purpose"
                      className={isSmall || isXsmall ? "" : classes.semiWidth}
                      validate={[required()]}
                    />
                  )}
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => (
                    <TextInput
                      source="remarks"
                      resource="visitor-qr-code"
                      label="Remarks"
                      className={isSmall || isXsmall ? "" : classes.semiWidth}
                    />
                  )}
                </FormDataConsumer>

                <BooleanInput
                  source="isApprovedRequired"
                  label="Approval Required"
                  hidden
                  defaultValue={
                    authProvider?.isHiddenField(
                      location?.pathname,
                      "isApprovedRequired",
                      "defaultValue"
                    )
                      ? true
                      : false
                  }
                />
              </div>
              <SectionTitle label="" />
              {!authProvider?.isHiddenField(
                props?.location?.pathname,
                "imageSrc",
                "isHide"
              ) && (
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => {
                    return (
                      <FileInput
                        multiple={false}
                        source="visitorDocument"
                        label="Images/Documents (Max. 20MB)"
                        accept="image/png, image/jpeg,application/pdf,.doc,.docx"
                        placeholder={
                          <p>
                            Drop a picture to{" "}
                            <span style={{ color: "blue" }}>upload </span>, or{" "}
                            <span style={{ color: "blue" }}>click </span>to
                            select it.
                          </p>
                        }
                        onChange={(e: any) =>
                          handleClick(e, formData?.invitedEmployeeId)
                        }
                        classes={{
                          dropZone: classes.dropZone,
                          p: classes.pTag,
                        }}
                      >
                        <FileField source="" title="title" />
                      </FileInput>
                    );
                  }}
                </FormDataConsumer>
              )}

              {!authProvider?.isHiddenField(
                props?.location?.pathname,
                "imageSrc",
                "isHide"
              ) && (
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) =>
                    formData &&
                    formData.visitorDocument &&
                    !formData.visitorDocument.src ? (
                      <img
                        style={{ width: 350, height: 200 }}
                        onClick={() =>
                          handleViewDocs(props.record.visitorDocument)
                        }
                        src={
                          props?.record?.visitorDocument
                            ? uploadApiUrl + props.record.visitorDocument
                            : ""
                        }
                      />
                    ) : null
                  }
                </FormDataConsumer>
              )}
            </SimpleForm>

            <div className="img-wrapper">
              {imgSrc && (
                <div className="captured-img">
                  <img
                    width="200"
                    height="250"
                    src={imgSrc}
                    alt="No Visitor Image Found"
                  />
                </div>
              )}
              <div className="save-bg"></div>
            </div>
          </div>

          <OtpDialogueBox
            open={openDialogue}
            close={handleDialogueClose}
            id={id ? id : visitorId}
            basePath={basePath}
            saveForm={saveOtpForm}
          />
          {/* ) : user.isVisitorGatepass === true ? (
            saveOtpForm()
          ) : (
            ""
          )} */}
        </div>
      )}
    </>
  );
};

const EmployeeRegistration: FC<FieldProps<Employee>> = ({ record }) => {
  return record && record.isRegistred ? (
    <DeRegistrationToolbar record={record} />
  ) : (
    <RegistrationToolbar record={record} />
  );
};

export default CommonForm;
