import * as React from "react";
import { FC, memo, useState } from "react";

import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { FieldProps, Visitor, Company } from "../types";
import helperFunc from "../helperFuncs";
import useDeepCompareEffect from "use-deep-compare-effect";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  company?: Company[];
}

interface Props {
  helperText?: boolean;
  labelName?: string;
}

interface Props extends FieldProps<Visitor> {}

const CompanyReferenceInput: FC<Props> = ({
  helperText,
  record,
  labelName,
}) => {
  const form = useForm();
  const user = helperFunc.getUserData();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  let filter = {
    isActive: true,
  };

  useDeepCompareEffect(() => {
    if (user && user.type === "super") {
      dataProvider
        .getSearchList("companies/search", { filter })
        .then((response: any) => {
          setState((state: any) => ({
            ...state,
            company: response && response.data,
            data: true,
          }));
          if (record) {
            response.data?.map((data: any) => {
              if (data.id === record.company) {
                // localStorage.setItem("turnoutDevice", data.turnoutDevice);
                return form.change("turnoutDevice", data.turnoutDevice);
              }
            });
          }
          if (record) {
            company?.map((data: any) => {
              if (data.id === record.company) {
                // localStorage.setItem("turnoutDevice", data.turnoutDevice);
                return form.change("turnoutDevice", data.turnoutDevice);
              }
            });
          }
        });
    }
  }, [record, dataProvider]);
  const { company } = state;
  if (company) {
    localStorage.setItem("companyId", company && company[0]._id);
  }
  const check = (value: any) => {
    company?.map((data: any) => {
      if (data.id === value.value) {
        // localStorage.setItem("turnoutDevice", data.turnoutDevice);
        return form.change("turnoutDevice", data.turnoutDevice);
      }
    });
  };

  return user && user.type === "super" ? (
    <SelectInput
      resettable
      disabled={record && record.mode === "auto" ? true : false}
      onChange={(value: any) => (
        form.change("employee", undefined), check(value.target)
      )}
      className={classes.root}
      label={labelName ? labelName : "Company"}
      source="company"
      helperText={helperText}
      choices={company}
      validate={requiredValidate}
      id="CompanyReferenceInput"

      // defaultValue={company && company[0]._id}
    />
  ) : null;
};
const requiredValidate = [required()];
CompanyReferenceInput.defaultProps = {
  source: "company",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CompanyReferenceInput);
