import { DepartmentIcon, DocumentManagementIcon } from "../svgIcon";
import { DocumentManagementCommon } from "./DocumentManagementCommon";
import DocumentManagementList from "./DocumentManagementList";

export default {
  list: DocumentManagementList,
  create: DocumentManagementCommon,
  edit: DocumentManagementCommon,
  icon: DocumentManagementIcon,
};
