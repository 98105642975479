import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  AutocompleteInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import RichTextInput from "ra-input-rich-text";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { emailTempstyles } from "../helperStyle";
import { templateType } from "../helperChoice";
import helperFunc from "../helperFuncs";
// const SectionTitle = ({ label }: { label: string }) => {
//     const translate = useTranslate();

//     return (
//         <Typography variant="h6" gutterBottom>
//             {translate(label)}
//         </Typography>
//     );
// };

const useStyles = makeStyles(emailTempstyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/email-template/create"
          ? "Update Email Template"
          : "Add Email Template"}
      </h2>
      <SimpleForm {...props}>
        {/* <SectionTitle label="resources.departments.page.edit" /> */}
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="email-template"
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Email Template Name"
          />
          <AutocompleteInput
            source="code"
            formClassName={isXsmall ? classes.name : classes.code}
            choices={templateType}
            validate={requiredValidate}
            // format={helperFunc.codeFormatter}
            // style={{ width: '220px' }}
          />
          <TextInput
            source="subject"
            resource="email-template"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
          />
        </div>
        <div style={{ display: "inline" }}>
          <RichTextInput
            label="Description"
            source="description"
            validate={requiredValidate}
            formClassName={classes.email}
          />
        </div>
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="email-template"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.email-template.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        resource="email-template"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Email Template Name"
      />
      <AutocompleteInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        choices={templateType}
        validate={requiredValidate}
        // format={helperFunc.codeFormatter}
        // style={{ width: '220px' }}
      />
      <TextInput
        source="subject"
        resource="email-template"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
      />
      {/* <div style={{ display: 'inline' }}> */}

      <RichTextInput
        fullHeight
        label="Description"
        source="description"
        validate={requiredValidate}
        formClassName={classes.address}
      />
      {/* </div> */}
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="email-template"
        defaultValue={true}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
// const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
