import React, { useState } from "react";
import {
  useDataProvider,
  FormDataConsumer,
  DateInput,
  TopToolbar,
  SelectInput,
} from "react-admin";
import {
  Box,
  Button,
  useMediaQuery,
  Theme,
  useTheme,
  Drawer,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Form } from "react-final-form";
import { useForm } from "react-final-form-hooks";
import { toastError } from "../components/toast";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { isActive } from "../helperChoice";
import helperFunc from "../helperFuncs";
import LeaveTypeSelectInput from "../components/LeaveTypeSelectInput";

const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const FilterReport = ({
  open,
  setOpen,
  filterParams,
  setFilterParams,
  handleDrawerOpen,
  page,
  rowsPerPage,
  count,
}: any) => {
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const onSubmit = (value: any) => {
    setFilterParams({
      ...filterParams,
      filter: {
        employee: value?.employee,
        time_from: value?.time_from
          ? helperFunc.getUtcDate(value?.time_from)
          : helperFunc.getUtcStartOfMonthDate(),
        time_to: value?.time_to
          ? helperFunc.getUtcDate(value?.time_to)
          : helperFunc.getUtcDate(new Date()),
        childCompanyId: value.childCompanyId,
        isActive: value?.isActive === "false" ? false : true,
      },
    });
    handleDrawerClose();
  };
  const validate: any = () => {};
  const useStyles = makeStyles((theme) => ({
    tableCell: { border: "solid 2px" },
    tableHeadCell: { border: "solid 2px", textAlign: "center" },
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    filter: {
      justifyContent: "flex-start",
    },
    smCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.8125rem !important",
    },
    lgCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.850rem !important",
      alignContent: "right !important",
    },
    exportIcon: {
      fontSize: 20,
    },
    csvSpan: { paddingLeft: "0.5em" },
  }));
  const theme = useTheme();
  const classes = useStyles();

  const { form, handleSubmit } = useForm({ onSubmit, validate });
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const drawerWidth = 400;

  const dataProvider = useDataProvider();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetFilter = (e: any, data: any) => {
    data.time_from = helperFunc.getUtcStartOfMonthDate();
    data.time_to = helperFunc.getUtcDate(new Date());
    data.isActive = "";
    form.change("time_from", helperFunc.getUtcStartOfMonthDate());
    form.change("time_to", helperFunc.getUtcDate(new Date()));
    form.change("isActive", "");
    form.change("hiddenVal", "valueChange");
    setFilterParams({
      skip: page,
      limit: rowsPerPage,
      sortBy: "name",
      sort: "asc",
      filter: {
        time_from: helperFunc.getUtcStartOfMonthDate(),
        time_to: helperFunc.getUtcDate(new Date()),
      },
    });
    setEmptyValue(true);
    setTimeout(function () {
      setEmptyValue(false);
    }, 1000);
    handleDrawerClose();
  };
  const exporterDetails = () => {
    dataProvider
      .callCustom("employee-leave-balance-report/export", {
        ...filterParams,
        limit: count,
      })
      .then((response: any) => {
        if (response && response.data && response.data.fileName) {
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  return (
    <>
      <div className="employee-leave-report">
        <TopToolbar>
          <div style={{ paddingRight: "15px", top: 0 }}>
            <Button
              color="primary"
              component="span"
              onClick={() => handleDrawerOpen()}
            >
              <FilterListIcon />
              {"Filter"}
            </Button>
            <Button
              className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
              color="primary"
              component="span"
              onClick={exporterDetails}
            >
              <GetAppIcon className={classes.exportIcon} />
              {!isSmall ? (
                <span className={classes.csvSpan}>Export</span>
              ) : null}
            </Button>
          </div>
        </TopToolbar>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className="employee-filter">
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => handleDrawerClose()}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            <Form onSubmit={onSubmit}>
              {(props: any) => (
                <form onSubmit={props.handleSubmit}>
                  <Box component="div">
                    <CompanyReferenceInput></CompanyReferenceInput>
                  </Box>

                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                            emptyValue={{ emptyValue }}
                            helperText={false}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <EmployeeSelectInput
                            helperText={false}
                            filter={{
                              company: formData.company,
                              isActive: true,
                              childCompanyId: formData.childCompanyId,
                            }}
                            emptyValue={{ emptyValue }}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  {/* <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <LeaveTypeSelectInput
                        // required={false}
                        helperText={false}
                        filter={{ isActive: true }}
                      />
                    </Box>
                  </Box> */}
                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <SelectInput
                        // allowEmpty={true}
                        label="label.isActive"
                        source="isActive"
                        choices={isActive}
                        helperText={false}
                        resettable
                        defaultValue={true}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            source="time_from"
                            label="label.fromDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                            defaultValue={filterParams?.filter?.time_from}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            // initialValue=""
                            source="time_to"
                            label="label.toDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                            defaultValue={filterParams?.filter?.time_to}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <FormDataConsumer formClassName={classes.root} alwaysOn>
                    {({ formData }: any) => (
                      <div className="actionbutton">
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          onSubmit={() => handleSubmit}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={(e: any) => resetFilter(e, formData)}
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </FormDataConsumer>
                </form>
              )}
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default FilterReport;
