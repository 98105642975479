import React, { useState } from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTranslate, Title } from "react-admin";
import { toastInfo } from "../components/toast";

type Props = {};

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  phone: { width: 544 },
  address: { display: "inline-block", width: 544, marginLeft: 32 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

const DateSetting = (props: Props) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [state, setState] = useState<State>({});
  interface State {
    format?: number;
  }

  React.useEffect(() => {
    const format: any = localStorage.getItem("dateFormat");
    setState((state) => ({
      ...state,
      format: format,
    }));
  }, [props]);

  const HandleOnChange = (format: any) => {
    toastInfo("Date changed Successfully");
    localStorage.setItem("dateFormat", format.target.value);
    if (format.target.value === "DD/MM/YYYY") {
      localStorage.setItem("pickerFormat", "dd/MM/yyyy");
    }
    if (format.target.value === "MM/DD/YYYY") {
      localStorage.setItem("pickerFormat", "MM/dd/yyyy");
    }
    if (format.target.value === "YYYY/MM/DD") {
      localStorage.setItem("pickerFormat", "yyyy/MM/dd");
    }
    setState((state) => ({
      ...state,
      format: format.target.value,
    }));
  };
  const { format } = state;
  return (
    <Card style={{ padding: "0px !important" }}>
      <Title title={translate("pos.configuration")} />

      <CardContent>
        <div className={classes.label}>{"Date Format"}</div>
        <Select
          onChange={HandleOnChange}
          defaultValue={format}
          value={format ? format : "DD/MM/YYYY"}
        >
          <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
          <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
          <MenuItem value="YYYY/MM/DD">YYYY/MM/DD</MenuItem>
          <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
          <MenuItem value="YYYY-MM-DD">YYYY-MM-DD</MenuItem>
        </Select>
      </CardContent>
    </Card>
  );
};

export default DateSetting;
