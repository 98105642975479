import * as React from "react";
import { FC, memo } from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  required,
  // TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { FieldProps, Visitor, Company } from "../types";
import helperFunc from "../helperFuncs";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));

interface Props extends FieldProps<Visitor> {}

const CompanyReferenceInput: FC<Props> = ({ record }) => {
  const user = helperFunc.getUserData();
  // const classes = useStyles();
  return user.type === "super" ? (
    <ReferenceInput
      source="company"
      label="Company"
      reference="companies"
      validate={requiredValidate}
    >
      <AutocompleteInput optionText={(choice: Company) => `${choice.name}`} />
    </ReferenceInput>
  ) : null;
};
const requiredValidate = [required()];
CompanyReferenceInput.defaultProps = {
  source: "company",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CompanyReferenceInput);
