import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import { Company } from "../types";
import helperFunc from "../helperFuncs";
import CompanyIcon from "@material-ui/icons/Business";
import { useMediaQuery, Theme } from "@material-ui/core";
interface Props {
  companies?: Company[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "50%",
    padding: 16,
  },
  cost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
  },
  mobileCost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
    fontSize: 10,
  },
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

const ListClients: FC<Props> = ({ companies = [] }) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardHeader title={translate("pos.dashboard.chart_clients")} />
      <List dense={true}>
        {companies.map((record) => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/companies/${record.id}`}
          >
            {isXSmall && isSmall ? null : (
              <ListItemAvatar>
                {record.settings.company_logo ? (
                  <Avatar src={record.settings.company_logo.src} />
                ) : (
                  <Avatar />
                )}
              </ListItemAvatar>
            )}
            <ListItemText primary={record.name} secondary={record.email} />
            <ListItemSecondaryAction>
              <span
                className={
                  isXSmall && isSmall ? classes.mobileCost : classes.cost
                }
              >
                {isXSmall && isSmall
                  ? helperFunc.commonDateFormat(record.createdAt)
                  : helperFunc.commonDateFormat(record.createdAt, "HH:mm:ss")}
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/companies",
          }}
          className={classes.link}
          title={`${translate("pos.dashboard.read_more")}`}
        >
          <CompanyIcon style={{ paddingRight: "0.5em" }} />
          {translate("pos.dashboard.read_more")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ListClients;
