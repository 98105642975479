import * as React from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  DateField,
  Filter,
  List,
  Pagination,
  SelectInput,
  downloadCSV,
  ArrayField,
  SingleFieldList,
  ChipField,
  TopToolbar,
  CreateButton,
  ExportButton,
  useDeleteMany,
  Button as ReactButton,
  DateInput,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import DepartmentLinkField from "./DepartmentLinkField";
import MobileGrid from "./MobileGrid";
import jsonExport from "jsonexport/dist";
import { Fragment } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import { type } from "../helperChoice";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import classnames from "classnames";
import ActionDelete from "@material-ui/icons/Delete";
import MultiDeleteTable from "../components/ErrorTable";

const BulkDeleteButton = ({ resource, selectedIds }: any) => {
  const [deleteMany, { data, loading, error }] = useDeleteMany(
    resource,
    selectedIds
  );
  return (
    <React.Fragment>
      <MultiDeleteTable data={data} resource={resource} />
      <ReactButton
        disabled={loading}
        align="right"
        onClick={deleteMany}
        label={"Delete"}
        className={classnames("ra-delete-button")}
        key="button"
      >
        <ActionDelete />
      </ReactButton>
    </React.Fragment>
  );
};

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      // <ReferenceInput source="company" label="label.client" reference="companies">
                      //   <SelectInput
                      //     resettable={true}
                      //     filter={{ isActive: true }}
                      //     optionText={(choice: Company) => `${choice.name}`}
                      //     helperText={false}
                      //   />
                      // </ReferenceInput>
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      source="type"
                      choices={type}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const NotificationFilter = (props: any) => (
  <Filter {...props}>
    {/* <SearchInput source="name" alwaysOn /> */}

    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      isDeleted,
      isActive,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      title,
      description,
      employeeCode,
      employeeName,
      employeeEmail,
      notifyBy,
      status,
      ...dataToExport
    } = record; // omit fields
    dataToExport.title = title;
    dataToExport.description = description;
    dataToExport.employeeName = employeeName;
    dataToExport.employeeCode = employeeCode;
    dataToExport.employeeEmail = employeeEmail;
    dataToExport.notifyBy = notifyBy;
    dataToExport.createdAt = helperFunc.commonDateFormat(createdAt, "HH:mm:ss"); // add a field
  });
  jsonExport(
    recordsForExport,
    {
      headers: ["name", "code", "createdAt", "status"], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Notifications Report"); // download as 'xxxx.csv` file
    }
  );
};
const Employee = (record: any) => (
  <div>
    {record &&
      record.employee &&
      record.employee.map((item: any) => {
        return (
          <span key={item._id} className="chip">
            {item.name}
          </span>
        );
      })}
  </div>
);

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    {/* <ResetViewsButton label="Reset Views" {...props} /> */}
    {/* default bulk delete action */}
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const NotificationList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <List
        className="list-view2"
        undoable={false}
        exporter={exporter}
        {...props}
        filters={
          isSmall ? (
            <NotificationFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <NotificationFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        // aside={<NotificationListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <TextField source="employeeName" label="Name" />
            <TextField source="title" />
            {/* <TextField source="description" /> */}

            {/* <TextField source="employeeCode" label="Code" /> */}
            {/* <TextField source="employeePhone" label="Phone" /> */}
            {/* <TextField source="employeeEmail" label="Email" /> */}

            <ArrayField source="notifyBy" label="Notify By">
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
            <DateField source="createdAt" showTime />
            {/* <ChipField source="notifyBy" /> */}
            {/* {user.type === "super" ? (
              <TextField label="Company" source="company.name" />
            ) : null} */}
            {/* <ArrayField source="employee">
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField> */}
            {/* <Employee /> */}
            {/* 
            <BooleanField source="isSend" label="Is Send" />
            <BooleanField source="isActive" label="Status" /> */}
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default NotificationList;
