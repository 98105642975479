import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, Role } from "../types";

const RoleLinkField: FC<FieldProps<Role>> = (props) =>
  props.record ? (
    <Link to={`/roles/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

RoleLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default RoleLinkField;
