import * as React from "react";
import { useState } from "react";

import {
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  useDataProvider,
  DateInput,
  FormDataConsumer,
  required,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import TeamLeaveLinkField from "./TeamLeaveLinkField";
import jsonExport from "jsonexport/dist";
import { approvalStatus } from "../helperChoice";
import { Fragment } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ReportingManager from "../components/ReportingManager";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import MultipleEmployeeTypeSelectInput from "../components/MultiSelectEmployeeInput";
import LeaveTypeSelectInput from "../components/LeaveTypeSelectInput";

const drawerWidth = 350;
const user = helperFunc.getUserData();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  descWordWrap: {
    overflowWrap: "anywhere",
  },
  toolTipWidth: {
    width: "230px",
    fontSize: "50px",
    padding: 0,
  },
  textMerge: {
    width: "70px",
    height: "auto",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else if (values.fromDate) {
        values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      } else if (values.toDate) {
        values.toDate = moment(values.toDate).format("YYYY-MM-DD");
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
    data.change("fromDate", helperFunc.startOfMonth(0));
    data.change("toDate", helperFunc.addDays(0, "forReport"));
    data.change("hiddenVal", "valueChange");
    setEmptyValue(true);
    setTimeout(function () {
      setEmptyValue(false);
    }, 1000);
    data.handleDrawerClose();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    spacing: {
      overflowWrap: "anywhere",
    },
  }));
  const theme = useTheme();
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={data.filterValues}
            // validate={(values) => {
            //   const errors: any = {};

            //   if (values.toDate && new Date(values.toDate) > new Date()) {
            //     errors.toDate = "To Date must be smaller than current Date";
            //   }

            //   return errors;
            // }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getChildCompany() === "true" ? (
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            helperText={false}
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <MultipleEmployeeTypeSelectInput
                          helperText={false}
                          filter={{
                            company: formData.company,
                            isActive: true,
                            childCompanyId: formData.childCompanyId,
                          }}
                          emptyValue={{ emptyValue }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <LeaveTypeSelectInput
                          filter={{
                            isActive: true,
                            childCompanyId: formData.childCompanyId,
                          }}
                          isFilter={true}
                          helperText={false}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                {/* <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      label="label.approvalStatus"
                      source="isApprove"
                      choices={approvalStatus}
                      helperText={false}
                    />
                  </Box>
                </Box> */}

                {/* <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {" "}
                    {user?.isReportingManager ? (
                      <FormDataConsumer
                      // formClassName={isXsmall ? null : classes.code}
                      >
                        {({ formData }: { formData: any }) => {
                          return (
                            <ReportingManager
                              filter={{
                                company: formData.company,
                                isActive: true,
                              }}
                            ></ReportingManager>
                          );
                        }}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box> */}

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root}>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          // defaultValue={helperFunc.startOfMonth(0)}
                          validate={[required()]}
                          source="fromDate"
                          label="label.fromDate"
                          alwaysOn
                          helperText={false}
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          // defaultValue={helperFunc.addDays(1, "forReport")}
                          validate={[required()]}
                          source="toDate"
                          label="label.toDate"
                          alwaysOn
                          helperText={false}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    // filters,
    // showFilter,
    // displayedFilters,
    handleDrawerOpen,
    // open
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      /> */}
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const LeaveFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeName" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const exporter = (records: any) => {
  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };

  const recordsForExport = records.map((record: any) => {
    const {
      name,
      leaveType,
      leaveDays,
      approvedBy,
      mailFrom,
      subject,
      fromDate,
      rejectBy,
      description,
      toDate,
      notifyTo,
      sandwichRule,
      isDeleted,
      isActive,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      employee,
      type,
      status,
      reason,
      isApprove,
      isApproved,
      halfLeaveType,
      ...dataToExport
    } = record; // omit fields

    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["Name"] = employee && employee.name; // add a field
    dataToExport["Leave Type"] = leaveType && leaveType.leaveName;
    dataToExport["Half Leave Type"] = halfLeaveType;
    dataToExport["Description"] = description;
    dataToExport["From Date"] = helperFunc.commonDateFormat(fromDate); // add a field
    dataToExport["To Date"] = helperFunc.commonDateFormat(toDate); // add a field
    dataToExport["Reason"] = description;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Approval Status"] = approvalStatusLeave[isApprove]; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Team Leave Report"); // download as 'xxxx.csv` file
    }
  );
};

const PostPanel = (props: any) => {
  const params = {
    // filter: {
    leaveType: props.record.leaveType._id,
    employee: props.record.employee._id,
    // },
  };
  const dataProvider = useDataProvider();
  const [history, setHistory] = React.useState({
    success: false,
    count: 0,
    list: [],
  });
  const [showData, setShowData] = React.useState(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const leaveStatus: any = {
    credit: "Credit",
    monthlyCredit: "Monthly Credit",
    initialCredit: "Initial Credit",
    debit: "Debit",
    pending: "Pending",
    cancel: "Cancel",
    reject: "Reject",
  };

  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };
  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };
  const classes = useStyles();
  React.useEffect(() => {
    if (showData === false) {
      dataProvider
        .callCustom("leave-balance-history/leave-Wise-History", params)
        .then((response: any) => {
          if (response) {
            if (response.data && response.data.success) {
              setHistory(response.data);
            }
            setShowData(true);
          }
          // setViewLoading(false)
        })
        .catch((err: any) => {
          setShowData(true);
          // notify(err.message, 'warning');
        });
    }
  }, [props]);

  if (showData === false) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <TableContainer component={Paper}>
        {history.list.length === 0 ? (
          <TableCell
            style={{ textAlign: isXsmall ? "center" : "right", width: "100vh" }}
          >
            {" "}
            No History Found
          </TableCell>
        ) : (
          <Table size="small" aria-label="a dense table">
            <TableHead>
              {" "}
              <TableRow>
                {/* <TableCell align="right">Employee</TableCell> */}
                <TableCell align="left">Updated By</TableCell>
                <TableCell align="left">Designation</TableCell>
                {/* <TableCell align="left">Employee</TableCell> */}
                {/* <TableCell align="left">Leave Type</TableCell> */}
                {/* <TableCell align="right">Subject</TableCell> */}
                {/* <TableCell align="left">Description</TableCell> */}
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Days</TableCell>
                {/* <TableCell align="left">To</TableCell> */}
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Opening Balance</TableCell>
                <TableCell align="left">Closing Balance</TableCell>
                <TableCell align="left">Remarks</TableCell>
                <TableCell align="left">Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.list.map((el: any, i: any) => (
                <TableRow key={i}>
                  <TableCell align="left">
                    {el && el.createdBy && el.createdBy.name
                      ? el.createdBy.name
                      : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {el && el.leaveBalanceUpdatedBy
                      ? el.leaveBalanceUpdatedBy
                      : "N/A"}
                  </TableCell>
                  {/* <TableCell align="left">
                    {el && el.employee && el.employee.name
                      ? el.employee.name
                      : "N/A"}
                  </TableCell> */}
                  {/* <TableCell align="left" >
                    {el &&
                    el.leaveType &&
                    el.leaveType.leaveName
                      ? el.leaveType.leaveName
                      : "N/A"}
                  </TableCell> */}
                  {/* <TableCell
                    data-tip={el && el.newData && el.newData.description}
                    data-for="description"
                    align="left"
                  >
                    <div className={classes.textMerge}>
                      {el && el.newData && el.newData.description
                        ? el.newData.description
                        : "N/A"}
                    </div>
                    {toolTip("description")}
                  </TableCell> */}

                  <TableCell align="left">
                    {el && el.fromDate
                      ? helperFunc.commonDateFormat(el.fromDate)
                      : "N/A"}
                  </TableCell>
                  {/* <TableCell align="left">
                    {el && el.toDate
                      ? helperFunc.commonDateFormat(el.toDate)
                      : "N/A"}
                  </TableCell> */}
                  {/* <FunctionField
                  label="label.status"
                   sortBy="status"
                  // sortByOrder="DESC"
                  render={(record: any) => (
                    
                  leaveStatus[record && record.status]
                  )}
                  /> */}
                  <TableCell align="left">
                    {el.leaveDays !== undefined ? el.leaveDays : "N/A"}
                  </TableCell>

                  <TableCell align="left">
                    {el && el.status ? leaveStatus[el.status] : "N/A"}
                  </TableCell>

                  <TableCell align="left">
                    {el.lastLeaveBalance !== undefined
                      ? el.lastLeaveBalance
                      : "N/A"}
                  </TableCell>

                  <TableCell align="left">
                    {el.updatedLeaveBalance !== undefined
                      ? el.updatedLeaveBalance
                      : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {el.comments !== undefined ? el.comments : "N/A"}
                  </TableCell>

                  <TableCell align="left">
                    {el && el.createdAt
                      ? helperFunc.commonDateFormat(el.createdAt, "HH:mm:ss")
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  }
};

const TeamLeaveList = (props: any) => {
  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const filterData = () => {
    let myFilters: any = {};
    myFilters["fromDate"] = helperFunc.subtractDaysFilter(7);
    myFilters["toDate"] = helperFunc.addDaysFilter(0);
    return myFilters;
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  const classes = useStyles();
  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Team Leaves
        </h2>
      ) : null}
      {/* <div
        style={
          isXsmall
            ? {
                width: window.screen.width,
                overflowX: "scroll",
              }
            : {width:"100%",overflow:"auto"}
        }
      > */}
      <List
        className="list-view2"
        exporter={exporter}
        bulkActionButtons={false}
        {...props}
        filterDefaultValues={filterData()}
        filters={
          isSmall ? (
            <LeaveFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <LeaveFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        // style={{
        //   width: isXsmall ? window.screen.width : window.screen.width / 1.25,
        // }}
        style={{
          width: isXsmall ? window.screen.width : "",
          overflow: isXsmall ? "scroll" : isSmall ? "scroll" : "auto",
        }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        title="resources.teamLeaves.title"
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        basePath="/team-leave-balance-history"
        resource="team-leave-balance-history"
        // aside={<TeamLeaveListAside />}
      >
        <Datagrid expand={<PostPanel {...props} />} optimized>
          {/* <TeamLeaveLinkField /> */}
          {user.type === "super" ? (
            <TextField label="Company" source="company.name" />
          ) : null}
          <TextField label="label.employee" source="employee.name" />
          <TextField label="label.leaveType" source="leaveType.code" />
          <TextField label="label.company" source="childCompanyId.name" />
          <TextField label="Leave Balance" source="leaveBalance" />
          {/* <TextField label="Client" source="company.name" /> */}
          <FunctionField
            label="label.createdAt"
            sortBy="createdAt"
            // sortByOrder="DESC"
            render={(record: any) =>
              `${
                record["createdAt"]
                  ? helperFunc.commonDateFormat(record["createdAt"], "HH:mm:ss")
                  : "N/A"
              }  `
            }
          />
        </Datagrid>
        {/* )} */}
      </List>
    </div>
    // </div>
  );
};

export default TeamLeaveList;
