import React, { useState, useEffect } from "react";
import { useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import { attendanceExtraDuty } from "../helperStyle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useMediaQuery,
  Theme,
  Box,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import helperFunc from "../helperFuncs";
import authProvider from "../authProvider";
import ClearIcon from "@material-ui/icons/Clear";
import { toastError, toastInfo } from "../components/toast";
import moment from "moment";

const ExtraDutyEdit = (props: any) => {
  interface res {
    list: any;
    datesData: any;
    filter: any;
    type: any;
  }
  interface Hres {
    childCompanyId: any;
    clientId: any;
    clientLocationId: any;
    company: any;
    employee: any;
    state: any;
    item: any;
  }
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  const useStyles = makeStyles(attendanceExtraDuty);
  const refresh = useRefresh();
  const user = helperFunc.getUserData();
  const dataProvider = useDataProvider();
  // const notify = useNotify();
  const classes = useStyles();
  const [loading, setLoading] = useState<any>(false);
  const [syncLoading, setSyncLoading] = useState<any>(false);
  const [sendrequest, setSendRequest] = useState<any>(false);
  const [downloadLoading, setDownloadLoading] = useState<any>(false);
  const [sendApprovalLoading, setSendApprovalLoading] = useState<any>(false);
  const [responseData, setResponseData] = useState<res>({
    list: [],
    datesData: [],
    filter: {},
    type: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [downloadTypes, setDownloadTypes] = useState<any>({ downloadType: "" });
  const [headerData, setHeaderData] = useState<Hres>({
    childCompanyId: {},
    clientId: {},
    clientLocationId: {},
    company: {},
    employee: {},
    state: {},
    item: {},
  });
  const [tabValue, setTab1] = useState("ur");
  const [syncData, setSyncData] = useState(false);

  const params = {
    id: props.id,
  };
  useEffect(() => {
    dataProvider
      .getOne("attendence-approval", params)
      .then((response: any) => {
        setHeaderData(response.allData);
        setResponseData(response.data.reportData);
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, [sendrequest]);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  React.useEffect(() => {
    if ((!isSmall || !isXSmall) && container) {
      container.innerText = "";
      container.innerText =
        authProvider.isMenuShow("attendenceApproval1", "update") ||
        authProvider.isMenuShow("attendenceApproval1", "create")
          ? headerData.item.isApproved === "Pending"
            ? "Edit Attendance Approval - 1"
            : "View Attendance Approval - 1"
          : "View Attendance Approval - 1";
    }
    return function cleanup() {
      if ((isSmall || isXSmall) && container) {
        container.innerText = "";
      }
    };
  }, [container, headerData]);
  const currentTab1 = (value: string) => {
    setTab1(value);
  };

  const handleClick = (rowIndex: number, data: any, str: any) => {
    if (data === "p" || data === "h") {
      data = data.toUpperCase();
    }
    let editdataResponse: any = { ...responseData };
    if (editdataResponse.list[rowIndex][str] !== undefined) {
      editdataResponse.list[rowIndex][str] = data;
      setResponseData(editdataResponse);
    } else {
      editdataResponse.list[rowIndex][str.toString()] = data;
      setResponseData(editdataResponse);
    }
  };
  const saveEditData = () => {
    responseData.list.forEach((el: any) => {
      if (el.isChanged) {
        responseData.datesData.forEach((date: any) => {
          helperFunc.timeToIsoTime(el, date);
        });
      }
    });

    setLoading(true);
    let payload: any = { reportData: responseData };

    setTimeout(function () {
      dataProvider
        .callCustom(`attendence-approval/update/${props.id}`, payload)
        .then((response: any) => {
          setLoading(false);
          toastInfo("updated successfully");
          // notify("updated successfully");
          setSendRequest((previousSendrequest: any) => !previousSendrequest);
          // refresh();
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    }, 1000);
  };

  const getTimeDifference = (time1: any, time2: any) => {
    const startTime = new Date(`2023-07-24T${time1}`);
    const endTime = new Date(`2023-07-24T${time2}`);

    // Step 2: Get the timestamp (in milliseconds) for both Date objects
    const startTimestamp = startTime.getTime();
    const endTimestamp = endTime.getTime();

    // Step 3: Calculate the time difference in milliseconds
    const timeDifference = endTimestamp - startTimestamp;

    // Step 4: Convert the time difference from milliseconds to hours and minutes
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (minutes < 10) {
      return `${hours}.0${minutes}`;
    } else return `${hours}.${minutes}`;
  };

  const syncEditData = () => {
    responseData.list.forEach((el: any) => {
      if (el.isChanged) {
        responseData.datesData.forEach((date: any) => {
          helperFunc.timeToIsoTime(el, date);
        });
      }
    });

    setSyncLoading(true);
    let payload: any = { reportData: responseData };

    setTimeout(function () {
      dataProvider
        .callCustom(`attendence-approval/syncAttendance/${props.id}`, payload)
        .then((response: any) => {
          setSyncLoading(false);
          toastInfo("updated successfully");
          setSyncData(true);
          // notify("updated successfully");
          // setSendRequest((previousSendrequest: any) => !previousSendrequest);
          // refresh();
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    }, 1000);
  };

  const exporterDetails = (value: any) => {
    setDownloadLoading(true);
    setDownloadTypes({
      ...downloadTypes,
      downloadType: value,
    });
    setDownloadLoading(false);
  };

  const handleClickMouse = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (downloadTypes.downloadType !== "") {
      dataProvider
        .callCustom("attendence-approval/export-reports", {
          _id: headerData.item._id,
          // activeSheet: `${tabValue === "p" ? "present" : "overTime"}`,
          downloadType: downloadTypes.downloadType,
        })
        .then((response: any) => {
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    }
  }, [downloadTypes]);

  const handleSendApproval = () => {
    setSendApprovalLoading(true);
    let payload = {
      isInternalApproved: true,
    };
    dataProvider
      .callCustom(`attendence-approval/update/${headerData.item._id}`, payload)
      .then((response: any) => {
        setSendApprovalLoading(false);
        setSendRequest((previousSendrequest: any) => !previousSendrequest);
        toastInfo("Send For Approval successfully");
        // notify('Send For Approval successfully')
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, 'warning');
      });
  };

  const handleChange = (
    rowIndex: number,
    date: number,
    data: any,
    str: string,
    mainStr: any
  ) => {
    let editdataResponse: any = { ...responseData };
    editdataResponse.list[rowIndex][date + str] = data;
    if (mainStr) {
      editdataResponse.list[rowIndex][date + mainStr] = data;
    }

    if (user?.isSyncAttendance === true) {
      let updatedInTime;
      if (
        editdataResponse.list[rowIndex][date + " _inTimesCopy"] === undefined
      ) {
        updatedInTime = helperFunc.timeWithAmPm(
          editdataResponse.list[rowIndex][date + "_inTimes"]
        );
      }
      let updatedOutTime;
      if (
        editdataResponse.list[rowIndex][date + " _outTimesCopy"] === undefined
      ) {
        updatedOutTime = helperFunc.timeWithAmPm(
          editdataResponse.list[rowIndex][date + "_outTimes"]
        );
      }

      editdataResponse.list[rowIndex][date + "_spentHours"] = getTimeDifference(
        updatedInTime,
        updatedOutTime
      );
    }

    editdataResponse.list[rowIndex]["isChanged"] = true;
    setResponseData(editdataResponse);
    // setData(() => setResponseData(editdataResponse));
  };

  const setData = (callback: any) => {
    setTimeout(() => {
      return callback();
    }, 500);
  };
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  return (
    <>
      {isXSmall ? (
        <h2
          style={{
            height: 40,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 15,
          }}
        >
          {authProvider.isMenuShow("attendenceApproval1", "update") ||
          authProvider.isMenuShow("attendenceApproval1", "create")
            ? headerData.item.isApproved === "Pending"
              ? "Edit Attendance Approval - 1"
              : "View Attendance Approval - 1"
            : "View Attendance Approval - 1"}
        </h2>
      ) : null}
      <div style={isXSmall ? { width: "100vh", overflow: "scroll" } : {}}>
        <div
          style={isXSmall ? { overflow: "scroll", width: "200vh" } : {}}
          className="container-fluid"
        >
          {/* <p style={{ color: 'red', fontSize: '8px' }}>{tabValue === "ur" && !headerData.item.isInternalApproved ? 'Time Format Should be Proper else it will not considered as a change "HH:MM am/pm"' : null}</p> */}
          <div style={{ marginBottom: 20, marginTop: 5 }} className="row">
            <div className="col-6">
              <table
                width={isXSmall ? window.screen.width : window.screen.width / 4}
                className={classes.table_first}
              >
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>Name And Address Of Contractor</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.company && headerData.company.name
                        ? headerData.company.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                {headerData !== undefined &&
                headerData.childCompanyId &&
                headerData.childCompanyId.licenseNumber ? (
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span>LIN&nbsp;</span>
                    </td>
                    <td className={classes.table_first_cell}>
                      <span>
                        {headerData.childCompanyId &&
                        headerData.childCompanyId.licenseNumber
                          ? headerData.childCompanyId.licenseNumber
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {headerData !== undefined &&
                headerData.childCompanyId &&
                headerData.childCompanyId.natureOfWork ? (
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span>Nature Of Work</span>&nbsp;
                    </td>
                    <td className={classes.table_first_cell}>
                      <span>
                        {headerData.childCompanyId &&
                        headerData.childCompanyId.natureOfWork
                          ? headerData.childCompanyId.natureOfWork
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </table>
            </div>

            {user.isClientConfiguration && (
              <div className={isXSmall ? "" : "col-6 colClass"}>
                <table
                  width={
                    isXSmall ? window.screen.width : window.screen.width / 4
                  }
                  className={`${
                    classes.table_second
                  } ${"finalReportSmallTable"}`}
                >
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span className="text-gray-light">
                        NAME OF PRINCIPAL EMPLOYER
                      </span>
                    </td>
                    <td className={classes.table_first_cell}>
                      <span className="to">
                        {headerData.clientId && headerData.clientId.name
                          ? headerData.clientId.name
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span className="address">CLIENTS CODE/LOCATION</span>
                    </td>
                    <td className={classes.table_first_cell}>
                      <span className="email">
                        {headerData.clientLocationId &&
                        headerData.clientLocationId.name
                          ? headerData.clientLocationId.name
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span className="address">LOCATION ADDRESS</span>
                    </td>
                    <td className={classes.table_first_cell}>
                      <span className="email">
                        {headerData.clientLocationId &&
                        headerData.clientLocationId.clientLocationAddress
                          ? headerData.clientLocationId.clientLocationAddress
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                  {responseData !== undefined &&
                  responseData.filter &&
                  responseData.filter.fromDate &&
                  responseData.filter.toDate ? (
                    <tr>
                      <td className={classes.table_first_cell}>
                        <span className="address">FOR THE MONTH OF</span>
                      </td>
                      <td className={classes.table_first_cell}>
                        <span className="email">{`${new Date(
                          responseData.filter.fromDate
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                        })}  To  ${new Date(
                          responseData.filter.toDate
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })} `}</span>
                      </td>
                    </tr>
                  ) : null}
                </table>
              </div>
            )}
          </div>

          {/* <div style={{ display: 'inline', flexDirection: 'column', marginTop: "5px" }}> */}
          {isXSmall ? (
            <>
              <button
                className={tabValue === "ur" ? classes.publicButton : ""}
                onClick={() => currentTab1("ur")}
              >
                Attendance Register
              </button>
              <button
                className={tabValue === "p" ? classes.publicButton : ""}
                onClick={() => currentTab1("p")}
              >
                Present Sheet
              </button>
              <button
                className={tabValue === "ot" ? classes.publicButton : ""}
                onClick={() => currentTab1("ot")}
              >
                Extra Duty Sheet
              </button>
            </>
          ) : (
            <>
              <Button
                className={tabValue === "ur" ? classes.publicButton : ""}
                component="div"
                onClick={() => currentTab1("ur")}
              >
                Attendance Register
              </Button>
              <Button
                className={tabValue === "p" ? classes.publicButton : ""}
                component="div"
                onClick={() => currentTab1("p")}
              >
                Present Sheet
              </Button>
              <Button
                className={tabValue === "ot" ? classes.publicButton : ""}
                component="div"
                onClick={() => currentTab1("ot")}
              >
                Extra Duty Sheet
              </Button>
            </>
          )}
          {/* </div> */}
          <div
            style={
              isXSmall
                ? {
                    width: "150vh",
                    paddingRight: window.screen.width >= 375 ? 60 : 0,
                    maxWidth: "150vh",
                    overflow: "scroll",
                  }
                : {}
            }
            className="finalreport"
          >
            {tabValue === "p" || tabValue === "ot" ? (
              <TableContainer
                className={
                  open ? classes.container : classes.closeSideBarcontainer
                }
              >
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          left: 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                          textAlign: "center",
                          flex: 1,
                        }}
                        className={classes.header}
                      >
                        Sr No.
                      </TableCell>
                      <TableCell
                        style={{
                          left: 99,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                      >
                        EMP Id
                      </TableCell>
                      <TableCell
                        style={{
                          left: 198,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                        }}
                        className={classes.header}
                      >
                        EMP Name
                      </TableCell>
                      <TableCell
                        style={{
                          left: 298,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          background: "#e6e6e6",
                          zIndex: 999,
                        }}
                        className={classes.header}
                      >
                        Designation
                      </TableCell>
                      {responseData.list
                        ? responseData &&
                          responseData.datesData &&
                          responseData.datesData.map(
                            (date: string, index: number) => {
                              return (
                                <TableCell className={classes.header}>
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "short",
                                  })}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                      {tabValue === "p" ? (
                        <>
                          <TableCell className={classes.header}>
                            Absent
                          </TableCell>
                          <TableCell className={classes.header}>
                            Present
                          </TableCell>
                          {/* <TableCell className={classes.header}>Paid Holiday</TableCell> */}
                          <TableCell className={classes.header}>
                            Holiday
                          </TableCell>
                          <TableCell className={classes.header}>
                            Total
                          </TableCell>
                        </>
                      ) : tabValue === "ot" ? (
                        <TableCell className={classes.header}>
                          Total Extra Duty
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {responseData &&
                      responseData.list &&
                      responseData.list.map(
                        (employeeData: any, rowIndex: number) => {
                          return (
                            <TableRow>
                              <TableCell
                                style={{
                                  left: 0,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                  textAlign: "center",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {rowIndex + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: 99,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeCode}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: 198,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                  textAlign: "center",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData.employeeName}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: 297,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.designationName
                                  ? employeeData.designationName
                                  : "N/A"}
                              </TableCell>
                              {responseData &&
                                responseData.datesData &&
                                responseData.datesData.map(
                                  (date: string, index: number) => {
                                    if (tabValue === "p") {
                                      if (
                                        headerData.item.isInternalApproved ||
                                        !(
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "update"
                                          ) ||
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "create"
                                          )
                                        )
                                      ) {
                                        return (
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {employeeData[date]}
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          align="left"
                                          className={classes.tableCell}
                                        >
                                          <input
                                            style={{ width: "65px" }}
                                            value={
                                              employeeData[date]
                                                ? employeeData[date]
                                                : ""
                                            }
                                            onChange={(e: any) => {
                                              handleClick(
                                                rowIndex,
                                                e.target.value,
                                                date
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      );
                                    } else if (tabValue === "ot") {
                                      if (
                                        headerData.item.isInternalApproved ||
                                        !(
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "update"
                                          ) ||
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "create"
                                          )
                                        )
                                      ) {
                                        return (
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {employeeData[`${date}_overTime`]}{" "}
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          align="left"
                                          className={classes.tableCell}
                                        >
                                          <input
                                            style={{ width: "65px" }}
                                            value={
                                              employeeData &&
                                              employeeData[`${date}_overTime`]
                                                ? employeeData[
                                                    `${date}_overTime`
                                                  ]
                                                : ""
                                            }
                                            onChange={(e: any) => {
                                              handleClick(
                                                rowIndex,
                                                e.target.value,
                                                `${date}_overTime`
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      );
                                    }
                                  }
                                )}
                              {tabValue === "p" ? (
                                <>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData.absent}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData.present}
                                  </TableCell>
                                  {/* <TableCell align="left" className={classes.tableCell}>{employeeData.paid_holiday}</TableCell> */}
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData.holiday}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData.totalCount}
                                  </TableCell>
                                </>
                              ) : tabValue === "ot" ? (
                                <TableCell className={classes.tableCell}>
                                  {employeeData.overTimeCount}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{
                            textAlign: "center",
                            border: "solid 1px lightgrey",
                            borderRight: "solid 1px lightgrey",
                          }}
                        >
                          <TableCell
                            colSpan={9}
                            style={{ textAlign: "center" }}
                          >
                            No record found
                          </TableCell>
                          {/* <p style={{ textAlign: 'center', marginLeft: 400,fontSize: 14, textTransform: "capitalize", padding: 10, maxWidth: "100%",minWidth:"100%" }} >No record found</p> */}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {tabValue === "ur" ? (
              <TableContainer className={classes.container}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className={classes.header} rowSpan={2}>STATE NAME</TableCell> */}
                      {user?.isClientConfiguration && (
                        <>
                          <TableCell
                            style={{
                              left: 0,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                              flex: 1,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Client Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 100,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 200,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Id
                          </TableCell>
                        </>
                      )}

                      {/* <TableCell className={classes.header} rowSpan={2}>LOCATION GPS ADDRESS</TableCell> */}
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 300 : 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Id
                      </TableCell>
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 400 : 100,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Name
                      </TableCell>
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 500 : 200,
                          position: "sticky",
                          width: "11x0px !important",
                          minWidth: "110px !important",
                          //  wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                          flex: 1,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        Designation
                      </TableCell>
                      {responseData.list
                        ? responseData &&
                          responseData.datesData &&
                          responseData.datesData.map(
                            (date: string, index: number) => {
                              return (
                                <TableCell
                                  className={classes.header}
                                  colSpan={4}
                                >
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                  })}
                                  {"-"}
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    month: "short",
                                  })}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                    <TableRow>
                      {responseData.list
                        ? responseData &&
                          responseData.datesData &&
                          responseData.datesData.map(
                            (employeeDate: any, index: number) => {
                              return (
                                <>
                                  <TableCell
                                    style={{ minWidth: "110px" }}
                                    className={classes.tableHead}
                                  >
                                    In
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: "110px" }}
                                    className={classes.tableHead}
                                  >
                                    Out
                                  </TableCell>
                                  {/* <TableCell className={classes.tableHead}>DUTY</TableCell> */}
                                  <TableCell className={classes.tableHead}>
                                    Extra Duty
                                  </TableCell>
                                  <TableCell className={classes.tableHead}>
                                    Spent Hours
                                  </TableCell>
                                </>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ justifyContent: "center" }}>
                    {responseData &&
                      responseData.list &&
                      responseData.list.map(
                        (employeeData: any, rowIndex: number) => {
                          return (
                            <TableRow hover role="checkbox">
                              {/* <TableCell align="left" className={classes.tableCell} >{employeeData && employeeData.stateName}</TableCell> */}
                              {user?.isClientConfiguration && (
                                <>
                                  <TableCell
                                    style={{
                                      left: 0,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData && employeeData.clientName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 100,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 200,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                      textAlign: "center",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationId}
                                  </TableCell>
                                </>
                              )}

                              {/* <TableCell align="left" className={classes.tableCell} >-</TableCell> */}
                              <TableCell
                                style={{
                                  left: user?.isClientConfiguration ? 300 : 0,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeCode}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user?.isClientConfiguration ? 400 : 100,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeName}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user?.isClientConfiguration ? 500 : 200,
                                  position: "sticky",
                                  width: "110px !important",
                                  minWidth: "110px !important",
                                  //  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.designationName}
                              </TableCell>
                              {/* {resetCurrentDataIndex()} */}
                              {responseData !== undefined &&
                              responseData.datesData !== undefined
                                ? responseData.datesData.map(
                                    (date: any, index: number) => {
                                      const checkViewMode =
                                        headerData.item.isInternalApproved ||
                                        !(
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "update"
                                          ) ||
                                          authProvider.isMenuShow(
                                            "attendenceApproval1",
                                            "create"
                                          )
                                        );
                                      if (employeeData && employeeData[date]) {
                                        // employeeData[date + "_inTimesCopy"] = helperFunc.timeWithAmPm(employeeData[date + "_inTimes"]) !== undefined ? helperFunc.timeWithAmPm(employeeData[date + "_inTimes"]) : employeeData[date + "_inTimes"];
                                        // employeeData[date + "_outTimesCopy"] = helperFunc.timeWithAmPm(employeeData[date + "_outTimes"]) !== undefined ? helperFunc.timeWithAmPm(employeeData[date + "_outTimes"]) : employeeData[date + "_outTimes"];
                                        employeeData["onDate"] = date;
                                        // let data = employeeData.data;

                                        const time1 =
                                          employeeData[date + "_inTimesCopy"];
                                        const time2 =
                                          employeeData[date + "_outTimesCopy"];

                                        return (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                type="time"
                                                disabled={checkViewMode}
                                                defaultValue={helperFunc.timeWithAmPm(
                                                  employeeData[
                                                    date + "_inTimes"
                                                  ]
                                                )}
                                                value={
                                                  employeeData[
                                                    date + "_inTimesCopy"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_inTimesCopy",
                                                    "_inTimes"
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                              {headerData.item
                                                .isInternalApproved ||
                                              !(
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "update"
                                                ) ||
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "create"
                                                )
                                              ) ? null : (
                                                <ClearIcon
                                                  color="error"
                                                  fontSize="small"
                                                  onClick={(e: any) => {
                                                    handleChange(
                                                      rowIndex,
                                                      date,
                                                      "",
                                                      "_inTimesCopy",
                                                      "_inTimes"
                                                    );
                                                  }}
                                                />
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                type="time"
                                                disabled={checkViewMode}
                                                defaultValue={helperFunc.timeWithAmPm(
                                                  employeeData[
                                                    date + "_outTimes"
                                                  ]
                                                )}
                                                value={
                                                  employeeData[
                                                    date + "_outTimesCopy"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_outTimesCopy",
                                                    "_outTimes"
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                              {headerData.item
                                                .isInternalApproved ||
                                              !(
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "update"
                                                ) ||
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "create"
                                                )
                                              ) ? null : (
                                                <ClearIcon
                                                  color="error"
                                                  fontSize="small"
                                                  onClick={(e: any) => {
                                                    handleChange(
                                                      rowIndex,
                                                      date,
                                                      "",
                                                      "_outTimesCopy",
                                                      "_outTimes"
                                                    );
                                                  }}
                                                />
                                              )}{" "}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                disabled={checkViewMode}
                                                value={
                                                  employeeData[
                                                    date + "_overTimes"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_overTimes",
                                                    undefined
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                disabled={checkViewMode}
                                                value={
                                                  // result
                                                  //   ? result
                                                  //   :

                                                  employeeData[
                                                    date + "_spentHours"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_spentHours",
                                                    undefined
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                            </TableCell>
                                            {/* {incCurrentDataIndex()} */}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                type="time"
                                                disabled={checkViewMode}
                                                value={
                                                  employeeData[
                                                    date + "_inTimesCopy"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_inTimesCopy",
                                                    "_inTimes"
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                              {headerData.item
                                                .isInternalApproved ||
                                              !(
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "update"
                                                ) ||
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "create"
                                                )
                                              ) ? null : (
                                                <ClearIcon
                                                  color="error"
                                                  fontSize="small"
                                                  onClick={(e: any) => {
                                                    handleChange(
                                                      rowIndex,
                                                      date,
                                                      "",
                                                      "_inTimesCopy",
                                                      "_inTimes"
                                                    );
                                                  }}
                                                />
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                type="time"
                                                disabled={checkViewMode}
                                                value={
                                                  employeeData[
                                                    date + "_outTimesCopy"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_outTimesCopy",
                                                    "_outTimes"
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                              {headerData.item
                                                .isInternalApproved ||
                                              !(
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "update"
                                                ) ||
                                                authProvider.isMenuShow(
                                                  "attendenceApproval1",
                                                  "create"
                                                )
                                              ) ? null : (
                                                <ClearIcon
                                                  color="error"
                                                  fontSize="small"
                                                  onClick={(e: any) => {
                                                    handleChange(
                                                      rowIndex,
                                                      date,
                                                      "",
                                                      "_outTimesCopy",
                                                      "_outTimes"
                                                    );
                                                  }}
                                                />
                                              )}
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                disabled={checkViewMode}
                                                value={
                                                  employeeData[
                                                    date + "_overTimes"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_overTimes",
                                                    undefined
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                disabled={checkViewMode}
                                                value={
                                                  employeeData[
                                                    date + "_spentHours"
                                                  ]
                                                }
                                                onChange={(e: any) => {
                                                  handleChange(
                                                    rowIndex,
                                                    date,
                                                    e.target.value,
                                                    "_spentHours",
                                                    undefined
                                                  );
                                                }}
                                                style={{
                                                  width: "65px",
                                                  border: checkViewMode
                                                    ? "none"
                                                    : "",
                                                }}
                                              />
                                            </TableCell>
                                          </>
                                        );
                                      }
                                    }
                                  )
                                : null}
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{ textAlign: "center" }}
                        >
                          <TableCell
                            colSpan={9}
                            style={{ textAlign: "center" }}
                          >
                            No record found
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </div>
          <div className="row">
            <div className={isXSmall ? "col-3" : "col-6"}>
              <table
                style={{ marginBottom: isXSmall ? 20 : 100 }}
                className={classes.table_first}
              >
                <tr>
                  <td>
                    <span>FOR OFFICE USE ONLY </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>PREPARED BY</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item.generatedBy &&
                      headerData.item.generatedBy.name
                        ? headerData.item.generatedBy.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>CHECKED AND AUTHORISED BY &nbsp;</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item &&
                      headerData.item.approvedby &&
                      headerData.item.approvedby.name
                        ? headerData.item.approvedby.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                {/* <tr>
                                <td className={classes.table_first_cell}>
                                    <span>DESIGNATION</span>
                                </td>
                                <td className={classes.table_first_cell}>
                                    <span>{headerData.item && headerData.item.designation ? headerData.item.designation : "N/A"}</span>
                                </td>
                            </tr> */}
                {/* <tr>
                                <td className={classes.table_first_cell}>
                                    <span>ATTENDANCE ID </span>&nbsp;
                 </td>
                                <td className={classes.table_first_cell}>
                                    <span>{headerData && headerData.item && headerData.item.attendenceId ? headerData.item.attendenceId : "N/A"}</span>
                                </td>
                            </tr> */}
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>BRANCH </span>&nbsp;
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData &&
                      headerData.clientLocationId &&
                      headerData.clientLocationId.branchId &&
                      headerData.clientLocationId.branchId.name
                        ? headerData.clientLocationId.branchId.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            {/* {headerData.item.isInternalApproved ?
                        <div className="col-6 colClass">
                            <table style={{ marginBottom: 100 }} className={classes.table_second}>
                                <tr>
                                    <td>
                                        <span>CERTIFICATION BY CLIENT </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.table_first_cell}>
                                        <span>{`THIS ATTENDANCE IS APPROVED VIA MAIL ID: ${headerData.clientLocationId && headerData.clientLocationId.clientAdminName ? headerData.clientLocationId.clientAdminName : ""} [${headerData.clientLocationId && headerData.clientLocationId.email ? headerData.clientLocationId.email : ""}]   _${headerData.clientLocationId && headerData.clientLocationId.designation ? headerData.clientLocationId.designation : ''}, ${headerData.item.approvedAt ? helperFunc.commonDateFormat(headerData.item.approvedAt, "HH:mm:ss") : "N/A"}  - SUB: ATTENDANCE APPROVAL FOR ${new Date(headerData.item.approvedAt).toLocaleDateString('en-GB', { month: "short" })}-${new Date(headerData.item.approvedAt).toLocaleDateString('en-GB', { year: "numeric" })}_${headerData.clientLocationId && headerData.clientLocationId.name ? headerData.clientLocationId.name : "N/A"}_${headerData.item && headerData.item.attendenceId ? headerData.item.attendenceId : ''} `}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        : null} */}
          </div>
          <div
            style={{ margin: isXSmall ? 10 : 0, display: "block" }}
            className={isXSmall ? "" : "stickBottm"}
          >
            <Box component="div" className={classes.flexColTop}>
              <Box
                component="div"
                className={
                  user?.isSyncAttendance === true
                    ? classes.flexColTop54Left
                    : classes.flexColTop50Left
                }
              >
                {user?.isSyncAttendance && (
                  <Box>
                    {(authProvider.isMenuShow(
                      "attendenceApproval1",
                      "update"
                    ) ||
                      authProvider.isMenuShow(
                        "attendenceApproval1",
                        "create"
                      )) &&
                    headerData.item.isInternalApproved === false ? (
                      <div className={classes.mright32}>
                        <Button
                          style={{
                            color: loading ? "black" : "white",
                          }}
                          variant="contained"
                          color="primary"
                          className={classes.actionButton}
                          component="span"
                          onClick={() => syncEditData()}
                          disabled={syncData === true}
                        >
                          {syncLoading && (
                            <CircularProgress size={25} thickness={2} />
                          )}
                          SYNC
                        </Button>
                      </div>
                    ) : null}
                  </Box>
                )}

                <Box>
                  {(authProvider.isMenuShow("attendenceApproval1", "update") ||
                    authProvider.isMenuShow("attendenceApproval1", "create")) &&
                  headerData.item.isInternalApproved === false ? (
                    <Button
                      style={{ color: loading ? "black" : "white" }}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                      component="span"
                      onClick={() => saveEditData()}
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      SUBMIT WITH MANUAL CHANGE
                    </Button>
                  ) : null}
                </Box>
                <div>
                  <Button
                    style={{ color: downloadLoading ? "blac" : "white" }}
                    className={classes.downloadButton}
                    variant="contained"
                    color="primary"
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    // onClick={() => exporterDetails("xlsx")}
                    onMouseOver={handleClickMouse}
                    disabled={downloadLoading}
                  >
                    {downloadLoading && (
                      <CircularProgress size={25} thickness={2} />
                    )}
                    Download
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                  >
                    <MenuItem
                      onClick={() => {
                        exporterDetails("xlsx");
                      }}
                    >
                      Download as Excel
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        exporterDetails("pdf");
                      }}
                    >
                      Download as PDF
                    </MenuItem> */}
                  </Menu>
                </div>
                <Box className={classes.mleft32}>
                  {(authProvider.isMenuShow("attendenceApproval1", "update") ||
                    authProvider.isMenuShow("attendenceApproval1", "create")) &&
                  headerData.item.isInternalApproved === false ? (
                    <Button
                      style={{ color: sendApprovalLoading ? "black" : "white" }}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton2}
                      component="span"
                      onClick={() => handleSendApproval()}
                      disabled={sendApprovalLoading}
                    >
                      {sendApprovalLoading && (
                        <CircularProgress size={15} thickness={2} />
                      )}
                      SEND FOR INTERNAL APPROVAL
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExtraDutyEdit;
