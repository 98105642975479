import React from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  required,
  email,
  ImageInput,
  FileInput,
  CheckboxGroupInput,
  ImageField,
  maxLength,
  number,
  BooleanInput,
  RadioButtonGroupInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  TabbedShowLayoutTabs,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { maxValue, minValue } from "../TurnoutAdmin";
import CustomActionToolbar from "./CustomActionToolbar";
import { clinetstyles } from "../helperStyle";
import { addons, turnoutDevices } from "../helperChoice";
import helperFunc from "../helperFuncs";
import ActiveDirectoryConfig from "./components/ActiveDirectoryConfig";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const validatePasswords = ({
  email,
  contactEmail,
  password,
  confirm_password,
  attendance_server_URL,
  registrationServerURL,
}: {
  email: string;
  contactEmail: string;
  password: string;
  confirm_password: string;
  tokenExpireTime: any;
  idealTokenExpireTime: any;
  settings: any;
  introScreen: any;
  metaInfo: any;
  attendance_server_URL: any;
  registrationServerURL: String;
}) => {
  const errors = {} as any;
  if (attendance_server_URL) {
    if (!helperFunc.validURL(attendance_server_URL)) {
      errors.attendance_server_URL = "Must be a valid Url";
    }
  }

  if (registrationServerURL) {
    if (!helperFunc.validURL(registrationServerURL)) {
      errors.registrationServerURL = "Must be a valid Url";
    }
  }

  // if (contactEmail) {
  //   //eslint-disable-next-line
  //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contactEmail)) {
  //     errors.contactEmail = "Must be a valid email";
  //   }
  // }

  // if (email) {
  //   //eslint-disable-next-line
  //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     errors.email = "Must be a valid email";
  //   }
  // }
  if (password) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!password.match(decimal)) {
      errors.password =
        "At lease one capital letters, At least one small letters, At least one special character, At least one number, Minimum 8 character,Maximum 15 character";
    }
  }
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(clinetstyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/companies/create"
          ? "Update Company"
          : "Add Company"}
      </h2>
      <SimpleForm {...props} validate={validatePasswords}>
        {props && Object.keys(props.record).length !== 0 ? (
          <CustomActionToolbar record={props.record}></CustomActionToolbar>
        ) : null}
        <SectionTitle label="resources.companies.fieldGroups.basic" />
        <div style={{ display: "inline" }}>
          <TextInput
            // fullWidth
            autoFocus
            source="name"
            formClassName={classes.customSize}
            validate={requiredValidate}
          />
          <SectionTitle label="" />
          <TextInput
            source="phone"
            // disabled
            // fullWidth
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            type="email"
            // disabled
            source="email"
            validation={{ email: true }}
            // fullWidth
            formClassName={classes.email}
            validate={[required(), email()]}
          />
          <SectionTitle label="" />
          <TextInput
            label="label.contact_phone"
            source="metaInfo.contact.phone"
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            label="label.contact_email"
            type="email"
            source="contactEmail"
            validation={{ email: true }}
            // fullWidth
            formClassName={classes.email}
            validate={[required(), email()]}
          />
          <SectionTitle label="" />
          <TextInput
            // disabled
            label="label.employee_size"
            source="numberOfMaxUser"
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            // disabled
            label="label.visitor_size"
            source="numberOfMaxVisitor"
            formClassName={classes.code}
            validate={requiredValidate}
          />
          <TextInput
            multiline
            label="label.company_Address"
            source="metaInfo.address.lines"
            formClassName={classes.code}
            validate={requiredValidate}
          />
          <SectionTitle label="" />
          <TextInput
            label="label.latitude"
            source="metaInfo.address.lat"
            formClassName={classes.name}
            validate={latitudeValidation}
          />
          <TextInput
            label="label.longitude"
            source="metaInfo.address.lng"
            formClassName={classes.code}
            validate={longitudeValidation}
          />
          <TextInput
            label="label.timezone"
            source="metaInfo.timezoneOffset"
            formClassName={classes.code}
            validate={timeZoneValidate}
          />

          {/* <SectionTitle label="resources.companies.fieldGroups.contact" />

        <SectionTitle label="" />


        <SectionTitle label="resources.companies.fieldGroups.credential" />


        <SectionTitle label="resources.customers.fieldGroups.password" /> */}
          <SectionTitle label="" />
          {user?.isLDAP ? null : (
            <>
              <PasswordInput
                source="password"
                formClassName={classes.password}
              />
              <PasswordInput
                source="confirm_password"
                formClassName={classes.confirm_password}
              />
            </>
          )}

          <SectionTitle label="" />
          <TextInput
            label="label.tokenExpire"
            source="tokenExpireTime"
            formClassName={classes.name}
            validate={tokenExpireValidation}
          />
          <RadioButtonGroupInput
            source="turnoutDevice"
            optionText="name"
            row
            fullWidth
            formClassName={classes.code}
            optionValue="id"
            choices={turnoutDevices}
          />
          <TextInput
            fullWidth
            source="attendance_server_URL"
            formClassName={classes.edgeUrl}
            // validate={commonValidate}
            label="Attendance Server Url"
          />
          <TextInput
            fullWidth
            source="registrationServerURL"
            formClassName={classes.edgeUrl}
            // validate={commonValidate}
            label="Registration Server URL"
          />
          <SectionTitle label="" />

          <CheckboxGroupInput
            source="assign_addons"
            choices={addons}
            optionText="name"
            optionValue="_id"
          />
          <SectionTitle label="Others" />

          {/* <SectionTitle label="label.token_setting" /> */}
          {/* <TextInput
          label="Ideal Token Expire Time"
          source="idealTokenExpireTime"
          formClassName={classes.name}
          validate={idealTokenExpireValidation}
        /> */}

          {/* <SectionTitle label="label.turnoutDevice" /> */}

          <BooleanInput
            source="isTurnoutM"
            label="label.turnoutM"
            formClassName={classes.name}
            defaultValue={false}
          />
          <BooleanInput
            source="isMask"
            label="label.mask"
            formClassName={classes.code}
            defaultValue={false}
          />
          <BooleanInput
            source="isAdvertisement"
            label="label.advertisement"
            defaultValue={false}
            formClassName={classes.code}
          />
          <SectionTitle label="" />

          <BooleanInput
            formClassName={classes.name}
            label="label.status"
            source="isActive"
          />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={true}
            label="CMS Is Active"
            source="cmsIsActive"
          />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={true}
            label="Is College"
            source="isCollege"
          />

          <FormDataConsumer formClassName={classes.code}>
            {
              ({ formData }: { formData: any }) => (
                // formData &&
                // formData.assign_addons &&
                // formData.assign_addons.includes("leave") ? (
                <BooleanInput
                  lable="Is Reporting Manager"
                  source="isReportingManager"
                />
              )
              // ) : null
            }
          </FormDataConsumer>
          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Visitor Verification Required"
            source="isVisitorVerificationRequired"
          />
          <SectionTitle label="" />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Visitor GatePass"
            source="isVisitorGatepass"
          />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Remarks"
            source="isAttendanceRemark"
          />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Change Attendance"
            source="isChangeAttendance"
          />
          <BooleanInput
            label="Document Management"
            source="isDocumentManagement"
            formClassName={classes.name}
          />

          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Not Recognized"
            source="isUnknownAttendance"
          />
          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Sync Attendance"
            source="isSyncAttendance"
          />
        </div>
        <TabbedShowLayout
          tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
          className={classes.tab}
        >
          <Tab label="White Label">
            {/* <SectionTitle label="resources.companies.fieldGroups.white_label" /> */}
            <div style={{ display: "inline" }}>
              <TextInput
                fullWidth
                label="label.titleTag"
                source="settings.heading_name"
                validate={tagValidation}
              />

              <TextInput
                fullWidth
                label="label.header_text"
                source="settings.heading_text"
                validate={requiredValidate}
              />

              {/* <TextInput
          fullWidth
          label="Footer text"
          source="settings.footer_text"
          validate={requiredValidate}
        /> */}
              {/* <FileInput
          source="settings.company_logo"
          label="Logo"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
          multiple={false}
        >
          <ImageField source="src" title="title" />
        </FileInput> */}
              <FileInput
                source="introScreen"
                label="label.introScreen"
                accept="image/*"
                // placeholder={<p>Drop your file here</p>}
                // options={{ onDrop: callback }}
                validate={requiredValidate}
              >
                <ImageField source="src" title="title" />
              </FileInput>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) =>
                  formData &&
                  formData.introScreen &&
                  !formData.introScreen.src ? (
                    <img
                      alt=""
                      style={{ width: 350, height: 200 }}
                      src={
                        props && props.record && props.record.introScreen
                          ? uploadApiUrl + props.record.introScreen
                          : ""
                      }
                    />
                  ) : null
                }
              </FormDataConsumer>

              <FileInput
                label="label.logo"
                multiple={false}
                source="settings.company_logo"
                accept="image/*"
                validate={requiredValidate}
              >
                <ImageField source="src" title="title" />
              </FileInput>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) =>
                  formData &&
                  formData.settings &&
                  formData.settings.company_logo &&
                  !formData.settings.company_logo.src ? (
                    <img
                      alt=""
                      style={{ width: 350, height: 200 }}
                      src={
                        props &&
                        props.record &&
                        props.record.settings &&
                        props.record.settings.company_logo
                          ? uploadApiUrl + props.record.settings.company_logo
                          : ""
                      }
                    />
                  ) : null
                }
              </FormDataConsumer>
            </div>
          </Tab>
          <Tab label="Privacy Policy">
            <div style={{ display: "inline" }}>
              {/* <SectionTitle label="resources.companies.fieldGroups.privacy" /> */}
              <RichTextInput
                label="label.privacy_Policy"
                source="metaInfo.company.policy"
                validate={requiredValidate}
              />
            </div>
          </Tab>
          <Tab label="License of User">
            {/* <SectionTitle label="resources.companies.fieldGroups.license" /> */}
            <RichTextInput
              label="label.license_of_User"
              source="metaInfo.company.license"
              validate={requiredValidate}
            />
          </Tab>
          <Tab label="About Us">
            {/* <SectionTitle label="resources.companies.fieldGroups.about" /> */}
            <RichTextInput
              label="label.about_us"
              source="metaInfo.company.about"
              validate={requiredValidate}
            />
          </Tab>
          <Tab label="News Feed">
            {/* <SectionTitle label="resources.companies.fieldGroups.news_feed" /> */}
            <TextInput
              fullWidth
              label="label.title"
              source="newsContent.title"
            />

            <TextInput
              fullWidth
              multiline
              label="label.description"
              source="newsContent.description"
            />

            <ImageInput
              label="label.image"
              multiple={false}
              source="newsContent.image"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <FormDataConsumer>
              {({ formData }: { formData: any }) =>
                formData &&
                formData.newsContent &&
                formData.newsContent.image &&
                !formData.newsContent.image.src ? (
                  <img
                    alt=""
                    style={{ width: 350, height: 200 }}
                    src={
                      props &&
                      props.record &&
                      props.record.newsContent &&
                      props.record.newsContent.image
                        ? uploadApiUrl + props.record.newsContent.image
                        : ""
                    }
                  />
                ) : null
              }
            </FormDataConsumer>
            <BooleanInput
              source="isNewsFeed"
              label="label.news_Feed"
              defaultValue={false}
            />
          </Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props} validate={validatePasswords}>
      {props && Object.keys(props.record).length !== 0 ? (
        <CustomActionToolbar record={props.record}></CustomActionToolbar>
      ) : null}
      <SectionTitle label="resources.companies.fieldGroups.basic" />
      <TextInput
        fullWidth
        autoFocus
        source="name"
        formClassName={classes.customSize}
        validate={requiredValidate}
      />
      <SectionTitle label="" />
      <TextInput
        source="phone"
        // disabled
        // fullWidth
        formClassName={classes.name}
        validate={requiredValidate}
      />
      <TextInput
        type="email"
        // disabled
        source="email"
        validation={{ email: true }}
        fullWidth
        formClassName={classes.email}
        validate={[required(), email()]}
      />
      <SectionTitle label="" />
      <TextInput
        label="label.contact_phone"
        source="metaInfo.contact.phone"
        formClassName={classes.name}
        validate={requiredValidate}
      />
      <TextInput
        label="label.contact_email"
        type="email"
        source="contactEmail"
        validation={{ email: true }}
        fullWidth
        formClassName={classes.email}
        validate={[required(), email()]}
      />
      <SectionTitle label="" />
      <TextInput
        // disabled
        label="label.employee_size"
        source="numberOfMaxUser"
        formClassName={classes.name}
        validate={requiredValidate}
      />
      <TextInput
        // disabled
        label="label.visitor_size"
        source="numberOfMaxVisitor"
        formClassName={classes.code}
        validate={requiredValidate}
      />
      <TextInput
        multiline
        label="label.company_Address"
        source="metaInfo.address.lines"
        formClassName={classes.code}
        validate={requiredValidate}
      />
      <SectionTitle label="" />
      <TextInput
        label="label.latitude"
        source="metaInfo.address.lat"
        formClassName={classes.name}
        validate={latitudeValidation}
      />
      <TextInput
        label="label.longitude"
        source="metaInfo.address.lng"
        formClassName={classes.code}
        validate={longitudeValidation}
      />
      <TextInput
        label="label.timezone"
        source="metaInfo.timezoneOffset"
        formClassName={classes.code}
        validate={timeZoneValidate}
      />

      {/* <SectionTitle label="resources.companies.fieldGroups.contact" />

      <SectionTitle label="" />


      <SectionTitle label="resources.companies.fieldGroups.credential" />


      <SectionTitle label="resources.customers.fieldGroups.password" /> */}
      <SectionTitle label="" />
      {user?.isLDAP ? null : (
        <>
          <PasswordInput source="password" formClassName={classes.password} />
          <PasswordInput
            source="confirm_password"
            formClassName={classes.confirm_password}
          />
        </>
      )}

      <SectionTitle label="" />
      <TextInput
        label="label.tokenExpire"
        source="tokenExpireTime"
        formClassName={classes.name}
        validate={tokenExpireValidation}
      />
      <RadioButtonGroupInput
        source="turnoutDevice"
        optionText="name"
        row
        fullWidth
        formClassName={classes.code}
        optionValue="id"
        choices={turnoutDevices}
      />

      <SectionTitle label="" />
      <TextInput
        fullWidth
        source="attendance_server_URL"
        formClassName={classes.edgeUrl}
        // validate={commonValidate}
        label="Attendance Server Url"
      />
      <TextInput
        fullWidth
        source="registrationServerURL"
        formClassName={classes.edgeUrl}
        // validate={commonValidate}
        label="Registration Server URL"
      />
      <SectionTitle label="" />
      <CheckboxGroupInput
        source="assign_addons"
        choices={addons}
        optionText="name"
        optionValue="_id"
      />
      <SectionTitle label="Others" />

      {/* <SectionTitle label="label.token_setting" /> */}
      {/* <TextInput
        label="Ideal Token Expire Time"
        source="idealTokenExpireTime"
        formClassName={classes.name}
        validate={idealTokenExpireValidation}
      /> */}

      {/* <SectionTitle label="label.turnoutDevice" /> */}

      <BooleanInput
        source="isTurnoutM"
        label="label.turnoutM"
        formClassName={classes.name}
        defaultValue={false}
      />
      <BooleanInput
        source="isMask"
        label="label.mask"
        formClassName={classes.code}
        defaultValue={false}
      />
      <BooleanInput
        source="isAdvertisement"
        label="label.advertisement"
        defaultValue={false}
        formClassName={classes.code}
      />
      <SectionTitle label="" />
      <BooleanInput
        formClassName={classes.name}
        label="label.status"
        source="isActive"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={true}
        label="CMS Is Active"
        source="cmsIsActive"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={true}
        label="Is Client"
        source="isClientConfigration"
      />
      <SectionTitle label="" />
      <BooleanInput
        formClassName={classes.name}
        defaultValue={true}
        label="Is College"
        source="isCollege"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Visitor Verification Required"
        source="isVisitorVerificationRequired"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Visitor GatePass"
        source="isVisitorGatepass"
      />

      <SectionTitle label="" />

      {/* <FormDataConsumer formClassName={classes.name}>
        {({ formData }: { formData: any }) => (
          
        )}
      </FormDataConsumer> */}

      <BooleanInput
        label="Is Reporting Manager"
        source="isReportingManager"
        formClassName={classes.name}
      />

      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Remarks"
        source="isAttendanceRemark"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Change Attendance"
        source="isChangeAttendance"
      />

      <SectionTitle label="" />

      <BooleanInput
        label="Document Management"
        source="isDocumentManagement"
        formClassName={classes.name}
      />

      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Not Recognized"
        source="isUnknownAttendance"
      />
      <BooleanInput
        formClassName={classes.code}
        defaultValue={false}
        label="Sync Attendance"
        source="isSyncAttendance"
      />

      <SectionTitle label="" />

      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
        className={classes.tab}
      >
        <Tab label="White Label">
          {/* <SectionTitle label="resources.companies.fieldGroups.white_label" /> */}
          <TextInput
            fullWidth
            label="label.titleTag"
            source="settings.heading_name"
            validate={tagValidation}
          />

          <TextInput
            fullWidth
            label="label.header_text"
            source="settings.heading_text"
            validate={requiredValidate}
          />

          {/* <TextInput
        fullWidth
        label="Footer text"
        source="settings.footer_text"
        validate={requiredValidate}
      /> */}
          {/* <FileInput
        source="settings.company_logo"
        label="Logo"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
        multiple={false}
      >
        <ImageField source="src" title="title" />
      </FileInput> */}
          <FileInput
            source="introScreen"
            label="label.introScreen"
            accept="image/*"
            // placeholder={<p>Drop your file here</p>}
            // options={{ onDrop: callback }}
            validate={requiredValidate}
          >
            <ImageField source="src" title="title" />
          </FileInput>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData && formData.introScreen && !formData.introScreen.src ? (
                <img
                  alt=""
                  style={{ width: 350, height: 200 }}
                  src={
                    props && props.record && props.record.introScreen
                      ? uploadApiUrl + props.record.introScreen
                      : ""
                  }
                />
              ) : null
            }
          </FormDataConsumer>

          <FileInput
            label="label.logo"
            multiple={false}
            source="settings.company_logo"
            accept="image/*"
            validate={requiredValidate}
          >
            <ImageField source="src" title="title" />
          </FileInput>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData &&
              formData.settings &&
              formData.settings.company_logo &&
              !formData.settings.company_logo.src ? (
                <img
                  alt=""
                  style={{ width: 350, height: 200 }}
                  src={
                    props &&
                    props.record &&
                    props.record.settings &&
                    props.record.settings.company_logo
                      ? uploadApiUrl + props.record.settings.company_logo
                      : ""
                  }
                />
              ) : null
            }
          </FormDataConsumer>
        </Tab>
        <Tab label="Privacy Policy">
          {/* <SectionTitle label="resources.companies.fieldGroups.privacy" /> */}
          <RichTextInput
            label="label.privacy_Policy"
            source="metaInfo.company.policy"
            validate={requiredValidate}
          />
        </Tab>
        <Tab label="License of User">
          {/* <SectionTitle label="resources.companies.fieldGroups.license" /> */}
          <RichTextInput
            label="label.license_of_User"
            source="metaInfo.company.license"
            validate={requiredValidate}
          />
        </Tab>
        <Tab label="About Us">
          {/* <SectionTitle label="resources.companies.fieldGroups.about" /> */}
          <RichTextInput
            label="label.about_us"
            source="metaInfo.company.about"
            validate={requiredValidate}
          />
        </Tab>
        <Tab label="News Feed">
          {/* <SectionTitle label="resources.companies.fieldGroups.news_feed" /> */}
          <TextInput fullWidth label="label.title" source="newsContent.title" />

          <TextInput
            fullWidth
            multiline
            label="label.description"
            source="newsContent.description"
          />

          <ImageInput
            label="label.image"
            multiple={false}
            source="newsContent.image"
            accept="image/*"
            placeholder={
              <p>
                Drop a picture to <span style={{ color: "blue" }}>upload </span>
                , or <span style={{ color: "blue" }}>click </span>to select it.
              </p>
            }
            classes={{ dropZone: classes.dropZone, p: classes.pTag }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData &&
              formData.newsContent &&
              formData.newsContent.image &&
              !formData.newsContent.image.src ? (
                <img
                  alt=""
                  style={{ width: 350, height: 200 }}
                  src={
                    props &&
                    props.record &&
                    props.record.newsContent &&
                    props.record.newsContent.image
                      ? uploadApiUrl + props.record.newsContent.image
                      : ""
                  }
                />
              ) : null
            }
          </FormDataConsumer>
          <BooleanInput
            source="isNewsFeed"
            label="label.news_Feed"
            defaultValue={false}
          />
        </Tab>
        <Tab label="SMS Config">
          <div style={{ display: "inline-block" }}>
            <FormDataConsumer>
              {({ formData }: { formData: any }) =>
                formData?.smsConfig?.isActive && (
                  <>
                    <TextInput
                      className={classes.name}
                      label="method"
                      source="smsConfig.method"
                      validate={requiredValidate}
                    />
                    <TextInput
                      // className={classes.code}
                      fullWidth
                      label="url"
                      source="smsConfig.url"
                      validate={requiredValidate}
                    />
                  </>
                )
              }
            </FormDataConsumer>
          </div>

          <BooleanInput
            formClassName={classes.name}
            defaultValue={false}
            label="Status"
            source="smsConfig.isActive"
          />
        </Tab>
        <Tab label="Active Directory Config">
          <ActiveDirectoryConfig />
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  );
};

// const CompanyTitle: FC<FieldProps<Company>> = ({ record }) =>
//   record ? <FullNameField record={record} /> : null;
const requiredValidate = [required()];
const timeZoneValidate = [required(), number()];
// const nameValidate = [required(), maxLength(100)];
// const addressVlaidation = [required(), maxLength(200)];
const latitudeValidation = [
  requiredValidate,
  number(),
  maxValue(90),
  minValue(-90),
];
const longitudeValidation = [
  requiredValidate,
  number(),
  maxValue(180),
  minValue(-180),
];
// const employeeSizeValidation = [
//   required(),
//   number(),
//   maxLength(5),
//   minLength(0),
//   minValue(1),
// ];
const tokenExpireValidation = [requiredValidate, number()];
// const idealTokenExpireValidation = [requiredValidate, number()];
const tagValidation = [
  required(),
  maxLength(70),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
export default CommonForm;
