import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "./commonForm";
import { PostEditToolbar } from '../components/PostEditToolbar';

const EmailTemplateEdit = (props: any) => {
  return (
    <Edit undoable={false} title="resources.emailTemplate.edit_title" {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};
export default EmailTemplateEdit;
