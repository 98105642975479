import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));

interface State {
  companyMasters?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  isXsmall?: boolean;
}

const MultiSelectChildCompany: FC<Props> = ({ filter, isXsmall }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getSearchList("companyMasters/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          companyMasters: response && response.data,
        }));
      });
  }, [filter]);

  const { companyMasters } = state;

  return (
    <AutocompleteArrayInput
      resettable
      label="Company (Subsidiary)"
      source="childCompanyIds"
      choices={companyMasters}
      // className={formClassName}
      className={classes.root}
      fullWidth={isXsmall ? false : true}
    />

    // <ReferenceArrayInput
    //   resettable
    //   label="Company (Subsidiary)"
    //   source="childCompanyId"
    //   reference="companyMasters"
    // //   choices=companymaster}
    //   // filter={{ company: formData.company, isActive: true }}
    // // validate={requiredValidate}
    // // formClassName={classes.referenceEmpArray}
    // >
    //   <AutocompleteArrayInput resettable />
    // </ReferenceArrayInput>
  );
};
// const requiredValidate = [required()];
MultiSelectChildCompany.defaultProps = {
  source: "childCompanyIds",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectChildCompany);
