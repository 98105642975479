import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
// import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
// import { Typography } from "@material-ui/core";
// import { Link } from "react-router-dom";
import helperFunc from "../helperFuncs";
import { useTranslate } from "react-admin";
import { Event } from "../types";
import { useMediaQuery, Theme } from "@material-ui/core";
// import companies from "../companies";
import moment from "moment";
// import CompanyIcon from "@material-ui/icons/Business";
interface Props {
  events?: Event[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "50%",
    padding: 16,
  },
  cost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
  },
  mobileCost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
    fontSize: 10,
  },
  listItemText: {
    fontSize: "0.7em", //Insert your required size
  },
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  title: { alignItems: "center" },
}));

const ListUpcomingEvents: FC<Props> = ({ events = [] }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardHeader title={translate("pos.dashboard.table_events")} />
      <List dense={true}>
        {events && events.length > 0 ? (
          events.map((record) => (
            <ListItem
            // key={record._id}
            // button
            // component={Link}
            // to={`/employees`}
            >
              {/* <ListItemAvatar>
              {record.settings.company_logo ? (
                <Avatar src={record.settings.company_logo.src} />
              ) : (
                <Avatar />
              )}
            </ListItemAvatar> */}
              <ListItemText
                classes={{
                  primary: isSmall ? classes.listItemText : undefined,
                }}
                primary={record.name}
                secondary={record.type}
              />
              <ListItemSecondaryAction>
                <span className={isSmall ? classes.mobileCost : classes.cost}>
                  {helperFunc.commonDateFormat(record.date)}
                  {/* {new Date(record.date).toLocaleString("en-GB")} */}
                </span>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <ListItem className={classes.title}> No Record Found </ListItem>
        )}
      </List>
      <CardActions style={{ justifyContent: "flex-end" }}>
        {/* <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/employees",
          }}
          className={classes.link}
          title={`${translate("pos.dashboard.read_more")}`}
        >
          <CompanyIcon style={{ paddingRight: "0.5em" }} />
          {translate("pos.dashboard.read_more")}
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default ListUpcomingEvents;
