import DesignationList from "./DesignationList";
import { DesignationIcon } from "../svgIcon";
import { CommonDesignations } from "./DesignationsCommon";

export default {
  list: DesignationList,
  create: CommonDesignations,
  edit: CommonDesignations,
  icon: DesignationIcon,
};
