import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../leaveTypes/commonForm";

const LeaveCreate = (props: any) => {
  return (
    <Create {...props} title="resources.leaveTypes.create_title">
      <CommonForm redirect={'/EmployeeLeaveMaster'} {...props} />
    </Create>
  );
};


export default LeaveCreate;
