import EmailTemplateList from "./EmailTemplateList";
import EmailTemplateEdit from "./EmailTemplateEdit";
import EmailTemplateCreate from "./EmailTemplateCreate";
import { EmailTemplatesIcon } from "../svgIcon";

export default {
  list: EmailTemplateList,
  create: EmailTemplateCreate,
  edit: EmailTemplateEdit,
  icon: EmailTemplatesIcon,
};
