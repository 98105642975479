import EmployeeIcon from "@material-ui/icons/Person";
import EmployeeList from "./EmployeeList";
import { EmployeeFormCommon } from "./EmployeeFormCommon";

export default {
  list: EmployeeList,
  create: EmployeeFormCommon,
  edit: EmployeeFormCommon,
  icon: EmployeeIcon,
};
