import * as React from "react";
import { FC } from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  downloadCSV,
  FormDataConsumer,
  required,
  TopToolbar,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FunctionField,
  DateInput,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { Route, RouteChildrenProps } from "react-router-dom";
import { ListComponentProps } from "../types";
import jsonExport from "jsonexport/dist";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import AttandanceShow from "./AttendanceShow";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import MultiSelectEmployeeInput from "../components/MultiSelectEmployeeInput";
// import { DateInput } from 'react-admin-date-inputs';

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters(
      {
        fromDate: helperFunc.startOfMonth(1),
        toDate: helperFunc.addDays(0),
      },
      []
    );
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {/* <Box mr={2}></Box> */}
                    {helperFunc.getUserData().type === "super" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <CompanyReferenceInput
                            helperText={false}
                          ></CompanyReferenceInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ||
                    helperFunc.getUserData().type === "company" ||
                    helperFunc.getUserData().designation === "HR" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <MultiSelectEmployeeInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          />
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          defaultValue={helperFunc.startOfMonth(1)}
                          validate={[required()]}
                          label="label.fromDate"
                          source="fromDate"
                          helperText={false}
                          alwaysOn
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          label="label.toDate"
                          defaultValue={helperFunc.addDays(0)}
                          validate={[required()]}
                          source="toDate"
                          helperText={false}
                          alwaysOn
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    // basePath,
    // total,
    // resource,
    // currentSort,
    // filterValues,
    // exporter,
    // filters,
    // showFilter,
    // displayedFilters,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      {/* {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })} */}
      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      /> */}
      {/* <ImportButton basePath={basePath} {...props} /> */}
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    // marginTop: "1em",
  },
  name: { display: "inline-block" },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
});

// const Aside = () => (
//   <div style={{ width: 200, margin: "1em" }}>
//     <Typography variant="h6">Post details</Typography>
//     <Typography variant="body2">
//       Posts will only be published one an editor approves them
//     </Typography>
//   </div>
// );

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      time,
      out,
      name,
      date,
      mode,
      id,
      _id,
      confidence,
      lat,
      long,
      createdAt,
      isActive,
      isDeleted,
      updatedAt,
      employee,
      employeeCode,
      company,
      department,
      designation,
      shift,
      device,
      isMaskIn,
      isMaskOut,
      workingHours,
      totalHours,
      __v,
      geoPoints,
      inMode,
      outMode,
      camera_ID,
      status,
      ...dataToExport
    } = record; // omit fields

    dataToExport.in = record.in; // add a field
    dataToExport.Out = record.out; // add a field
    dataToExport.Name = name;
    dataToExport.EmployeeCode = employeeCode;
    dataToExport.Designation = designation && designation.name;
    dataToExport.Department = department && department.name;
    dataToExport.Shift = shift && shift.name;
    dataToExport.Date = helperFunc.commonDateFormat(date);
    // dataToExport.mode = record.mode; // add a field
    dataToExport.InLocation =
      geoPoints && geoPoints.in && geoPoints.in.location;
    dataToExport.OutLocation =
      geoPoints && geoPoints.out && geoPoints.out.location;

    dataToExport.CameraID = record.camera_ID; // add a field
    dataToExport.Status = record.status; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "Name",
        "EmployeeCode",
        "Designation",
        "Department",
        "Shift",
        "CameraID",
        "Date",
        "in",
        "Out",
        "InLocation",
        "OutLocation",
        "Status",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "AttendanceReport"); // download as 'xxxx.csv` file
    }
  );
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const AttendanceReportList: FC<ListComponentProps<{ id: string }>> = (
  props
) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Route path="/attendance/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          // !!(match && match.params && match.params.id !== "create");

          return (
            <>
              {isXSmall ? (
                <h2
                  style={{ fontSize: 30, backgroundColor: "#fff", padding: 10 }}
                >
                  Attendance Request
                </h2>
              ) : null}
              <div
                className={open === true ? "overlay" : ""}
                onClick={() => handleDrawerClose("fromlist")}
              />
              <div
                style={
                  isXSmall
                    ? {
                        width: window.screen.width,
                        overflowX: "scroll",
                      }
                    : {}
                }
              >
                <List
                  className="list-view2"
                  bulkActionButtons={false}
                  title="resources.attendance.request"
                  exporter={exporter}
                  actions={
                    <ListActions
                      {...props}
                      handleDrawerOpen={handleDrawerOpen}
                    />
                  }
                  {...props}
                  filters={
                    <AttendanceFilter
                      {...props}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                  }
                  perPage={20}
                  filterDefaultValues={{
                    fromDate: helperFunc.startOfMonth(0),
                    toDate: helperFunc.addDays(1),
                    company: helperFunc.selectedCompany(),
                  }}
                  pagination={
                    <Pagination
                      labelRowsPerPage="Records per page"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                    />
                  }
                  sort={{ field: "createdAt", order: "DESC" }}
                >
                  <Datagrid>
                    <TextField source="company.name" label="Company" />
                    <TextField source="employee.name" label="Employee" />
                    <TextField
                      source="source"
                      label="Source"
                      sortable={false}
                    />
                    <TextField
                      source="failedType"
                      label="Failed Type"
                      sortable={false}
                    />
                    <TextField source="success" />
                    <FunctionField
                      label="label.createdAt"
                      sortBy="createdAt"
                      // sortByOrder="DESC"
                      render={(record: any) =>
                        `${
                          record["createdAt"]
                            ? helperFunc.commonDateFormat(
                                record["createdAt"],
                                "HH:mm:ss"
                              )
                            : "N/A"
                        }  `
                      }
                    />
                    <AttandanceShow {...props} />
                  </Datagrid>
                </List>
              </div>
            </>
          );
        }}
      </Route>
    </div>
  );
};

export default AttendanceReportList;
