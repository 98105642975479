// import VisitorIcon from "@material-ui/icons/PersonOutline";
import AttendanceList from "./AttendanceList";
// import AttendanceEdit from "./AttendanceEdit";
// import VisitorCreate from "./VisitorCreate";

export default {
  list: AttendanceList,
  // create: VisitorCreate,
  // edit: AttendanceEdit,
  // icon: VisitorIcon,
};
