import * as React from "react";
import { forwardRef } from "react";
import {
  AppBar,
  UserMenu,
  MenuItemLink,
  useTranslate,
  Link,
} from "react-admin";
import { Typography, useMediaQuery } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import ProfileIcon from "@material-ui/icons/PermContactCalendar";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { usePermissions } from "react-admin";
import Logo from "./Logo";
import helperFunc from "../helperFuncs";
const useStyles = makeStyles({
  title: {
    flex: 1.3,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const UserName = forwardRef<any, any>((props, ref) => {
  const user = helperFunc.getUserData();
  return (
    <MenuItemLink
      // ref={ref}
      to="#"
      primaryText={user.name}
      // leftIcon={<ProfileIcon />}
      // onClick={props.onClick}
    />
  );
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate("pos.configuration")}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});

const ProfileMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/profile/my-profile"
      primaryText={translate("pos.profile")}
      leftIcon={<ProfileIcon />}
      onClick={props.onClick}
    />
  );
});
const ConfigMenu = forwardRef<any, any>((props, ref) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();

  return permissions === "company" ? (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate("pos.configuration")}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  ) : null;
});
const SettingMenu = forwardRef<any, any>((props, ref) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();

  return permissions === "company" ? (
    <MenuItemLink
      ref={ref}
      to="/settings/my-company"
      primaryText={translate("pos.companyProfile")}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  ) : null;
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <UserName />
    {/* <ConfigurationMenu /> */}
    <ProfileMenu />
    <ConfigMenu />
    <SettingMenu />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      {isSmall ? null : (
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      )}

      <Logo />

      <span className={classes.spacer} />
      <Tooltip title="About Turnout" arrow>
        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/about-turnout",
          }}
        >
          <img src="/turnout-white1.png" alt="About Turnout " />{" "}
        </Button>
      </Tooltip>
    </AppBar>
  );
};

export default CustomAppBar;
