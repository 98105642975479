import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  useDataProvider,
  useTranslate,
} from "react-admin";
import {
  Theme,
  useMediaQuery,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useRedirect } from "ra-core";
import { makeStyles } from "@material-ui/core/styles";
import { streamStyle } from "../helperStyle";
import {
  codeValidate,
  nameValidate,
  SectionTitle,
  validate,
} from "../helperValidate";
import { styleData, user } from "../helperConst";
import { PostToolbar } from "./PostToolbar";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { toastError, toastInfo } from "../components/toast";
import { SmFormHeader } from "../components/SmFormHeader";
import DialogueBox from "../components/ConfirmDialogueBox";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import { toast } from "react-toastify";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(streamStyle);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [allotments, setAllotments] = useState(Array);
  const [objStream, setObjStream] = useState({});
  const [objCourse, setObjCourse] = useState({});
  const [objSemester, setObjSemester] = useState({});
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [index, setIndex] = useState(Number);
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const header = ["Stream", "Course", "Semester", "Action"];
  const { location, resource, id, basePath } = props;

  const addAllotment = (data: any) => {
    if (data && (data.stream === "" || data.stream === undefined)) {
      toastError("Please Select Stream");
      return;
    } else if (data && (data.course === "" || data.course === undefined)) {
      toastError("Please Select Course");
      return;
    } else if (data && (data.semester === "" || data.semester === undefined)) {
      toastError("Please Select Semester");
      return;
    } else {
      let duplicate = false;
      allotments.filter((s: any, sIdx: any) => {
        if (
          s.stream._id === data.stream &&
          s.course._id === data.course &&
          s.semester._id === data.semester
        ) {
          duplicate = true;
        }
      });

      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
      } else {
        if (Object.keys(objCourse).length == 0) {
          toastError("Course is not Valid");
        } else if (Object.keys(objSemester).length == 0) {
          toastError("Semester is not Valid");
        } else {
          setAllotments([
            ...allotments,
            {
              stream: objStream,
              course: objCourse,
              semester: objSemester,
            },
          ]);
        }
      }
      setObjStream({});
      setObjCourse({});
      setObjSemester({});

      setEmptyValue(true);
      setTimeout(function () {
        setEmptyValue(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (location && location.pathname !== `/${resource}/create`) {
      const resourcePath = `${resource}/get/${id}`;
      dataProvider
        .callCustom(resourcePath, {})
        .then((response: any) => {
          setAllotments(
            response && response.data && response.data.streamAndCourseData
          );
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const streamData = (data: any) => {
    setObjStream(data);
    setObjCourse({});
    setObjSemester({});
  };

  const courseData = (data: any) => {
    setObjCourse(data);
    setObjSemester({});
  };
  const semesterData = (data: any) => {
    setObjSemester(data);
  };

  const saveForm = (formdata: any) => {
    const resourceName = translate(`resources.${resource}.name`, resource);
    const resourcePath =
      location && location.pathname === `/${resource}/create`
        ? `${resource}/create`
        : `${resource}/update/${id}`;

    dataProvider
      .callCustom(resourcePath, {
        company: formdata.company ? formdata.company : "",
        streamAndCourseData: allotments,
        name: formdata && formdata.name ? formdata.name : null,
        code: formdata && formdata.code ? formdata.code : null,
      })
      .then((response: any) => {
        location && location.pathname === `/${resource}/create`
          ? toastInfo(`${resourceName} created Succesfully`)
          : toastInfo(`${resourceName} updated Succesfully`);
        redirect("list", basePath);
        setAllotments([]);
      });

    formdata.hideFiled = true;
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any) => {
    setShowDialog(true);
    setIndex(index);
  };

  const removeAllotment = (idx: any) => {
    setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
  };

  return (
    <>
      <SmFormHeader {...props} />
      <SimpleForm {...props} save={saveForm} toolbar={<PostToolbar />}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput
                record={formData}
                labelName="College"
              ></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />

        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <TextInput
              autoFocus
              id="classRoomName"
              source="name"
              formClassName={classes.name}
              validate={nameValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              source="code"
              id="classRoomCode"
              formClassName={classes.code}
              validate={codeValidate}
              format={helperFunc.codeFormatter}
            />
          </div>
        </div>

        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />

        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <StreamSelectInput
              dataManage={streamData}
              emptyValue={{ emptyValue }}
              labelName="Stream *"
              textWrap={true}
            ></StreamSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={isSmall || isXsmall ? classes.name : classes.code}
        >
          {({ formData }: { formData: any }) => {
            return (
              <CourseSelectInput
                filter={{ stream: formData.stream, isActive: true }}
                dataManage={courseData}
                labelName="Course *"
                objCourse={objCourse}
                textWrap={true}
              ></CourseSelectInput>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={isSmall || isXsmall ? classes.name : classes.code}
        >
          {({ formData }: { formData: any }) => (
            <SemesterSelectInput
              filter={{
                stream: formData.stream,
                course: formData.course,
                isActive: true,
              }}
              dataManage={semesterData}
              labelName="Semester *"
              objSemester={objSemester}
              textWrap={true}
            ></SemesterSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
          {({ formData }: { formData: any }) => (
            <Button
              // onClick={() => addAllotment(formData)}
              id="classroomAllocate"
              onClick={() => addAllotment(formData)}
              variant="outlined"
              size="small"
              color="primary"
              type="button"
              className={isXsmall ? undefined : classes.allotmentBtn}
              // disabled={}
            >
              Allocate
            </Button>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <>
                <TableContainer
                  className={isXsmall ? classes.isSmallTable : classes.table}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {header.map((item: any) => {
                          return (
                            <TableCell className={classes.tableHead}>
                              {item}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allotments && allotments.length > 0
                        ? allotments.map((allotment: any, index: number) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell
                                  className={classes.tableValue}
                                  align="left"
                                >
                                  {allotment.stream
                                    ? allotment.stream.name
                                    : ""}
                                </TableCell>
                                <TableCell
                                  className={classes.tableValue}
                                  align="left"
                                >
                                  {allotment.course
                                    ? allotment.course.name
                                    : ""}
                                </TableCell>
                                <TableCell
                                  className={classes.tableValue}
                                  align="left"
                                >
                                  {allotment.semester
                                    ? allotment.semester.name
                                    : ""}
                                </TableCell>

                                <TableCell
                                  className={classes.tableValue}
                                  align="left"
                                >
                                  <Button
                                    onClick={() => handleOpenClick(index)}
                                    variant="outlined"
                                    className={classes.removeAllotmentBtn}
                                    type="button"
                                  >
                                    Remove{" "}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : null}

                      {allotments && allotments.length === 0 ? (
                        <TableRow key="no Allotments">
                          <TableCell
                            className={classes.tableValue}
                            colSpan={4}
                            align="center"
                          >
                            No record found.
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <DialogueBox
                  handleCloseClick={handleCloseClick}
                  open={showDialog}
                  remove={() => removeAllotment(index)}
                  itemName="Allotment"
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </>
  );
};

export default CommonForm;
