import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  AutocompleteInput,
  BooleanInput,
  maxLength,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FileInput,
  ImageField,
  RadioButtonGroupInput,
  CheckboxGroupInput,
} from "react-admin";
import { regex } from "ra-core";
import helperFunc from "../helperFuncs";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ShiftSelectInput from "../components/ShiftSelectInput";
import DepartmentSelectInput from "../components/DepartmentSelectInput";
import { alertNotification, gender, type } from "../helperChoice";
import { notificationStyles } from "../helperStyle";
import DesignationSelectInput from "../components/DesignationSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import EmployeeTypeSelectInput from "../components/EmployeeTypeSelectInput";
import WorkplaceSelectInput from "../components/WorkplaceSelectInput";
import MultiSelectChildCompany from "../components/MultiSelectChildCompany";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(notificationStyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <SimpleForm {...props}>
      <div style={{ display: "inline" }}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer alwaysOn>
          {({ formData }: { formData: any }) => (
            <TextInput
              source="title"
              formClassName={isXsmall ? classes.name : classes.name}
              validate={[
                required(),
                maxLength(10),
                regex(/.*\S.*/, "Only whitespaces are not allowed"),
              ]}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }: { formData: any }) => (
            <TextInput
              style={{ marginLeft: "32px" }}
              source="description"
              formClassName={isXsmall ? classes.name : classes.code}
              validate={[
                required(),
                maxLength(10),
                regex(/.*\S.*/, "Only whitespaces are not allowed"),
              ]}
            />
          )}
        </FormDataConsumer>

        <RadioButtonGroupInput
          validate={requiredValidate}
          style={{ width: "300px", minWidth: "300px" }}
          source="gender"
          formClassName={isXsmall ? null : classes.name}
          choices={gender}
        />

        <SectionTitle label="" />

        <FileInput
          label="label.image"
          multiple={false}
          source="image"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </FileInput>
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) =>
            formData && formData.image && !formData.image.src ? (
              <img
                style={{ width: 350, height: 200 }}
                alt={
                  props && props.record && props.record.image
                    ? uploadApiUrl + props.record.image
                    : ""
                }
                src={
                  props && props.record && props.record.image
                    ? uploadApiUrl + props.record.image
                    : ""
                }
              />
            ) : null
          }
        </FormDataConsumer>
      </div>
    </SimpleForm>
  ) : (
    <SimpleForm {...props}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <SectionTitle label="" />
      <div className="d-flex">
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <TextInput
              source="title"
              formClassName={isXsmall ? classes.name : classes.name}
              validate={[
                required(),
                maxLength(10),
                regex(/.*\S.*/, "Only whitespaces are not allowed"),
              ]}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => (
            <TextInput
              style={{ marginLeft: "32px" }}
              source="description"
              formClassName={isXsmall ? classes.name : classes.code}
              validate={[
                required(),
                maxLength(10),
                regex(/.*\S.*/, "Only whitespaces are not allowed"),
              ]}
            />
          )}
        </FormDataConsumer>
      </div>

      <FileInput
        label="label.image"
        multiple={false}
        source="image"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </FileInput>
      <FormDataConsumer formClassName={classes.name}>
        {({ formData }: { formData: any }) =>
          formData && formData.image && !formData.image.src ? (
            <img
              style={{ width: 350, height: 200 }}
              src={
                props && props.record && props.record.image
                  ? uploadApiUrl + props.record.image
                  : ""
              }
            />
          ) : null
        }
      </FormDataConsumer>

      <SectionTitle label="" />
      <CheckboxGroupInput
        // validate={requiredValidate}
        label="Notify By"
        style={{ width: "306px", minWidth: "306px" }}
        source="alert"
        formClassName={isXsmall ? null : classes.name}
        choices={alertNotification}
        multiple={true}
      />
      <SectionTitle label="Audience Filter" />

      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <MultiSelectChildCompany
            filter={{ isActive: true, company: formData.company }}
            source="childCompanyIds"
          ></MultiSelectChildCompany>
        )}
      </FormDataConsumer>

      {/* <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <ChildCompanySelectInput
            filter={{ isActive: true, company: formData.company }}
          ></ChildCompanySelectInput>
        )}
      </FormDataConsumer> */}

      <EmployeeTypeSelectInput
        formClassName={isXsmall ? undefined : classes.code}
        filter={{ isActive: true }}
      ></EmployeeTypeSelectInput>

      <CheckboxGroupInput
        // validate={requiredValidate}
        style={{ marginLeft: "32px", width: "290px", minWidth: "290px" }}
        source="gender"
        formClassName={isXsmall ? null : classes.code}
        choices={gender}
        multiple={true}
      />

      <SectionTitle label="" />

      {helperFunc.getUserData().type == "super" ||
      helperFunc.getUserData().type == "company" ||
      helperFunc.getUserData().designation == "HR" ? (
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <DesignationSelectInput
              filter={{ company: formData.company, isActive: true }}
            />
          )}
        </FormDataConsumer>
      ) : null}

      <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
        {({ formData }: { formData: any }) => (
          <WorkplaceSelectInput
            filter={{ company: formData.company, isActive: true }}
          ></WorkplaceSelectInput>
        )}
      </FormDataConsumer>

      <SectionTitle label="" />

      <BooleanInput
        formClassName={classes.name}
        source="isActive"
        label="label.status"
      />
    </SimpleForm>
  );
};
const nameValidate = [required(), maxLength(50)];
const codeValidate = [required(), maxLength(20)];
const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
