import LeaveEdit from "./LeaveEdit";
import LeaveCreate from "./LeaveCreate";
import TabPanel from "./TabPanel";
import { LeaveIcon } from "../svgIcon";

export default {
  list: TabPanel,
  create: LeaveCreate,
  edit: LeaveEdit,
  icon: LeaveIcon,
};
