import { AttendanceIcon, UnknownAttendanceIcon } from "../svgIcon";
import MyTeamList from "./MyTeamList";
import { TeamAttendanceCommon } from "./teamAttendanceCommon";

export default {
  list: MyTeamList,
  create: TeamAttendanceCommon,
  edit: TeamAttendanceCommon,
  icon: AttendanceIcon,
};
