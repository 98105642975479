import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  states?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  validate?: any;
}

const StateSelectInput: FC<Props> = ({ filter, validate }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("states/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          states: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { states } = state;

  return (
    <SelectInput
      resettable
      label="State"
      className={classes.root}
      source="state"
      choices={states}
      // validate={requiredValidate}
    />
  );
};
StateSelectInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};
const requiredValidate = [required()];

export default memo<Props>(StateSelectInput);
