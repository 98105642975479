import * as React from "react";
import { SaveButton, Toolbar } from "react-admin";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";

export const PostToolbar = (props: any) => (
  <Toolbar {...props} pristine={false}>
    <SaveButton />
    {props && props.record && Object.keys(props.record).length > 0 ? (
      <DeleteButtonWithConfirmation
        undoable={false}
        titleSource={props && props.titleSource ? props.titleSource : "name"}
        resource={props.resource}
      />
    ) : null}
  </Toolbar>
);
