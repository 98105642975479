import React from "react";
import axios from "axios";
import { useDataProvider } from "react-admin";
const MobileTest = () => {
  const handleApi = () => {
    const startTime = performance.now();

    const resourcePath =
      "https://api.turnout.cloud/api/cms/dashboard/mobile_dashboard";

    axios
      .post(
        resourcePath,
        {
          startDate: startTime,
          endDate: performance.now(),
        },
        {
          headers: {
            "x-access-token":
              "/myaPhhqw0zxMbPC6QS63mpViCHk6C+Uyiw6BL2m5sevL6CG6pOk1pAcDtSsJyOTsfcc+4pcYYTVJp0HwXJHzw==",
          },
        }
      )
      .then((res) => {
        const endTime = performance.now();
        alert(endTime - startTime);
      });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={handleApi}
    >
      <button>Timer Api Hit</button>
    </div>
  );
};

export default MobileTest;
