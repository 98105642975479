import * as React from "react";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Employee } from "../types";

const UnknownUploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  root: { width: 25, maxWidth: 25, maxHeight: 25 },
  media: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
  },
  logo: {
    width: "100%",
    height: "inherit",
  },
});
interface State {
  images?: string;
}
const AttendanceLogo: FC<FieldProps<Employee>> = ({ record }) => {
  const classes = useStyles();
  if (record && record.image) {
    return (
      <img
        src={
          record.image
            ? UnknownUploadApiUrl + record.image
            : UnknownUploadApiUrl && UnknownUploadApiUrl + record
        }
        alt="Team Statistics"
        className={classes.logo}
      />
    );
  } else {
    return (
      <img
        src={UnknownUploadApiUrl && UnknownUploadApiUrl + record}
        alt="Team Attendance"
        className={classes.logo}
      />
    );
  }
};

export default AttendanceLogo;
