import React from "react";
import { TextInput, required, NumberInput, BooleanInput } from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SectionTitle } from "../../helperValidate";

type Props = {};

const useStyles = makeStyles({
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
});

const LDAP = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <SectionTitle label="" />
      <div className={classes.name}>
        <TextInput
          label="Host"
          source="activeDirectory.host"
          validate={requiredValidate}
          style={{ width: "500px" }}
        />
      </div>
      <div className={classes.code}>
        <NumberInput
          label="Timeout"
          source="activeDirectory.timeout"
          validate={requiredValidate}
          style={{ width: "256px" }}
        />
      </div>

      <SectionTitle label="" />
      <div className={classes.name}>
        <TextInput
          label="BindDN"
          source="activeDirectory.bindDN"
          validate={requiredValidate}
          style={{ width: "500px" }}
        />
      </div>

      <div className={classes.code}>
        <NumberInput
          label="Connect Timeout"
          source="activeDirectory.connectTimeout"
          validate={requiredValidate}
          style={{ width: "256px" }}
        />
      </div>
      <SectionTitle label="" />
    </>
  );
};

const requiredValidate = [required()];

export default LDAP;
