import helperFunc from "./helperFuncs";
import { regex } from "ra-core";

export const user = helperFunc.getUserData();
export const styleData = { marginLeft: "32px" };
export const margStyleData = { marginLeft: "182px" };
export const margltstyleData = { marginLeft: "5px", marginTop: "10px" };
export const regexWhitespace = regex(
  /.*\S.*/,
  "Only whitespaces are not allowed"
);
export const regexSpecialChar = regex(
  /^[a-zA-Z ]*$/,
  "Only alphabets are allowed"
);
export const regexOnlyNumber = regex(/^\d+$/, "Alphabets are not allowed");
