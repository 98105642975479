import * as React from "react";
import { FC } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import { useMediaQuery, Theme } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";

import CardIcon from "./CardIcon";

interface Props {
  value?: number;
  data?: {};
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "2em",
    marginTop: 20,
  },
  card: {
    display: "flex",
    width: "100%",
    height: "300px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  mobileCard: {
    display: "flex",
    width: "100%",
    height: "180px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  title: {
    padding: "20px",
    fontSize: "1.5rem",
  },
});

const ChartClients: FC<Props> = (data) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data.data || {};

  const keys = Object.keys(dataObj);
  const values = Object.values(dataObj);
  const dataArr: any = [];
  keys.forEach((key, index) => {
    if (key === "activeClient") {
      key = "Active Client";
    }
    if (key === "inActiveClient") {
      key = "In Active Client";
    }
    dataArr.push({
      name: key,
      value: values[index],
    });
  });
  const COLORS = ["#D63330", "#1E77B4", "#FFBB28", "#FF8042"];

  return (
    <div
      className={
        isSmall
          ? classes.mobileCard
          : isXSmall
          ? classes.mobileCard
          : classes.card
      }
    >
      <Typography component="h2" className={classes.title}>
        {translate("pos.dashboard.chart_clients")}
      </Typography>

      <ResponsiveContainer width={300} height={250}>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={dataArr}
            fill="#8884d8"
            label
          >
            {dataArr.map((entry: any, index: any) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartClients;
