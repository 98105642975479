import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";
import helperFunc from "../helperFuncs";
import FullNameField from "./FullNameField";
import { FieldProps, Leave } from "../types";

const LeaveLinkField: FC<FieldProps<Leave>> = (props) => {
  const user = helperFunc.getUserData();
  if (props && props.record && user.designation === "HR") {
    return (
      <Link to={`/leave-management/${props.record._id}`}>
        <FullNameField {...props} />
      </Link>
    );
  } else if (
    props &&
    props.record &&
    props &&
    new Date(props.record.toDate) > new Date() &&
    props.record.isApprove === "pending"
  ) {
    return (
      <Link to={`/leave-management/${props.record._id}`}>
        <FullNameField {...props} />
      </Link>
    );
  } else return <FullNameField {...props} />;
};

// const LeaveLinkField: FC<FieldProps<Leave>> = (props) => {
//   const user = helperFunc.getUserData();
//   return props && props.record && (props.record.isApprove === "pending" && ((user && user.designation == "HR") || (user && user.type == "company"))) ? (
//     <Link to={`/leave-management/${props.record._id}`}>
//       <FullNameField {...props} />
//     </Link>
//   ) : <FullNameField {...props} />;
// }

LeaveLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default LeaveLinkField;
