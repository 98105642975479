import ClassroomList from "./classroomList";
import { CommonClassroom } from "./classroomCommon";
import { ClassroomIcon } from "../svgIcon";

export default {
  list: ClassroomList,
  create: CommonClassroom,
  edit: CommonClassroom,
  icon: ClassroomIcon,
};
