import React, { useState } from "react";
import {
  FormDataConsumer,
  SimpleForm,
  Button,
  useDataProvider,
  TextInput,
  required,
  regex,
  useRefresh,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ReasonSelectInput from "../components/ReasonSelectInput";
import { toastError, toastInfo } from "../components/toast";

const Remarks = (props: any) => {
  const { clickedEmp, handleCloseClick } = props;
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [reasonObj, setReasonObj] = useState() as any;

  const reasonData = (data: any) => setReasonObj(data);

  const saveForm = (record: any) => {
    if (reasonObj?.id === undefined || reasonObj?.id === "") {
      toastError("Remarks is required");
    } else {
      dataProvider
        .callCustomLocalOne("attendance/set_remarks/" + clickedEmp?._id, {
          reasonId: reasonObj?.id,
          remark: reasonObj?.name === "Other" ? record?.other : reasonObj?.name,
        })
        .then((response: any) => {
          props.handleCloseClick();
          toastInfo("Remarks created Successfully");
          setTimeout(() => {
            refresh();
          }, 200);
        });
    }
  };
  return (
    <SimpleForm validate={() => true} toolbar={null}>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <ReasonSelectInput
            labelName="Remarks *"
            dataManage={reasonData}
          ></ReasonSelectInput>
        )}
      </FormDataConsumer>
      {reasonObj?.name === "Other" && (
        <FormDataConsumer>
          {({ formData }: { formData: any }) => (
            <TextInput
              source="other"
              validate={[
                required(),
                regex(/.*\S.*/, "Only whitespaces are not allowed"),
              ]}
            />
          )}
        </FormDataConsumer>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <Button label="Save" onClick={() => saveForm(formData)}>
                <CheckCircleIcon />
              </Button>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                style={{ color: "#181818cf" }}
              >
                <IconCancel />
              </Button>
            );
          }}
        </FormDataConsumer>
      </div>
    </SimpleForm>
  );
};

export default Remarks;
