import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, Device } from "../types";

const DeviceLinkField: FC<FieldProps<Device>> = (props) =>
  props.record ? (
    <Link to={`/devices/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

DeviceLinkField.defaultProps = {
  source: "camera_ID",
  addLabel: true,
};

export default DeviceLinkField;
