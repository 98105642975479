import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";

interface State {
  classrooms?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  labelName?: string;
}

const ClassroomSelect: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  propsUrl,
  helperText,
  labelName,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const searchUrl = "classroom/search";

  useEffect(() => {
    dataProvider
      .getSearchList(propsUrl ? propsUrl : searchUrl, filter)
      .then((response: any) => {
        response &&
          response.data.forEach((item: any) => {
            item.name = `${item.name}`;
          });
        setState((state) => ({
          ...state,
          classrooms: response && response.data,
        }));
      });
  }, [filter]);

  if (emptyValue && emptyValue.emptyValue) {
    form.change("classroom", undefined);
  }

  const { classrooms } = state;
  const check = (value: any) => {
    classrooms?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "classroom");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Classroom"}
      disabled={record && record.mode === "auto" ? true : false}
      source="classroom"
      validate={validate}
      choices={classrooms}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
    />
  );
};

ClassroomSelect.defaultProps = {
  source: "classroom",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ClassroomSelect);
