import * as React from "react";
import { FC, memo, useState } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
interface State {
  branches?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const MultiSelectBranchInput: FC<Props> = ({ filter }) => {
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("branches/multiple-search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          branches: response && response.data,
        }));
      });
  }, [filter]);

  const { branches } = state;

  return (
    <AutocompleteArrayInput
      resettable
      label="Branches"
      source="dataRights.branchId"
      choices={branches}
    />
  );
};
MultiSelectBranchInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectBranchInput);
