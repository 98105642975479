import React, { useState } from "react";
import "date-fns";
import {
  SimpleForm,
  TextInput,
  FormDataConsumer,
  BooleanInput,
  useTranslate,
  useDataProvider,
  Button,
  useRefresh,
  LinearProgress,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { timeslotStyle } from "../helperStyle";
import { styleData } from "../helperConst";
import { SectionTitle } from "../helperValidate";
import { toastError, toastInfo } from "../components/toast";
import MultiSelectStreamInput from "../components/MultiSelectStreamInput";

const useStyles = makeStyles(timeslotStyle);
const TimeslotEditDialogueBox = (props: any) => {
  const [allotments, setAllotments] = useState(Array);
  const [objStream, setObjStream] = useState([]);
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [isLoad, setLoader] = useState(false);
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();
  const { resource, close, editData } = props;

  const saveForm = (data: any) => {
    if (data && data.stream && data.stream.length == 0) {
      toastError("Please Select Stream");
    } else {
      const resourceName = translate(`resources.${resource}.name`, resource);
      const resourcePath = `${resource}/update/${editData._id}`;
      setLoader(true);
      dataProvider
        .callCustom(resourcePath, {
          data,
        })
        .then((response: any) => {
          setLoader(false);
          toastInfo(`${resourceName} updated Succesfully`);
          refresh();
          setAllotments([]);
          close();
        });
    }
  };
  const streamData = (data: any) => {
    setObjStream(data);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.close}
        aria-label="Timeslot Allotments"
        style={{ zIndex: 1, padding: 0 }}
        className="allotment-dialog"
      >
        <DialogTitle className={classes.pb0}>
          {" "}
          <span className={classes.headTitle}>Edit Timeslots</span>
        </DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null}>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => (
                <MultiSelectStreamInput
                  dataManage={streamData}
                  emptyValue={{ emptyValue }}
                  labelName="Stream *"
                  defaultValue={editData && editData.stream}
                ></MultiSelectStreamInput>
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => {
                setAllotments(formData);
                return (
                  <TextInput
                    label="Start Time *"
                    source="startTime"
                    type="time"
                    resource="timeslot"
                    formClassName={classes.name}
                    defaultValue={editData && editData.startTime}
                    disabled={true}
                  />
                );
              }}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="End Time *"
                  type="time"
                  source="endTime"
                  resource="timeslot"
                  formClassName={isXsmall ? classes.name : classes.code}
                  defaultValue={editData && editData.endTime}
                  disabled={true}
                />
              )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <div style={{ display: "flex" }}>
              <div className={classes.textInput}>
                <FormDataConsumer formClassName={classes.textInput}>
                  {({ formData }: { formData: any }) => (
                    <BooleanInput
                      source="isBreak"
                      label="Is Break"
                      helperText={false}
                      defaultValue={editData && editData.isBreak}
                      disabled={true}
                    />
                  )}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXsmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) =>
                    formData.isBreak ? (
                      <div>
                        <TextInput
                          source="breakTitle"
                          label="BreakTitle"
                          helperText={false}
                          defaultValue={editData && editData.breakTitle}
                          disabled={true}
                        />
                      </div>
                    ) : null
                  }
                </FormDataConsumer>
              </div>
            </div>
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              type="submit"
              label="Save"
              onClick={() => saveForm(allotments)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Cancel"
            onClick={props.close}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeslotEditDialogueBox;
