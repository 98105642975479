import AttendanceList from "./UnknownAttendanceList";
import AttendanceEdit from "./UnknownAttendanceEdit";
import AttendanceCreate from "./UnknownAttendanceCreate";
import { UnknownAttendanceIcon } from "../svgIcon";

export default {
  list: AttendanceList,
  create: AttendanceCreate,
  edit: AttendanceEdit,
  icon: UnknownAttendanceIcon,
};
