import SemesterList from "./subjectList";
import { CommonSubject } from "./commonSubject";
import { SubjectIcon } from "../svgIcon";

export default {
  list: SemesterList,
  create: CommonSubject,
  edit: CommonSubject,
  icon: SubjectIcon,
};
