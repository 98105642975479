import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from "@material-ui/core";
import { importStyle } from "../helperStyle";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  useDataProvider,
  showNotification,
  useRefresh,
  useNotify,
} from "react-admin";
import { useMediaQuery, Theme, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toastError, toastInfo } from "../components/toast";

const useStyles = makeStyles(importStyle);
const ImportButton = connect(undefined, { showNotification })((props: any) => {
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("text-button-file")
        : null;
  }
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const [errorData, setErrorData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [responseData, setResponseData] = useState({
    totalCount: 0,
    successCount: 0,
    dataError: 0,
  });
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  // const notify = useNotify();
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { resource } = props;
  const handleChange = (e: any) => {
    let file = e.target.files && e.target.files[0];
    let data = new FormData();
    // e.target.files[0] = undefined;
    // file = null;
    // if (file) {
    //"/import-employee"
    data.append("file", file);
    container.value = "";
    dataProvider
      .callCustom(resource + "/import-employee", data)
      .then((responseLocal: any) => {
        if (
          responseLocal &&
          responseLocal.data &&
          responseLocal.data.success === true
        ) {
          if (responseLocal.data.dataError > 0) {
            setIsOpen(true);
            setResponseData({
              totalCount: responseLocal.data.totalCount,
              successCount: responseLocal.data.successCount,
              dataError: responseLocal.data.dataError,
            });
            setErrorData(responseLocal.data.mainErrorData);
          } else {
            toastInfo("Data has been imported Successfully.");
            // notify("Data has been imported Successfully.");
          }
          refresh();
          // e.target.files[0] = null;
          // data = new FormData();
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        refresh();
        // e.target.files[0] = null;
        // data = new FormData();
      });
    // }
  };
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <input
        type="file"
        id="text-button-file"
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleChange}
      />
      <label htmlFor="text-button-file" className="btnfilter mb-0">
        <Button
          className="p-0"
          color="primary"
          component="span"
          style={{
            fontSize: "0.8125rem",
          }}
        >
          <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: 21 }} />
          {!isXSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>Import</span>
          ) : null}
        </Button>
      </label>
      <Dialog
        aria-labelledby="simple-dialog-title"
        className={classes.myPopup}
        onClose={handleClose}
        open={isOpen}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">{resource} Data Import</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={classes.close} />
          </IconButton>
        </DialogTitle>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Total :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.totalCount}
            </span>
          </div>
          <div className={classes.label}>
            Success:{" "}
            <span className={classes.label} style={{ color: "green" }}>
              {responseData.successCount}
            </span>
          </div>
          <div className={classes.label}>
            Error :{" "}
            <span className={classes.label} style={{ color: "red" }}>
              {responseData.dataError}
            </span>
          </div>
        </Box>
        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.theadCol}>Sr no</TableCell>
                  <TableCell className={classes.theadCol}>Name</TableCell>
                  <TableCell className={classes.theadCol}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data: any, index: number) => (
                    <TableRow key={index + 1}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {data ? data.name : ""}
                      </TableCell>
                      <TableCell align="left">
                        {data ? data.reason : ""}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={errorData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </>
  );
});

export default connect()(ImportButton);
