import React, { useState } from "react";
import "date-fns";
import {
  SimpleForm,
  TextInput,
  FormDataConsumer,
  BooleanInput,
  useTranslate,
  useDataProvider,
  Button,
  useRefresh,
  LinearProgress,
} from "react-admin";
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  useMediaQuery,
  TableBody,
  Table,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { useRedirect } from "ra-core";
import { timeslotStyle } from "../helperStyle";
import { SectionTitle } from "../helperValidate";
import { margltstyleData, styleData } from "../helperConst";
import DialogueBox from "../components/ConfirmDialogueBox";
import { toastError, toastInfo } from "../components/toast";
import MultiSelectStreamInput from "../components/MultiSelectStreamInput";

const useStyles = makeStyles(timeslotStyle);
const CreateDialogueBox = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const [allotments, setAllotments] = useState(Array);
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [objStream, setObjStream] = useState([]);
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [isLoad, setLoader] = useState(false);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const classes = useStyles();
  const header = [
    "Stream",
    "Start Time",
    "End Time",
    "Is Break",
    "Break Title",
    "Action",
  ];
  const { resource, close } = props;

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, allotment: any) => {
    setShowDialog(true);
    setIndex(index);
  };
  const removeAllotment = (idx: any) => {
    setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
  };

  const addAllotment = (data: any) => {
    if (data && (data.stream === "" || data.stream === undefined)) {
      toastError("Please Select Stream");
      return;
    } else if (
      data &&
      (data.startTime === "" || data.startTime === undefined)
    ) {
      toastError("Please Select Start Time");
      return;
    } else if (data && (data.endTime === "" || data.endTime === undefined)) {
      toastError("Please Select End Time");
      return;
    } else {
      let duplicate = false;
      allotments.filter((s: any, sIdx: any) => {
        if (
          s.startTime === data.startTime &&
          s.endTime === data.endTime &&
          s.stream === data.stream
        ) {
          duplicate = true;
        }
      });

      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
      } else if (data.startTime >= data.endTime) {
        toastError("End Time should be greater than Start Time ");
      } else {
        setAllotments([
          ...allotments,
          {
            startTime: data.startTime,
            endTime: data.endTime,
            isBreak: data.isBreak,
            breakTitle: data.isBreak ? data.breakTitle : "",
            stream: data.stream,
          },
        ]);
      }
      data.startTime = "";
      data.endTime = "";
      data.breakTitle = "";
      data.stream = "";
      setEmptyValue(true);
      setTimeout(function () {
        setEmptyValue(false);
      }, 1000);
    }
  };

  const saveForm = () => {
    const resourceName = translate(`resources.${resource}.name`, resource);
    const resourcePath = `timeslot/create`;
    if (allotments && allotments.length === 0) {
      toastError("Please fill required details in form ");
    } else {
      setLoader(true);
      dataProvider
        .callCustom(resourcePath, {
          timeslotData: allotments,
        })
        .then((response: any) => {
          setLoader(false);
          toastInfo(`${resourceName} created Succesfully`);
          refresh();
          setAllotments([]);
          close();
        });
    }
  };
  const streamData = (data: any) => {
    setObjStream(data);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.close}
        aria-label="Timeslot Allotments"
        style={{ zIndex: 1, padding: 0 }}
        className="allotment-dialog"
      >
        <DialogTitle className={classes.pb0}>
          {" "}
          <span className={classes.headTitle}>Add Timeslots</span>
        </DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null}>
            <SectionTitle label="" />
            <FormDataConsumer>
              {({ formData }: { formData: any }) => (
                <MultiSelectStreamInput
                  dataManage={streamData}
                  emptyValue={{ emptyValue }}
                  labelName="Stream *"
                ></MultiSelectStreamInput>
              )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <TextInput
                    label="Start Time *"
                    source="startTime"
                    type="time"
                    resource="timeslot"
                    formClassName={classes.name}
                  />
                );
              }}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="End Time *"
                  type="time"
                  source="endTime"
                  resource="timeslot"
                  formClassName={isXsmall ? classes.name : classes.code}
                />
              )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <div style={{ display: "flex" }}>
              <div className={classes.textInput}>
                <FormDataConsumer formClassName={classes.textInput}>
                  {({ formData }: { formData: any }) => (
                    <BooleanInput
                      source="isBreak"
                      label="Is Break"
                      defaultValue={false}
                      helperText={false}
                    />
                  )}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXsmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) =>
                    formData.isBreak ? (
                      <div>
                        <TextInput
                          source="breakTitle"
                          label="BreakTitle"
                          helperText={false}
                          // validate={validate}
                        />
                      </div>
                    ) : null
                  }
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXsmall ? undefined : margltstyleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => (
                    <MaterialButton
                      onClick={() => addAllotment(formData)}
                      variant="outlined"
                      size="small"
                      color="primary"
                      type="button"
                      className={
                        formData.isBreak
                          ? classes.allotmentAfterBreakBtn
                          : classes.allotmentBtn
                      }
                    >
                      Allocate
                    </MaterialButton>
                  )}
                </FormDataConsumer>
              </div>
            </div>
            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {header.map((item: any) => {
                      return (
                        <TableCell className={classes.tableHead}>
                          {item}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allotments && allotments.length > 0
                    ? allotments.map((allotment: any, index: any) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {objStream}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment && allotment.startTime
                                ? allotment.startTime
                                : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment && allotment.endTime
                                ? allotment.endTime
                                : ""}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment && allotment.isBreak ? (
                                <CheckIcon />
                              ) : (
                                <ClearIcon />
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment && allotment.breakTitle
                                ? allotment.breakTitle
                                : "N/A"}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove{" "}
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                  {allotments && allotments.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={6}
                        align="center"
                      >
                        No Data found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(index)}
            />
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              type="submit"
              label="Save"
              onClick={() => saveForm()}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Cancel"
            onClick={props.close}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateDialogueBox;
