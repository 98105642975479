import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  required,
  SelectInput,
  useDataProvider,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  reportingManager?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
}

const ReportingManagerInput: FC<Props> = ({ filter, formClassName }) => {
  const payload = {
    ...filter,
    sort: "asc",
    sortBy: "name",
  };

  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("designations/reporting-manager-employee", payload)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          reportingManager: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { reportingManager } = state;

  return (
    // <AutocompleteInput
    //   resettable
    //   label="Reporting Manager"
    //   className={formClassName ? formClassName : classes.root}
    //   source="reportingManager"
    //   choices={reportingManager}
    //   optionText={(choice: any) => `${choice.name}`}
    //   emptyValue="true"
    //   allowEmpty
    // />
    <SelectInput
      resettable
      label="Reporting Manager"
      className={formClassName ? formClassName : classes.root}
      source="reportingManager"
      // validate={validate}
      choices={reportingManager}
      // helperText={helperText}
      // onChange={(value: any) => check(value.target)}
    />
  );
};
const requiredValidate = [required()];
ReportingManagerInput.defaultProps = {
  source: "zoneId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ReportingManagerInput);
