import * as React from "react";
import PropTypes from "prop-types";
import Footer from "./components/Footer";
import Button from "@material-ui/core/Button";
import { lightTheme } from "./themes";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import MobileNavBar from "../components/MobileNavBar";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useMediaQuery, Theme } from "@material-ui/core";
import Header from "./components/Header";

const LandingPageSecond = () => {
  var container: any = null;
  var advantageboxRef1 = React.useRef(null);

  const handleMouseEnter = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.add("active");
    container = null;
  };

  const handleMouseOut = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.remove("active");
    container = null;
  };

  return (
    <React.Fragment>
      <Header />
      {/* <section className="breadcrumb-area">
                <div className="breadcrumb-shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inn">
                                <div className="section-title wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <h2><span>Turnout System</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="breadcrumb-area">
        <div className="breadcrumb-shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inn">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h2>
                    <span>Turnout System</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="hero-left">
                <h2>
                  <span className="underline"> Attendance Management </span>{" "}
                  <span></span>
                </h2>
                <p>
                  <b>
                    Due to COVID-19 impact a Touchless technology is the future.
                  </b>
                </p>
                <p>
                  Turnout is an AI based Face Recognition solution which uses
                  machine learning algorithms to mark the attendance of the
                  employees. We developed AI powered Face recognition attendance
                  system for improving efficiency of the system and a secure way
                  to taking attendance.
                </p>
                <p>
                  Our smart Face Recognition solution works on a touchless
                  technology and that improves employee security and safety. Our
                  smart system used to calculate attendance automatically by
                  recognizing the facial dimensions. The system gives Real-time
                  data for quick processing and reporting.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img src="assets/img/Turnout-Edge.png" alt="shape" />
            </div>
          </div>
        </div>
      </section>

      <section
        className="about-section  turnout-s section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="about-top-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 covid-19-solution order-lg-1 order-2">
              <img src="assets/img/Turnout-Web.png" alt="shape" />
            </div>
            <div className="col-lg-7 order-lg-2 order-1">
              <div className="hero-left">
                <h2>
                  <span className="underline"> Turnout Web </span> <span></span>
                </h2>
                <p>
                  New employee registration process is now automated using our
                  Turnout system. Employee need to look in front of camera and
                  registration will be automatically done.
                </p>
                <p>
                  The purpose of access control is to grant entrance to a highly
                  confidential office space only to the authorized peoples.
                </p>
                <p>
                  A attendance management lets you manage employee real-time
                  attendance from their in and out details with particular date
                  and time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage1")}
                onMouseOut={() => handleMouseOut("advantage1")}
                id="advantage1"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Project"></i>
                </div>
                <div className="service-title col">
                  <h3>Project</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage2")}
                onMouseOut={() => handleMouseOut("advantage2")}
                id="advantage2"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Department"></i>
                </div>
                <div className="service-title col">
                  <h3>Department</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage3")}
                onMouseOut={() => handleMouseOut("advantage3")}
                id="advantage3"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Shift"></i>
                </div>
                <div className="service-title col">
                  <h3>Shift</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage4")}
                onMouseOut={() => handleMouseOut("advantage4")}
                id="advantage4"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Designation"></i>
                </div>
                <div className="service-title col">
                  <h3>Designation</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage5")}
                onMouseOut={() => handleMouseOut("advantage5")}
                id="advantage5"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Workforce"></i>
                </div>
                <div className="service-title col">
                  <h3>Workforce </h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage6")}
                onMouseOut={() => handleMouseOut("advantage6")}
                id="advantage6"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Attendance"></i>
                </div>
                <div className="service-title col">
                  <h3>Attendance </h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage7")}
                onMouseOut={() => handleMouseOut("advantage7")}
                id="advantage7"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Leave"></i>
                </div>
                <div className="service-title col">
                  <h3>Leave</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="service-box web-box wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                onMouseOver={() => handleMouseEnter("advantage8")}
                onMouseOut={() => handleMouseOut("advantage8")}
                id="advantage8"
              >
                <div className="web-icon tai-icon-center col">
                  <i className="tai-ico-Task"></i>
                </div>
                <div className="service-title col">
                  <h3>Task</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="turnout-mobile  section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="hero-left">
                <h2>
                  <span className="underline"> Turnout Mobile </span>{" "}
                  <span></span>
                </h2>
                <p>
                  Turnout-M is a mobile app-based attendance system with AI
                  based Face Recognition technology. The Turnout-M employee
                  attendance mobile app works with iPhone and Android phones.
                </p>
              </div>
            </div>
            <div className="col-lg-7 ">
              <img src="assets/img/t-mobile.png" alt="shape" />
            </div>
          </div>
        </div>
      </section>

      <section
        className=" section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-1 order-2">
              <img src="assets/img/t-rfid.png" alt="shape" />
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="hero-left">
                <h2>
                  <span className="underline"> Turnout RFID </span>{" "}
                  <span></span>
                </h2>
                <p>
                  Turnout – R is a smart RFID based attendance system that track
                  the attendance using a simple smart card. RFID attendance
                  system track, manages, organizes, and records attendance
                  without any errors. Turnout RFID based access control systems
                  designed especially for manage check-in and check-out of
                  employee and person’s attendance.RFID, or Radio Frequency
                  Identification based attendance system gives flexibility,
                  security and increased efficiency than other attendance
                  system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about-section  covid-19 section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="about-top-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-left">
                <h2>
                  <span className="underline"> Turnout COVID-19 </span>{" "}
                  <span></span>
                </h2>
                <p>
                  Due to the COVID-19 impact, a complete security check system
                  (i.e mask detection, temperature measurement and sanitization)
                  is the future. COVID-19 - RCSS is the AI based face mask
                  detection solution integrated in embedded device which also
                  support touch less temperature measurement and hand
                  sanitization.
                </p>
              </div>
              <div id="works" className="">
                <div className="row ">
                  <div className="col-lg-3 col-md-12 mb-30 hover">
                    <div
                      ref={advantageboxRef1}
                      onMouseOver={() => handleMouseEnter("id1")}
                      onMouseOut={() => handleMouseOut("id1")}
                      id="id1"
                      className="s-single-services text-center"
                   
                    >
                      <div className="services-icon">
                        <img src="assets/img/Mask-Detect.png" />
                      </div>
                      <div className="second-services-content">
                        <h5>Mask Detect</h5>
                        <a href="">
                          <span>1</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-30">
                    <div  ref={advantageboxRef1}
                      onMouseOver={() => handleMouseEnter("id2")}
                      onMouseOut={() => handleMouseOut("id2")}
                      className="s-single-services text-center"
                      id="id2">
                      <div className="services-icon">
                        <img src="assets/img/Thermal.png" />
                      </div>
                      <div className="second-services-content">
                        <h5>Thermal Screening</h5>
                        <a href="">
                          <span>2</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-30">
                    <div  ref={advantageboxRef1}
                      onMouseOver={() => handleMouseEnter("id3")}
                      onMouseOut={() => handleMouseOut("id3")}
                      className="s-single-services text-center"
                      id="id3">
                      <div className="services-icon">
                        <img src="assets/img/Sanitization.png" />
                      </div>
                      <div className="second-services-content">
                        <h5>Sanitization</h5>
                        <a href="">
                          <span>3</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 mb-30">
                    <div  ref={advantageboxRef1}
                      onMouseOver={() => handleMouseEnter("id4")}
                      onMouseOut={() => handleMouseOut("id4")}
                      className="s-single-services text-center"
                      id="id4">
                      <div className="services-icon">
                        <img src="assets/img/Allow.png" />
                      </div>
                      <div className="second-services-content">
                        <h5>Allow To Go In </h5>
                        <a href="">
                          <span>4</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 covid-19-solution">
              <img src="assets/img/Turnout-COVID-19.png" alt="shape" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

LandingPageSecond.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LandingPageSecondWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <LandingPageSecond {...props} />
  </ThemeProvider>
);

export default LandingPageSecondWithTheme;
