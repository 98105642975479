import * as React from "react";
import { FC, memo } from "react";
import { AutocompleteArrayInput } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
}));

interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  isXsmall?: boolean;
  validate?: any;
}

const MultiSelectWeekdaysInput: FC<Props> = ({ validate, isXsmall }) => {
  const classes = useStyles();
  const weekDays = [
    {
      name: "Monday",
      id: "monday",
    },
    {
      name: "Tuesday",
      id: "tuesday",
    },
    {
      name: "Wednesday",
      id: "wednesday",
    },
    {
      name: "Thursday",
      id: "thursday",
    },
    {
      name: "Friday",
      id: "friday",
    },
    {
      name: "Saturday",
      id: "saturday",
    },
    {
      name: "Sunday",
      id: "sunday",
    },
  ];

  return (
    <AutocompleteArrayInput
      resettable
      label="Weekday(s)"
      source="weekDays"
      choices={weekDays}
      validate={validate}
      className={classes.root}
      fullWidth={isXsmall ? false : true}
    />
  );
};

MultiSelectWeekdaysInput.defaultProps = {
  source: "weekDays",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectWeekdaysInput);
