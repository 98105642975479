import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";

interface State {
  students?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  labelName?: string;
  formData?: any;
  semesterChange?: any;
  streamChange?: any;
  courseChange?: any;
}

const StudentSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  propsUrl,
  helperText,
  labelName,
  formData,
  semesterChange,
  streamChange,
  courseChange,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const searchUrl = "student/search";

  useEffect(() => {
    if (filter && filter.semester) {
      dataProvider
        .getSearchList(propsUrl ? propsUrl : searchUrl, filter)
        .then((response: any) => {
          response &&
            response.data.forEach((item: any) => {
              item.name = `${item.name} (${item.employeeCode})`;
            });
          setState((state) => ({
            ...state,
            students: response && response.data,
          }));
        });
    }
  }, [filter.semester]);

  if (emptyValue && emptyValue.emptyValue) {
    form.change("student", undefined);
  }

  if (semesterChange || streamChange || courseChange) {
    form.change("student", undefined);
  }

  const { students } = state;
  const check = (value: any) => {
    students?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "student");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Student"}
      disabled={record && record.mode === "auto" ? true : false}
      source="student"
      validate={validate}
      choices={students}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
    />
  );
};

StudentSelectInput.defaultProps = {
  source: "student",
  label: "resources.customers.fields.name",
};

export default memo<Props>(StudentSelectInput);
