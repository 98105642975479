import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
import { toastError } from "../components/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  searchData?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  label?: string;
  source?: string;
  apiName?: any;
  enabled?: boolean;
  initialValue?: boolean;
  isValidate?: any;
}

const SelectDropdown: FC<Props> = ({
  filter,
  label,
  source,
  apiName,
  isValidate,
  enabled,
  initialValue,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();

  useDeepCompareEffect(() => {
    if (enabled) {
      dataProvider
        .getSearchList(apiName, filter)
        .then((response: any) => {
          setState((state) => ({
            ...state,
            searchData: response && response.data,
          }));
        })
        .catch((err: any) => {
          toastError(err);
        });
    }
  }, [filter]);

  const { searchData } = state;

  return (
    <SelectInput
      label={label}
      className={classes.root}
      source={source}
      validate={isValidate === false ? false : requiredValidate}
      choices={searchData}
      initialValue={
        initialValue ? searchData && searchData[0] && searchData[0]._id : ""
      }
      disabled={initialValue}
    />
  );
};
const requiredValidate = [required()];
SelectDropdown.defaultProps = {
  source: "searchData",
  label: "resources.customers.fields.name",
};

export default memo<Props>(SelectDropdown);
