import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  // CSSProperties,
} from "react";

import { useVersion, useDataProvider, useNotify } from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import ChartAttendance from "./components/ChartAttendance";
import AttendanceCard from "./components/AttendanceCard";
import GeneralDetailCard from "./components/GeneralDetailCard";
import QuickLinksCard from "./components/QuickLinksCard";
import EmployeeTimeCard from "./components/EmployeeTimeCard";
import LeavesData from "./components/LeavesData";
import AttendanceListData from "./components/AttendanceListData";
import authProvider from "../authProvider";
import EmployeeBehaviour from "./components/hr/EmployeeBehaviour";
import TeamLeaveApplication from "./components/TeamLeaveApplication";

import RequestCard from "./components/RequestCard";
import { toastError } from "../components/toast";
import { useLocation } from "react-router";
// import EmployeeLeaveCard from "./components/EmployeeLeaveCard"

interface State {
  AttendanceChartData?: { total: 0; present: 0; absent: 0 };
  EmployeeAttendanceData?: {};
  AttendanceCardData?: any;
  EmployeeLeaveData?: any;
  GeneralDetailCardCount?: { holidays: 0; offdays: 0; events: 0; requests: 0 };
  GeneralDetailCardData?: any;
  MonthlyAttendanceData?: [];
  EmployeeTimeCardData?: { shift_time: { inTime: 0; outTime: 0 }; in_time: "" };
  LeaveAssignedData?: [];
  TeamLeaveData?: [];
}

const styles = {
  flex: {
    display: "flex",
  },
  flexCol: {
    flex: 1,
    display: "flex",
    marginTop: "1em",
    // maxWidth: "calc(100% - 130px)",
  },
  flexChart: {
    display: "flex",
    flex: 1,
    marginTop: "1em",
    // maxWidth: "calc(100% - 130px)",
  },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1 },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
  flexXSmall: { display: "flex", marginRight: "15px", marginLeft: "15px" },
};

const EmployeeDashboard: FC = () => {
  var container: any = null;
  const [monthAttendanceLoading, setMonthAttendanceLoading] = useState(false);
  const [employeeTimeLoading, setEmployeeTimeLoading] = useState(false);
  const [leaveBalanceLoading, setLeaveBalanceLoading] = useState(false);
  const [generalDetailsLoading, setGeneralDetailsLoading] = useState(false);
  const [monthlyAttendanceLoading, setMonthlyAttendanceLoading] =
    useState(false);
  const [teamLeaveApplication, setTeamLeaveApplicationLoading] =
    useState(false);

  const location = useLocation();

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  React.useEffect(() => {
    if (!isSmall && !isXSmall && container && container.innerText) {
      container.innerText = "";
      container.innerText = "Dashboard";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container && container.innerText) {
        container.innerText = "";
      }
    };
  }, [container]);
  const [state, setState] = useState<State>({});
  const version = useVersion();
  // const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    AttendanceChartData,
    EmployeeAttendanceData,
    AttendanceCardData,
    EmployeeLeaveData,
    GeneralDetailCardCount,
    GeneralDetailCardData,
    EmployeeTimeCardData,
    LeaveAssignedData,
    MonthlyAttendanceData,
    TeamLeaveData,
  } = state;
  const fetchMonthlyAttendanceData = useCallback(async () => {
    setMonthAttendanceLoading(true);
    await dataProvider
      .callCustom("dashboard/monthly_attendence_report", {})
      .then((response: any) => {
        setMonthAttendanceLoading(false);
        setState((state) => ({
          ...state,
          AttendanceChartData: {
            ...AttendanceChartData,
            ...response?.data?.item,
          },
          EmployeeAttendanceData: {
            ...(response && response.data && response.data.item
              ? // ...EmployeeAttendanceData,
                response?.data?.item?.employeeAttendenceData
              : []),
          },
        }));
      })
      .catch((err: any) => {
        setMonthAttendanceLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          toastError(error.msg);
          // notify(error.msg, "warning");
          if (err.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchLeaveAppData = useCallback(async () => {
    setLeaveBalanceLoading(true);
    await dataProvider
      .callCustom("dashboard/employee-leave-data", {})
      .then((response: any) => {
        setLeaveBalanceLoading(false);
        const leaveCount = response?.data?.leaveCount || {};
        const leaveData = response?.data?.leaveData || {};
        setState((state) => ({
          ...state,
          AttendanceCardData: { ...AttendanceCardData, ...leaveCount },
          EmployeeLeaveData: { ...EmployeeLeaveData, ...leaveData },
        }));

        // const balanceLeave = response.data.balanceLeave || [];
        // setState((state) => ({
        //   ...state,
        //   AttendanceCardData: balanceLeave,
        // }));

        const leaveAssigned = response?.data?.item || [];
        setState((state) => ({
          ...state,
          LeaveAssignedData: leaveAssigned,
        }));
      })
      .catch((err: any) => {
        setLeaveBalanceLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          // notify(error.msg, "warning");
          toastError(error.msg);

          if (err.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchGeneralDetailData = useCallback(async () => {
    setGeneralDetailsLoading(true);
    const cardsCount = await dataProvider
      .callCustom("dashboard/general_detail", {})
      .then((response: any) => {
        setGeneralDetailsLoading(false);
        const generalDetailsCount = response?.data?.item?.dataCount || {};
        const generalDetailsData = response?.data?.item?.data || {};
        setState((state) => ({
          ...state,
          GeneralDetailCardCount: {
            ...GeneralDetailCardCount,
            ...generalDetailsCount,
          },
          GeneralDetailCardData: {
            ...GeneralDetailCardData,
            ...generalDetailsData,
          },
        }));
      })
      .catch((err: any) => {
        setGeneralDetailsLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          toastError(error.msg);
          if (err.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchEmployeeTimeData = useCallback(async () => {
    setEmployeeTimeLoading(true);
    const cardsCount = await dataProvider
      .callCustom("dashboard/employee_time", {})
      .then((response: any) => {
        setEmployeeTimeLoading(false);
        const respData = response?.data?.item || {};
        setState((state) => ({
          ...state,
          EmployeeTimeCardData: { ...EmployeeTimeCardData, ...respData },
        }));
      })
      .catch((err: any) => {
        setEmployeeTimeLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          toastError(error.msg);
          if (err.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchEmployeeAttendanceData = useCallback(async () => {
    setMonthlyAttendanceLoading(true);
    const cardsCount = await dataProvider
      .callCustom("dashboard/monthly_attendence_list", {})
      .then((response: any) => {
        setMonthlyAttendanceLoading(false);
        const respData = response?.data?.item || [];
        setState((state) => ({
          ...state,
          MonthlyAttendanceData: { ...MonthlyAttendanceData, ...respData },
        }));
      })
      .catch((err: any) => {
        setMonthlyAttendanceLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          toastError(error.msg);
          if (err?.response?.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchTeamLeaveApplicationData = useCallback(async () => {
    setTeamLeaveApplicationLoading(true);
    const cardsCount = await dataProvider
      .getList("teamLeave", {
        pagination: { page: 1, perPage: 500000, leaveDate: new Date() },
        sort: { field: "createdAt", order: "DESC" },
      })
      .then((response: any) => {
        setTeamLeaveApplicationLoading(false);
        const respData = response?.data || [];
        setState((state) => ({
          ...state,
          TeamLeaveData: { ...TeamLeaveData, ...respData },
        }));
      })
      .catch((err: any) => {
        setTeamLeaveApplicationLoading(false);
        err?.response?.data?.errors?.forEach((error: any) => {
          toastError(error.msg);
          if (err.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
    setState((state) => ({ ...state }));
  }, [dataProvider]);

  useEffect(() => {
    fetchMonthlyAttendanceData();
    fetchLeaveAppData();
    fetchGeneralDetailData();
    fetchEmployeeTimeData();
    fetchEmployeeAttendanceData();
    authProvider.isMenuShow("teamLeaves") && fetchTeamLeaveApplicationData();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  // const requestSortData = GeneralDetailCardData

  return isXSmall ? (
    <div style={styles.flexXSmall}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          {authProvider.isMenuShow("attendances") && (
            <ChartAttendance
              isLoading={monthAttendanceLoading}
              data={AttendanceChartData}
              data2={EmployeeAttendanceData}
            ></ChartAttendance>
          )}
        </div>
        <div style={styles.flexCol}>
          <EmployeeTimeCard
            isLoading={employeeTimeLoading}
            data={EmployeeTimeCardData}
          ></EmployeeTimeCard>
        </div>
        <div style={styles.flexCol}>
          {/* {authProvider.isMenuShow("leaves") && ( */}
          <AttendanceCard
            isLoading={leaveBalanceLoading}
            data={AttendanceCardData}
            data2={EmployeeLeaveData}
          ></AttendanceCard>
          {/* )} */}
        </div>

        <div style={styles.flexCol}>
          <GeneralDetailCard
            isLoading={generalDetailsLoading}
            data={GeneralDetailCardCount}
            data2={GeneralDetailCardData}
          ></GeneralDetailCard>
        </div>
        <div style={styles.flexCol}>
          <RequestCard
            isLoading={generalDetailsLoading}
            data={GeneralDetailCardCount}
            data2={GeneralDetailCardData}
          ></RequestCard>
        </div>
        <div style={styles.flexChart}>
          {authProvider.isMenuShow("teamLeaves") && (
            <TeamLeaveApplication
              isLoading={teamLeaveApplication}
              data={TeamLeaveData}
              // data2={GeneralDetailCardData}
            ></TeamLeaveApplication>
          )}
        </div>
        {/* <div style={styles.flexCol}>
          <QuickLinksCard></QuickLinksCard>
        </div> */}
        {authProvider.isHiddenField(
          location?.pathname,
          "leavesBalance",
          "isHide"
        ) === false && (
          <div style={styles.flexCol}>
            {/* {authProvider.isMenuShow("leaves") && ( */}
            <LeavesData
              isLoading={leaveBalanceLoading}
              leaves={LeaveAssignedData}
            ></LeavesData>
            {/* )} */}
          </div>
        )}
        <div style={styles.flexCol}>
          {authProvider.isMenuShow("attendances") && (
            <AttendanceListData
              isLoading={monthlyAttendanceLoading}
              data={MonthlyAttendanceData}
            ></AttendanceListData>
          )}
        </div>
        <div style={styles.flexCol}>
          <EmployeeBehaviour />
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div className="super-dash" style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          {authProvider.isMenuShow("attendances") && (
            <ChartAttendance
              isLoading={monthAttendanceLoading}
              data={AttendanceChartData}
              data2={EmployeeAttendanceData}
            ></ChartAttendance>
          )}
          <EmployeeTimeCard
            isLoading={employeeTimeLoading}
            data={EmployeeTimeCardData}
          ></EmployeeTimeCard>
          <GeneralDetailCard
            isLoading={generalDetailsLoading}
            data={GeneralDetailCardCount}
            data2={GeneralDetailCardData}
          ></GeneralDetailCard>
        </div>

        <div style={styles.flexCol}>
          {/* {authProvider.isMenuShow("leaves") && ( */}
          <LeavesData
            isLoading={leaveBalanceLoading}
            leaves={LeaveAssignedData}
          ></LeavesData>
          {/* // )} */}
          {/* {authProvider.isMenuShow("leaves") && ( */}
          <AttendanceCard
            isLoading={leaveBalanceLoading}
            data={AttendanceCardData}
            data2={EmployeeLeaveData}
          ></AttendanceCard>
          {/* // )} */}
          <RequestCard
            isLoading={generalDetailsLoading}
            data={GeneralDetailCardCount}
            data2={GeneralDetailCardData}
          ></RequestCard>
        </div>

        <div style={styles.flexChart}>
          {authProvider.isMenuShow("teamLeaves") && (
            <TeamLeaveApplication
              isLoading={teamLeaveApplication}
              data={TeamLeaveData}
              // data2={GeneralDetailCardData}
            ></TeamLeaveApplication>
          )}
        </div>
        <div style={styles.flexCol}>
          {authProvider.isMenuShow("attendances") && (
            <AttendanceListData
              isLoading={monthlyAttendanceLoading}
              data={MonthlyAttendanceData}
            ></AttendanceListData>
          )}
        </div>
        <div style={styles.flexCol}>
          <EmployeeBehaviour />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div>
        <div style={styles.flexCol}>
          {authProvider.isMenuShow("attendances") && (
            <ChartAttendance
              isLoading={monthAttendanceLoading}
              data={AttendanceChartData}
              data2={EmployeeAttendanceData}
              isTwoCard={authProvider.isHiddenField(
                location?.pathname,
                "leavesBalance",
                "isHide"
              )}
            ></ChartAttendance>
          )}
          <EmployeeTimeCard
            isLoading={employeeTimeLoading}
            data={EmployeeTimeCardData}
          ></EmployeeTimeCard>
          {authProvider.isHiddenField(
            location?.pathname,
            "leavesBalance",
            "isHide"
          ) === false && (
            <GeneralDetailCard
              isLoading={generalDetailsLoading}
              data={GeneralDetailCardCount}
              data2={GeneralDetailCardData}
            ></GeneralDetailCard>
          )}
        </div>
        <div style={styles.flexCol}>
          {authProvider.isHiddenField(
            location?.pathname,
            "leavesBalance",
            "isHide"
          ) === false && (
            <LeavesData
              isLoading={leaveBalanceLoading}
              leaves={LeaveAssignedData}
            ></LeavesData>
          )}

          {authProvider.isHiddenField(
            location?.pathname,
            "leaveApplication",
            "isHide"
          ) === false && (
            <AttendanceCard
              isLoading={leaveBalanceLoading}
              data={AttendanceCardData}
              data2={EmployeeLeaveData}
            ></AttendanceCard>
          )}
          {authProvider.isHiddenField(
            location?.pathname,
            "leaveApplication",
            "isHide"
          ) && (
            <GeneralDetailCard
              isLoading={generalDetailsLoading}
              data={GeneralDetailCardCount}
              data2={GeneralDetailCardData}
            ></GeneralDetailCard>
          )}

          <RequestCard
            isLoading={generalDetailsLoading}
            data={GeneralDetailCardCount}
            data2={GeneralDetailCardData}
            isTwoCard={authProvider.isHiddenField(
              location?.pathname,
              "leavesBalance",
              "isHide"
            )}
          ></RequestCard>
        </div>
      </div>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flexChart}>
            {authProvider.isMenuShow("teamLeaves") && (
              <TeamLeaveApplication
                isLoading={teamLeaveApplication}
                data={TeamLeaveData}
                // data2={GeneralDetailCardData}
              ></TeamLeaveApplication>
            )}
          </div>

          <div style={styles.flexCol}>
            {authProvider.isMenuShow("attendances") && (
              <AttendanceListData
                isLoading={monthlyAttendanceLoading}
                data={MonthlyAttendanceData}
              ></AttendanceListData>
            )}
          </div>
          <div style={styles.flexCol}>
            <EmployeeBehaviour />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDashboard;
