import EmployeeVehicleList from "./EmployeeVehicleList";
import EmployeeVehicleEdit from "./EmployeeVehicleEdit";
import EmployeeVehicleCreate from "./EmployeeVehicleCreate";
import { VehicleIcon } from "../svgIcon";

export default {
  list: EmployeeVehicleList,
  create: EmployeeVehicleCreate,
  edit: EmployeeVehicleEdit,
  icon: VehicleIcon,
};
