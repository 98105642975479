import * as React from "react";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Employee } from "../types";
import EmptyUser from "../employees/emptyUser.png";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  root: { width: 25, maxWidth: 25, maxHeight: 25 },
  media: {
    width: 280,
  },
  logo: { width: "100%", height: "inherit" },
});
interface State {
  images?: any;
  isBlobFile?: any;
}
const EmployeeLogo: FC<FieldProps<Employee>> = ({ record }) => {
  const classes = useStyles();
  let isBlobFile = false;
  if (record && record.profile_image) {
    isBlobFile = record.profile_image.indexOf("base64") > -1 ? true : false;
  }

  return record && record.profile_image && isBlobFile ? (
    <img src={EmptyUser} alt="Employee" className={classes.logo} />
  ) : (
    <img
      src={
        record && record.profile_image
          ? uploadApiUrl + record.profile_image
          : record &&
            record.companyLogo &&
            Object.keys(record.companyLogo).length != 0
          ? uploadApiUrl + record.companyLogo
          : EmptyUser
      }
      alt="Employee"
      onError={(e: any) => {
        e.target.src = EmptyUser; // some replacement image
      }}
      className={classes.logo}
    />
  );
};

export default EmployeeLogo;
