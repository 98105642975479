import TabPanel from "./TabPanel";
import { DesignationIcon } from "../svgIcon";
import { CommonDesignations } from "../designations/DesignationsCommon";

export default {
  list: TabPanel,
  create: CommonDesignations,
  edit: CommonDesignations,
  icon: DesignationIcon,
};
