import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import helperFunc from "../helperFuncs";
import CompanyReferenceInput from "./CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { FieldProps, Department } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { approvalStatus } from "../helperChoice";
import { requestStyles } from "../helperStyle";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(requestStyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const user = helperFunc.getUserData();
  return (
    <>
      <SimpleForm {...props}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput record={formData}></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
              validate={nameValidate}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <EmployeeSelectInput
              filter={{
                isActive: true,
                childCompanyId: formData.childCompanyId,
                company: formData.company,
              }}
              validate={nameValidate}
            ></EmployeeSelectInput>
          )}
        </FormDataConsumer>
        {/* <SectionTitle label="" /> */}
        <FormDataConsumer formClassName={isSmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <TextInput
              autoFocus
              source="carNumber"
              resource="employeeVehicle"
              formClassName={classes.name}
              validate={nameValidate}
            />
          )}
        </FormDataConsumer>
        <SectionTitle label="" />

        <FormDataConsumer formClassName={isSmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <TextInput
              //   multiline={true}
              //   disabled={props && Object.keys(props.record).length === 0 ? false : true}
              source="brand"
              resource="employeeVehicle"
              formClassName={classes.name}
              // fullWidth={isXsmall ? null : true}
              //   validate={nameValidate}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer formClassName={isSmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <TextInput
              // multiline={true}
              // disabled={props && Object.keys(props.record).length === 0 ? false : true}
              source="type"
              resource="employeeVehicle"
              formClassName={classes.name}
              // fullWidth={isXsmall ? null : true}
              //   validate={[required(), maxLength(15)]}
            />
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="designations"
          defaultValue="true"
        />
        <SectionTitle label="" />
      </SimpleForm>
    </>
  );
};

// const RequestTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

// const requiredValidate = [required()];
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
