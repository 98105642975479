import DocumentTypeList from "./DocumentTypeList";

import { DepartmentIcon, DocumentTypeIcon } from "../svgIcon";
import { CommonDocumentType } from "./documentTypeCommon";

export default {
  list: DocumentTypeList,
  create: CommonDocumentType,
  edit: CommonDocumentType,
  icon: DocumentTypeIcon,
};
