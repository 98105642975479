import React, { useRef, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Button, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import organization from "./org.json";
import { saveAs } from "file-saver";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import html2canvas from "html2canvas";
import { Margin, usePDF } from "react-to-pdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import clsx from "clsx";
import { Menu, MenuItem } from "@material-ui/core";
import { toastError } from "../components/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 5,
    height: "auto",
    width: "auto",
    lineHeight: "normal",
    cursor: "Pointer",
    backgroundColor: " #2032b7e6",
    color: "#2032b7e6",
  },

  rootLevel0: {
    background: "white",
    display: "inline-block",
    borderRadius: 5,
    height: "auto",
    width: "auto",
    lineHeight: "normal",
    cursor: "Pointer",
    backgroundColor: " #2e85c7 ",
    color: " #2e85c7 ",
  },
  rootLevel1: {
    background: "white",
    display: "inline-block",
    borderRadius: 5,
    height: "auto",
    width: "auto",
    lineHeight: "normal",
    cursor: "Pointer",
    backgroundColor: "#167572e0 ",
    color: "#167572e0  ",
  },
  rootLevel2: {
    background: "white",
    display: "inline-block",
    borderRadius: 5,
    height: "auto",
    width: "auto",
    lineHeight: "normal",
    cursor: "Pointer",
    backgroundColor: "#77c7a7",
    color: "#77c7a7",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "none",
    borderBottom: "1px solid #d6d6e763",
    color: "white",
    paddingBottom: "1px",
  },
  subTitle: {
    fontSize: 12,
    lineHeight: "normal",
    color: "#e5e9ef",
    lineBreak: "normal",
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#ECECF4",
  },
}));

function Organization({ org, onCollapse, collapsed, exportPage }) {
  const classes = useStyles();

  return (
    <Card
      variant="outlined"
      className={
        org.level === "parent"
          ? classes.root
          : org.level === 0
          ? classes.rootLevel0
          : org.level === 1
          ? classes.rootLevel1
          : org.level === 2
          ? classes.rootLevel2
          : classes.root
      }
      // style={{
      //   cursor: "Pointer",
      //   backgroundColor: "#2e85c7",
      //   color: "#2e85c7",
      // }}
    >
      <CardContent
        style={{
          padding: 5,
        }}
      >
        <div style={{ position: "relative" }}>
          <div>
            <span className={classes.title} variant="subtitle1">
              {org.title}
            </span>
          </div>
          <div>
            <span variant="subtitle2" className={classes.subTitle}>
              {org.subTitle}
            </span>
          </div>
          <div>
            <span variant="subtitle2" className={classes.subTitle}>
              {org.name}
            </span>
          </div>
          {org?.children?.length > 0 && (
            <div style={{}}>
              <IconButton
                size="small"
                onClick={onCollapse}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: !collapsed,
                })}
              >
                <ExpandMoreIcon
                  style={{ position: "absolute", color: "#ffffffc9" }}
                />
              </IconButton>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
function Account({ a }) {
  const classes = useStyles();

  return (
    <Card
      variant="outlined"
      className={classes.root}
      // ref={drag}
      style={{ cursor: "Pointer", backgroundColor: "#f57622" }}
    >
      <CardContent
        style={{
          padding: 5,
        }}
      >
        <div>
          <div>
            <span className={classes.title}>{a?.title}</span>
          </div>
          <div>
            <span className={classes.subTitle}>{a?.subTitle}</span>
          </div>
          <div>
            <span className={classes.subTitle}>{a?.name}</span>
          </div>
        </div>

        {/* <IconButton
          size="small"
          // onClick={onCollapse}
          // className={clsx(classes.expand, {
          //   [classes.expandOpen]: !collapsed,
          // })}
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardContent>
    </Card>
  );
}
function Product({ p }) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <Typography className={classes.title} variant="subtitle1">
          {p?.title}
        </Typography>
        <Typography variant="subtitle2">{p?.name}</Typography>
      </CardContent>
    </Card>
  );
}
function Node({ o, parent, chartRef, exportPage }) {
  const [collapsed, setCollapsed] = React.useState(
    o.level === "parent" ? false : true
  );

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });

  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"lightGrey"}
          lineHeight={"25px"}
          lineBorderRadius={"5px"}
          ref={chartRef}
          id="stackD"
          // nodePadding={"3px"}
          // padding={"2px"}
        >
          {props.children}
        </Tree>
      );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
          exportPage={exportPage}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
          exportPage={exportPage}
        />
      }
    >
      {/* {_.map(o.children, (a) => (
        <TreeNode label={<Account a={a} />}>
          <TreeNode label={<Product p={a} />} />
        </TreeNode>
      ))} */}
      {_.map(o.children, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}
const theme = createMuiTheme({
  palette: {
    background: "#ECECF4",
  },
  fontFamily: "Roboto, sans-serif",
});

export default function OrgReactChart(props) {
  const chartRef = useRef();
  const saveCanvas = () => {
    //save to png
    const canvasSave = document.getElementById("stackD");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "testing.png");
    });
  };

  const dataProvider = useDataProvider();
  const [chartData, setChartData] = useState();
  const [exportPage, setExportPage] = useState(false);

  useEffect(() => {
    const resourcePath = `employees/list-organization`;
    dataProvider
      .callCustom(resourcePath, {})
      .then((response) => {
        setChartData(response?.data);
      })
      .catch((err) => {
        toastError(err);
      });
  }, []);

  const { toPDF, targetRef } = usePDF({
    filename: "usepdf-example.pdf",
    page: { margin: Margin.MEDIUM },
  });

  const downloadImage = () => {
    const table = document.getElementById("table-container");

    html2canvas(table).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "organizationChart.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const options = [
    { label: "Image", onClick: downloadImage },
    { label: "Pdf", onClick: toPDF },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  return (
    <ThemeProvider theme={theme}>
      <div className="org-chart-border">
        <div className="d-flex justify-content-end">
          <Button
            style={{
              padding: "3px 0px 0px 0px",
              fontSize: "13px",
            }}
            color="primary"
            component="span"
            onClick={handleClick}
          >
            <GetAppIcon style={{ marginRight: "0.2em" }} />
            Export
          </Button>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
            style={{ top: "40px" }}
          >
            {options.map((option) => (
              <MenuItem
                key={option?.label}
                onClick={() => option?.onClick(option?.label)}
              >
                {option?.label}
              </MenuItem>
            ))}
          </Menu>
          {/* <a onClick={downloadImage}>Download as PNG</a>

        <a onClick={toPDF}>Download PDF</a> */}
        </div>
        {chartData?.list?.length === 0 ? (
          <div className="noRecordText">No Hierarchy Found</div>
        ) : (
          <Box className="form-content">
            <DndProvider backend={HTML5Backend}>
              {chartData?.list?.map((ele) => {
                return (
                  <div id="table-container" ref={targetRef}>
                    <Node o={ele} chartRef={chartRef} exportPage={exportPage} />
                  </div>
                );
              })}
            </DndProvider>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
}
