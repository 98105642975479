import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";
import FullNameField from "./FullNameField";
import { FieldProps } from "../types";
import { Record } from "ra-core";

export interface Leaves extends Record {
  _id: string;
  company: string;
  leaveName: string;
  code: string;
  isActive: boolean;
  setViewMode: any;
  viewMode: boolean;
}
const FinalReportLinkField: FC<FieldProps<Leaves>> = (props: any) =>
  props.record ? (
    <Link to={{
      pathname: `/final-report/${props.record._id}`,
      state: { attendenceId: props.record && props.record.attendenceId },
    }}>
      <FullNameField {...props} />
    </Link>
  ) : null;

FinalReportLinkField.defaultProps = {
  source: "attendenceId",
  addLabel: true,
};

export default FinalReportLinkField;
