import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, Theme } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { lightTheme } from "./themes";
import Footer from "./components/Footer";
import Header from "./components/Header";

const AccessControl = () => {
  var advantageboxRef1 = React.useRef(null);
  var container: any = null;

  const handleMouseEnter = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.add("active");
    container = null;
  };

  const handleMouseOut = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.remove("active");
    container = null;
  };
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <div className="inner-page access-control dropdown-page">
        {/* <div id="preloader">
          <div classNameName="preload-content">
            <div id="dream-load"></div>
          </div>
        </div> */}

        {/* <div id="preloader">
          <div className="preload-content">
            <div id="dream-load"></div>
          </div>
        </div> */}
        <Header />
        {/* <!--Header Section End--> */}
        {/* <!-- Breadcrumb Start --> */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-shape"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inn">
                  <div
                    className="section-title wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <h2>
                      <span>ACCESS CONTROL</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Breadcrumb End --> */}
        {/* <!-- about section  --> */}
        <section
          className="section_100 py-2 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-1 order-md-0 order-lg-0 order-xl-0">
                <div className="hero-left">
                  <h2>
                    <span>Face Recognition Based Access Control</span>{" "}
                    <span></span>
                  </h2>
                  <p>
                    The purpose of access control is to grant entrance to a
                    highly confidential office space only to the authorized
                    peoples. Turnout helps in that with facial recognition based
                    authorization.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-0 order-md-1 order-lg-1 order-xl-1">
                <img
                  src="assets/img/accessControl.png"
                  alt="Face Recognition Based Access Control"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- key features  --> */}
        <section
          className="about-section  turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">Key Features</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage1")}
                  onMouseOut={() => handleMouseOut("advantage1")}
                  id="advantage1"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-face-recognition"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Face Recognition</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage2")}
                  onMouseOut={() => handleMouseOut("advantage2")}
                  id="advantage2"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-deep-learning"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Deep Learning Based Computer Vision</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage3")}
                  onMouseOut={() => handleMouseOut("advantage3")}
                  id="advantage3"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-secure-cloud"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Secure Cloud Storage</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage4")}
                  onMouseOut={() => handleMouseOut("advantage4")}
                  id="advantage4"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-pricing"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Pay As You Use Pricing Model</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage5")}
                  onMouseOut={() => handleMouseOut("advantage5")}
                  id="advantage5"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-surveillance"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Easy Integration With Surveillance System</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage6")}
                  onMouseOut={() => handleMouseOut("advantage6")}
                  id="advantage6"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-automated"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Automated Alerts, Reports and Analytics</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">How It Works</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <img
                  src="assets/img/turnout-access-control.jpg"
                  alt="How it works"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-section access-benefits  turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2 className="mb-0 text-white">
                    <span>Benefits of Turnout</span>
                  </h2>
                  <p className="text-white">
                    Compared to Conventional Access Control System
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-4 w-max-content mx-auto justify-content-center">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <img
                  src="assets/img/Visitor-Management-benifit.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <ul className="list-box">
                  <li>
                    <h3>Benefits</h3>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Cannot be conjectured or stolen.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      High individual identification accuracy.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Less exposed to damage and sudden changes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Cannot lost or forgot.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section_100 py-0 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  src="assets/img/number-plate.png"
                  alt="Number plate based vehicle access control"
                />
              </div>
              <div className="col-lg-6">
                <div className="hero-left">
                  <h2>
                    <span>Number plate based vehicle access control</span>{" "}
                    <span></span>
                  </h2>
                  <p>
                    The purpose of access control is to grant entrance to a
                    registered vehicles in the premises. Here we use an advance
                    ANPR technology to grant an access to the vehicles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="benefits about-section turnout-s section_100 wow fadeInUp"
          style={{ backgroundColor: "#eef3fa" }}
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          {/* <!-- <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape">
        </div>
        <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape">
        </div> --> */}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2 className="mb-1">
                    <span className="underline">Key Features</span>
                  </h2>
                  <p>Number Plate Based Vehicle Access Control</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage7")}
                  onMouseOut={() => handleMouseOut("advantage7")}
                  id="advantage7"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-vehicle"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Vehicle Management</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage8")}
                  onMouseOut={() => handleMouseOut("advantage8")}
                  id="advantage8"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-deep-learning"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Deep Learning Based Computer Vision</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage9")}
                  onMouseOut={() => handleMouseOut("advantage9")}
                  id="advantage9"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-secure-cloud"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Secure Cloud Storage</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage10")}
                  onMouseOut={() => handleMouseOut("advantage10")}
                  id="advantage10"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-pricing"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Pay As You Use Pricing Model</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage11")}
                  onMouseOut={() => handleMouseOut("advantage11")}
                  id="advantage11"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-automated"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Automated Alerts, Reports and Analytics</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage12")}
                  onMouseOut={() => handleMouseOut("advantage12")}
                  id="advantage12"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-secure-data"></i>
                  </div>
                  <div className="service-title text-center">
                    <h3>Securing premises with limited vehicle access</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">How It Works</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <img
                  src="assets/img/how-it-works-vehicle.jpg"
                  alt="How it works"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-section  vehicle-benefits turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2 className="mb-0">
                    <span>
                      Benefits of Number Plate Based <br />
                      Vehicle Access Control System
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-start mt-4 w-max-content mx-auto justify-content-center">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <img
                  src="assets/img/number-plate-r.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <ul className="list-unstyled list-box">
                  <li>
                    <h3>Benefits Of Number Plate</h3>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Positioned at site entrances with barriers, gates.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      System uses personal databases with vehicle and driver
                      profiles.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Vehicles can be categorized into known sub-categories such
                      as staff, contractors, etc.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Provide reliable, time-stamped indisputable evidence.
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-check-circle-o"></i>
                      Entry and exit records is stored for future viewing and
                      analysis.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- benefits  --> */}
        {/* <!-- industry Application --> */}
        <section
          className="turnout-s section_100 wow fadeInUp position-relative"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">Industry Application</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/healthcare.png"
                      alt="Healthcare"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Healthcare</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/Real-Estate.png"
                      alt="Real Estate"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Real Estate</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/manufacturing.png"
                      alt="Manufacturing"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Manufacturing</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/tourism.png"
                      alt="Travel and Tourism"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Travel and Tourism</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/insurance.png"
                      alt="Insurance"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Insurance</h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/offices.png"
                      alt="Offices"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Offices </h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/education.png"
                      alt="Education"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Education</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/retail.png"
                      alt="Retail"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Retail</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/agency.png"
                      alt="Agency"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Agency</h4>
                </div>
              </div>
              <div className="col-lg col-md-6">
                <div className="card industry text-center border-0">
                  <div className="icon">
                    <img
                      src="assets/img/bank.png"
                      alt="Banking"
                      className="img-fluid d-block mx-auto mb-3"
                    />
                  </div>
                  <h4>Banking</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

AccessControl.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const AccessControlWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <AccessControl {...props} />
  </ThemeProvider>
);

export default AccessControlWithTheme;
