import React from "react";
import { Create } from "react-admin";
import CommonForm from "./commonForm";

const RegionCreate = (props: any) => {
  return (
    <Create {...props} title="resources.regions.page.create">
      <CommonForm redirect={'/regions'} {...props} />
    </Create>
  );
};


export default RegionCreate;
