import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  email,
  AutocompleteInput,
  RadioButtonGroupInput,
  // DateInput,
  maxLength,
  minLength,
  number,
  BooleanInput,
  FormDataConsumer,
  DateInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import FullNameField from "./FullNameField";
import { Visitor, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import RegistrationToolbar from "./RegistrationToolbar";
import DeRegistrationToolbar from "./DeRegistrationToolbar";
import helperFunc from "../helperFuncs";
import { visitorStyles } from "../helperStyle";
import { gender, bloodGroup } from "../helperChoice";
import moment from "moment";
// import { DateInput, TimeInput, DateTimeInput } from "react-admin-date-inputs";
export const validatePasswords = ({
  birthdate,
  email,
  password,
  confirm_password,
}: {
  birthdate: string;
  email: string;
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;
  // if (
  //   birthdate &&
  //   moment(birthdate).format("YYYY-MM-DD") > helperFunc.addDays(18 * 365)
  // ) {
  //   errors.birthdate = " Age should be 18 or greater then 18 years";
  // }
  if (email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.email = "Must be a valid email";
    }
  }
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }
  return errors;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
const useStyles = makeStyles(visitorStyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.down("lg"));
  const isXLarge = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xl")
  );
  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/visitors/create"
          ? "Update Visitor"
          : "Add Visitor"}
      </h2>
      <SimpleForm {...props} validate={validatePasswords}>
        {props && Object.keys(props.record).length !== 0 ? (
          <VisitorRegistration
            record={props && props.record}
          ></VisitorRegistration>
        ) : null}
        <SectionTitle label="resources.companies.fieldGroups.basic" />
        <div style={{ display: "inline" }}>
          <CompanyReferenceInput></CompanyReferenceInput>
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                filter={{ isActive: true }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>

          <TextInput
            autoFocus
            source="name"
            reference="visitors"
            formClassName={classes.name}
            validate={nameValidate}
          />

          <TextInput
            multiline
            label="label.address"
            source="metaInfo.address.lines"
            reference="visitors"
            formClassName={isXsmall ? null : classes.code}
            validate={addressValidate}
          />
          <RadioButtonGroupInput
            source="gender"
            reference="visitors"
            fullWidth
            formClassName={isXsmall ? null : classes.code}
            validate={[required()]}
            choices={gender}
          />

          <SectionTitle label="" />
          <TextInput
            label="label.mobile"
            source="phone"
            reference="visitors"
            formClassName={classes.name}
            validate={mobValidate}
          />

          <TextInput
            label="label.alternateMobile"
            source="alternatePhone"
            reference="visitors"
            formClassName={isXsmall ? null : classes.code}
            validate={altmobValidate}
          />
          <AutocompleteInput
            reference="visitors"
            source="bloodGroup"
            // validate={[required()]}
            formClassName={isXsmall ? null : classes.code}
            choices={bloodGroup}
          />

          <SectionTitle label="" />

          <TextInput
            type="email"
            source="email"
            reference="visitors"
            validation={{ email: true }}
            fullWidth={isXsmall ? false : true}
            formClassName={classes.email}
            validate={[email()]}
          />
          <DateInput
            options={{ format: helperFunc.datePickerFormat(), clearable: true }}
            label="label.dateOfBirth"
            reference="visitors"
            source="birthdate"
            formClassName={isXsmall ? null : classes.code}
            validate={requiredValidate}
            inputProps={{
              min: "1900-08-10",
              max: "3000-08-20",
            }}
          />

          {/* <SectionTitle label="resources.customers.fieldGroups.password" />
<PasswordInput source="password" formClassName={classes.password} />
<PasswordInput
  source="confirm_password"
  formClassName={classes.confirm_password}
/> */}
          <BooleanInput source="isActive" label="label.status" />
        </div>
      </SimpleForm>
    </React.Fragment>
  ) : (
    <SimpleForm {...props} validate={validatePasswords}>
      {props && Object.keys(props.record).length !== 0 ? (
        <VisitorRegistration
          record={props && props.record}
        ></VisitorRegistration>
      ) : null}

      <CompanyReferenceInput></CompanyReferenceInput>
      <SectionTitle label="resources.companies.fieldGroups.basic" />

      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <ChildCompanySelectInput
            filter={{ isActive: true }}
          ></ChildCompanySelectInput>
        )}
      </FormDataConsumer>
      <br />

      <TextInput
        autoFocus
        source="name"
        reference="visitors"
        formClassName={classes.name}
        validate={nameValidate}
      />

      <TextInput
        multiline
        label="label.address"
        source="metaInfo.address.lines"
        reference="visitors"
        formClassName={isXsmall ? null : classes.code}
        validate={addressValidate}
      />
      <RadioButtonGroupInput
        source="gender"
        reference="visitors"
        fullWidth
        formClassName={isXsmall ? null : classes.code}
        validate={[required()]}
        choices={gender}
      />

      <SectionTitle label="" />
      <TextInput
        label="label.mobile"
        source="phone"
        reference="visitors"
        formClassName={classes.name}
        validate={mobValidate}
      />

      <TextInput
        label="label.alternateMobile"
        source="alternatePhone"
        reference="visitors"
        formClassName={isXsmall ? null : classes.code}
        validate={altmobValidate}
      />
      <AutocompleteInput
        reference="visitors"
        source="bloodGroup"
        // validate={[required()]}
        formClassName={isXsmall ? null : classes.code}
        choices={bloodGroup}
      />

      <SectionTitle label="" />

      <TextInput
        type="email"
        source="email"
        reference="visitors"
        validation={{ email: true }}
        fullWidth={isXsmall ? false : true}
        formClassName={classes.email}
        validate={[email()]}
      />
      <DateInput
        label="label.dateOfBirth"
        reference="visitors"
        source="birthdate"
        formClassName={isXsmall ? null : classes.code}
        validate={requiredValidate}
        options={{ format: helperFunc.datePickerFormat(), clearable: true }}
        inputProps={{
          min: "1900-08-10",
          max: "3000-08-20",
        }}
      />
      {/* <FormDataConsumer formClassName={isXsmall ? null :classes.name}>
        {({ formData }: { formData: any }) => (
          <ChildCompanySelectInput
            filter={{ isActive: true }}
          ></ChildCompanySelectInput>
        )}
      </FormDataConsumer> */}
      {/* <SectionTitle label="resources.customers.fieldGroups.password" />
        <PasswordInput source="password" formClassName={classes.password} />
        <PasswordInput
          source="confirm_password"
          formClassName={classes.confirm_password}
        /> */}
      <BooleanInput source="isActive" label="label.status" />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const addressValidate = [
  required(),
  maxLength(200),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const mobValidate = [
  required(),
  number(),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const altmobValidate = [
  number(),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const VisitorTitle: FC<FieldProps<Visitor>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const VisitorRegistration: FC<FieldProps<Visitor>> = ({ record }) => {
  return record && record.isRegistred ? (
    <DeRegistrationToolbar record={record} />
  ) : (
    <RegistrationToolbar record={record} />
  );
};
const requiredValidate = [required()];

export default CommonForm;
