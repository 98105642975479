import * as React from "react";
import { FC, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
// import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, useMediaQuery, Theme } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useDataProvider,
} from "react-admin";
import employees from "../employees";
import { FieldProps, Employee } from "../types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toastError, toastInfo } from "../components/toast";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  mobileRoot: { textAlign: "left" },
  button: { backgroundColor: "#f44336" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const DeRegistrationToolbar: FC<FieldProps<Employee>> = ({ record }: any) => {
  const mainApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [isLocalDevice, setLocalDevice] = useState(false);
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  // const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const company =
    typeof record.company === "object" ? record.company._id : record.company;
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalDevice(event.target.checked);
  };
  const handleConfirm = (id: any) => {
    if (isLocalDevice) {
      setLoading(true);
      const apiUrl = "employees/get-device-info/" + id;
      dataProvider.callCustom(apiUrl).then((response: any) => {
        if (response.data) {
          const employeeData = response.data;
          if (
            employeeData &&
            employeeData.device &&
            employeeData.device.deviceType === "turnoutR"
          ) {
            dataProvider
              .callCustomLocal(
                employeeData.device.edgeURL + "/empderegistration",
                {
                  rfid: employeeData.rfid,
                  company_id: company,
                }
              )
              .then((responseLocal: any) => {
                if (responseLocal.data.success === true) {
                  dataProvider
                    .update("employees", {
                      id: id,
                      isRegistred: false,
                    })
                    .then((response: any) => {
                      toastInfo("Employee de-register successfully.");
                      // notify("Employee de-register successfully.");
                      refresh();
                      setLoading(false);
                    })
                    .catch((err: any) => {
                      setLoading(false);
                    });
                }
              });
          } else if (
            employeeData &&
            employeeData.device &&
            employeeData.device.deviceType === "turnoutE"
          ) {
            dataProvider
              .callCustomLocal(
                employeeData.device.edgeURL + "/deregistration",
                {
                  traget_ID: id,
                  company_id: company,
                }
              )
              .then((responseLocal: any) => {
                if (responseLocal.data.success === true) {
                  dataProvider
                    .callCustom("employees/employee_de_registration", {
                      person_ID: id,
                      isUploaded: responseLocal.data.isUpdated
                        ? responseLocal.data.isUpdated.toString() === "1"
                        : false,
                      isRegistred: false,
                    })
                    .then((response: any) => {
                      toastInfo("Employee de-register successfully.");
                      // notify("Employee de-register successfully.");
                      refresh();
                      setLoading(false);
                    })
                    .catch((err: any) => {
                      setLoading(false);
                    });
                } else {
                  toastError(responseLocal.data.msg);
                  // notify(responseLocal.data.msg, "warning");
                  setLoading(false);
                }
              })
              .catch((err: any) => {
                setLoading(false);
              });
          }
        }
      });
    } else {
      setLoading(true);
      dataProvider
        .callCustomLocal(`${mainApiUrl}/employees/de_registration`, {
          person_ID: id,
          filter: { company: company },
        })
        .then((response: any) => {
          if (
            response &&
            response.data &&
            response.data.success
            // response.data.body &&
            // response.data.body.success
          ) {
            toastInfo("De-registration process is started.");
            // notify("De-registration process is started.");
            refresh();
          } else {
            toastError(response.data.msg);
            // notify(response.data.msg, "warning");
          }
          setLoading(false);
        })
        .catch((err: any) => {
          toastError(err.message);
          setLoading(false);
        });
    }
    setOpen(false);
  };

  return record ? (
    <div className={isXsmall ? classes.mobileRoot : classes.root}>
      {isXsmall ? (
        <employees.icon
          style={{ fontSize: 30, color: "red", textAlign: "center" }}
          onClick={handleClick}
        />
      ) : (
        <Button
          variant="contained"
          label="De-Registration"
          onClick={handleClick}
          align="right"
          className={classes.button}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={25} thickness={2} />
          ) : (
            <employees.icon className={classes.icon} />
          )}
        </Button>
      )}
      {/* <Confirm
                isOpen={open}
                title="De-Registration"
                content="Are you sure you wish to De-register this Employee ?"
                onConfirm={() => handleConfirm(record.id)}
                onClose={handleDialogClose}
            /> */}
      <Dialog
        fullWidth={false}
        open={open}
        onClose={handleDialogClose}
        aria-label="De-Registration"
        style={{ zIndex: 1 }}
      >
        <DialogTitle>De-Registration</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you wish to De-register this Employee ?
          </Typography>
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={isLocalDevice}
                onChange={handleChange}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Is Local Device"
          />
        </DialogContent>

        <DialogActions>
          <Button
            // disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={() => handleConfirm(record.id)}
          >
            <CheckCircleIcon />
          </Button>
          <Button
            label="Cancel"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default DeRegistrationToolbar;
