import * as React from "react";
import { FC, Fragment } from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  downloadCSV,
  FormDataConsumer,
  required,
  TopToolbar,
  ExportButton,
  DateInput,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { Route, RouteChildrenProps } from "react-router-dom";
import { ListComponentProps } from "../types";
// import GridList from "./GridList";
// import { ImportButton } from "react-admin-import-csv";
import jsonExport from "jsonexport/dist";
import EmployeeLeaveTypeSelectInput from "../components/EmployeeLeaveTypeSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";

import moment from "moment";

// import { DateInput } from "react-admin-date-inputs";

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_to) {
        values.time_to = moment.utc(moment(values.time_to).utc()).format();
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
    // if (Object.keys(values).length > 0) {
    //   data.setFilters(values);
    //   data.handleDrawerClose("fromFilter");
    // } else {
    //   data.setFilters(values);
    //   data.handleDrawerClose("fromFilter");
    // }
  };

  const resetFilter = () => {
    data.setFilters(
      {
        time_to: moment.utc(moment(new Date()).utc()).format(),
      },
      []
    );
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));

  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {/* <Box mr={2}></Box> */}
                    {helperFunc.getUserData().type === "super" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <CompanyReferenceInput
                            helperText={false}
                          ></CompanyReferenceInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                {helperFunc.getChildCompany() === "true" && (
                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            helperText={false}
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                )}

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <EmployeeSelectInput
                          helperText={false}
                          filter={{
                            company: formData.company,
                            isActive: true,
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          // options={{
                          //   format: helperFunc.datePickerFormat(),
                          //   clearable: true,
                          // }}
                          // defaultValue={helperFunc.addDays(1, "forReport")}
                          validate={[required()]}
                          source="time_to"
                          label="label.toDate"
                          alwaysOn
                          helperText={false}
                          // inputProps={{
                          //   min: "1900-08-10",
                          //   max: "3000-08-20",
                          // }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      {/* ) : null} */}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    // marginTop: "1em",
  },
  name: { display: "inline-block" },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
});

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      name,
      code,
      leaveName,
      leaves,
      requested,
      approved,
      rejected,
      pendingApproval,
      spent,
      pending,
      isActive,
      leaveDays,
      isApprove,
      approvedBy,
      rejectBy,
      notifyTo,
      company,
      sandwichRule,
      isDeleted,
      _id,
      mailFrom,
      subject,
      fromDate,
      time_to,
      toDate,
      description,
      createdBy,
      createdAt,
      updatedAt,
      __v,
      updatedBy,
      id,
      userid,
      ...dataToExport
    } = record; // omit fields

    dataToExport["Name"] = name; // add a field
    dataToExport["Leave Name"] = leaveName; // add a field
    dataToExport["Allowed Leaves"] = leaves;
    dataToExport["Requested"] = requested;
    dataToExport["Approved"] = approved;
    dataToExport["Rejected"] = rejected;
    dataToExport["Pending Approval"] = pendingApproval;
    dataToExport["Spent"] = spent;
    dataToExport["Pending"] = pending;
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Leave Report"); // download as 'xxxx.csv` file
    }
  );
};

export const LeaveReportFilter = (props: any) => (
  <Filter {...props}>
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const AttendanceReportList: FC<ListComponentProps<{ id: string }>> = (
  props
) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div className={classes.root}>
      <Route path="/attendance/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          return (
            <Fragment>
              {isXSmall ? (
                <h2
                  style={{ fontSize: 30, backgroundColor: "#fff", padding: 10 }}
                >
                  Leave Report
                </h2>
              ) : null}
              <div
                className={open === true ? "overlay" : ""}
                onClick={() => handleDrawerClose("fromlist")}
              />
              <div
                style={
                  isXSmall
                    ? {
                        width: window.screen.width,
                        overflowX: "scroll",
                      }
                    : {}
                }
              >
                <List
                  className="list-view2"
                  bulkActionButtons={false}
                  title="resources.leaves.leaveReport"
                  exporter={exporter}
                  actions={
                    <ListActions
                      {...props}
                      handleDrawerOpen={handleDrawerOpen}
                    />
                  }
                  {...props}
                  filters={
                    <LeaveReportFilter
                      {...props}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                  }
                  perPage={20}
                  filterDefaultValues={{
                    time_to: moment.utc(moment(new Date()).utc()).format(),
                    company: helperFunc.selectedCompany(),
                  }}
                  pagination={
                    <Pagination
                      labelRowsPerPage="Records per page"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                    />
                  }
                  sort={{ field: "name", order: "ASC" }}
                >
                  <Datagrid>
                    <TextField source="name" label="Employee" />
                    <TextField
                      source="leaveName"
                      label="Leave Type"
                      sortBy="leaveName"
                    />
                    <TextField source="leaves" label="Allowed Leaves" />
                    <TextField source="requested" label="Requested" />
                    <TextField source="approved" label="Approved" />
                    <TextField source="rejected" label="Rejected" />
                    <TextField
                      source="pendingApproval"
                      label="Pending Approval"
                    />
                    <TextField source="spent" label="Spent" />
                    <TextField source="pending" label="Pending" />
                  </Datagrid>
                </List>
              </div>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default AttendanceReportList;
