import * as React from "react";
import { useState } from "react";
import { useDeleteMany, Button as ReactButton } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ActionDelete from "@material-ui/icons/Delete";
import MultiDeleteTable from "../components/ErrorTable";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTranslate } from "ra-core";
import { toastInfo } from "./toast";

const BulkDeleteButton = ({ resource, selectedIds }: any) => {
  const [deleteMany, { data, loading, error }] = useDeleteMany(
    resource,
    selectedIds
  );
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenClick = (index: any, allotment: any) => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  return (
    <>
      <MultiDeleteTable data={data} resource={resource} />
      <ReactButton
        disabled={loading}
        align="right"
        onClick={handleOpenClick}
        label={"Delete"}
        style={{ color: "#f44336" }}
        // className={classnames("ra-delete-button")}
        key="button"
      >
        <ActionDelete />
      </ReactButton>
      <DialogueBox
        handleCloseClick={handleCloseClick}
        open={showDialog}
        deleteMany={deleteMany}
        resource={resource}
      />
    </>
  );
};

const DialogueBox = (props: any) => {
  const translate = useTranslate();
  const handleClick = () => {
    props.deleteMany();
    props.handleCloseClick();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCloseClick}
        aria-label="Are you sure?"
      >
        <DialogTitle>
          Deleting{" "}
          {translate(
            `resources.${props.resource}.name`,
            props && props.resource
          )}
          (s)
        </DialogTitle>

        <DialogContent>
          <div style={{ margin: window.screen.width < 460 ? 15 : 0 }}>
            Are you sure you want to Delete{" "}
            {translate(
              `resources.${props.resource}.name`,
              props && props.resource
            )}
            (s)?
          </div>
          <div></div>
        </DialogContent>
        <DialogActions>
          <ReactButton type="submit" label="Confirm" onClick={handleClick}>
            <CheckCircleIcon />
          </ReactButton>
          <ReactButton
            label="ra.action.cancel"
            onClick={props.handleCloseClick}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </ReactButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkDeleteButton;
