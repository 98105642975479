import React, { useState, useEffect, useCallback, FC } from "react";
import { useVersion, useDataProvider, useNotify } from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import TotalHeads from "./TotalHeads";
import TotalDepartments from "./TotalDepartments";
import TotalDesignations from "./TotalDesignations";
import TotalDevices from "./TotalDevices";
import TotalNotifications from "./TotalNotifications";
import TotalRoles from "./TotalRoles";
import ChartEmployees from "./ChartEmployees";
import ListNotifications from "./ListNotifications";
import ListUpcomingEvents from "./ListUpcomingEvents";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { Customer, Order, Review, Request, Event } from "../types";
import { toastError } from "../components/toast";
import CustomCard from "./CustomCard";
import { EmployeeIcon, NotificationIcon } from "../svgIcon";
import ZoneSelectInput from "../components/ZoneSelectInput";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import { Dropdown } from "../common";
import RegEmpCard from "./components/company/RegisteredEmployeeCard";
import PresentEmployeeCard from "./components/company/PresentEmployee";
import AbsentEmployeeCard from "./components/company/AbsentEmployee";

interface CustomerData {
  [key: string]: Customer;
}

interface State {
  nbNewOrders?: number;
  nbPendingReviews?: number;
  pendingOrders?: Order[];
  pendingOrdersCustomers?: CustomerData;
  pendingReviews?: Review[];
  pendingReviewsCustomers?: CustomerData;
  recentOrders?: Order[];
  revenue?: number;
  Headcounts?: number;
  DepartmentCount?: number;
  DesignationCount?: number;
  DeviceCount?: number;
  Notifications?: number;
  ProjectCount?: number;
  RolesCount?: number;
  TaskChartData?: {};
  EmployeesChartData?: {};
  EmployeesData?: {};
  ProjectsChartData?: {};
  recentNotificationList?: Request[];
  recentEventList?: Event[];
  cardsCountData?: {};
  RegistrationCount?: any;
  RegistrationData?: any;
  TotalEmployee?: any;
  employeeAttendenceData?: any;
}

const styles = {
  flex: { display: "flex" },
  dropdownDiv: {
    marginRight: "5px",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
  },
  flexXSmall: {
    display: "flex",
    marginRight: "15px",
    marginLeft: "15px",
    width: window.screen.width - 10,
  },
  flexCol: { display: "flex", marginTop: "2em" },
  smallFlexCol: {
    display: "flex",
    marginTop: "2em",
    width: window.screen.width - 10,
  },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
};

const CompanyDashboard: FC = () => {
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  React.useEffect(() => {
    if (!isSmall && !isXSmall && container && container.innerText) {
      container.innerText = "";
      container.innerText = "Dashboard";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container && container.innerText) {
        container.innerText = "";
      }
    };
  }, [container]);
  const [state, setState] = useState<State>({});
  const [cardsCountLoading, setCardsCountLoading] = useState(false);
  const [diagramLoading, setDiagramLoading] = useState(false);
  const [filterData, setFilterData] = useState() as any;
  const [formData, setFormData] = useState() as any;
  const version = useVersion();
  // const notify = useNotify();
  const dataProvider = useDataProvider();

  const fetchCardsCount = useCallback(async () => {
    setCardsCountLoading(true);
    await dataProvider
      .callCustom("dashboard/cardsCount", { formData })
      .then((response: any) => {
        setCardsCountLoading(false);
        setState((state) => ({
          ...state,
          Headcounts:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.headcounts,
          DepartmentCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.departmentCount,
          DesignationCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.designationCount,
          DeviceCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.deviceCount,
          Notifications:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.notifications,
          ProjectCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.projectCount,
          RolesCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.rolesCount,
        }));
      })
      .catch((err: any) => {
        setCardsCountLoading(false);
        err.response.data.errors.forEach((error: any) => {
          toastError(error.msg);
          // notify(error.msg, "warning");
          if (err.response.status === 401) {
            // localStorage.clear()
            window.location.replace("/login");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider, formData]);

  const fetchDiagramDahboardData = useCallback(async () => {
    setDiagramLoading(true);

    const cardsCount = await dataProvider
      .callCustom("dashboard/diagramDahboardData", { formData })
      .then((response: any) => {
        setDiagramLoading(false);
        setState((state) => ({
          ...state,
          TaskChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.task,
          EmployeesChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.employeesCount,
          ProjectsChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.projects,
          EmployeesData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.employeesData,
          RegistrationCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.registration,
          RegistrationData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.registrationData,
          TotalEmployee:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.totalEmployee,
        }));
      })
      .catch((err: any) => {
        setDiagramLoading(false);
        err.response.data.errors.forEach((error: any) => {
          toastError(error.msg);
          if (err.response.status === 401) {
            // localStorage.clear()
            window.location.replace("/login");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider, formData]);

  const fetchCompanyListDahboardData = useCallback(async () => {
    const cardsCount = await dataProvider
      .callCustom("dashboard/dashboardTableListing", {
        requestLimit: 10,
        requestSkip: 0,
        eventSkip: 0,
        eventLimit: 10,
        formData,
      })
      .then((response: any) => {
        setState((state) => ({
          ...state,
          recentNotificationList:
            response &&
            response.data &&
            response.data.notification &&
            response.data.notification.item,
          recentEventList:
            response &&
            response.data &&
            response.data.upcoming_events &&
            response.data.upcoming_events.item,
          employeeAttendenceData: response?.data?.employeeAttendenceData,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(error.msg);
          if (err.response.status === 401) {
            // localStorage.clear()
            window.location.replace("/login");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider, formData]);

  useEffect(() => {
    fetchCardsCount();
    fetchDiagramDahboardData();
    fetchCompanyListDahboardData();
  }, [version, formData]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    cardsCountData,
    Headcounts,
    DepartmentCount,
    DesignationCount,
    DeviceCount,
    Notifications,
    ProjectCount,
    RolesCount,
    TaskChartData,
    EmployeesChartData,
    EmployeesData,
    ProjectsChartData,
    recentNotificationList,
    recentEventList,
    nbNewOrders,
    nbPendingReviews,
    pendingOrders,
    pendingOrdersCustomers,
    pendingReviews,
    pendingReviewsCustomers,
    revenue,
    recentOrders,
    RegistrationCount,
    RegistrationData,
    TotalEmployee,
    employeeAttendenceData,
  } = state;

  const handleResetFilter = () => {
    setFormData({});
    setFilterData({
      zoneId: "",
      regionId: "",
      state: "",
      city: "",
      branchId: "",
      workplaceId: "",
    });
  };

  return isXSmall ? (
    <div style={styles.flexXSmall}>
      <div style={styles.leftCol}>
        <div style={styles.smallFlexCol}>
          <TotalHeads isLoading={cardsCountLoading} value={Headcounts} />
        </div>

        <div style={styles.smallFlexCol}>
          <TotalDepartments value={DepartmentCount} />
        </div>

        <div style={styles.smallFlexCol}>
          <TotalDesignations value={DesignationCount} />
        </div>

        <div style={styles.smallFlexCol}>
          <TotalNotifications value={Notifications} />
        </div>
        <div style={styles.smallFlexCol}>
          <TotalRoles value={RolesCount} />
        </div>
        <div style={styles.smallFlexCol}>
          <TotalDevices value={DeviceCount} />
        </div>
        <div style={styles.smallFlexCol}>
          <CustomCard
            value={Notifications}
            icon={GroupIcon}
            title="pos.dashboard.registered_employees"
          />
        </div>
        <div style={styles.smallFlexCol}>
          <CustomCard
            value={Notifications}
            icon={GroupIcon}
            title="pos.dashboard.registered_employees"
          />
        </div>
        <div style={styles.smallFlexCol}>
          <CustomCard
            value={Notifications}
            icon={GroupIcon}
            title="pos.dashboard.absent_employees"
          />
        </div>
        {/* <div style={styles.smallFlexCol}>
          <ChartEmployees
            isLoading={diagramLoading}
            data={EmployeesChartData}
            data2={EmployeesData}
          ></ChartEmployees>
        </div> */}

        <div style={styles.smallFlexCol}>
          <ListNotifications notifications={recentNotificationList} />
        </div>
        <div style={styles.smallFlexCol}>
          <ListUpcomingEvents events={recentEventList}></ListUpcomingEvents>
        </div>
        <div style={styles.smallFlexCol}></div>
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.smallFlexCol}>
          <TotalHeads value={Headcounts} />
          <TotalDepartments value={DepartmentCount} />
        </div>
        <div style={styles.smallFlexCol}>
          <TotalDesignations value={DesignationCount} />
          <TotalNotifications value={Notifications} />
        </div>
        <div style={styles.smallFlexCol}>
          <TotalRoles value={RolesCount} />
          <TotalDevices value={DeviceCount} />
        </div>
        {/* <div style={styles.smallFlexCol}>
          <ChartEmployees
            isLoading={diagramLoading}
            data={EmployeesChartData}
            data2={EmployeesData}
          ></ChartEmployees>
        </div> */}

        <div style={styles.smallFlexCol}>
          <ListNotifications
            notifications={recentNotificationList}
          ></ListNotifications>
          <ListUpcomingEvents events={recentEventList}></ListUpcomingEvents>
        </div>
        <div style={styles.smallFlexCol}></div>
      </div>
    </div>
  ) : (
    <div className="form-content" style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.dropdownDiv}>
          {/* <Dropdown
            apiName="zones"
            setFilterData={setFilterData}
            filterData={filterData}
            name="zoneId"
            placeHolder="Zones"
            formData={formData}
            setFormData={setFormData}
          />
          <Dropdown
            apiName="regions"
            setFilterData={setFilterData}
            filterData={filterData}
            name="regionId"
            placeHolder="Regions"
            formData={formData}
            setFormData={setFormData}
          />
          <Dropdown
            apiName="states"
            setFilterData={setFilterData}
            filterData={filterData}
            name="state"
            placeHolder="States"
            formData={formData}
            setFormData={setFormData}
          />
          <Dropdown
            apiName="cities"
            setFilterData={setFilterData}
            filterData={filterData}
            name="city"
            placeHolder="Cities"
            formData={formData}
            setFormData={setFormData}
          />
          <Dropdown
            apiName="branches"
            setFilterData={setFilterData}
            filterData={filterData}
            name="branchId"
            placeHolder="Branches"
            formData={formData}
            setFormData={setFormData}
          />
          <Dropdown
            apiName="workplaces"
            setFilterData={setFilterData}
            filterData={filterData}
            name="workplaceId"
            placeHolder="Workplaces"
            formData={formData}
            setFormData={setFormData}
          /> */}
          {/* <div className="d-flex align-items-center ml-1">
            <HighlightOffRoundedIcon
              style={{ color: "#283593", cursor: "pointer" }}
              onClick={handleResetFilter}
            />
          </div> */}
        </div>

        <div style={styles.flex}>
          <TotalHeads value={Headcounts} />
          <TotalDepartments value={DepartmentCount} />
          <TotalDesignations value={DesignationCount} />
        </div>
        <div style={styles.flexCol}>
          <TotalNotifications value={Notifications} />
          <TotalRoles value={RolesCount} />
          <TotalDevices value={DeviceCount} />
        </div>
        <div style={styles.flexCol}>
          <RegEmpCard
            value={RegistrationCount?.registeredEmployeeCnt}
            icon={GroupIcon}
            title="pos.dashboard.registered_employees"
            bgColor="#31708f"
            data={RegistrationData}
          />
          <PresentEmployeeCard
            value={employeeAttendenceData?.onTimeEmployeeData?.length}
            icon={GroupIcon}
            title="pos.dashboard.present_employees"
            bgColor="#4caf50"
            data={employeeAttendenceData?.onTimeEmployeeData}
          />
          <AbsentEmployeeCard
            value={employeeAttendenceData?.absentEmployeeData?.length}
            icon={GroupIcon}
            title="pos.dashboard.absent_employees"
            bgColor="#f44336"
            data={employeeAttendenceData?.absentEmployeeData}
          />
        </div>
        {/* <div style={styles.flexCol}>
          <ChartEmployees
            isLoading={diagramLoading}
            data={EmployeesChartData}
            data2={EmployeesData}
          ></ChartEmployees>
        </div> */}

        <div style={styles.flexCol}>
          <ListNotifications
            notifications={recentNotificationList}
          ></ListNotifications>
          <ListUpcomingEvents events={recentEventList}></ListUpcomingEvents>
        </div>
        <div style={styles.flexCol}></div>
      </div>
    </div>
  );
};

export default CompanyDashboard;
