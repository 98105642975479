import TeamLeaveList from "./TeamLeaveList";
import TeamLeaveEdit from "./TeamLeaveEdit";
// import LeaveCreate from "./LeaveCreate";

import LeaveIcon from "@material-ui/icons/Bathtub";

export default {
  list: TeamLeaveList,
  // create: LeaveCreate,
  edit: TeamLeaveEdit,
  icon: LeaveIcon,
};
