import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import {
  required,
  useDataProvider,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
import helperFunc from "../helperFuncs";
import useDeepCompareEffect from "use-deep-compare-effect";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  employees?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  propsUrl?: any;
  dataManage?: any;
  helperText?: boolean;
  emptyValue?: any;
}

const MultipleEmployeeTypeSelectInput: FC<Props> = ({
  filter,
  propsUrl,
  helperText,
  emptyValue,
}) => {
  const user = helperFunc.getUserData();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const empSearchUrl = "employees/search";

  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList(propsUrl ? propsUrl : empSearchUrl, filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          employees: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { employees } = state;
  const form = useForm();

  if (emptyValue && emptyValue.emptyValue) {
    form.change("employee", undefined);
  }

  return (
    // <ReferenceArrayInput
    //   resettable
    //   label="Employees"
    //   source="employee"
    //   reference="employees"
    //   // choices={employees}
    //   // filter={{ company: formData.company, isActive: true }}
    //   // validate={requiredValidate}
    //   // formClassName={classes.referenceEmpArray}
    // >
    //   <AutocompleteArrayInput resettable choices={employees} />
    // </ReferenceArrayInput>

    <AutocompleteArrayInput
      resettable
      label="Employees"
      source="employee"
      choices={employees}
      // validate={requiredValidate}
      // className={formClassName}
      className={classes.root}
      helperText={helperText}
      // onChange = {handleData}
      // fullWidth={isXsmall ? false : true}
    />
  );
};
const requiredValidate = [required()];
MultipleEmployeeTypeSelectInput.defaultProps = {
  source: "employee",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultipleEmployeeTypeSelectInput);
