import * as React from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  SelectInput,
  SearchInput,
  Pagination,
  downloadCSV,
  BulkDeleteButton,
  TopToolbar,
  ExportButton,
  CreateButton,
  FunctionField,
  DateInput,
  FormDataConsumer,
  required,
  DateTimeInput,
} from "react-admin";
import {
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Theme,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import helperFunc from "../helperFuncs";
import RequestLinkField from "./RequestLinkField";
import MobileGrid from "./MobileGrid";
import { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";

import { approvalStatus, requestTypeArr } from "../helperChoice";
import { emptyUser } from "../img";

const drawerWidth = 350;
const approvalStatusLeave: any = {
  reject: "Rejected",
  approve: "Approved",
  pending: "Pending",
  cancel: "Cancel",
};
const statusType: any = {
  early_check_out: "Early Check Out",
  late_check_in: "Late Check In",
  missed_checkin: "Missed Check In",
  missed_checkout: "Missed Check Out",
  in_between_check_out: "In Between Check Out",
  other: "Other",
  over_time: "Over Time",
  procurement: "Procurement",
  attendancechangerequest: "Attendance Change Request",
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tbody: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "100px",
  },
  table: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "100px",
  },

  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  descWordWrap: {
    overflowWrap: "anywhere",
  },
  toolTipWidth: {
    width: "230px",
    fontSize: "50px",
    padding: 0,
  },
  textMerge: {
    width: "70px",
    height: "auto",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  userImg: { padding: 0, height: 24, width: 24, borderRadius: "30px" },
}));

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.fromTime > allValues.toTime) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.fromTime > allValues.toTime) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={data.filterValues}
            validate={(values) => {
              const errors: any = {};

              if (values.fromTime > values.toTime) {
                return "To date cannot be Smaller then From date";
              }

              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      // allowEmpty={false}
                      source="requestStatus"
                      label="label.approvalStatus"
                      choices={approvalStatus}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      source="requestType"
                      label="Request Type"
                      choices={requestTypeArr}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="fromTime"
                      validate={[checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[checkToDate]}
                      source="toTime"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const RequestFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeName" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      employee,
      isApprove,
      requestType,
      requestStatus,
      fromTime,
      toTime,
      reason,
    } = record; // omit fields

    return {
      Name: employee.name,
      "Request Type": statusType[requestType],
      "From Date Time": helperFunc.commonDateFormat(fromTime, "HH:mm:ss"),
      "To Date Time": helperFunc.commonDateFormat(toTime, "HH:mm:ss"),
      Reason: reason,
      Status: approvalStatusLeave[requestStatus],
    };
  });
  jsonExport(recordsForExport, (err: any, csv: any) => {
    downloadCSV(csv, "Requests Report"); // download as 'xxxx.csv` file
  });
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const RequestList = (props: any) => {
  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Requests
        </h2>
      ) : null}
      <div>
        <List
          exporter={exporter}
          {...props}
          actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
          filters={
            isSmall ? (
              <RequestFilter
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            ) : (
              <RequestFilter
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            )
          }
          sort={{ field: "createdAt", order: "DESC" }}
          perPage={20}
          bulkActionButtons={<PostBulkActionButtons />}
          pagination={
            <Pagination
              labelRowsPerPage="Records per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          }
          title="resources.attendenceRequestLog.title"
          // aside={<RequestListAside />}
          className="list-view2"
        >
          {isXsmall ? (
            <MobileGrid />
          ) : (
            <Datagrid optimized classes={classes}>
              <FunctionField
                label="label.image"
                render={(record: any) => (
                  <img
                    src={
                      record &&
                      record?.employee?.profile_image !== "" &&
                      uploadApiUrl &&
                      uploadApiUrl + record &&
                      uploadApiUrl + record?.employee?.profile_image &&
                      uploadApiUrl + record?.employee?.profile_image !== ""
                        ? uploadApiUrl + record?.employee?.profile_image
                        : emptyUser
                    }
                    // alt="Request"
                    className={classes.userImg}
                    // onError={(e: any) => {
                    //   e.target.src = "assets/img/emptyUser.png"; // some replacement image
                    // }}
                  />
                )}
              />

              <TextField source="employee.name" label="Employee" />
              <FunctionField
                label="Request Type"
                sortBy="requestType"
                render={(record: any) =>
                  `${
                    record["requestType"]
                      ? statusType[record?.requestType]
                      : "N/A"
                  }  `
                }
              />
              <FunctionField
                label="From Date & Time"
                sortBy="fromTime"
                render={(record: any) =>
                  `${
                    record.attendanceChangeRequestTime &&
                    record.requestStatus === "approve"
                      ? helperFunc.commonDateFormat(
                          record.attendanceChangeRequestTime,
                          "HH:mm:ss"
                        )
                      : record.fromTime
                      ? helperFunc.commonDateFormat(record.fromTime, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />
              <FunctionField
                label="To Date & Time"
                sortBy="toTime"
                render={(record: any) =>
                  `${
                    record.toTime
                      ? helperFunc.commonDateFormat(record.toTime, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />
              <FunctionField
                label="label.reasons"
                sortBy="reason"
                render={(record: any) => {
                  const d = (
                    <Fragment>
                      <div
                        data-tip={record.reason}
                        data-for="reason"
                        className={
                          record && record.reason ? classes.textMerge : ""
                        }
                      >
                        {record && record.reason ? record.reason : "N/A"}
                      </div>
                      {toolTip("reason")}
                    </Fragment>
                  );
                  return d;
                }}
              />

              <FunctionField
                label="Status"
                sortBy="requestStatus"
                render={(record: any) =>
                  `${
                    record?.requestStatus
                      ? approvalStatusLeave[record?.requestStatus]
                      : "N/A"
                  }  `
                }
              />
              <RequestLinkField />
            </Datagrid>
          )}
        </List>
      </div>
    </div>
  );
};

export default RequestList;
