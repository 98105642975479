import StudentList from "./studentList";
import { CommonStudent } from "./studentCommon";
import { StudentIcon } from "../svgIcon";

export default {
  list: StudentList,
  create: CommonStudent,
  edit: CommonStudent,
  icon: StudentIcon,
};
