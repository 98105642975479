import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  FormDataConsumer,
  Button,
  useDataProvider,
  useRefresh,
  LinearProgress,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";
import { useRedirect } from "ra-core";
import { streamStyle } from "../helperStyle";
import { SectionTitle, validate } from "../helperValidate";
import { toastError, toastInfo } from "../components/toast";
import MultiSelectTimeslot from "../components/MultiSelectTimeslotInput";
import MultiSelectWeekdaysInput from "../components/MultiSelectWeekdaysInput";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import ClassroomSelectInput from "../components/ClassroomSelectInput";

const useStyles = makeStyles(streamStyle);
export const AddDialogueBox = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const [paramsData, setParamsData] = useState() as any;
  const [isLoad, setLoader] = useState(false);
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [semesterChange, setSemesterChange] = useState(Boolean);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { close, open } = props;

  const saveForm = (data: any) => {
    if (data.stream === undefined || data.stream === null) {
      toastError("Please select Stream");
    } else if (data.course === undefined || data.course === null) {
      toastError("Please select Course");
    } else if (data.semester === undefined || data.semester === null) {
      toastError("Please select Semester");
    } else if (data.classroom === undefined || data.classroom === null) {
      toastError("Please select Classroom");
    } else if (data.weekDays === undefined || data.weekDays === null) {
      toastError("Please select Weekday(s)");
    } else if (data.timeslotIds === undefined || data.timeslotIds === null) {
      toastError("Please select Timeslot(s)");
    } else {
      setLoader(true);
      dataProvider
        .callCustom("assign-timeslot/create", {
          timeslots: data && data.timeslotIds ? data.timeslotIds : null,
          weekdays: data && data.weekDays ? data.weekDays : null,
          stream: data && data.stream ? data.stream : null,
          classroom: data && data.classroom ? data.classroom : null,
          semester: data && data.semester ? data.semester : null,
          course: data && data.course ? data.course : null,
        })
        .then((response: any) => {
          setLoader(false);
          close();
          redirect("assign-timeslot/list");
          toastInfo(`Assign Timeslot added Succesfully`);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };
  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };
  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };

  return (
    <div className={classes.dialogContentDiv}>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Timeslot Allotments"
        style={{ zIndex: 1 }}
        className="dialogWidth"
      >
        {" "}
        <DialogTitle className={classes.pb0}>
          {" "}
          <span className={classes.headTitle}>Assign Timeslots</span>
        </DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <SimpleForm {...props} toolbar={null}>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <StreamSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                    }}
                    dataManage={streamData}
                  ></StreamSelectInput>
                );
              }}
            </FormDataConsumer>

            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <CourseSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                    }}
                    streamChange={streamChange}
                    dataManage={courseData}
                  ></CourseSelectInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <SemesterSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                      course: formData.course,
                    }}
                    dataManage={semesterData}
                    streamChange={streamChange}
                    courseChange={courseChange}
                  ></SemesterSelectInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <ClassroomSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                      course: formData.course,
                      semester: formData.semester,
                    }}
                    streamChange={streamChange}
                    courseChange={courseChange}
                    semesterChange={semesterChange}
                  ></ClassroomSelectInput>
                );
              }}
            </FormDataConsumer>

            <SectionTitle label="" />
            <FormDataConsumer formClassName={isSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <Box className={classes.flexDiv}>
                    <MultiSelectWeekdaysInput
                      validate={validate}
                      filter={{ isActive: true }}
                    ></MultiSelectWeekdaysInput>
                  </Box>
                );
              }}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                setParamsData(formData);
                return (
                  <Box className={classes.flexDiv}>
                    <MultiSelectTimeslot
                      validate={validate}
                      filter={{ isActive: true, stream: formData.stream }}
                    ></MultiSelectTimeslot>
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              label="Save"
              onClick={() => saveForm(paramsData)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button label="Cancel" onClick={close} style={{ color: "#181818cf" }}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
