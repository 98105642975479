import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
}));

interface State {
  multiStream?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  isXsmall?: boolean;
  validate?: any;
  labelName?: any;
  dataManage?: any;
  emptyValue?: any;
  defaultValue?: any;
}

const MultiSelectStream: FC<Props> = ({
  filter,
  isXsmall,
  validate,
  labelName,
  dataManage,
  emptyValue,
  defaultValue,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const form = useForm();

  if (emptyValue && emptyValue.emptyValue) {
    form.change("stream", undefined);
  }
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getSearchList("stream/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          multiStream: response && response.data,
        }));
      });
  }, [filter]);

  const check = (value: any) => {
    const streamArr = [] as any;
    value.map((item: any) => {
      multiStream?.map((data: any) => {
        if (data.id === item) {
          streamArr.push(data.name);
          if (dataManage) {
            dataManage(streamArr.toString(), "stream");
          }
        }
      });
    });
  };

  const { multiStream } = state;

  return (
    <AutocompleteArrayInput
      fullWidth
      resettable
      label={labelName ? labelName : "Stream(s)"}
      source="stream"
      choices={multiStream}
      validate={validate}
      className={classes.root}
      // fullWidth={isXsmall ? false : true}
      onChange={(value: any) => check(value)}
      defaultValue={defaultValue}
    />
  );
};

MultiSelectStream.defaultProps = {
  source: "stream",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectStream);
