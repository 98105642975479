// import VisitorIcon from "@material-ui/icons/PersonOutline";
import VisitorList from "./VisitorList";
import VisitorEdit from "./VisitorEdit";
import VisitorCreate from "./VisitorCreate";
import { VisitorIcon } from "../svgIcon";

export default {
  list: VisitorList,
  create: VisitorCreate,
  edit: VisitorEdit,
  icon: VisitorIcon,
};
