import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import {
  SelectInput,
  useDataProvider,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";

interface State {
  departments?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  source?: any;
  label?: any;
  childCompanyChange?: any;
  className?: any;
  defaultValue?: any;
}

const SearchableEmployeeSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  propsUrl,
  source,
  label,
  childCompanyChange,
  className,
  defaultValue,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const empSearchUrl = "employees/search";

  useDeepCompareEffect(() => {
    if (filter) {
      dataProvider
        .getSearchList(propsUrl ? propsUrl : empSearchUrl, filter)
        .then((response: any) => {
          response &&
            response.data.forEach((item: any) => {
              item.name = `${item.name}(${item.employeeCode})`;
            });
          setState((state) => ({
            ...state,
            departments: response && response.data,
            // newDeparments: response && response.data
          }));
        });
    }
  }, [filter, dataProvider]);

  if (emptyValue && emptyValue.emptyValue) {
    form.change("employee", undefined);
  }
  if (childCompanyChange) {
    form.change(source, undefined);
  }
  const { departments } = state;
  const check = (value: any) => {
    departments?.map((data: any) => {
      if (data.id === value) {
        if (dataManage) {
          dataManage(data, "employee");
        }
      }
    });
  };

  return (
    <FormDataConsumer>
      {({ formData }: { formData: any }) => (
        <SelectInput
          source="invitedEmployeeId"
          label={label ? label : "Employee Name*"}
          resettable={true}
          choices={departments}
          initialValue={defaultValue}
          validate={validate}
        />
      )}
    </FormDataConsumer>
  );
};

SearchableEmployeeSelectInput.defaultProps = {
  source: "invitedEmployeeId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(SearchableEmployeeSelectInput);
