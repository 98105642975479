import * as React from "react";
import { Create } from "react-admin";
import CommonForm from '../states/commonForm';


const StateCreate = (props: any) => {
	return (
		<Create {...props}>
			<CommonForm redirect={'/states'} {...props} />
		</Create>
	);
};
export default StateCreate;
