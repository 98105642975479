import React from "react";
import { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  FormTab,
  maxLength,
  Toolbar,
  SaveButton,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery, Checkbox, Switch } from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormSpy, useForm } from "react-final-form";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { rolesStyles } from "../helperStyle";
import authProvider from "../authProvider";

const CustomToolbar = (props: any) => (
  <Toolbar alwaysEnableSaveButton {...props}>
    <SaveButton disabled={false} />
  </Toolbar>
);

const useStyles = makeStyles(rolesStyles);
const CommonForm = (props: any) => {
  const [permis, setPermi] = useState([]);
  const [moduleRole, setModuleRole] = useState([]);
  // const [listPer, setList] = useState(false);
  // const [createPer, setCreate] = useState(false);
  // const [updatePer, setUpdate] = useState(false);
  // const [deletePer, setDelete] = useState(false);

  permis.sort((a: any, b: any) => a.name.localeCompare(b.name));

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const SelectAllBtn = (props: any) => {
    const { role, index, ele, module } = props;
    const form = useForm();

    return (
      // <Checkbox
      //   key={role.id}
      //   resource={`role.isAll${role.id}`}
      //   onChange={(e: any) => {
      //     RowUpdate(e, role, form, index);
      //   }}
      //   checked={role.checked ? role.checked : false}
      // />
      <div className="role-switch">
        <Switch
          checked={ele?.checked ? ele?.checked : false}
          resource={`role.isAll${ele.id}`}
          key={ele?.id}
          onChange={(e: any) => {
            RowUpdate(e, role, form, ele, module, index);
          }}
          className="role-switch"
        />
      </div>
    );
  };

  const RowUpdate = (
    e: any,
    role: any,
    form: any,
    ele: any,
    module: any,
    ind: any
  ) => {
    let permiData = [...permis];

    permiData.forEach((data: any, index: any) => {
      if (module.id === data.id) {
        data?.moduleData?.forEach((ele2: any) => {
          ele2?.subModuleData?.forEach((subData: any) => {
            if (subData.id === ele.id) {
              subData.permissions.forEach(
                (element: any) => (element.checked = e.target.checked)
              );
              subData.checked = e.target.checked;
            }
          });
        });
      }
    });

    setPermi(permiData);
    updateFormValue(form, permiData, module, ele);
  };

  const updateFormValue = (
    form: any,
    permiData: any,
    module: any,
    subModule: any
  ) => {
    permiData.map((val: any) => {
      if (module?.id === val?.id) {
        val &&
          val.moduleData.forEach((newVal: any) => {
            newVal?.subModuleData?.forEach((ele: any) => {
              if (ele.id === subModule.id) {
                let permissions: any = [];
                ele?.permissions?.forEach((per: any) => {
                  if (per.checked) {
                    return permissions.push(per.id);
                  }
                });

                if (permissions.length > 0) {
                  form &&
                    form.change(
                      "hideFiled",
                      permiData.length + permissions.length + 1
                    );
                  return (
                    form && form.change("permissions." + ele.id, permissions)
                  );
                } else {
                  form &&
                    form.change(
                      "hideFiled",
                      permiData.length + permissions.length + 1
                    );
                  return (
                    form && form.change("permissions." + ele.id, undefined)
                  );
                }
              }
            });
          });
      }
    });

    return setPermi(permiData);
  };

  const HandlePermissionChange = (
    e: any,
    form: any,
    el: any,
    role: any,
    module: any,
    subModule: any
  ) => {
    let permiData = [...permis];

    // if (e.target.checked) {
    permiData.forEach((data: any, index: any) => {
      if (module?.id === data?.id) {
        data.moduleData.forEach((element: any) => {
          element?.subModuleData?.forEach((subData: any) => {
            if (subData.id === subModule.id) {
              subData?.permissions?.forEach((per: any) => {
                if (per.id === el.id) {
                  per.checked = e.target.checked;
                }
              });
            }
          });
          // }
        });
      }
    });

    setPermi(permiData);

    updateFormValue(form, permiData, module, subModule);
  };

  const RoleInstances2 = () => {
    const form = useForm();
    return (
      <div className="MuiPaper-root MuiCard-root RaList-content-162 MuiPaper-elevation1 MuiPaper-rounded">
        {moduleRole?.map((module: any) => {
          const checkRole = module?.moduleData?.filter(
            (data: any) => data?.subModuleData?.length > 0
          );

          return (
            <>
              {checkRole?.length > 0 && (
                <div className="role-module-div">
                  {/* {checkRole?.length > 0 &&} */}
                  <span className="role-module-title">{module?.name}</span>

                  <div className="MuiPaper-root MuiCard-root RaList-content-162 MuiPaper-elevation1 MuiPaper-rounded">
                    <div className="roleList  card-table">
                      <div className="row-center table-responsive role-table">
                        <table style={{ width: "100%" }} className="dataTable">
                          <thead className="role-table-bg">
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  fontWeight: 600,
                                  paddingLeft: "13px",
                                }}
                                align="center"
                              >
                                Select All
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  fontWeight: 600,
                                  paddingLeft: "13px",
                                }}
                                align="left"
                              >
                                Page Title
                              </th>
                              <th
                                align="center"
                                style={{ textAlign: "center", fontWeight: 600 }}
                              >
                                View
                              </th>
                              <th
                                align="center"
                                style={{ textAlign: "center", fontWeight: 600 }}
                              >
                                Create{" "}
                              </th>
                              <th
                                align="center"
                                style={{ textAlign: "center", fontWeight: 600 }}
                              >
                                Update{" "}
                              </th>
                              <th
                                align="center"
                                style={{ textAlign: "center", fontWeight: 600 }}
                              >
                                Delete{" "}
                              </th>
                            </tr>
                          </thead>

                          {module &&
                            module?.moduleData.map((role: any, key: any) => {
                              return (
                                <>
                                  <div className="role-module-subtitle">
                                    {role?.subModuleData?.length > 0 &&
                                      role?.name}
                                  </div>
                                  {role?.subModuleData?.map((ele: any) => {
                                    return (
                                      <tr
                                        className="odd notification-list"
                                        style={{ marginLeft: 40 }}
                                      >
                                        <td align="center">
                                          <FormSpy>
                                            {(props) => {
                                              return (
                                                <SelectAllBtn
                                                  role={role}
                                                  form={props}
                                                  index={key}
                                                  ele={ele}
                                                  module={module}
                                                />
                                              );
                                            }}
                                          </FormSpy>
                                        </td>
                                        <td
                                          align="left"
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "13px",
                                          }}
                                        >
                                          {ele.name}
                                        </td>
                                        {ele.permissions.map(
                                          (el: any, index: any) => {
                                            return (
                                              <td align="center" key={index}>
                                                <div className="role-switch">
                                                  <Switch
                                                    checked={
                                                      el.checked
                                                        ? el.checked
                                                        : false
                                                    }
                                                    color="primary"
                                                    name={el.id}
                                                    inputProps={{
                                                      "aria-label":
                                                        "secondary checkbox",
                                                    }}
                                                    onChange={(e: any) =>
                                                      HandlePermissionChange(
                                                        e,
                                                        form,
                                                        el,
                                                        role,
                                                        module,
                                                        ele
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };
  const classes = useStyles();
  const user = helperFunc.getUserData();

  let updatedModuleName = user?.roleModuleName.map((ele: any) => {
    return { ...ele, moduleData: [] };
  });

  const temp = authProvider?.isHiddenField(props?.location?.pathname);

  const updatedUserModulesData = user?.modules?.filter(
    (val: any) => Object.keys(temp).indexOf(val.id) == -1
  );

  let mergedArray = updatedModuleName?.map(function (item: any) {
    updatedUserModulesData?.map(function (item2: any) {
      if (item.id == item2.moduleId) {
        item.moduleData.push({ ...item2 });
      }
    });

    return item;
  });

  const matchingItems = mergedArray?.map((item1: any) => {
    let updatedData = user?.subModules.map((subModule: any) => {
      let newData = item1.moduleData.filter(
        (item2: any) => subModule.id === item2.subModuleId
      );
      return { ...subModule, subModuleData: newData };
    });

    return { ...item1, moduleData: updatedData };
  });

  let permissionCheck: any = props.record && props.record.permissions;
  useEffect(() => {
    matchingItems.forEach((element: any) => {
      element.moduleData.forEach((modules: any) => {
        modules?.subModuleData?.forEach((subData: any) => {
          subData.checked =
            props.record &&
            props.record.permissions &&
            props.record.permissions[subData?.id] &&
            props.record.permissions[subData?.id]?.length === 4;
          subData?.permissions?.forEach((per: any) => {
            per.checked =
              props.record &&
              props.record.permissions &&
              props.record.permissions[subData?.id] &&
              props.record.permissions[subData?.id].indexOf(per.id) > -1;
          });
        });
      });
    });

    setModuleRole(matchingItems);
    updateFormValue(undefined, matchingItems, undefined, undefined);
  }, [permissionCheck]);

  return isSmall ? (
    <div
      style={{ width: window.screen.width, overflowX: "scroll", margin: -10 }}
    >
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 20,
        }}
      >
        {props && props.location && props.location.pathname !== "/roles/create"
          ? "Update Role"
          : "Add Role"}
      </h2>
      <SimpleForm toolbar={<CustomToolbar />} {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="roles"
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
          />
          <TextInput
            source="code"
            resource="roles"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="roles"
          defaultValue="true"
        />
        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hide}
        />
        {/* <FormTab label="label.roles_permission" path="roles">
          <RoleInstances />
        </FormTab> */}
      </SimpleForm>
    </div>
  ) : (
    <div className="form-content">
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <TextInput
          autoFocus
          resource="roles"
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
          label="Role Name"
        />
        <TextInput
          source="code"
          resource="roles"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={codeValidate}
          format={helperFunc.codeFormatter}
        />
        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hide}
        />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="roles"
          defaultValue="true"
        />

        <FormTab label="label.roles_permission" path="roles">
          <RoleInstances2 />
        </FormTab>

        {/* <FormTab label="label.roles_permission" path="roles">
      <RoleInstances />
    </FormTab> */}
      </SimpleForm>
    </div>
  );
};

const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

// const RoleTitle: FC<FieldProps<Role>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

// const requiredValidate = [required()];

export default CommonForm;
