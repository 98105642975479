import * as React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  BooleanInput,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { employeeTypeStyle } from "../helperStyle";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(employeeTypeStyle);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/employee-type/create"
          ? "Update Employee Type"
          : "Add Employee Type"}
      </h2>
      <SimpleForm {...props}>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
          />
          <TextInput
            source="code"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
      />
      <TextInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

// const SectionTitle = ({ label }: { label: string }) => {
//     const translate = useTranslate();

//     return (
//         <Typography variant="h6" gutterBottom>
//             {translate(label)}
//         </Typography>
//     );
// };

export default CommonForm;
