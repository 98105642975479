import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  zones?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
}

const ZoneSelectInput: FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider.getSearchList("zones/search", filter).then((response: any) => {
      setState((state) => ({
        ...state,
        zones: response && response.data,
      }));
    });
  }, [filter, dataProvider]);

  const { zones } = state;

  return (
    <SelectInput
      resettable
      label="Zone"
      className={classes.root}
      source="zoneId"
      // validate={requiredValidate}
      choices={zones}
    />
  );
};
const requiredValidate = [required()];
ZoneSelectInput.defaultProps = {
  source: "zoneId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ZoneSelectInput);
