import VisitorIcon from "@material-ui/icons/PersonOutline";
import visitorReportList from "./visitorReportList";
// import AttendanceEdit from "./AttendanceEdit";
// import VisitorCreate from "./VisitorCreate";

export default {
  list: visitorReportList,
  // create: VisitorCreate,
  // edit: AttendanceEdit,
  icon: VisitorIcon,
};
