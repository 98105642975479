import * as React from "react";
import { FC, useCallback, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  // Link,
  useMediaQuery,
  Theme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import helperFunc from "../../helperFuncs";

interface Props {
  value?: number;
  data?: any;
  actualData?: any;
  record?: any;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  tableCell: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
  },
  noRecordFound: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
    width: "100px",
  },
  filter: {
    width: "89%",
  },
  table: {
    borderCollapse: "inherit",
  },
  root: {
    flex: 1,
    // display: 'flex',
    width: "100%",
    marginRight: "1em",
    justifyContent: "center",
    alignItems: "center",
  },

  flexCol: {
    display: "flex",
    width: "100%",
  },

  mleft32: {
    marginLeft: 32,
  },

  container: {
    maxHeight: 550,
    maxWidth: "calc(100vw - 268px)",
  },

  header: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    position: "sticky",
    zIndex: 10,
    top: 0,
  },
  tableHead: {
    border: "solid 1px lightgrey",

    textAlign: "center",
    position: "sticky",
    top: "2.5rem",
    minWidth: "100px",
  },
  mobileRoot: {
    // flex: 1,
    width: window.screen.width - 28,
    // padding: 16,
    marginRight: "1em",
    overflow: "auto",
  },
  margin: {
    display: "flex",
    marginRight: "20px",
    marginTop: "7px",
  },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
}));

const TeamLeaveApplication: FC<Props> = ({ data = {}, isLoading }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const translate = useTranslate();
  const headerArr = [
    "Name",
    "Leave Type",
    "Description",
    "Leave Days",
    "Updated By",
    "From",
    "To",
    "Reason",
    "Approval Status",
    "Created At",
  ];
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any, index: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };

  let dataArr = Object.keys(data).map((k) => data[k]);

  return (
    <>
      {dataArr && dataArr.length > 0 ? (
        <Card
          className={isXSmall ? classes.mobileRoot : classes.root}
          // style={{ width: 100, overflow: "auto" }}
        >
          <CardHeader
            title={translate("pos.dashboard.team_leave_application")}
          />
          {isLoading ? (
            <span
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </span>
          ) : dataArr && dataArr.length > 0 ? (
            <Paper style={{ marginLeft: "15px" }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headerArr && headerArr.length > 0
                        ? headerArr.map((item: any) => {
                            return (
                              <TableCell className={classes.theadFCol}>
                                {item}
                              </TableCell>
                            );
                          })
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* ["Name","Leave Type","Subject","Reason", "Type", "From Date", "To Date", "Approval Status","Created At"]; */}
                    {dataArr && dataArr.length > 0 ? (
                      dataArr
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data: any, index: number) => (
                          <TableRow>
                            <TableCell>
                              {data && data.employee
                                ? data.employee.name
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {data && data.leaveType && data.leaveType.code
                                ? data.leaveType.code
                                : "N/A"}
                            </TableCell>
                            <TableCell style={{ overflowWrap: "anywhere" }}>
                              {data && data.description
                                ? data.description
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {data && data.leaveDays ? data.leaveDays : "N/A"}
                            </TableCell>
                            <TableCell>
                              {data && data.updatedBy && data.updatedBy.name
                                ? data.updatedBy.name
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {data && data.fromDate
                                ? helperFunc.formatDate(data.fromDate)
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {data && data.toDate
                                ? helperFunc.formatDate(data.toDate)
                                : "N/A"}
                            </TableCell>
                            <TableCell style={{ overflowWrap: "anywhere" }}>
                              {data && data.reason ? data.reason : "N/A"}
                            </TableCell>

                            <TableCell>
                              {data && data.isApprove
                                ? approvalStatusLeave[data.isApprove]
                                : "N/A"}
                            </TableCell>

                            <TableCell>
                              {data && data.createdAt
                                ? helperFunc.commonDateFormat(
                                    data.createdAt,
                                    "HH:mm:ss"
                                  )
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          style={{
                            textAlign: "center",
                            // marginRight: isXSmall ? undefined : "350px",
                          }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataArr && dataArr.length > 0 ? (
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={(dataArr && dataArr.length) || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ) : null}
            </Paper>
          ) : (
            <h4
              style={{
                width: "100%",
                height: "200px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              No Leave Application Found
            </h4>
          )}
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default TeamLeaveApplication;
