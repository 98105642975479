// import VisitorIcon from "@material-ui/icons/PersonOutline";
// import AttendanceList from "./AttendanceList";
import AttendanceReportTable from "./AttendanceReportTable";

// import AttendanceEdit from "./AttendanceEdit";
// import VisitorCreate from "./VisitorCreate";

export default {
  list: AttendanceReportTable,
  // create: VisitorCreate,
  // edit: AttendanceEdit,
  // icon: VisitorIcon,
};
