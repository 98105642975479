import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  maxLength,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  number,
  AutocompleteInput,
  usePermissions,
  useDataProvider,
  FileField,
  FileInput,
  // Button as ReactButton,
} from "react-admin";

import { useRedirect } from "ra-core";

import {
  Typography,
  Table,
  TableBody,
  useMediaQuery,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Button as MaterialButton,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { maxValue, minValue } from "../TurnoutAdmin";
import CustomActionToolbar from "./CustomActionToolbar";
import { Alert } from "@material-ui/lab";
import helperFunc from "../helperFuncs";
import { styleData } from "../helperConst";
import { devicesStyles } from "../helperStyle";
import {
  allDevices,
  turnoutE,
  turnoutR,
  turnoutA,
  deviceLocation,
  turnoutC,
} from "../helperChoice";
import MultiDeviceSelect from "./MultiDeviceDialogue";
import WorkplaceIdSelectInput from "../components/WorkplaceIdSelectInput";
import CameraVenueSelectInput from "../components/CameraVenueSelectInput";
import ClassroomSelect from "../components/ClassroomSelect";
import DialogueBox from "../components/ConfirmDialogueBox";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { PostToolbar } from "./PostToolbar";
import { toastInfo, toastError } from "../components/toast";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const CustomAlert = (props: any) => (
  <div className="mb-2">
    <Alert severity="error">
      Model is not up to date. please update model.{" "}
      <strong>check it out!</strong>
    </Alert>
  </div>
);

const useStyles = makeStyles(devicesStyles);
const DeviceEdit = (props: any) => {
  const [count, setCount] = useState(0);
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [companyId, setCompanyId] = useState("");
  const [flag, setFlag] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [allFormData, setAllFormData] = useState({});

  let [listCompanyData, setListCompanyData] = useState([]) as any;
  const [allotments, setAllotments] = useState(Array);
  const [fileData, setFileData] = React.useState();
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  var turnoutDeviceValue: any = [];
  const user = helperFunc.getUserData();
  const turnoutDeviceData: any = helperFunc.getWhiteLabeling();
  const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;
  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const handleAdd = () => {
    setFlag("add");
    setAllotments([]);

    setDialogueOpen(true);
  };

  const handleOpenClick = (index: any, allotment: any) => {
    setIndex(index);
    setShowDialog(true);
  };

  const handleEdit = (index: number) => {
    setFlag("edit");
    setIndex(index);

    setCompanyId(listCompanyData[index].companyId);
    setCompanyName(listCompanyData[index].companyName);
    setAllotments(listCompanyData[index].cameraDetails);

    setDialogueOpen(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const removeAllotment = (idx: any) => {
    setListCompanyData(
      listCompanyData.filter((s: any, sIdx: any) => index !== sIdx)
    );
  };

  useEffect(() => {
    if (
      props.location &&
      props.location.pathname !== `/${props.resource}/create`
    ) {
      const resourcePath = `${props.resource}/get/${props.id}`;
      dataProvider
        .callCustom(resourcePath, {})
        .then((response: any) => {
          setListCompanyData(response?.data?.item?.assignCompanies);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const saveForm = (formdata: any) => {
    const resourceName = translate(
      `resources.${props.resource}.name`,
      props.resource
    );
    const resourcePath =
      props.location && props.location.pathname === `/${props.resource}/create`
        ? `${props.resource}/create`
        : `${props.resource}/update/${props.id}`;

    const updatedFileDataUrl = fileData
      ? `${uploadApiUrl}${fileData}`
      : props?.record?.binURL;

    dataProvider
      .callCustom(resourcePath, {
        ...formdata,
        binURL: updatedFileDataUrl,
        assignCompanies: listCompanyData,
      })
      .then((response: any) => {
        props.location &&
        props.location.pathname === `/${props.resource}/create`
          ? toastInfo(`${resourceName} created Succesfully`)
          : toastInfo(`${resourceName} updated Succesfully`);
        redirect("list", props.basePath);
        setListCompanyData([]);
      });

    formdata.hideFiled = true;
  };

  if (turnoutDeviceData && turnoutDeviceData.turnoutDevice === "all") {
    turnoutDeviceValue = allDevices;
  } else if (
    turnoutDeviceData &&
    turnoutDeviceData.turnoutDevice === "turnoutE"
  ) {
    turnoutDeviceValue = turnoutE;
  } else if (
    turnoutDeviceData &&
    turnoutDeviceData.turnoutDevice === "turnoutR"
  ) {
    turnoutDeviceValue = turnoutR;
  } else if (
    turnoutDeviceData &&
    turnoutDeviceData.turnoutDevice === "turnoutC"
  ) {
    turnoutDeviceValue = turnoutC;
  } else if (
    turnoutDeviceData &&
    turnoutDeviceData.turnoutDevice === "turnoutA"
  ) {
    turnoutDeviceValue = turnoutA;
  }

  const handleClick = (e: any, company: any) => {
    let imgData = new FormData();
    imgData?.append("file", e, e?.name);

    dataProvider
      .callCustom(`devices/upload/${company}`, imgData)
      .then((response: any) => {
        setFileData(response?.data?.item?.file);
      })
      .catch((err: any) => console.log(err));
  };

  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/devices/create"
          ? "Update Device"
          : "Add Device"}
      </h2>
      <SimpleForm
        // save={saveForm}
        toolbar={<PostToolbar />}
        className={classes}
        {...props}
      >
        {/* <SectionTitle label="resources.devices.page.edit" /> */}
        {props && props.record && props.record.isUpToDate === false ? (
          <CustomAlert />
        ) : null}
        {props && Object.keys(props.record).length !== 0 ? (
          <CustomActionToolbar record={props.record}></CustomActionToolbar>
        ) : null}
        <div style={{ display: "inline" }}>
          <CompanyReferenceInput></CompanyReferenceInput>
          {user.type === "super" ? (
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) =>
                formData.turnoutDevice === "all" ? (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    formClassName={classes.name}
                    resource="devices"
                    choices={allDevices}
                  />
                ) : formData.turnoutDevice === "turnoutE" ? (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    formClassName={classes.name}
                    resource="devices"
                    choices={turnoutE}
                  />
                ) : formData.turnoutDevice === "turnoutR" ? (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    resource="devices"
                    formClassName={classes.name}
                    choices={turnoutR}
                  />
                ) : formData.turnoutDevice === "turnoutA" ? (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    resource="devices"
                    formClassName={classes.name}
                    choices={turnoutR}
                  />
                ) : formData.turnoutDevice === "turnoutC" ? (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    resource="devices"
                    formClassName={classes.name}
                    choices={turnoutC}
                  />
                ) : (
                  <AutocompleteInput
                    validate={requiredValidate}
                    label="label.deviceType"
                    source="deviceType"
                    resource="devices"
                    formClassName={classes.name}
                    // choices={[{}]}
                    // choices={turnoutDeviceValue}
                  />
                )
              }
            </FormDataConsumer>
          ) : (
            <AutocompleteInput
              validate={requiredValidate}
              label="label.deviceType"
              source="deviceType"
              formClassName={classes.name}
              choices={turnoutDeviceValue}
              resource="devices"
            />
          )}

          <TextInput
            source="mac_ipAddress"
            label="resources.devices.fields.mac_ipAddress"
            formClassName={classes.textInput}
            validate={commonValidate}
            resource="devices"
          />
          <TextInput
            source="camera_ID"
            formClassName={classes.textInputMargin}
            resource="devices"
            validate={commonValidate}
            label="label.device_id"
          />

          <AutocompleteInput
            validate={locationValidate}
            label="resources.devices.fields.device_location"
            source="device_location"
            formClassName={classes.textInputMargin}
            choices={deviceLocation}
          />
          <SectionTitle label="" />
          <TextInput
            // fullWidth
            source="edgeURL"
            formClassName={classes.textInput}
            resource="devices"
            validate={commonValidate}
            label="label.url"
          />
          <TextInput
            label="label.tokenExpire"
            source="tokenExpireTime"
            formClassName={classes.textInputMargin}
            validate={tokenExpireValidation}
          />

          <SectionTitle label="" />
          <FormDataConsumer formClassName={classes.textInput}>
            {({ formData }: { formData: any }) =>
              formData.deviceType !== "turnoutR" ? (
                <NumberInput
                  source="registration_frame"
                  formClassName={classes.textInput}
                  resource="devices"
                  validate={[commonValidate, maxValue(30), minValue(10)]}
                  label="resources.devices.fields.registration_frame"
                />
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.textInputMargin}>
            {({ formData }: { formData: any }) =>
              formData.deviceType !== "turnoutR" ? (
                <NumberInput
                  source="false_cnt"
                  formClassName={classes.textInputMargin}
                  resource="devices"
                  validate={[commonValidate, minValue(1)]}
                  label="resources.devices.fields.false_cnt"
                />
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.textInputMargin}>
            {({ formData }: { formData: any }) =>
              formData.deviceType !== "turnoutR" ? (
                <NumberInput
                  source="response_time"
                  formClassName={classes.textInputMargin}
                  resource="devices"
                  validate={commonValidate}
                  label="resources.devices.fields.response_time"
                />
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />
          <TextInput
            label="label.latitude"
            source="lat"
            formClassName={classes.textInput}
            validate={latitudeValidation}
          />
          <TextInput
            label="label.longitude"
            source="lng"
            formClassName={classes.textInputMargin}
            validate={longitudeValidation}
          />
          <FormDataConsumer formClassName={classes.textInputMargin}>
            {({ formData }: { formData: any }) => (
              <WorkplaceIdSelectInput
                filter={{ company: formData.company, isActive: true }}
              ></WorkplaceIdSelectInput>
            )}
          </FormDataConsumer>

          {user.isCollege || permissions === "super" ? (
            <FormDataConsumer formClassName={classes.textInput}>
              {({ formData }: { formData: any }) => {
                return <CameraVenueSelectInput></CameraVenueSelectInput>;
              }}
            </FormDataConsumer>
          ) : null}
          <SectionTitle label="" />
          <div className={classes.flexDiv}>
            {user.isCollege || permissions === "super" ? (
              <div className={classes.textInput}>
                <FormDataConsumer formClassName={classes.textInput}>
                  {({ formData }: { formData: any }) => (
                    <BooleanInput
                      // formClassName={classes.textInput}
                      source="IsClass"
                      label="IsClass"
                      helperText={false}
                    />
                  )}
                </FormDataConsumer>
              </div>
            ) : null}

            {user.isCollege || permissions === "super" ? (
              <div style={isSmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => {
                    if (formData.IsClass) {
                      return (
                        <ClassroomSelect
                          validate={[required()]}
                          formClassName={classes.textInputMargin}
                          helperText={false}
                        ></ClassroomSelect>
                      );
                    }
                  }}
                </FormDataConsumer>
              </div>
            ) : null}
          </div>
          <SectionTitle label="" />
          <FormDataConsumer formClassName={classes.textInput}>
            {({ formData }: { formData: any }) =>
              formData.deviceType !== "turnoutR" ? (
                <BooleanInput
                  formClassName={classes.textInput}
                  source="isIpCamara"
                  resource="devices"
                  label="label.isIpCamera"
                />
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.cameraUrl}>
            {({ formData, className }: { formData: any; className: any }) =>
              formData.isIpCamara && formData.deviceType !== "turnoutR" ? (
                <TextInput
                  source="camera_Url"
                  formClassName={classes.cameraUrl}
                  resource="devices"
                  validate={cameraLinkValidate}
                  label="resources.devices.fields.camera_Url"
                />
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />
          <BooleanInput
            formClassName={classes.textInput}
            source="isMaster"
            label="label.isMaster"
            disabled={
              props && props.record && props.record.isMaster === false
                ? false
                : props &&
                  props.record &&
                  props.record.isUpToDate &&
                  props.record.isUploaded
                ? true
                : false
            }
          />
          <BooleanInput
            formClassName={classes.textInput}
            source="isActive"
            label="label.status"
            defaultValue={true}
          />
        </div>
      </SimpleForm>
    </>
  ) : (
    <SimpleForm
      {...props}
      save={saveForm}
      toolbar={<PostToolbar />}
      className={classes}
    >
      {/* <SectionTitle label="resources.devices.page.edit" /> */}
      {props && props.record && props.record.isUpToDate === false ? (
        <CustomAlert />
      ) : null}
      {props && Object.keys(props.record).length !== 0 ? (
        <CustomActionToolbar record={props.record}></CustomActionToolbar>
      ) : null}
      <CompanyReferenceInput></CompanyReferenceInput>
      <div className={classes.flexDiv}>
        {user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData.turnoutDevice === "all" ? (
                <AutocompleteInput
                  validate={requiredValidate}
                  label="label.deviceType"
                  source="deviceType"
                  formClassName={classes.name}
                  resource="devices"
                  choices={allDevices}
                />
              ) : formData.turnoutDevice === "turnoutE" ? (
                <AutocompleteInput
                  validate={requiredValidate}
                  label="label.deviceType"
                  source="deviceType"
                  formClassName={classes.name}
                  resource="devices"
                  choices={turnoutE}
                />
              ) : formData.turnoutDevice === "turnoutR" ? (
                <AutocompleteInput
                  validate={requiredValidate}
                  label="label.deviceType"
                  source="deviceType"
                  resource="devices"
                  formClassName={classes.name}
                  choices={turnoutR}
                />
              ) : (
                <AutocompleteInput
                  validate={requiredValidate}
                  label="label.deviceType"
                  source="deviceType"
                  resource="devices"
                  formClassName={classes.name}
                />
              )
            }
          </FormDataConsumer>
        ) : (
          <AutocompleteInput
            validate={requiredValidate}
            label="label.deviceType"
            source="deviceType"
            formClassName={classes.name}
            choices={turnoutDeviceValue}
            resource="devices"
          />
        )}
        {user.isCollege || permissions === "super" ? (
          <div style={{ marginLeft: "32px" }}>
            <FormDataConsumer
              formClassName={classes.textInputMargin}
              style={{ marginLeft: "32px" }}
            >
              {({ formData }: { formData: any }) => {
                return (
                  <CameraVenueSelectInput
                    // validate={[required]}

                    helperText={false}
                  ></CameraVenueSelectInput>
                );
              }}
            </FormDataConsumer>
          </div>
        ) : null}
      </div>
      {user.type === "super" ? (
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            return (
              formData.deviceType === "turnoutK" && (
                <TextInput
                  source="mac_ipAddress"
                  label="resources.devices.fields.mac_ipAddress"
                  formClassName={classes.textInput}
                  validate={commonValidate}
                  resource="devices"
                />
              )
            );
          }}
        </FormDataConsumer>
      ) : null}
      {/* <div style={{ display: "flex" }}> */}
      <SectionTitle label="" />
      <TextInput
        source="mac_ipAddress"
        label="resources.devices.fields.mac_ipAddress"
        formClassName={classes.textInput}
        validate={commonValidate}
        resource="devices"
      />
      <TextInput
        source="camera_ID"
        formClassName={classes.textInputMargin}
        resource="devices"
        validate={commonValidate}
        label="label.device_id"
      />
      <AutocompleteInput
        validate={locationValidate}
        label="resources.devices.fields.device_location"
        source="device_location"
        formClassName={classes.textInputMargin}
        choices={deviceLocation}
      />
      <SectionTitle label="" />
      <TextInput
        fullWidth
        source="edgeURL"
        formClassName={classes.edgeUrl}
        resource="devices"
        validate={commonValidate}
        label="label.url"
      />
      <TextInput
        label="label.tokenExpire"
        source="tokenExpireTime"
        formClassName={classes.textInputMargin}
        validate={tokenExpireValidation}
      />
      <SectionTitle label="" />
      <FormDataConsumer formClassName={classes.textInput}>
        {({ formData }: { formData: any }) =>
          formData.deviceType !== "turnoutR" ? (
            <NumberInput
              source="registration_frame"
              formClassName={classes.textInput}
              resource="devices"
              validate={[commonValidate, maxValue(30), minValue(10)]}
              label="resources.devices.fields.registration_frame"
            />
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.textInputMargin}>
        {({ formData }: { formData: any }) =>
          formData.deviceType !== "turnoutR" ? (
            <NumberInput
              source="false_cnt"
              formClassName={classes.textInputMargin}
              resource="devices"
              validate={[commonValidate, minValue(1)]}
              label="resources.devices.fields.false_cnt"
            />
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.textInputMargin}>
        {({ formData }: { formData: any }) =>
          formData.deviceType !== "turnoutR" ? (
            <NumberInput
              source="response_time"
              formClassName={classes.textInputMargin}
              resource="devices"
              validate={commonValidate}
              label="resources.devices.fields.response_time"
            />
          ) : null
        }
      </FormDataConsumer>
      <SectionTitle label="" />
      <TextInput
        label="label.latitude"
        source="lat"
        formClassName={classes.textInput}
        validate={latitudeValidation}
      />
      <TextInput
        label="label.longitude"
        source="lng"
        formClassName={classes.textInputMargin}
        validate={longitudeValidation}
      />
      <FormDataConsumer formClassName={classes.textInputMargin}>
        {({ formData }: { formData: any }) => (
          <WorkplaceIdSelectInput
            filter={{ company: formData.company, isActive: true }}
          ></WorkplaceIdSelectInput>
        )}
      </FormDataConsumer>
      <SectionTitle label="" />
      <div className={classes.flexDiv}>
        {user.isCollege || permissions === "super" ? (
          <div className={classes.textInput}>
            <FormDataConsumer formClassName={classes.textInput}>
              {({ formData }: { formData: any }) => (
                <BooleanInput
                  formClassName={classes.textInput}
                  source="isClass"
                  label="Is Class"
                />
              )}
            </FormDataConsumer>
          </div>
        ) : null}
        {user.isCollege || permissions === "super" ? (
          <div style={isSmall ? undefined : styleData}>
            <FormDataConsumer formClassName={classes.textInputMargin}>
              {({ formData }: { formData: any }) => {
                if (formData.isClass) {
                  return (
                    <ClassroomSelect
                      validate={[required()]}
                      helperText={false}
                    ></ClassroomSelect>
                  );
                }
              }}
            </FormDataConsumer>
          </div>
        ) : null}
      </div>
      <SectionTitle label="" />
      <div className={classes.flexDiv}>
        <div className={classes.textInput}>
          <FormDataConsumer formClassName={classes.textInput}>
            {({ formData }: { formData: any }) =>
              formData.deviceType !== "turnoutR" ? (
                <BooleanInput
                  formClassName={classes.textInput}
                  source="isIpCamara"
                  label="label.isIpCamera"
                />
              ) : null
            }
          </FormDataConsumer>
        </div>
        <div style={isSmall ? undefined : styleData}>
          <FormDataConsumer formClassName={classes.cameraUrl}>
            {({ formData, className }: { formData: any; className: any }) =>
              formData.isIpCamara && formData.deviceType !== "turnoutR" ? (
                <TextInput
                  source="camera_Url"
                  formClassName={classes.cameraUrl}
                  resource="devices"
                  validate={cameraLinkValidate}
                  label="resources.devices.fields.camera_Url"
                  helperText={false}
                />
              ) : null
            }
          </FormDataConsumer>
        </div>
      </div>
      <SectionTitle label="" />
      <BooleanInput
        formClassName={classes.textInput}
        source="isMaster"
        label="label.isMaster"
        disabled={
          props && props.record && props.record.isMaster === false
            ? false
            : props &&
              props.record &&
              props.record.isUpToDate &&
              props.record.isUploaded
            ? true
            : false
        }
      />
      <SectionTitle label="" />
      <BooleanInput
        formClassName={classes.textInput}
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
      <SectionTitle label="Device Configuration" />
      <FormDataConsumer>
        {({ formData }: { formData: any }) => {
          return (
            <FileInput
              multiple={false}
              source="binURL"
              label=".bin file"
              accept=".bin"
              placeholder={
                <p>
                  Drop a file to <span style={{ color: "blue" }}>upload </span>,
                  or <span style={{ color: "blue" }}>click </span>to select it.
                </p>
              }
              onChange={(e: any) => handleClick(e, formData?.company)}
              classes={{ dropZone: classes.dropZone, p: classes.pTag }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.disFlexjusEnd}>
        {({ formData, className }: { formData: any; className: any }) => (
          <Button
            id="multiAddCompany"
            variant="outlined"
            size="small"
            color="primary"
            type="button"
            onClick={handleAdd}
            className={isSmall ? undefined : classes.allotmentBtn}
          >
            Add Company
          </Button>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <>
            <TableContainer
              className={isXSmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Company</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listCompanyData?.length > 0
                    ? listCompanyData?.map((allotment: any, index: number) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.companyName &&
                              allotment.companyName !== ""
                                ? allotment.companyName
                                : ""}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <MaterialButton
                                onClick={() => handleEdit(index)}
                                variant="outlined"
                                className={classes.editAllotmentBtn}
                                type="button"
                              >
                                Edit
                              </MaterialButton>
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}

                  {listCompanyData?.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={5}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>

            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(index)}
            />
            <MultiDeviceSelect
              open={dialogueOpen}
              setOpen={setDialogueOpen}
              count={count}
              setCount={setCount}
              listCompanyData={listCompanyData}
              setListCompanyData={setListCompanyData}
              allotments={allotments}
              setAllotments={setAllotments}
              companyId={companyId}
              setCompanyId={setCompanyId}
              companyName={companyName}
              setCompanyName={setCompanyName}
              flag={flag}
              index={index}
            />
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

// const DeviceTitle: FC<FieldProps<Device>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;
const requiredValidate = [required()];
const cameraLinkValidate = [required(), maxLength(150)];
// const apiKeyValidate = [required(), maxLength(50)];
const commonValidate = [required(), maxLength(50)];
const locationValidate = [required(), maxLength(3)];
const tokenExpireValidation = [requiredValidate, number()];
const latitudeValidation = [
  requiredValidate,
  number(),
  maxValue(90),
  minValue(-90),
];
const longitudeValidation = [
  requiredValidate,
  number(),
  maxValue(180),
  minValue(-180),
];

export default DeviceEdit;
