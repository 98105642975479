import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, Department } from "../types";

const ClientAdminLinkField: FC<FieldProps<Department>> = (props) =>
  props.record ? (
    <Link to={`/clientAdmins/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

ClientAdminLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default ClientAdminLinkField;
