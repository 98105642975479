import * as React from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CountrySelectInput from "../components/CountrySelectinput";
import RegionSelectInput from "../components/RegionSelectInput";
import { stateStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(stateStyle);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props && props.location && props.location.pathname !== "/states/create"
          ? "Update State"
          : "Add State"}
      </h2>
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer formClassName={isSmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <CountrySelectInput
              filter={{ isActive: true }}
            ></CountrySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <RegionSelectInput filter={{ isActive: true }}></RegionSelectInput>
          )}
        </FormDataConsumer>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="State Name"
          />
          <TextInput
            source="code"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </React.Fragment>
  ) : (
    <SimpleForm {...props}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <FormDataConsumer formClassName={isSmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <CountrySelectInput filter={{ isActive: true }}></CountrySelectInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.code}>
        {({ formData }: { formData: any }) => (
          <RegionSelectInput filter={{ isActive: true }}></RegionSelectInput>
        )}
      </FormDataConsumer>
      <SectionTitle label="" />
      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="State Name"
      />
      <TextInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default CommonForm;
