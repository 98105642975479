import * as React from "react";
import { FC, useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  makeStyles,
  CircularProgress,
  Box,
} from "@material-ui/core";
// import { useTheme } from "@material-ui/core/styles";
import { useTranslate, Link } from "react-admin";
import DialogueBox from "../DialogueBox";
import Request from "../icons/request.png";
import { requestCardStyle } from "../../helperStyle";
import { useMediaQuery, Theme } from "@material-ui/core";
import authProvider from "../../authProvider";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
  isloading?: boolean;
  isTwoCard?: boolean;
}
const useStyles = makeStyles(requestCardStyle);

const RequestCard: FC<Props> = (data: any) => {
  const [state, setState] = React.useState({
    holidays: 0,
    offdays: 0,
    requests: 0,
    teamRequest: 0,
  });
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");

  let newSortData = data?.data2?.requests?.sort(
    (a: any, b: any) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  newSortData = newSortData?.length > 0 && newSortData.reverse();

  const dataObj: any = { ...data.data2, requests: newSortData } || {};
  const translate = useTranslate();
  const classes = useStyles();
  const isloading = data.isLoading;

  const headers = () => {
    if (activeSlice === "Today Leave Requests") {
      return [
        { label: "Subject", key: "subject" },
        { label: "Created At", key: "CreatedAt" },
        { label: "Approval Status", key: "ApprovalStatus" },
      ];
    } else {
      return [
        // { label: "Name", key: "employee.name" },
        { label: "Request Type", key: "RequestType" },
        { label: "Approval Status", key: "ApprovalStatus" },
        { label: "Subject", key: "reason" },
        { label: "CreatedAt", key: "CreatedAt" },
      ];
    }
  };

  const object: any = {
    "My Requests": "requests",
    "Team Requests": "teamRequest",
  };
  let dataBySlice = dataObj[object[activeSlice]];

  React.useEffect(() => {
    setState(data.data);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = useCallback((data: any) => {
    // setActiveIndex(index);
    setOpen(true);
    setActiveSlice(data);
    setPage(0);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <Card className={isXSmall ? classes.mobileRoot : classes.root}>
      <CardHeader title={translate("pos.dashboard.request")} />
      {isloading ? (
        <span
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <>
          <Box
            className={
              isXSmall
                ? classes.cardimgSmall
                : data.isTwoCard
                ? classes.cardImgMed
                : classes.cardImgReq
            }
          >
            <img
              src={Request}
              title={translate("pos.dashboard.general_detail")}
            ></img>
          </Box>

          <DialogueBox
            dialogueData={dataBySlice}
            open={open}
            handleClose={handleClose}
            activeSlice={activeSlice}
            headerArr={headers()}
            page={page}
            handleDefaultPage={handleDefaultPage}
            rowsPerPage={rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            exportData={[]}
            isExport={false}
          />

          <CardContent className={classes.card}>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to="/request"
                onClick={() => handleClickOpen("My Requests")}
              >
                My Request({state && state.requests ? state.requests : 0})
              </Link>
            </Typography>
            {authProvider.isMenuShow("teamrequests") && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                // style={{ fontSize: "14px" }}
              >
                <Link
                  // to="/request"
                  onClick={() => handleClickOpen("Team Requests")}
                >
                  Team Request(
                  {state && state.teamRequest ? state.teamRequest : 0})
                </Link>
              </Typography>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default RequestCard;
