import React from "react";
import { Create } from "react-admin";
import CommonForm from '../countries/commonForm';


const CountryCreate = (props: any) => {
  return (
    <Create {...props} >
      <CommonForm redirect={'/countries'}  {...props} />
    </Create>
  );
};


export default CountryCreate;
