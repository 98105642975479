import React from "react";

export const TermsAndCondition = () => {
  return (
    <div className="public-terms-condition">
      <p>
        TERMS AND CONDITIONS <br />
        Serco and its affiliates provide their services to you subject to the
        following conditions. If you visit or shop at Serco, you accept these
        conditions. Please read them carefully. In addition, when you use any
        current or future Serco service or visit or purchase from any business
        affiliated with Serco, whether or not included in the Serco Web site,
        you also will be subject to the guidelines and conditions applicable to
        such service or business.
      </p>

      <p>ELECTRONIC COMMUNICATIONS</p>

      <p>
        When you visit Serco or send e-mails to us, you are communicating with
        us electronically. You consent to receive communications from us
        electronically. We will communicate with you by e-mail or by posting
        notices on this site. You agree that all agreements, notices,
        disclosures and other communications that we provide to you
        electronically satisfy any legal requirement that such communications be
        in writing.
      </p>

      <p>COPYRIGHT</p>

      <p>
        All content included on this site, such as text, graphics, logos, button
        icons, images, audio clips, digital downloads, data compilations, and
        software, is the property of Serco or its content suppliers and
        protected by international copyright laws. The compilation of all
        content on this site is the exclusive property of Serco and protected by
        international copyright laws. All software used on this site is the
        property of Serco or its software suppliers and protected by
        international copyright laws.
      </p>

      <p>LICENSE AND SITE ACCESS</p>

      <p>
        Serco grants you a limited license to access and make personal use of
        this site and not to download (other than page caching) or modify it, or
        any portion of it, except with express written consent of Serco. This
        license does not include any resale or commercial use of this site or
        its contents; any collection and use of any product listings,
        descriptions, or prices; any derivative use of this site or its
        contents; any downloading or copying of account information for the
        benefit of another merchant; or any use of data mining, robots, or
        similar data gathering and extraction tools. This site or any portion of
        this site may not be reproduced, duplicated, copied, sold, resold,
        visited, or otherwise exploited for any commercial purpose without
        express written consent of Serco. You may not frame or utilise framing
        techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of Serco and
        our affiliates without express written consent. You may not use any meta
        tags or any other &quot;hidden text&quot; utilizing Serco's name or
        trademarks without the express written consent of Serco. Any
        unauthorised use terminates the permission or license granted by Serco.
        You are granted a limited, revocable, and nonexclusive right to create a
        hyperlink to the home page of Serco so long as the link does not portray
        Serco, its affiliates, or their products or services in a false,
        misleading, derogatory, or otherwise offensive matter. You may not use
        any Serco logo or other proprietary graphic or trademark as part of the
        link without express written permission.
      </p>

      <p>YOUR ACCOUNT</p>

      <p>
        If you use this site, you are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer, and you agree to accept responsibility for all
        activities that occur under your account or password. Serco does sell
        products for children, but it sells them to adults, who can purchase
        with a credit card. If you are under 18, you may use Serco only with
        involvement of a parent or guardian. Serco and its affiliates reserve
        the right to refuse service, terminate accounts, remove or edit content,
        or cancel orders in their sole discretion.
      </p>

      <p>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</p>

      <p>
        Visitors may post reviews, comments, and other content; and submit
        suggestions, ideas, comments, questions, or other information, so long
        as the content is not illegal, obscene, threatening, defamatory,
        invasive of privacy, infringing of intellectual property rights, or
        otherwise injurious to third parties or objectionable and does not
        consist of or contain software viruses, political campaigning,
        commercial solicitation. You may not use a false e-mail address,
        impersonate any person or entity, or otherwise mislead as to the origin
        of content. Serco reserves the right (but not the obligation) to remove
        or edit such content, but does not regularly review posted content. If
        you do post content or submit material, and unless we indicate
        otherwise, you grant Serco and its affiliates a nonexclusive,
        royalty-free, perpetual, irrevocable, and fully sublicensable right to
        use, reproduce, modify, adapt, publish, translate, create derivative
        works from, distribute, and display such content throughout the world in
        any media. You grant Serco and its affiliates and sublicensees the right
        to use the name that you submit in connection with such content, if they
        choose. You represent and warrant that you own or otherwise control all
        of the rights to the content that you post; that the content is
        accurate; that use of the content you supply does not violate this
        policy and will not cause injury to any person or entity; and that you
        will indemnify Serco or its affiliates for all claims resulting from
        content you supply. Serco has the right but not the obligation to
        monitor and edit or remove any activity or content. Serco takes no
        responsibility and assumes no liability for any content posted by you or
        any third party.
      </p>

      <p>COPYRIGHT COMPLAINTS</p>

      <p>
        Serco and its affiliates respect the intellectual property of others. If
        you believe that your work has been copied in a way that constitutes
        copyright infringement, email us with your complaint.
      </p>

      <p>RISK OF LOSS</p>

      <p>
        All items purchased from Serco are made pursuant to a shipment contract.
        This means that the risk of loss and title for such items pass to you
        upon our delivery to the carrier.
      </p>

      <p>PRODUCT DESCRIPTIONS</p>

      <p>
        Serco and its affiliates attempt to be as accurate as possible. However,
        Serco does not warrant that product descriptions or other content of
        this site is accurate, complete, reliable, current, or error-free. If a
        product offered by Serco itself is not as described, your sole remedy is
        to return it in unused condition.
      </p>

      <p>OTHER BUSINESSES</p>

      <p>
        Parties other than Serco and its subsidiaries sell product lines on this
        site. In addition, we provide may links to the sites of affiliated
        companies and certain other businesses. We are not responsible for
        examining or evaluating, and we do not warrant the offerings of, any of
        these businesses or individuals or the content of their web sites. Serco
        does not assume any responsibility or liability for the actions,
        product, and content of all these and any other third parties. You
        should carefully review their privacy statements and other conditions of
        use.
      </p>

      <p>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</p>

      <p>
        THIS SITE IS PROVIDED BY SERCO ON AN &quot;AS IS&quot; AND &quot;AS
        AVAILABLE&quot; BASIS. SERCO MAKES NO REPRESENTATIONS OR WARRANTIES OF
        ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE
        INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU
        EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE
        FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, SERCO DISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        SERCO DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM
        SERCO ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. SERCO WILL NOT BE
        LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
        INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
        AND CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS
        ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
        IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
        EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </p>

      <p>APPLICABLE LAW</p>

      <p>
        By visiting Serco, you agree that the laws of Australia, without regard
        to principles of conflict of laws, will govern these Conditions of Use
        and any dispute of any sort that might arise between you and Serco or
        its affiliates.
      </p>

      <p>DISPUTES</p>

      <p>
        Any dispute relating in any way to your visit to Serco or to products
        you purchase through Serco shall be submitted to confidential
        arbitration in Australia, except that, to the extent you have in any
        manner violated or threatened to violate Serco's intellectual property
        rights, Serco may seek injunctive or other appropriate relief in any
        court in Australia, and you consent to exclusive jurisdiction and venue
        in such courts. Arbitration under this agreement shall be conducted
        under the rules then prevailing of an independent arbitrator. The
        arbitrator's award shall be binding and may be entered as a judgment in
        any court of competent jurisdiction. To the fullest extent permitted by
        applicable law, no arbitration under this Agreement shall be joined to
        an arbitration involving any other party subject to this Agreement,
        whether through class arbitration proceedings or otherwise.
      </p>

      <p>SITE POLICIES, MODIFICATION, AND SEVERABILITY</p>

      <p>
        Please review our other policies, posted on this site. These policies
        also govern your visit to Serco. We reserve the right to make changes to
        our site, policies, and these Conditions of Use at any time. If any of
        these conditions shall be deemed invalid, void, or for any reason
        unenforceable, that condition shall be deemed severable and shall not
        affect the validity and enforceability of any remaining condition.
      </p>
    </div>
  );
};
