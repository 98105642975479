import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  useMediaQuery,
  Theme,
  Typography,
} from "@material-ui/core";
import { useDataProvider, FormDataConsumer, DateInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
import { Form } from "react-final-form";

import helperFunc from "../helperFuncs";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { assignTimeslotShowStyle } from "../helperStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toastError, toastInfo } from "../components/toast";
import StreamSelectInput from "../components/StreamSelectInput";
import { SectionTitle, validate } from "../helperValidate";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import { styleData } from "../helperConst";
import { SmListHeader } from "../components/SmFormHeader";
import ClassroomSelectInput from "./ClassroomSelectInput";

const CommonReportForm = (props: any) => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;
  const useStyles = makeStyles(assignTimeslotShowStyle);
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [viewlLoading, setViewLoading] = useState<any>(null);

  const [semesterChange, setSemesterChange] = useState(Boolean);
  const { resource, apiName, responseData, dateArr, subArr, formdata } = props;
  const resourceName = translate(`resources.${resource}.navTitle`, resource);

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = resourceName;
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const onSubmit = (formdata: any) => {
    if (!formdata.stream) {
      toastError("Please Select Stream");
    } else if (!formdata.course) {
      toastError("Please Select Course");
    } else if (!formdata.semester) {
      toastError("Please Select Semester");
    } else if (!formdata.fromDate) {
      toastError("Please Select From Date");
    } else if (!formdata.toDate) {
      toastError("Please Select To Date");
    } else if (
      formdata.toDate &&
      new Date(formdata.fromDate) > new Date(formdata.toDate)
    ) {
      toastError("To Date must be  greater than from Date");
    } else if (formdata.toDate && new Date(formdata.toDate) > new Date()) {
      toastError("To Date must be  smaller than current Date");
    } else {
      setViewLoading(true);

      dataProvider
        .callCustom(`college-report/${apiName}`, {
          stream: formdata && formdata.stream ? formdata.stream : null,
          course: formdata && formdata.course ? formdata.course : null,
          semester: formdata && formdata.semester ? formdata.semester : null,
          classroom: formdata && formdata.classroom ? formdata.classroom : null,
          fromDate: (formdata && formdata.fromDate) || null,
          toDate: (formdata && formdata.toDate) || null,
        })
        .then((response: any) => {
          if (responseData) {
            responseData(response && response.data && response.data.list);
          }
          if (subArr) {
            subArr(response && response.data && response.data.subjectList);
          }
          if (dateArr) {
            dateArr(response && response.data && response.data.dates);
          }
          setViewLoading(false);
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    }
  };

  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };

  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };

  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };

  return (
    <div>
      <SmListHeader {...props} />
      <Form onSubmit={onSubmit}>
        {(props: any) => (
          <form>
            <Box
              component="div"
              className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
            >
              <CompanyReferenceInput></CompanyReferenceInput>
            </Box>
            <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
              <div>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <StreamSelectInput
                      labelName="Stream *"
                      dataManage={streamData}
                    ></StreamSelectInput>
                  )}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => {
                    return (
                      <CourseSelectInput
                        filter={{ stream: formData.stream, isActive: true }}
                        validate={validate}
                        formClassName={classes.code}
                        streamChange={streamChange}
                        dataManage={courseData}
                      ></CourseSelectInput>
                    );
                  }}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <SemesterSelectInput
                      filter={{
                        stream: formData.stream,
                        course: formData.course,
                        isActive: true,
                      }}
                      validate={validate}
                      streamChange={streamChange}
                      courseChange={courseChange}
                      dataManage={semesterData}
                    ></SemesterSelectInput>
                  )}
                </FormDataConsumer>
              </div>
            </div>

            <Box component="div" className={isXSmall ? "" : classes.flexCol}>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) => (
                  <ClassroomSelectInput
                    filter={{
                      stream: formData.stream,
                      course: formData.course,
                      semester: formData.semester,
                      isActive: true,
                    }}
                    // validate={validate}
                    streamChange={streamChange}
                    courseChange={courseChange}
                    semesterChange={semesterChange}
                  ></ClassroomSelectInput>
                )}
              </FormDataConsumer>
              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer formClassName={classes.mleft32}>
                  {({ formData }: { formData: any }) => (
                    <DateInput
                      source="fromDate"
                      label="label.fromDate"
                      alwaysOn
                      validate={validate}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  )}
                </FormDataConsumer>
              </div>

              <Box component="div" className={isXSmall ? "" : classes.mleft32}>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => {
                    return (
                      <DateInput
                        validate={validate}
                        source="toDate"
                        label="label.toDate"
                        alwaysOn
                        inputProps={{
                          min: "1900-08-10",
                          max: "3000-08-20",
                        }}
                        options={{
                          format: helperFunc.datePickerFormat(),
                          clearable: true,
                        }}
                      />
                    );
                  }}
                </FormDataConsumer>
              </Box>
            </Box>
            <Box>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) => {
                  if (formdata) {
                    formdata(formData);
                  }

                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onSubmit(formData)}
                    >
                      {viewlLoading && (
                        <CircularProgress
                          size={20}
                          thickness={2}
                          style={{ color: "aliceblue" }}
                        />
                      )}
                      VIEW
                    </Button>
                  );
                }}
              </FormDataConsumer>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CommonReportForm;
