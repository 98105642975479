import * as React from "react";
import { FC, memo, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";

interface State {
  departments?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  source?: any;
  disabled?: any;
}

const EmployeeSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  propsUrl,
  helperText,
  source,
  disabled,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const empSearchUrl = "employees/search";

  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList(propsUrl ? propsUrl : empSearchUrl, filter)
      .then((response: any) => {
        response &&
          response.data.forEach((item: any) => {
            item.name = `${item.name}(${item.employeeCode})`;
          });
        setState((state) => ({
          ...state,
          departments: response && response.data,
          // newDeparments: response && response.data
        }));
      });
  }, [filter]);

  if (emptyValue && emptyValue.emptyValue) {
    form.change("employee", undefined);
  }
  const { departments } = state;
  const check = (value: any) => {
    departments?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "employee");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label="Employee"
      disabled={(record && record.mode === "auto") || disabled ? true : false}
      source={source ? source : "employee"}
      validate={validate}
      choices={departments}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
    />
  );
};

EmployeeSelectInput.defaultProps = {
  source: "employee",
  label: "resources.customers.fields.name",
};

export default memo<Props>(EmployeeSelectInput);
