import * as React from "react";
import { FC, useState, useCallback } from "react";
import {
  useMediaQuery,
  Theme,
  Card,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import { AppState } from "../../../types";
import { leavesCardStyle } from "../../../helperStyle";
import DialogueBox from "../../DialogueBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import TodaysLeaveRequest from "../../icons/Todays-leave-request.png";
import PendingLeaveRequest from "../../icons/pending_leave_request.png";
import OtherPendingRequest from "../../icons/Other_Pending_request.png";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  data3?: {};
  isLoading?: boolean;
}
const useStyles = makeStyles(leavesCardStyle);

const LeaveCard: FC<Props> = ({ data, data2, data3, isLoading }: any) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  let openSidebar = useSelector(
    (state: AppState) => state.admin.ui.sidebarOpen
  );
  const [allRequestCounts, setallRequestCounts] = React.useState({
    pendingLeaveManagementCnt: 0,
    pendingRequestCnt: 0,
  });
  const [empAttendenceCount, setEmpAttendenceCount] = React.useState({
    absentEmployee: 0,
    leaveEmployee: 0,
    presentEmployee: 0,
  });
  const [leaveRequest, setLeaveRequest] = useState("");
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  React.useEffect(() => {
    setallRequestCounts(data);
    setEmpAttendenceCount(data2);
  }, [data, data2, data3]);
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data3 || {};
  // let dataArr: any = [];

  // const isApprove = "isApprove";
  // const pending = "pending";
  // let pendingLeaveUrl = `/leave-management?filter=%7B"${isApprove}"%3A"${pending}"%7D&order=DESC&page=1&perPage=20&sort=createdAt`;
  const headers = () => {
    if (leaveRequest === "Today Leave Requests") {
      return [
        { label: "Name", key: "employee.name" },
        { label: "Request", key: "request" },
        { label: "Status", key: "Leave" },
      ];
    } else if (leaveRequest === "Pending Leave Requests") {
      return [
        { label: "Name", key: "employee.name" },
        { label: "From", key: "fromDateStr" },
        { label: "To", key: "toDateStr" },
        { label: "LeaveType", key: "leaveType.code" },
      ];
    } else {
      return [
        { label: "Name", key: "employee.name" },
        { label: "Subject", key: "subject" },
        { label: "CreatedAt", key: "CreatedAt" },
      ];
    }
  };

  const obj: any = {
    "Today Leave Requests": "todayLeaveData",
    "Pending Leave Requests": "pendingLeaveManagement",
    "Other Pending Requests": "pendingRequest",
  };
  let dataArr = dataObj[obj[leaveRequest]];

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = useCallback((data: any) => {
    // setActiveIndex(index);
    setOpen(true);
    // setActiveSlice(data.name);
    setLeaveRequest(data);
    setPage(0);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <Card
      className={
        isXSmall
          ? classes.mobileRoot
          : isSmall
          ? classes.smallRoot
          : classes.root
      }
    >
      <Typography component="h6" className={classes.title}>
        {translate("pos.dashboard.leaves")}
      </Typography>
      <DialogueBox
        dialogueData={dataArr}
        open={open}
        handleClose={handleClose}
        activeSlice={leaveRequest}
        headerArr={headers()}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={[]}
        isExport={false}
      />

      {/* <Box className={classes.cardImg}>
                <img
                    alt=""
                    src="icons/quick_links.png"
                    title={translate("pos.dashboard.quick_links")}
                ></img>
            </Box> */}
      {isLoading ? (
        <span
          style={{
            width: "100%",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        // (empAttendenceCount && empAttendenceCount.leaveEmployee && leaveData.length > 0 ?
        <Grid
          container
          wrap="nowrap"
          style={{
            width: "auto",
            overflow: "auto",
            maxHeight: 180,
            marginLeft: 17,
            // marginRight: 17,
          }}
        >
          {/* {(isLoading ? Array.from(new Array(3)) : leaveData).map(
            (item: any, index: any) =>  */}

          <Box width={125} marginRight={1} my={5} marginTop={2}>
            <Box
              style={{
                border: "1px solid #6c757d9e",
                alignItems: "center",
                padding: "6px",
                borderRadius: "7px",
                height: "100px",
                width: "100px",
                marginBottom: "10px",
              }}
            >
              <img
                style={{ width: 100, height: 65 }}
                alt="Today Leave Requests"
                src={TodaysLeaveRequest}
                onClick={() => handleClickOpen("Today Leave Requests")}
              />
              <Typography
                style={{
                  textAlign: "center",
                  color: "blue",
                  paddingTop: "3px",
                }}
              >
                {`${
                  empAttendenceCount && empAttendenceCount.leaveEmployee
                    ? empAttendenceCount.leaveEmployee
                    : 0
                } / ${
                  empAttendenceCount && empAttendenceCount.absentEmployee
                    ? empAttendenceCount.absentEmployee
                    : 0
                }`}
              </Typography>
            </Box>
            {/* <Skeleton variant="rect" width={100} height={65} /> */}
            <Box pr={2}>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "center" }}
              >
                {/* {item.name} */}
                Today
              </Typography>
            </Box>
            {/* <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box> */}
          </Box>
          <Box width={125} marginRight={1} my={5} marginTop={2}>
            <Box
              style={{
                border: "1px solid #6c757d9e",
                alignItems: "center",
                padding: "6px",
                borderRadius: "7px",
                height: "100px",
                width: "100px",
                marginBottom: "10px",
              }}
            >
              <img
                style={{ width: 100, height: 65 }}
                alt="Pending Leave Requests"
                src={PendingLeaveRequest}
                onClick={() => handleClickOpen("Pending Leave Requests")}
              />
              <Typography
                style={{
                  textAlign: "center",
                  color: "blue",
                  paddingTop: "3px",
                }}
              >
                {allRequestCounts && allRequestCounts.pendingLeaveManagementCnt
                  ? allRequestCounts.pendingLeaveManagementCnt
                  : 0}
              </Typography>
            </Box>
            {/* <Skeleton variant="rect" width={100} height={65} /> */}
            <Box pr={2}>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "center" }}
              >
                {/* {item.name} */}
                Pending
              </Typography>
            </Box>
            {/* <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box> */}
          </Box>
          <Box width={125} marginRight={1} my={5} marginTop={2}>
            <Box
              style={{
                border: "1px solid #6c757d9e",
                alignItems: "center",
                padding: "6px",
                borderRadius: "7px",
                height: "100px",
                width: "100px",
                marginBottom: "10px",
              }}
            >
              <img
                style={{ width: 100, height: 65 }}
                alt="Other Pending Requests"
                src={OtherPendingRequest}
                onClick={() => handleClickOpen("Other Pending Requests")}
              />
              <Typography
                style={{
                  textAlign: "center",
                  color: "blue",
                  paddingTop: "3px",
                }}
              >
                {allRequestCounts && allRequestCounts.pendingRequestCnt
                  ? allRequestCounts.pendingRequestCnt
                  : 0}
              </Typography>
            </Box>
            {/* <Skeleton variant="rect" width={100} height={65} /> */}
            <Box pr={2}>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "center" }}
              >
                {/* {item.name} */}
                Other Pending
              </Typography>
            </Box>
            {/* <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box> */}
          </Box>
        </Grid>
        // <CardContent className={classes.card}>
        //   <Typography
        //     style={{ fontSize: 15 }}
        //     variant="subtitle1"
        //     color="textSecondary"
        //   >
        //     <Link
        //       href="#"
        //       // to={"/attendance"}
        //       onClick={() => handleClickOpen("Today Leave Requests")}
        //     >
        //       {`Total Today - ${
        //         empAttendenceCount && empAttendenceCount.leaveEmployee
        //           ? empAttendenceCount.leaveEmployee
        //           : 0
        //       } / ${
        //         empAttendenceCount && empAttendenceCount.absentEmployee
        //           ? empAttendenceCount.absentEmployee
        //           : 0
        //       }`}
        //     </Link>
        //   </Typography>
        //   <Typography
        //     style={{ fontSize: 15 }}
        //     variant="subtitle1"
        //     color="textSecondary"
        //   >
        //     <Link
        //       // to={pendingLeaveUrl}
        //       onClick={() => handleClickOpen("Pending Leave Requests")}
        //     >
        //       {`Pending - ${
        //         allRequestCounts && allRequestCounts.pendingLeaveManagementCnt
        //           ? allRequestCounts.pendingLeaveManagementCnt
        //           : 0
        //       }`}
        //     </Link>
        //   </Typography>
        //   <Typography
        //     style={{ fontSize: 15 }}
        //     variant="subtitle1"
        //     color="textSecondary"
        //   >
        //     <Link
        //       //   to={
        //       //     "/request?filter=%7B%22isApprove%22%3A%22pending%22%7D&order=DESC&page=1&perPage=10&sort=createdAt"
        //       //   }
        //       onClick={() => handleClickOpen("Other Pending Requests")}
        //     >
        //       {`Other Pending - ${
        //         allRequestCounts && allRequestCounts.pendingRequestCnt
        //           ? allRequestCounts.pendingRequestCnt
        //           : 0
        //       }`}
        //     </Link>
        //   </Typography>
        // </CardContent>
      )}
    </Card>
  );
};

export default LeaveCard;
