import CountryIcon from "@material-ui/icons/Public";
import CountryList from "./employeeTypeList";
import CountryEdit from "./employeeTypeEdit";
import CountryCreate from "./employeeTypeCreate";

export default {
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
  icon: CountryIcon,
};
