import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "./commonForm";

const HolidayCreate = (props: any) => {
  return (
    <Create {...props} title="resources.holidayList.create_title">
      <CommonForm redirect={'/holidayList'} {...props} />
    </Create>
  );
};


export default HolidayCreate;
