import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  BooleanInput,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CountryStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(CountryStyle);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/countries/create"
          ? "Update Country"
          : "Add Country"}
      </h2>
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.smallName}
            validate={nameValidate}
            label="Country Name"
          />
          <TextInput
            source="code"
            formClassName={classes.smallCode}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Country Name"
      />

      <TextInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
export default CommonForm;
