import * as React from "react";
import { useState } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  SaveButton,
  Toolbar,
  number,
  FormDataConsumer,
  minLength,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery, Box } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import { maxValue, minValue } from "../TurnoutAdmin";
import { workPlaceStyles } from "../helperStyle";
import EmployeeAllotment from "./EmployeeAllotment";
import WorkplaceShift from "./workplaceShift";
import helperFunc from "../helperFuncs";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButtonWithConfirmation undoable={false} titleSource="name" />
  </Toolbar>
);

const useStyles = makeStyles(workPlaceStyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const [allotments, setAllotments] = useState(Array);
  const [shifts, setShifts] = useState(Array);
  const [recordName, setRecordName] = useState(String);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const user = helperFunc.getUserData();

  const allotmentsUpdate = (data: any) => setAllotments(data);
  const shiftUpdate = (data: any) => {
    setShifts(data);
  };

  return isSmall || isXsmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/workplaces/create"
          ? "Update Workplace"
          : "Add Workplace"}
      </h2>
      <SimpleForm
        toolbar={
          <PostEditToolbar
            record={{ allotments: allotments, shifts: shifts }}
          />
        }
        {...props}
      >
        {/* <SectionTitle label="resources.departments.page.edit" /> */}
        <div style={{ display: "inline" }}>
          {/* <Box component="div" className={classes.inlineButton}> */}

          {props && Object.keys(props.record).length !== 0 ? (
            <EmployeeAllotment
              updateAllotment={allotmentsUpdate}
              type="workplace"
              record={props && props.record}
            ></EmployeeAllotment>
          ) : null}

          {props && Object.keys(props.record).length !== 0 ? (
            <WorkplaceShift
              recordName={recordName}
              setRecordName={setRecordName}
              updateShift={shiftUpdate}
              type="workplaceShift"
              record={props && props.record}
            ></WorkplaceShift>
          ) : null}

          {/* </Box> */}
          <CompanyReferenceInput></CompanyReferenceInput>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Workplace Name"
          />
          <SectionTitle label="" />
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                filter={{ isActive: true, company: formData.company }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>
          {user?.isClientConfiguration ? (
            <>
              <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                  <ClientMasterSelectInput
                    filter={{
                      isActive: true,
                      childCompanyId: formData.childCompanyId,
                      company: formData.company,
                    }}
                  ></ClientMasterSelectInput>
                )}
              </FormDataConsumer>
              <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                  <ClientLocationSelectInput
                    filter={{
                      isActive: true,
                      clientId: formData.clientId,
                      company: formData.company,
                    }}
                  ></ClientLocationSelectInput>
                )}
              </FormDataConsumer>{" "}
            </>
          ) : (
            ""
          )}

          <SectionTitle label="" />
          <TextInput
            label="label.latitude"
            source="lat"
            formClassName={classes.name}
            validate={latitudeValidation}
          />

          <TextInput
            label="label.longitude"
            source="lng"
            formClassName={isXsmall ? null : classes.code}
            validate={longitudeValidation}
          />
          <TextInput
            label="label.radius"
            source="radius"
            formClassName={isXsmall ? null : classes.code}
            validate={[required(), number(), minValue(20)]}
          />
          <SectionTitle label="" />
          <BooleanInput
            source="isMobileWorkplace"
            label="No Geo Restrictions"
          />
          <TextInput
            label="Duty Hours"
            source="dutyHrs"
            formClassName={isXsmall ? null : classes.name}
            validate={[number(), maxLength(6)]}
          />
          <TextInput
            label="Working Days"
            source="workingDays"
            formClassName={isXsmall ? null : classes.code}
            validate={[number(), maxLength(1)]}
          />
          <SectionTitle label="" />
          <TextInput
            type="time"
            label="Time of Reporting"
            source="timeOfReporting"
            formClassName={isXsmall ? null : classes.name}
          />
          <TextInput
            label="Attendance Cycle"
            source="attendanceCycle"
            formClassName={isXsmall ? null : classes.code}
          />
          <SectionTitle label="label.address" />
          {/* <FormDataConsumer formClassName={classes.name}>
                        {({ formData }: { formData: any }) => (
                            <CountrySelectInput></CountrySelectInput>
                        )}
                    </FormDataConsumer> */}
          {/*
                    <FormDataConsumer formClassName={classes.name} style={{ width: '220px' }}>
                        {({ formData }: { formData: any }) => (
                            <ZoneSelectInput />
                        )}
                    </FormDataConsumer>
                    <FormDataConsumer formClassName={classes.code} style={{ width: '220px' }}>
                        {({ formData }: { formData: any }) => (
                            <RegionSelectInput filter={{ zoneId: formData.zoneId, isActive: true }} />
                        )}
                    </FormDataConsumer>
                    <SectionTitle label="" />
                    <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                        {({ formData }: { formData: any }) => (
                            <StateSelectInput
                                filter={{ regionId: formData.regionId, isActive: true }}
                            ></StateSelectInput>
                        )}
                    </FormDataConsumer>

                    <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                        {({ formData }: { formData: any }) => (
                            <StateSelectInput
                                filter={{ country: formData.country, isActive: true }}
                            ></StateSelectInput>

                        )}
                    </FormDataConsumer>
                    <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                        {({ formData }: { formData: any }) => (
                            <CitiesSelectInput
                                filter={{ state: formData.state, isActive: true }}
                            ></CitiesSelectInput>
                        )}
                    </FormDataConsumer> */}

          <SectionTitle label="" />
          <TextInput
            multiline
            fullWidth={isXsmall ? false : true}
            source="address"
            formClassName={classes.address}
            validate={addressVlaidation}
          />
          <TextInput
            label="label.zipCode"
            source="zipcode"
            formClassName={isXsmall ? null : classes.code}
            validate={[required(), maxLength(6), number(), minLength(5)]}
          />

          <SectionTitle label="" />

          <BooleanInput
            formClassName={classes.code}
            source="isActive"
            defaultValue="true"
            label="label.status"
          />
        </div>
      </SimpleForm>
    </React.Fragment>
  ) : (
    <div className="form-content">
      <SimpleForm
        toolbar={
          <PostEditToolbar
            record={{ allotments: allotments, shifts: shifts }}
          />
        }
        {...props}
      >
        <Box component="div" className={classes.inlineButton}>
          {props && Object.keys(props.record).length !== 0 ? (
            <EmployeeAllotment
              updateAllotment={allotmentsUpdate}
              type="workplace"
              record={props && props.record}
            ></EmployeeAllotment>
          ) : null}

          {props && Object.keys(props.record).length !== 0 ? (
            <WorkplaceShift
              updateShift={shiftUpdate}
              recordName={recordName}
              setRecordName={setRecordName}
              type="workplaceShift"
              record={props && props.record}
            ></WorkplaceShift>
          ) : null}
        </Box>
        <CompanyReferenceInput></CompanyReferenceInput>
        <TextInput
          autoFocus
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
          label="Workplace Name"
        />
        <SectionTitle label="" />
        <div style={{ display: "flex" }}>
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                filter={{ isActive: true, company: formData.company }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>
          {user?.isClientConfiguration ? (
            <>
              <div style={{ marginLeft: "32px" }}>
                <FormDataConsumer
                  formClassName={isXsmall ? null : classes.code}
                >
                  {({ formData }: { formData: any }) => (
                    <ClientMasterSelectInput
                      filter={{
                        isActive: true,
                        childCompanyId:
                          formData.childCompanyId && formData.childCompanyId._id
                            ? formData.childCompanyId._id
                            : formData.childCompanyId,
                        company: formData.company,
                      }}
                    ></ClientMasterSelectInput>
                  )}
                </FormDataConsumer>
              </div>
              <div style={{ marginLeft: "32px" }}>
                {" "}
                <FormDataConsumer
                  formClassName={isXsmall ? null : classes.code}
                >
                  {({ formData }: { formData: any }) => (
                    <ClientLocationSelectInput
                      filter={{
                        isActive: true,
                        clientId:
                          formData.clientId && formData.clientId._id
                            ? formData.clientId._id
                            : formData.clientId,
                        company: formData.company,
                      }}
                    ></ClientLocationSelectInput>
                  )}
                </FormDataConsumer>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <SectionTitle label="" />
        <TextInput
          label="label.latitude"
          source="lat"
          formClassName={classes.name}
          validate={latitudeValidation}
        />

        <TextInput
          label="label.longitude"
          source="lng"
          formClassName={isXsmall ? null : classes.code}
          validate={longitudeValidation}
        />
        <TextInput
          label="label.radius"
          source="radius"
          defaultValue="200"
          formClassName={isXsmall ? null : classes.code}
          validate={[required(), number(), minValue(20)]}
        />

        <SectionTitle label="label.address" />
        {/*<FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                      <CountrySelectInput></CountrySelectInput>
                  )}
                  </FormDataConsumer> */}

        {/* <FormDataConsumer formClassName={classes.name} style={{ width: '220px' }}>
                  {({ formData }: { formData: any }) => (
                      <ZoneSelectInput />
                  )}
              </FormDataConsumer>
              <FormDataConsumer formClassName={classes.code} style={{ width: '220px' }}>
                  {({ formData }: { formData: any }) => (
                      <RegionSelectInput filter={{ zoneId: formData.zoneId, isActive: true }} />
                  )}
              </FormDataConsumer>
              <SectionTitle label="" />
              <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                  {({ formData }: { formData: any }) => (
                      <StateSelectInput
                          filter={{ regionId: formData.regionId, isActive: true }}
                      ></StateSelectInput>
                  )}
              </FormDataConsumer>
              <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                  {({ formData }: { formData: any }) => (
                      <CitiesSelectInput
                          filter={{ state: formData.state, isActive: true }}
                      ></CitiesSelectInput>
                  )}
                  </FormDataConsumer> */}
        <TextInput
          multiline
          fullWidth={isXsmall ? false : true}
          source="address"
          formClassName={classes.address}
          validate={addressVlaidation}
        />
        <TextInput
          label="label.zipCode"
          source="zipcode"
          formClassName={isXsmall ? null : classes.code}
          validate={[
            required(),
            maxLength(6),
            number(),
            regex(/.*\S.*/, "Only whitespaces are not allowed"),
          ]}
        />

        <SectionTitle label="Configuration" />

        <BooleanInput
          source="isMobileWorkplace"
          label="No Geo Restrictions"
          formClassName={classes.name}
        />

        <BooleanInput
          formClassName={classes.code}
          source="isRestrictedWorkplace"
          label="Restricted Workplace"
        />
        <BooleanInput
          formClassName={classes.code}
          source="isEmergency"
          label="Emergency"
        />
        <SectionTitle label="" />
        <BooleanInput
          formClassName={classes.name}
          source="isActive"
          defaultValue="true"
          label="label.status"
        />
      </SimpleForm>
    </div>
  );
};
const latitudeValidation = [number(), maxValue(90), minValue(-90)];
const longitudeValidation = [number(), maxValue(180), minValue(-180)];
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
// const codeValidate = [required(), maxLength(20)];
const addressVlaidation = [
  required(),
  maxLength(200),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

// const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

// const requiredValidate = [required()];

export default CommonForm;
