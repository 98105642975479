import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import { showNotification } from "react-admin";
import { useMediaQuery, Theme, Button } from "@material-ui/core";

const ImportVideoButton = connect(undefined, { showNotification })(
  (props: any) => {
    const isXSmall = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down("xs")
    );
    return (
      <>
        <input
          type="file"
          id="text-button-file"
          style={{ display: "none" }}
          accept=".mp4"
          onChange={props.onChange}
        />
        <label
          htmlFor="text-button-file"
          style={{
            display: "inline-flex",

            alignItems: "center",
          }}
        >
          <Button
            color="primary"
            component="span"
            style={{
              padding: "3px 0px 0px 0px",
              fontSize: "13px",
            }}
          >
            <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: 20 }} />
            {!isXSmall ? (
              <span style={{ paddingLeft: "0.5em" }}>Upload Video</span>
            ) : null}
          </Button>
        </label>
      </>
    );
  }
);

export default connect()(ImportVideoButton);
