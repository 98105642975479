import * as React from "react";
import { Link, useDataProvider, useRefresh } from "react-admin";

import { toastError, toastInfo } from "../components/toast";

const RequestLinkField = (props: any) => {
  const [status, setStatus] = React.useState() as any;
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const approvalStatus: any = {
    cancel: "Cancel",
  };

  const handleCancel = (record: any) => {
    const payLoad = {
      ...record,
      leaveType: record?.leaveType?._id,
      employeeData: record?.employee,
      employee: record?.name,
      isApprove: "cancel",
    };

    dataProvider
      .callCustomLocalOne("teamLeave/update/" + record?._id, payLoad)
      .then((response: any) => {
        setStatus(response?.data?.data?.status);
        toastInfo("Leave cancelled Successfully");
        refresh();
      });
  };

  return (
    <>
      {
        new Date(props?.record?.toDate) > new Date() &&
          props?.record?.isApprove === "pending" && (
            <Link onClick={(event: any) => handleCancel(props.record)}>
              Cancel
            </Link>
          )
        // : (
        //   status === "cancel" && <span>Cancel</span>
        // )
      }
    </>
  );
};

RequestLinkField.defaultProps = {
  source: "Action",
};

export default RequestLinkField;
