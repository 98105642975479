import LeaveEdit from "./LeaveEdit";
import LeaveCreate from "./LeaveCreate";
import { LeaveIcon } from "../svgIcon";
import LeaveList from "./LeaveList";
import { LeaveForm } from "./leaveCommon";

export default {
  list: LeaveList,
  create: LeaveForm,
  edit: LeaveForm,
  icon: LeaveIcon,
};
