import * as React from "react";
import { FC, useState } from "react";
import { maleFemaleCardStyle } from "../../../helperStyle";
import { Card, Typography, Box, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import DialogueBox from "../../DialogueBox";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  value?: number;
  data?: {};
  isLoading?: boolean;
}

const useStyles = makeStyles(maleFemaleCardStyle);

const MaleFemaleCard: FC<Props> = ({ data, isLoading }: any) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gender, setGender] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data || {};
  let dataArr: any = [];
  const headerArr: any = [
    { label: "Name", key: "name" },
    { label: "Code", key: "employeeCode" },
    { label: "Email", key: "email" },
  ];

  const obj: any = {
    Male: "maleData",
    Female: "feMaleData",
  };
  dataArr = dataObj[obj[gender]];

  //   const [empCounts, setEmpCounts] = React.useState({
  //     feMale: 0,
  //     male: 0,
  //   });

  //   React.useEffect(() => {
  // setEmpCounts(data);
  //   }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (data: any) => {
    setOpen(true);
    setGender(data);
    handleDefaultPage(0);
    handleRowsPerPage(10);
  };

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <Card
      className={
        isXSmall
          ? classes.mobileRoot
          : isSmall
          ? classes.smallRoot
          : classes.root
      }
    >
      <Typography component="h6" className={classes.title}>
        {translate("pos.dashboard.male_Female_Ratio")}
      </Typography>
      <DialogueBox
        dialogueData={dataArr}
        open={open}
        handleClose={handleClose}
        activeSlice={gender}
        headerArr={headerArr}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={[]}
        isExport={false}
      />
      <Box className={isXSmall ? classes.mobileCardImg : classes.cardImg}>
        {isLoading ? (
          <span
            style={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <>
            <Box>
              <Typography variant="subtitle1" color="textSecondary">
                {/* <Link
                to={`/employees?filter=%7B"${gender}"%3A"m"%7D&order=ASC&page=1&perPage=20&sort=name`}
              > */}
                <img
                  style={{ height: 50, margin: 10 }}
                  alt=""
                  src="icons/male.png"
                  title={"Male"}
                  onClick={() => handleClickOpen("Male")}
                />

                {dataObj && dataObj.male ? dataObj.male : 0}
                {/* </Link> */}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {/* <Link
                to={`/employees?filter=%7B"${gender}"%3A"f"%7D&order=ASC&page=1&perPage=20&sort=name`}
              > */}
                <img
                  style={{ height: 50, margin: 10 }}
                  alt=""
                  src="icons/female.png"
                  title={"Female"}
                  onClick={() => handleClickOpen("Female")}
                />

                {dataObj && dataObj.feMale ? dataObj.feMale : 0}
                {/* </Link> */}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};

export default MaleFemaleCard;
function index(data: any, index: any): void {
  throw new Error("Function not implemented.");
}
