import * as React from "react";
import { useState, useEffect } from "react";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Loading } from "react-admin";
import { useDataProvider } from "react-admin";
import { lightTheme } from "./themes";
import { createMuiTheme, makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;
const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const styles: Styles<Theme, any> = {
  card: {
    fontFamily: "Roboto , Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    display: "flex",
    width: "100%",
    minHeight: "450px",
    textAlign: "justify",
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    flexDirection: "column",
    paddingTop: "10px",
  },
  title: {
    padding: "10px",
    width: "100%",
    fontSize: "1.5rem",
  },
  flex: { display: "flex", width: "100%" },
  flexCol: {
    display: "flex",
  },

  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { width: "40%", padding: "10px" },
  rightCol: { width: "60%", padding: "10px", textAlign: "center" },
  flexTop: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBottom: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
  },
  singleCol: {
    marginTop: "4em",
    marginBottom: "2em",
    marginLeft: "1em",
    marginRight: "1em",
  },
};
interface FormValues {
  username?: string;
}
interface State {
  data?: any;
}
const useStyles = makeStyles(styles);
const AboutTurnout = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [state, setState] = useState<State>({});
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    dataProvider
      .callCustomLocal(`${apiUrl}/public/about-us`)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          data: response && response.data && response.data.item,
        }));
      });
  }, [dataProvider]);

  const { data } = state;

  if (!data) {
    return <Loading loadingPrimary="Loading" />;
  } else {
    return isXSmall ? (
      <div className={classes.card}>
        <Box component="div" className={classes.singleCol}>
          <Box component="div" textAlign="center">
            <img
              src={data && data.image && uploadApiUrl + data.image}
              width="80%"
              alt=""
            />
          </Box>
          <Box component="div" textAlign="center">
            <div dangerouslySetInnerHTML={{ __html: data && data.data }} />
          </Box>
        </Box>
        <Box component="div" textAlign="center" className={classes.singleCol}>
          <Box textAlign="center" component="div" className={classes.flexCol}>
            <Typography
              component="div"
              id="footer-copyright"
              // className={classes.copyright}
              align="left"
              dangerouslySetInnerHTML={{ __html: data && data.footer }}
            ></Typography>
          </Box>
        </Box>
      </div>
    ) : isSmall ? (
      <div className={classes.card}>
        <Box component="div" className={classes.singleCol} textAlign="center">
          <Box component="div" textAlign="center">
            <img
              src={data && data.image && uploadApiUrl + data.image}
              width="80%"
              alt=""
            />
          </Box>
          <Box component="div">
            <div dangerouslySetInnerHTML={{ __html: data && data.data }} />
          </Box>
        </Box>
        <Box component="div" className={classes.singleCol} textAlign="center">
          {/* <Box component="div" className={classes.flexCol} textAlign="center"> */}
          <Typography
            component="div"
            id="footer-copyright"
            className={classes.copyright}
            align="center"
            dangerouslySetInnerHTML={{ __html: data && data.footer }}
          ></Typography>
          {/* </Box> */}
        </Box>
      </div>
    ) : (
      <div className={classes.card}>
        <Box component="div" className={classes.flexTop}>
          <Box component="div" className={classes.flexCol}>
            <Typography component="h2" className={classes.title}>
              {data && data.title}
            </Typography>
          </Box>
        </Box>

        <Box component="div" className={classes.flex}>
          <Box component="div" className={classes.leftCol}>
            <div dangerouslySetInnerHTML={{ __html: data && data.data }} />
          </Box>
          <Box component="div" className={classes.rightCol}>
            <img
              src={data && data.image && uploadApiUrl + data.image}
              width="80%"
              alt=""
            />
          </Box>
        </Box>
        <Box component="div" className={classes.flexBottom}>
          <Box component="div" className={classes.flexCol}>
            <Typography
              component="div"
              id="footer-copyright"
              className={classes.copyright}
              align="left"
              dangerouslySetInnerHTML={{ __html: data && data.footer }}
            ></Typography>
          </Box>
        </Box>
      </div>
    );
  }
};

// AboutTurnout.propTypes = {
//   authProvider: PropTypes.func,
//   previousRoute: PropTypes.string,
// };

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const OtpRequestWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <AboutTurnout {...props} />
  </ThemeProvider>
);

export default OtpRequestWithTheme;
