import React, { useState } from "react";
import {
  SimpleForm,
  useTranslate,
  required,
  FormDataConsumer,
  BooleanInput,
  SelectInput,
  DateTimeInput,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import useDeepCompareEffect from "use-deep-compare-effect";
import MomentUtils from "@date-io/moment";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import { deviceLocation } from "../helperChoice";
import { attendanceStyles } from "../helperStyle";
import { SectionTitle, validate } from "../helperValidate";
import { SmFormHeader } from "../components/SmFormHeader";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import ClassroomSelectInput from "../components/ClassroomSelectInput";
import SubjectSelectInput from "../components/SubjectSelectInput";
import FacultySelectInput from "../components/FacultySelectInput";
import StudentSelectInput from "../components/StudentSelectInput";

export const validatePasswords = ({ time }: { time: string }) => {
  const errors = {} as any;
  if (time && new Date(time) >= new Date()) {
    errors.time = "Date Should be smaller then current date";
  }
  return errors;
};

const useStyles = makeStyles(attendanceStyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  let [update, setupdate] = React.useState(false);
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [semesterChange, setSemesterChange] = useState(Boolean);
  const [subjectChange, setSubjectChange] = useState(Boolean);
  let newClass = classes;
  const user = helperFunc.getUserData();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };
  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };
  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };
  const subjectData = (data: any) => {
    setSubjectChange(true);
    setTimeout(function () {
      setSubjectChange(false);
    }, 1000);
  };

  useDeepCompareEffect(() => {
    if (isSmall) {
      classes.code = newClass.name;
    } else {
      classes.code = newClass.code;
    }
    setupdate(update ? false : true);
  }, [isSmall, classes]);

  return (
    <>
      <SmFormHeader {...props} />
      <SimpleForm {...props} validate={validatePasswords}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput record={formData}></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <StreamSelectInput
              validate={validate}
              filter={{
                isActive: true,
                company: formData.company,
              }}
              dataManage={streamData}
            ></StreamSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <CourseSelectInput
              validate={validate}
              filter={{ stream: formData.stream }}
              streamChange={streamChange}
              dataManage={courseData}
            ></CourseSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <SemesterSelectInput
              validate={validate}
              filter={{ stream: formData.stream, course: formData.course }}
              streamChange={streamChange}
              courseChange={courseChange}
              dataManage={semesterData}
            ></SemesterSelectInput>
          )}
        </FormDataConsumer>
        {!isSmall ? <SectionTitle label="" /> : null}
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <ClassroomSelectInput
              filter={{
                stream: formData.stream,
                course: formData.course,
                semester: formData.semester,
                isActive: true,
              }}
              validate={validate}
              streamChange={streamChange}
              courseChange={courseChange}
              semesterChange={semesterChange}
            ></ClassroomSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <SubjectSelectInput
              validate={validate}
              filter={{
                isActive: true,
                stream: formData.stream,
              }}
              dataManage={subjectData}
              streamChange={streamChange}
              courseChange={courseChange}
              semesterChange={semesterChange}
            ></SubjectSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <FacultySelectInput
              validate={validate}
              filter={{
                isActive: true,
                stream: formData.stream,
                subject: formData.subject,
              }}
              subjectChange={subjectChange}
              streamChange={streamChange}
              courseChange={courseChange}
              semesterChange={semesterChange}
            ></FacultySelectInput>
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <StudentSelectInput
              validate={validate}
              filter={{
                isActive: true,
                stream: formData.stream,
                course: formData.course,
                semester: formData.semester,
              }}
              streamChange={streamChange}
              courseChange={courseChange}
              semesterChange={semesterChange}
            ></StudentSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <SelectInput
              resettable
              disabled={formData.mode === "auto" ? true : false}
              label="label.device_location"
              reference="authType"
              source="authType"
              validate={validate}
              formClassName={classes.code}
              choices={deviceLocation}
            />
          )}
        </FormDataConsumer>
        <DateTimeInput
          formClassName={classes.code}
          label="label.dateTime"
          source="time"
          options={{
            format: "DD/MM/YYYY, HH:mm:ss",
            ampm: true,
            clearable: true,
          }}
          providerOptions={{ utils: MomentUtils }}
        />
      </SimpleForm>
    </>
  );
};

export default CommonForm;
