import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../visitors/commonForm";

const VisitorCreate = (props: any) => {

  return (
    <Create {...props}>
      <CommonForm redirect={'/visitors'}{...props} />
    </Create>
  );
};

export default VisitorCreate;
