import CameraVenueList from "./cameraVenueList";
import { CommonCameraVenue } from "./cameraVenueCommon";
import { CameraVenueIcon } from "../svgIcon";

export default {
  list: CameraVenueList,
  create: CommonCameraVenue,
  edit: CommonCameraVenue,
  icon: CameraVenueIcon,
};
