import * as React from "react";
import { Create } from "react-admin";
import CommonForm from '../branches/commonForm';


const BranchCreate = (props: any) => {
  return (
    <Create {...props} >
      <CommonForm redirect={'/branches'}  {...props} />
    </Create>
  );
};


export default BranchCreate;
