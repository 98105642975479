import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate, Link } from "react-admin";

import CardIcon from "./CardIcon";

interface Props {
  value?: number;
  data?: JSON;
  isLoading?: boolean;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    borderBottom: "4px solid #31708f",
  },
  title: {},
  titleEmp: {
    color: "rgb(45 109 146)",
  },
  titleVisitor: {
    color: "rgb(45 109 146)",
  },
});

const TotalHeads: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Link to="/employees">
        <CardIcon Icon={GroupIcon} bgColor="#31708f" />
        <Card className={classes.card}>
          <div>
            <Typography className={classes.title} color="textSecondary">
              {translate("pos.dashboard.total_heads")}
            </Typography>
            <Typography variant="h5" component="h2">
              {value}
            </Typography>
          </div>
          {/* <div>
            <Typography className={classes.titleEmp} color="textSecondary">
              Employee
            </Typography>
            <Typography variant="h5" component="h2">
              {value}
            </Typography>
          </div> */}
          {/* <div>
            <Typography className={classes.titleVisitor} color="textSecondary">
              Visitor
            </Typography>
            <Typography variant="h5" component="h2">
              {value}
            </Typography>
          </div> */}
        </Card>
      </Link>
    </div>
  );
};

export default TotalHeads;
