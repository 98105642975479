import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {
  useTranslate,
  DateInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { changeTheme } from "./actions";
import { AppState } from "../types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Designations from "./designations";
import DesignationHierarchy from "./designationhierarchy";
import helperFunc from "../helperFuncs";
import { toastInfo } from "../components/toast";
import SyncIcon from "@material-ui/icons/Sync";

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  phone: { width: 544 },
  address: { display: "inline-block", width: 544, marginLeft: 32 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
  root: {
    flexGrow: 1,
    width: "100%",
  },
});
// const requiredValidate = [required()];
export const validatePasswords = ({
  password,
  confirm_password,
}: {
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};
// const SectionTitle = ({ label }: { label: string }) => {
//   const translate = useTranslate();

//   return (
//     <Typography variant="h6" gutterBottom>
//       {translate(label)}
//     </Typography>
//   );
// };

const Configuration = (props: any) => {
  const { resource, id } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useSelector((state: AppState) => state.theme);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const user = helperFunc.getUserData();
  const [configData, setConfigData] = useState() as any;
  const [state, setState] = useState<State>({});
  interface State {
    format?: number;
  }

  //   const { data, loading, error } = useQuery({
  //     type: "getProfile",
  //     resource: "account/profile",
  //     payload: {},
  //   });

  //   if (loading) return <Loading />;
  //   if (error) return <Error />;
  //   if (!data) return null;

  // React.useEffect(() => {
  //   const format: any = localStorage.getItem("dateFormat");
  //   setState((state) => ({
  //     ...state,
  //     format: format,
  //   }));
  // }, [props]);

  // const HandleOnChange = (format: any) => {
  //   toastInfo("Date changed Successfully");
  //   localStorage.setItem("dateFormat", format.target.value);
  //   if (format.target.value === "DD/MM/YYYY") {
  //     localStorage.setItem("pickerFormat", "dd/MM/yyyy");
  //   }
  //   if (format.target.value === "MM/DD/YYYY") {
  //     localStorage.setItem("pickerFormat", "MM/dd/yyyy");
  //   }
  //   if (format.target.value === "YYYY/MM/DD") {
  //     localStorage.setItem("pickerFormat", "yyyy/MM/dd");
  //   }
  //   setState((state) => ({
  //     ...state,
  //     format: format.target.value,
  //   }));
  // };

  const handleTheme = (value: any) => {
    dispatch(changeTheme(value));
    localStorage.setItem("theme", value);
  };
  const { format } = state;
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any) => {
    setConfigData({ ...configData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (user?.company?._id) {
      const resourcePath = `companyMasters/get/odfrsetting/${user?.company?._id}`;
      dataProvider
        .callCustom(resourcePath, {})
        .then((response: any) => {
          setConfigData({
            dailySyncTime: response?.data?.item?.odfrsetting?.dailySyncTime,
            retryCount: response?.data?.item?.odfrsetting?.retryCount,
            recognitionThreshold:
              response?.data?.item?.odfrsetting?.recognitionThreshold,
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const saveForm = () => {
    const resourcePath = `companyMasters/update/odfrsetting/${user?.company?._id}`;

    dataProvider
      .callCustom(resourcePath, {
        recognitionThreshold: configData?.recognitionThreshold,
        retryCount: configData?.retryCount,
        dailySyncTime: configData?.dailySyncTime,
      })
      .then((response: any) => {
        toastInfo(`ODFR setting updated Succesfully`);
      });
  };

  const CustomToolbar = (props: any) => (
    <Toolbar alwaysEnableSaveButton {...props}>
      <SaveButton disabled={false} />
    </Toolbar>
  );

  return (
    <div>
      <div style={{ fontSize: "25px", padding: "10px 0px" }}>
        Configurations
      </div>
      <SimpleForm toolbar={false} redirect={false} validate={validatePasswords}>
        <div
          className="d-flex"
          style={{ minWidth: "800px", display: "flex", marginTop: "18px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: "160px" }}
          >
            <SyncIcon
            // onClick={handleClick}

            // className={classes.icon}
            />
            Settings
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "32px" }}
          >
            <SyncIcon
            // onClick={handleClick}
            // style={{ fontSize: 60 }}
            // className={classes.icon}
            />
            Registrations
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "32px" }}
          >
            <SyncIcon
            // onClick={handleClick}
            // style={{ fontSize: 60 }}
            // className={classes.icon}
            />
            Logs
          </Button>

          {/* <BooleanInput
            source="sync_settings"
            label="Settings"
            defaultValue={false}
          /> */}
          {/* <BooleanInput
            source="sync_registrations"
            label="Registrations"
            defaultValue={false}
          /> */}
          {/* <BooleanInput source="sync_logs" label="Logs" defaultValue={false} /> */}
        </div>
        <div className="d-flex">
          <div
            style={{ minWidth: "400px", display: "flex", marginTop: "18px" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "160px" }}
            >
              <SyncIcon
              // onClick={handleClick}
              // style={{ fontSize: 60 }}
              // className={classes.icon}
              />
              Check In Out
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "32px", minWidth: "175px" }}
            >
              <SyncIcon
              // onClick={handleClick}
              // style={{ fontSize: 60 }}
              // className={classes.icon}
              />
              QR Data
            </Button>
            {/* <BooleanInput
              source="sync_checkinout"
              label="Check In Out"
              defaultValue={false}
              style={{ width: "160px" }}
            />
            <BooleanInput
              source="sync_qrdata"
              label="QR Data"
              defaultValue={false}
              style={{ width: "180px" }}
            /> */}
          </div>
        </div>
      </SimpleForm>
      <div style={{ fontSize: "25px", padding: "10px 0px" }}>Settings</div>
      <SimpleForm save={saveForm} toolbar={<CustomToolbar />}>
        <div className="d-flex">
          <div
            style={{
              position: "relative",
              transition:
                "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              display: "flex",
              height: "49px",
              minWidth: "182px",
              alignItems: "center",
              // justifyContent: "center",
              marginTop: "9px",
            }}
          >
            <span
              style={{
                color: "#3f51b5",
                marginRight: "13px",
                marginLeft: "8px",
              }}
            >
              Time :{" "}
            </span>

            <input
              type="time"
              onChange={(e: any) => handleChange(e)}
              min="00:00:00"
              max="23:59:00"
              step="1"
              value={configData?.dailySyncTime}
            />
          </div>

          <TextInput
            autoFocus
            label="Recognition Threshold"
            source="recognitionThreshold"
            className={classes.code}
            defaultValue={configData?.recognitionThreshold}
            onChange={(e: any) => handleChange(e)}
            // validate={nameValidate}
          />
          <TextInput
            autoFocus
            source="retryCount"
            label="Retry Count"
            className={classes.code}
            defaultValue={configData?.retryCount}
            onChange={(e: any) => handleChange(e)}
            // validate={nameValidate}
          />
        </div>
      </SimpleForm>
    </div>
    // <div>
    //   <div style={{ fontSize: "25px", padding: "10px 0px" }}>
    //     Configurations
    //   </div>
    //   <AppBar position="static" color="default">
    //     <Tabs
    //       value={value}
    //       onChange={handleChange}
    //       indicatorColor="primary"
    //       textColor="primary"
    //       variant="scrollable"
    //       scrollButtons="auto"
    //       aria-label="scrollable auto tabs example"
    //     >
    //       {helperFunc.getUserData().type === "company" ||
    //       helperFunc.getUserData().type === "super" ? (
    //         <Tab label="Hierarchy" {...a11yProps(0)} />
    //       ) : null}
    //       {helperFunc.getUserData().type === "company" ||
    //       helperFunc.getUserData().type === "super" ? (
    //         <Tab label="Designations" {...a11yProps(1)} />
    //       ) : null}
    //       <Tab label="Other Settings" {...a11yProps(2)} />
    //     </Tabs>
    //   </AppBar>
    //   {helperFunc.getUserData().type === "company" ||
    //   helperFunc.getUserData().type === "super" ? (
    //     <TabPanel value={value} index={0}>
    //       <DesignationHierarchy />
    //     </TabPanel>
    //   ) : null}
    //   {helperFunc.getUserData().type === "company" ||
    //   helperFunc.getUserData().type === "super" ? (
    //     <TabPanel value={value} index={1}>
    //       <Designations />
    //     </TabPanel>
    //   ) : null}
    //   <TabPanel
    //     value={value}
    //     index={
    //       helperFunc.getUserData().type === "company" ||
    //       helperFunc.getUserData().type === "super"
    //         ? 2
    //         : 0
    //     }
    //   >
    //     <Card style={{ padding: "0px !important" }}>
    //       <Title title={translate("pos.configuration")} />

    //       <CardContent>
    //         <div className={classes.label}>{"Date Format"}</div>
    //         <Select
    //           onChange={HandleOnChange}
    //           defaultValue={format}
    //           value={format ? format : "DD/MM/YYYY"}
    //         >
    //           <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
    //           <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
    //           <MenuItem value="YYYY/MM/DD">YYYY/MM/DD</MenuItem>
    //           <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
    //           <MenuItem value="YYYY-MM-DD">YYYY-MM-DD</MenuItem>
    //         </Select>
    //       </CardContent>
    //     </Card>
    //   </TabPanel>
    // </div>
  );
};

export default Configuration;
