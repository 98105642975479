import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  cameraVenue?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  validate?: {};
  helperText?: any;
}

const CameraVenueSelectInput: FC<Props> = ({
  filter,
  validate,
  helperText,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getSearchList("camera-venue/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          cameraVenue: response && response.data,
        }));
      });
  }, [filter]);

  const { cameraVenue } = state;

  return (
    <SelectInput
      label="Camera Venue"
      className={classes.root}
      source="cameraVenue"
      helperText={helperText}
      // validate={requiredValidate}
      choices={cameraVenue}
    />
  );
};
const requiredValidate = [required()];
CameraVenueSelectInput.defaultProps = {
  source: "cameraVenue",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CameraVenueSelectInput);
