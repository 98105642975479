import CompanyMasterList from "./CompanyMasterList";
import { CompanySubcidiaryIcon } from "../svgIcon";
import { CommonCompanyMaster } from "./companyMasterCommon";

export default {
  list: CompanyMasterList,
  create: CommonCompanyMaster,
  edit: CommonCompanyMaster,
  icon: CompanySubcidiaryIcon,
};
