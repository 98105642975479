import * as React from "react";
import { Button } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const DialogueBox = (props: any) => {
  const handleClick = () => {
    props.remove();
    props.handleCloseClick();
  };

  const { dialogueTitle, dialogueMessage } = props;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCloseClick}
        aria-label="Are you sure?"
      >
        <DialogTitle>
          {dialogueTitle ? dialogueTitle : "Deleting Allotments"}

          {/* {props
            ? props &&
              props.itemName &&
              props.itemName.employeeId &&
              props.itemName.employeeId.name
              ? props.itemName.employeeId.name
              : props.itemName
              ? props.itemName
              : "N/A"
            : "N/A"} */}
        </DialogTitle>
        <div style={{ margin: window.screen.width < 460 ? 15 : 0 }}>
          <p style={{ marginRight: "25px", marginLeft: "25px" }}>
            {dialogueMessage
              ? dialogueMessage
              : "Are you sure you want to delete? Please Save for confirm changes."}
          </p>
        </div>

        <DialogActions>
          <Button type="submit" label="Confirm" onClick={handleClick}>
            <CheckCircleIcon />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={props.handleCloseClick}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogueBox;
