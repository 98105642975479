import React, { useState, useEffect } from "react";

import {
  FormDataConsumer,
  SimpleForm,
  DateTimeInput,
  TextInput,
  useDataProvider,
  required,
  minValue,
  DateInput,
} from "react-admin";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Button } from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { toastError, toastInfo } from "../components/toast";
import helperFunc from "../helperFuncs";

const ChangeAttendance = (props: any) => {
  const { clickedEmp, handleCloseClick } = props;
  const dataProvider = useDataProvider();
  const defaultTime = moment(clickedEmp?.time).format("HH:mm");
  const [value, setValue] = useState(defaultTime);

  const newDate = (helperFunc.commonDateFormat(clickedEmp?.time) +
    value) as any;

  const isoDate = moment().toISOString(newDate);

  const saveForm = (record: any) => {
    if (isoDate === undefined || isoDate === "") {
      toastError("Time is Required");
    } else if (record?.remarks === undefined || record?.remarks === "") {
      toastError("Remarks is Required");
    } else {
      dataProvider
        .callCustomLocalOne("attendance/change_request/" + clickedEmp?._id, {
          time: isoDate,
          reason: record?.remarks,
        })
        .then((response: any) => {
          props.handleCloseClick();
          toastInfo("Change Attendance Request sent Successfully");
        });
    }
  };

  return (
    <SimpleForm toolbar={null}>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <>
            <div
              style={{
                position: "relative",
                transition:
                  "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: "10px",
              }}
            >
              <span style={{ color: "#3f51b5", marginRight: "13px" }}>
                Time * :{" "}
              </span>
              {helperFunc.commonDateFormat(clickedEmp?.time)}{" "}
              <input
                type="time"
                onChange={(ev) => setValue(ev.target.value)}
                min="00:00"
                max="23:59"
                step="60"
                value={value}
                // disabled={true}
              />
            </div>
          </>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <TextInput label="Reason *" source="remarks" />
        )}
      </FormDataConsumer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <Button label="Save" onClick={() => saveForm(formData)}>
                <CheckCircleIcon />
              </Button>
            );
          }}
        </FormDataConsumer>

        <Button
          label="ra.action.cancel"
          onClick={handleCloseClick}
          style={{ color: "#181818cf" }}
        >
          <IconCancel />
        </Button>
      </div>
    </SimpleForm>
  );
};

export default ChangeAttendance;
