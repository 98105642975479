import { Card, CardContent } from "@material-ui/core";
import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, AppBar, Tabs, Tab, Box } from "@material-ui/core";

import DesignationList from "./DesignationList";
import DesignationHierarchy from "./designationhierarchy";
import Designations from "./designations";
import TestHierarchy from "./TestHierarchy";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
  appbar: {
    boxShadow: "unset",
  },
  tabs: {
    backgroundColor: "white",
    borderRadius: "5px",
  },
  tab: {
    fontSize: "12px",
    color: "black",
    backgroundColor: "white",
    maxWidth: "200px",
    "&:focus": {
      backgroundColor: "white",
      color: "gray",
      //   fontSize:'20px'
    },
    "&:visited": {
      color: "gray",
    },
    "&:selected": {
      color: "red",
    },
  },
  tableCell: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
  },
  noRecordFound: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "left",
    fontSize: 12,
    padding: 10,
    minWidth: "100px",
    width: "100px",
  },
  filter: {
    width: "89%",
  },
  table: {
    borderCollapse: "inherit",
  },
  root: {
    width: "100%",
  },
  childBlock: {
    display: "flex",
    // margin: 10
  },

  Blockreport: {
    maxWidth: "100%",
    display: "none",
    overflow: "hidden",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  rtable: {
    maxWidth: "100%",
    display: "block",
    overflowX: "auto",
  },
  flexCol: {
    display: "flex",
    width: "100%",
  },
  ButtonflexCol: {
    margin: 10,
    display: "flex",
    width: "100vh",
  },
  mobileFlexCol: {
    margin: 10,
    width: "100vh",
  },
  flexColTop: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
  },
  mleft32: {
    marginLeft: 32,
  },
  savemleft32: {
    marginLeft: 500,
  },
  viewBtn: {
    fontSize: 14,
    height: 35,
    marginTop: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    marginBottom: 15,
    alignItems: "center",
    margin: "auto",
    width: "10%",
    position: "sticky",
  },
  downloadBtn: {
    fontSize: 14,
    height: 35,
    marginTop: 15,
    color: "#3f51b5",
    borderColor: "#3f51b5",
    marginBottom: 15,
    width: "15%",
    alignItems: "center",
    margin: "auto",
  },
  container: {
    maxHeight: 550,
    maxWidth: "calc(100vw - 268px)",
  },
  menuCloseContainer: {
    maxHeight: 550,
    maxWidth: "calc(114vw - 268px)",
  },
  smallContainer: {
    maxHeight: 550,
    maxWidth: "100%",
  },
  header: {
    border: "solid 1px lightgrey",
    borderRight: "solid 0px lightgrey",
    textAlign: "center",
    position: "sticky",
    zIndex: 10,
    top: 0,
  },
  tableHead: {
    border: "solid 1px lightgrey",
    textAlign: "center",
    position: "sticky",
    top: "2.5rem",
    minWidth: "100px",
  },
  margin: {
    display: "flex",
    marginRight: "20px",
    marginTop: "7px",
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <Card className={classes.card}>
      {/* <CardHeader title="Leaves" /> */}
      <CardContent style={{ padding: "0px" }}>
        <div>
          <AppBar position="static" color="default" className={classes.appbar}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
              className={classes.tabs}
              // TabIndicatorProps={{
              //   style: {
              //     backgroundColor: "#adacac",
              //     color: "black",
              //   },
              // }}
            >
              <Tab
                label="Designations"
                {...a11yProps(0)}
                className={classes.tab}
              />
              {/* {authProvider.isMenuShow("teamLeaves", "list") ? ( */}
              <Tab
                label="Designations Hierarchy"
                {...a11yProps(1)}
                className={classes.tab}
              />
              {/* // ) : null} */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <DesignationList />
          </TabPanel>
          {/* {authProvider.isMenuShow("teamLeaves", "list") ? ( */}
          <TabPanel value={value} index={1}>
            <>
              {/* <div className="sub-heading mt-18">
                <h6 key={Math.random()}>Hierarchy</h6>
                <hr />
              </div> */}
              {/* <DesignationHierarchy /> */}
              {/* <div className="sub-heading mt-18">
                <h6 key={Math.random()}>Designations</h6>
                <hr />
              </div>

              <Designations /> */}
              <TestHierarchy />
            </>
          </TabPanel>
          {/* ) : null} */}
        </div>
      </CardContent>
    </Card>
  );
};
