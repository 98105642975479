import React, { Fragment, useState, useEffect } from "react";
import { useNotify, TextInput, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import { attendanceExtraDuty } from "../helperStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { useForm } from "react-final-form-hooks";
import Grid from "@material-ui/core/Grid";
import helperFunc from "../helperFuncs";
import { toastError } from "../components/toast";

const FinalReportEdit = (props: any) => {
  const param: any = useParams();
  interface res {
    list: any;
    datesData: any;
    filter: any;
    type: any;
  }
  interface Hres {
    childCompanyId: any;
    clientId: any;
    clientLocationId: any;
    company: any;
    employee: any;
    state: any;
    item: any;
  }
  const approvalStatus: any = {
    Pending: "ATTENDANCE NOT SENT FOR APPROVAL",
    Approved: "APPROVED",
    Send_for_approval: "REQUEST SENT FOR APPROVAL",
    Send_for_rectification: "CLIENT ASKED FOR CORRECTION",
  };

  const onSubmit = (value: any) => {
    setSaveLoading(true);
    if (Object.keys(value).length !== 0) {
      dataProvider
        .callCustom(`public/approval-reports-update/${param.id}`, {
          isApproved: "Send_for_rectification",
          rectificationText: value.rectificationText,
          _id: param.id,
        })
        .then((response: any) => {
          dataProvider
            .getOne("public/approval-reports", param)
            .then((response: any) => {
              setHeaderData(response.allData);
            })
            .catch((err: any) => {
              toastError(err.message);
            });
          setSaveLoading(false);
          setOpen(false);
          alert("Send for Rectification");
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    } else {
      alert(" Rectification text required");
    }
  };
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  const useStyles = makeStyles(attendanceExtraDuty);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  //   const notify = useNotify();
  const classes = useStyles();
  const [responseData, setResponseData] = useState<res>({
    list: [],
    datesData: [],
    filter: {},
    type: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { handleSubmit } = useForm({ onSubmit });
  const [open, setOpen] = useState<any>(false);
  const [saveLoading, setSaveLoading] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [headerData, setHeaderData] = useState<Hres>({
    childCompanyId: {},
    clientId: {},
    clientLocationId: {},
    company: {},
    employee: {},
    state: {},
    item: {},
  });
  const [tab1, setTab1] = useState(true);

  useEffect(() => {
    dataProvider
      .getOne("public/approval-reports", param)
      .then((response: any) => {
        setHeaderData(response.allData);
        setResponseData(response.data.reportData);
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, []);
  const currentTab1 = () => {
    setTab1(true);
  };
  const currentTab2 = () => {
    setTab1(false);
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const handleClickPopUp = () => {
    setOpen(true);
  };

  const handleClickApproval = () => {
    setLoading(true);

    dataProvider
      .callCustom(`public/approval-reports-update/${param.id}`, {
        isApproved: "Approved",
        _id: param.id,
      })
      .then((response: any) => {
        setLoading(false);
        dataProvider
          .getOne("public/approval-reports", param)
          .then((response: any) => {
            setHeaderData(response.allData);
            refresh();
          })
          .catch((err: any) => {
            alert(err.message);
          });
        alert("Attendance successfully approved");
      })
      .catch((err: any) => {
        setLoading(false);
        alert(err.message);
      });
  };
  return (
    <div
      style={
        window.screen.width < 460 ? { width: "100vh", overflow: "scroll" } : {}
      }
    >
      <div
        style={
          window.screen.width < 460
            ? { overflow: "scroll", width: "200vh" }
            : {}
        }
        className="container-fluid"
      >
        {headerData &&
        headerData.item.isApproved === "Send_for_approval" &&
        headerData.item.isApproved !== undefined ? (
          <div style={{ margin: "20px", fontSize: "2em" }}>
            {headerData.item.attendenceId}
          </div>
        ) : null}
        {headerData &&
        headerData.item.isApproved !== "Send_for_approval" &&
        headerData.item.isApproved !== undefined ? (
          <div style={{ margin: "20px", fontSize: "2em" }}>{`${
            headerData.item.attendenceId
          } - ${approvalStatus[headerData.item.isApproved]}`}</div>
        ) : null}
        <div style={{ marginBottom: 20, marginTop: 5 }} className="row">
          <div className={window.screen.width < 460 ? "col-4" : "col-6"}>
            <table
              width={window.screen.width < 460 ? window.screen.width : ""}
              className={classes.table_first}
            >
              <tr>
                <td className={classes.table_first_cell}>
                  <span>Name And Address Of Contractor</span>
                </td>
                <td className={classes.table_first_cell}>
                  <span>
                    {headerData.company && headerData.company.name
                      ? headerData.company.name
                      : "N/A"}
                  </span>
                </td>
              </tr>
              {headerData !== undefined &&
              headerData.childCompanyId &&
              headerData.childCompanyId.licenseNumber ? (
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>LIN&nbsp;</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.childCompanyId &&
                      headerData.childCompanyId.licenseNumber
                        ? headerData.childCompanyId.licenseNumber
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              ) : null}
              {headerData !== undefined &&
              headerData.childCompanyId &&
              headerData.childCompanyId.natureOfWork ? (
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>Nature Of Work</span>&nbsp;
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.childCompanyId &&
                      headerData.childCompanyId.natureOfWork
                        ? headerData.childCompanyId.natureOfWork
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              ) : null}
            </table>
          </div>
          <div
            style={window.screen.width < 460 ? { marginLeft: 15 } : {}}
            className={
              window.screen.width < 460 ? "col-4 colClass" : "col-6 colClass"
            }
          >
            <table
              width={
                window.screen.width < 460
                  ? window.screen.width
                  : window.screen.width / 4
              }
              className={`${classes.table_second} ${"finalReportSmallTable"}`}
            >
              <tr>
                <td className={classes.table_first_cell}>
                  <span className="text-gray-light">
                    NAME OF PRINCIPAL EMPLOYER
                  </span>
                </td>
                <td className={classes.table_first_cell}>
                  <span className="to">
                    {headerData.clientId && headerData.clientId.name
                      ? headerData.clientId.name
                      : "N/A"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className={classes.table_first_cell}>
                  <span className="address">CLIENTS CODE/LOCATION</span>
                </td>
                <td className={classes.table_first_cell}>
                  <span className="email">
                    {headerData.clientLocationId &&
                    headerData.clientLocationId.name
                      ? headerData.clientLocationId.name
                      : "N/A"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className={classes.table_first_cell}>
                  <span className="address">LOCATION ADDRESS</span>
                </td>
                <td className={classes.table_first_cell}>
                  <span className="email">
                    {headerData.clientLocationId &&
                    headerData.clientLocationId.clientLocationAddress
                      ? headerData.clientLocationId.clientLocationAddress
                      : "N/A"}
                  </span>
                </td>
              </tr>
              {responseData !== undefined &&
              responseData.filter &&
              responseData.filter.fromDate &&
              responseData.filter.toDate ? (
                <tr>
                  <td className={classes.table_first_cell}>
                    <span className="address">FOR THE MONTH OF</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span className="email">{`${new Date(
                      responseData.filter.fromDate
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                    })}  To  ${new Date(
                      responseData.filter.toDate
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} `}</span>
                  </td>
                </tr>
              ) : null}
            </table>
          </div>
        </div>
        {window.screen.width < 460 ? (
          <div style={{ marginTop: "15px" }}>
            <button
              className={tab1 ? classes.publicButton : ""}
              onClick={() => currentTab1()}
            >
              Present Sheet
            </button>
            <button
              className={!tab1 ? classes.publicButton : ""}
              onClick={() => currentTab2()}
            >
              Extra Duty Sheet
            </button>
          </div>
        ) : (
          <div style={{ marginTop: "15px" }}>
            <Button
              className={tab1 ? classes.publicButton : ""}
              component="span"
              onClick={() => currentTab1()}
            >
              Present Sheet
            </Button>
            <Button
              className={!tab1 ? classes.publicButton : ""}
              component="span"
              onClick={() => currentTab2()}
            >
              Extra Duty Sheet
            </Button>
          </div>
        )}
        <div
          className={classes.rtable}
          style={
            window.screen.width < 460
              ? {
                  width: window.screen.width >= 375 ? "140vh" : "150vh",
                  paddingRight: window.screen.width >= 375 ? 60 : 0,
                  maxWidth: window.screen.width >= 375 ? "140vh" : "150vh",
                  overflow: "scroll",
                  height:
                    window.screen.width < 460 ? "285px" : window.screen.height,
                }
              : { marginBottom: 60, width: "103%", overflowX: "hidden" }
          }
        >
          <TableContainer
            className={
              window.screen.width < 460
                ? undefined
                : classes.publicReportcontainer
            }
          >
            <Table className={classes.table} aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      left: 0,
                      position: "sticky",
                      width: "100px !important",
                      minWidth: "100px !important",
                      wordBreak: "break-word",
                      background: "#e6e6e6",
                      zIndex: open ? 0 : 999,
                      textAlign: "center",
                      flex: 1,
                    }}
                    className={classes.header}
                  >
                    Sr No.
                  </TableCell>
                  <TableCell
                    style={{
                      left: 99,
                      position: "sticky",
                      width: "100px !important",
                      minWidth: "100px !important",
                      wordBreak: "break-word",
                      background: "#e6e6e6",
                      textAlign: "center",
                      zIndex: open ? 0 : 999,
                    }}
                    className={classes.header}
                  >
                    EMP Id
                  </TableCell>
                  <TableCell
                    style={{
                      left: 199,
                      position: "sticky",
                      width: "100px !important",
                      minWidth: "100px !important",
                      wordBreak: "break-word",
                      background: "#e6e6e6",
                      zIndex: open ? 0 : 999,
                    }}
                    className={classes.header}
                  >
                    EMP Name
                  </TableCell>
                  <TableCell
                    style={{
                      left: 299,
                      position: "sticky",
                      width: "100px !important",
                      minWidth: "100px !important",
                      background: "#e6e6e6",
                      zIndex: open ? 0 : 999,
                    }}
                    className={classes.header}
                  >
                    Designation
                  </TableCell>
                  {responseData.list
                    ? responseData &&
                      responseData.datesData &&
                      responseData.datesData.map(
                        (date: string, index: number) => {
                          return (
                            <TableCell className={classes.header}>
                              {new Date(date).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                              })}
                            </TableCell>
                          );
                        }
                      )
                    : null}
                  {tab1 === true ? (
                    <Fragment>
                      <TableCell className={classes.header}>Absent</TableCell>
                      <TableCell className={classes.header}>Present</TableCell>
                      {/* <TableCell className={classes.header}>Paid Holiday</TableCell> */}
                      <TableCell className={classes.header}>Holiday</TableCell>
                      <TableCell className={classes.header}>Total</TableCell>
                    </Fragment>
                  ) : (
                    <TableCell className={classes.header}>
                      Total Extra Duty
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {responseData &&
                  responseData.list &&
                  responseData.list.map(
                    (employeeData: any, rowIndex: number) => {
                      return (
                        <TableRow>
                          <TableCell
                            style={{
                              left: 0,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              textAlign: "center",
                            }}
                            align="left"
                            className={classes.tableCell}
                          >
                            {rowIndex + 1}
                          </TableCell>
                          <TableCell
                            style={{
                              left: 99,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              textAlign: "center",
                              background: "#e6e6e6",
                            }}
                            align="left"
                            className={classes.tableCell}
                          >
                            {employeeData && employeeData.employeeCode}
                          </TableCell>
                          <TableCell
                            style={{
                              left: 199,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              textAlign: "center",
                            }}
                            align="left"
                            className={classes.tableCell}
                          >
                            {employeeData.employeeName}
                          </TableCell>
                          <TableCell
                            style={{
                              left: 299,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                            }}
                            align="left"
                            className={classes.tableCell}
                          >
                            {employeeData && employeeData.designationName
                              ? employeeData.designationName
                              : "N/A"}
                          </TableCell>
                          {responseData &&
                            responseData.datesData &&
                            responseData.datesData.map(
                              (date: string, index: number) => {
                                if (tab1 === true) {
                                  return (
                                    <TableCell
                                      align="left"
                                      className={classes.tableCell}
                                    >
                                      {employeeData[date]}{" "}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      align="left"
                                      className={classes.tableCell}
                                    >
                                      {employeeData[`${date}_overTime`]}{" "}
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                          {tab1 === true ? (
                            <Fragment>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData.absent}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData.present}
                              </TableCell>

                              {/* <TableCell align="left" className={classes.tableCell}>{employeeData.paid_holiday}</TableCell> */}
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData.holiday}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData.totalCount}
                              </TableCell>
                            </Fragment>
                          ) : (
                            <TableCell className={classes.tableCell}>
                              {employeeData.overTimeCount}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    }
                  )}
                {responseData &&
                  ((responseData.list && responseData.list.length === 0) ||
                    !responseData.list) && (
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.noRecordFound}
                        colSpan={responseData.datesData.length}
                      >
                        <b>No record found</b>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {headerData.item.isApproved === "Approved" ? (
          <div className="row">
            <div className={window.screen.width < 460 ? "col-4" : "col-6"}>
              <table
                style={{ marginBottom: window.screen.width < 460 ? 30 : 100 }}
                className={classes.table_first}
              >
                <tr>
                  <td>
                    <span>FOR OFFICE USE ONLY </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>PREPARED BY</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item.generatedBy &&
                      headerData.item.generatedBy.name
                        ? headerData.item.generatedBy.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>CHECKED AND AUTHORISED BY &nbsp;</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item &&
                      headerData.item.approvedby &&
                      headerData.item.approvedby.name
                        ? headerData.item.approvedby.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                {/* <tr>
                                <td className={classes.table_first_cell}>
                                    <span>DESIGNATION</span>
                                </td>
                                <td className={classes.table_first_cell}>
                                    <span>{headerData.item && headerData.item.designation && headerData.item.designation.name ? headerData.item.designation.name : "N/A"}</span>
                                </td>
                            </tr> */}
                {/* <tr>
                                <td className={classes.table_first_cell}>
                                    <span>ATTENDANCE ID </span>&nbsp;
                 </td>
                                <td className={classes.table_first_cell}>
                                    <span>{headerData && headerData.item && headerData.item.attendenceId ? headerData.item.attendenceId : "N/A"}</span>
                                </td>
                            </tr> */}
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>BRANCH </span>&nbsp;
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData &&
                      headerData.clientLocationId &&
                      headerData.clientLocationId.branchId &&
                      headerData.clientLocationId.branchId.name
                        ? headerData.clientLocationId.branchId.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div
              style={window.screen.width < 460 ? { marginLeft: 15 } : {}}
              className={
                window.screen.width < 460 ? "col-4 colClass" : "col-6 colClass"
              }
            >
              <table
                style={{ marginBottom: 100 }}
                className={classes.table_second}
              >
                <tr>
                  <td>
                    <span>CERTIFICATION BY CLIENT </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>
                      {`THIS ATTENDANCE IS APPROVED VIA MAIL ID: ${
                        headerData.clientLocationId &&
                        headerData.clientLocationId.clientAdminName
                          ? headerData.clientLocationId.clientAdminName
                          : ""
                      } [${
                        headerData.clientLocationId &&
                        headerData.clientLocationId.email
                          ? headerData.clientLocationId.email
                          : ""
                      }]   _${
                        headerData.clientLocationId &&
                        headerData.clientLocationId.designation
                          ? headerData.clientLocationId.designation
                          : ""
                      }, ${
                        headerData.item.approvedAt
                          ? helperFunc.commonDateFormat(
                              headerData.item.approvedAt,
                              "HH:mm:ss"
                            )
                          : "N/A"
                      }  - SUB: ATTENDANCE APPROVAL FOR ${new Date(
                        headerData.item.approvedAt
                      ).toLocaleDateString("en-GB", {
                        month: "short",
                      })}-${new Date(
                        headerData.item.approvedAt
                      ).toLocaleDateString("en-GB", { year: "numeric" })}_${
                        headerData.clientLocationId &&
                        headerData.clientLocationId.name
                          ? headerData.clientLocationId.name
                          : "N/A"
                      }_${
                        headerData.item && headerData.item.attendenceId
                          ? headerData.item.attendenceId
                          : ""
                      } `}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        ) : null}
        {headerData &&
        headerData.item &&
        headerData.item.isApproved === "Send_for_approval" ? (
          <div
            style={{ marginTop: 25 }}
            className={window.screen.width < 460 ? "" : "stickBottm"}
          >
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} lg={12} style={{ textAlign: "center" }}>
                <Button
                  style={{ color: "white" }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component="span"
                  disabled={loading}
                  onClick={() => handleClickApproval()}
                >
                  {loading && <CircularProgress size={20} thickness={2} />}
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={() => handleClickPopUp()}
                >
                  Ask For Rectification
                </Button>
              </Grid>
            </Grid>
            <div>
              <Dialog
                // fullWidth
                style={{ zIndex: 1 }}
                // onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
              >
                <DialogTitle>Send For Approval</DialogTitle>
                <DialogContent dividers className="overflowXHide">
                  <Box component="div" m={1} className={classes.flex}>
                    <Box component="div" m={1} className={classes.twoCol}>
                      <Form onSubmit={onSubmit}>
                        {(props: any) => (
                          <form onSubmit={props.handleSubmit}>
                            <TextInput source="rectificationText" multiline />
                            <Button
                              type="submit"
                              style={{ color: "white" }}
                              variant="contained"
                              color="primary"
                              disabled={saveLoading}
                              className={classes.buttonClass}
                              onSubmit={() => handleSubmit}
                            >
                              {saveLoading && (
                                <CircularProgress
                                  size={20}
                                  thickness={2}
                                  style={{ color: "aliceblue" }}
                                />
                              )}
                              SAVE
                            </Button>
                            <Button
                              type="button"
                              style={{ color: "blue" }}
                              variant="contained"
                              // color="primary"
                              className={classes.buttonClass}
                              onClick={() => setOpen(false)}
                            >
                              Close
                            </Button>
                          </form>
                        )}
                      </Form>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        ) : null}
        {/* </div> */}
      </div>
    </div>
  );
};
const ApprovapReport = () => {
  return (
    <div>
      <FinalReportEdit />
    </div>
  );
};

export default ApprovapReport;
