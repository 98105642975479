import * as React from "react";
import PropTypes from "prop-types";
import MobileNavBar from "../components/MobileNavBar";
import { withStyles } from "@material-ui/core/styles";

import { lightTheme } from "./themes";

import Footer from "./components/Footer";
import Header from "./components/Header";

import { Menu, MenuProps } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Button, useMediaQuery, Theme } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  name: {
    color: "#000",
    fontSize: "14px",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  root: {
    width: "100%",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Poppins",
    color: "#212529",
    fontSize: "16px",
    padding: "5px 10px",
    textTransform: "capitalize",
    fontWeight: "normal",
  },
  expandBox: {
    boxShadow: "none",
  },
}));

const LandingPageOne = () => {
  const classes = useStyles();
  var advantageboxRef1 = React.useRef(null);
  var container: any = null;

  const handleMouseEnter = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.add("active");
    container = null;
  };

  const handleMouseOut = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.remove("active");
    container = null;
  };

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      {/* <Header resource={Flag} /> */}

      {isSmall ? (
        <header className="header-area animated" style={{ marginTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12  d-flex align-items-center justify-content-between">
                <div className="logo-area scroll-hide-logo">
                  <a href="index.html">
                    <img
                      src="assets/img/Turnout-logo.png"
                      alt="logo"
                      className="img-fluid turnout-logo"
                    />
                  </a>

                  <div className="menu-area d-flex justify-content-end align-items-center">
                    {/* <div className="mobile-menu"></div> */}
                  </div>
                  <div style={{ position: "absolute", right: -8, top: -1 }}>
                    <Button
                      id="demo-positioned-button"
                      aria-controls="demo-positioned-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MenuIcon color="primary" />
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        {" "}
                        <Link className="dropdown-item " to="/">
                          HOME
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        {" "}
                        <Link className="dropdown-item" to="/turnout-system">
                          TURNOUT SYSTEM
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <div className={classes.root}>
                          <ExpansionPanel className={classes.expandBox}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Typography className={classes.heading}>
                                SOLUTION
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography>
                                <Link
                                  className="dropdown-item"
                                  to="/turnout-edge"
                                >
                                  Attendance Management
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/visitor-management"
                                >
                                  Visitor Management
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/access-control"
                                >
                                  Access Control
                                </Link>
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      </MenuItem>

                      <MenuItem onClick={handleClose}>
                        {" "}
                        <Link className="dropdown-item" to="/contact">
                          CONTACT
                        </Link>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="right-bar-icon ml-4 d-flex">
                  <Link to="/login" className="theme-btn btn-login-small ">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="header-area animated" style={{ marginTop: "-1px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="logo-area scroll-hide-logo">
                  <a href="index.html">
                    <img
                      src="assets/img/Turnout-logo.png"
                      alt="logo"
                      className="img-fluid turnout-logo"
                    />
                  </a>
                </div>

                {/* <div className="logo-area scroll-show-logo">
                <a href="index.html">
                  <img src="assets/img/Turnout-logo.png" alt="logo" />
                </a>
              </div> */}
                <div className="menu-area d-flex justify-content-end align-items-center">
                  <div className="mobile-menu"></div>

                  <div>
                    <div className="main-menu">
                      <nav className="menu-box">
                        <ul className="nav-menu" id="mobile-menu">
                          <li>
                            <NavLink activeClassName="active-nav-link" to="/">
                              Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              activeClassName="active-nav-link"
                              to="/turnout-system"
                            >
                              Turnout System
                            </NavLink>
                          </li>
                          <li>
                            <a
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              // variant="contained"
                              // color="primary"
                              style={{
                                color: "white",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              onClick={handleClick}
                            >
                              SOLUTION
                              <ExpandMoreIcon />
                            </a>

                            <StyledMenu
                              id="customized-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              // className="dropdown"
                              style={{ padding: 0, top: 2 }}
                            >
                              {/* <StyledMenuItem> */}
                              {/* <ListItemIcon> */}
                              <Link
                                className="dropdown-item"
                                to="/turnout-edge"
                              >
                                Attendance Management
                              </Link>
                              {/* </ListItemIcon> */}
                              {/* <ListItemText primary="Sent mail" /> */}
                              {/* </StyledMenuItem> */}
                              {/* <StyledMenuItem> */}
                              <Link
                                className="dropdown-item"
                                to="/visitor-management"
                              >
                                Visitor Management
                              </Link>
                              {/* </StyledMenuItem> */}
                              {/* <StyledMenuItem> */}
                              <NavLink
                                activeClassName="active-nav-link"
                                className="dropdown-item"
                                to="/access-control"
                              >
                                Access Control
                              </NavLink>
                              {/* </StyledMenuItem> */}
                            </StyledMenu>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className="right-bar-icon ml-4 d-flex">
                    <Link to="/login" className="theme-btn btn-login ">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}

      <section className="hero-section">
        <div className="hero-shape1">
          <img src="assets/img/hero-pattarn-1.png" alt="shape" />
        </div>
        <div className="hero-shape2">
          <img src="assets/img/hero-pattarn-2.png" alt="shape" />
        </div>
        <div className="hero-shape3">
          <img src="assets/img/hero-pattarn-3.png" alt="shape" />
        </div>
        <div className="hero-shape4">
          <img src="assets/img/hero-pattarn-4.png" alt="shape" />
        </div>
        <div className="hero-shape5">
          <img src="assets/img/her-pattarn-5.png" alt="shape" />
        </div>
        <div className="hero-bg">
          <img src="assets/img/hero_shape.png" alt="hero shape" />
        </div>

        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="hero-left">
                <h2>
                  <span className="underline">Face Recognition </span>{" "}
                  <span>Based Attendance, Visitor management</span> and Access
                  Control System
                </h2>
                <ul className="footer-widget__list text-center">
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.rydotinfotech.turnout"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="image_btn mr-md-2 mr-2"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/google-play.jpg"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://apps.apple.com/us/app/turnout-attendance-system/id1535149142"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="image_btn"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/app-store.jpg"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="hero-right">
                <video key="assets/img/main.mp4" loop autoPlay>
                  <source src="assets/img/main.mp4" type="video/mp4" />
                </video>
                <div className="hero-dot-shape">
                  <img src="assets/img/hero-dot-shape.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-section companies-section section_100 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 covid-19-solution order-lg-1 order-2">
              <video key="assets/img/main.mp4" loop autoPlay>
                <source src="assets/img/COVID-19.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="col-lg-7 order-lg-2 order-1">
              <div className="hero-left">
                <h2>
                  <span className="underline"> AI Based </span>{" "}
                  <span> Solution Against COVID-19</span>
                </h2>
                <p>
                  Turnout is an AI based touch-free HRM system using facial
                  recognition technique to mark person's attendance. past the
                  covid-19 pandemic, biometrics is now a thing of the past as it
                  is considered a critical transmitter of viruses and bacteria.
                  and the traditional way of manually entering attendance is
                  usually prone to human error and manipulation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="touchless video-section section_100">
        <div className="video-bg-shape"></div>
        <div className="video-pattern">
          <img src="assets/img/her-pattarn-5.png" alt="video patter" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h2>
                  Touchless Solution In <br />{" "}
                  <span> Covid - 19 Situation </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container covid-container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 highlights-cards-section">
              <div className="highlights-card">
                <div className="highlights-card-img-section mb-5">
                  <img alt="" src="assets/img/Biometric.svg" />
                </div>
                <div className="highlights-card-content">
                  <h2>Biometric</h2>
                </div>
                <div className="close-icon">
                  <img alt="" src="assets/img/close.svg" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 highlights-cards-section">
              <div className="highlights-card highlights-card-center">
                <div className="highlights-card-img-section mb-5">
                  <img alt="" src="assets/img/Attendance.svg" />
                </div>
                <div className="highlights-card-content highlights-card-content-white">
                  <h2>Turnout Attendance System</h2>
                </div>
                <div className="close-icon">
                  <img alt="" src="assets/img/check.svg" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 highlights-cards-section">
              <div className="highlights-card">
                <div className="highlights-card-img-section mb-5">
                  <img alt="" src="assets/img/Manual-Entries.svg" />
                </div>
                <div className="highlights-card-content">
                  <h2>Manual Entries</h2>
                </div>
                <div className="close-icon">
                  <img alt="" src="assets/img/close.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="choose-section section_100">
        <div className="choose-shape-bg">
          <img src="assets/img/choose-shape.png" alt="choose" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="choose-left text-center wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div className="section-title">
                  <h2>
                    Turnout <span>EcoSystem</span> <br />
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <img
                src="assets/img/turnout-diagram.png"
                className="img-fluid"
                alt=""
                width="60%"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="service-section section_100 ">
        <div className="container">
          <div className="row align-items-center section_100">
            <div className="col-lg-6">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="hero-left">
                  <h2>
                    <span className="underline"> Face Recognition Based </span>{" "}
                    <span> Access Control</span>
                  </h2>
                  <p>
                    The purpose of access control is to grant entrance to a
                    highly confidential office space only to the authorized
                    peoples. Turnout helps in that with facial recognition based
                    authorization
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-right wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <video key="assets/img/main.mp4" loop autoPlay>
                  <source
                    src="assets/img/Face-Recognition.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>

          <div className="row align-items-center section_100">
            <div className="col-lg-6 order-lg-1 order-2">
              <div
                className="about-right wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <video key="assets/img/main.mp4" loop autoPlay>
                  <source
                    src="assets/img/Vehicle-access.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="hero-left">
                  <h2>
                    <span className="underline"> Vehicle Access Control </span>{" "}
                    <span></span>
                  </h2>
                  <p>
                    RFID Vehicle Access Control System is compatible with
                    multi-protocol and has a well-integrated design. This
                    water-proof device not only supports quick and multi-tag but
                    additionally has circular polarization without directional
                    limit for the tags. It is a Passive RFID card/tag reader
                    with integrated antenna and weather proof design applied in
                    outdoor. This device has many utilizations including person
                    tracking systems, vehicle tracking systems etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-section section_100 about-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 mb-5">
              <div
                className="about-left text-center wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="hero-left">
                  <h2>
                    <span className="underline"> Face Recognition Based </span>{" "}
                    <br />
                    Visitor Management System<span></span>
                  </h2>
                  <p>
                    In any organization visitors management and their resource
                    access is very crucial for security and collaborations
                    prospective. During this pandemic situation employees and
                    workplace protection is also the most important aspect for
                    every organization. To fulfill this need touchless visitors
                    and employees management will be the future of modern
                    organizations. With the Turnout both employees and visitors
                    can check-in safely, reducing the risk of infections.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div
                className="wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img src="assets/img/Visitor.png" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="features-section mobile-feature">
        <div className="container features-container">
          <div className="row mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="choose-left text-center wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div className="section-title">
                  <h2>
                    {" "}
                    <span>Core Features</span> <br />
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 features-content-section">
              <div className="col features-card">
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Access-Control.svg" />
                </div>
                <div className="features-card-content-section">
                  <h3>Access Control, Visitor, HRM Attendance Management</h3>
                </div>
              </div>

              <div className="col features-card">
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Deep-Learning.svg" />
                </div>
                <div className="features-card-content-section">
                  <h3>Deep Learning Based Computer Vision</h3>
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Secure-Cloud.svg" />
                </div>
                <div className="features-card-content-section">
                  <h3>Secure Cloud Storage</h3>
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/On-Premise.svg" />
                </div>
                <div className="features-card-content-section">
                  <h3>On-Premise And Remote Workplace Attendance Management</h3>
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/back-in-time.svg" />
                </div>
                <div className="features-card-content-section">
                  <h3>Time saving and reduced contagion</h3>
                </div>
              </div>
            </div>

            <div className="col-lg col-md-6 features-header-img-section text-center">
              <img alt="" src="assets/img/features.png" className="img-fluid" />
            </div>

            <div className="col-lg-4 col-md-12 features-content-section">
              <div className="col features-card">
                <div className="features-card-content-section">
                  <h3>Face recognition</h3>
                </div>
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Face-recognition.svg" />
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-content-section">
                  <h3>Pay As You Use Pricing Model</h3>
                </div>
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Pay-As.svg" />
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-content-section">
                  <h3>Easy Integration With Existing HRMS</h3>
                </div>
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Easy-Integration.svg" />
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-content-section">
                  <h3>Easy Integration With Surveillance System</h3>
                </div>
                <div className="features-card-img-section">
                  <img
                    alt=""
                    src="assets/img/features/Surveillance-System.svg"
                  />
                </div>
              </div>
              <div className="col features-card">
                <div className="features-card-content-section">
                  <h3>Automated Alerts, Reports and Analytics</h3>
                </div>
                <div className="features-card-img-section">
                  <img alt="" src="assets/img/features/Automated-Alerts.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-section service-section section_100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 text-center">
              <div
                className="section-title wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h2>
                  {" "}
                  <span>Advantages</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg col-md-6">
              <div
                className="service-box wow fadeInUp"
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("advantage1")}
                onMouseOut={() => handleMouseOut("advantage1")}
                data-wow-duration="1s"
                id="advantage1"
                data-wow-delay="0.2s"
              >
                <div className="service-icon">
                  <i className="flaticon-devices"></i>
                </div>
                <div className="service-title">
                  <h3>Mobile & Web</h3>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="service-box wow fadeInUp"
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("advantage2")}
                onMouseOut={() => handleMouseOut("advantage2")}
                data-wow-duration="1s"
                id="advantage2"
                data-wow-delay="0.3s"
              >
                <div className="service-icon">
                  <i className="flaticon-online-transfer"></i>
                </div>
                <div className="service-title">
                  <h3>Online & Offline Data Sync</h3>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="service-box wow fadeInUp"
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("advantage3")}
                onMouseOut={() => handleMouseOut("advantage3")}
                data-wow-duration="1s"
                id="advantage3"
                data-wow-delay="0.4s"
              >
                <div className="service-icon">
                  <i className="flaticon-cloud-network"></i>
                </div>
                <div className="service-title">
                  <h3>Cloud Based Instant Updates</h3>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="service-box wow fadeInUp"
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("advantage4")}
                onMouseOut={() => handleMouseOut("advantage4")}
                data-wow-duration="1s"
                id="advantage4"
                data-wow-delay="0.5s"
              >
                <div className="service-icon">
                  <i className="flaticon-secure-shield"></i>
                </div>
                <div className="service-title">
                  <h3>100% Secure Mobile Platform</h3>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="service-box wow fadeInUp"
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("advantage5")}
                onMouseOut={() => handleMouseOut("advantage5")}
                data-wow-duration="1s"
                id="advantage5"
                data-wow-delay="0.6s"
              >
                <div className="service-icon">
                  <i className="flaticon-feature"></i>
                </div>
                <div className="service-title">
                  <h3>Customized Branding</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about-section">

                <div className="about-top-shape">
                    <img src="assets/img/about-shape.png" alt="about shape" />
                </div>
                <div className="about-bottom-shape">
                    <img src="assets/img/about-shape.png" alt="about shape" />
                </div>
                <div className="about-top section_100 tabturnout">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-left wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="pill" href="#home">
                                                <div className="number-1">1</div>
                                                <div className="tab-title">Employee Attendance</div>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="pill" href="#menu1">
                                                <div className="number-1">2</div>
                                                <div className="tab-title">Visitor Management</div>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="pill" href="#menu2">
                                                <div className="number-1">3</div>
                                                <div className="tab-title">Constrained Access Control</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="tab-content">
                                    <div id="home" className="container tab-pane active"><br />
                                        <div className="about-right mb-4  fadeInRight">
                                            <img src="assets/img/Employee-Attendance.png" alt="about" />
                                        </div>
                                        <p className="text-center">Turnout helps to beat employee attendance problem by transforming your
                                        workplace with
                           touch-less Facial Recognition for on-premises and mobile workforce.</p>
                                        </div>
                                        <div id="menu1" className="container tab-pane fade"><br />
                                            <div className="about-right mb-4  fadeInRight">
                                                <img src="assets/img/Visitor-Management.png" alt="about" />
                                            </div>
                                            <p className="text-center">Turnout provides touch-less visitors management which restricts the
                           resources accessed by visitors along with tracking of on-premises visitors.</p>
                                            </div>
                                            <div id="menu2" className="container tab-pane fade"><br />
                                                <div className="about-right mb-4  fadeInRight">
                                                    <img src="assets/img/Access-Control.png" alt="about" />
                                                </div>
                                                <p className="text-center">Turnout helps to restricts the access of highly confidential resources to
                           limited peoples and <br />Provide registered vehicle access by number plate.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </section> */}

      <section
        id="works"
        className="works services-area services-bg section_100"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 text-center">
              <div
                className="section-title wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h2>
                  {" "}
                  <span>How it Works?</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-12 mb-30 hover">
              <div
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("id1")}
                onMouseOut={() => handleMouseOut("id1")}
                className="s-single-services text-center"
                id="id1"
              >
                <div className="services-icon">
                  <img alt="" src="assets/img/Works1.svg" />
                </div>
                <div className="second-services-content">
                  <h5>Person Detail Registration</h5>
                  <p>
                    A company admin creates the person’s record by filling
                    his/her details
                  </p>
                  <a>
                    <span>1</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 mb-30">
              <div
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("id2")}
                onMouseOut={() => handleMouseOut("id2")}
                className="s-single-services text-center "
                id="id2"
              >
                <div className="services-icon">
                  <img
                    alt=""
                    src="assets/img/Works2.svg"
                    style={{ marginTop: "-4px" }}
                  />
                </div>
                <div className="second-services-content">
                  <h5>Facial Features Registration</h5>
                  <p>
                    Device captures person's facial data from live video or
                    saved local video.
                  </p>
                  <a>
                    <span>2</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 mb-30">
              <div
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("id3")}
                onMouseOut={() => handleMouseOut("id3")}
                className="s-single-services text-center "
                id="id3"
              >
                <div className="services-icon">
                  <img
                    alt=""
                    src="assets/img/Works3.svg"
                    style={{ marginTop: "-4px" }}
                  />
                </div>
                <div className="second-services-content">
                  <h5>Facial Recognition</h5>
                  <p>
                    Device scans and compares the face against the stored
                    photos.
                  </p>
                  <a>
                    <span>3</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 mb-30">
              <div
                ref={advantageboxRef1}
                onMouseOver={() => handleMouseEnter("id4")}
                onMouseOut={() => handleMouseOut("id4")}
                className="s-single-services text-center "
                id="id4"
              >
                <div className="services-icon">
                  <img alt="" src="assets/img/Works4.svg" />
                </div>
                <div className="second-services-content">
                  <h5>Capture Real Time Attendance</h5>
                  <p>
                    Capture real-time attendance data on cloud and sync it with
                    different HRM functionalities.
                  </p>
                  <a>
                    <span>4</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="about-top-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src="assets/img/about-shape.png" alt="about shape" />
        </div>
        <div className="about-top section_100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div
                  className="section-title wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h2>
                    {" "}
                    <span>Industry We Serve</span>{" "}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 wow move-up  wow slideInUp">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Healthcare-01-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Healthcare</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Real Estate-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Real Estate</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/surface1.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Manufacturing</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Hospitality-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Hospitality</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Insurance-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Insurance</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Retail-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Retail</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Agency-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Agency</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Textile-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Textile</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Finance-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Finance</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow move-up  wow slideInUp"
                      // style="visibility: visible; animation-name: slideInUp;"
                    >
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src="assets/img/industry/Logistics-01.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="heading">Logistics</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

LandingPageOne.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LandingPageOneWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <LandingPageOne {...props} />
  </ThemeProvider>
);

export default LandingPageOneWithTheme;
