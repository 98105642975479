import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "../unknownAttendance/commonForm";
import { PostEditToolbar } from '../components/PostEditToolbar';

const AttendanceEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Unknown Attendance"} {...props}>
      <CommonForm 
      toolbar={<PostEditToolbar />}
       {...props} />
    </Edit>
  );
};


export default AttendanceEdit;
