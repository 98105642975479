import LeaveList from "./LeaveList";
import LeaveEdit from "./LeaveEdit";
import LeaveCreate from "./LeaveCreate";
import { LeaveTypeIcon } from "../svgIcon";

export default {
  list: LeaveList,
  create: LeaveCreate,
  edit: LeaveEdit,
  icon: LeaveTypeIcon,
};
