import DeleteEmployeeList from "./deleteEmployeeList";
// import { CommonCourse } from "./courseCommon";
import { CourseIcon } from "../svgIcon";

export default {
  list: DeleteEmployeeList,
  // create: CommonCourse,
  // edit: CommonCourse,
  // icon: CourseIcon,
};
