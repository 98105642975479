import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "../attendance/commonForm";
import { PostEditToolbar } from "../components/PostEditToolbar";

const AttendanceEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Attendance"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};

export default AttendanceEdit;
