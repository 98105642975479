import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Labeled, EditButton } from "react-admin";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FieldProps, Attendance } from "../types";
import helperFunc from "../helperFuncs";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  redButton: {
    color: "red",
  },
  flex: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    flexDirection: "row",
  },
  flexChild: {
    display: "inline-block",
    width: "100%",
    padding: "0 15px",
    maxWidth: "100%",
  },
  twoCol: {
    display: "contents",
    width: "50%",
    overflow: "hidden",
    maxWidth: "50%",
  },
  twoColChild: { display: "inline-block", width: "100%", maxWidth: "50%" },
  blueButton: {
    color: "blue",
  },
  typeclass: {
    alignItems: "center",
  },
  lblName: {
    right: theme.spacing(0),
  },
  lblLocation: {
    right: theme.spacing(0),
  },
  lblDeLocation: {
    left: theme.spacing(1),
  },
  maskClass: {
    left: theme.spacing(0),
    right: theme.spacing(0),
  },
  overflowXHide: { overflowX: "hidden" },
  overflowYHide: { overflowY: "initial" },
  lblDate: {
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  mode: {
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  edit: {
    float: "right",
  },
}));

interface State {
  images?: string;
}

const AttandanceShow: FC<FieldProps<Attendance>> = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  // const DialogActions = withStyles((theme) => ({
  //     root: {
  //         margin: 0,
  //         padding: theme.spacing(1),
  //     },
  // }))(MuiDialogActions);
  const mode: any = {
    mobile: "Mobile",
    edge: "Edge",
    manual: "Manual",
    cron: "Auto",
    auto: "Auto",
    Kiosk: "Kiosk",
    "kiosk-QR": "Kiosk-QR",
    "turnout-A": "Turnout A",
  };
  const location: any = {
    in: "In",
    out: "Out",
  };
  // const [state, setState] = useState<State>({});
  // const dataProvider = useDataProvider();
  // const version = useVersion();
  // const fetchCardsCount = useCallback(
  //   async (record: any) => {

  //     const cardsCount = await dataProvider
  //       .callCustomLocalOne("public/attendence-image/" + record._id, {})
  //       .then((response: any) => {
  //         setState((state) => ({
  //           ...state,
  //           images:
  //             response &&
  //             response.data &&
  //             response.data.item &&
  //             response.data.item.image,
  //         }));

  //
  //       });

  //     setState((state) => ({ ...state }));
  //   },
  //   [dataProvider]
  // );

  // useEffect(() => {
  //   fetchCardsCount(record);
  // }, [record]); // eslint-disable-line react-hooks/exhaustive-deps
  // const { images } = state;

  return record ? (
    <div className={classes.root}>
      <IconButton aria-label="Show">
        <VisibilityIcon
          className={
            record && record.employee && record.employee.type === "visitor"
              ? classes.redButton
              : classes.blueButton
          }
          onClick={handleClick}
        />
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          classes={classes}
          onClose={handleClose}
        >
          Attendance Detail
        </DialogTitle>

        <DialogContent dividers className={classes.overflowYHide}>
          <Box component="div" m={1} className={classes.flex}>
            <Box component="div" m={1} className={classes.twoCol}>
              <img
                style={{
                  alignItems: "center",
                  height: "230px",
                  width: "500px",
                }}
                src={
                  record.image
                    ? uploadApiUrl + record.image
                    : record?.mode === "kiosk-QR"
                    ? "assets/img/QRCodeSample.jpg"
                    : "assets/img/emptyUser.png"
                }
                alt={record.image}
              />
            </Box>
            <Box component="div" m={1} className={classes.twoCol}>
              <Box component="div" m={1} className={classes.flexChild}>
                <Labeled label="Name" className={classes.twoColChild}>
                  <Typography component="div" id="name" align="left">
                    {record && record.employee && record.employee.name}
                  </Typography>
                </Labeled>
                <Labeled label="Date Time" className={classes.twoColChild}>
                  <Typography component="div" id="time" align="left">
                    {helperFunc.commonDateFormat(
                      record && record.time,
                      "HH:mm:ss"
                    )}
                    {/* {helperFunc.localDateAndTime(record && record.time)} */}
                  </Typography>
                </Labeled>

                <Labeled label="Entry" className={classes.twoColChild}>
                  <Typography component="div" id="location">
                    {record && location[record.authType]}
                  </Typography>
                </Labeled>

                <Labeled label="Mode" className={classes.twoColChild}>
                  <Typography component="div" id="time">
                    {record && mode[record.mode]}
                  </Typography>
                </Labeled>
                {/* <Labeled label="Type" aria-label="right">
                            <Typography component="div" id="name" aria-label="right">
                                {record && record.employee && record.employee.type}
                            </Typography>
                        </Labeled> */}

                {/* <Labeled label="Confidence">
                            <Typography component="div" id="name">
                                {record &&
                                    record.confidence && record.confidence !== "" ?
                                    parseFloat(record.confidence).toFixed(3) : "N/A"}
                            </Typography>
                        </Labeled> */}
                {/* <Labeled label="Mask" className={classes.twoColChild}>
                                <Typography component="div" id="mask" align="left">
                                    {user.clientDetail && !user.clientDetail.isMask
                                        ? "Not subscribed"
                                        : record.isMask
                                            ? "Yes"
                                            : "No"}
                                </Typography>
                            </Labeled> */}
                {record.childCompanyId && (
                  <Labeled label="Company" className={classes.twoColChild}>
                    <Typography component="div" id="childCompanyId">
                      {record &&
                        record.childCompanyId &&
                        record.childCompanyId.name}
                    </Typography>
                  </Labeled>
                )}
                {record.clientId && (
                  <Labeled label="Client" className={classes.twoColChild}>
                    <Typography component="div" id="clientId">
                      {record && record.clientId && record.clientId.name}
                    </Typography>
                  </Labeled>
                )}
                {record.clientLocationId && (
                  <Labeled
                    label="Client Location"
                    className={classes.twoColChild}
                  >
                    <Typography component="div" id="clientLocationId">
                      {record &&
                        record.clientLocationId &&
                        record.clientLocationId.name}
                    </Typography>
                  </Labeled>
                )}
                {record.workplaceId && (
                  <Labeled label="Workplace" className={classes.twoColChild}>
                    <Typography component="div" id="workplaceId">
                      {record && record.workplaceId && record.workplaceId.name}
                    </Typography>
                  </Labeled>
                )}
                {record.shiftData && record.shiftData.name && (
                  <Labeled label="Shift Name" className={classes.twoColChild}>
                    <Typography component="div" id="shiftName">
                      {record && record.shiftData && record.shiftData.name}
                    </Typography>
                  </Labeled>
                )}
                {record.shiftData && record.shiftData.timeOfReporting && (
                  <Labeled
                    label="Time Of Reporting"
                    className={classes.twoColChild}
                  >
                    <Typography component="div" id="timeOfReporting">
                      {record &&
                        record.shiftData &&
                        record.shiftData.timeOfReporting}
                    </Typography>
                  </Labeled>
                )}
                {record.shiftData && record.shiftData.inTime && (
                  <Labeled label="In TIme" className={classes.twoColChild}>
                    <Typography component="div" id="inTime">
                      {record && record.shiftData && record.shiftData.inTime}
                    </Typography>
                  </Labeled>
                )}

                {record.shiftData && record.shiftData.outTime && (
                  <Labeled label="Out TIme" className={classes.twoColChild}>
                    <Typography component="div" id="outTime">
                      {record && record.shiftData && record.shiftData.outTime}
                    </Typography>
                  </Labeled>
                )}
                {record?.employee?.type && (
                  <Labeled label="Type" className={classes.twoColChild}>
                    <Typography
                      component="div"
                      id="outTime"
                      style={{ textTransform: "capitalize" }}
                    >
                      {record?.employeeType?.name
                        ? record?.employeeType?.name
                        : record?.employee?.type}
                    </Typography>
                  </Labeled>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export default AttandanceShow;
