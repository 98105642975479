import * as React from "react";

import { BottomNavigation, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    left: 0,
    bottom: 0,
    fontSize: "13px",

    backgroundColor: "#424242",

    color: "#ffffff",
    height: "20px",
    padding: "7px 0px",
    // bottom: 0,
    top: "auto",
    // position: "fixed",
    zIndex: 1100,
    width: "100%",
  },
  spacer: {
    flex: 1,
  },
  copyright: {
    fontSize: "13px",
  },
}));

const AppFooterBar = (props: any) => {
  const classes = useStyles();
  return (
    <BottomNavigation className={classes.root}>
      <Typography
        component="div"
        id="footer-copyright"
        className={classes.copyright}
        align="left"
      >
        Copyright ©{new Date().getFullYear()} All Rights Reserved | Designed And
        Developed By Turnout
      </Typography>
      <p>&nbsp;</p>
      <Typography
        component="div"
        id="footer-branding"
        align="right"
      ></Typography>
    </BottomNavigation>
  );
};

export default AppFooterBar;
