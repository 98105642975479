import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { departmentstyles } from "../helperStyle";
import { SelectInput } from "../common";
import helperFunc from "../helperFuncs";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(departmentstyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/departments/create"
          ? "Update Department"
          : "Add Department"}
      </h2>
      <SimpleForm {...props}>
        {/* <SectionTitle label="resources.departments.page.edit" /> */}
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <SelectInput />
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Document Type Name"
          />
          <TextInput
            source="code"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          label="label.status"
          source="isActive"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.departments.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>

      <FormDataConsumer formClassName={isSmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <SelectInput
            filter={{ isActive: true }}
            label="Category"
            apiName="document-category"
            source="documentCategoryId"
          ></SelectInput>
        )}
      </FormDataConsumer>

      <SectionTitle label="" />

      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Document Type Name"
      />
      <TextInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        label="label.status"
        source="isActive"
        defaultValue={true}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [required(), maxLength(20)];

const requiredValidate = [required()];

export default CommonForm;
