import React from "react";
import { TextInput, required } from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";

type Props = {};

const useStyles = makeStyles({
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
});

const OpenId = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <TextInput
        label="URL"
        source="smsConfig.url"
        validate={requiredValidate}
        className={classes.code}
      />

      <div>
        <TextInput
          label="Server Redirect URL"
          source="smsConfig.url"
          validate={requiredValidate}
          className={classes.name}
        />
        <TextInput
          label="Client Redirect URL"
          source="smsConfig.url"
          validate={requiredValidate}
          className={classes.code}
        />
      </div>
      <div>
        <TextInput
          label="Client Id"
          source="smsConfig.url"
          validate={requiredValidate}
          className={classes.name}
        />
        <TextInput
          label="Client Secret"
          source="smsConfig.url"
          validate={requiredValidate}
          className={classes.code}
        />
      </div>
    </>
  );
};

const requiredValidate = [required()];

export default OpenId;
