import { AttendanceIcon } from "../svgIcon";
import EmergencyList from "./EmergencyList";
import { EmergencyCommon } from "./emergencyCommon";

export default {
  list: EmergencyList,
  create: EmergencyCommon,
  edit: EmergencyCommon,
  icon: AttendanceIcon,
};
