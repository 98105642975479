import * as React from "react";
import { FC, memo, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  workplace?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  emptyValue?: any;
  validate?: any;
  dataManage?: any;
  helperText?: boolean;
  labelName?: string;
}

const WorkplaceIdSelectInput: FC<Props> = ({
  helperText,
  filter,
  emptyValue,
  validate,
  labelName,
  dataManage,
}) => {
  const form = useForm();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("workplaces/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          workplace: response && response.data,
        }));
      });
  }, [filter]);

  const { workplace } = state;

  const check = (value: any) => {
    workplace?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "workplace");
        }
      }
    });
  };

  if (emptyValue && emptyValue.emptyValue) {
    form.change("workplaceId", undefined);
    form.change("hiddenVal", "valueChange");
  }
  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Workplaces"}
      className={classes.root}
      source="workplaceId"
      validate={validate}
      choices={workplace}
      helperText={helperText}
      onChange={(value: any) => check(value.target)}
    />
  );
};
WorkplaceIdSelectInput.defaultProps = {
  source: "workplace",
  label: "resources.customers.fields.name",
};

export default memo<Props>(WorkplaceIdSelectInput);
