import FacultyList from "./facultyList";
import { CommonFaculty } from "./facultyCommon";
import { FacultyIcon } from "../svgIcon";

export default {
  list: FacultyList,
  create: CommonFaculty,
  edit: CommonFaculty,
  icon: FacultyIcon,
};
