import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  BooleanInput,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CountryStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { SmFormHeader } from "../components/SmFormHeader";

const useStyles = makeStyles(CountryStyle);
const CommonForm = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <>
      {isSmall ? <SmFormHeader /> : ""}
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <div className={isSmall ? classes.divInline : ""}>
          <TextInput
            autoFocus
            label="Remarks"
            source="name"
            formClassName={isSmall ? classes.smallName : classes.name}
            validate={nameValidate}
          />
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
