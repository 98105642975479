import * as React from "react";
import { FC } from "react";
import { Card, Typography, useMediaQuery, Theme } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import { Customer, Order, Event } from "../../../types";
import { listUpcomingEventsStyle } from "../../../helperStyle";
import helperFunc from "../../../helperFuncs";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  events?: Event[];
  isLoading?: boolean;
}

const useStyles = makeStyles(listUpcomingEventsStyle);

const ListUpcomingEvents: FC<Props> = ({ events = [], isLoading }) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  const gender: any = "";
  return (
    <Card
      className={
        isXSmall
          ? classes.mobileRoot
          : isSmall
          ? classes.smallRoot
          : classes.root
      }
    >
      <Typography component="h6" className={classes.title}>
        {translate("pos.dashboard.table_events")}
      </Typography>
      {isLoading ? (
        <span
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <List dense={true} className={events.length > 0 ? classes.list : ""}>
          {events && events.length > 0 ? (
            events.map((record) => (
              <ListItem
                key={record._id}
                button
                // component={Link}
                // to={`/employees?filter=%7B%3A%7D&order=ASC&page=1&perPage=20&sort=name`}
              >
                <ListItemText primary={record.name} secondary={record.type} />
                <ListItemSecondaryAction>
                  <span className={classes.cost}>
                    {record?.date && helperFunc.commonDateFormat(record.date)}
                  </span>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <h4
              style={{
                height: "200px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              No Events Found
            </h4>
          )}
        </List>
      )}
      {/* {events && events.length > 500000 ?
        <CardActions style={{ justifyContent: "flex-end"}}>
          
          <Button
            size="small"
            color="primary"
            component={Link}
            to={{
              pathname: "employees",
            }}
            className={classes.link}
            title={`${translate("pos.dashboard.read_more")}`}
          >
            <CompanyIcon style={{ paddingRight: "0.5em" }} />
            {translate("pos.dashboard.read_more")}
          </Button>
        </CardActions> : null} */}
    </Card>
  );
};

export default ListUpcomingEvents;
