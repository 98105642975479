import * as React from "react";
import { FC } from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { Loading } from "react-admin";
import { DatagridProps, Visitor } from "../types";
import AttendanceLogo from "./AttendanceLogo";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import MapShow from "./MapShow";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-2px",
  },
  gridList: {
    width: "100%",
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  icon: {
    color: 'white',
  }
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 6;
};

// const times = (nbChildren: number, fn: (key: number) => any) => {

//   return Array.from({ length: nbChildren }, (_, key) => fn(key));
// }

// const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
//   width,
//   nbItems = 20,
// }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <MuiGridList
//         cellHeight={180}
//         cols={getColsForWidth(width)}
//         className={classes.gridList}
//       >
//         {" "}
//         {times(nbItems, (key) => (
//           <GridListTile key={key}>
//             <div className={classes.placeholder} />
//           </GridListTile>
//         ))}
//       </MuiGridList>
//     </div>
//   );
// };

const LoadedGridList: FC<GridProps> = ({ selectedRow, width }) => {
  const location: any = {
    in: "In",
    out: "Out",
  }
  const classes = useStyles();

  if (!selectedRow) return null;

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {selectedRow.length !== 0 ? selectedRow !== null && selectedRow.map((id: any) => (
          <GridListTile
            // @ts-ignore
            style={{ minWidth: "50%", maxwidth: "100%", height: "184px" }}
          >
            <AttendanceLogo record={id}> </AttendanceLogo>

            <GridListTileBar
              className={classes.tileBar}
              title={id && id.employee && id.employee.name}
              subtitle={id && id.time && `${location[id.authType]} ${'Time'} : ${helperFunc.commonDateFormat(id && id.time, "hh:mm:ss")}`}
              actionIcon={id && id.lat ? <MapShow record={id} /> : null}
            />

          </GridListTile>
        )) : "No Record Found"}
      </MuiGridList>
    </div>
  );
};

interface GridProps extends DatagridProps<Visitor>, WithWidth {
  selectedRow?: any;
}



const GridList: FC<GridProps> = ({ ...props }) => {
  return props.selectedRow ? <LoadedGridList {...props} /> : <Loading />;
}


export default withWidth()(GridList);
