import React, { useState, useEffect } from "react";
import { useDataProvider, FormDataConsumer } from "react-admin";
import {
  Box,
  Button,
  useMediaQuery,
  Theme,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-final-form";
import { assignTimeslotShowStyle } from "../helperStyle";
import { toastError } from "../components/toast";
import { SmFormHeader } from "../components/SmFormHeader";
import { AddDialogueBox } from "./dialogueBox";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FacultywiseInput from "../components/FacultywiseInput";

const FacultyTimetable = (props: any): any => {
  const [timeslotData, setTimeslotData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoad, setLoader] = useState(false);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(assignTimeslotShowStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const headerArr = [
    { label: "Monday", key: "monday" },
    { label: "Tuesday", key: "tuesday" },
    { label: "Wednesday", key: "wednesday" },
    { label: "Thursday", key: "thursday" },
    { label: "Friday", key: "friday" },
    { label: "Saturday", key: "saturday" },
    { label: "Sunday", key: "sunday" },
  ];
  let container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Faculty Timetable";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const handleAddDialogClose = () => {
    setDialogOpen(false);
  };

  const onSubmit = (formdata: any) => {
    if (!formdata.faculty) {
      toastError("Please Select Faculty");
    } else {
      setLoader(true);
      const resourcePath = `faculty-timetable/timetable`;
      dataProvider
        .callCustom(resourcePath, {
          faculty: formdata && formdata.faculty ? formdata.faculty : null,
        })
        .then((response: any) => {
          setLoader(false);
          setTimeslotData(response && response.data && response.data.list);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <SmFormHeader {...props} />
      <Form onSubmit={onSubmit}>
        {(props: any) => (
          <form>
            <Box
              component="div"
              className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
            >
              <CompanyReferenceInput></CompanyReferenceInput>
            </Box>
            <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
              <div>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <FacultywiseInput
                      labelName="Faculty *"
                      // formData={formData}
                      // dataManage={streamData}
                    ></FacultywiseInput>
                  )}
                </FormDataConsumer>
              </div>
            </div>

            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(formData)}
                  >
                    {isLoad ? (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        className={classes.loadDivColor}
                      />
                    ) : (
                      "VIEW"
                    )}
                  </Button>
                );
              }}
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <AddDialogueBox
                    open={dialogOpen}
                    close={handleAddDialogClose}
                    formData={formData.stream}
                  />
                );
              }}
            </FormDataConsumer>
          </form>
        )}
      </Form>

      {timeslotData && timeslotData.length === 0 ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smrtable : classes.rtable}>
          <>
            <TableContainer className={isXSmall ? "" : classes.container}>
              <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headerArr.map((day: any) => {
                      return (
                        <TableCell className={classes.header} rowSpan={2}>
                          {day.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {timeslotData &&
                    timeslotData.map((employeeData: any, index: number) => {
                      return (
                        <>
                          <TableRow>
                            {headerArr.map((item: any) => {
                              return (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {employeeData && employeeData[item.key] ? (
                                    <button
                                      className={
                                        employeeData &&
                                        employeeData[item.key].faculty
                                          ? classes.textColor
                                          : classes.timeText
                                      }
                                    >
                                      {employeeData[item.key].time}
                                      {employeeData &&
                                      employeeData[item.key].faculty ? (
                                        <>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {employeeData[item.key].streamName}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {employeeData[item.key].courseName}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {
                                              employeeData[item.key]
                                                .semesterName
                                            }
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {employeeData[item.key].subject}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            ({employeeData[item.key].classroom})
                                          </Typography>
                                        </>
                                      ) : (
                                        <div
                                          className={classes.tablecellTextColor}
                                        >
                                          {employeeData[item.key].breakTitle}
                                        </div>
                                      )}
                                    </button>
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
      )}
    </div>
  );
};
export default FacultyTimetable;
