import NotificationList from "./NotificationList";
import NotificationEdit from "./NotificationEdit";
import NotificationCreate from "./NotificationCreate";
import DepartmentIcon from "@material-ui/icons/Group";

export default {
  list: NotificationList,
  create: NotificationCreate,
  edit: NotificationEdit,
  icon: DepartmentIcon,
};
