import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import { Table, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { streamStyle } from "../helperStyle";
import { useParams } from "react-router-dom";
import helperFunc from "../helperFuncs";
import NoImageFound from "../img/Noimagefound.jpg";

const useStyles = makeStyles(streamStyle);
const ViewPage = (props: any) => {
  const [printData, setPrintData] = useState({} as any);

  let id = {} as any;
  const vehicleType: any = {
    twoWheeler: "Two Wheeler",
    fourWheeler: "Four Wheeler",
    none: "",
  };

  const dates = helperFunc.commonDateFormat(printData?.createdAt, "HH:mm:ss");

  const dataProvider = useDataProvider();
  const classes = useStyles();

  id = useParams();

  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const printCount = localStorage.getItem("printCount");

  useEffect(() => {
    document.title = `${printData?.company?.name} Visitor Pass ${printCount}`;
  });

  useEffect(() => {
    const resourcePath = `visitor-qr-code/get/${id?.id}`;
    dataProvider
      .callCustom(resourcePath, {})
      .then((response: any) => {
        setPrintData(response?.data?.item);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const handlePrint = (event: any) => {
    event.preventDefault();
    window.print();
  };

  const dataArr = {
    details: [
      { label: "Name", value: printData?.employee?.name },
      { label: "Mobile No", value: printData?.employee?.phone },
      { label: "From", value: printData?.from },
      {
        label: "Purpose of Meeting",
        value: `${printData?.purpose ? printData?.purpose : ""}`,
      },
    ],
  };

  return (
    <>
      <div className="container-fluid print-view">
        <div className="row">
          <>
            <TableContainer className={classes.table}>
              <Table className="print-table">
                <TableRow>
                  <TableCell rowSpan={3} className="brand-logo">
                    <img
                      src={`${uploadApiUrl}${printData?.company?.settings?.company_logo}`}
                      height="125px"
                      width="187px"
                      alt="companyLogo"
                    />
                  </TableCell>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>Gate Pass</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>{printData?.company?.name}</h4>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>Date of issue : {dates}</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Visitor Card No</TableCell>
                  <TableCell>{printCount}</TableCell>
                  <TableCell className="last-cell" rowSpan={5}>
                    <img
                      src={
                        printData?.employee?.profile_image
                          ? `${uploadApiUrl}${printData.employee.profile_image}`
                          : NoImageFound
                      }
                      height="125px"
                      width="187px"
                      alt="visitorImage"
                    />
                  </TableCell>
                </TableRow>
                {dataArr.details.map((ele) => {
                  return (
                    <TableRow>
                      <TableCell>{ele.label}</TableCell>
                      <TableCell>{ele.value}</TableCell>
                    </TableRow>
                  );
                })}

                <TableRow>
                  <TableCell>Whom To Meet</TableCell>
                  <TableCell>{printData?.invitedEmployeeId?.name}</TableCell>
                  <TableCell className="last-cell">
                    {printData?.company?.name} <br /> Authorized Sign
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total No Of Person</TableCell>
                  <TableCell>{printData?.totalPerson}</TableCell>
                  <TableCell rowSpan={4} className="last-cell">
                    <img
                      src={printData.qrCode}
                      height="125px"
                      width="187px"
                      alt="qrCode"
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Vehicle Type</TableCell>
                  <TableCell>
                    {vehicleType && vehicleType[printData.vehicleType]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vehicle Number</TableCell>
                  <TableCell>
                    {printData?.vehicleNumber ? printData?.vehicleNumber : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Remarks</TableCell>
                  <TableCell>{printData?.remarks}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center bottom-buttons mt-10 hide-print-btn">
        <a
          className="btn btn-primary text-white mb-2"
          href="javascript:void()"
          onClick={handlePrint}
        >
          <span className="fa fa-print mr-2"></span>
          Print
        </a>
      </div>
    </>
  );
};
export default ViewPage;
