import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import {
  useDataProvider,
  AutocompleteArrayInput,
  SelectArrayInput,
} from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
const useStyles = makeStyles((theme) => ({
  // root: {
  //   minWidth: "500px",
  // },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));

interface State {
  multiData?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
  isXsmall?: boolean;
  source?: string;
  label?: string;
  apiName?: string;
}

const MultiSelectDropdown: FC<Props> = ({
  filter,
  isXsmall,
  source,
  label,
  apiName,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList(`${apiName}/search`, filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          multiData: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { multiData } = state;

  return (
    <div>
      <SelectArrayInput
        resettable
        label={label}
        source={source}
        choices={multiData}
        className="multi-dropdown-width"
      />
    </div>
  );
};

MultiSelectDropdown.defaultProps = {
  source: "childCompanyIds",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectDropdown);
