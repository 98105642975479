import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImportVideoButton from "../employees/videoImport";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  useDataProvider,
  required,
  BooleanInput,
  TextInput,
  LinearProgress,
} from "react-admin";
import employees from "../employees";
import { FieldProps, Employee, Device } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Dialog from "@material-ui/core/Dialog";
// import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, useMediaQuery, Theme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toastError, toastInfo } from "../components/toast";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right", marginBottom: 10 },
  mobileRoot: { textAlign: "left" },
  name: { display: "inline-block" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  header: {
    padding: 10,
  },
});

interface State {
  device?: string;
  isLocal?: boolean;
  videoUrl?: string;
  rfid?: string;
  deviceData?: Device;
  employeeData?: {};
  is_local_device?: boolean;
}

const RegistrationToolbar: FC<FieldProps<Employee>> = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [newopen, setNewOpen] = useState(false);
  const [newRFopen, setNewRFOpen] = useState(false);
  const [localOpen, setlocalOpen] = useState(false);
  const [videoURLOpen, setVideoURLOpen] = useState(true);
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [rfidOpen, setRfidOpen] = useState(false);
  const refresh = useRefresh();
  // const notify = useNotify();
  const [state, setState] = useState<State>({});
  const [isValidate, setEnable] = useState(false);
  const [isLoad, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;

  const handleChange = (e: any) => {
    // let { videoUrl } = state;
    setLoader(true);
    let file;
    let data;
    file = e.target.files && e.target.files[0];
    data = new FormData();

    data.append("file", file);
    dataProvider
      .callCustom("account/uploadVideo", data)
      .then((responseLocal: any) => {
        if (responseLocal.data.success === true) {
          setEnable(true);
          setLoader(false);
          setState((state) => ({
            ...state,
            videoUrl:
              responseLocal.data && responseLocal.data.item
                ? uploadApiUrl + responseLocal.data.item.video
                : "",
          }));
          toastInfo("Data has been imported Successfully.");
          // notify("Data has been imported Successfully.");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        setLoader(false);
      });
  };

  const handleClick = () => {
    setEnable(false);
    setOpen(true);
    setState((state) => ({
      ...state,
      videoUrl: "",
    }));
  };

  const handleDialogClose = () => setOpen(false);
  const handleVideoDialogClose = () => {
    setNewOpen(false);
    setNewRFOpen(false);
  };

  const handleConfirm = (record: any) => {
    const { is_local_device, deviceData } = state;
    if (is_local_device && is_local_device === true) {
      var employeeData = {
        _id: record._id,
        name: record.name,
      };
      setState((state) => ({
        ...state,
        employeeData: employeeData,
      }));

      if (deviceData) {
        //const deviceData = response.data;
        if (deviceData.deviceType === "turnoutR") {
          setNewRFOpen(true);
        } else if (deviceData.deviceType === "turnoutE") {
          setLoading(true);
          dataProvider
            .callCustomLocal(deviceData.edgeURL + "/details_reg", {
              person_ID: record.id,
              company_id: record.company,
            })
            .then((responseLocal: any) => {
              if (responseLocal.data.success === true) {
                setNewOpen(true);
                setLoading(false);
              }
            })
            .catch((err: any) => {
              setLoading(false);
            });
        }
      }
      setOpen(false);
    } else {
      dataProvider
        .callCustomLocal(`${apiUrl}/student/registration`, {
          studentId: record && record.record && record.record.id,
          fileURL: videoUrl ? videoUrl : "",
        })
        .then((responseLocal: any) => {
          if (responseLocal.data && responseLocal.data.success === true) {
            // notify("Registration process is started.");
            toastInfo("Registration process is started.");
            setOpen(false);
            refresh();
          } else {
            toastError(responseLocal.data.Msg);
            // notify(responseLocal.data.Msg, 'warning');
          }
        })
        .catch((err: any) => {
          // notify(err);
          toastError(err.message);
        });
    }
  };

  const HandleOnChange = (device: any) => {
    if (!!device && device !== "") {
      setVideoURLOpen(true);
      setState((state) => ({
        ...state,
        isLocal: true,
      }));
      setEnable(true);
    }

    if (device !== "") {
      dataProvider
        .getOne("devices", {
          id: device,
        })
        .then((response: any) => {
          if (response.data) {
            const deviceData = response.data;
            if (deviceData.deviceType === "turnoutR") {
              setRfidOpen(true);
              setlocalOpen(false);
            } else if (deviceData.deviceType === "turnoutE") {
              setRfidOpen(false);
              setlocalOpen(true);
            }
            setState((state) => ({
              ...state,
              device: device,
              deviceData: deviceData,
            }));
          }
        });
    }
  };
  const HandleOnChangeIsLocal = (is_local: any) => {
    if (is_local === true) {
      setVideoURLOpen(true);
    } else {
      setVideoURLOpen(false);
    }

    setState((state) => ({
      ...state,
      isLocal: is_local,
    }));
  };

  const HandleOnChangeIsLocalDevice = (is_local_device: any) => {
    if (is_local_device === true) {
      setEnable(false);
      setVideoURLOpen(false);
      setDeviceOpen(true);
    } else {
      setVideoURLOpen(true);
      setlocalOpen(false);
      setDeviceOpen(false);
    }

    setState((state) => ({
      ...state,
      is_local_device: is_local_device,
    }));
  };
  const HandleOnChangeVideoURL = (video_url: any) => {
    const { is_local_device } = state;
    setState((state) => ({
      ...state,
      videoUrl:
        video_url && video_url.target && video_url.target.value
          ? uploadApiUrl + video_url.target.value
          : "",
    }));
    if (!is_local_device) {
      setEnable(true);
    }
  };

  const HandleOnChangeRfid = (rfid: any) => {
    const setRfid =
      rfid && rfid.target && rfid.target.value ? rfid.target.value : "";
    setState((state) => ({
      ...state,
      rfid: setRfid,
    }));
  };
  const handleConfirmVideo = (record: any) => {
    const { device, deviceData, isLocal, videoUrl, rfid, is_local_device } =
      state;
    if (deviceData) {
      if (deviceData.deviceType === "turnoutR") {
        dataProvider
          .callCustomLocal(deviceData.edgeURL + "/newempregistration", {
            _id: record.id,
            rfid: rfid,
            name: record.name,
            company_id: record.company,
          })
          .then((responseLocal: any) => {
            if (responseLocal.data && responseLocal.data.success === true) {
              dataProvider
                .callCustomLocal(apiUrl + "/employees/update/" + record.id, {
                  id: record.id,
                  device: device,
                  rfId: rfid,
                  isRegistred: true,
                })
                .then((response: any) => {
                  toastInfo("Student register successfully.");
                  // notify("Employee register successfully.");
                  refresh();
                });
            } else {
              toastError(responseLocal.data.Msg);
              // notify(responseLocal.data.Msg, 'warning');
            }
          });
      } else if (deviceData.deviceType === "turnoutE") {
        var isUploaded = true;
        setLoading(true);
        dataProvider
          .callCustomLocal(deviceData.edgeURL + "/registration", {
            person_ID: record.id,
            is_local: isLocal ? isLocal : false,
            video_url: videoUrl ? videoUrl : "",
            company_id: record.company,
          })
          .then((responseLocal: any) => {
            if (responseLocal.data.success === true) {
              isUploaded = false;
              if (
                responseLocal.data.isUpdated &&
                responseLocal.data.isUpdated.toString() === "1"
              ) {
                isUploaded = true;
              }
              dataProvider
                .callCustom("employees/employee_registration", {
                  person_ID: record.id,
                  deviceId: device,
                  imagedata: responseLocal.data.imagedata,
                  isRegistred: true,
                  isUploaded: isUploaded,
                })
                .then((response: any) => {
                  toastInfo("Student register successfully.");
                  // notify("Employee register successfully.");
                  setLoading(false);
                  refresh();
                })
                .catch((err: any) => {
                  setLoading(false);
                });
              if (isUploaded === false) {
                dataProvider
                  .callCustomLocal(deviceData.edgeURL + "/UploadModels", {})
                  .then((responseLocal: any) => {
                    if (responseLocal.data.success === true) {
                      toastInfo(responseLocal.data.msg);
                      // notify(responseLocal.data.msg);
                      refresh();
                    } else {
                      toastError(responseLocal.data.msg);
                      // notify(responseLocal.data.msg, "warning");
                    }
                  });
              }
            } else {
              if (responseLocal.data.imagedata) {
                const responseLocalData = responseLocal.data;
                dataProvider
                  .getOne("employees", { id: responseLocalData.imagedata })
                  .then((responseLocalEMP: any) => {
                    if (responseLocalEMP.data.success === true) {
                      toastError(
                        responseLocal.data.msg +
                          " " +
                          responseLocalEMP.data.item.name
                      );
                    } else {
                      toastError(responseLocal.data.msg);
                      // notify(responseLocal.data.msg, "warning");
                    }
                  });
              } else {
                toastInfo(responseLocal.data.msg);
                // notify(responseLocal.data.msg);
              }
            }
          });
      }
      // const deviceData = response.data;
    }

    setNewOpen(false);
    setNewRFOpen(false);
  };

  const validateField = ({ values }: { values: any }) => {
    const errors = {} as any;
    // if (password && confirm_password && password !== confirm_password) {
    //   errors.confirm_password = ["resources.customers.errors.password_mismatch"];
    // }

    return errors;
  };
  const { videoUrl, isLocal } = state;

  return record ? (
    <div className={isXsmall ? classes.mobileRoot : classes.root}>
      {isXsmall ? (
        <employees.icon
          style={{ fontSize: 30, color: "#283593", textAlign: "center" }}
          onClick={handleClick}
        />
      ) : (
        <Button
          variant="contained"
          color="primary"
          label="Registration"
          className={"mb-10"}
          onClick={handleClick}
          disabled={loading}
          style={{ marginBottom: "10px" }}
        >
          {loading ? (
            <CircularProgress size={25} thickness={2} />
          ) : (
            <employees.icon className={classes.icon} />
          )}
        </Button>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Registration"
        style={{ zIndex: 1 }}
      >
        <DialogTitle>Registration</DialogTitle>
        <DialogContent>
          <Typography className={isXsmall ? classes.header : undefined}>
            Are you sure you wish to register this Student ?
          </Typography>
          <SimpleForm
            validate={validateField}
            // We override the redux-form name to avoid collision with the react-admin main form
            // form="post-quick-create"
            // We override the redux-form onSubmit prop to handle the submission ourselves
            // onSubmit={handleConfirm}
            onSubmit={() => handleConfirm(record)}
            // We want no toolbar at all as we have our modal actions
            record={{ video_url: videoUrl, is_local: isLocal ? isLocal : true }}
            toolbar={null}
          >
            <BooleanInput
              label="Is Local Device"
              source="is_local_device"
              onChange={HandleOnChangeIsLocalDevice}
            />
            {deviceOpen ? (
              <ReferenceInput
                source="device._id"
                reference="devices"
                validate={requiredValidate}
                formClassName={classes.suggestions}
                filter={{ company: record.company, isMaster: true }}
                onChange={HandleOnChange}
              >
                <AutocompleteInput
                  formClassName={classes.suggestions}
                  optionText={(choice: Device) =>
                    `${choice.camera_ID} - ${choice.device_location}`
                  }
                  optionValue="_id"
                />
              </ReferenceInput>
            ) : null}
            {localOpen ? (
              <BooleanInput
                label="Is Local"
                source="is_local"
                defaultValue={true}
                onChange={HandleOnChangeIsLocal}
              />
            ) : null}

            {videoURLOpen ? (
              <TextInput
                // formClassName={classes.code}
                // multiline
                label="Video URL"
                source="video_url"
                onChange={(value: any) => HandleOnChangeVideoURL(value)}
                //   onChange={HandleOnChangeVideoURL}
              />
            ) : null}
            {videoURLOpen ? (
              isLoad ? (
                <LinearProgress />
              ) : (
                <ImportVideoButton
                  // formClassName={classes.code}

                  // multiline
                  // onChange={(value: any) => HandleOnChangeVideoURL(value)}
                  onChange={handleChange}
                />
              )
            ) : null}
            {rfidOpen ? (
              <TextInput
                // formClassName={classes.code}

                // multiline
                label="RFID"
                source="rfid"
                onChange={(value: any) => HandleOnChangeRfid(value)}
                //   onChange={HandleOnChangeVideoURL}
              />
            ) : null}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={() => handleConfirm(record)}
          >
            <CheckCircleIcon />
          </Button>
          <Button label="Cancel" onClick={handleDialogClose}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={newopen}
        title="Video Registration"
        content="Click 'START', Once you ready to start video registration process!"
        confirm="START"
        cancel="Cancel"
        onConfirm={() => handleConfirmVideo(record)}
        onClose={handleVideoDialogClose}
      />
      <Confirm
        isOpen={newRFopen}
        title="RFID Registration"
        content="Click 'START', Once you ready to start RFID registration process!"
        confirm="START"
        cancel="Cancel"
        onConfirm={() => handleConfirmVideo(record)}
        onClose={() => handleVideoDialogClose()}
      />
    </div>
  ) : null;
};

const requiredValidate = [required()];

export default RegistrationToolbar;
