import EmployeeIcon from "@material-ui/icons/Person";
import { SubAdminForm } from "./SubAdminCommon";
import SubAdminList from "./SubAdminList";

export default {
  list: SubAdminList,
  create: SubAdminForm,
  edit: SubAdminForm,
  icon: EmployeeIcon,
};
