import * as React from "react";
import { useState } from "react";
import { withTypes } from "react-final-form";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, makeStyles, Theme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import {
  Notification,
  SimpleForm,
  TextInput,
  required,
  useDataProvider,
  Link,
  PasswordInput,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { useTranslate } from "ra-core";
import { toastError, toastInfo } from "../components/toast";
import { lightTheme } from "./themes";

const isDefaultImage = process.env.REACT_APP_DATA_PROVIDER_IS_DEFAULT_IMAGE;

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: `${
      isDefaultImage === "true" ? "url(/auth-bg.jpg)" : "url(/zucampus.jpeg)"
    }`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 315,
    marginTop: "4em",
  },
  adminCard: {
    height: "10em",
    minWidth: "430px",
    marginTop: "10em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  systemAdmin:{
    marginLeft: "14px",
    fontSize: "18px",
    color: "#424242",
    fontWeight: 600,
  }
,
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    width: "100%",
    padding: "0 1em 1em 1em",
  },
  actionNew: {
    textAlign: "right",
    padding: "0 1em 1em 1em",
  },
  icon: { paddingRight: "0.25em" },
  links: {
    display: "inline-flex",
    alignItems: "center",
  },
  linksResend: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "175px",
  },
  copyright: {
    position: "fixed",
    left: 0,
    bottom: "-13px",
    fontSize: "13px",
    backgroundColor: "#424242",
    color: "#ffffff",
    height: "48px",
    padding: "7px 0px",
    // bottom: 0,
    top: "auto",
    // position: "fixed",
    zIndex: 1100,
    width: "100%",
    textAlign: "center",
  },
  mobileCopyright: {
    position: "absolute",
    left: 0,
    bottom: "-13px",
    fontSize: "13px",
    backgroundColor: "#424242",
    color: "#ffffff",
    height: "30px",
    padding: "7px 0px",
    // bottom: 0,
    top: "auto",
    // position: "fixed",
    zIndex: 1100,
    width: "100%",
    textAlign: "center",
  },
}));

interface FormValues {
  username?: string;
}

const { Form } = withTypes<FormValues>();
interface State {
  email?: string;
  otp?: string;
  newPassword?: string;
}

export const validatePasswords = ({
  password,
  confirm_password,
}: {
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};
const ForgotPassword = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState("block");
  const [newopen, setNewOpen] = useState("none");
  // const notify = useNotify();
  const isSerco = process.env.REACT_APP_DATA_PROVIDER_IS_SERCO;

  const [state, setState] = useState<State>({});

  const handleOnChangeOTP = (otp: any) => {
    // if (!!device && device !== "") {
    //   setEnable(true);
    // }

    setState((state) => ({
      ...state,
      otp: otp,
    }));
  };
  const handleOnChangeNewPassword = (password: any) => {
    // if (!!device && device !== "") {
    //   setEnable(true);
    // }

    setState((state) => ({
      ...state,
      newPassword: password,
    }));
  };
  const handleClick = (formData: any, type: String) => {
    dataProvider
      .callCustomOuter("auth/otp-request", {
        //person_ID: record.id,
        username: state?.email
          ? state?.email
          : formData && formData.email
          ? formData.email
          : formData.otp,
      })
      .then((response: any) => {
        if (response.data.success === true) {
          setState((state) => ({
            ...state,
            email: formData.email ? formData.email : response.data?.item,
          }));
          toastInfo("Otp has been sent Successfully.");
          setTimeout(() => {
            setNewOpen("block");
            setOpen("none");
          }, 2000);
        }
      })
      .catch((error: Error) => {
        setLoading(false);
        toastError(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message
        );
        // notify(
        //   typeof error === "string"
        //     ? error
        //     : typeof error === "undefined" || !error.message
        //     ? "ra.auth.sign_in_error"
        //     : error.message,
        //   "warning"
        // );
      });
  };

  const handleClickChangePassword = (formData: any) => {
    const { email } = state;

    dataProvider
      .callCustomOuter("auth/reset-password", {
        //person_ID: record.id,
        otp: formData.otp,
        password: formData.password,
        confirmPassword: formData.password,
        username: email,
      })
      .then((response: any) => {
        if (response && response.data && response.data.success === true) {
          toastInfo("Password has been changed Successfully.");
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
        }
      })
      .catch((error: Error) => {
        setLoading(false);
        toastError(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message
        );
      });
  };

  return (
    <div className={classes.main}>
      {/* {isForgotPassword ? ""} */}

      <Card className={isSerco === "true" ? classes.adminCard : classes.card}>
        <div className={classes.avatar}>
          <img src="/turnout-blue.png" alt="" />
        </div>
        <Box>
          {isSerco === "true" ? (
            <Box className={classes.systemAdmin}>Please contact your system administrator.</Box>
          ) : (
            <Box>
              {" "}
              <Box component="span" display={open}>
                <SimpleForm toolbar={null}>
                  <TextInput
                    label="Email or Phone or Employee Code"
                    // type="email"
                    source="email"
                    fullWidth
                    //   onChange={handleOnChangeEmail}
                    // validation={{ email: true }}
                    formClassName={classes.input}
                    validate={[
                      required(),
                      regex(/.*\S.*/, "Only whitespaces are not allowed"),
                    ]}
                  />

                  <FormDataConsumer fullWidth>
                    {({ formData }: { formData: any }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClick(formData, "send")}
                        disabled={loading}
                        fullWidth
                      >
                        {loading && (
                          <CircularProgress size={25} thickness={2} />
                        )}
                        Send OTP
                      </Button>
                    )}
                  </FormDataConsumer>

                  <Notification />
                </SimpleForm>
              </Box>
              <Box component="span" display={newopen}>
                <SimpleForm toolbar={null} validate={validatePasswords}>
                  <TextInput
                    label="OTP"
                    source="otp"
                    fullWidth
                    onChange={handleOnChangeOTP}
                    formClassName={classes.input}
                    validate={[
                      required(),
                      regex(/.*\S.*/, "Only whitespaces are not allowed"),
                    ]}
                  />
                  <FormDataConsumer fullWidth>
                    {({ formData }: { formData: any }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClick(formData, "resend")}
                        // disabled={loading}
                        className={classes.linksResend}
                        // fullWidth

                        size="small"
                      >
                        {loading && (
                          <CircularProgress size={25} thickness={2} />
                        )}
                        Resend OTP
                      </Button>
                    )}
                  </FormDataConsumer>

                  <PasswordInput
                    source="password"
                    fullWidth
                    validate={[required()]}
                    formClassName={classes.input}
                    label="New Password"
                  />

                  <PasswordInput
                    fullWidth
                    source="confirm_password"
                    formClassName={classes.input}
                    validate={[required()]}
                    label="Confirm New Password"
                  />

                  {/* <TextInput
              fullWidth
              type="password"
              source="password"
              onChange={handleOnChangeNewPassword}
              formClassName={classes.input}
              validate={[required()]}
            /> */}

                  <FormDataConsumer>
                    {({ formData }: { formData: any }) => {
                      return (
                        <Button
                          variant="contained"
                          onClick={() => handleClickChangePassword(formData)}
                          color="primary"
                          fullWidth
                        >
                          {loading && (
                            <CircularProgress size={25} thickness={2} />
                          )}
                          {/* {translate("ra.auth.su")} */}
                          Change Password
                        </Button>
                      );
                    }}
                  </FormDataConsumer>

                  <Notification />
                </SimpleForm>
              </Box>
            </Box>
          )}

          <Box component="div" className={classes.actionNew}>
            <Button
              size="small"
              color="primary"
              component={Link}
              to={{
                pathname: "/login",
              }}
              className={classes.links}
            >
              <LockIcon className={classes.icon}></LockIcon>
              Login
            </Button>
          </Box>
        </Box>
      </Card>
      <p className={isSmall ? classes.mobileCopyright : classes.copyright}>
        Copyright ©{new Date().getFullYear()} All Rights Reserved | Designed And
        Developed By{" "}
        <a
          style={{ color: "#ADD8E6", textDecoration: "none" }}
          href="http://demo.turnout.cloud"
        >
          Turnout
        </a>
      </p>
    </div>
  );
};

// ForgotPassword.propTypes = {
//   authProvider: PropTypes.func,
//   previousRoute: PropTypes.string,
// };

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const OtpRequestWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <ForgotPassword {...props} />
  </ThemeProvider>
);

export default OtpRequestWithTheme;
