import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import Configuration from "./configuration/Configuration";
import Segments from "./segments/Segments";
import profile from "./profile";
import { Dashboard } from "./dashboard";
import ApprovapReport from "./layout/approval-report";
import {
  ForgotPassword,
  Reset,
  AboutTurnout,
  PrivacyPolicy,
  Login,
  LandingPageFirst,
  LandingPageSecond,
  Contact,
  TurnoutEdge,
  AccessControl,
  VisitorManagement,
} from "./layout";
import { RouteWithoutLayout } from "react-admin";

import ViewQrPage from "./layout/viewPublicQr";

import InviteVisitorPublic from "./layout/inviteVisitorPublic";
import ViewPage from "./inviteVisitors/viewInviteVisitor";
import DateSetting from "./dateSetting/dateSetting";
// import OrganizationChart from "./organizationChart/organizationChart";
import employee from "./organizationChart/data";
import MachineAttendanceReport from "./machineAttendanceReport/MachineAttendanceReport";
import EmployeeAttendanceReport from "./attendanceReport/EmployeeAttendanceReport";
import OrgReactChart from "./organizationChart/orgChartReact";
import MobileTest from "./mobileTest/MobileTest";
import CreateUserPublic from "./layout/createUserPublic";

const loginCheck = process.env.REACT_APP_DATA_PROVIDER_IS_LOGIN_SCREEN;

export default [
  <Route
    exact
    path="/dashboard"
    render={() =>
      localStorage.getItem("token") ? (
        <Dashboard />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />,
  <Route
    exact
    path="/configuration"
    render={() =>
      localStorage.getItem("token") ? (
        <Configuration />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />,

  // <Route
  //   exact
  //   path="/todaysAttendance/:id"
  //   render={() =>
  //     localStorage.getItem("token") ? (
  //       <TodaysAttendanceList />
  //     ) : (
  //       <Redirect to={{ pathname: "/login" }} />
  //     )
  //   }
  // />,

  <RouteWithoutLayout
    exact
    path="/"
    render={() => (loginCheck === "true" ? <Login /> : <LandingPageFirst />)}
  />,
  <RouteWithoutLayout
    exact
    path="/turnout-system"
    render={() => <LandingPageSecond />}
  />,
  <RouteWithoutLayout exact path="/login" render={() => <Login />} />,
  <Route
    exact
    path="/segments"
    render={() =>
      localStorage.getItem("token") ? (
        <Segments />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />,
  <Route
    exact
    path="/organization-chart"
    render={() =>
      localStorage.getItem("token") ? (
        // <OrgReactChart />
        <OrgReactChart />
      ) : (
        // <OrganizationChart />
        // <OrgReactChart />

        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />,
  // <Route
  //   exact
  //   path="/organization-chart2"
  //   render={() =>
  //     localStorage.getItem("token") ? (
  //       <OrganizationChart />
  //     ) : (
  //       <Redirect to={{ pathname: "/login" }} />
  //     )
  //   }
  // />,
  <Route
    exact
    path="/date-settings"
    render={() =>
      localStorage.getItem("token") ? (
        <DateSetting />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />,
  <RouteWithoutLayout
    exact
    path="/forgotPassword"
    render={() => <ForgotPassword />}
  />,
  <RouteWithoutLayout
    exact
    path="/about-turnout"
    render={() => <AboutTurnout />}
  />,
  <RouteWithoutLayout
    exact
    path="/privacy-policy"
    render={() => <PrivacyPolicy />}
  />,
  <RouteWithoutLayout
    exact
    path="/turnout-edge"
    render={() => <TurnoutEdge />}
  />,
  <RouteWithoutLayout
    exact
    path="/access-control"
    render={() => <AccessControl />}
  />,
  <RouteWithoutLayout
    exact
    path="/visitor-management"
    render={() => <VisitorManagement />}
  />,
  <RouteWithoutLayout exact path="/reset" render={() => <Reset />} />,
  <Route key="account" path="/account" component={profile.edit} />,
  <RouteWithoutLayout
    exact
    path="/approval-report/:id"
    render={() => <ApprovapReport />}
  />,
  <RouteWithoutLayout exact path="/contact" render={() => <Contact />} />,
  <RouteWithoutLayout
    exact
    path="/invite-visitor-public/:id"
    render={() => <InviteVisitorPublic />}
  />,
  <RouteWithoutLayout
    exact
    path="/create-self-user/:id"
    render={() => <CreateUserPublic />}
  />,
  <RouteWithoutLayout
    exact
    path="/attendance-report-machine/:id"
    render={() => <MachineAttendanceReport />}
  />,
  <RouteWithoutLayout
    exact
    path="/attendance-report-employee/:id"
    render={() => <EmployeeAttendanceReport />}
  />,
  <RouteWithoutLayout
    exact
    path="/view-invite-visitor/:id"
    render={() => <ViewPage />}
  />,
  <RouteWithoutLayout
    exact
    path="/mobile-test"
    render={() => <MobileTest />}
  />,
  <RouteWithoutLayout exact path="/q/:id" render={() => <ViewQrPage />} />,
];
