import * as React from "react";
import { FC, useState, useCallback, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  maxLength,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  useDataProvider,
  FileInput,
  FileField,
  BooleanInput,
} from "react-admin";
import { regex } from "ra-core";
import {
  Typography,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import FullNameField from "./FullNameField";
import { Leave, FieldProps, LeaveTypes, BehalfOf } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import {
  approvalStatus,
  aprroverStatus,
  halfLeaveType,
  leaveType,
  rejectedStatus,
} from "../helperChoice";
import { leaveStyle } from "../helperStyle";
// import { DateInput } from "react-admin-date-inputs2";
import GetAppIcon from "@material-ui/icons/GetApp";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import EmployeeLeaveTypeSelectInput from "../components/EmployeeLeaveTypeSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import authProvider from "../authProvider";
import MomentUtils from "@date-io/moment";
import DialogueBox from "./dialogueBox";
import LeaveApproverSelectInput from "../components/LeaveApproverSelectInput";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
const dateArr: any = ["21-06-2022"];

interface State {
  AssessmentYear?: any;
  assessmentYearStart?: any;
  assessmentYearEnd?: any;
}

const useStyles = makeStyles(leaveStyle);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const user = helperFunc.getUserData();
  let [docs, setDocs] = useState([]);
  const [text, setText] = useState("");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());

  const [state, setState] = useState<State>({});

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [imageData, setImageData] = useState({});
  const [halfLeaveToggle, setHalfLeaveToggle] = useState("");
  const imageConst = {
    width: "200px",
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formData: any
  ) => {
    if (event.target.checked) {
      setHalfLeaveToggle("halfLeave");
      formData.type = "halfLeave";
    } else {
      setHalfLeaveToggle("fullLeave");
      formData.type = "fullLeave";
    }
  };

  const today = new Date().toISOString().slice(0, 10);

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, data: any) => {
    setShowDialog(true);
    setIndex(index);
    setImageData(data);
  };

  useEffect(() => {
    setDocs(
      props.record && props.record.documents ? props.record.documents : []
    );
  }, [props]);

  const remove = async (i: any, data: any) => {
    let changedDocs = docs.filter((val: any, idx: any) => idx !== i);
    const payload = {
      _id: props.record._id,
      changedDocs: changedDocs,
    };
    await dataProvider
      .callCustom("leave-management/delete-image", payload)
      .then((response: any) => {})
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          if (err.response.status === 401) {
            localStorage.clear();
            window.location.replace("/");
          }
        });
      });
    props.record.documents = changedDocs;
    data.documents = changedDocs;
    setDocs(props.record.documents);
    data.hideFiled = true;
  };

  const validatedate = ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => {
    const errors = {} as any;
    // if (dateArr.includes(helperFunc.createdAtDateFormat(fromDate))) {
    //   errors.fromDate = `This date is already been used for leave`;
    // }
    // if (fromDate && new Date(fromDate) < new Date()) {
    //   errors.fromDate = 'From date must be greater then current date';
    // }

    if (fromDate && new Date(fromDate) < new Date(assessmentYearStart)) {
      errors.fromDate = "From date must be greater than assessment Date";
    }
    if (fromDate && new Date(fromDate) > new Date(assessmentYearEnd)) {
      errors.fromDate = "From date must be less than assessment Date";
    }
    // else if (toDate && new Date(toDate) < new Date(assessmentYearStart)) {
    //   errors.fromDate = 'From date must be greater then assessment Date';
    // }
    // else if (toDate && new Date(toDate) > new Date(assessmentYearEnd)) {
    //   errors.fromDate = 'From date must be less then assessment Date';
    // }
    if (fromDate && toDate && new Date(toDate) < new Date(fromDate)) {
      errors.toDate = "To date must be greater than From date";
    }
    return errors;
  };
  const dataProvider = useDataProvider();
  const fetchAssessmentYear = useCallback(async () => {
    await dataProvider
      .getSearchList("EmployeeLeaveMaster/search", {})
      .then((response: any) => {
        setState((state) => ({
          ...state,
          AssessmentYear: response && response.data ? response.data : 0,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          if (err.response.status === 401) {
            localStorage.clear();

            window.location.replace("/");
          }
        });
      });
  }, [dataProvider]);

  useEffect(() => {
    fetchAssessmentYear();
  }, []);

  const { AssessmentYear, assessmentYearStart, assessmentYearEnd } = state;
  AssessmentYear &&
    AssessmentYear.map((item: any) => {
      setState((state) => ({
        assessmentYearStart: item.assessmentYearStart,
        assessmentYearEnd: item.assessmentYearEnd,
      }));
    });

  const handleCheckBox = (e: any, formData: any) => {
    if (e.target.checked) {
      formData.type = "halfLeave";
    } else {
      formData.type = "fullLeave";
    }
  };

  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/leave-management/create"
          ? "Update Leave"
          : "Add Leave"}
      </h2>
      <SimpleForm
        {...props}
        validate={
          props && Object.keys(props.record).length === 0 ? validatedate : null
        }
      >
        {user?.type === "super" ? (
          <CompanyReferenceInput></CompanyReferenceInput>
        ) : null}
        {user?.type == "company" || user?.type == "super" ? (
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                validate={requiredValidate}
                filter={{ isActive: true, company: formData.company }}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>
        ) : null}
        {user?.type == "company" || user?.type == "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <EmployeeSelectInput
                validate={requiredValidate}
                filter={{
                  // workplaceId: formData.workplaceId,
                  isActive: true,
                  company: formData.company,
                }}
              ></EmployeeSelectInput>
            )}
          </FormDataConsumer>
        ) : null}
        <br />
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            return (
              <RadioButtonGroupInput
                // disabled={formData && formData.fromDate !== formData.toDate}
                row={false}
                // fullWidth
                formClassName={classes.email}
                // label="label.leaves.type"
                source="type"
                resource="leaves"
                choices={leaveType}
                defaultValue={leaveType[0].id}
                // onClick={() => setText("")}
              />
            );
          }}
        </FormDataConsumer>

        <div style={{ display: "inline" }}>
          <EmployeeLeaveTypeSelectInput
            filter={{ isActive: true }}
            formClassName={classes.name}
            // helperText={true}
          />
        </div>
        <SectionTitle label="" />
        <div style={{ display: "inline" }}>
          <DateInput
            // disabled={props && Object.keys(props.record).length === 0 ? false : true}
            autoFocus
            label="label.from"
            resource="leaves"
            source="fromDate"
            options={{
              format: "DD/MM/YYYY",
              clearable: true,
            }}
            inputProps={{
              min: "1900-08-10",
              max: "3000-08-20",
            }}
            providerOptions={{ utils: MomentUtils }}
            // formClassName={classes.name}
            validate={requiredValidate}
            // options={{ format: helperFunc.datePickerFormat(), clearable: true }}
          />
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) =>
              formData.type !== "halfLeave" ? (
                <DateInput
                  disabled={formData && formData.type === "halfLeave"}
                  label="label.to"
                  source="toDate"
                  resource="leaves"
                  options={{
                    format: helperFunc.datePickerFormat(),
                    clearable: true,
                  }}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                  // formClassName={classes.name}
                  validate={requiredValidate}
                  // formClassName={classes.code}
                />
              ) : null
            }
          </FormDataConsumer>
        </div>
        <SectionTitle label="" />
        <div style={{ display: "inline" }}>
          {/* <TextInput
            // disabled={props && Object.keys(props.record).length === 0 ? false : true}
            source="subject"
            resource="leaves"gggg\
            formClassName={classes.name}
            validate={subjectValidate}
            multiline={true}
          /> */}
          <TextInput
            // disabled={props && Object.keys(props.record).length === 0 ? false : true}
            label="label.description"
            resource="leaves"
            source="description"
            multiline={true}
            formClassName={classes.address}
            validate={resoneValidate}
          />

          <SectionTitle label="" />

          {/* {user.designation == "HR" ? (
            <ReferenceInput
              formClassName={classes.code}
              filter={{ isActive: true }}
              source="behalfOf"
              reference="employees"
            >
              <AutocompleteInput
                optionText={(choice: BehalfOf) => `${choice.name}`}
              />
            </ReferenceInput>
          ) : null} */}
        </div>
        {user?.designation === "HR" ||
        user?.type === "employee" ||
        user?.type === "company" ? (
          <FileInput
            source="documents"
            label="Documents (Max. 20MB)"
            accept="image/*"
            multiple
          >
            <FileField source="src" />
          </FileInput>
        ) : null}
        {docs.length > 0 ? (
          <span style={{ textAlign: "center" }}>Uploaded Documents</span>
        ) : null}
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            return (
              <div style={{ display: "inline" }}>
                {docs.length > 0
                  ? docs.map((el: any, i: any) => (
                      <div style={{ margin: "5px" }} className="card-actions">
                        <div className="card-action-icon">
                          {(user?.designation === "HR" ||
                            user?.type === "employee" ||
                            user?.type === "company") &&
                          i == 0 ? (
                            <RemoveCircleOutlineIcon
                              // onClick={() => remove(i, formData)}
                              onClick={() => handleOpenClick(i, formData)}
                              style={{ color: "red" }}
                            />
                          ) : null}
                          <GetAppIcon
                            onClick={() =>
                              helperFunc.imageDownloader(
                                `${uploadApiUrl + el}`,
                                el
                              )
                            }
                            style={{ color: "blue" }}
                          />
                        </div>
                        <img
                          className="action-img"
                          alt={el}
                          style={{
                            height: "150px",
                            width: "inherit",
                            objectFit: "contain",
                          }}
                          src={uploadApiUrl + el}
                        />
                      </div>
                    ))
                  : null}
              </div>
            );
          }}
        </FormDataConsumer>
        {props && props.resource === "teamLeave" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <RadioButtonGroupInput
                row={false}
                fullWidth
                formClassName={classes.code}
                label="label.approvalStatus"
                source="isApprove"
                resource="leaves"
                choices={
                  formData.isApprove === "approve"
                    ? aprroverStatus
                    : approvalStatus
                }
                onClick={() => setText("")}
              />
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        {props && props.resource === "teamLeave" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData.isApprove === "reject" ||
              formData.isApprove === "cancel" ? (
                <TextInput
                  label="label.remarks"
                  resource="leaves"
                  source="reason"
                  fullWidth
                  multiline={true}
                  className={classes.reason}
                  validate={resoneValidate}
                  // defaultValue={null}
                  helperText={false}
                  // value={text}
                  // onChange={(e: any) => setText(e.target.value)}
                />
              ) : null
            }
          </FormDataConsumer>
        ) : null}

        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hidden}
        />
      </SimpleForm>
    </React.Fragment>
  ) : (
    <div className="form-content">
      <SimpleForm
        {...props}
        validate={
          props && Object.keys(props.record).length === 0 ? validatedate : null
        }
        label="Identifier"
      >
        {authProvider.isMenuShow("leaves", "update") &&
        user?.type !== "super" &&
        user?.type !== "company" ? (
          <Typography className={classes.text}>
            {props &&
              props.record &&
              props.record.employeeData &&
              props.record.employeeData.name}
          </Typography>
        ) : null}
        {user?.type == "super" ? (
          <CompanyReferenceInput></CompanyReferenceInput>
        ) : null}
        {user?.type == "company" || user?.type == "super" ? (
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <ChildCompanySelectInput
                filter={{ isActive: true, company: formData.company }}
                disabled={props && props.resource === "teamLeave"}
              ></ChildCompanySelectInput>
            )}
          </FormDataConsumer>
        ) : null}
        {user?.type == "company" || user?.type == "super" ? (
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) => {
              return (
                <EmployeeSelectInput
                  validate={requiredValidate}
                  filter={{
                    workplaceId: formData.workplaceId,
                    isActive: true,
                    company: formData.company,
                    childCompanyId: formData.childCompanyId,
                  }}
                  disabled={props && props.resource === "teamLeave"}
                ></EmployeeSelectInput>
              );
            }}
          </FormDataConsumer>
        ) : null}

        <SectionTitle label="" />

        <SectionTitle label="" />

        {props && props.resource !== "teamLeave" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => {
              return <LeaveApproverSelectInput filter={{ isActive: true }} />;
            }}
          </FormDataConsumer>
        ) : (
          ""
        )}
        <FormDataConsumer
          formClassName={
            props && props.resource !== "teamLeave"
              ? classes.code
              : classes.name
          }
        >
          {({ formData }: { formData: any }) => {
            return (
              <EmployeeLeaveTypeSelectInput
                filter={{ isActive: true }}
                disabled={props && props.resource === "teamLeave"}
              />
            );
          }}
        </FormDataConsumer>
        <SectionTitle label="" />

        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={formData?.type === "halfLeave"}
                    onChange={(event) => handleChange(event, formData)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={props && props.resource === "teamLeave"}
                  />
                }
                label="Half Leave"
              />
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => {
            return (
              formData?.type === "halfLeave" && (
                <RadioButtonGroupInput
                  row={true}
                  fullWidth
                  style={{
                    height: "70px",
                    marginTop: "-19px",
                    marginLeft: "120px",
                  }}
                  // formClassName={classes.email}
                  // label="label.leaves.type"
                  source="halfLeaveType"
                  label=""
                  choices={halfLeaveType}
                  disabled={props && props.resource === "teamLeave"}
                  // defaultValue={halfLeaveType[0].id}
                  className={classes.name}
                  // onClick={() => setText("")}
                />
              )
            );
          }}
        </FormDataConsumer>

        <SectionTitle label="" />

        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <DateInput
              label="label.from"
              source="fromDate"
              resource="leaves"
              validate={[required()]}
              // className={classes.date}
              options={{
                format: "DD/MM/YYYY",
                // ampm: true,
                clearable: true,
              }}
              inputProps={{
                min: "1900-08-10",
                max: "3000-08-20",
              }}
              disabled={props && props.resource === "teamLeave"}
              // defaultValue={today}
              providerOptions={{ utils: MomentUtils }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) =>
            (formData && formData.type === "halfLeave") ||
            (props && props.resource === "teamLeave") ? (
              <DateInput
                disabled={
                  (formData && formData.type === "halfLeave") ||
                  (props && props.resource === "teamLeave")
                }
                label="label.to"
                source={formData.type === "halfLeave" ? "fromDate" : "toDate"}
                resource="leaves"
                options={{
                  format: "DD/MM/YYYY",
                  clearable: true,
                }}
                inputProps={{
                  min: "1900-08-10",
                  max: "3000-08-20",
                }}
                // defaultValue={today}
                // className={classes.date}
                // style={{ background: "whitesmoke" }}
                providerOptions={{ utils: MomentUtils }}
              />
            ) : (
              <DateInput
                disabled={
                  (formData && formData.type === "halfLeave") ||
                  (props && props.resource === "teamLeave")
                }
                label="label.to"
                source={formData.type === "halfLeave" ? "fromDate" : "toDate"}
                resource="leaves"
                options={{
                  format: "DD/MM/YYYY",
                  clearable: true,
                }}
                inputProps={{
                  min: "1900-08-10",
                  max: "3000-08-20",
                }}
                validate={requiredValidate}
                // defaultValue={today}
                // className={classes.date}
                // style={{ background: "whitesmoke" }}
                providerOptions={{ utils: MomentUtils }}
              />
            )
          }
        </FormDataConsumer>

        <SectionTitle label="" />
        <TextInput
          label="label.remarks"
          resource="leaves"
          source="description"
          fullWidth
          multiline={true}
          style={{ width: "724px" }}
          // className={classes.reason}
          validate={resoneValidate}
          disabled={props && props.resource === "teamLeave"}
        />
        <SectionTitle label="" />
        {user?.designation === "HR" ||
        user?.type === "employee" ||
        user?.type === "company" ||
        user?.type === "super" ? (
          <ImageInput
            source="documents"
            label="Images/Documents (Max. 20MB)"
            accept="image/png, image/jpeg,application/pdf"
            multiple={true}
            placeholder={
              <p>
                Drop a picture/document to{" "}
                <span style={{ color: "blue" }}>upload </span>, or{" "}
                <span style={{ color: "blue" }}>click </span>to select it.
              </p>
            }
            classes={{ dropZone: classes.dropZone, p: classes.pTag }}
            options={{
              disabled: props && props.resource === "teamLeave" ? true : false,
            }}

            // onChange={fileChangedHandler}
          >
            {props &&
            props.location &&
            props.location.pathname === "/leave-management/create" ? (
              <ImageField
                source="src"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: " #FAFAFA",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <ImageField source="src" />
            )}
          </ImageInput>
        ) : null}
        {docs.length > 0 ? (
          <span style={{ textAlign: "center" }}>Uploaded Documents</span>
        ) : null}
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => {
            return formData.documents && !formData.documents.src ? (
              <div style={{ display: "inline" }}>
                {docs.length > 0
                  ? docs.map((el: any, i: any) => (
                      <div style={{ margin: "5px" }} className="card-actions">
                        <div className="card-action-icon">
                          {user?.designation === "HR" ||
                          user?.type === "employee" ||
                          user?.type === "company" ? (
                            <div>
                              <RemoveCircleOutlineIcon
                                onClick={() => handleOpenClick(i, formData)}
                                style={{ color: "red" }}
                              />
                            </div>
                          ) : null}
                          <GetAppIcon
                            onClick={() =>
                              helperFunc.imageDownloader(
                                `${uploadApiUrl + el}`,
                                el
                              )
                            }
                            style={{ color: "blue" }}
                          />
                        </div>
                        <img
                          className="action-img"
                          alt={el}
                          style={{
                            height: "150px",
                            width: "inherit",
                            objectFit: "contain",
                          }}
                          src={uploadApiUrl + el}
                        />
                      </div>
                    ))
                  : null}
              </div>
            ) : null;
          }}
        </FormDataConsumer>

        {props && props.resource === "teamLeave" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <RadioButtonGroupInput
                row={true}
                fullWidth
                formClassName={classes.email}
                label="label.approvalStatus"
                source="isApprove"
                resource="leaves"
                choices={
                  (formData.isApprove === "approve" ||
                    formData.isApprove === "cancel") &&
                  formData.approvedBy !== null
                    ? aprroverStatus
                    : formData.isApprove === "reject" &&
                      formData.rejectBy !== null
                    ? rejectedStatus
                    : approvalStatus
                }
                onClick={() => setText("")}
              />
            )}
          </FormDataConsumer>
        ) : null}

        <SectionTitle label="" />
        {props && props.resource === "teamLeave" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData.isApprove === "reject" ||
              formData.isApprove === "cancel" ? (
                <TextInput
                  label="label.remarks"
                  resource="leaves"
                  source="reason"
                  fullWidth
                  multiline={true}
                  className={classes.reason}
                  validate={resoneValidate}
                  // defaultValue={null}
                  helperText={false}
                  // value={text}
                  // onChange={(e: any) => setText(e.target.value)}
                />
              ) : null
            }
          </FormDataConsumer>
        ) : null}

        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hidden}
          // defaultValue={false}
        />
        <DialogueBox
          handleCloseClick={handleCloseClick}
          open={showDialog}
          remove={() => remove(index, imageData)}
          // allotmentDialog={allotmentDialog}
        />
      </SimpleForm>
    </div>
  );
};
const LeaveTitle: FC<FieldProps<Leave>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];
const resoneValidate = [
  required(),
  maxLength(500),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
