import * as React from "react";
import PropTypes from "prop-types";
import { useDataProvider } from "react-admin";
import MobileNavBar from "../components/MobileNavBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery, Theme } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "./themes";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Contact = () => {
  const dataProvider = useDataProvider();

  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const validate = () => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    if (name.length === 0) {
      nameError = "Name Is Required";
    }
    if (email.length === 0) {
      emailError = "Email is Required";
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      emailError = "Email is Invalid";
    }

    if (phone.length === 0) {
      phoneError = "Phone No. is Required";
    } else if (!/^\d+$/.test(phone)) {
      phoneError = "Phone No. Should contain digits only";
    } else if (phone.length > 10 || phone.length < 10) {
      phoneError = "Phone No. Should contain digits 10 only";
    }
    // if (this.state.headerText.length === 0) {
    //   headerTextError = 'Header Text Is Required';
    // }
    // if (this.state.footerText.length === 0) {
    //   footerTextError = 'Footer Text Is Required';
    // }
    // if (this.state.logo.length === 0) {
    //   logoError = 'Logo Is Required';
    // }

    if (nameError || emailError || phoneError) {
      setNameError(nameError);
      setEmailError(emailError);
      setPhoneError(phoneError);
      return false;
    }
    return true;
  };

  const contactUS = (e: any) => {
    const params = {
      name: name,
      email: email,
      company: company,
      phone: phone,
      subject: subject,
      message: message,
    };
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      setNameError("");
      setEmailError("");
      setPhoneError("");
      dataProvider
        .callCustom("/public/contact-us/create", params)
        .then((response: any) => {
          setSuccess(true);
          setLoading(false);
          setName("");
          setEmail("");
          setCompany("");
          setPhone("");
          setSubject("");
          setMessage("");
          setTimeout(() => {
            setSuccess(false);
          }, 10000);
          //  alert('Your request has been placed successfully. We will get back to you soon!!!');
        })
        .catch((err: any) => {
          setLoading(false);
          setFailed(true);
          setErrMsg(err.message);
          setTimeout(() => {
            setFailed(false);
            setErrMsg("");
          }, 10000);
        });
    }
  };

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      {/* <!--Header Section Start--> */}
      <Header />
      {/* <!--Header Section End--> */}

      {/* <!-- Breadcrumb Start --> */}
      <section className="breadcrumb-area">
        <div className="breadcrumb-shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inn">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h2>
                    <span>Contact Us</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcrumb End --> */}

      {/* <!-- Contact Form Start --> */}
      <section className="contact-form section_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h2>
                  Get in <span>Touch</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div
                className="contact-form-wrapper wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                {failed === true ? (
                  <div className="alert alert-danger" role="alert">
                    {errMsg}
                  </div>
                ) : null}
                {success === true ? (
                  <div className="alert alert-success" role="alert">
                    Your request has been placed successfully. We will get back
                    to you soon!!!
                  </div>
                ) : null}
                <form className="was-validated">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="name">Name </label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          maxLength={50}
                          className={`input-field ${
                            nameError ? "border-danger" : ""
                          }`}
                          placeholder="Enter Your Name"
                          required
                        />
                        <span style={{ color: "red", fontSize: 10 }}>
                          {nameError}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="email">Email </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          className={`input-field ${
                            emailError ? "border-danger" : ""
                          }`}
                          placeholder="Enter Your Email"
                          required
                        />
                        <span style={{ color: "red", fontSize: 10 }}>
                          {emailError}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="subjict">Company </label>
                        <input
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          type="text"
                          className="input-field"
                          id="subjict"
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="phone">Phone </label>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          maxLength={10}
                          className={`input-field ${
                            phoneError ? "border-danger" : ""
                          }`}
                          id="phone"
                          placeholder="Enter Your Phone No"
                        />
                        <span style={{ color: "red", fontSize: 10 }}>
                          {phoneError}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="subjict">Subject </label>
                        <input
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          type="text"
                          className="input-field"
                          id="subjict"
                          placeholder="Write Your subject"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group button-area">
                        <label htmlFor="message">Message </label>
                        <textarea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          id="message"
                          className="input-field textarea"
                          placeholder="Write Your Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group button-area">
                        <button
                          onClick={(e) => contactUS(e)}
                          disabled={loading}
                          type="button"
                          className="theme-btn"
                        >
                          {loading ? (
                            <React.Fragment>
                              Send Message{" "}
                              <CircularProgress
                                size={20}
                                thickness={2}
                                style={{ alignSelf: "end", color: "aliceblue" }}
                              />{" "}
                            </React.Fragment>
                          ) : (
                            "Send Message"
                          )}{" "}
                          <span className="fa fa-chevron-right"></span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <div
                className="address-area wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h4 className="title">Contact Information</h4>
                <ul className="address-list">
                  <li>
                    <p>
                      <i className="fa fa-map-marker"></i> 401-405, One World
                      West, 200, Sardar Patel Ring Rd, Ambli, Ahmedabad, 380058,
                      Gujarat , India
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-phone"></i>
                      (+91) 76239 14914
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-envelope"></i>
                      info@turnout.cloud
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-globe"></i>
                      www.turnout.cloud
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Form End --> */}

      {/* <!-- Footer Start --> */}
      <Footer />
    </React.Fragment>
  );
};

Contact.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const ContactWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Contact {...props} />
  </ThemeProvider>
);

export default ContactWithTheme;
