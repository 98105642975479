import RequestList from "./RequestList";
import { RequestIcon } from "../svgIcon";
import { RequestForm } from "./requestCommon";

export default {
  list: RequestList,
  create: RequestForm,
  // edit: RequestEdit,
  icon: RequestIcon,
};
