import React, { useState, Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { collegeReportStyle } from "../helperStyle";
import CommonReportForm from "../components/commonReportForm";

const SubjectwiseAttendanceReport = (props: any): any => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const useStyles = makeStyles(collegeReportStyle);
  const classes = useStyles();
  const [responseData, setResponseData] = useState([]);
  const [subArr, setSubArr] = useState([]);
  const headerArr = ["Sr.No", "Student Name"];

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <CommonReportForm
        resource={props.resource}
        apiName={"report4"}
        responseData={setResponseData}
        subArr={setSubArr}
      />

      {(responseData && responseData.length === 0) ||
      responseData === undefined ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smTableDiv : classes.rtable}>
          <React.Fragment>
            <TableContainer
              className={
                isXSmall ? classes.smtableContainer : classes.container
              }
            >
              <Table
                className={isXSmall ? classes.smRootDiv : classes.table}
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    {headerArr && headerArr.length > 0
                      ? headerArr.map((item: any) => {
                          return (
                            <TableCell
                              className={classes.header}
                              align="center"
                            >
                              {item}
                            </TableCell>
                          );
                        })
                      : null}
                    {subArr
                      ? subArr &&
                        subArr.map((subject: any, index: number) => {
                          return (
                            <TableCell
                              className={classes.header}
                              align="center"
                            >
                              {subject && subject.name}
                            </TableCell>
                          );
                        })
                      : null}
                    <TableCell className={classes.header}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ justifyContent: "center" }}>
                  {responseData &&
                    responseData.map((data: any, rowIndex: number) => {
                      return (
                        <Fragment>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {rowIndex + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {data && data.name}
                            </TableCell>
                            {subArr &&
                              subArr.map((item: any, index: any) => {
                                return (
                                  <Fragment key={index + 1}>
                                    <TableCell
                                      align="center"
                                      className={classes.tableCell}
                                    >
                                      {data &&
                                      data.data &&
                                      item &&
                                      item.name &&
                                      data.data[item.name]
                                        ? data.data[item.name]
                                        : 0}
                                    </TableCell>
                                  </Fragment>
                                );
                              })}
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {data && data.total ? data.total : 0}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};
export default SubjectwiseAttendanceReport;
