import React, { useState, useRef, useEffect } from "react";
import { useDataProvider, Button } from "react-admin";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogueBox from "./dialogueBox";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";
import { toastError } from "../components/toast";

// const seed = [
//   {
//     id: "123",
//     title: "CEO",
//     subtitle: "CEO Name",
//     isDirectory: true,
//     expanded: true,
//     children: [
//       { id: "456", title: "Human Resource", subtitle: "Hr" },
//       {
//         id: "789",
//         title: "Product Owner",
//         subtitle: "Pro Own1",
//         expanded: true,
//         children: [
//           {
//             id: "789",
//             title: "Product Manager",
//             subtitle: "Pro Own1",
//             expanded: true,
//             children: [
//               {
//                 id: "789",
//                 title: "Emp1",
//                 subtitle: "Emp1",
//                 expanded: true,
//                 children: [],
//               },
//               {
//                 id: "789",
//                 title: "Emp2",
//                 subtitle: "Emp2",
//                 expanded: true,
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         id: "789",
//         title: "Product Owner",
//         subtitle: "Pro Own2",
//         expanded: true,
//         children: [],
//       },
//     ],
//   },
// ];

const Tree = () => {
  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [treeData, setTreeData] = useState();
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState(false);
  const [nodeInfo, setNodeInfo] = useState(false);
  const dataProvider = useDataProvider();

  const inputEl = useRef();

  function createNode() {
    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: null,
      expandParent: true,
      getNodeKey,
      newNode: {
        id: "123",
        title: value,
      },
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
  }

  useEffect(() => {
    const resourcePath = `designations/list-hierarchy`;
    dataProvider
      .callCustom(resourcePath, {})
      .then((response) => {
        setTreeData(response?.data?.list);
      })
      .catch((err) => {});
  }, []);

  const handleDialogueClose = () => setDeleteDialogueOpen(false);
  const handleDialogueOpen = (nodeInfo) => {
    setNodeInfo(nodeInfo);
    setDeleteDialogueOpen(true);
  };

  function updateNode(rowInfo) {
    const { node, path } = rowInfo;
    const { children } = node;

    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        children,
        title: value,
      },
    });

    setTreeData(newTree);

    inputEl.current.value = "";
  }

  function addNodeChild(rowInfo) {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        title: value,
      },
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  }

  function addNodeSibling(rowInfo) {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 2],
      expandParent: true,
      getNodeKey,
      newNode: {
        title: value,
      },
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  }

  function removeNode() {
    const { path } = nodeInfo;
    setTreeData(
      removeNodeAtPath({
        treeData,
        path,
        getNodeKey,
      })
    );
  }

  function updateTreeData(treeData) {
    setTreeData(treeData);
  }

  function expand(expanded) {
    setTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }

  const alertNodeInfo = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
      .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
      .join(",\n   ");

    global.alert(
      "Info passed to the icon and button generators:\n\n" +
        `node: {\n   ${objectString}\n},\n` +
        `path: [${path.join(", ")}],\n` +
        `treeIndex: ${treeIndex}`
    );
  };

  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const saveForm = () => {};

  const handleMoveNode = (nodeData) => {
    dataProvider
      .callCustom(`designations/update-parentId/${nodeData?.node?.id}`, {
        parentId: nodeData?.nextParentNode?.id,
      })
      .then((response) => {})
      .catch((err) => {
        toastError(err.message);
      });
  };

  return (
    <div
      style={{
        borderRadius: "5px",
        margin: "16px",
        padding: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
    >
      <div className="row">
        {/* <div className="col-md-6">
          <div className="ml-2">
            <input
              ref={inputEl}
              type="text"
              placeholder="Add Designation"
              className="add-input"
            />
            <AddCircleIcon
              onClick={createNode}
              style={{
                fontSize: 26,
                color: "#283593",
                cursor: "pointer",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
            <ExpandMoreIcon
              onClick={expandAll}
              style={{
                fontSize: 26,
                color: "#283593",
                cursor: "pointer",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
            <ExpandLessIcon
              onClick={collapseAll}
              style={{
                fontSize: 26,
                color: "#283593",
                cursor: "pointer",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
          </div>
        </div> */}
        {/* <div
          className="col-md-6"
          style={{ display: "flex", justifyContent: "start" }}
        >
          <div className="mr-2">
            <form
              style={{ display: "inline-block" }}
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <label htmlFor="find-box" style={{ position: "relative" }}>
               
                <input
                  id="find-box"
                  type="text"
                  className="pr-2 search-input"
                  value={searchString}
                  onChange={(event) => setSearchString(event.target.value)}
                  placeholder="Search"
                />
                <SearchIcon
                  style={{
                    position: "absolute",
                    right: "7px",
                    top: "3px",
                    color: "#b7c7d1",
                  }}
                />
              </label>
              <span>
                <ArrowLeftIcon
                  disabled={!searchFoundCount}
                  onClick={selectPrevMatch}
                  style={{
                    fontSize: "30px",
                    color: "#283593",
                    cursor: "pointer",
                  }}
                />
                <ArrowRightIcon
                  disabled={!searchFoundCount}
                  onClick={selectNextMatch}
                  style={{
                    fontSize: "30px",
                    color: "#283593",
                    cursor: "pointer",
                  }}
                />
              </span>

             

              <span>
                &nbsp;
                {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                &nbsp;/&nbsp;
                {searchFoundCount || 0}
              </span>
            </form>
          </div>
        </div> */}
      </div>

      <div
        style={{
          height: "100vh",
        }}
      >
        <SortableTree
          treeData={treeData}
          onChange={(treeData) => updateTreeData(treeData)}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={(matches) => {
            setSearchFoundCount(matches.length);
            setSearchFocusIndex(
              matches.length > 0 ? searchFocusIndex % matches.length : 0
            );
          }}
          // rowDirection="rtl"
          style={{ right: "45px" }}
          canDrag={({ node }) => !node.dragDisabled}
          theme={FileExplorerTheme}
          generateNodeProps={(rowInfo) => ({
            // title: rowInfo.node.label,
            // subtitle: rowInfo.node.subTitle,
            buttons: [
              <div>
                {/* <button
                  label="Add Sibling"
                  onClick={(event) => addNodeSibling(rowInfo)}
                >
                  Add Sibling
                </button>
                <button
                  label="Add Child"
                  onClick={(event) => addNodeChild(rowInfo)}
                >
                  Add Child
                </button> */}
                {/* <button label="Update" onClick={(event) => updateNode(rowInfo)}>
                  Update
                </button> */}
                {/* <DeleteIcon
                  style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                  onClick={(event) => handleDialogueOpen(rowInfo)}
           
                /> */}
                {/* <span>
                  <i label="Delete" onClick={(event) => removeNode(rowInfo)}>
                    Remove
                  </i>
                </span> */}

                {/* <button
                  label="Alert"
                  onClick={(event) => alertNodeInfo(rowInfo)}
                >
                  Info
                </button> */}
              </div>,
            ],
            style: {
              height: "50px",
            },
          })}
          onMoveNode={handleMoveNode}
        />

        <DialogueBox
          label="Node"
          open={deleteDialogueOpen}
          remove={removeNode}
          handleCloseClick={handleDialogueClose}
        />
      </div>
    </div>
  );
};

export default Tree;
