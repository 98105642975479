import * as React from "react";
import { FC } from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { EditButton } from "react-admin";
import { DatagridProps, Visitor } from "../types";
import AttendanceLogo from "./AttendanceLogo";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Identifier } from "ra-core";
import AttandanceShow from "./AttendanceShow";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-2px",
  },
  editButton: {
    marginTop: "-220px",
    color: "#00008b",
  },
  gridList: {
    width: "100%",
    margin: 0,
  },
  mobileGridList: {
    width: window.screen.width,
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  icon: {
    color: "white",
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
  width,
  nbItems = 20,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={
          getColsForWidth(width) === 2
            ? classes.mobileGridList
            : classes.gridList
        }
      >
        {" "}
        {times(nbItems, (key) => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadedGridList: FC<GridProps> = ({ ids, data, basePath, width }) => {
  const location: any = {
    in: "In",
    out: "Out",
  };
  const classes = useStyles();
  if (!ids || !data) return null;
  const user = helperFunc.getUserData();
  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={
          getColsForWidth(width) === 2
            ? classes.mobileGridList
            : classes.gridList
        }
      >
        {ids !== null &&
          ids.map((id) => (
            <GridListTile
            // @ts-ignore
            // component={Link}
            // key={id}
            // to={linkToRecord(basePath, data[id].id)}
            >
              <AttendanceLogo record={data[id]}> </AttendanceLogo>

              <GridListTileBar
                className={classes.tileBar}
                title={data[id] && data[id].employee && data[id].employee.name}
                subtitle={
                  data[id] &&
                  data[id].time &&
                  location[data[id].authType] +
                    "  " +
                    "Time" +
                    "  " +
                    ":" +
                    helperFunc.commonDateFormat(
                      data[id] && data[id].time,
                      "HH:mm:ss"
                    )
                }
                actionIcon={[
                  user.designation === "HR" ||
                  user.type === "super" ||
                  user.type === "company" ? (
                    <EditButton
                      label=""
                      className={classes.editButton}
                      basePath="/college-attendance"
                      record={data[id]}
                    />
                  ) : null,
                  <AttandanceShow record={data[id]} />,
                ]}
              />
            </GridListTile>
          ))}
      </MuiGridList>
    </div>
  );
};

interface GridProps extends DatagridProps<Visitor>, WithWidth {
  selectedRow?: Identifier;
}

const GridList: FC<GridProps> = ({ loaded, ...props }) =>
  loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);
