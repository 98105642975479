import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import GroupIcon from "@material-ui/icons/Group";

import CardIcon from "./CardIcon";
import { EmployeeIcon } from "../svgIcon";

interface Props {
  value?: number;
  data?: JSON;
  icon?: any;
  title?: string;
  bgColor?: string;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    // borderBottom: `4px solid #4caf50`,
  },
  title: {},
});

const CustomCard: FC<Props> = ({ value, icon, title, bgColor }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <CardIcon Icon={icon} bgColor={bgColor ? bgColor : "#4caf50"} />
      <Card
        className={classes.card}
        style={{ borderBottom: `4px solid ${bgColor}` }}
      >
        <Typography className={classes.title} color="textSecondary">
          {translate(title)}
        </Typography>
        <Typography variant="h5" component="h2">
          {value ? value : 0}
        </Typography>
      </Card>
    </div>
  );
};

export default CustomCard;
