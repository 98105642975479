import * as React from "react";
import {
  SimpleForm,
  useTranslate,
  required,
  FormDataConsumer,
  BooleanInput,
  SelectInput,
  maxLength,
  TextInput,
  DateTimeInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import { deviceLocation } from "../helperChoice";
import { attendanceStyles } from "../helperStyle";
import useDeepCompareEffect from "use-deep-compare-effect";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";

export const validatePasswords = ({ time }: { time: string }) => {
  const errors = {} as any;
  if (time && new Date(time) >= new Date()) {
    errors.time = "Date Should be smaller then current date";
  }
  return errors;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(attendanceStyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  let [update, setupdate] = React.useState(false);
  let newClass = classes;
  const user = helperFunc.getUserData();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  // const empSearchByWorkPlace = "workplaces/employee_search";
  useDeepCompareEffect(() => {
    if (isSmall) {
      classes.code = newClass.name;
    } else {
      classes.code = newClass.code;
    }
    setupdate(update ? false : true);
  }, [isSmall, classes]);

  return (
    <React.Fragment>
      {isXsmall ? (
        <h2
          style={{
            display: "flex",
            fontSize: 25,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          {props &&
          props.location &&
          props.location.pathname !== "/employee-vehicle-attandence/create"
            ? "Update Attendance"
            : "Add Attendance"}
        </h2>
      ) : null}

      <SimpleForm {...props} validate={validatePasswords}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
              validate={nameValidate}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <EmployeeSelectInput
              filter={{
                isActive: true,
                childCompanyId: formData.childCompanyId,
                company: formData.company,
              }}
              validate={nameValidate}
            ></EmployeeSelectInput>
          )}
        </FormDataConsumer>
        {/* <SectionTitle label="" /> */}
        <FormDataConsumer formClassName={isSmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <TextInput
              autoFocus
              source="carNumber"
              resource="employeeVehicle"
              formClassName={classes.name}
              validate={nameValidate}
            />
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <FormDataConsumer formClassName={isSmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <SelectInput
              resettable
              disabled={formData.mode === "auto" ? true : false}
              label="label.device_location"
              reference="authType"
              source="authType"
              validate={[required()]}
              formClassName={classes.code}
              choices={deviceLocation}
            />
          )}
        </FormDataConsumer>
        {/* <SectionTitle label="" /> */}
        <DateTimeInput
          formClassName={isSmall ? null : classes.code}
          label="label.date"
          source="time"
          validate={[required()]}
          options={{
            format: helperFunc.datePickerFormat("HH:mm"),
            ampm: false,
            clearable: true,
          }}
        />
        <SectionTitle label="" />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="designations"
          defaultValue="true"
        />
        <SectionTitle label="" />
      </SimpleForm>
    </React.Fragment>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
