import React from "react";
import { Create } from "react-admin";
import CommonForm from "../zones/commonForm";


const ZoneCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonForm redirect={'/zones'} {...props} />
    </Create>
  );
};

export default ZoneCreate;
