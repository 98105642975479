import React from "react";
import { TextInput, required, FileField, FileInput } from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";

type Props = {};

const useStyles = makeStyles({
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
});

const SAML = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <TextInput
        label="Server Redirect URL"
        source="smsConfig.url"
        validate={requiredValidate}
        className={classes.code}
      />

      <div>
        <TextInput
          label="Client Redirect URL"
          source="smsConfig.url"
          validate={requiredValidate}
          className={classes.name}
        />

        <FileInput
          source="idpCertificate"
          label="IDP Certificate"
          accept="pdf/*"
        >
          <FileField source="src" />
        </FileInput>
      </div>
      <div>
        <FileInput source="spCertificate" label="SP Certificate" accept="pdf/*">
          <FileField source="src" />
        </FileInput>
        <FileInput
          source="xCertificate"
          label="X.509 Certificate"
          accept="pdf/*"
        >
          <FileField source="src" />
        </FileInput>
      </div>
    </>
  );
};

const requiredValidate = [required()];

export default SAML;
