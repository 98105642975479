import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import helperFunc from "../helperFuncs";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AttendanceDialogueBox from "./AttendanceDialogueBox";

export default function EditMenu(props) {
  const { className, setAnchorEl, setClickedEmp, anchorEl, clickedEmp } = props;

  const open = Boolean(anchorEl);

  const user = helperFunc.getUserData();

  const ITEM_HEIGHT = 48;

  const options = [
    { label: "Change Attendance", isPermission: user.isChangeAttendance },
    { label: "Remarks", isPermission: user.isAttendanceRemark },
  ];

  const [activeMenu, setActiveMenu] = React.useState("");
  const [dialogueOpen, setDialogueOpen] = React.useState(false);

  const handleClick = (event, record) => {
    setClickedEmp(record);
    setAnchorEl(event.currentTarget);
  };

  const handleDialogueOpen = () => {
    setDialogueOpen(true);
  };
  const handleDialogueClose = () => {
    setDialogueOpen(false);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (typeof option !== "object") {
      setActiveMenu(option);
      handleDialogueOpen();
    } else {
      setActiveMenu("");
    }
  };

  return (
    <div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map(
          (option) =>
            option?.isPermission && (
              <MenuItem
                key={option?.label}
                onClick={() => handleClose(option?.label)}
              >
                {option?.label}
              </MenuItem>
            )
        )}
      </Menu>

      {user.isChangeAttendance || user.isAttendanceRemark ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event)}
          className={className}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        ""
      )}
      <AttendanceDialogueBox
        open={dialogueOpen}
        activeMenu={activeMenu}
        setAnchorEl={setAnchorEl}
        handleCloseClick={handleDialogueClose}
        clickedEmp={clickedEmp}
      ></AttendanceDialogueBox>
    </div>
  );
}
