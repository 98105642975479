import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MapIcon from "@material-ui/icons/Map";
import { FieldProps, Attendance } from "../types";
import { MyMapComponent } from "../components/map";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    maxWidth: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  lblName: {
    right: theme.spacing(0),
  },
  lblLocation: {
    left: theme.spacing(1),
  },
  lblDate: {
    left: theme.spacing(1),
  },
  mapIcon: {
    color: "#ffffff",
  },
}));

interface State {
  data?: any;
  attendanceData?: any;
}

const MapShow: FC<FieldProps<Attendance>> = (record: any) => {
  // let inLoc = record.record.inLoc ? record.record.inLoc : {}
  // let outLoc = record.record.outLoc ? record.record.outLoc : {}
  // inLoc.location = "In";
  // outLoc.location = "Out";
  // let location: any = [];
  // let InLocation: boolean = true;
  // location = [inLoc, outLoc, ...location];
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [apicall, setApiCall] = useState(false);

  const [state, setState] = useState<State>({});
  const { attendanceData } = state;
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   InLocation = event.target.checked;
  //   setState(InLocation);
  // };

  const handleClick = () => {
    setOpen(true);
    setApiCall(true);
  };
  const handleClose = () => setOpen(false);

  const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      width: "100%",
    },
  }))(MuiDialogActions);
  return record ? (
    <div className={classes.root}>
      <IconButton aria-label="Show" onClick={handleClick}>
        <MapIcon className={classes.mapIcon} />
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          classes={classes}
          onClose={handleClose}
          style={{ width: 400 }}
        >
          Location
        </DialogTitle>
        <DialogContent dividers>
          <MyMapComponent isMarkerShown={true} record={record} />
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export default MapShow;
