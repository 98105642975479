import * as React from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  TextInput,
  downloadCSV,
  BulkDeleteButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  DateInput,
} from "react-admin";
import { regex } from "ra-core";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import { Fragment } from "react";
import ShiftLinkField from "./ShiftLinkField";
import MobileGrid from "./MobileGrid";
import helperFunc from "../helperFuncs";
import jsonExport from "jsonexport/dist";
import { isActive } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, [
      {
        company: "",
      },
    ]);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      // <ReferenceInput source="company" label="label.client" reference="companies">
                      //   <SelectInput
                      //     resettable={true}
                      //     filter={{ isActive: true }}
                      //     optionText={(choice: Company) => `${choice.name}`}
                      //     helperText={false}
                      //   />
                      // </ReferenceInput>
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <TextInput
                      source="code"
                      helperText={false}
                      resettable={true}
                      validate={[
                        regex(/.*\S.*/, "Only whitespaces are not allowed"),
                      ]}
                      format={helperFunc.codeFormatter}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      allowEmpty={true}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                      resettable={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    filters,
    showFilter,
    displayedFilters,
    handleDrawerOpen,
    open,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const ShiftFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      isDeleted,
      isActive,
      full,
      half,
      workingDays,
      workingHours,
      offDays,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      name,
      code,
      ...dataToExport
    } = record; // omit fields
    const user = helperFunc.getUserData();
    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["Name"] = name;
    dataToExport["Code"] = code;
    dataToExport["Full Shift"] = full && full.inTime ? full.inTime : "N/A"; // add a field
    dataToExport["Half Shift"] = half && half.outTime ? half.outTime : "N/A"; // add a field
    dataToExport["Working Monday"] =
      workingDays && workingDays.isMonday ? "Yes" : "No";
    dataToExport["Working Tuesday"] =
      workingDays && workingDays.isTuseday ? "Yes" : "No";
    dataToExport["Working Wednesday"] =
      workingDays && workingDays.isWednesday ? "Yes" : "No";
    dataToExport["Working Thursday"] =
      workingDays && workingDays.isThursday ? "Yes" : "No";
    dataToExport["Working Thursday"] =
      workingDays && workingDays.isThursday ? "Yes" : "No";
    dataToExport["Working Friday"] =
      workingDays && workingDays.isFriday ? "Yes" : "No";
    dataToExport["Working Saturday"] =
      workingDays && workingDays.isSaturday ? "Yes" : "No";
    dataToExport["Working Sunday"] =
      workingDays && workingDays.isSunday ? "Yes" : "No";
    dataToExport["Working Sunday"] =
      workingDays && workingDays.isSunday ? "Yes" : "No";
    dataToExport["FullDay Hours"] =
      workingDays && workingHours.full ? workingHours.full : "N/A";
    dataToExport["HalfDay Hours"] =
      workingDays && workingHours.half ? workingHours.half : "N/A";
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Status"] = isActive ? "Yes" : "No"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Shifts Report"); // download as 'xxxx.csv` file
    }
  );
};

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    {/* <ResetViewsButton label="Reset Views" {...props} /> */}
    {/* default bulk delete action */}
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const ShiftList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <List
        className="list-view2"
        exporter={exporter}
        {...props}
        filters={
          isSmall ? (
            <ShiftFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <ShiftFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        // aside={<ShiftListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <ShiftLinkField />
            <TextField source="code" format={helperFunc.codeFormatter} />
            {helperFunc.getUserData().type === "super" ? (
              <TextField label="label.client" source="company.name" />
            ) : null}
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField source="isActive" label="label.status" />
          </Datagrid>
        )}
      </List>
    </div>
  );
};
export default ShiftList;
