import CompanyList from "./CompanyList";
import CompanyEdit from "./CompanyEdit";
import CompanyCreate from "./CompanyCreate";
import { CompanyIcon } from "../svgIcon";

export default {
  list: CompanyList,
  create: CompanyCreate,
  edit: CompanyEdit,
  icon: CompanyIcon,
};
