import React, { useState, useEffect } from "react";
import { Button, useDataProvider } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const FireAlertDialogueBox = (props: any) => {
  const isFireConfiguration =
    process.env.REACT_APP_DATA_PROVIDER_IS_FIRE_CONFIGURATION;
  const handleClick = () => {
    props.remove();
    props.handleCloseClick();
  };

  const [fireData, setFireData] = useState() as any;
  const [open, setOpen] = useState() as any;

  const dataProvider = useDataProvider();

  useEffect(() => {
    try {
      setInterval(async () => {
        const resourcePath = `alerts/fire`;
        {
          isFireConfiguration === "true" &&
            dataProvider
              .callCustom(resourcePath, {})
              .then((response: any) => {
                if (response?.data?.item?.alertStatus === true) {
                  setFireData(response?.data?.item);
                  setOpen(true);
                } else {
                  setOpen(false);
                }
              })
              .catch((err: any) => {});
        }
      }, 90000);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleCloseClick}
        aria-label="Are you sure?"
      >
        {/* <DialogTitle>Deleting Image</DialogTitle> */}
        <DialogContent>
          {fireData !== undefined && (
            <div className="d-flex align-content-center justify-content-center">
              <div className="fire-div pl-2">
                <div className="attention-fire-div">Attention! Fire!</div>
                <div className="building-fire-div">Fire in the Building.</div>
                <div className="premise-fire-div">
                  Please evacuate the Premises.
                </div>
              </div>
              <div>
                <img
                  src="assets/img/fire1.png"
                  height={80}
                  width={80}
                  className="fire-img"
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button
            // disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={handleClick}
          >
            <CheckCircleIcon />
          </Button> */}
          {/* <Button
            label="ra.action.cancel"
            onClick={props.handleCloseClick}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FireAlertDialogueBox;
