import React from "react";

export const DashboardIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M300,10.6c0-7.8-2.6-10.6-9.8-10.6C196.8,0.2,103.4,0.1,10,0.1C1.2,0.1,0,1.4,0,11.2c0,69.3,0,138.6,0,207.9
   c0,10.1,1.1,11.3,10.6,11.3c32.8,0,65.6,0.1,98.4,0c19.3-0.1,16.6,0.7,16.6,18.6c0,19.1-12.4,35-29.6,38c-2.6,0.5-5.3,0.6-8,0.9
   c0,4.4,0,8.1,0,12.1c41.5,0,82.7,0,123.8,0c0-4.4,0-8.2,0-11.9c-27.2-4-37.7-16.8-37.6-45.5c0-11.3,0.7-12.2,11.2-12.2
   c34.8,0,69.7,0,104.5,0c8.8,0,10-1.4,10-11.1C299.9,149.8,299.9,80.2,300,10.6z M135.5,160.2c-19.7-7.1-31.1-26.4-28.2-48.1
   c2.5-18.5,19-33.7,39.3-35.5c0,12.6,0.3,25.2-0.1,37.8c-0.2,5.3,1.7,8.1,6.3,10.5c10.9,5.8,21.4,12.3,32.5,18.7
   C174.7,160.1,153.9,166.8,135.5,160.2z M188.8,137.8c-10.8-6.2-21.3-12.1-31.7-18.3c-1.5-0.9-3.5-2.8-3.5-4.3
   c-0.3-12.8-0.2-25.5-0.2-38.3C183.2,78.2,202.1,110.7,188.8,137.8z"
      />
    </svg>
  );
};
export const AboutIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M158.8,300c-5.9,0-11.7,0-17.6,0c-0.8-0.2-1.5-0.4-2.3-0.5c-21-1.4-40.9-7-59.4-17.1C37.4,259,11.5,223.6,2.4,176.1
	c-1.1-5.7-1.6-11.5-2.4-17.3c0-5.9,0-11.7,0-17.6c0.2-0.8,0.4-1.7,0.5-2.5C2,117.8,7.4,97.9,17.6,79.6C45.3,30.5,87.4,3.7,143.7,0.3
	c29.7-1.8,57.7,5.3,83,21c45.4,28.1,69.9,69.1,73,122.4c1.6,27.1-4.3,52.9-17.4,76.6c-23.3,42.3-58.8,68.2-106.3,77.3
	C170.4,298.7,164.6,299.2,158.8,300z M183.6,222.5c-4.4,0.9-8.5,2.2-12.7,2.7c-11.7,1.3-16.8-3.8-14.6-15.3c2.2-11,5.2-21.8,8-32.6
	c2.7-10.2,6.2-20.1,6.5-30.8c0.3-8.8-2.3-16.2-9.8-21.3c-7.5-5.1-16.1-5.7-24.8-4.7c-9.7,1.1-18.6,5-27.6,8.5
	c-0.7,0.3-1.4,1.1-1.6,1.8c-0.8,2.9-1.5,5.8-2.2,8.7c0.4,0.1,0.6,0.2,0.8,0.2c0.7-0.2,1.3-0.4,1.9-0.6c5.6-2,11.3-3.1,17.2-1.9
	c4.2,0.9,6.5,2.7,6.7,6.9c0.2,4.7,0.1,9.6-0.9,14.2c-2.4,10.2-5.3,20.4-8.2,30.5c-2.6,9.2-5.2,18.4-5.4,28.1
	c-0.3,10,5.1,18.4,14.1,22.2c7.8,3.3,16.1,4,24.1,2.1c8.4-2,16.4-5.4,24.6-8.2c0.6-0.2,1.2-0.8,1.3-1.4
	C182.2,228.5,182.9,225.5,183.6,222.5z M163.6,57.5c-2.8,0.5-5.6,0.8-8.3,1.7c-13.4,4.2-19.3,19.7-11.6,31.2
	c5.6,8.3,13.9,11.2,23.7,9.9c11.5-1.4,20.1-12.5,18.5-23.9C184.5,65.8,175.3,58.1,163.6,57.5z"
      />
    </svg>
  );
};
export const AttendanceIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M79.5,143.2c0.4,2.4,0.7,4.4,1.1,6.5c1.7,8.3,4.5,16,9.7,22.6c5,6.3,11.3,10.2,18.9,11.5c7.5,1.3,14.9,0.9,21.7-2.9
     c8.7-4.8,12.8-13.1,14.7-22.8c0.8-4,0.8-8.2,1.2-12.3c0.1-0.6,0-1.3,0-2.3c6.1,7.4,13.8,10.7,21.8,13.8c7.5,2.9,14.9,6,22.2,9.3
     c8.3,3.7,14.8,9.5,18.2,18.7c1.2,3.3,2.6,6.6,3.8,9.9c0.2,0.6,0.1,1.6-0.2,2c-1.4,1.8-3.1,3.5-4.8,5.5
     c-17.3-18.5-37-33.3-58.3-46.5c-14.6,31.5-29.1,63.1-43.8,94.9c3.9,1.1,7.5,2.3,11.2,3.2c14.9,3.7,28.9,9.8,42.1,18
     c7.1,4.4,13.8,9.4,18.6,16.8c0.4,0.6,0.8,1.2,1.4,2.1c-4.3,0.9-8.3,1.8-12.2,2.5c-15.7,3-31.4,5.4-47.3,6.1
     c-8.7,0.4-17.5-0.3-26.3-1.2C77,297,60.9,294.4,45,290.5c-11.9-2.9-23.5-6.5-34.8-11.4c-3.9-1.7-5.8-4.5-6.7-8.6
     c-1-4.5-0.8-9-0.5-13.5c1.4-19.3,3.7-38.4,9.1-57c1.6-5.7,4-11.2,6.1-16.7c2.6-6.6,7.2-11.2,13-14.5c3.3-1.8,6.8-3.2,10.3-4.7
     c7.4-3.1,14.9-6,22.2-9.3C69.5,152.3,75.2,149.1,79.5,143.2z"
        />
        <path
          d="M169,52.7c-0.9,6.2-1.9,12.3-2.8,18.5c-0.1,0.9,0.1,1.9,0.3,2.9c0.4,1.9,1.2,3.8,1.3,5.7c0.3,5.8-1.2,11.4-2.7,17
     c-0.1,0.5-0.3,1.1-0.6,1.5c-2.5,3.1-2.9,7.1-4,10.8c-4.5,15.6-12.4,28.7-24.7,38.5c-15.7,12.5-32,11.9-46.8-1.8
     c-12.2-11.2-19.7-25.5-22.7-42.3c-0.1-0.6-0.2-1.3-0.5-1.8C61.5,96,61,89.1,60.2,82.3c-0.2-1.6,0-3.3,0.3-4.9
     c0.2-1.3,0.6-2.8,1.5-3.7c1.4-1.5,1.2-2.9,1-4.7c-1.2-8.1-1.8-16.2-1.3-24.4c0.6-10.8,5.3-19.5,12.1-27.2C79.3,11,86,6.1,93.5,2.4
     c3-1.5,6.2-1.9,9.6-2.3c8.8-1,16.9,2.1,25,4.7c5.9,1.9,11.9,4,17.4,7c15.7,8.3,23.2,22.2,23.1,40.8C168.7,52.7,168.8,52.7,169,52.7
     z"
        />
        <path
          d="M128.5,234.6c7.5-16.9,14.8-33.6,22.3-50.6c18,13.7,34.4,29.1,47.9,48.4c2.2-2.9,4.3-5.6,6.4-8.4
     c16.1-20.6,33.2-40.2,52.7-56.9c11.2-9.5,22.9-18,36.2-23.5c1-0.4,2-0.8,2.9-1.1c0.1,0.2,0.3,0.3,0.4,0.5c-0.2,0.1-0.4,0.1-0.6,0.2
     c-12.6,8.1-22.8,19.2-32.3,31.2c-13.3,17-24.3,35.6-34.7,54.9c-10.8,20.1-19.9,41.2-28.5,62.5c-0.4,1.1-0.9,2.1-1.5,3.5
     c-0.6-0.8-1-1.3-1.4-1.8c-5.1-7.4-10.1-14.7-15.2-22.1c-6.1-8.9-14.2-15.1-22.7-20.6c-9.8-6.4-20.2-11.5-31-15.7
     C129.3,235,129,234.8,128.5,234.6z"
        />
      </g>
    </svg>
  );
};
export const AttendanceApproval1Icon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <g>
          <path
            d="M175.23,203.4c0.25,5.57,0.42,10.84,0.73,16.1c0.88,14.69,2.47,29.28,6.75,43.45c1.97,6.53,4.55,12.78,8.75,18.24
			c2.23,2.89,4.8,5.41,8.38,7.13c0.05-0.82,0.13-1.42,0.13-2.02c0.01-15.33-0.01-30.65,0.04-45.98c0-1.19-0.33-1.74-1.5-2.13
			c-3.28-1.1-4.95-3.52-5.07-6.95c-0.08-2.38-0.09-4.77-0.02-7.16c0.15-4.6,3.19-7.64,7.8-7.7c5.27-0.07,10.55-0.08,15.83,0
			c4.62,0.07,7.64,3.12,7.77,7.72c0.07,2.32,0.05,4.65,0,6.97c-0.09,3.66-1.77,6-5.25,7.21c-0.89,0.31-1.32,0.64-1.31,1.67
			c0.04,15.89,0.03,31.78,0.04,47.67c0,0.12,0.05,0.24,0.14,0.66c0.69-0.37,1.34-0.66,1.92-1.04c4.43-2.91,7.52-6.97,9.99-11.57
			c4.82-8.97,7.18-18.72,8.93-28.64c2.39-13.58,3.23-27.3,3.63-41.06c0.02-0.75,0-1.5,0-2.44c0.7-0.04,1.24-0.13,1.77-0.09
			c9.71,0.71,19.44,1.16,29.11,2.22c15.27,1.69,26.8,14.71,26.91,30.05c0.1,13.06,0.03,26.13,0.04,39.19
			c0.01,3.68-1.74,6.48-4.39,8.81c-3.9,3.42-8.55,5.46-13.37,7.15c-10.55,3.7-21.5,5.55-32.56,6.84c-23.6,2.75-47.25,2.93-70.91,1
			c-14.72-1.2-29.35-3.1-43.45-7.81c-4.84-1.62-9.51-3.61-13.55-6.84c-3.34-2.66-5.08-5.95-5.02-10.39
			c0.15-12.75-0.07-25.5,0.09-38.25c0.2-15.27,12.07-28.23,27.23-29.8c7.24-0.75,14.52-1.16,21.78-1.7
			C169.4,203.72,172.21,203.58,175.23,203.4z"
          />
          <path
            d="M203.71,45.38c-5.89,2.11-11.56,4.15-17.22,6.18c-2.54,0.91-5.12,1.72-7.62,2.75c-1.31,0.54-2.1,0.32-3.08-0.7
			c-14.47-14.93-32.03-23.74-52.67-26.32c-44.1-5.5-85.98,24.35-95.26,67.86c-9.09,42.68,14.48,85.25,55.43,100.09
			c8.62,3.12,17.53,4.85,26.7,5.14c0.38,0.01,0.75,0.04,1.12,0.08c0.1,0.01,0.19,0.12,0.45,0.3c-0.31,0.5-0.59,1.03-0.93,1.52
			c-5.11,7.31-10.24,14.6-15.3,21.94c-0.77,1.12-1.53,1.34-2.8,1.09c-28.55-5.54-51.97-19.61-69.75-42.62
			c-16.63-21.53-24.65-46.15-23.4-73.28c1.74-37.73,18.39-67.76,49.3-89.48C68.94,5.69,91.84-0.89,116.54,0.1
			c35.35,1.41,64.08,16.37,86.25,43.94C203.06,44.37,203.28,44.74,203.71,45.38z"
          />
          <path
            d="M264.75,136.54c-0.2,19.5-6.72,34.88-18.47,48.17c-5.41,6.12-11.72,11.13-18.89,15.1c-12.31,6.83-24.6,6.92-36.74-0.19
			c-21.17-12.41-34.3-30.61-36.64-55.2c-2.11-22.21,4.31-42.18,20.65-57.96c21.34-20.61,52.03-19.16,71.97,2.97
			c9.49,10.53,14.94,22.97,17.09,36.89C264.33,130.22,264.52,134.18,264.75,136.54z M252.18,135.31c-0.76-0.07-1.32-0.12-1.87-0.17
			c-5.99-0.6-12.01-1.01-17.97-1.84c-10.03-1.39-19.88-3.6-29.31-7.41c-6.01-2.43-11.53-5.55-15.36-11.05
			c-0.74-1.07-1.74-1.99-2.72-2.86c-3.63-3.2-6.65-3.06-9.66,0.73c-2.57,3.24-4.82,6.73-7.17,10.14c-0.34,0.5-0.56,1.13-0.7,1.73
			c-5.9,24.94,6.78,52.64,29.5,64.67c8.06,4.27,16.04,4.55,24.08,0.12c13.64-7.52,22.76-18.88,28.17-33.33
			C251.64,149.44,252.66,142.58,252.18,135.31z"
          />
        </g>
        <polygon points="106.93,59.72 80.56,73.83 85.71,94.13 101.65,85.53 101.65,169.56 124.7,169.56 124.7,59.72 	" />
      </g>
    </svg>
  );
};
export const AttendanceApproval2Icon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M175.23,203.4c0.25,5.57,0.42,10.84,0.73,16.1c0.88,14.69,2.47,29.28,6.75,43.45c1.97,6.53,4.55,12.78,8.75,18.24
		c2.23,2.89,4.8,5.41,8.38,7.13c0.05-0.82,0.13-1.42,0.13-2.02c0.01-15.33-0.01-30.65,0.04-45.98c0-1.19-0.33-1.74-1.5-2.13
		c-3.28-1.1-4.95-3.52-5.07-6.95c-0.08-2.38-0.09-4.77-0.02-7.16c0.15-4.6,3.19-7.64,7.8-7.7c5.27-0.07,10.55-0.08,15.83,0
		c4.62,0.07,7.64,3.12,7.77,7.72c0.07,2.32,0.05,4.65,0,6.97c-0.09,3.66-1.77,6-5.25,7.21c-0.89,0.31-1.32,0.64-1.31,1.67
		c0.04,15.89,0.03,31.78,0.04,47.67c0,0.12,0.05,0.24,0.14,0.66c0.69-0.37,1.34-0.66,1.92-1.04c4.43-2.91,7.52-6.97,9.99-11.57
		c4.82-8.97,7.18-18.72,8.93-28.64c2.39-13.58,3.23-27.3,3.63-41.06c0.02-0.75,0-1.5,0-2.44c0.7-0.04,1.24-0.13,1.77-0.09
		c9.71,0.71,19.44,1.16,29.11,2.22c15.27,1.69,26.8,14.71,26.91,30.05c0.1,13.06,0.03,26.13,0.04,39.19
		c0.01,3.68-1.74,6.48-4.39,8.81c-3.9,3.42-8.55,5.46-13.37,7.15c-10.55,3.7-21.5,5.55-32.56,6.84c-23.6,2.75-47.25,2.93-70.91,1
		c-14.72-1.2-29.35-3.1-43.45-7.81c-4.84-1.62-9.51-3.61-13.55-6.84c-3.34-2.66-5.08-5.95-5.02-10.39
		c0.15-12.75-0.07-25.5,0.09-38.25c0.2-15.27,12.07-28.23,27.23-29.8c7.24-0.75,14.52-1.16,21.78-1.7
		C169.4,203.72,172.21,203.58,175.23,203.4z"
        />
        <path
          d="M203.71,45.38c-5.89,2.11-11.56,4.15-17.22,6.18c-2.54,0.91-5.12,1.72-7.62,2.75c-1.31,0.54-2.1,0.32-3.08-0.7
		c-14.47-14.93-32.03-23.74-52.67-26.32c-44.1-5.5-85.98,24.35-95.26,67.86c-9.09,42.68,14.48,85.25,55.43,100.09
		c8.62,3.12,17.53,4.85,26.7,5.14c0.38,0.01,0.75,0.04,1.12,0.08c0.1,0.01,0.19,0.12,0.45,0.3c-0.31,0.5-0.59,1.03-0.93,1.52
		c-5.11,7.31-10.24,14.6-15.3,21.94c-0.77,1.12-1.53,1.34-2.8,1.09c-28.55-5.54-51.97-19.61-69.75-42.62
		c-16.63-21.53-24.65-46.15-23.4-73.28c1.74-37.73,18.39-67.76,49.3-89.48C68.94,5.69,91.84-0.89,116.54,0.1
		c35.35,1.41,64.08,16.37,86.25,43.94C203.06,44.37,203.28,44.74,203.71,45.38z"
        />
        <path
          d="M264.75,136.54c-0.2,19.5-6.72,34.88-18.47,48.17c-5.41,6.12-11.72,11.13-18.89,15.1c-12.31,6.83-24.6,6.92-36.74-0.19
		c-21.17-12.41-34.3-30.61-36.64-55.2c-2.11-22.21,4.31-42.18,20.65-57.96c21.34-20.61,52.03-19.16,71.97,2.97
		c9.49,10.53,14.94,22.97,17.09,36.89C264.33,130.22,264.52,134.18,264.75,136.54z M252.18,135.31c-0.76-0.07-1.32-0.12-1.87-0.17
		c-5.99-0.6-12.01-1.01-17.97-1.84c-10.03-1.39-19.88-3.6-29.31-7.41c-6.01-2.43-11.53-5.55-15.36-11.05
		c-0.74-1.07-1.74-1.99-2.72-2.86c-3.63-3.2-6.65-3.06-9.66,0.73c-2.57,3.24-4.82,6.73-7.17,10.14c-0.34,0.5-0.56,1.13-0.7,1.73
		c-5.9,24.94,6.78,52.64,29.5,64.67c8.06,4.27,16.04,4.55,24.08,0.12c13.64-7.52,22.76-18.88,28.17-33.33
		C251.64,149.44,252.66,142.58,252.18,135.31z"
        />
      </g>
      <path
        d="M108.61,148.35c19.89-19.22,34.98-35.69,34.98-56.28c0-16.94-11-34.03-35.57-34.03c-11.66,0-22.84,4.02-31.49,11.32
	l-2.89,2.44l8.56,18.96l5.05-4.21c3.27-2.72,9.95-7.29,18-7.29c9.97,0,14.81,4.75,14.81,14.46c-0.13,12.83-10.61,24.85-35.65,48.69
	l-12.11,11.76v15.41h73.74v-21.21H108.61z"
      />
    </svg>
  );
};
export const CityIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M175.23,203.4c0.25,5.57,0.42,10.84,0.73,16.1c0.88,14.69,2.47,29.28,6.75,43.45c1.97,6.53,4.55,12.78,8.75,18.24
		c2.23,2.89,4.8,5.41,8.38,7.13c0.05-0.82,0.13-1.42,0.13-2.02c0.01-15.33-0.01-30.65,0.04-45.98c0-1.19-0.33-1.74-1.5-2.13
		c-3.28-1.1-4.95-3.52-5.07-6.95c-0.08-2.38-0.09-4.77-0.02-7.16c0.15-4.6,3.19-7.64,7.8-7.7c5.27-0.07,10.55-0.08,15.83,0
		c4.62,0.07,7.64,3.12,7.77,7.72c0.07,2.32,0.05,4.65,0,6.97c-0.09,3.66-1.77,6-5.25,7.21c-0.89,0.31-1.32,0.64-1.31,1.67
		c0.04,15.89,0.03,31.78,0.04,47.67c0,0.12,0.05,0.24,0.14,0.66c0.69-0.37,1.34-0.66,1.92-1.04c4.43-2.91,7.52-6.97,9.99-11.57
		c4.82-8.97,7.18-18.72,8.93-28.64c2.39-13.58,3.23-27.3,3.63-41.06c0.02-0.75,0-1.5,0-2.44c0.7-0.04,1.24-0.13,1.77-0.09
		c9.71,0.71,19.44,1.16,29.11,2.22c15.27,1.69,26.8,14.71,26.91,30.05c0.1,13.06,0.03,26.13,0.04,39.19
		c0.01,3.68-1.74,6.48-4.39,8.81c-3.9,3.42-8.55,5.46-13.37,7.15c-10.55,3.7-21.5,5.55-32.56,6.84c-23.6,2.75-47.25,2.93-70.91,1
		c-14.72-1.2-29.35-3.1-43.45-7.81c-4.84-1.62-9.51-3.61-13.55-6.84c-3.34-2.66-5.08-5.95-5.02-10.39
		c0.15-12.75-0.07-25.5,0.09-38.25c0.2-15.27,12.07-28.23,27.23-29.8c7.24-0.75,14.52-1.16,21.78-1.7
		C169.4,203.72,172.21,203.58,175.23,203.4z"
        />
        <path
          d="M203.71,45.38c-5.89,2.11-11.56,4.15-17.22,6.18c-2.54,0.91-5.12,1.72-7.62,2.75c-1.31,0.54-2.1,0.32-3.08-0.7
		c-14.47-14.93-32.03-23.74-52.67-26.32c-44.1-5.5-85.98,24.35-95.26,67.86c-9.09,42.68,14.48,85.25,55.43,100.09
		c8.62,3.12,17.53,4.85,26.7,5.14c0.38,0.01,0.75,0.04,1.12,0.08c0.1,0.01,0.19,0.12,0.45,0.3c-0.31,0.5-0.59,1.03-0.93,1.52
		c-5.11,7.31-10.24,14.6-15.3,21.94c-0.77,1.12-1.53,1.34-2.8,1.09c-28.55-5.54-51.97-19.61-69.75-42.62
		c-16.63-21.53-24.65-46.15-23.4-73.28c1.74-37.73,18.39-67.76,49.3-89.48C68.94,5.69,91.84-0.89,116.54,0.1
		c35.35,1.41,64.08,16.37,86.25,43.94C203.06,44.37,203.28,44.74,203.71,45.38z"
        />
        <path
          d="M264.75,136.54c-0.2,19.5-6.72,34.88-18.47,48.17c-5.41,6.12-11.72,11.13-18.89,15.1c-12.31,6.83-24.6,6.92-36.74-0.19
		c-21.17-12.41-34.3-30.61-36.64-55.2c-2.11-22.21,4.31-42.18,20.65-57.96c21.34-20.61,52.03-19.16,71.97,2.97
		c9.49,10.53,14.94,22.97,17.09,36.89C264.33,130.22,264.52,134.18,264.75,136.54z M252.18,135.31c-0.76-0.07-1.32-0.12-1.87-0.17
		c-5.99-0.6-12.01-1.01-17.97-1.84c-10.03-1.39-19.88-3.6-29.31-7.41c-6.01-2.43-11.53-5.55-15.36-11.05
		c-0.74-1.07-1.74-1.99-2.72-2.86c-3.63-3.2-6.65-3.06-9.66,0.73c-2.57,3.24-4.82,6.73-7.17,10.14c-0.34,0.5-0.56,1.13-0.7,1.73
		c-5.9,24.94,6.78,52.64,29.5,64.67c8.06,4.27,16.04,4.55,24.08,0.12c13.64-7.52,22.76-18.88,28.17-33.33
		C251.64,149.44,252.66,142.58,252.18,135.31z"
        />
      </g>
      <path
        d="M108.61,148.35c19.89-19.22,34.98-35.69,34.98-56.28c0-16.94-11-34.03-35.57-34.03c-11.66,0-22.84,4.02-31.49,11.32
	l-2.89,2.44l8.56,18.96l5.05-4.21c3.27-2.72,9.95-7.29,18-7.29c9.97,0,14.81,4.75,14.81,14.46c-0.13,12.83-10.61,24.85-35.65,48.69
	l-12.11,11.76v15.41h73.74v-21.21H108.61z"
      />
    </svg>
  );
};
export const ContactUsIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M117.5,157.7c8.1-7.2,14.6-13.2,21.3-19c3.7-3.3,3.8-6.2,1.4-10.8C109,68.3,151.4-1.3,217.9,0c42.4,0.8,79.6,37,81.9,79.7
		c2.5,46.9-28.5,86.5-72.1,92.7c-19.5,2.8-37.4-1-54.8-10.1c-3-1.6-7.1-1.4-10.7-1.3c-11.2,0.4-22.6,1.6-33.8,1.6
		C124.9,162.7,121.3,159.5,117.5,157.7z M177.2,101.2c7.3,0.1,13.4-6,13.4-13.3c0-7.2-6.3-13.6-13.4-13.5
		c-6.9,0.1-12.6,5.9-12.8,13.1C164.2,95.2,169.8,101.1,177.2,101.2z M229.5,88c0.1-7.2-6.1-13.7-13.3-13.7c-7,0-12.7,5.7-12.9,12.9
		c-0.3,7.7,5.2,13.7,12.6,13.9C223.1,101.4,229.4,95.4,229.5,88z M254.8,101.2c7.3,0,13.3-6.1,13.2-13.5
		c-0.1-7.3-6.4-13.5-13.5-13.3c-7,0.2-12.5,6-12.6,13.3C241.8,95.3,247.4,101.2,254.8,101.2z"
        />
        <path
          d="M174.6,300c-21.8-3.6-46.6-12.3-69.1-26.8c-32-20.5-58.1-47.5-78.4-79.7c-12.1-19.2-19.9-40.6-24.6-62.7
		c-5-23.4,3-43.8,19.3-60.4c8-8.2,19.5-9.4,30.5-8.9c2.2,0.1,4.9,2.9,6.2,5.1c8.8,15.1,17.3,30.5,26.1,45.7
		c3.2,5.5,1.8,10.2-2.4,14.2c-6.4,6-13.3,11.6-19.8,17.7c-7.4,6.9-7.4,9.4-2.7,18.5c17.7,34.1,43.3,60,76.8,78
		c7,3.8,12.4,3.7,17.8-2.8c5.8-7,11.8-14,18-20.7c4.3-4.8,9.1-4.9,14.6-1.5c13.7,8.4,27.6,16.6,41.6,24.4c6.9,3.8,8.5,8.9,7.2,16.3
		C231.2,281.7,207.8,300,174.6,300z"
        />
      </g>
    </svg>
  );
};
export const RoleIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M69.2,300c-10.9,0-21.9,0-32.8,0c-5.9-41.3-11.7-82.6-17.6-124.2c-6.2,0-12.3,0-18.7,0c0-1,0-1.8,0-2.5
		c0-16.8-0.1-33.6,0-50.4c0.1-25.2,17.8-46.5,42.5-51.5c1.8-0.4,3.6-0.7,6.2-1.1c-21.5-3.8-32.4-21.2-31-38
		C19.3,14.1,34.4,0.2,52.3,0c18.4-0.1,33.5,13.3,35.4,31.9c1.7,16.1-8.7,34.3-30,38.3c1.5,0.4,3,0.6,4.5,0.9
		c25.8,5.1,43.2,26.3,43.3,52.6c0,5.5,0,11.1,0,16.7c12.2,0,24.2,0,36.4,0c0-35.2,0-70.1,0-105.3c17.7,0,35,0,52.7,0
		c0-11.8,0-23.3,0-35.1C229.9,0,265,0,300,0c0,29.3,0,58.6,0,87.9c-35.1,0-70.2,0-105.5,0c0-11.9,0-23.4,0-35c-11.8,0-23.3,0-34.9,0
		c0,29.3,0,58.4,0,87.6c11.7,0,23.2,0,35,0c0-11.7,0-23.2,0-35c35.4,0,70.4,0,105.4,0c0,29.3,0,58.6,0,87.9c-35.1,0-70.2,0-105.5,0
		c0-11.9,0-23.4,0-35c-11.8,0-23.3,0-34.9,0c0,29.3,0,58.4,0,87.6c11.7,0,23.2,0,35,0c0-11.7,0-23.2,0-35c35.4,0,70.4,0,105.4,0
		c0,29.7,0,59.4,0,89.1c-35.2,0-70.3,0-105.5,0c0-12.1,0-24.1,0-36.4c-17.8,0-35.1,0-52.8,0c0-35.2,0-70.2,0-105.2
		c-12.3,0-24.2,0-36.4,0c0,5.8,0,11.5,0,17.4c-6.3,0-12.4,0-18.7,0C80.9,217.3,75,258.7,69.2,300z"
        />
      </g>
    </svg>
  );
};
export const CompanyIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M181.1,298.8c-17.6,0-35.2,0-52.7,0c-0.1-1.2-0.1-2.3-0.1-3.5c0-20.1,0-40.2,0-60.3c0-14.6-9-23.6-23.5-23.6
		c-9.4,0-18.7,0-28.1,0c-14.4,0-23.4,8.9-23.4,23.3c0,20.5,0,41,0,61.5c0,0.9,0.1,1.8,0.1,2.6c-14.5,0-28.9,0-43.4,0
		c-5-1.6-8.3-5-10-10c0-89.8,0-179.6,0-269.5c0.2-0.5,0.5-1,0.6-1.6c3.1-11.3,13-18,24.6-16.5C37.2,3,49.2,5,61.1,6.8
		c23.8,3.6,47.6,7.2,71.4,10.9c10.2,1.6,20.4,3.1,30.6,4.7c9.4,1.5,18.1,10,18.1,23.2c-0.2,83.4-0.1,166.9-0.1,250.4
		C181.2,296.9,181.1,297.9,181.1,298.8z M59.5,52.1c-3.3,0-6.6-0.1-10,0c-4.9,0.2-8.6,3.9-8.9,8.8c-0.3,4.5,3,9.2,7.7,9.5
		c7.4,0.4,14.8,0.5,22.2,0c4.9-0.3,7.9-4.9,7.5-9.9c-0.3-4.6-4.1-8.3-8.9-8.4C65.9,52,62.7,52.1,59.5,52.1z M59.3,108.3
		c2.9,0,5.8,0.1,8.8,0c6-0.1,10-4.1,9.9-9.6c-0.1-5.4-4.1-9.1-10-9.1c-5.8-0.1-11.5-0.1-17.3,0c-6,0.1-10.2,3.9-10.2,9.3
		c0,5.3,4.3,9.3,10.2,9.4C53.7,108.3,56.5,108.3,59.3,108.3z M59.3,127.1c-3.1,0-6.3-0.1-9.4,0c-5.2,0.2-9.1,3.9-9.4,9
		c-0.2,4.6,3.3,9.2,8.1,9.5c7.1,0.4,14.2,0.4,21.3,0c5.1-0.3,8.3-4.8,8-9.8c-0.3-4.8-4.1-8.4-9-8.6C65.8,127,62.6,127.1,59.3,127.1z
		 M59.3,183.3c2.9,0,5.9,0.1,8.8,0c6-0.1,10-4.1,9.9-9.6c-0.1-5.4-4.1-9.1-10-9.1c-5.8-0.1-11.5-0.1-17.3,0c-6,0.1-10.2,4-10.1,9.4
		c0,5.3,4.3,9.3,10.3,9.4C53.7,183.3,56.5,183.3,59.3,183.3z M121.8,70.8c3.1,0,6.2,0.1,9.4,0c5.6-0.2,9.5-4.3,9.5-9.5
		c-0.1-5.2-4.1-9.1-9.7-9.2c-5.9-0.1-11.9-0.1-17.9,0c-5.8,0.1-9.9,3.9-9.9,9.3c0,5.3,4.1,9.3,9.9,9.4
		C115.9,70.9,118.8,70.8,121.8,70.8z M122.1,89.6c-3.3,0-6.6-0.1-9.9,0c-4.9,0.2-8.7,3.9-9,8.7c-0.3,4.8,3,9.4,7.8,9.7
		c7.2,0.4,14.4,0.5,21.6,0c5-0.3,8.4-5,8-9.8c-0.4-4.7-4.2-8.4-9.1-8.6C128.3,89.5,125.2,89.6,122.1,89.6z M122,127.1
		C122,127.1,122,127.1,122,127.1c-3.1,0-6.3,0-9.4,0c-0.2,0-0.4,0-0.6,0c-4.8,0.3-8.5,3.9-8.9,8.5c-0.4,4.8,2.8,9.5,7.7,9.8
		c7.4,0.5,14.8,0.5,22.2,0c4.8-0.3,8-5.1,7.6-9.9c-0.4-4.6-4.2-8.3-8.9-8.5C128.4,127,125.2,127.1,122,127.1z M122.1,164.6
		c-3.3,0-6.6-0.1-9.9,0c-4.9,0.2-8.7,3.9-9,8.7c-0.3,4.8,3,9.4,7.8,9.7c7.2,0.5,14.4,0.5,21.6,0c5-0.3,8.4-5,8-9.9
		c-0.4-4.7-4.2-8.4-9.1-8.6C128.3,164.5,125.2,164.6,122.1,164.6z"
        />
        <path
          d="M193.9,298.8c-0.1-1.6-0.1-3.1-0.1-4.7c0-78.8,0-157.6,0-236.5c0-1.7,0-3.4,0-5.6c5.4,1.5,10.4,2.9,15.5,4.3
		c24.1,6.8,48.3,13.6,72.4,20.5c11.6,3.3,18.3,14.5,18.3,30.6c0,53.3,0,106.6,0,159.9c0,16.4-6.1,26.9-18,31
		c-0.3,0.1-0.5,0.3-0.8,0.5C252.1,298.8,223,298.8,193.9,298.8z M243.7,147.2c3.2,0,6.4,0.1,9.7,0c5.1-0.2,9.1-5.7,9.1-12.4
		c0.1-6.9-4-12.6-9.2-12.8c-6.3-0.2-12.7-0.1-19,0c-5.3,0.1-9.3,5.7-9.3,12.6c0,6.9,4.1,12.4,9.3,12.6
		C237.4,147.3,240.6,147.2,243.7,147.2z M243.7,197.7c3.2,0,6.4,0.1,9.7,0c5.2-0.2,9.2-5.9,9.2-12.8c-0.1-6.7-4-12.3-9.2-12.4
		c-6.3-0.2-12.7-0.1-19,0c-5.3,0.1-9.3,5.7-9.3,12.6c0,6.9,4,12.4,9.3,12.6C237.4,197.8,240.6,197.7,243.7,197.7z M243.9,248.3
		c3.1,0,6.3,0.1,9.4,0c5.3-0.2,9.3-5.8,9.2-12.7c0-6.9-4.1-12.4-9.4-12.5c-6.3-0.1-12.5-0.1-18.7,0c-5.3,0.1-9.3,5.6-9.4,12.5
		c-0.1,7,4.1,12.6,9.5,12.8C237.6,248.4,240.7,248.3,243.9,248.3z"
        />
      </g>
    </svg>
  );
};
export const DepartmentIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M6.6,300.5c-5.1-2.3-6.8-6.4-6.5-11.8c0.3-7.8,0-15.7,0.1-23.5c0.1-8.9,3.6-16.3,10.2-22.2c1.4-1.3,2.4-1.6,4.1-0.2
		c17.5,14.3,41.9,14.3,59.5,0c1.5-1.2,2.4-1.3,3.9,0c6.5,5.8,10.2,12.9,10.4,21.6c0.2,9,0,18,0.1,27c0,4.6-2.7,7.2-6.5,9.2
		C56.7,300.5,31.6,300.5,6.6,300.5z"
        />
        <path
          d="M112.4,300.5c-5.1-2.3-6.8-6.4-6.5-11.8c0.3-7.8,0-15.7,0.1-23.5c0.1-8.9,3.6-16.3,10.2-22.2c1.4-1.3,2.4-1.6,4.1-0.2
		c17.5,14.3,41.9,14.3,59.5,0c1.5-1.2,2.4-1.3,3.9,0c6.5,5.8,10.2,12.9,10.4,21.6c0.2,9,0,18,0.1,27c0,4.6-2.7,7.2-6.5,9.2
		C162.6,300.5,137.5,300.5,112.4,300.5z"
        />
        <path
          d="M218.2,300.5c-5.1-2.3-6.8-6.4-6.5-11.8c0.3-7.8,0-15.7,0.1-23.5c0.1-8.9,3.6-16.3,10.2-22.2c1.4-1.3,2.4-1.6,4.1-0.2
		c17.5,14.3,41.9,14.3,59.5,0c1.5-1.2,2.4-1.3,3.9,0c6.5,5.8,10.2,12.9,10.4,21.6c0.2,9,0,18,0.1,27c0,4.6-2.7,7.2-6.5,9.2
		C268.4,300.5,243.3,300.5,218.2,300.5z"
        />
        <path
          d="M53.1,158.2c-15.2,0-29.8,0.1-44.4,0c-4.8,0-8.6-3.8-8.6-8.6C0,137.7,0,125.9,0.1,114c0-5,3.8-8.6,8.9-8.7
		c5.4-0.1,10.8,0,16.1,0c1.7-4,3.4-7.9,4.9-11.8c0.2-0.6-0.2-1.9-0.7-2.4c-3.1-3.3-6.4-6.4-9.5-9.6c-4.5-4.5-4.5-9.4,0-13.9
		c16.1-16.2,32.3-32.3,48.4-48.4c4.5-4.5,9.3-4.5,13.9,0c3.2,3.1,6.3,6.4,9.6,9.5c0.6,0.5,1.8,1,2.4,0.7c3.9-1.5,7.8-3.2,11.8-4.9
		c0-5.2,0-10.3,0-15.5c0-5.9,3.7-9.5,9.6-9.5c23,0,46,0,69.1,0c5.9,0,9.5,3.6,9.5,9.5c0,5.2,0,10.4,0,15.5c4,1.7,7.9,3.4,11.8,4.9
		c0.6,0.2,1.8-0.2,2.4-0.7c3.3-3.1,6.4-6.4,9.6-9.5c4.5-4.4,9.4-4.4,13.9,0.1c16.1,16.1,32.2,32.1,48.2,48.2c4.7,4.8,4.7,9.5,0,14.3
		c-3.1,3.1-6.3,6.2-9.3,9.4c-0.5,0.6-0.9,1.8-0.7,2.4c1.5,3.9,3.2,7.8,4.9,11.7c5,0,10,0,15,0c6.5,0,10.1,3.5,10.1,9.9
		c0,11.1,0,22.1,0,33.2c0,6.1-3.6,9.8-9.6,9.8c-14.3,0-28.6,0-43.3,0c1.5-35.9-11-65.5-40-87.1c-18.4-13.8-39.4-19.8-62.3-18.5
		c-27.2,1.6-50,12.8-67.9,33.4C58.8,106.6,51.7,130.9,53.1,158.2z"
        />
        <path
          d="M229.3,158.2c-6.2,0-11.9,0.1-17.7,0c-5.3-0.1-8.4-4-8.7-9.8c-1.2-22.5-12-38.9-32.7-47.8c-34.1-14.6-72.3,10.6-73.1,47.7
		c-0.1,6.5-3.6,9.9-10.1,9.9c-5.4,0-10.7,0-16.3,0c-1.6-31.3,8.9-57.1,35.1-74.5c27.8-18.5,57.3-19.1,85.8-1.6
		C219.6,99.2,230.9,125.6,229.3,158.2z"
        />
        <path
          d="M44.2,177.1c16.2,0,29.4,13.2,29.4,29.3c0,16.3-13.2,29.5-29.5,29.5c-16.1-0.1-29.3-13.3-29.2-29.5
		C14.8,190.2,28,177.1,44.2,177.1z"
        />
        <path
          d="M179.4,206.5c0,16.2-13.2,29.3-29.4,29.3c-16.3,0-29.5-13.3-29.4-29.6c0.1-16.1,13.4-29.2,29.5-29.2
		C166.3,177.1,179.4,190.3,179.4,206.5z"
        />
        <path
          d="M285.2,206.4c0,16.2-13.1,29.4-29.3,29.4c-16.3,0.1-29.6-13.2-29.5-29.5c0-16.1,13.3-29.3,29.4-29.3
		C272,177.1,285.2,190.2,285.2,206.4z"
        />
      </g>
    </svg>
  );
};
export const VehicleAttendanceIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M253.4,18.8c8,0,16,0,24.5,0h4.3c0,4.6,0,9.1,0,13.7c0,0.7,0,1.4,0,2.1v1.1c0,3.9,0,7.8,0.2,11.9c0.3,4,3.7,7.1,6.8,8.2
   c0.7,0.3,1.5,0.4,2.3,0.4c2.3,0,4-1.2,4.5-1.6c1.3-0.9,4.2-3.4,4.1-7.1c-0.2-11.7,0.2-23.7-0.2-34.3V0h-52.8l-1.9,2.4l0,0
   c-4,5-3.5,8.9-2.4,11.2C244.5,17,248.1,18.8,253.4,18.8z"
      />
      <path
        d="M281.2,253.2c0,8,0,16,0,24.5v4.3c-4.6,0-9.1,0-13.7,0c-0.7,0-1.4,0-2.1,0h-1.1c-3.9,0-7.8,0-11.9,0.2
   c-4,0.3-7.1,3.7-8.2,6.8c-0.3,0.7-0.4,1.5-0.4,2.3c0,2.3,1.2,4,1.6,4.5c0.9,1.3,3.4,4.2,7.1,4.1c11.7-0.2,23.7,0.2,34.3-0.2H300V247
   l-2.4-1.9l0,0c-5-4-8.9-3.5-11.2-2.4C283,244.4,281.2,247.9,281.2,253.2z"
      />
      <g>
        <path
          d="M46.8,18.8c-8,0-16,0-24.5,0H18c0,4.6,0,9.1,0,13.7c0,0.7,0,1.4,0,2.1v1.1c0,3.9,0,7.8-0.2,11.9c-0.3,4-3.7,7.1-6.8,8.2
     c-0.7,0.3-1.5,0.4-2.3,0.4c-2.3,0-4-1.2-4.5-1.6c-1.3-0.9-4.2-3.4-4.1-7.1c0.2-11.7-0.2-23.7,0.2-34.3V0H53l1.9,2.4l0,0
     c4,5,3.5,8.9,2.4,11.2C55.6,17,52.1,18.8,46.8,18.8z"
        />
        <path
          d="M19,253.2c0,8,0,16,0,24.5v4.3c4.6,0,9.1,0,13.7,0c0.7,0,1.4,0,2.1,0h1.1c3.9,0,7.8,0,11.9,0.2c4,0.3,7.1,3.7,8.2,6.8
     c0.3,0.7,0.4,1.5,0.4,2.3c0,2.3-1.2,4-1.6,4.5c-0.9,1.3-3.4,4.2-7.1,4.1c-11.7-0.2-23.7,0.2-34.3-0.2H0.2V247l2.4-1.9l0,0
     c5-4,8.9-3.5,11.2-2.4C17.2,244.4,19,247.9,19,253.2z"
        />
      </g>
      <path
        d="M70.7,229.7c-0.2,2.3-0.4,4.5-0.7,6.7c-1,10.7-5.7,15.2-16.3,15.4c-6.8,0.1-13.6,0.2-20.4-0.1c-9-0.4-15-5.9-15-14.9
   c-0.2-31.7-0.5-63.4,0.2-95.1c0.1-6.9,4.2-13.7,6.4-20.6c-3-1-8-1.4-11.1-4c-3-2.6-5.2-7.3-5.8-11.4C7,98.2,12.4,92,19.7,90.4
   c13.7-2.9,20.4,0,28.9,13.8c1.4-4.4,2.6-7.8,3.6-11.4c3.5-12.2,6.8-24.5,10.3-36.7c4.2-14.9,13.5-22,29.1-22
   c39.1-0.1,78.1-0.1,117.2,0c15.4,0,24.3,6.8,28.5,21.5c4,14.1,7.9,28.3,11.9,42.4c0.5,1.8,1.4,3.6,2.8,7.3
   c3.6-13.7,12.5-16,23.4-15.3c9.6,0.6,16,5.4,16.5,13.5c0.7,9.3-4,14.8-14.1,16.7c-1.1,0.2-2.2,0.4-4.6,0.8c9,8.9,9,19.6,8.9,30.4
   c-0.1,27.2,0,54.3-0.1,81.5c0,13.6-4.9,18.2-18.5,18.3c-3.7,0-7.4,0-11,0c-17.8,0-21.1-3-22.4-21.5
   C177.2,229.7,124.2,229.7,70.7,229.7z M244.8,111.4c-5.6-19.7-10.6-38.2-16.1-56.6c-2.4-8.1-8.5-12.8-17.1-12.8
   c-40.8-0.2-81.6-0.2-122.3,0c-7.5,0-14.7,3-17.1,10.5c-6.2,19.2-11.5,38.8-17.4,59C118.7,111.4,181.1,111.4,244.8,111.4z
    M122.6,196.1c18.7,0,36.8,0,54.9,0c0-6.7,0-12.8,0-19.2c-9.7,0-18.8,0-27.8,0c-8.9,0-17.9,0-27.1,0
   C122.6,184,122.6,189.8,122.6,196.1z M49.3,154c-1,9.3,0.2,13,5.8,13.4c9.4,0.7,18.8,0.2,28.5,0.2C80.2,153.3,66.9,151.1,49.3,154z
    M216.7,168c7.7,0,15.2,0.1,22.7,0c10.7-0.1,12.9-3.1,10.7-14.3C233.6,151.6,219.3,152.4,216.7,168z M41.7,112.3
   C38,95.6,33.6,97.5,21.5,98.6c-2.2,0.2-6.1,4.7-5.7,6.5c0.6,2.7,4,6.4,6.6,6.9C28.6,113,35.1,112.3,41.7,112.3z M257.7,112.3
   c6.9,0,13.2,0.8,19.2-0.3c2.9-0.5,5.1-4.6,7.7-7c-2.4-2.3-4.5-6-7.3-6.6C265.3,95.8,262.4,98.1,257.7,112.3z"
      />
    </svg>
  );
};
export const UnknownAttendanceIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M0.1,279.8c0.8-1.4,2-2.7,2.2-4.1c2-13.7,9.6-24.1,18.6-33.8c3.2-3.5,6.3-7,9.6-10.4c8.7-8.9,19.9-13.2,31.5-16.3
   c17-4.5,32.7-11.9,47.7-20.8c0.9-0.5,1.7-2.6,1.4-3.7c-3.2-12.4-6.6-24.8-10-37.1c-1.2-4.5-2.5-9.1-3.9-13.6c-0.4-1.1-1.2-2.6-2.2-3
   c-3.9-1.5-6.1-4.4-7-8.2c-1.6-6.9-2.6-14-4.1-20.9C81.8,98,81,88,81.2,77.9C81.5,63.2,83.3,49,91.4,36c4.8-7.7,9.3-15.5,16-22
   c4.9-4.7,10-7.5,17.1-5.9c2,0.4,4.4-0.3,6.5-0.9c6.2-2,12.4-4.4,19-2.3c8,2.6,16,5.4,23.7,8.6c5.7,2.4,11.1,5.5,16.7,8.3
   c7.2,3.6,11.4,9.9,15.3,16.4c5.9,10,9.2,20.9,11.2,32.3c0.6,3.7,2.6,7.7,1.7,10.9c-1.6,6.1,1.7,12.3-1.4,18.1
   c-3.8,7.1-7.4,14.3-11,21.5c-1.1,2.3-2.1,4.7-2.8,7.1c-1.1,3.8-1.9,7.6-2.9,11.4c-0.6,2.3-1.7,4.1-4.5,4.3c-0.6,0.1-1.4,1.2-1.7,2
   c-1.5,3.8-2.9,7.6-4.3,11.4c-0.1,0.3-0.4,0.7-0.4,1c0.5,7.6,0.3,15.3,1.9,22.7c1.8,8.3,9.4,11.5,16.7,13.3c4.9,1.2,9.3,2.6,13.1,5.8
   c5,4.3,10.3,7.2,17.2,6.8c4-0.2,7.1,2.3,9.4,5.4c2.3,3,5.1,4.2,8.8,3.7c4.6-0.6,8.7,0.6,12.8,3c3.5,2.1,7.4,3.6,11.3,4.9
   c7.3,2.3,13.2,6.4,17.6,12.6c0.4,0.6,1.1,0.9,1.6,1.4c0,20.7,0,41.5,0,62.2c-100,0-200,0-300,0C0.1,293.3,0.1,286.5,0.1,279.8z
    M120.7,101.2c2.9,0,5.7,0.1,8.6,0c3.2-0.1,5.1-1.8,5.4-5.1c0.1-1.9-0.1-3.7,0-5.6c0.3-4.6,0.3-9.2,1.2-13.7
   c1.2-5.9,6.3-8.9,13.1-8.5c5.6,0.3,10.2,3.9,10.7,9.2c1.2,13.3,0.1,26.2-9.5,36.9c-2.9,3.2-5.4,6.8-7.9,10.4
   c-7.7,11.3-11,23.4-6.7,36.8c0.9,2.8,2.7,4.1,5.5,4c4.9,0,9.7,0.1,14.6,0c3.6-0.1,4.5-1.5,4.4-5.3c-0.1-4.2-0.7-8.9,0.8-12.5
   c2.6-6.4,5.9-12.8,10.3-18c10.7-12.6,18.5-26.1,19.1-43.2c0.4-12.5-1.5-23.9-10.9-33c-8.5-8.2-19.1-11.1-30.5-11.2
   c-14.8-0.2-27.5,4.7-36.1,17.4c-7.7,11.4-7.8,24.2-6.3,37.1c0.3,2.8,2.4,4.3,5.3,4.3C114.9,101.2,117.8,101.2,120.7,101.2z
    M164.2,187.8L164.2,187.8c0-3.2,0.1-6.5,0-9.7c-0.2-4.1-2.4-6.1-6.4-6.2c-5.2-0.1-10.5-0.1-15.7,0c-4,0.1-6.1,2.1-6.2,6
   c-0.1,6.5-0.2,13,0,19.5c0.1,3.9,2.2,6,6.2,6.1c5.4,0.1,10.7,0.1,16.1,0c3.9-0.1,5.9-2.3,6.1-6.2C164.3,194,164.2,190.9,164.2,187.8
   z"
      />
      <path
        d="M259.7,156.8c0,6.2,0,12.4,0,18.9v3.3c-3.8,0-7.6,0-11.5,0c-0.6,0-1.2,0-1.8,0h-0.9c-3.3,0-6.5,0-10,0.2
   c-3.3,0.2-5.9,2.9-6.9,5.3c-0.3,0.5-0.3,1.2-0.3,1.8c0,1.8,1,3.1,1.3,3.5c0.8,1,2.8,3.2,5.9,3.2c9.8-0.2,19.8,0.2,28.7-0.2h11V152
   l-2-1.5l0,0c-4.2-3.1-7.4-2.7-9.4-1.9C261.2,150,259.7,152.7,259.7,156.8z"
      />
      <path
        d="M236.4,14.5c6.7,0,13.4,0,20.5,0h3.6c0,3.6,0,7,0,10.6c0,0.5,0,1.1,0,1.6v0.9c0,3,0,6,0.2,9.2c0.3,3.1,3.1,5.5,5.7,6.3
   c0.6,0.2,1.3,0.3,1.9,0.3c1.9,0,3.3-0.9,3.8-1.2c1.1-0.7,3.5-2.6,3.4-5.5c-0.2-9,0.2-18.3-0.2-26.5V0h-44.2l-1.6,1.9l0,0
   c-3.3,3.9-2.9,6.9-2,8.7C229,13.1,232,14.5,236.4,14.5z"
      />
      <path
        d="M63.6,14.5c-6.7,0-13.4,0-20.5,0h-3.6c0,3.6,0,7,0,10.6c0,0.5,0,1.1,0,1.6v0.9c0,3,0,6-0.2,9.2c-0.3,3.1-3.1,5.5-5.7,6.3
   c-0.6,0.2-1.3,0.3-1.9,0.3c-1.9,0-3.3-0.9-3.8-1.2c-1.1-0.7-3.5-2.6-3.4-5.5c0.2-9-0.2-18.3,0.2-26.5V0h44.1l1.6,1.9l0,0
   c3.3,3.9,2.9,6.9,2,8.7C70.9,13.1,68,14.5,63.6,14.5z"
      />
      <path
        d="M40.3,156.8c0,6.2,0,12.4,0,18.9v3.3c3.8,0,7.6,0,11.5,0c0.6,0,1.2,0,1.8,0h0.9c3.3,0,6.5,0,10,0.2c3.3,0.2,5.9,2.9,6.9,5.3
   c0.3,0.5,0.3,1.2,0.3,1.8c0,1.8-1,3.1-1.3,3.5c-0.8,1-2.8,3.2-5.9,3.2c-9.8-0.2-19.8,0.2-28.7-0.2h-11V152l2-1.5l0,0
   c4.2-3.1,7.4-2.7,9.4-1.9C38.8,150,40.3,152.7,40.3,156.8z"
      />
    </svg>
  );
};
export const DesignationIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M0,57.1c12.8,0,25.1,0,37.4,0c2.2,0,4.6-0.3,6.8-0.9c7.8-2.4,12.4-9.2,12.5-17.7c0-11.6,0-23.2,0-34.8c0-1.1,0-2.1,0-3.5
		c1.4,0,2.4,0,3.5,0c47.6,0,95.2,0.2,142.8-0.1c11.1-0.1,18.5,8.2,18.3,18.5c-0.5,18.4-0.1,36.7-0.1,55.2c0,1.1,0,2.1,0,2.7
		c-7.3,0.8-14.2,1.6-21.1,2.4c-1,0.1-2,0.3-2.9,0.7c-4,1.5-8.2,3.1-12.2,4.7c-3.9-2.7-8.5-1.3-12.7-1.3
		c-43.9-0.1-87.9-0.1-131.7-0.1c-0.7,0-1.6-0.2-2,0.1c-0.8,0.6-1.7,1.4-1.8,2.2c-0.1,0.6,0.9,1.7,1.7,2c1.1,0.4,2.3,0.2,3.5,0.2
		c44.8,0,89.4,0,134.1,0c1.2,0,2.3,0,4.4,0c-8.8,7.5-12.6,16.7-14.4,27.1c-8.4,0-16.5,0-24.7,0c-33.4,0-66.7,0-100.1,0
		c-1.9,0-4.6-0.7-4.6,2.3c0,2.9,2.6,2.3,4.5,2.3c40.1,0,80.2,0,120.4,0c1.2,0,2.3,0,3.5,0c0.2,9.3,0.6,18.3-4,26.5
		c0.3,0.2,0.5,0.4,0.8,0.6c-1.4,0-2.9,0-4.3,0c-38.6,0-77.3,0-115.9,0c-1.9,0-4.9-0.9-4.9,2.2c0,3.3,3,2.4,5,2.4
		c39.1,0,78.3,0.1,117.4,0c2.7,0,3.7,0.7,4.2,3.4c0.6,3.1,1.8,6.2,3.2,9c0.8,1.7,2.1,3.8,3.7,4.4c4.6,1.8,5,6.3,7.7,10.1
		c-1.6,0.1-2.6,0.2-3.6,0.2c-44.1,0-88.2,0-132.3,0c-2,0-5.4-1.2-5.3,2.3c0.1,3.3,3.4,2.2,5.5,2.2c44.1,0.1,88.2,0.1,132.3-0.1
		c5.1,0,8.9,1.1,11.2,6.3c-3.7,3-7.8,6.3-11.9,9.3c-0.6,0.5-2.1-0.2-3.7-0.4c-3.7,4.1-9.6,4.8-14.9,7c-14.5,5.7-26.4,14.5-29.8,30.8
		c-1.5,7.1-1,14.5-1.3,21.8c-0.2,4.5,0,9.2,0,13.9c-10.5,0-20.6,0-30.5,0c-25.3,0-50.6,0-75.9,0c-11,0-17.7-6.8-17.7-17.7
		c0-64.3,0-128.7,0-193C0,59.4,0,58.5,0,57.1z M86.5,223.6c-1.2-2.1-2.3-3.8-3.2-5.5c-0.9-1.7-2-3.4-2.2-5.2
		c-0.4-5.3-2.7-7.6-8.1-8.1c-1.8-0.2-3.7-1.1-5.1-2.2c-3.6-3-7.7-3.3-10.9,0c-1.7,1.7-3.4,2.4-5.7,2.4c-4.8,0-7.3,2.8-7.7,7.7
		c-0.1,1.8-0.9,3.8-2.1,5.2c-3.4,4-3.5,7.4-0.1,11.4c1.2,1.4,2.1,3.4,2.2,5.1c0.4,5.1,2.9,7.7,8.1,8c1.7,0.1,3.6,0.9,4.9,2
		c4,3.5,7.6,3.5,11.6,0c1.3-1.1,3.2-1.9,4.9-2c5.1-0.3,7.6-2.8,8-8.1c0.1-1.7,1.2-3.4,2-4.9C84,227.5,85.2,225.7,86.5,223.6z"
        />
        <path
          d="M258.5,123.6c-0.2,3.6-0.3,6.3-0.6,8.8c-0.3,2.7-1,5.2,1.9,7.4c1.3,0.9,1.7,3.5,1.8,5.4c0.4,5.7-0.9,11.2-4.1,15.9
		c-1.1,1.6-3.3,2.4-4.9,3.7c-0.7,0.5-1.4,1.2-1.8,1.9c-2.4,4.3-4.2,9.1-7.2,13c-15.9,21.4-44,20.9-59.3-1c-2.6-3.7-4.2-8.1-6.5-12.1
		c-0.4-0.8-1.4-1.3-2.1-1.8c-1.5-1-3.5-1.7-4.4-3.1c-3.4-5.4-4.5-11.7-4.3-18.1c0-1.4,0.6-3.3,1.5-4c2.2-1.7,1.5-3.7,1.6-5.8
		c0.2-7.3-0.2-14.6,1-21.7c2.1-12.3,9.4-21.3,21.1-25.9c13.6-5.3,27.5-6.5,41.4-1.5s21.5,15.2,23.9,29.5
		C258.2,117.7,258.3,121.2,258.5,123.6z"
        />
        <path
          d="M205.1,299.8c-1.4,0.1-2.4,0.2-3.3,0.2c-19.1,0-38.2,0-57.3,0c-9.7,0-15.2-5.3-15.2-15.1c0-15,0.1-30.2,0.4-45.2
		c0.1-6.8,3.2-12.5,7.6-17.4c6-6.9,16.9-13.3,24.2-14.1c-0.3,3.5-0.4,7-0.9,10.4c-0.7,5.4-0.7,10.8,2.5,15.5c1.2,1.8,1.2,3.6,0,5.5
		c-2,3.2-1.5,6.3,0.2,9.6c10.4,19.1,23.5,35.8,40.4,49.4C204,298.8,204.3,299.1,205.1,299.8z"
        />
        <path
          d="M268.4,208.2c15.5,2,30.6,17.9,31.1,33.2c0.5,14.7,0.5,29.5,0.4,44.3c0,8.8-5.9,14.2-14.6,14.3c-19.4,0-38.6,0-58,0
		c-0.7,0-1.4-0.1-2-0.1c-0.3-0.3-0.5-0.6-0.8-0.9c0.6-0.2,1.4-0.3,1.8-0.7c17-13.9,30.3-31,40.6-50.4c1.5-2.8,1.4-5.5-0.2-8.3
		c-1.2-1.9-1.4-3.8,0-5.7c4.5-6.4,2.3-13.4,2-20.3C268.7,211.8,268.5,210.1,268.4,208.2z"
        />
        <path
          d="M214.9,293.8c8.8-12.7,15-26.2,20-40.3c2.4-6.8,4-13.7,6.3-20.6c1.2-3.5,2.6-7.1,4.4-10.3c2.6-4.7,5.7-9.1,8.5-13.8
		c1.3-2.2,2.1-4.7,3.2-7.3c3.3,2.4,7.4,4.8,6.8,10.6c-0.2,1.8,0,3.8,0.4,5.6c1.2,5.8,0.2,11.3-3,16.2c-1.1,1.8-1.3,3.5,0,5.2
		c2.5,3.2,1.7,6.2-0.2,9.5c-11.1,19.4-24.7,36.4-42.9,49.6c-2.5,1.8-4.5,1.7-7-0.1c-17.4-12.6-30.7-28.8-41.5-47.3
		c-0.5-0.9-0.8-1.8-1.5-2.6c-2.3-2.9-2.4-5.9-0.2-9c1.3-1.8,1.3-3.6,0-5.5c-5.2-7.2-2.4-15.1-2.5-22.9c0-4.7,3.1-6.8,5.6-8.7
		c4.1,6.9,8.1,13.8,12.5,20.5c2.7,4.2,4.4,8.8,5.8,13.6c5.2,19.3,12.4,37.7,23.3,54.5C213.5,291.8,214,292.6,214.9,293.8z"
        />
        <path
          d="M214.3,217.4c-4.2,5.7-8.4,11.2-12.5,16.7c-2.4,3.2-3.6,3.1-5.7-0.2c-5.9-9.6-11.9-19.2-17.8-28.6
		c-1.5-2.4-1.1-3.9,1.1-5.4c2.8-1.9,5.4-3.9,8.1-6.2c1.7-1.4,3-1.3,4.7,0c7.5,5.6,14.9,11.3,22.7,17c4.9-3.8,9.9-7.7,14.8-11.5
		c2.4-1.9,4.9-3.7,7.4-5.6c1.4-1.2,2.7-1.3,4.2-0.1c3,2.3,6,4.6,9.1,6.9c1.7,1.2,1.8,2.6,0.8,4.3c-6.2,10-12.4,20-18.6,29.9
		c-1.5,2.4-3,2.4-4.8,0c-3.8-4.8-7.5-9.8-11.3-14.7C215.7,219.1,215.1,218.3,214.3,217.4z"
        />
        <path
          d="M2.1,52.3C18.3,35.6,34.8,18.6,51.7,1.1c0.2,1.6,0.3,2.3,0.3,3c0,11.3,0,22.6,0,33.8c0,9-5.4,14.3-14.3,14.4
		c-11.5,0-22.9,0-34.4,0C2.7,52.3,2,52.3,2.1,52.3z"
        />
        <path
          d="M214.2,285.1c-3.5-7.3-7-14.4-10.4-21.7c-0.3-0.6-0.2-1.5,0-2.3c0.9-6.2,1.9-12.2,2.7-18.4c0.2-1.5-0.3-3-0.3-4.5
		c0-0.7-0.2-1.6,0.2-2.1c2.5-3.5,5.1-7.1,8.1-10.9c2.8,3.7,5.5,7.3,8.3,10.8c0.3,0.3,0.5,1.1,0.3,1.3c-2.6,4.2,0,8.4,0.2,12.5
		c0.2,3.1,0.8,6.4,1.5,9.5c0.8,3.6,0.4,7-1.4,10.3c-2.8,5.1-5.3,10.4-8.1,15.5C214.9,285,214.6,285.1,214.2,285.1z"
        />
      </g>
    </svg>
  );
};
export const ClientMasterIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M150.17,299.99c-18.95,0-37.91-0.03-56.86,0.01c-20.37,0.04-35.13-12.23-36.22-33.89c-2.38-47.21,11.53-87.82,48.78-115.72
		c31.79-23.81,72.32-19.14,101.07,9.81c21.23,21.38,32.62,48.18,36.17,78.79c1.16,10.04,1.51,20.58,0.01,30.51
		c-3,19.87-15.97,30.49-34.73,30.5C188.99,299.99,169.58,299.98,150.17,299.99z"
        />
        <path
          d="M93,61.76C93.16,27.28,118.71,0.08,151.02,0c31.33-0.08,57.09,28.21,56.86,62.46c-0.22,33.55-26.19,61.21-57.3,61.03
		C118.66,123.31,92.85,95.64,93,61.76z"
        />
        <path
          d="M240.72,299.99c16.97-19.21,16.97-41.21,13.95-63.81c-3.96-29.68-14.81-56.04-34.64-79.6c4.33,0.69,7.48,1.01,10.55,1.72
		c17.92,4.16,32.34,14.52,43.65,29.66c18.03,24.13,25.99,51.93,23.62,82.67c-1.42,18.35-13.77,29.36-30.91,29.35
		C258.66,299.98,250.38,299.99,240.72,299.99z"
        />
        <path
          d="M59.13,299.33c-11.65,0-23.14,1.38-34.2-0.36c-12.64-1.99-18.85-12.83-21.55-25.45c-9.18-42.81,21.37-100.13,60.35-113.33
		c5.12-1.74,10.52-2.55,17.09-4.1c-1.62,2.25-2.29,3.26-3.04,4.21C54.21,190.04,44,224.71,44.91,263.4
		C45.18,275.18,49.12,284.59,59.13,299.33z"
        />
        <path
          d="M84.17,38.84c-8.57,39.01,3.18,68.62,35.23,89.59c-0.99,0.58-1.94,1.12-2.88,1.69c-7.09,4.31-14.13,8.72-21.29,12.88
		c-2.31,1.34-4.91,2.73-7.46,2.92c-27.74,2.1-50.84-19.7-53.44-50.21C31.97,68.13,52.37,42,78.76,38.87
		C80.52,38.66,82.33,38.84,84.17,38.84z"
        />
        <path
          d="M182.21,128c31.13-20.38,43.11-49.87,34.44-89.19c7.86-0.5,15.15,1.7,22.09,5.38c21.5,11.38,32.43,37.49,26.18,62.28
		c-6.17,24.48-27.84,41.15-51.53,39.52c-2.04-0.14-4.28-0.61-6.02-1.69C198.91,139.01,190.6,133.47,182.21,128z"
        />
      </g>
    </svg>
  );
};
export const ClientLocationIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M149.96,0C70.74,0.5,19.15,80.94,48.08,147.93c23.83,55.2,59.58,102.32,96.61,148.78c4.11,5.16,7.46,3.72,11-0.67
	c33.73-41.87,66.15-84.66,89.51-133.44c7.94-16.57,14.66-33.8,14.85-52.64C260.63,50.11,210.43,0.03,149.96,0z M185.81,167.58
	c-11.3,5.47-23.34,7.85-35.84,8.07c-12.89-0.23-25.14-3.04-36.79-8.64c-7.5-3.61-6.46-7.92-0.45-11.32
	c5.15-2.91,11.09-4.42,16.68-6.54c13.55-5.12,14.12-11.55,5.07-22.01c-9.69-11.19-20.63-22.95-14.42-40.54
	c1.34-3.79,0.21-8.41,0.44-12.63c0.93-17.16,13.62-29.76,29.75-29.66c15.76,0.1,28.85,12.34,29.15,28.95
	c0.15,8.44,4.71,17.09,2.53,24.44c-3.07,10.35-6.62,21.39-15.41,29.24c-11.06,9.89-9.52,17.12,4.56,22.34
	c4.96,1.84,10.03,3.38,14.89,5.45c2.92,1.24,5.85,3.27,6.12,6.75C192.42,165.55,188.52,166.26,185.81,167.58z"
      />
    </svg>
  );
};
export const WorkplaceIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M0,251.6c2.1-5.1,5.9-6.7,11.4-6.7c92.5,0.1,184.9,0.1,277.4,0.1c8.3,0,11.3,3,11.3,11.2c0,10.8,0,21.7,0,32.5
		c0,8.5-3,11.5-11.4,11.5c-92.3,0-184.5,0-276.8,0.1c-5.4,0-9.4-1.5-11.9-6.5C0,279.7,0,265.7,0,251.6z"
        />
        <path
          d="M35.5,236.3c-2-8.9-4-17.6-6-26.4c-3.2-14.3-6.3-28.5-9.5-42.8c-2.3-10.1,2.3-16,12.6-16c78.4,0,156.8,0,235.1,0
		c9.6,0,14.4,5.9,12.4,15.2c-4.9,22.4-9.9,44.7-14.9,67.1c-0.2,0.9-0.6,1.9-0.9,2.9C188.1,236.3,111.9,236.3,35.5,236.3z M150,216
		c6.2,0,11.1-5,11.1-11.1c0-6-5.2-11.2-11.2-11.2c-6,0-11.1,5.2-11.1,11.2C138.9,211.1,143.8,216,150,216z"
        />
        <path
          d="M66.7,140.7c20.1-24,46.1-29.9,75.4-27.5c-15.9-3.4-29.1-10.9-38.4-24.3c-9.3-13.5-12.2-28.5-8.8-44.6
		c5.5-26.2,29.8-45.1,57-44.3c26.8,0.8,49.8,21.2,54.1,47.5c4.3,26.4-11.7,59-48,65.8c29.2-2.5,55.3,3.3,75.4,27.4
		C177.6,140.7,122.5,140.7,66.7,140.7z"
        />
      </g>
    </svg>
  );
};
export const EmployeeIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M0,251.6c2.1-5.1,5.9-6.7,11.4-6.7c92.5,0.1,184.9,0.1,277.4,0.1c8.3,0,11.3,3,11.3,11.2c0,10.8,0,21.7,0,32.5
		c0,8.5-3,11.5-11.4,11.5c-92.3,0-184.5,0-276.8,0.1c-5.4,0-9.4-1.5-11.9-6.5C0,279.7,0,265.7,0,251.6z"
        />
        <path
          d="M35.5,236.3c-2-8.9-4-17.6-6-26.4c-3.2-14.3-6.3-28.5-9.5-42.8c-2.3-10.1,2.3-16,12.6-16c78.4,0,156.8,0,235.1,0
		c9.6,0,14.4,5.9,12.4,15.2c-4.9,22.4-9.9,44.7-14.9,67.1c-0.2,0.9-0.6,1.9-0.9,2.9C188.1,236.3,111.9,236.3,35.5,236.3z M150,216
		c6.2,0,11.1-5,11.1-11.1c0-6-5.2-11.2-11.2-11.2c-6,0-11.1,5.2-11.1,11.2C138.9,211.1,143.8,216,150,216z"
        />
        <path
          d="M66.7,140.7c20.1-24,46.1-29.9,75.4-27.5c-15.9-3.4-29.1-10.9-38.4-24.3c-9.3-13.5-12.2-28.5-8.8-44.6
		c5.5-26.2,29.8-45.1,57-44.3c26.8,0.8,49.8,21.2,54.1,47.5c4.3,26.4-11.7,59-48,65.8c29.2-2.5,55.3,3.3,75.4,27.4
		C177.6,140.7,122.5,140.7,66.7,140.7z"
        />
      </g>
    </svg>
  );
};
export const RequestIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M300,287.6c-1.3-1.2-2.7-2.2-4-3.5c-27.6-27.3-55.3-54.6-82.9-81.9c-0.2-0.2-0.4-0.5-0.7-0.9c0.5-0.4,0.9-0.9,1.5-1.3
		c28.1-19.4,56.2-38.8,84.4-58.2c0.6-0.4,1.2-0.6,1.8-1C300,189.9,300,238.8,300,287.6z"
        />
        <path
          d="M150.1,43.4c30.8,0,61.6,0,92.3,0c6.4,0,8.3,1.8,8.3,8.5c0,32.6,0,65.2,0,97.8c0,2.1-0.5,3.3-2.2,4.4
		c-32.1,22-64.1,44.2-96.1,66.3c-1.8,1.3-3,1.3-4.9,0.1c-32.1-22.2-64.2-44.4-96.3-66.5c-1.6-1.1-2.2-2.2-2.2-4.2
		c0.1-32.9,0-65.8,0-98.8c0-5.6,2-7.7,7.5-7.7C87.8,43.4,118.9,43.4,150.1,43.4z M177.7,101.4c-0.1-17.5-12.1-30.3-27.3-30.5
		c-15.4-0.2-27.8,12.2-28.2,28.2c-0.1,3.8,1.2,6.8,4.3,8.7c6.1,3.7,12.6-0.5,13.3-8.5c0.4-5,3.3-8.6,7.6-9.7c4.1-1.1,8.6,0.8,11,4.6
		c2.4,3.9,2.2,8-1,12.2c-1.9,2.6-4.1,4.9-6.2,7.4c-6.5,7.6-10,16.5-10.1,26.7c-0.1,6.1,3.3,10.3,8.4,10.4c5.4,0.2,8.9-3.7,9.2-9.9
		c0.3-6.2,2.3-11.6,6.4-16.1c1.8-2,3.8-3.9,5.5-5.9C175.3,113.7,177.8,107.4,177.7,101.4z M158.9,170.8c-0.1,0-0.2,0-0.2,0
		c0-1,0-2,0-3.1c-0.2-5.2-3.9-9.2-8.7-9.3c-4.8-0.1-8.8,4-8.9,9.3c-0.1,2.2-0.1,4.5,0,6.7c0.3,4.4,3.6,7.9,7.6,8.4
		c4.3,0.5,8.1-1.8,9.4-6.1C158.7,174.8,158.7,172.8,158.9,170.8z"
        />
        <path
          d="M102.8,211.8c6.8,4.7,13.5,9.3,20.2,13.9c7,4.8,14.1,9.6,21,14.5c3.9,2.8,7.7,2.8,11.7,0.1c13-9,26.1-18,39.1-27
		c1.5-1.1,2.5-1.3,4,0.2c28.7,28.5,57.5,56.9,86.3,85.3c0.3,0.3,0.5,0.6,1.1,1.3c-90.9,0-181.4,0-272.6,0
		C43.5,270.4,73.1,241.2,102.8,211.8z"
        />
        <path d="M87.7,201.3C58.4,230.2,29.4,258.9,0,287.9c0-49.2,0-97.9,0-147.1C29.3,161,58.3,181.1,87.7,201.3z" />
        <path d="M119.3,24.7c9.4-8.1,18.3-15.8,27.2-23.4c2-1.7,4.6-1.8,6.6-0.1c9,7.6,18,15.3,27.5,23.5C159.9,24.7,140,24.7,119.3,24.7z" />
        <path d="M268.5,100.7c8.2,7.7,16.1,15.1,24.3,22.8c-8.1,5.6-16,11.1-24.3,16.8C268.5,127,268.5,114.1,268.5,100.7z" />
        <path d="M31.3,140.3C23,134.6,15.1,129.1,7,123.5c8.1-7.6,16-15,24.3-22.8C31.3,114.1,31.3,126.9,31.3,140.3z" />
      </g>
    </svg>
  );
};
export const MasterIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        className="st0"
        style={{ fill: "none" }}
        d="M150.4,56.4c4.8,5.3,9.8,10.7,14.5,15.9c5-5.4,10-10.8,14.9-16c-4.8-5.2-9.8-10.6-14.6-15.8
	C160.3,45.7,155.4,51,150.4,56.4z"
      />
      <path
        d="M295.7,242.1c-11.1-11.2-22.1-22.3-33.3-33.4c-1.7-1.7-2.4-3.3-2.4-5.7c0.1-51.6,0.1-103.1,0.1-154.7c0-1.2,0-2.3,0-3.7
	c-1.8,0-3.1,0-4.4,0c-19.9,0-39.8,0-59.7,0.1c-2.3,0-3.8-0.7-5.4-2.3c-10.9-11.1-21.8-22-32.7-33C157,8.5,156,7.6,155.4,7
	c-16.6,16.7-32.9,33.1-48.9,49.3c16.3,16.5,32.5,32.9,48.9,49.4c0.7-0.6,1.4-1.3,2.2-2.1c11.1-11.1,22.1-22.3,33.3-33.4
	c1.2-1.1,3.1-2,4.7-2c12.6-0.2,25.2-0.1,37.9-0.1c1,0,2.1,0.1,3.3,0.2c0,1,0,1.8,0,2.5c0,44.2,0,88.5-0.1,132.7
	c0,1.5-0.9,3.4-1.9,4.5c-11.1,11.3-22.3,22.5-33.4,33.7c-0.7,0.7-1.4,1.6-1.8,2c16.5,16.7,32.8,33.1,48.7,49.2
	c16.2-16.2,32.5-32.7,49-49.2C296.9,243.4,296.3,242.7,295.7,242.1z M154.9,72.3c-5.1-5.2-10.4-10.6-15.6-15.9
	c5.4-5.4,10.7-10.7,15.9-16c5.2,5.2,10.6,10.6,15.8,15.8C165.6,61.5,160.2,66.9,154.9,72.3z M248.2,259.9
	c-5.1-5.1-10.4-10.5-15.7-15.8c5.2-5.2,10.6-10.6,15.7-15.8c5.2,5.2,10.5,10.7,15.7,15.9C259,249.3,253.6,254.6,248.2,259.9z"
      />
      <path
        d="M2.7,122.4c0,23.5,0,46.7,0,69.9c65,0,129.9,0,194.7,0c0-23.4,0-46.6,0-69.9C132.4,122.4,67.6,122.4,2.7,122.4z M175.8,169
	c-50.4,0-100.8,0-151.6,0c0-7.7,0-15.3,0-23c50.5,0,100.9,0,151.6,0C175.8,153.5,175.8,161.1,175.8,169z"
      />
    </svg>
  );
};
export const EmployeeTypesIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M236.1,205c0,13.8-0.1,27.6,0.1,41.4c0.1,4.6-1,7.4-5.5,9.8c-25.7,14.2-51.2,28.7-76.9,42.8c-2.5,1.3-7.1,1.3-9.6,0
		c-26.1-14.7-51.9-29.9-77.9-44.6c-4.2-2.4-5.7-5.2-5.7-9.8c0.2-25.9,0-51.8,0.1-77.8c0.1-18,9-29.3,26.7-34.4
		c8.9-2.5,17.7-5.4,26.7-7.4c5.1-1.1,6.2-4.4,4.6-7.8c-4-8.9-7.3-18.8-13.7-25.9c-5.6-6.2-8-12.5-8.4-19.8
		c-0.2-5.1,1.9-10.3,3.2-15.5c0.6-2.5,2.2-5,2.5-7.5c0.5-4.7,0.1-9.4,0.4-14.1c1.3-19.9,15.9-33.7,36.2-34.2
		c6.5-0.2,12.9-0.2,19.4,0c19.7,0.6,34.4,15.1,35.2,34.2c0.3,8.9,1.2,17.1,4.8,26c3,7.3-1.5,17.7-3.7,26.5c-1,4.1-4.2,7.9-6.5,11.7
		c-2.8,4.8-5.4,9.6-8.6,14.1c-4.4,6.3-3.5,9.8,3.8,11.9c8.9,2.6,17.7,5.1,26.6,7.7c16.6,4.9,25.5,16.6,25.6,33.6
		C236.1,179.1,236,192,236.1,205z M112.9,84.3c2.5,7,3.4,14,7.2,19c4.8,6.5,8.9,12.3,7.5,20.7c-0.2,1.3,0.7,3.5,1.8,4.3
		c5.9,3.7,11.7,8.5,18.3,10c3.7,0.9,8.7-3.8,13.1-6.1c0.5-0.2,0.9-0.7,1.4-0.8c5.6-2,7.2-6,6.8-11.6c-0.2-3,0-7.3,2-9.1
		c7.6-7.2,10.8-15.9,11.3-25.7c9.9-3.9,13-17.4,5.9-24.3c-1.2,1.5-2.6,2.9-5.1,5.9c-4-7.8-7.8-14.2-10.6-20.9
		c-2.9-6.9-7.7-10.3-14.9-11.8c-7.4-1.6-14.7-3.9-24.8-6.7c-2.3,5.2-5.1,11.9-8.4,18.6c-3.1,6.2-6.6,12-10.1,18
		c-0.3,0.6-1.3,0.9-2.3,1.4c-1.2-1.6-2.5-3.1-3.7-4.8C101.6,68.5,104.9,81,112.9,84.3z M123.1,250c5.1,2,10.1,4,15.1,6.1
		c6.5-9.1,14-9.1,20.4-0.1c2.5-0.9,5.2-1.8,7.6-2.8c2.6-1,5.1-2.2,7.4-3.3c-2.4-10.2,3.5-15.9,14.3-13.9c1-2.3,2.3-4.8,3.3-7.3
		c1-2.4,2-5,2.9-7.4c-9.2-6.3-9.1-12.4,0.1-20.6c-2-4.7-4-9.4-6.1-14.2c-10.6,2.1-15.8-2.8-15.1-14.4c-3.5-1.5-7.1-3-10.6-4.5
		c-1.2-0.5-2.6-0.9-3.7-1.3c-6.2,9.1-13.6,9-20.5,0c-2.6,1-5.2,1.9-7.7,3c-2.5,1.1-5,2.2-7.3,3.3c2.4,10.1-3.4,15.6-14.5,13.9
		c-1,2.4-2.2,5-3.2,7.5c-1,2.4-2,5-2.9,7.4c9.4,5.8,9.3,12.4,0.2,20.2c0.9,2.2,1.9,4.6,2.9,6.8c1,2.4,2.2,5,3.3,7.3
		C119.9,233.8,125.4,239.6,123.1,250z"
        />
        <path
          d="M225.2,132.1c10.1-4.4,11.1-9.6,5.3-18c-4.2-6.1-8-12.7-10.6-19.5c-1.2-3.1,0.9-7.5,1.5-11.3c0.5-2.9,1.3-5.8,1.4-8.7
		c0.6-20.2,5.7-25.1,26.5-24.9c3.9,0,8.1,0,11.4,1.7c3.5,1.7,7.6,4.9,8.8,8.3c3.1,9.6,7,19.8,6.2,29.6c-0.7,8.6-6.4,17.2-11.3,24.9
		c-5.6,8.6-4.2,14.5,5.9,17.3c29.7,8.3,29.7,8.3,29.7,38.8c0,10.2,0.2,20.4-0.1,30.6c-0.1,2.4-1,6-2.8,7
		c-17.6,10.6-35.5,20.8-54.8,32c0-8.3,0-15.1,0-21.9c0-15.7-0.3-31.5,0.1-47.2C242.7,155.4,238.6,142.2,225.2,132.1z"
        />
        <path
          d="M54.7,237.4c-18.4-10.5-35.1-20-51.6-29.9c-1.8-1.1-2.9-4.6-2.9-6.9C0,185-0.1,169.2,0.1,153.5c0.1-10,4.1-14.8,14.2-17.7
		c5.4-1.6,10.8-3,16.2-4.6c9.3-2.6,10.7-8.9,5.2-17.1c-4.3-6.3-7.9-13.1-10.6-20.1c-1.1-3.1,0.8-7.4,1.4-11.1
		c0.6-4.3,1.4-8.6,1.7-12.9c0.6-14.7,6.9-20.8,22.1-20.9c3.9,0,8.4-0.7,11.7,0.8c4.7,2.2,10.9,5.5,12.5,9.6c3.5,9.7,6.8,20.4,6,30.4
		c-0.7,8.5-6.9,16.9-11.7,24.7c-3.9,6.5-3.5,12.2,3.9,16.8c-16.5,10.9-18.6,27.1-18,44.6C55.1,195.8,54.7,215.5,54.7,237.4z"
        />
        <path
          d="M148.9,237.6c-15.2,0.2-27.3-11.5-27.6-26.4c-0.2-14.7,11.9-26.7,27-27c15.1-0.3,27.3,11.4,27.4,26.4
		C175.9,225.7,164.2,237.5,148.9,237.6z"
        />
      </g>
    </svg>
  );
};
export const EmployeeMultiRegistrationIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M198.54,0.2c6.93-0.07,13.71-0.45,20.4,0.17c6.06,0.57,10.04,6.46,10.05,13.07c0.02,37.08,0.01,74.16,0.01,111.24
		c0,11.02,0,22.05,0,32.79c0,0.89-0.64,1.65-1.52,1.82c-6.27,1.2-12.37,1.96-18.11,3.81c-25.16,8.09-42.2,24.49-50.72,49.09
		c-0.98,2.84-3.67,4.77-6.72,4.73c-13.02-0.15-26.05-0.21-39.06,0.09c-2.47,0.06-5.72,1.39-7.17,3.23c-3.14,4,0.45,9.28,6.16,9.37
		c8.9,0.14,17.8,0.04,26.69,0.04c4.63,0,9.25,0,14.17,0c1.07,0,1.91,0.87,1.88,1.92c-0.44,16.68,3.41,32.02,12.68,46.21
		c0.79,1.21-0.06,2.85-1.52,2.86c-0.01,0-0.02,0-0.03,0c-47.73,0.02-95.46,0.02-143.18,0c-8.27,0-13.55-5.29-13.55-13.53
		C8.98,182.6,8.98,98.08,9,13.55C9,5.33,14.3,0.06,22.55,0.02c5.48-0.03,10.95-0.01,16.88-0.01L198.54,0.2z M156.3,95.7
		c9.16,0,18.33,0,27.49,0c5.79,0,11.59,0.11,17.38-0.06c4.03-0.11,6.87-3.04,6.77-6.5c-0.1-3.34-2.84-5.91-6.69-6.2
		c-1.07-0.08-2.16-0.03-3.23-0.03c-24.12,0-48.24,0-72.36,0c-4.72,0-9.44-0.11-14.15,0.04c-4.6,0.14-7.66,3.64-6.82,7.49
		c0.74,3.42,3.41,5.24,7.95,5.25C127.19,95.71,141.74,95.7,156.3,95.7z M156.19,146.7c-8.62,0-17.25,0-25.87,0
		c-6.33,0-12.67-0.1-19,0.05c-4.03,0.1-6.88,3.01-6.79,6.48c0.09,3.34,2.83,5.92,6.67,6.23c1.07,0.08,2.15,0.03,3.23,0.03
		c24.93,0,49.86,0,74.79,0c4.04,0,8.09,0.16,12.12-0.06c4.72-0.26,7.61-4.25,6.18-8.2c-1.29-3.57-4.14-4.54-7.68-4.53
		C185.3,146.72,170.75,146.7,156.19,146.7z M50.47,87.37c-0.77,0.79-2.06,0.77-2.79-0.05c-1.23-1.38-2.03-2.28-2.82-3.18
		c-1.14-1.31-2.19-2.7-3.41-3.92c-2.69-2.7-6.42-2.96-9.04-0.75c-2.71,2.29-3.07,5.81-0.61,8.88c2.91,3.64,5.95,7.2,9.1,10.64
		c4.08,4.45,9.35,4.64,13.83,0.54c3.25-2.98,6.33-6.13,9.49-9.21c7.17-6.99,14.4-13.93,21.49-21.01c3.85-3.84,2.82-9.44-1.89-10.9
		c-3.04-0.94-5.46,0.23-7.58,2.42C67.79,69.56,59.31,78.27,50.47,87.37z M50.48,151.14c-0.77,0.8-2.09,0.77-2.81-0.07
		c-2.43-2.82-4.24-4.98-6.19-7.02c-2.64-2.76-6.39-3.02-9.03-0.84c-2.73,2.26-3.13,5.83-0.69,8.87c2.55,3.17,5.16,6.3,7.9,9.31
		c4.4,4.83,11.99,5.05,16.65,0.46c0.81-0.79,1.6-1.61,2.41-2.4c8.99-8.77,18.01-17.5,26.94-26.32c3.27-3.23,3.37-6.74,0.57-9.47
		c-2.66-2.59-6.41-2.36-9.65,0.64c-1.47,1.36-2.91,2.76-4.3,4.2C65.07,135.98,57.87,143.47,50.48,151.14z M56.96,221.33
		c-0.76,0.79-2.06,0.78-2.79-0.04c-1.22-1.36-2.02-2.25-2.8-3.15c-1.14-1.3-2.19-2.69-3.41-3.93c-2.68-2.71-6.41-3.02-9.04-0.85
		c-2.74,2.26-3.15,5.82-0.7,8.87c2.66,3.31,5.4,6.58,8.27,9.71c4.17,4.56,11.32,4.82,15.8,0.56c1.18-1.12,2.3-2.29,3.46-3.43
		c8.8-8.58,17.63-17.13,26.38-25.77c3.27-3.23,3.38-6.74,0.58-9.47c-2.66-2.59-6.41-2.37-9.65,0.64c-1.57,1.45-3.11,2.94-4.59,4.48
		C71.35,206.35,64.24,213.75,56.96,221.33z"
        />
        <g>
          <path
            d="M276.8,278.79c-24.59-32.59-71.52-31.16-94.86-0.02c-18.8-17.24-26.47-56.42-2-85.39c23.99-28.4,67.89-30.52,94.54-4.33
			C302.14,216.24,298.12,257.57,276.8,278.79z M229.34,248.33c14.19-0.11,25.96-12.07,25.78-26.2c-0.17-13.99-12-25.62-26-25.57
			c-14.13,0.05-25.98,12.09-25.78,26.19C203.56,236.77,215.42,248.44,229.34,248.33z"
          />
          <path
            d="M267.03,287.67c-22.73,17.17-55.59,15.71-75.38-0.07c8.66-12.08,20.26-18.98,35.1-19.75
			C243.63,266.97,256.82,274.02,267.03,287.67z"
          />
        </g>
        <path
          d="M229.1,209.53c7.35-0.05,13.1,5.66,13.07,12.98c-0.02,7.15-5.66,12.81-12.79,12.86c-7.33,0.05-13.09-5.67-13.06-12.99
		C216.34,215.22,221.95,209.58,229.1,209.53z"
        />
      </g>
    </svg>
  );
};
export const VisitorIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M149.9,300c-43.5,0-86.9,0-130.4,0c-8.1,0-14.1-5.1-15.9-13.5c-0.4-1.7-0.4-3.5-0.4-5.3c0-65.6,0-131.1,0-196.7
		c0-12,6.2-19.1,16.7-19.1c86.8,0,173.6,0,260.4,0c9.9,0,16.3,7.1,16.3,18.5c0,65.8,0,131.6,0,197.4c0,11.6-6.3,18.7-16.4,18.7
		C236.9,300,193.4,300,149.9,300z M23.1,107.8c0,33.4,0,66.6,0,99.7c25.1,0,50,0,74.9,0c0-33.3,0-66.4,0-99.7
		C73,107.8,48.2,107.8,23.1,107.8z M278.4,121.8c0-4.9,0-9.4,0-13.9c-47.7,0-95.1,0-142.6,0c0,4.8,0,9.4,0,13.9
		C183.5,121.8,230.9,121.8,278.4,121.8z M278.5,158c-47.8,0-95.3,0-142.7,0c0,4.8,0,9.3,0,13.8c47.6,0,95.1,0,142.7,0
		C278.5,167.1,278.5,162.6,278.5,158z M245,256.1c2.2,0,4,0.2,5.7,0c5.4-0.7,9.2,2,12,7.1c2.3,4.1,4.7,8.2,7,12.3
		c2.6,4.6,4.8,5.4,9.7,3.4c-5.2-7.8-8.1-17.3-15.8-23.2c1.2-0.4,2-0.7,2.9-1c6.4-2.2,10-7.9,9.4-14.8c-0.7-7.7-4.8-12.4-11.9-12.8
		c-7.9-0.4-15.8-0.3-23.7-0.4c-0.4,0-0.7,0.3-1.1,0.4c0,17.5,0,34.8,0,52.3c2,0,3.8,0,5.9,0C245,271.7,245,264.2,245,256.1z
		 M186.7,253.1c-0.1,12.8,5.5,22.7,14.9,26.2c10.9,4.1,27.4-1.1,29.2-22.9c1.6-20-10.6-33.6-27.1-30
		C193.4,228.5,186.8,239,186.7,253.1z M87.3,262.7c0.1,5.1,1.6,8.9,4.4,12.1c6.5,7.4,21.2,7.8,28,0.8c6.6-6.8,5.5-18.3-2.3-23.1
		c-1.9-1.2-4-1.9-6.1-2.6c-3.8-1.3-7.6-2.3-11.4-3.6c-2.9-1-5-2.9-4.9-6.8c0.1-3.3,2.1-5.7,5.4-6.9c8-2.7,13.1,0.1,16.8,9.5
		c1.7-0.2,3.5-0.5,5.4-0.7c-0.6-7-3.3-12-8.9-13.8c-4-1.3-8.4-1.7-12.5-1.3c-7.4,0.9-11.8,6.1-12.3,13.2c-0.4,6.5,3.2,11.5,10.4,14
		c4.3,1.5,8.6,2.6,12.9,4.1c4.1,1.5,6,4.1,5.9,7.7c-0.1,3.7-2.5,7-6.4,8.1c-7.8,2.2-16.9,0.2-19-11.4
		C90.9,262.3,89.2,262.5,87.3,262.7z M23.7,227.1c5.6,16.7,11.2,33,16.6,49.5c0.8,2.4,1.9,3.2,4.1,3.1c2.2,0,3.5-0.4,4.4-3.1
		c5.2-15.7,10.6-31.2,15.9-46.8c0.3-0.9,0.5-1.9,0.8-2.9c-5.8-0.7-5.8-0.7-7.6,5c-0.3,1-0.6,2-1,3c-4.1,12.5-8.1,25.1-12.3,38
		c-0.3-0.7-0.6-1.1-0.7-1.5c-4.1-13.2-8.1-26.4-12.2-39.6C29.9,225.8,29.7,225.7,23.7,227.1z M168.2,279.5c0-15.6,0-30.9,0-46.7
		c5.2,0,10.1,0,15.1,0c0-2.3,0-4.1,0-6c-12.2,0-24.2,0-36.2,0c0,2.1,0,4,0,6.2c5,0,9.9,0,15,0c0,15.7,0,31,0,46.5
		C164.1,279.5,165.9,279.5,168.2,279.5z M150,79.4c-2.7,0-5.4-0.1-8.1,0c-3.9,0.2-7.2,3.5-7.4,7.2c-0.2,3.5,3.1,7.4,7,7.6
		c5.7,0.3,11.4,0.3,17.1,0c3.9-0.2,7.1-4,6.9-7.6c-0.2-3.7-3.5-7-7.4-7.1C155.4,79.3,152.7,79.4,150,79.4z M139.3,226.9
		c-2.2,0-4,0-5.8,0c0,17.7,0,35,0,52.5c2,0,3.8,0,5.8,0C139.3,261.8,139.3,244.4,139.3,226.9z M72.6,226.8c0,17.8,0,35.2,0,52.7
		c2,0,3.7,0,5.6,0c0-17.6,0-35.1,0-52.7C76.3,226.8,74.6,226.8,72.6,226.8z"
        />
        <path
          d="M165.5,58.8c-10.6,0-20.7,0-31.1,0c0-7.4,0-14.6,0-21.8c0-8.4,0-16.8,0-25.2c0-7.6,3-11.7,8.4-11.7c5-0.1,10-0.1,15,0
		c4.2,0.1,7.6,3.7,7.7,9C165.6,25.6,165.5,42,165.5,58.8z"
        />
        <path
          d="M91.2,201.1c-20.8,0-41.5,0-62.4,0c-0.5-7.3-0.8-14.6,1.6-21.4c0.7-2,3-3.9,5-4.6c3.4-1.3,7.1-1.5,10.7-2.3
		c1.3-0.3,2.7-0.5,3.9-1c3.8-1.5,4.5-6.1,1.5-9c-7-6.8-8-19.9-2.1-28.1c6.1-8.4,16.3-8.1,21.9,0.8c5.3,8.4,4.2,20.6-2.6,27.2
		c-1.3,1.3-2.4,2.3-1.9,4.7c0.6,2.5,1.7,4.1,3.8,4.6c3.6,0.8,7.2,1.4,10.7,2.1c7.4,1.4,8.7,2.8,9.7,11.3c0.5,3.9,0.3,7.8,0.4,11.8
		C91.4,198.4,91.3,199.6,91.2,201.1z"
        />
        <path
          d="M245.2,232.8c6,0,12-0.3,17.8,0.1c3.9,0.3,6.3,3.7,6.5,7.8c0.2,4.2-2.1,8.1-6.1,8.5c-6,0.6-12,0.1-18.2,0.1
		C245.2,244.2,245.2,238.5,245.2,232.8z"
        />
        <path
          d="M192.9,253.6c0.2-2.1,0.3-4.3,0.7-6.4c1.4-9.3,6.7-14.9,14.4-15.3c8-0.4,14,4.5,15.7,13.8c0.8,4.7,0.9,9.8,0.2,14.4
		c-1.5,9.6-8,14.9-16.4,14.3c-7.9-0.6-13.6-7.6-14.4-17.6c-0.1-1.1,0-2.1,0-3.2C193,253.6,192.9,253.6,192.9,253.6z"
        />
      </g>
    </svg>
  );
};
export const LeaveIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M148.5,5.9c37,0.2,67.2,29.9,68.4,65.6c0.2,6-0.4,12.1-1,18c-0.6,5.3-2.6,10,2.5,15.2c4.8,4.9,1.7,14.7-4.8,16.7
     c-4.9,1.6-5.1,4.7-5.8,8.3c-3.6,19.8-13,36.2-29.5,47.8c-23.9,16.9-53.3,10.2-71.9-15.9c-7.1-10-11.9-21-13.6-33.2
     c-0.4-3.1-0.9-5.4-5-6.7c-8-2.6-9.6-12.7-4.3-19.6c1.5-2,2.5-5.6,2-8c-9-39.9,13.8-78.5,51.9-87.1C141.6,6.2,146.1,6.1,148.5,5.9z"
        />
        <path
          d="M223.9,204.2c-19.6,29.4-17.2,60.8,5.4,79.1c21.3,17.2,52.2,12.7,69.4-22.4c0,9.7,0.1,17.9,0,26c-0.1,5.9-3.7,7.3-8.3,7.3
     c-15.5-0.1-31,0-46.5,0c-76.7,0-153.5,0-230.2,0c-11.1,0-10.6-0.9-12-13.3c-1.9-17.3,4.7-28,16.2-38.1
     c24.3-21.2,50.6-37.3,80.3-45.8c4.7-1.4,10.7-0.5,15.4,1.5c24.1,10.5,48.2,11,72.1,0.3c6.8-3,12.7-3.9,19.4-1.3
     C211,199.7,217.2,201.8,223.9,204.2z"
        />
        <path
          d="M256.3,288.1c-23.2,0-41-17.9-41-41.4c0-22.3,18.2-40.5,40.6-40.6c22.5-0.1,40.9,18.2,41.2,41
     C297.5,269.5,279,288.1,256.3,288.1z M224.4,251.7c15.4,0,30.1,0,46.7,0c-6.4,6-11.5,10.8-16.5,15.6c0.3,0.6,0.5,1.3,0.8,1.9
     c4.1-0.8,9.4-0.2,12.2-2.6c7.1-6,13.1-13.4,19.8-20.4c-5.3-5-11.4-10.3-16.8-16.3c-4.7-5.2-9.8-4.4-16.5-1.8
     c4.9,4.6,9.9,9.2,15.7,14.6c-15.6,0-30.2,0-45.2,0C224.4,245.9,224.4,248.3,224.4,251.7z"
        />
      </g>
    </svg>
  );
};
export const LeaveTypeIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          style={{ fill: "#FFFFFF" }}
          d="M0,257.7c0-56.4,0-112.7,0-169.1c93.8,0,187.6,0,281.3,0c0,19.1,0,37.8,0,57.1c-17.3-13.9-36.7-21-59-22
		c5.4-3.2,7.3-6.7,6.2-10.8c-1.1-4.4-4.5-6.7-10-6.7c-5.2,0-10.3,0-15.5,0c-5.5,0.1-9.3,3.8-9.3,8.8c0,5,3.9,8.7,9.4,8.7
		c3,0,6.1,0,9.1,0c-20.4,2.1-38.4,9.7-54.2,22.6c-3-3.7-4.7-4.9-7.8-5c-6.2-0.1-12.5-0.2-18.7,0c-4.6,0.2-8.1,4.2-8.1,8.8
		c0.1,4.4,3.5,8.2,8,8.6c1.5,0.1,3.1,0.1,4.7,0.1c2.9,0,5.9,0,8.9,0c-3.3,5.3-6.4,10.2-9.3,15.3c-0.9,1.6-1.8,2.4-3.8,2.4
		c-3.7,0.1-6.4,2.1-7.8,5.6c-1.3,3.3-0.7,6.5,1.9,8.9c1.6,1.5,1.6,2.7,1.1,4.6c-5,17.5-4.8,35,0.5,52.4c1.7,5.4,4,10.7,6.1,16.2
		c-1.2,0-2.3,0-3.5,0c-39.5,0-79-0.1-118.5,0.1C6.4,264.2,2.3,262.7,0,257.7z M70.6,123.6c2.9,0,5.9,0.1,8.8,0c5.2-0.1,9-4,9-8.9
		c-0.1-4.8-3.8-8.5-8.9-8.6c-5.8-0.1-11.5-0.1-17.3,0c-5.2,0.1-9,3.9-9,8.9c0.1,4.8,3.8,8.5,8.9,8.7C65,123.7,67.8,123.6,70.6,123.6
		z M141,123.6c2.9,0,5.9,0.1,8.8,0c5-0.2,8.8-3.9,8.8-8.7c0-4.8-3.7-8.7-8.7-8.8c-5.8-0.1-11.7-0.1-17.6,0c-5,0.1-8.8,3.9-8.8,8.7
		c0,4.8,3.7,8.6,8.7,8.8C135.2,123.7,138.1,123.6,141,123.6z M70.8,158.8c2.9,0,5.9,0.1,8.8,0c5-0.2,8.8-3.9,8.8-8.7
		c0-4.8-3.7-8.7-8.7-8.8c-5.8-0.1-11.7-0.1-17.6,0c-5,0.1-8.8,3.9-8.8,8.7c0,4.8,3.7,8.6,8.7,8.8C64.9,158.8,67.9,158.8,70.8,158.8z
		 M70.8,193.9c2.9,0,5.9,0.1,8.8,0c5-0.2,8.8-3.9,8.8-8.7c0-4.8-3.7-8.7-8.7-8.8c-5.8-0.1-11.7-0.1-17.6,0c-5,0.1-8.8,3.9-8.8,8.7
		c0,4.8,3.7,8.6,8.7,8.8C64.9,194,67.9,193.9,70.8,193.9z M70.8,229c2.9,0,5.9,0.1,8.8,0c5-0.2,8.8-3.9,8.8-8.7
		c0-4.8-3.7-8.7-8.7-8.8c-5.8-0.1-11.7-0.1-17.6,0c-5,0.1-8.8,3.9-8.8,8.7c0,4.8,3.7,8.6,8.7,8.8C64.9,229.1,67.8,229,70.8,229z"
        />
        <path
          d="M214.8,299.8c-7.6-1.8-15.6-2.8-22.9-5.6c-24-9.5-40.4-26.9-47.1-51.9c-8.6-31.8-0.2-59.3,24.4-81.2
		c17.9-15.9,39.3-21.9,63-18.2c28.5,4.4,49.2,20.1,60.5,46.4c12.8,29.7,8.3,57.9-12.4,82.8c-13.5,16.2-31.3,25.1-52.3,27.3
		c-0.6,0.1-1.1,0.3-1.7,0.4C222.6,299.8,218.7,299.8,214.8,299.8z M229.4,212c0-1.4,0-2.4,0-3.5c0-7.6,0.1-15.2,0-22.8
		c-0.1-5-3.9-8.8-8.7-8.8c-4.8,0-8.7,3.7-8.8,8.7c-0.1,11.7-0.1,23.4,0,35.1c0,5.1,3.7,8.8,8.7,8.8c11.8,0.1,23.6,0.1,35.4,0
		c3.7,0,6.5-2,7.9-5.5c2.4-5.8-1.8-11.9-8.3-12C247,211.9,238.5,212,229.4,212z"
        />
        <path
          d="M228.8,18.4c14.5,0,29.1-0.1,43.6,0c5.1,0,9,3.3,9,8.1c0.2,14.7,0.1,29.4,0.1,44.5C187.6,71,93.8,71,0,71
		c0-15.4,0-30.8,0-46.2c2.2-5,6.1-6.6,11.5-6.5c13.9,0.2,27.9,0.1,41.8,0.1l-0.1-0.1c0,8.6,0,17.1,0,25.7c0,4,1.5,7,5.2,8.7
		c6,2.7,12.2-1.6,12.3-8.4c0.1-8.7,0.1-17.3,0.1-26l-0.1,0.1c11.7,0,23.5,0,35.2,0l-0.1-0.1c0,8.6,0,17.1,0,25.7c0,4,1.5,7,5.2,8.7
		c6,2.7,12.2-1.6,12.3-8.4c0.1-8.7,0.1-17.3,0.1-26l-0.1,0.1c11.7,0,23.5,0,35.2,0l-0.1-0.1c0,8.6,0,17.1,0,25.7c0,4,1.5,7,5.2,8.7
		c6,2.7,12.2-1.6,12.3-8.4c0.1-8.7,0.1-17.3,0.1-26l-0.1,0.1c11.7,0,23.5,0,35.2,0l-0.1-0.1c0,8.6,0,17.1,0,25.7c0,4,1.5,7,5.2,8.7
		c6,2.7,12.2-1.6,12.3-8.4C228.9,35.6,228.9,26.9,228.8,18.4L228.8,18.4z"
        />
        <path
          className="st0"
          d="M70.6,123.6c-2.8,0-5.7,0.1-8.5,0c-5.1-0.2-8.8-3.9-8.9-8.7c-0.1-4.9,3.8-8.8,9-8.9c5.8-0.1,11.5-0.1,17.3,0
		c5.1,0.1,8.8,3.9,8.9,8.6c0.1,4.9-3.8,8.7-9,8.9C76.5,123.7,73.6,123.6,70.6,123.6z"
        />
        <path
          d="M53.3,18.4c0-3.1-0.1-6.2,0-9.3c0.2-5.2,3.9-8.9,8.9-8.8C67,0.3,70.6,3.9,70.8,9c0.1,3.1,0,6.2,0,9.3c0,0,0.1-0.1,0.1-0.1
		C65,18.3,59.1,18.3,53.3,18.4L53.3,18.4z"
        />
        <path
          d="M106,18.4c0-3.1-0.1-6.2,0-9.3c0.2-5.2,3.9-8.9,8.9-8.8c4.9,0.1,8.5,3.7,8.6,8.8c0.1,3.1,0,6.2,0,9.3c0,0,0.1-0.1,0.1-0.1
		C117.7,18.3,111.8,18.3,106,18.4L106,18.4z"
        />
        <path
          d="M158.7,18.4c0-3.1-0.1-6.2,0-9.3c0.2-5.2,3.9-8.9,8.9-8.8c4.9,0.1,8.5,3.7,8.6,8.8c0.1,3.1,0,6.2,0,9.3
		c0,0,0.1-0.1,0.1-0.1C170.3,18.3,164.5,18.3,158.7,18.4L158.7,18.4z"
        />
        <path
          d="M211.3,18.4c0-3.1-0.1-6.2,0-9.3c0.2-5.2,3.9-8.9,8.9-8.8c4.9,0.1,8.5,3.7,8.6,8.8c0.1,3.1,0,6.2,0,9.3
		c0,0,0.1-0.1,0.1-0.1C223,18.3,217.1,18.3,211.3,18.4L211.3,18.4z"
        />
      </g>
    </svg>
  );
};
export const HolidayIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M163.5-0.1c-14.6,3.2-23.8,12.8-31,24.5c-9.8,15.8-15.2,33.1-19,50.9c-3.1,14.6-4.6,29.5-5,44.8c-1.2,0-2.3,0-3.4,0
		c-14.3,0-28.6,0-43,0c-7.2,0-10.9-3.6-10.7-10.5c1.2-53,39.7-97.2,93.7-107.7c4.5-0.9,9-1.4,13.5-2C160.2-0.1,161.9-0.1,163.5-0.1z
		"
        />
        <path
          d="M176.3-0.1c5.6,0.9,11.2,1.5,16.7,2.8c41.9,10.1,70,34.9,84.5,74c4,10.9,5.7,22.3,5.8,33.9c0,5.4-3.6,9.4-9,9.5
		c-15.5,0.1-31.1,0-46.6,0c-0.4,0-0.8-0.1-1.3-0.2c-0.4-6.5-0.7-12.9-1.3-19.3c-2.8-26.6-8.5-52.3-22.6-75.8
		c-3.9-6.5-8.7-12.4-14.8-17.3c-4.8-3.8-10.4-6-16.2-7.6C173-0.1,174.7-0.1,176.3-0.1z"
        />
        <path
          d="M281.1,270.6c0,7.1,0.1,13.9,0,20.6c-0.1,4.5-3.5,8.2-7.9,8.8c-4.3,0.6-8.5-1.7-9.9-5.7c-0.5-1.5-0.6-3.3-0.6-4.9
		c-0.1-6.1,0-12.3,0-18.8c-1.4,0-2.5,0-3.5,0c-56.6,0-113.2,0-169.8,0c-2.4,0-3.5,0.5-4.1,2.9c-1.6,6.5-3.5,12.8-5.3,19.2
		c-1.6,5.5-6.3,8.4-11.4,7.1c-5.1-1.3-7.8-6.1-6.3-11.5c1.9-7.2,3.8-14.3,5.9-21.5c0.5-1.9,0.1-3-1.3-4.3
		c-20.9-20-41.7-40-62.6-60.1c-5.9-5.7-5.8-10.5,0.5-15.9c19.2-16.5,48.8-15.9,67.2,1.4c8.7,8.1,17.1,16.4,25.7,24.6
		c0.9,0.9,2.4,1.6,3.6,1.6c18.2,0.1,36.4,0.1,54.6,0.1c0.6,0,1.2-0.1,1.9-0.2c0-31.2,0-62.4,0-93.8c-10.2,0-20.4,0-31,0
		c0-1.9-0.1-3.5,0-5.1c1.1-25.3,5.5-50,16.5-73.3c3.2-6.7,7-13,12.3-18.4c8.2-8.1,15.3-8,23.5,0.2c7,7.1,11.5,15.8,15.2,24.8
		c6.8,16.7,10.7,34.1,12.4,52c0.6,6.4,1,12.8,1.5,19.5c-10.7,0-21,0-31.4,0c0,31.5,0,62.7,0,94.2c1.4,0,2.7,0,4,0
		c23,0,45.9-0.1,68.9,0c24.7,0.1,44.2,15.3,49.3,38.2c0.6,2.8,0.9,5.8,0.9,8.7c0.1,5.6-3.8,9.3-9.7,9.5
		C287.4,270.7,284.5,270.6,281.1,270.6z"
        />
      </g>
    </svg>
  );
};
export const WeekOffIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M42.8,0c1.8,0,3.7,0,5.5,0c4.5,2.4,7.4,5.7,6.1,11.1c11.7,0,23.1,0,34.6,0C87.6,5.5,90.6,2.4,95.2,0c1.6,0,3.3,0,4.9,0
     c5,2.1,7.6,5.6,6.6,11.1c11.7,0,23,0,34.5,0c-1.1-5.6,1.7-8.9,6.4-11.1c1.6,0,3.3,0,4.9,0c4.9,2.2,7.5,5.7,6.5,11
     c11.6,0,22.9,0,34.4,0c-1.1-5.7,1.7-9,6.5-11c1.6,0,3.3,0,4.9,0c4.7,2.3,7.6,5.7,6.3,11.2c11.6,0,22.9,0,34.5,0
     c-1.2-5.4,1.6-8.8,6.1-11.2c1.8,0,3.7,0,5.5,0c4.5,2.4,7.5,5.6,6.1,11.3c3,0,5.9-0.3,8.7,0.1c4.4,0.6,9,0.9,12.9,2.6
     c10.8,4.8,15.2,13.6,15.2,24.9c-0.1,68.2-0.1,136.3,0,204.5c0,2.3-0.8,3.8-2.4,5.3c-17.1,16.3-34.1,32.7-51,49.1
     c-1.6,1.6-3.2,2.2-5.5,2.2c-70.9-0.1-141.9-0.1-212.8-0.1c-1.1,0-2.2,0-3.3-0.1c-7.4-0.5-13.6-3.4-18.5-8.8
     C1.5,285.7,0,279.3,0,272.4c0.1-77.7,0-155.4,0-233.2c0-1.5,0.1-2.9,0.2-4.4c0.7-12,10.9-22.2,23.3-23.1c4.3-0.3,8.7-0.4,13-0.6
     c0-1.3-0.1-2.6,0-3.8C37,3.6,39.8,1.7,42.8,0z M14.5,82.7c-0.1,0.8-0.2,1.5-0.2,2.1c0,62.9,0,125.7,0,188.6
     c0,8.2,4.7,12.7,13.3,12.7c69.4,0,138.8,0,208.3,0c3.7,0,5.4-1.7,5.4-5.4c0-6.6-0.1-13.3,0.1-19.9c0.3-9,7.5-16.4,16.8-17
     c7.2-0.5,14.4-0.3,21.6-0.4c4.3-0.1,5.9-1.5,5.9-5.5c0-50.7,0-101.3,0-152c0-1,0-2.1,0-3.1C195,82.7,104.8,82.7,14.5,82.7z"
        />
        <path
          d="M47.1,195.7c7.7,0,15.1,0,22.5,0.1c0.6,0,1.5,0.9,1.8,1.5c2.4,6.7,14,7.3,19,3.8c2.5-1.7,2.4-4.7-0.2-6.4
     c-1.8-1.2-3.9-2-6-2.6c-6.9-2-13.9-3.6-20.6-5.9c-6.1-2.1-11.1-5.8-13.3-12c-4.2-11.7,1-23,13.1-27.5c12-4.4,24.1-4.6,36.1,0.3
     c7.9,3.3,14.3,12.2,13.9,19.6c-7.3,0-14.5,0-21.8-0.1c-0.6,0-1.3-1-1.7-1.7c-2.3-4.4-12-6.3-15.8-3c-1.9,1.6-1.8,4.2,0.6,5.7
     c2,1.3,4.3,2.1,6.6,2.8c6.8,2,13.7,3.5,20.3,5.9c10.6,3.9,15.3,11.1,14.4,21.2c-0.9,9.4-6.4,15.8-15.3,19
     c-12.4,4.5-25.2,5.1-37.6-0.2C53.9,212.5,48.2,206,47.1,195.7z"
        />
        <path
          d="M115.2,218.1c6.2-16.6,12.1-32.6,18.1-48.6c2.7-7.3,5.5-14.6,8.2-22c0.5-1.5,1.2-2.1,3-2.1c6.9,0.1,13.8,0,20.7,0.1
     c1,0,2.5,0.9,2.9,1.7c8.7,23.1,17.3,46.3,25.9,69.5c0.1,0.3,0.2,0.7,0.3,1.4c-7.9,0-15.6,0-23.2-0.1c-0.6,0-1.4-0.9-1.7-1.6
     c-0.9-2.5-1.6-5-2.5-7.7c-8.1,0-16.1,0-24.6,0c-0.7,2.3-1.3,4.9-2.2,7.3c-0.3,0.8-1.3,2-2,2C130.6,218.2,123.1,218.1,115.2,218.1z
      M147.1,193.2c5.3,0,10.1,0,15.4,0c-2.6-8.4-5.1-16.6-7.6-24.8c-0.2,0-0.4,0.1-0.5,0.1C151.9,176.7,149.5,184.8,147.1,193.2z"
        />
        <path
          d="M206.1,164.6c-7.6,0-14.7,0-22,0c0-6.3,0-12.5,0-18.9c22.9,0,45.7,0,68.8,0c0,6.2,0,12.4,0,18.9c-7.2,0-14.4,0-22,0
     c0,18,0,35.6,0,53.4c-8.4,0-16.4,0-24.8,0C206.1,200.2,206.1,182.6,206.1,164.6z"
        />
      </g>
    </svg>
  );
};
export const SundayOffIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M147.6,0c1.6,0,3.3,0,4.9,0c4.7,2.3,7.7,5.6,6.3,11.3c11.6,0,23,0,34.7,0c-1.3-5.7,1.6-9,6.5-11.3c1.4,0,2.8,0,4.3,0
     c5.3,2,8,5.6,6.9,11.2c11.7,0,23,0,34.6,0c-1.1-5.7,1.6-9.1,6.6-11.2c1.4,0,2.8,0,4.3,0c5.2,2.1,7.9,5.6,6.7,11.5
     c3,0,5.7-0.1,8.5,0c2.7,0.1,5.5,0.1,8.1,0.8c12.7,3.2,20.1,12.6,20.1,25.6c0,68.7,0,137.5-0.1,206.2c0,1.3-0.6,2.9-1.6,3.8
     c-17.5,16.9-35,33.8-52.6,50.5c-0.9,0.9-2.6,1.5-3.9,1.5c-71.5,0.1-143,0.1-214.4-0.1c-4.2,0-8.8-0.9-12.6-2.6
     C4.4,292.4,0,283.8,0,272.8c0.1-78,0-156,0.1-234c0-1.6,0-3.1,0.2-4.7C1.4,22.2,11.6,12.5,24,11.7c4.2-0.3,8.4,0,12.9,0
     C35.5,5.4,38.4,2.1,43.4,0c1.4,0,2.8,0,4.3,0c5.2,2.1,7.7,5.7,6.7,11.3c11.7,0,23.1,0,34.5,0c-0.6-6.1,1-8.5,6.9-11.3
     c1.4,0,2.8,0,4.3,0c4.9,2.2,7.7,5.6,6.5,11.3c11.7,0,23.1,0,34.7,0C139.9,5.6,142.9,2.3,147.6,0z M14.2,82.6c0,1,0,1.7,0,2.3
     c0,62.9,0,125.8,0,188.7c0,8,4.9,12.7,13.4,12.7c69.5,0,138.9,0,208.4,0c3.5,0,5.3-1.9,5.3-5.2c0-6.7-0.1-13.5,0.1-20.2
     c0.4-9.3,7.9-16.6,17.5-17.1c7-0.3,14-0.2,21-0.2c4.1,0,5.8-1.7,5.8-5.7c0-50.7,0-101.4,0-152c0-1,0-2.1,0-3.2
     C195.2,82.6,104.9,82.6,14.2,82.6z"
        />
        <path
          d="M190,145.6c7.4,0,14.5-0.1,21.6,0.1c1,0,2.2,1.2,2.9,2.1c7.7,11.1,15.2,22.3,22.9,33.5c0.6,0.9,1.2,1.7,2.1,2.8
     c0-13,0-25.6,0-38.4c7.6,0,15.3,0,23.2,0c0,24.1,0,48,0,72.2c-1,0.1-1.9,0.1-2.9,0.1c-5.8,0-11.6-0.1-17.4,0.1
     c-2.1,0.1-3.3-0.6-4.4-2.3c-7.6-11.3-15.3-22.5-23-33.8c-0.5-0.8-1.1-1.5-1.8-2.5c0,12.9,0,25.5,0,38.3c-7.6,0-15.2,0-23.1,0
     C190,193.9,190,169.9,190,145.6z"
        />
        <path
          d="M155.8,145.7c8.5,0,16.5,0,24.7,0c0,1.2,0,2.3,0,3.3c-0.1,14.6,0.2,29.3-0.6,43.9c-0.9,16.2-10.9,25.6-27.8,26.6
     c-6.9,0.4-14,0.1-20.9-0.9c-13.4-2.1-21.3-10.3-22.7-23.5c-0.7-7.1-0.6-14.2-0.7-21.3c-0.1-9.1,0-18.2,0-27.2
     c0-0.2,0.1-0.4,0.2-0.8c8,0,16,0,24.4,0c0,1.3,0,2.5,0,3.6c0.1,14.3,0.1,28.7,0.3,43c0.1,6.3,3.7,9.5,10.1,9.8
     c7.3,0.3,11.4-2.5,12.4-8.9c0.3-2.1,0.3-4.3,0.3-6.4c0-12.6,0-25.2,0-37.8C155.8,148.1,155.8,147,155.8,145.7z"
        />
        <path
          d="M100,166.6c-7.4,0-14.6,0-21.8-0.1c-0.6,0-1.5-0.8-1.9-1.4c-2.6-4.5-11.7-6.4-15.8-3.4c-2,1.5-2.1,4.2,0.1,5.7
     c1.9,1.3,4.2,2.3,6.5,3c6.9,2.1,13.9,3.6,20.6,6c6,2,10.9,5.6,13.2,11.7c4.3,11.4-1.5,23.8-13.9,28.4c-12.6,4.7-25.6,5.2-38.2-0.3
     c-8.8-3.9-14.4-10.5-15.3-20.3c7.9,0,15.6,0,23.3,0c3.2,8.3,12.2,8.5,18.3,6.1c1.4-0.5,3-2.2,3.1-3.5c0.1-1.4-1.1-3.6-2.4-4.2
     c-3.7-1.6-7.8-2.6-11.7-3.9c-5.9-1.9-12.2-3.2-17.7-5.9c-14.4-7.1-15.2-26.4-1.9-35.1c11.1-7.3,34.2-7.7,44.9-0.2
     C95.2,153.2,99.1,159.1,100,166.6z"
        />
      </g>
    </svg>
  );
};
export const NotificationIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M144.7,299.6c-5.7-1.9-11.7-3.1-16.9-5.9c-12.2-6.3-19.7-16.7-23.3-29.9c-0.1-0.4-0.1-0.8-0.2-1.4c30.4,0,60.7,0,91.2,0
		c-1,5.6-3.2,10.8-6.3,15.5c-7.8,11.9-18.7,19-32.8,21.2c-0.5,0.1-0.9,0.3-1.3,0.5C151.7,299.6,148.2,299.6,144.7,299.6z"
        />
        <path
          d="M150,249.8c-38.3,0-76.5,0-114.8,0c-13.2,0-23.1-6.5-25.7-17.1c-2.1-8.8,1.1-16.1,8.8-21.9c8-6.1,14.6-13.2,20-21.2
		c8.8-13.3,12.9-27.8,13-43.2c0-12.1-0.2-24.2,0.1-36.2c0.9-40.8,35.8-76.4,81.4-83.2c2.5-0.4,3.3-1.1,3.2-3.3
		c-0.2-3.7-0.1-7.4,0-11.1c0.3-6.8,6.3-11.9,13.8-12c7.7-0.1,13.9,5.1,14.2,12c0.2,3.9,0.1,7.8,0,11.7c0,1.7,0.6,2.3,2.5,2.6
		c42.6,6.7,74.5,35.4,81.1,73.1c0.8,4.8,0.9,9.7,1,14.6c0.2,13.1-0.3,26.3,0.6,39.4c1.6,22.3,12.4,40.9,31.2,55.8
		c5.5,4.4,9.8,9.2,10.4,16c1.2,13.6-9.7,24-25.5,24.1c-14.4,0.1-28.9,0-43.3,0C198,249.8,174,249.8,150,249.8z"
        />
        <path
          d="M0.1,117.2c1.9-40,16.7-74.3,44.6-103c3.7-3.8,8.1-5.4,13.3-3.8c4.9,1.6,7.8,5.1,8.6,10.1c0.7,4.4-0.9,8-4,11.1
		C54.2,40.1,47.1,49.5,41.2,60c-10.5,18.8-15.8,39-16.5,60.5c-0.1,2.2-0.1,4.5-0.6,6.6c-1.5,5.7-6.7,9.3-12.8,9
		c-5.6-0.3-10.4-4.4-11.2-10.1C-0.2,123,0.1,120.1,0.1,117.2z"
        />
        <path
          d="M299.9,117.2c0,2.7,0.3,5.5-0.1,8.2c-0.9,6.7-6.4,11-13.1,10.6c-6.3-0.4-11.4-5.6-11.5-12.2C275,111.3,273.5,99,270,87
		c-5.8-19.8-15.7-37.2-29.7-52.3c-1.3-1.4-2.7-2.8-4-4.3c-4.4-5.2-4.1-12.5,0.7-17.1s12.4-4.9,17.1,0c5.6,5.8,11,11.9,15.8,18.3
		C288.7,57,298.5,85.7,299.9,117.2z"
        />
      </g>
    </svg>
  );
};
export const VehicleIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M300,94.5c-0.5,2.5-1.1,5-1.7,7.5c-1.1,4.9-3,6.7-7,6.7c-7.8,0-15.6,0-23.4,0c-0.8,0-1.6,0.2-3,0.3c0.8,0.9,1.1,1.4,1.5,1.6
   c6.4,3.7,8.8,10.4,8.8,19c-0.1,25.8,0,51.6,0,77.4c0,12.1,0,24.3,0,36.4c0,8.5-2.4,11.6-9,11.6c-9.9,0-19.7,0-29.6,0
   c-6.6,0-9-3.2-9-11.6c0-11.7,0-23.3,0-34.9c0-1.3,0-2.7,0-4.2c-51.8,0-103.4,0-155.2,0c0,4.8,0,9.4,0,14c0,8.6,0,17.3,0,25.9
   c-0.1,7.5-2.6,10.8-8.4,10.8c-10.3,0.1-20.5,0.1-30.8,0c-5.8,0-8.4-3.3-8.4-10.8c0-38.5,0-76.9,0-115.4c0-8.4,2.6-14.7,8.8-18.3
   c0.4-0.2,0.7-0.7,1.5-1.7c-1.5-0.1-2.4-0.2-3.3-0.2c-7.6,0-15.2,0-22.9,0c-4.4,0-6-1.8-7.4-7.1c-2.1-7.8-1.8-15.5,0.3-23.2
   c1-3.8,3-5.8,6.1-5.8c8.8,0,17.6-0.1,26.4,0c3.3,0,5.2,1.9,6.2,6c0.4,1.5,0.8,3.1,1.3,5.4c1-3.5,1.9-6.4,2.7-9.2
   c5.1-18,10.1-36,15.2-54C63.2,8.1,70.4,1.5,81.1,1.5c45.8-0.1,91.6,0,137.4,0c10.8,0,18,6.6,21.8,19.7c5.6,19.6,11.1,39.3,16.7,58.9
   c0.3,1.1,0.7,2.2,1.1,3.5c0.3-0.6,0.5-0.9,0.6-1.2c2.7-9.8,2.7-9.8,10.8-9.8c7.2,0,14.5,0,21.7,0c4.4,0,6.1,1.7,7.3,7.2
   c0.5,2.4,1,4.8,1.6,7.2C300,89.5,300,92,300,94.5z M61.3,109.1c59.2,0,118.1,0,177.2,0c-0.2-0.9-0.2-1.5-0.4-2
   c-6.7-23.6-13.4-47.2-19.9-70.9c-0.7-2.6-1.9-2.6-3.5-2.6c-43.2,0-86.3,0-129.5,0c-2.2,0-3,0.9-3.7,3.4
   c-6.3,22.8-12.8,45.6-19.2,68.4C61.9,106.5,61.6,107.6,61.3,109.1z M72.2,134.3c-4.6,0-9.2-0.1-13.8,0c-6.6,0.1-11.5,5.8-11.7,13.7
   c-0.2,8.1,4.4,14.5,11.2,14.7c9.4,0.3,18.7,0.3,28.1,0c6.7-0.2,11.3-6.4,11.2-14.6c-0.1-7.9-4.9-13.6-11.6-13.7
   C81.2,134.3,76.7,134.3,72.2,134.3z M227.8,162.7c4.6,0,9.2,0.1,13.8,0c6.9-0.2,11.7-6.4,11.6-14.6c-0.1-8-5-13.7-11.8-13.8
   c-8.9-0.1-17.8,0-26.6,0c-7,0-11.9,5.8-12,14c-0.1,8.4,4.8,14.3,12,14.4C219,162.8,223.4,162.8,227.8,162.7z"
      />
      <rect x="0" y="268.4" width="300" height="31.6" />
    </svg>
  );
};
export const EmailTemplatesIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M300,76.6c0,63.4,0,126.9,0,190.3c-0.1,0.2-0.3,0.4-0.3,0.7c-4,22-13.6,32.4-30,32.4c-79.8,0-159.6,0-239.3,0
   c-1,0-2,0-2.9,0C11.7,299.2,0,282.8,0,261.1C0,200.6,0,140.2,0,79.7c0-1,0.1-1.9,0.2-3.1c0.5,0.4,0.8,0.6,1,0.8
   c40.5,37.3,81,74.7,121.5,112c18.2,16.8,36.2,16.8,54.4,0c36.2-33.3,72.4-66.7,108.6-100.1C290.5,85,295.3,80.9,300,76.6z"
      />
      <path
        d="M300,40.8c-1.1,3.9-1.7,8.2-3.5,11.5c-2.4,4.5-5.2,9.1-8.7,12.1c-40.7,35.5-81.6,70.8-122.5,105.9
   c-10.1,8.7-20.7,8.6-30.8,0c-41-35.2-81.9-70.6-122.8-106C2,55.8-2.1,41,1,26.4c3.2-15,13.1-25.3,25.5-26.3C27.4,0,28.3,0,29.1,0
   c80.6,0,161.1,0,241.7,0c13.3,0,23.8,9.2,27.7,24.5c0.5,2.1,1,4.3,1.5,6.5C300,34.3,300,37.6,300,40.8z"
      />
    </svg>
  );
};
export const FinalReportIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M153.9,281.5c0,5.9,0,11,0,16.8c-3,0.2-5.7,0.5-8.5,0.5c-33.2,0-66.4,0.1-99.6,0c-26.4,0-41.3-14.9-41.3-41.4
		c0-58,0.1-116-0.1-174.1c0-8.1,2.6-14.3,8.4-19.9C32.4,45.1,51.3,26.1,71,7.8c3.8-3.5,10.1-6.2,15.2-6.3
		c39.3-0.5,78.5-0.5,117.8-0.2c20.3,0.1,36.4,14,36.9,33.9c1,37.2,0.3,74.4,0.3,112.1c-5.7,0-11,0-17.1,0c0-3.6,0-6.7,0-9.8
		c0-31.8,0-63.5,0-95.3c0-16.4-7.4-23.7-23.9-23.7c-34.1,0-68.1,0-102.2,0c-2.5,0-5.1,0.3-8.6,0.5c0,9,0.1,17.5,0,26.1
		c-0.2,22.3-14.8,37.5-37.2,38.4c-9.7,0.4-19.5,0.1-30.4,0.1c0,4,0,7,0,10.1c0,54.3,0,108.5,0,162.8c0,18.1,6.9,24.9,25.2,24.9
		c32.3,0,64.7,0,97,0C147.2,281.5,150.3,281.5,153.9,281.5z"
        />
        <path
          d="M239.9,297.6c-1-10.3-8.7-20.3-1.9-31.9c0.9-1.5-0.8-6.2-2.4-7c-2.4-1.1-6.4-1-8.7,0.3c-1.6,0.9-2.9,5.7-1.9,7.2
		c6.5,9.8,0.3,19-1.1,28.3c-0.2,1.6-3.4,4-5.2,4c-16.4,0.3-32.8,0.2-48.9,0.2c-4.6-17.9-2.4-24.7,11.6-34.5
		c7.8-5.4,16.2-9.9,24-15.3c2.3-1.6,4.7-5.1,4.7-7.7c0-8.9-0.9-17.5-3.3-26.6c-3.2-12.2-1.8-26.4,0.9-39.1
		c2.5-11.6,16.1-16.2,31.1-13.5c10.4,1.8,18.3,10.5,18.7,21.8c0.3,10.3,2.1,21.5-1.2,30.8c-2.9,8.2-3.3,15.9-3.9,24.1
		c-0.4,5.6,1.4,8.9,6.6,11.5c6.7,3.3,12.6,8.1,19.2,11.6c14.3,7.6,20.5,18.8,15.6,35.8C276.7,297.6,258.8,297.6,239.9,297.6z"
        />
        <path
          d="M122.9,106.8c22.5,0,45,0.1,67.5,0c6.5,0,12,1.2,11.7,9.1c-0.2,7.3-5.6,8.3-11.7,8.3c-45.5-0.1-91.1-0.1-136.6,0
		c-6.1,0-11.3-1.4-11.3-8.7c0-7.3,5.1-8.7,11.3-8.7C76.8,106.8,99.8,106.8,122.9,106.8z"
        />
        <path
          d="M122.8,141.4c22.5,0,45,0.1,67.5,0c6.4,0,12,1.1,11.8,9c-0.2,7.3-5.6,8.4-11.6,8.4c-45.5-0.1-91.1-0.1-136.6,0
		c-6.1,0-11.3-1.3-11.4-8.6c-0.1-7.2,5-8.8,11.2-8.8C76.7,141.4,99.8,141.4,122.8,141.4z"
        />
        <path
          d="M190.3,210.7c1.9,5.8,3.6,11.2,5.6,17.3c-3.2,0-6.1,0-8.9,0c-43.6,0-87.1,0-130.7,0c-2.6,0-5.9,0.7-7.6-0.5
		c-2.7-2-5.9-5.3-5.9-8.1c0-2.8,3.6-5.7,5.9-8.2c0.6-0.6,2.2-0.4,3.4-0.4C98.2,210.7,144.3,210.7,190.3,210.7z"
        />
        <path
          d="M191.3,177c0,5,0,10.1,0,15.7c-2.5,0.2-4.7,0.6-6.9,0.6c-43.3,0-86.5,0-129.8,0c-2.3,0-5.2,0.4-6.7-0.8
		c-2.4-2-5.2-5.2-5.2-7.8c0-2.7,3-5.6,5.1-8c0.7-0.8,2.7-0.7,4.1-0.7c45.9,0,91.7,0.1,137.6,0.1C190,176.2,190.5,176.7,191.3,177z"
        />
        <path
          d="M177,248.8c-9.9,11.6-19.9,14.5-32.3,14.1c-30.3-0.8-60.5-0.3-90.8-0.2c-6.4,0-12.2-1.2-12.2-8.7c0-7.6,5.8-8.7,12.2-8.7
		c36.9,0.1,73.8,0,110.7,0.2C168.4,245.5,172.2,247.4,177,248.8z"
        />
        <path
          d="M154.6,89.4c-12.4,0-24.8-0.1-37.2,0c-6,0-10.6-2.1-10.6-8.6c-0.1-6.5,4.4-8.8,10.4-8.8c24.8,0,49.6,0,74.4,0
		c5.9,0,10.5,1.8,10.6,8.6c0.1,6.7-4.3,8.8-10.3,8.8C179.4,89.4,167,89.4,154.6,89.4z"
        />
      </g>
    </svg>
  );
};
export const RegisterIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M300,52.7c0,80.3,0,160.5,0,240.8c-2.3,5.1-6.4,6.5-11.8,6.5c-92.1-0.1-184.3-0.1-276.4,0c-5.4,0-9.5-1.5-11.8-6.5
     C0,213.2,0,133,0,52.7C100,52.7,200,52.7,300,52.7z M75.7,132.4c-5.3,0-9.8-0.1-14.4,0c-12.3,0.3-23.3,9.3-24.9,21.5
     c-1,7.2-0.6,14.6-0.6,21.9c0,5.6,3.7,9.3,9.4,9.3c23,0,46.1,0,69.1,0c5.7,0,9.3-3.7,9.3-9.4c0-5.2,0-10.3,0-15.5
     c0-16.5-11.2-27.7-27.8-27.8c-3.8,0-7.6,0-11.4,0c14.2-4,22.9-15.6,21.5-28.9c-1.4-13.1-12.2-23.4-25.2-23.8
     c-13.3-0.4-24.7,8.7-27,21.6C51.2,115.5,59,126.8,75.7,132.4z M150.2,202.7c-34.5,0-68.9,0-103.4,0c-7,0-11.1,3.2-11.1,8.8
     c0,5.6,4.1,8.8,11.1,8.8c69,0,138,0,207,0c0.9,0,1.8,0,2.6,0c4.5-0.3,8.1-3.9,8.3-8.3c0.2-4.3-2.8-8.2-7.1-9.1
     c-1.1-0.2-2.3-0.2-3.5-0.2C219.5,202.7,184.9,202.7,150.2,202.7z M150,255.4c34.6,0,69.1,0,103.7,0c1,0,2,0,2.9,0
     c4.3-0.3,7.7-3.6,8.2-7.8c0.5-4.1-2.1-8.1-6.4-9.3c-1.4-0.4-2.9-0.4-4.3-0.4c-69.2,0-138.4,0-207.6,0c-0.9,0-1.8,0-2.6,0
     c-4.3,0.4-7.7,3.7-8.1,7.9c-0.4,4.1,2.1,7.9,6.2,9.1c1.4,0.4,2.9,0.5,4.3,0.5C80.9,255.4,115.4,255.4,150,255.4z M202.7,150
     c17.5,0,34.9,0,52.4,0c4,0,7.1-1.4,8.8-5.1c2.9-6-1.4-12.5-8.4-12.5c-16.6-0.1-33.2,0-49.8,0c-18.3,0-36.7,0-55,0
     c-3.9,0-6.9,1.4-8.6,4.9c-3.1,6,1.3,12.6,8.4,12.6C168,150,185.3,150,202.7,150z M203.3,167.5c-17.5,0-34.9,0-52.4,0
     c-4.1,0-7.2,1.6-9,5.3c-2.7,6,1.8,12.2,8.8,12.3c19.4,0,38.8,0,58.2,0c15.4,0,30.8,0,46.2,0c3.8,0,6.8-1.4,8.6-4.9
     c3-6-1.3-12.6-8.4-12.6C238.1,167.5,220.7,167.5,203.3,167.5z M167.7,114.8c4.7,0.2,8.9-3.5,9.2-8.3c0.3-4.7-3.5-8.9-8.2-9.3
     c-4.8-0.3-9.1,3.6-9.3,8.4C159.2,110.4,163,114.6,167.7,114.8z M212.1,105.9c0-4.7-4-8.7-8.8-8.7c-4.7,0-8.7,4-8.8,8.7
     c0,4.9,4.1,8.9,8.9,8.8C208.2,114.7,212.1,110.7,212.1,105.9z M238.5,97.2c-4.7,0-8.8,3.9-8.8,8.6c-0.1,4.7,3.8,8.8,8.6,8.9
     c4.9,0.1,9-3.9,9-8.8C247.3,101.3,243.3,97.3,238.5,97.2z"
        />
        <path d="M194.5,0c0,11.6,0,23.2,0,35.1c-65,0-129.8,0-194.5,0C0,25.6,0,16,0,6.4C1.4,3.5,3.5,1.4,6.4,0C69.1,0,131.8,0,194.5,0z" />
        <path d="M229.7,0c0,11.6,0,23.2,0,34.9c-5.8,0-11.5,0-17.6,0c0-11.6,0-23.3,0-34.9C218,0,223.8,0,229.7,0z" />
        <path d="M264.8,0c0,11.6,0,23.2,0,34.9c-5.8,0-11.5,0-17.6,0c0-11.6,0-23.3,0-34.9C253.1,0,259,0,264.8,0z" />
        <path
          d="M300,35.1c-5.7,0-11.5,0-17.6,0c0-11.8,0-23.5,0-35.1c3.7,0,7.4,0,11.1,0c2.9,1.4,5,3.5,6.4,6.4C300,16,300,25.6,300,35.1z
     "
        />
        <path
          d="M53.3,167.3c0-3.2-0.1-6.2,0-9.2c0.3-4.5,3.9-8.1,8.5-8.1c11.9-0.1,23.8-0.1,35.7,0c4.7,0,8.3,3.7,8.5,8.4
     c0.1,2.9,0,5.8,0,8.9C88.5,167.3,71.1,167.3,53.3,167.3z"
        />
        <path d="M88.5,105.9c0.1,4.7-3.9,8.8-8.6,8.9c-4.9,0.1-9-4-9-8.8c0-4.7,4-8.7,8.7-8.7C84.4,97.2,88.4,101.2,88.5,105.9z" />
      </g>
    </svg>
  );
};
export const CountryIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M123.8,109.4c-2.7-0.3-5.3-0.8-7.8-1c-5.2-0.4-10.2,0.5-14.8,3.1c-2.7,1.5-5.4,3-8.2,4.3c-5,2.4-9.8,1.2-12.9-3.3
		c-3.2-4.7-6.4-9.5-9.2-14.5c-4.6-8.1,1.6-18,11.3-18c5.1,0,10.2,1,15.2,1.6c8.1,0.9,14.6-1.8,19.1-8.6c4-6,9.2-8.2,16.2-6.9
		c9.4,1.8,17.1-1,22.7-9.1c1.1-1.7,2.5-3.2,3.7-4.8c6.5-8.9,6.8-19.9,0.6-29.1c-4.4-6.6-9.1-13.1-13.6-19.7
		c-0.7-0.9-1.3-1.9-2.2-3.4c32.5-1.1,61.3,8.7,88.8,24.8c-3.8,6.5-7.4,12.7-11.1,18.9c-2.1,3.6-3.4,7.3-2.6,11.5
		c1.4,7.1,7.2,12.9,14.9,14c5.6,0.8,10.6,2.3,12.5,8.3c1.9,6.3-1.7,10.3-6.1,14c-5.8,4.9-11.2,10.4-17.2,15c-9,6.9-9.9,27.9,1,33.9
		c3.4,1.9,4.2,5.5,4.5,9.2c1,11.6,6.5,16.6,18,16.6c4.3,0,8.6,0.1,12.8-0.4c8.5-1,15.2-5.4,18.7-13.2c2.9-6.5,7.1-9.8,14.2-9.5
		c2.2,0.1,4.5-0.3,7.2-0.5c0.2,2,0.4,3.9,0.4,5.7c1.1,58.1-34.4,114.1-87.4,138c-80,36-171.7-1.9-203-84c-10-26.3-12.1-53.4-6.4-81
		c0.3-1.3,1.7-2.8,3-3.3c3.9-1.5,7.9-2.7,11.8-3.9c7.9-2.6,15.9-0.1,20.3,6.9c5.3,8.4,13,14.5,20.3,20.9c2.5,2.2,6,3.4,9.2,4.5
		c6.1,2,10,5.7,11.6,11.9c0.2,0.9,0.5,1.8,0.8,2.8c8.4,29.1,3,19.7,21.4,43c7.4,9.4,11.7,19.3,12.8,31c0.5,5.5,1.7,11,2.7,16.5
		c1.7,9,10.8,13,18.6,8.3c4.6-2.8,6.7-7,8-12.1c1.1-4.4,2.4-9.3,5.2-12.6c6.4-7.2,10.4-15.3,12.3-24.5c2-9.3,6.8-16.2,14.8-21.3
		c3.4-2.2,6.5-4.9,9.7-7.3c7.7-6,8.5-13.8,2.2-21.4c-4.4-5.3-8.7-10.7-13.2-15.9c-1.4-1.7-1.3-2.9,0-4.6c4.1-5.2,8.1-10.5,9.6-17.3
		c3.7-16.7-6.2-33.5-22.4-37.8c-16.3-4.3-32.6,5.2-37.6,21.8C124.3,107.7,124.1,108.4,123.8,109.4z"
        />
        <path
          d="M58.9,32.7c7.2-7,14.6-11.7,23.1-17.2c5.5-2.2,11.1-4.2,16.7-6.2c5.6-1.9,11.2-4.2,16.9-5.6c6-1.5,12.3-1.8,18.4-2.6
		c2.6-0.3,2.9,1.6,4.2,3.4c1.8,2.5,3.5,5,5.3,7.5c3.4,4.9,6.9,9.9,10.2,14.8c4.6,6.9,4.3,14.5-0.6,21.3c-0.8,1.2-1.8,2.2-2.6,3.4
		c-4.2,7-10.3,8.7-18.1,7.3c-9-1.7-16.1,1.6-21.2,9.2c-3.3,5-7.8,7.1-13.7,6.2c-3.6-0.6-7.3-0.9-11-1.4c-13.4-1.8-24.7,7.5-24.4,20
		c0.2,6.9,11.9,25.8,18,29c5.3,2.8,10.7,2.8,16.1,0.1c2.9-1.4,5.7-3,8.5-4.5c5.3-2.8,10.8-2.8,16.3-1.1c1,0.3,2.3,1.8,2.4,2.8
		c0.9,6.2,3.3,11.6,7,16.5c6.7,8.8,13.2,17.7,19.9,26.6c2.8,3.8,4.9,3.7,7.8,0c3.4-4.5,6.8-9.1,10.4-13.8c5.2,6.2,10.2,12,15,18
		c2.5,3.2,1.8,6.5-1.7,9.3c-5,4-10.1,7.9-15.3,11.8c-5,3.8-8.4,8.6-10.2,14.7c-0.7,2.4-1.6,4.7-2.2,7.2c-1.9,7.9-5.2,14.9-10.9,20.9
		c-4.1,4.3-5.8,9.9-6.9,15.8c-0.4,2.1-1.3,4.6-2.8,5.9c-1.6,1.4-4.3,2.3-6.3,1.9c-1.4-0.3-3.1-2.9-3.4-4.7
		c-1.8-9.1-3.2-18.3-4.6-27.4c-0.8-4.9-2.5-9.3-5.6-13.3c-5.5-7.1-11.2-14.2-16.4-21.6c-2.5-3.5-4.4-7.6-5.8-11.7
		c-2.1-6.1-3.5-12.3-5.2-18.5c-2.4-8.9-8-14.5-16.8-17.4c-3.4-1.1-6.7-3.1-9.4-5.4c-6.4-5.7-12.5-11.5-17.5-18.7
		c-5.8-8.4-16.7-11.3-26.5-8.2c-3.4,1.1-6.7,2.1-10.1,3.2c-0.3-0.3-0.6-0.5-0.8-0.8c-1.1-1,7.5-19.8,8.3-22
		c4.5-12,14.3-21.6,22.6-31c7-7.9,14.3-15.6,21.8-23C58.4,33.2,58.6,33,58.9,32.7z"
        />
        <path
          d="M239,28.2c-0.1,0.2,17.1,14.3,18.7,15.9c10.6,10.6,18,23.9,25,37c4.9,9.2,9.3,18.7,12.3,28.6c0.7,2.1,6.4,25.5,3.6,25.7
		c-3.3,0.3-6.7,0.7-10.1,0.9c-7.1,0.5-12.3,3.8-15.1,10.2c-3.9,9-10.6,13-20.3,12.6c-2.9-0.1-5.7,0.1-8.6,0c-5.2-0.2-8.4-3-8.6-8.1
		c-0.4-7.3-3-13.1-9.1-17.6c-5.6-4.1-4.8-16,0.9-21.1c6.3-5.7,12.6-11.3,19-16.8c6.2-5.3,8.9-11.8,6.5-19.7
		c-2.3-7.8-8-11.9-16.1-13.2c-2.9-0.5-5.9-1.9-8.3-3.6c-2.8-2-4-5.4-2.4-8.6C230.3,43.1,234.5,36.1,239,28.2z"
        />
        <path
          d="M154.3,155.8c-6.9-9.4-13.8-18-20-27.2c-6.8-10.1-4.5-23.7,4.7-31.7c8.5-7.4,21.2-7.7,29.8-0.7c9.5,7.7,12.1,21.1,6.1,31.5
		c-0.8,1.3-1.7,2.6-2.6,3.8C166.5,139.5,160.6,147.4,154.3,155.8z M153.5,97.1c-10,0-17.8,7.8-17.9,17.9c0,9.7,7.9,17.8,17.6,17.9
		c9.8,0.1,18-7.9,18.1-17.8C171.4,105.2,163.4,97.1,153.5,97.1z"
        />
        <path
          d="M153.4,104.2c5.9,0,10.7,4.7,10.8,10.5c0.1,5.9-5,11-10.9,10.9c-5.8-0.1-10.6-4.9-10.5-10.8
		C142.8,108.9,147.5,104.2,153.4,104.2z"
        />
      </g>
    </svg>
  );
};
export const ZoneIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M151.5,0c28.4,0.8,55.1,9.8,76.2,31.9c14.1,14.8,21.5,32.9,24.8,53c4.9,30.3-3.2,57.8-17.9,83.9
		c-20,35.5-46.7,65.7-76.5,93.2c-4.8,4.5-11,4.7-15.7,0.3c-33.6-31.1-63.7-65-83.7-106.8c-8.5-17.9-13.4-36.7-12.4-56.7
		C47.8,67,58.9,40,85.1,20.6C104.3,6.5,126.2,0.4,151.5,0z M149.8,161.7c31.7,0.1,57.8-25.7,57.9-57.2c0.1-31.8-25.8-57.9-57.5-58.1
		c-31.5-0.2-57.7,25.9-57.8,57.3C92.2,135.7,117.9,161.6,149.8,161.7z"
        />
        <path
          d="M30.9,276.8c79.3,0,158.8,0,238.5,0c-0.6-1.3-1-2.4-1.5-3.4c-6.5-13.1-13.2-26.1-19.6-39.3c-1.3-2.6-2.7-3.4-5.5-3.3
		c-4.2,0.2-8.4,0.1-12.6,0c-6.4-0.2-11.1-5-11.2-11.3c-0.1-6.3,4.5-11.5,10.8-11.7c8.2-0.3,16.3-0.2,24.5,0c4.6,0.1,7.9,2.6,10,6.6
		c11.4,22.7,22.8,45.4,34.1,68.2c4.4,8.9-1.1,17.2-11.1,17.2c-65.8,0-131.6,0-197.4,0c-25.5,0-50.9,0-76.4,0
		C2.2,300-3,291.8,2,281.8c11-22.1,22.1-44.2,33.1-66.3c2.7-5.3,6.6-7.9,12.6-7.8c7,0.1,13.9,0,20.9,0.1c7.3,0.1,12.4,4.9,12.3,11.7
		c-0.1,6.6-5,11.3-12.2,11.4c-4.3,0.1-8.7-0.1-13,0.1c-1.1,0-2.7,0.7-3.1,1.6C45.2,247,38,261.7,30.7,276.3
		C30.7,276.3,30.8,276.5,30.9,276.8z"
        />
        <path
          d="M149.5,138.6c-19.3-0.2-34.4-15.7-34.1-34.9c0.2-19,15.7-34.3,34.6-34.2c19.3,0.1,34.7,15.7,34.5,35
		C184.4,123.6,168.9,138.8,149.5,138.6z"
        />
      </g>
    </svg>
  );
};
export const RegionIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M259.11,105.06c-0.25,23.89-10.01,47.84-30.86,67.5c-23.25,21.92-46.85,43.51-69.87,65.63
		c-6.44,6.19-10.68,6.04-17.04-0.08c-23.5-22.59-48.19-44.13-71.12-67.21c-30.12-30.31-37.62-66.34-22.32-104.8
		C63.21,27.63,94.05,4.76,138.16,0.58C204.47-5.71,258.32,39.81,259.11,105.06z M151,55.16c-25.97-0.62-47.59,18.69-47.98,42.86
		c-0.39,24.39,19.79,44.13,46.01,44.99c24.97,0.82,47.83-19.62,48.27-43.16C197.76,75.7,177.14,55.79,151,55.16z"
        />
        <path
          d="M148.75,300c-45.59-2.19-81.28-4.74-114.89-17.11c-8.54-3.14-17.05-7.21-24.14-12.4c-12.69-9.29-13.3-21.5,0.21-29.56
		c13.57-8.1,29.06-14.46,44.68-18.86c27.83-7.82,28.15-6.98,48.92,11.4c12.32,10.9,24.56,21.87,36.88,32.77
		c8.8,7.79,10.32,7.8,19.38-0.21c16.76-14.82,33.22-29.91,50.35-44.39c3.09-2.61,9.45-4.75,13.18-3.77
		c20.15,5.33,41.04,9.83,59.6,18.22c22.91,10.35,22.4,26.55,0.75,39.04c-21.79,12.57-46.78,17.29-71.92,20.21
		C187.68,298.15,163.33,298.98,148.75,300z"
        />
      </g>
    </svg>
  );
};
export const StateIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M100.4,46.9c1.7,0.7,2.9-0.4,4.1-1.2c0.2-0.2,0.4-0.7,0.3-1c-0.5-2.5,1.1-4.3,2.1-6.3c0.1-0.2,0.6-0.2,0.9-0.2
		c2.2,0,4.4,0,6.7,0c0.1,0.2,0.2,0.4,0.2,0.6c0,3.1,0.8,5.8,3.1,8c0.9,0.8,0.9,2.4,2.5,2.6c0.4,0.1,0.8,0.1,1.4,0.1
		c-0.7,1.9,1.6,1.8,2.4,2.8c0.5,0.6,1.6,1,2.5,1.2c2.1,0.4,3.4,1.6,4.7,3.2c0.4,0.5,1.6,0.5,2.6,0.8c-0.3,1-0.5,2.3-1.1,3.5
		c-4.1,9.1-8.3,18.2-12.6,27.3c-0.4,0.8-0.9,1.5-1.4,2.1c-1.2,1.5-1.3,2.3,0,3.7c1.8,2,1.8,3.5,0.4,5.7c-0.3,0.4-0.3,1-0.3,1.5
		c-0.2,1.9-0.4,3.8-0.7,5.7c0,0.2-0.7,0.5-1.1,0.5c-7.2,0-14.3,0-21.5-0.1c-0.4,0-0.8-0.4-1.3-0.4c-0.7,0-1.8-0.1-2,0.3
		c-0.6,1-1.2,0.4-1.5,0.1c-0.7-0.8-1.3-1.7-1.9-2.6c-0.2-0.3-0.6-0.5-0.8-0.8c-0.5-0.5-0.9-1.1-1.5-1.5c-0.8-0.6-1.7-1.3-2.6-1.4
		c-1.7-0.2-3.5-0.1-5.1-0.1c-1.8,2.4-3.8,4.5-4.6,7.5c-1.5,0.1-3.1,0.2-4.7,0.4c-0.4,0.1-0.9,0.7-1.2,1.2c-0.3,0.6-0.5,1.2-0.9,2
		c-1.2-1.4-3-1-4.8,0.5c-2.1-0.2-3.6-0.3-5.1-0.4c-0.2,0-0.5,0.2-0.6,0.4c-1.3,2.2-2.5,2.6-4.9,1.6c-8.4-3.4-16.6-7.4-24.6-11.6
		c-2.3-1.2-5.2-1.4-7.9-2C13.9,99.3,8,98.2,2.8,95.3c-0.5-0.3-1.3-0.8-1.2-1.1c0.1-0.8,0.5-1.6,1.1-2.1c0.5-0.5,1.3-0.7,2-1
		c1.5-0.4,2.1-1.3,1.3-2.7c-0.8-1.4-0.2-2.4,0.6-3.6c0.8-1.2,1.1-2.7,1.6-4c0.1-0.4,0.2-0.8,0.5-1c1.8-1.2,3.8-2.3,5.6-3.6
		c2.3-1.7,5.2-1,7.7-2.1c0.6-0.2,1.2-0.4,1.6-0.8c1.1-0.9,2.5-0.8,3.6-0.1c0.7,0.4,1.8,0.6,2.6,0.4c0.4-0.1,0.9-1,1-1.5
		c1.2-6.4,2.3-12.8,3.5-19.3c0.2-1,0.3-2,0.6-3c0.5-2.3-1.4-3.9-1.6-6c-0.1-0.6-1.1-1.3-1.8-1.7c-1.4-0.6-1.6-1.6-1.6-2.9
		c0-1.1,0-2.2,0-3.2c1-0.3,1.8-0.7,2.7-0.9c0.7-0.1,1.5,0,2.2,0.2c1.4,0.4,2.1-0.1,2-1.5c-0.2-2.7-0.4-2.9-3.2-3
		c-0.7,0-1.3-0.2-2.1-0.4c0-0.8,0.1-1.6,0-2.3c-0.2-1.6,1-1.7,2-1.7c3.6-0.1,7.2,0,10.9,0c0-0.3-0.1-0.7-0.1-1
		c2.6,0.8,3.7-1.6,5.2-2.2c0.8,1,1.6,1.8,2.1,2.8c0.4,0.7,0.3,1.6,0.4,2.5c0,0.8-0.1,1.7-0.1,2.7c1.7-1,2.8-0.3,3.8,0.8
		c0.4,0.5,1,0.8,1.4,1.3c1.1,1.3,3.3,1,4.6,0c0.5-0.4,1.3-0.6,2.1-1c-0.1,1.2-0.1,1.9-0.2,3.1c3.1-2.4,5.9-4.4,8.6-6.5
		c0.5,0.3,0.9,0.5,1.3,0.6c1-1.5,3.2-2.2,2.6-4.2c1.3-0.6,2.3-1.2,3.4-1.5c1.2-0.3,2.2-0.5,3.6,0.2c1.6,0.9,3.2,1.1,1.8,3.3
		c-0.1,0.1,0.4,0.6,0.6,0.9c0.9,1.9,2.3,3.5,1.6,6c-0.4,1.5,0.6,3.6,1.2,5.3c0.5,1.4,1.4,2.7,0.3,4.2c-0.2,0.2-0.4,0.5-0.6,0.6
		c-1.2,0.5-0.9,1.2-0.3,1.8c1.5,1.4,3.3,2.4,4.4,4.4c0.5,0.9,2.4,1,3.7,1.5c0-0.1,0.2-0.3,0.1-0.4c-0.9-1.4-0.7-2.8-0.6-4.3
		c0.1-1.8,1-2.9,2.7-3.1C99,44.2,100.1,45.3,100.4,46.9z"
        />
        <path
          d="M136.7,55c-1.4,0.4-2.8,0.9-3.8,1.2c-0.8-0.6-1.4-0.9-1.9-1.3c-0.1-0.1-0.4-0.1-0.4-0.3c-1.4-2.4-4.5-2.1-6.3-3.7
		c-0.7-0.6-1.1-1.4-1.7-2.1c-0.2-0.3-0.4-0.6-0.7-1c-1.5,0.8-2.4,0.5-2.9-1.1c-0.2-0.6-0.8-1-1.2-1.5c-0.3-0.3-0.7-0.5-0.8-0.8
		c-1-2.5-1.7-5-1.4-7.7c0.1-1.4,0.1-2.8,0-4.3c-0.1-1.4,0.8-1.7,1.8-2c0.5-0.1,1.2-0.3,1.3-0.6c0.5-2.3,2-1.7,3.1-1.2
		c0.7-0.6,1.2-1.3,1.8-1.4c1.5-0.1,2.7-0.5,3.5-1.9c0.2-0.3,1.5-0.2,2.3-0.1c1,0.1,1.7,0.7,2.9,0.2c0.9-0.4,2.2,0.1,3.4,0.2
		c0.8,0,1.7,0,2.5,0c-0.1-0.9-0.1-1.8-0.2-2.7c-0.1-0.6-0.2-1.2-0.3-2.2c2.9,1.6,5.2,0.1,7.9-0.4c0.4,2,0.8,4,1.1,5.8
		c1.4,0.4,2.9,0.6,3.9,1.3c1.4,1,2.7,2.2,4.5,2.4c0.4,0,0.9,0.6,1.1,1c0.6,1.4,1.3,2.7,2.6,3.6c0.1,0.1,0.3,0.5,0.2,0.6
		c-1,2.3,0.8,3.9,1.5,5.7c0.7,1.6,2.1,3,3.2,4.5c-0.1,0.1-0.3,0.1-0.4,0.2c0.6,1.1,0.9,2.5,1.8,3.2c1.1,0.8,2.5,1.7,4.2,0.9
		c1-0.5,1.5-1,1.6-2.1c0.1-0.8,0.6-1.7,1-2.4c0.4-0.7,1.1-1.2,1.4-1.9c0.8-1.8,2.6-2.3,3.9-3.4c1-0.8,1.9-1.7,2.7-2.6
		c0.2-0.2,0.1-0.7,0.4-0.9c0.5-0.4,1.1-0.7,1.7-1c0.2,0.5,0.5,1,0.7,1.5c0.1,0.3,0,0.7-0.1,1.2c0.6,0.1,1.2,0.4,1.8,0.3
		c1.2-0.2,1.8,0.3,2.6,1.2c1.6,1.8,3.9,2.8,6.3,3.1c0.6,0.1,1.2,0,2,0c-0.2,0.6-0.4,1.1-0.7,1.9c2.2-0.1,3.7,2.3,6.1,1.2
		c0.3-0.2,1,0.3,1.5,0.5c0.9,0.6,0.9,0.6,1.3-0.3c2.5,1,5,2,7.4,3c0.6,0.3,1.1,0.9,1.7,1.3c-0.7,0.8,1,2.3-0.8,3
		c-0.2,0.1-0.3,0.7-0.3,1.1c0,0.4,0.5,0.8,0.4,1c-0.8,1.7-1.8,3.3-2.7,4.9c-0.1,0.2-0.6,0.4-0.5,0.6c0.5,2.7-2.2,4-2.7,6.2
		c-0.6,3-3.1,4.8-4.2,7.4c-0.2,0.4-1.2,0.5-1.6,0.9c-2.6,2-5.1,4-8,6.3c1.9,0.7,3.5,1.2,4.7,1.6c-0.3,0.9-0.5,1.5-0.7,2.1
		c-0.4,1.1-0.5,2.6-1.3,3.2c-1.4,1.2-1.6,3.7-4.2,3.8c-1,0-2,1-1.6,2.6c0.2,0.7-0.3,1.6-0.8,2.3c-0.6,0.9-0.8,1.6-0.1,2.5
		c1.2,1.6,1.4,3.5,0.2,5c-1.7,2.2-3.5,4.3-5.2,6.6c-0.5,0.7-0.8,1.7-1,2.6c-0.3,1.3-0.9,2-2.5,1.8c-12.3-1-24.6-1.8-36.9-2.7
		c-3.2-0.2-6.5-0.4-9.7-0.8c-1.4-0.2-2.7-0.8-3.6-2.1c-0.7-1-1.9-1.8-3-2.3c-1.3-0.6-1.3-1.7-1.4-2.6c-0.3-2.8-1.8-5.1-3-7.6
		c-0.8-1.5-2-2.8-3-4.3c-0.3-0.4-0.4-1.3-0.1-1.5c2.5-2.3,3.1-5.5,4.5-8.4c3.6-7.5,7-15.1,10.4-22.6c0.3-0.7,0.3-1.4,0.6-2.1
		c0.2-0.4,0.7-0.8,1-1.2c0.5-0.6,1-1.2,1.5-1.8C137,55.2,136.9,55.1,136.7,55z"
        />
        <path
          d="M180.6,116.2c-1.1,4.1-2.4,7.9-3.1,11.9c-0.4,2.1,0.3,4.4,0.4,6.6c0,0.7,0,1.3-0.1,2c0,0.4-0.3,0.9-0.1,1.1
		c1.8,2.6,0.2,4.8-0.7,7.2c-0.8,2-1.2,4.1-1.7,6.2c-0.2,0.8-0.2,1.6-0.4,2.3c-0.2,1-0.4,1.8-2,1.9c-1.4,0-2.5,1.1-2.9,2.7
		c-0.4,1.4-0.1,3.6-2.1,3.8c-2.1,0.2-2.7,1.9-4.3,2.6c-1.2,0.5-1,2-1.5,3c-0.6,1.3-2.1,2.2-2.7,3.5c-0.8,1.7-1.3,3.7-1.6,5.6
		c-0.1,0.5,0.8,1.2,1.1,2.1c-1.4-1.4-3.8,1.2-4.9-1.3c1.7-0.8,2-1.9,1.2-4.9c-0.6,0.4-1.1,0.6-1.5,1c-0.7,0.6-1.4,1.4-2.2,1.9
		c-0.3,0.2-1.1,0.1-1.5-0.1c-1.2-0.9-2.4-1.4-3.6,0c-0.2,0.2-0.8,0.3-1.1,0.2c-1.6-0.3-3.2-0.4-4.5-1.7c-1.6-1.5-4.6-1.8-6.7-0.8
		c-0.3,0.1-0.6,0.3-0.8,0.2c-2.4-0.4-2.9,1.5-4.1,2.8c-0.5,0.6-1.4,0.8-2.2,1.1c-1,0.4-1.4,0-1.9-0.9c-0.4-0.8-1.4-1.5-2.3-1.7
		c-3.3-0.8-4.8-3.8-3.2-6.7c0.3-0.5,0.5-1.1,0.4-1.6c-0.1-0.4-0.7-0.9-1.1-0.9c-4.4-0.1-8.7,0-13.2,0c-0.8-2.7,0.4-5.9-2.8-8.1
		c1-0.1,1.5-0.2,2.5-0.3c-0.6-2.4-1.1-4.7-1.7-6.9c-0.1-0.3-0.2-0.6-0.2-0.9c0.5-2.1,0.5-2.2-1.1-3.6c1.8-2.3,3.4-4.6,4.1-7.6
		c0.2-1,1.5-1.7,2.2-2.5c-0.8-1.5-1.8-3-2.4-4.5c-0.2-0.6,0.6-1.5,0.8-2.3c0.2-0.9,0.4-1.8,0.2-2.6c-0.1-0.4-1.1-0.7-1.7-0.8
		c-3.4-0.4-6.7-0.7-9.9-1.1c-0.2-1.7-0.5-3.5-0.6-5.3c-0.1-1.3-0.2-2.6,0.3-3.8c0.4-0.9,0-1.5-0.1-2.3c-0.2-1.4,0.1-2.9,0.1-4.4
		c7.4,0,14.5,0,21.6,0c0.9,0,1.8-0.3,2.5-0.7c0.4-3.5-0.2-6.9,1.4-10c0.2,0.2,0.6,0.4,0.7,0.7c0.6,2.4,2.4,4.5,2.3,7.1
		c0,2,1.5,2.9,3.1,3.8c0.3,0.2,1,0.3,1,0.5c0.1,1.8,2.3,1.6,2.9,3c0.1,0.2,0.5,0.3,0.7,0.3c14.9,1,29.9,2.1,44.9,3.1
		C178.1,116,179.5,116.1,180.6,116.2z"
        />
        <path
          d="M212.1,216.1c-0.3-1.2-0.3-2.7-2.1-3.1c-0.2,0-0.5-0.3-0.5-0.5c0.1-1.5,0.3-3.1,0.5-4.6c0.1-0.8,0.8-2,0.5-2.4
		c-0.5-0.6-1.6-0.8-2.5-0.9c-0.7-0.1-1,0-1.4-0.9c-0.6-1.4-1.5-2.6-0.5-4.2c0.2-0.3-0.2-0.9-0.5-1.4c-0.1-0.2-0.5-0.3-0.5-0.4
		c0.5-2-1.2-2.9-1.9-4.1c-2.2-0.3-4,1-6.2,0.7c-1.5-0.2-3.2,0.7-4.8,1c-0.9,0.2-1.7,0.2-2.6,0.7c-0.1-0.1-0.3-0.1-0.3-0.2
		c0-0.7,0.2-1.9,0-1.9c-1.1-0.3-2.3-0.4-3.5-0.6c-1.8-0.3-3.7-0.7-5.5-0.9c-0.6-0.1-1.3,0.1-1.9,0.3c-0.7,0.3-1.4,0.7-2.1,1
		c-0.2-0.1-0.4-0.2-0.6-0.4c0.3-0.8,0.6-1.5,0.9-2.3c0.2-0.5,0.3-0.9,0.6-1.4c0.2-0.3,0.3-0.8,0.6-1c1.4-0.7,2.1-1.8,2.7-3.1
		c0.5-1.2,1.5-1.2,2.5-1.4c1.7-0.2,3.3-0.3,5-0.5c0,0.2,0,0.3,0.1,0.5c0.7-0.6,1.4-1.3,2.2-1.6c2.6-1.2,5.3-0.6,8,0
		c0.7,0.2,1.6,0.2,2.2-0.1c1.5-0.8,2.9-1.9,4.1-2.7c0.1-1.4,0.3-2.6,0.1-3.8c-0.1-0.6-0.7-1.2-1.2-1.9c0.7-0.9,2.2-2.1,2.3-3.4
		c0.1-1.3-1.1-2.7-2-4.6c0.5-1.6,1.7-3.4,4.7-3.5c-0.4-1.3-0.7-2.3-0.9-3.2c0.1-1.2,0.2-2.3,0.2-3.3c2.3,0.7,2.3,0.7,2.8-1.7
		c0.9,0.4,1.9,0.8,2.9,1.3c0,0.1-0.3,0.3-0.4,0.6c-0.1,0.4-0.1,0.9-0.1,1.3c0.4,0,0.9,0.2,1.2,0c2.8-1.4,5.6-2.9,8.4-4.4
		c0.7-0.4,1.4-0.9,1.9-1.5c1.6-1.8,3.4-1.6,5.6-0.8c-0.2,0.6-0.4,1.3-0.6,2c1.3,0.5,2.6,1,3.9,1.6c0.9-1.5,1.9-1.7,4.2-0.5
		c2,1,4,2,6,3c0.3,0.1,0.7,0.3,1,0.2c1.4-0.4,2.6,0,3.4,1.1c0.7,1,1.3,2,1.9,3c-0.1,0.2-0.2,0.3-0.3,0.5c0.9-0.1,1.8-0.2,2.6-0.4
		c2.3-0.4,4.1,0.8,6.1,1.5c0.4,0.1,0.8,0.5,1.2,0.6c1,0.4,1,0.9,0.3,1.7c-1.3,1.5-2.8,3-3.9,4.7c-0.8,1.2-0.9,2.7-1.3,4
		c1.2,1.2,2.3,2.3,3.5,3.6c-1.7,0.1-3.1,0.2-4.5,0.3c-0.2,0-0.5,0-0.6,0.2c-0.8,0.9-1.6,1.8-2.1,2.9c-0.3,0.5,0.1,1.3,0.2,2
		c0.2,0.1,0.5,0.1,0.7,0.2c-0.7,0.8-1.3,1.6-1.8,2.2c0.6,0.8,1.2,1.5,1.7,2.2c0.6,0.9,0.8,1.6,0,2.6c-1,1.2-1.6,2.7-2.2,4.2
		c-0.4,0.9-0.8,1.2-1.8,1.2c-2,0-2.1,0.4-2.1,2.3c0,1-0.5,2.5-1.3,2.9c-1.3,0.7-1.6,1.8-1.7,2.8c-0.3,3.5-2.8,5-5.7,5.9
		c-1.2,0.4-2.5,0.2-3.8,0.2c-2,0-3.8,0.4-5.6,1.3c-1.7,0.8-3.7,1.1-5.6,1.7c-0.7,0.2-1.4,0.5-2.1,0.8c-0.2,0.1-0.4,0.4-0.6,0.3
		c-2.2-0.4-4,0-5.3,1.9C214.1,216.3,213,216.1,212.1,216.1z"
        />
        <path
          d="M231.5,150.2c-2.7-1.7-6.8-0.7-9.1,1.8c-1.1,1.2-2.9,2-4.4,2.9c-0.9,0.6-1.9,1-3.3,1.7c0.1-1.7-0.1-3.2,0.3-4.5
		c0.5-1.3,0-2.1-0.9-2.7c-1.1-0.7-1.4-1.7-1.1-2.9c0.3-1.4,0.7-2.9,1.1-4.4c-0.4,0.1-0.9,0.1-2,0.3c0.7-1.1,1.2-1.9,1.8-2.7
		c-1.5-0.6-1.7-1.5-0.6-2.7c0.2-0.2,0.2-0.8,0-1c-1.9-2.5-2-2.5,0.2-4.2c-0.5-0.2-0.9-0.4-1.6-0.8c0.8-0.4,1.4-0.7,2.4-1.2
		c-1.6-0.8-2.9-1.4-4.6-2.3c2.7-3.1,5.1-5.8,7.8-8.9c0.3,1.2,0.4,2,0.6,2.7c0.1,0.5,0.3,1,0.6,1.4c1.5,2,4.2,2.4,6,0.7
		c0.9-0.9,1.9-1.3,3.2-1c1.7,0.5,1.9-1.3,2.8-2c0.9-0.6,1.4-1.8,1.8-2.8c0.3-0.6,0.3-1.9-0.1-2.2c-1.8-1.4-1-2.2,0.5-3
		c0.3-0.1,0.5-0.3,0.4-0.3c1.2,0.3,2.1,0.6,3.1,0.8c0.3,0.1,0.6,0.1,0.8,0.2c0.6,0.4,1.4,1.4,1.8,1.2c1.2-0.3,2.3-1.1,3.5-1.8
		c0.3-0.2,0.6-0.6,0.8-0.6c2.4,0.3,3.7-1.5,5.3-2.7c0.1-0.1,0.2-0.1,0.3-0.2c0.6-0.9,1.2-2.2,2.6-1.4c2.2,1.2,2.9,2.8,2.5,4.6
		c-0.1,0.3-0.2,0.5-0.4,1c1.3-0.3,2.7-0.1,3.4-0.8c1.5-1.2,2.9-2.5,4.5-3.5c1.3-0.8,2.3-2.1,3.2-3c1.1,0.6,1.9,1,2.6,1.4
		c1.6,0.7,3.2,1.4,4.8,2.2c0.5,0.3,0.8,0.9,1.1,1.4c0.5,0.6,1,1.3,1.4,2c0.2,0.3,0.2,0.8,0.3,1.2c0.3,1.1-0.1,2.4,1.4,3
		c0.4,0.2,0.7,1,0.6,1.5c-0.1,1.1-0.5,2.1-0.9,3.3c-0.1,0-0.4-0.2-0.6-0.1c-0.6,0.1-1.6,0-1.8,0.4c-0.3,0.5-0.4,1.4-0.1,2
		c0.5,1.2,1,2.6,1.9,3.5c0.9,0.9,2.3,1.3,3.7,2.1c-1.2,3.2-3.3,6.2-6,9c-0.3,0.3-1.1,0.2-1.7,0.3c-0.4,0.1-0.7,0.2-1,0.4
		c-0.5,0.4-0.8,1-1.3,1.3c-1.2,0.6-1.2,1.5-1.2,2.7c0.1,2.2,0.1,4.5-0.5,6.7c-0.7,2.6-0.4,5.3,0.2,7.6c1,3.7-0.2,6.9-0.9,10.4
		c-0.5,2.3-0.6,4.8-0.7,7.2c0,1.1-0.3,1.7-1.2,2.4c-1,0.7-1.8,1.8-2.7,2.7c-0.7-0.5-1.7-1-2.6-1.6c-0.2-0.1-0.6-0.4-0.6-0.6
		c0.4-1.8-1.2-2.5-2.1-3.6c-0.5-0.6-0.5-1.6-0.7-2.4c-0.2-1.1,0.3-1.7,1.6-1.6c0.2,0,0.8-0.6,0.8-0.7c-0.8-1.8,0.9-2.5,1.7-3.4
		c2.4-2.6,1.1-3.6-1.1-4.7c-1.8-0.9-3.9-1.3-5.9-1.6c-0.8-0.1-1.8,0.4-2.4,0.2c-1.3-1.4-2.7-2.8-4-4.2c-0.2-0.2-0.5-0.3-0.8-0.3
		c-3.1,0.8-5.2-1.1-7.5-2.5c-1.3-0.8-2.6-1.6-4.2-0.8c-1.1,0.6-2.3,0.8-3.1-0.2C231,152.2,231.4,151.1,231.5,150.2z"
        />
        <path
          d="M175.1,190.3c0,1.7-0.1,3,0,4.3c0.1,1-0.5,1.3-1.3,1.9c-1.3,0.9-2.3,2.2-3.3,3.5c-0.5,0.7-0.8,1.6-1.1,2.4
		c-0.3,0.6-0.5,1.2-0.7,1.9c-0.1,0.2-0.1,0.5-0.2,0.6c-1.7,1.6-2.2,4-3.8,5.7c-0.7,0.7-1.7,1.1-2.6,1.7c-2,1.4-4,2.8-6,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,3-4,4.3-4.2,7.4c-0.1,1.5-0.9,1.4-1.8,0.5c-1-0.9-2-1.2-3.4-0.6c-1.2,0.5-2.6,0.7-4,1
		c-0.6-2.7-1.2-5.1-1.6-7.5c-0.3-1.4,0-2.7-0.9-4.1c-0.6-1-1.1-1.7-2.4-1.7c-1.1,0-2-0.2-2.6-1.3c-0.1-0.3-1.2-0.4-1.4-0.2
		c-2.6,2.2-5.2,0.8-7.9,0.2c-0.8-0.2-1.8,0-2.7,0c0.3-2.6,0.7-5.1,0.7-7.7c0-1.2-0.5-2.4-0.9-3.6c-0.3-0.9-0.8-1.8-1-2.7
		c-0.1-0.3,0.4-0.8,0.8-1.1c0.4-0.4,0.8-0.6,1.3-1c-0.8-0.7-1.5-1.2-2.3-1.9c1.1-1.9,2.1-3.8,3.3-6c-0.5-1.4,0.4-4.4,1.8-5.5
		c-0.5-0.8-1-1.7-1.5-2.4c1-0.1,2,0,2.8-0.3c0.8-0.3,1.5-1.1,2.3-1.7c0.8-0.7,1.5-1.9,2.5-2.1c2.6-0.5,5.2-1.2,7.7,0.5
		c0.9,0.6,1.9,1.3,3,1.8c1.2,0.5,2.4,0.2,3.6-0.5c0.9-0.5,1.7-0.9,2.5,0.2c0.6,0.8,2.6,0.5,3.3-0.4c0.5-0.7,1.2-1.3,1.8-1.9
		c0.2,0.1,0.4,0.3,0.6,0.4c-0.9,1.3-1.8,2.6-2.6,3.8c1.3,1.6,3.2,1.5,4.7,1.2c1.6-0.3,1.9,0.3,2.1,1.4c0.2,1.2,0.8,1.2,1.7,0.9
		c-0.1,0.7-0.6,1.9-0.3,2.1c0.7,0.5,1.7,0.7,2.6,0.8c0.6,0.1,1,0.1,1.6,0.6c1.8,1.3,3.8,2.3,5.9,2.9
		C172.8,188.9,173.8,189.1,175.1,190.3z"
        />
        <path
          d="M192.6,139.7c0.5,1.1,1,2.1,1.5,3.2c2.4,0.1,5.1-0.4,7.4,1.5c0.2-0.6,0.5-1.3,0.7-2c2,1,3.6-0.3,5.3-1.1
		c1.1-0.5,2.3-0.7,3.7-1.2c0.2,3.2,0.4,5.9,0.5,8.6c-0.2,0-0.3,0.1-0.5,0.1c0.3,0.2,0.7,0.4,1,0.6c0.5,0.4,1.2,0.8,1.3,1.2
		c0.1,1.1-0.1,2.3-0.2,3.5c0,0.1-0.7,0.3-1,0.2c-0.4-0.1-0.6-0.5-1-0.7c-0.5-0.2-1.2-0.5-1.5-0.4c-0.3,0.2-0.4,0.9-0.5,1.4
		c-0.1,0.3,0,0.7,0.1,1.2c-0.3-0.1-0.5-0.1-0.7-0.2c-1.8-0.5-2.6,0.1-2.4,1.9c0.2,1.5,0.4,2.9,0.7,4.7c-0.8,0-1.7,0.1-2.6,0.2
		c0-3.1-0.3-3.3-3.3-3.3c-1.4,0-2.8,0-4.4,0c-0.3,1.7-0.5,3.3-0.8,5.1c2.4,0,4.5,0,7,0c-0.1,0.8-0.2,1.5-0.3,2.2
		c-0.2,0.8-0.4,1.4,0.5,2.2c1.4,1.1,1.3,2.7-0.1,3.6c-0.6,0.3-1,0.9-1.3,1.5c-0.7,1,0.3,2.1,1.8,2.2c-0.1,1.1-0.2,2.2-0.4,3.2
		c-0.4,1.5-3.6,2.9-5.2,2.5c-1.3-0.3-2.8-0.3-4.2-0.4c-2-0.2-3.9-0.1-5.2,1.7c-0.1,0.1-0.3,0.2-0.3,0.2c-1.3-1.4-2.4,0.4-3.6,0.3
		c-2.7-0.1-4.6,0.8-5.7,3.4c-0.4,1-1.8,1.6-2.6,2.3c-2.8-1.1-5.7-2.2-8.6-3.3c-0.6-0.2-1.1-0.8-1.7-1c-1.5-0.5-3.1-0.9-4.7-1.4
		c0.5-0.5,0.9-1.1,1.7-1.9c-1.2-0.3-2-0.5-2.6-0.6c-0.5-1.8-1-3.5-1.3-5.2c-0.1-0.9,0.1-1.9,0.6-2.7c1-1.5,2.3-2.7,3.4-4.1
		c0.4-0.5,0.4-1.4,0.5-2.1c0.1-0.2,0-0.6,0.2-0.7c1.4-1.1,2.3-2.9,4.5-3.2c0.7-0.1,1.5-1,1.9-1.8c0.5-0.9,0.6-2.1,1.1-3
		c0.3-0.7,0.9-1.3,1.6-1.6c0.7-0.4,2-0.3,2.4-0.9c0.7-1.1,0.9-2.5,1.3-3.8c0.9-2.6,0.7-5.4,2.2-7.9c0.9-1.5,1.1-3.4,1.5-4.9
		c2.8,1.3,5.5,2.6,8.4,3.9c0.4-1,0.7-2,1.1-3.2c0.6,0.3,1.1,0.5,1.9,0.9c0.1-0.2,0.2-0.6,0.4-1.1
		C192.2,139.5,192.4,139.6,192.6,139.7z"
        />
        <path
          d="M126,273.2c1.5-1.5,3.1-3,4.6-4.5c0.8-0.9,1.5-2,2.3-2.9c1-1.1,2.2-2.1,3.2-3.2c0.6-0.6,1.2-1.4,1.9-1.9
		c0.4-0.3,1.1-0.4,1.5-0.7c0.4-0.4,0.6-0.9,0.9-1.4c-0.1-0.1-0.2-0.2-0.4-0.2c1.7-0.9,3.7-1.5,4.9-2.9c1.6-1.7,3.5-2.6,5.7-3
		c0.5-0.1,0.9-0.6,1.3-1c1.6-1.6,3.7-0.8,5.5-0.8c2.9,0,5.9,0.7,8.8,1.1c0.6,0.1,1.2,0.5,1.8,0.8c0.3,0.2,0.6,0.5,0.9,0.5
		c3.8,0.1,6,2.7,8,5.2c1.6,2,3.6,2.4,5.9,2.4c0.5,0,1,0,2,0c-2.3,1.6-1.1,4.6-4.2,5.6c1.5,0.7,2.5,1.1,3.7,1.7
		c-2.2,3.9-4,7.8-6.6,11.3c-1.9,2.5-4.5,4.5-7.1,6.4c-2.9,2-5.7,3.9-6.5,7.6c-0.7,3.3-3.6,5-6.1,6.9c-0.2,0.2-1,0-1.1-0.2
		c-0.6-1,0.3-3.6,1.3-4.4c0.8-0.6,1.4-1.5,2.3-2.4c-1.3-0.9-2.5-1.7-3.7-2.6c-0.4-0.3-0.8-0.6-0.9-1c-0.5-2-1.9-2.8-3.7-3.3
		c-0.4-0.1-0.8-0.3-1-0.6c-1.4-1.9-3.4-3.2-5.5-3.8c-1.6-0.5-2.3-1.5-3.4-2.4c-1.9-1.8-2.2-1.7-3.9,0c-0.9-1.1-1.6-2.3-2.6-3.3
		c-1.2-1.2-2.6-2.3-4-3.3c-1.3-1-2.6-1-3.7,0.4c-0.3,0.4-1.2,0.4-1.8,0.5C126.2,273.4,126.1,273.3,126,273.2z"
        />
        <path
          d="M207.3,109.4c0.2-0.8,0.3-1.3,0.6-1.8c0.6-1.1,0.5-2.6,2.2-3.1c1.3-0.4,1.1-1.9,0.8-2.9c-0.3-1.1-2.1-1.7-3.1-0.9
		c-1.3,1-1.7,0.2-2.2-0.7c-0.8-1.3-1.6-2.6-2.4-3.9c-0.2-0.3-0.6-0.4-0.9-0.7c2-4,2-8.2,1.2-12.5c-0.1-0.4-0.4-1-0.8-1.1
		c-1.7-0.5-2.7-2.5-4.9-1.8c-0.3,0.1-0.7-0.3-1.5-0.6c1.7-1.2,3.2-2.3,4.6-3.3c0.8-0.6,1.6-1,2.2-1.7c1.1-1.1,2.2-2.3,3-3.6
		c1.5-2.6,2.8-5.3,4-8c0.7-1.5,0.7-3.4,1.6-4.7c1-1.5,1.4-3.1,1.7-4.8c0.2-1,0.4-2.1,0.7-3.2c2,0.9,4.3,1.3,5.1,4.1
		c0.6-0.7,0.9-1.2,1.3-1.4c0.5-0.2,1.2-0.3,1.8-0.4c-0.1,2.1,3.2,1.9,3,4.1c0,0.7,0.4,1.6,0.8,2.2c0.3,0.4,1,0.7,1.6,0.9
		c0.5,0.2,1.1,0.3,1.6,0.5c0.2,0.3,0.3,0.7,0.5,1c0.6-1.3,1.2-2.6,1.8-3.9c0.3,0.3,0.5,0.6,0.8,1c0.7,0.1,1.3,0.2,2,0.4
		c1.4,0.4,2.8,0.8,4,1.5c1.5,0.8,2.9,1.4,4.7,1.3c0.8-0.1,1.6,0.3,2.5,0.5c0.5,1.2-0.2,2-1.3,2.9c-1,0.8-1.9,1.4-3.2,1.5
		c-0.6,0.1-1.2,1.3-1.7,2c-0.7,1.2-1.3,2.4-2,3.6c-0.2,0.3-0.5,0.8-0.4,0.9c1.2,1.7-0.2,3.6,0.7,5.3c1,1.7,0.3,3.1-0.9,4.5
		c-1.2,1.5-1.1,4.1,0.4,5.3c1.1,1,0.6,2,0.3,2.9c-0.2,0.5-1.1,0.9-1.7,0.9c-1.1,0-2.2-0.1-3.3-0.4c-1.3-0.3-3.8,0.4-4.5,1.4
		c-1.6,2.4-4.1,3.7-6.7,4.6c-2.6,0.8-4,2-4.4,4.8c-0.2,1.8-1.6,3.4-2.3,5.2c-0.3,0.8-0.6,1.7-0.4,2.5c0.5,2.5,1.2,4.9,0.5,7.4
		c-0.2,0.9-0.8,1.2-1.5,1c-0.7-0.2-1.9-0.8-1.9-1.2c0-1.3-1.8-2.2-0.8-3.6c0.1-0.1-0.1-0.4-0.3-0.6
		C209.2,111.5,208.2,110.4,207.3,109.4z"
        />
        <path
          d="M195.9,89.3c0.5-1,0.8-1.6,1-2.2c0.5-1.2,0.2-2.7,1.3-3.7c0.1-0.1,0.1-0.4,0-0.5c-1-0.8-2.1-1.5-3.2-2.3
		c-0.1,0.1,0.2-0.4,0.3-0.4c1.4,0.3,2.8,0.7,4.2,1.1c0.2,0.1,0.4,0.4,0.6,0.4c1.7,0.1,2,1.3,2.4,2.7c0.9,3.2,0,6.2-0.7,9.3
		c-0.1,0.5-1,0.9-1.7,1.5c1.9,2.3,3.7,4.7,5.5,7c1.1,1.4,1.8-0.7,2.9-0.4c0.4,0.1,0.9,0,1.4,0c-0.3,0.6-0.4,1.6-0.9,1.8
		c-1.8,0.7-2.6,1.7-2.6,3.6c0,0.8-0.6,1.5-1,2.4c1.1,1.2,2.4,2.6,3.7,4c-0.6,2,1.3,4.9,3.7,5.5c0.7,0.2,1.4,0.5,2.3,0.8
		c-0.6,0.5-1.1,1-1.7,1.2c-2,0.7-1.9,2.7-3,4c-0.1,0.2-0.1,0.5-0.3,0.6c-0.5,0.6-2,0.9-0.9,2.2c0.6,0.7,1.2,1.3,1.8,2
		c0,1.9-1.2,3.8,0.2,5.9c0.9,1.3-0.6,3.6-2.1,3.9c-1.3,0.2-2.5,1-3.8,1.4c-0.9,0.2-2,0.4-2.7,0c-1.5-0.8-2-0.3-2.3,1
		c-1.5,0.2-2.6-2.5-4.3-0.4c-1.2-1.1-2.2-2.1-3.2-3c-0.9-0.8-3.4-0.4-4,0.7c-0.4,0.7-0.7,1.4-1.1,2.1c-1.8-0.9-3.6-1.8-5.7-2.9
		c0.3-1.1,0.7-2.4,1.1-4.1c-1.5,1-2.8,1.9-4.1,2.8c-0.1-1.3-0.1-2.9-0.3-4.5c-0.1-1.4-0.4-2.8-0.6-4.2c0-0.1-0.1-0.2,0-0.3
		c0.7-2.6,1.3-5.3,2.2-7.9c0.9-2.6,1.8-5.2,3-7.6c0.8-1.6,2.1-2.9,3.2-4.2c2.1-2.4,3.8-4.9,3.8-8.2c0-0.2,0-0.4-0.1-0.5
		c-2.3-1.1-0.5-2.4-0.4-3.7c0-2.6,1.2-3.6,3.5-4.1c0.4-0.1,0.7-0.6,0.9-0.9C194.9,89.5,194.8,89.5,195.9,89.3z"
        />
        <path
          d="M227.1,215.7c-0.4,0.1-0.8,0.2-1.2,0.3c-1.9,0.4-3,1.4-3.7,3.3c-0.4,1-1.8,1.6-2.8,2.4c-0.5,0.5-1.1,1-1.8,1.7
		c-1.7-0.4-3.7-0.6-5.4,1.3c-0.7,0.8-2.3,0.7-3.2,1.3c-3.1,2.1-6.1,4.3-9,6.5c-0.5,0.4-0.8,1.1-1.4,1.9c-1.1-0.7-1.5-3.1-3.7-1.5
		c0.6-2.4,0.4-2.7-1.8-2.8c-1.2,0-2.4,0-3.8,0c1.1-2.2-0.3-3.6-1.4-5.3c-0.6-0.9-0.5-2.2-0.7-3.4c-0.2-0.8-0.4-1.6-0.6-2.4
		c-0.3-1-3.2-2.4-4.2-2.4c-1.9,0-3.7,0.2-5.6-0.7c-1.5-0.8-3.4-0.8-5.1-1.1c-0.4-0.1-0.8-0.2-1.2-0.2c-1.6-0.1-2.9-1.4-4.8-0.3
		c-1,0.6-2.8,0-4.4-0.1c0.3-0.3,0.5-0.7,0.8-0.9c2.9-1.4,4.6-3.8,6-6.6c0.8-1.7,2.6-3.1,3.1-4.9c0.4-1.3,0.6-2.6,1.6-3.5
		c0.5-0.4,1-0.7,1.5-1c0.4-0.3,0.9-0.6,1.3-1c0.8-0.6,1.5-1.3,2.2-2c1.5-1.3,3.2-0.4,4.8-0.2c0.7,0.1,1.5,0.3,2.2,0.2
		c1.7,0,3.3-0.1,3.8,2.1c0.2,1.1,1,1.1,2,0.2c0.3,0.6,0.7,1.2,1.2,2.1c0.7-2.5,1.6-2.9,4.2-2.9c1.2,0,2.7,0.2,3.7-0.3
		c0.7-0.4,1-0.7,1.8-0.4c0.8,0.3,1.6,0.3,2.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c0.4,1,0.8,2,1.3,3.4c-1.4,1.8,0.6,3.7,1.1,5.7
		c0.1,0.3,1.1,0.5,1.7,0.6c0.7,0.1,1.5,0.1,2.3,0.3c-1.9,1.8-1,4.1-1.7,6c0,0.1,0.2,0.4,0.3,0.6c1.2,1.1,2.1,2.5,2.5,4
		c0.1,0.2,0.5,0.4,0.7,0.4c1.5-0.1,3-0.2,4.4-0.5c0.4-0.1,0.7-0.9,0.8-1.1c2.2-0.7,4.3-1.4,6.4-2.1c0.6,1.9,2.2,1.7,3.7,1.6
		C227.1,215.4,227.1,215.6,227.1,215.7z"
        />
        <path
          d="M238,73c0.8-1.6,1.5-3,2.3-4.3c0.4-0.7,1-1.4,1.7-1.7c2.5-0.9,4.3-2.5,5.2-4.9c0.1,0,0.1,0,0.2,0c0.8,0.3,2,0.3,2.3,0.8
		c0.3,0.4-0.4,1.5-0.5,2.3c-0.1,0.6,0,1.4,0.3,1.9c0.7,1.4,0,3.3,1.6,4.4c0.3,0.2,0,1.4-0.2,2.1c-0.2,0.8-0.4,1.5-0.6,2.3
		c-0.3,1.6,0.6,2.4,1.4,3.4c0.4,0.5,0.8,1.2,0.9,1.9c0.4,2.8,0.7,5.7,1.2,8.5c0.2,1,0.6,2,1.9,2.3c0.3,0.1,0.8,0.7,0.8,0.9
		c-0.4,1.3-0.9,2.7-1.6,3.9c-0.5,0.8-0.3,2.9,1,4.1c-0.4,0.6-0.8,1.4-1.3,1.8c-1.6,1.3-3.4,2.4-5,3.8c-1.1,0.9-1.9,2-2.9,3
		c-0.6,0.5-1.2,1-1.9,1.3c-1.1,0.6-2.2,1-3.4,1.5c-1.2,0.5-2.3,1.5-3.5,0c-0.3-0.4-1.1-0.4-1.6-0.5c-0.6-0.2-1.2-0.4-1.8-0.5
		c-0.4-0.1-0.9-0.2-1.2-0.1c-0.8,0.4-1.7,0.8-2.1,1.5c-0.4,0.6-0.4,1.7-0.2,2.4c0.5,1.3,0.3,3.6-0.8,4.5c-0.6,0.5-1.2,1.1-1.9,1.7
		c-1.8-0.6-3.5,0.1-4.8,1.6c-1,1.2-2,0.3-2.8,0.1c-0.6-0.2-1.2-1.1-1.5-1.8c-0.5-1-0.7-2.2-1.3-3.1c-0.2-0.3-1.3-0.1-2.1-0.1
		c0.2-1.5,0.5-3.5,0.8-5.6c-1.3-1.2-1.5-3.3-0.5-5.2c0.9-1.9,1.7-3.9,2.3-5.9c0.4-1.5,1.2-2.5,2.7-2.9c3.1-0.9,5.9-2.4,7.9-5.1
		c1-1.3,2.9-1.4,4.3-0.6c0.5,0.3,1.1,0.2,1.7,0.2c0.3,0,0.5-0.2,0.8-0.3c2.7-0.7,2.9-0.8,3.2-3.4c0.1-0.6-0.2-1.3-0.6-1.8
		c-1.4-1.5-1.8-3.7-0.3-5c1.8-1.6,1.3-3.2,0.7-4.9c-0.2-0.5-0.5-1.2-0.2-1.6C239.1,74.7,238.8,73.7,238,73z"
        />
        <path
          d="M69.9,109.9c1.5-0.1,2.9-0.1,4.3-0.3c0.6-0.1,1.2-0.6,1.6-1.1c1.3-2,2.5-4.2,3.7-6.2c0.2-0.3,0.7-0.5,1-0.5
		c1.1-0.1,2.2-0.2,3.3,0c0.7,0.1,1.3,0.8,1.9,1.3c0.7,0.5,0.6,1.8,2.1,1.9c0.7,0,1.3,1.7,2.1,2.5c1.5,1.6,2.3,1.9,3.7,0.2
		c2,0.7,0.8,2,0.6,3.1c-0.1,0.4-0.4,0.9-0.3,1.2c0.9,2,0.5,4,0.3,6.1c-0.2,1.4,0.6,2.8,0.7,4.2c0.1,0.8-0.3,1.7-0.5,2.7
		c1.1,0,2,0,2.9,0c2.9-0.2,5.6,0.2,8.4,1c1.4,0.4,1.4,1.1,0.6,1.9c-1.4,1.5-1.4,2.8,0,4.4c0.7,0.8,1,1.9,1,2.7
		c-0.5,1.2-0.7,2.8-1.6,3.6c-1.7,1.7-1.5,4.9-4.4,5.7c0-0.2,0-0.3,0-0.5c-1.4-0.2-2.8-0.5-4.1-0.6c-0.7-0.1-1.5,0.1-2.3,0
		c-0.8-0.2-1.9-0.4-2.3-1c-1.3-2.1-3.8-1.9-5.5-3c-1-0.6-1.9-1.3-3-1.9c-0.5-0.3-1.1-0.5-1.6-0.6c-0.3-0.1-0.7-0.1-1.1-0.2
		c-1.5-0.7-2.8-2.3-4.6-0.8c-1.1-0.9-3.2,0.7-3.8-1.7c-0.2-0.8-1.8-1.3-2.7-1.9c-0.2-0.1-0.7-0.1-0.8-0.3c-0.6-1.8-2.7-2.9-2.5-5
		c0.2-2-0.2-3.9-0.7-5.8c-0.3-1.4,0.5-2.9,0.5-4.4c0-1-0.5-2.1-0.7-3.1c-0.1,0-0.3,0.1-0.4,0.1c0.2-0.5,0.4-1,0.5-1.3
		c0.5,0.3,1.1,0.9,1.4,0.8C68.9,112.6,69.8,111.6,69.9,109.9z"
        />
        <path
          d="M80.4,20.5c-1.2,0-2.7,0-3.5,0c-0.6-1.7-1.1-2.8-1.5-4c-0.3-1-0.6-2.1-0.5-3.1c0.1-1.4-0.6-2.2-1.6-3
		c-0.7-0.6-1.4-1.2-2.3-1.9c2-0.3,3.6-0.8,4.6,1.2c0.1,0.2,0.7,0.2,1.1,0.2c0.7,0.1,1.4,0.1,2.2,0.1c0.7,0,1.4,0,2.1-0.2
		c1.4,1,2.4,3,5,1.5c0.1-0.6,0.1-1.4,0.2-2.1c0.6-0.3,1.1-0.7,1.7-1c0.3-0.1,0.7-0.2,1.1-0.1c2,0.5,5-0.6,6.7-2
		c0.8-0.7,2.1-0.7,3.1-1.1c2-0.7,3.9-3.4,3.4-5.2c0.4,0,0.8,0,1.2,0c0.8,0.1,2.1,0,2.2,0.2c0.3,1,0.5,2.2,0.1,3.1
		c-0.7,1.8-0.9,1.7,1.2,2.1c0.5,0.1,0.9,0.2,1.2,0.3c0.3,0.9,0.5,1.7,0.9,2.4c0.8,1.4,0.3,2.3-1,3.1c-0.6,0.4-1.1,0.6-0.6,1.5
		c0.2,0.3,0.1,1-0.1,1.4c-1.3,3-1.5,5.9,0.8,8.6c0.2,0.3,0.1,0.8,0.1,1.3c-0.1,1.3,0,2.5,1.6,3c0.2,0,0.3,0.2,0.4,0.3
		c0.8,1.7,2.3,2.4,4,2.8c0.2,0.1,0.5,0.6,0.5,1c0,2,0,4.1,0,6.3c-2.2,0-4.3,0.2-6.4-0.1c-2.1-0.3-2.8,1.1-3.6,2.3
		c-0.7,1.1-1,2.5-1.4,3.8c-0.1,0.5,0.1,1.1,0.2,1.8c-0.5,0.2-1.1,0.5-1.9,0.9c-0.2-0.4-0.4-0.8-0.7-1.1c-0.9-1-2-2.1-3.5-1.7
		c-2,0.5-3.3,2.3-3.3,4.1c0,0.3,0.1,0.6,0,0.8c-0.2,0.6-0.5,1.3-0.8,1.9c-0.6-0.4-1.1-0.9-1.7-1.4c-0.4-0.4-0.7-1-1.1-1.2
		c-1.9-0.8-2-2.2-1-3.5c1.6-1.9,0-3.3-0.6-4.7c-0.8-1.9-1.4-3.6-0.8-5.7c0.6-2-0.6-3.6-1.5-5.1c-0.6-1-0.5-1.6-0.5-2.5
		c0-0.6-0.1-1.6-0.5-1.8c-1.2-0.7-2.6-0.9-3.9-1.6C81.1,22.2,80.9,21.4,80.4,20.5z"
        />
        <path
          d="M197.5,235.1c-1.3,1.7-2.4,3.2-3.6,4.6c-0.4,0.4-1.2,0.6-1.9,0.6c-1.5-0.1-2.7,0.1-4,0.9c-0.6,0.4-1.9,0.4-2.4,0
		c-1.3-1-2.5-1-4-0.7c-0.6,0.1-1.1,0.1-1.7,0.1c-0.3,0-0.8-0.2-0.8-0.1c-1.2,2.1-3.9,1.2-5.4,2.6c-0.5,0.5-0.6,1.5-0.9,2.4
		c-3.3-0.7-6.7-1.5-10.2-2.3c-0.6-0.1-1.3,0.1-1.9,0c-0.7-0.1-1.4-0.5-2.1-0.6c-0.7-0.1-1.4,0-2.6,0c-0.3-0.6-0.9-1.4-1-2.3
		c-0.2-2.3-2.3-4.3-4.9-3c-0.4,0.2-0.9,0.1-1.4,0.1c0.1-0.5,0.2-0.9,0.3-1.4c0.5-2,1.2-3.9,1.6-5.9c0.2-1.1,0.2-2.2,0.3-2.8
		c0.8-1.8,1.4-3.1,2-4.5c0.2-0.5,0.6-1,0.9-1.5c0.6-0.8,1.7-1.6,1.9-2.5c0.3-1.5,1.4-2,2.4-2.6c2.2-1.4,4.7-1.2,7.2-1
		c0.8,0.1,1.7-0.1,2.5-0.2c1.6,0.2,3.3,0.2,4.9,0.6c1.5,0.4,2.8,1.2,4.2,1.8c0.4,0.1,0.7,0.3,1.1,0.3c1.6,0,3.1-0.1,4.7,0
		c1.4,0,3.2,1.9,3.1,3.3c-0.1,2.6,0.5,5,2.4,6.9c0.2,0.2,0.2,0.6,0.1,1c0,0.7-0.1,1.4-0.2,2.4c1.6,0,2.9,0,4.3,0
		c0.8,0,1.4-0.1,1.2,1.2c-0.2,0.8,0,2.3,1.9,1.7C196.1,234,196.9,234.8,197.5,235.1z"
        />
        <path
          d="M54.4,116.6c-1.3,0.6-1.9,2.6-4.1,2.5c-0.8,0-1.5,1.1-2.3,1.7c-0.5,0.3-1.1,0.8-1.5,0.7c-1.7-0.4-2.2,0.7-3.1,1.7
		c-0.8,0.9-2,1.6-3.2,2.5c-0.2-0.7-0.3-1.1-0.4-1.4c-1.4-0.2-2.8-0.5-4.2-0.5c-2.4,0-4.8,0-7.1,0.5c-1.9,0.4-3,0-3-2
		c0-1.4-0.1-2.8,0-4.3c0.2-1.6,0.5-3.1,0.8-4.7c0.1-0.4-0.1-0.8-0.2-1.2c-0.4,0.1-0.9,0.2-1.2,0.4c-1,0.6-2.1,1.2-2.8,2
		c-1.4,1.8-3.3,2.1-5.3,2c-0.9,0-1.9,0-2.8,0c-0.5-4.5-3.9-4.7-7.8-4.5c1.8-1.5,2.2-2.8,0.7-4.3c-1.2-1.1-2.3-2.4-3.2-3.7
		c-0.7-1.1-1.1-2.4-1.7-3.6c-0.2-0.5-0.4-1.1-0.8-1.4c-1.3-1.1-1.3-1.4,0.1-2.2c0.4-0.2,0.6-0.6,1-1c7.1,4,15.2,5.1,23.1,6.9
		c0.8,0.2,1.7,0.5,2.4,0.9C36.6,107.9,45.5,112.3,54.4,116.6z"
        />
        <path
          d="M280.8,76.9c-3-0.1-3.5,2-4.4,3.9c-0.7,1.5-1.6,3-2.4,4.4c-0.1,0.1-0.2,0.4-0.3,0.4c-3.2,0.9-3.2,3.6-3.7,6.1
		c0,0.2,0,0.4-0.1,0.5c-1.6,1.8,0.2,2.9,0.7,4.5c-0.2,0.2-0.5,0.6-0.9,1c-1.7,2.2-1.8,2.2-3.6,0.2c-1.7-2-3.4-2.3-5.9-1.6
		c-1,0.3-2.2,0-3.4,0c0.4-1.5,0.7-2.8,1.1-4.1c-4.3-1.5-3.5-5.4-4.1-8.5c-0.2-1,0.3-2.1-0.1-3c-0.5-1.3-1.4-2.4-2.4-4
		c0.3-1.2,0.8-3,1.1-4.7c0.1-0.5-0.4-1-0.5-1.5c-0.6-1.7-1.4-3.4-1.6-5.1c-0.1-0.9,0.7-1.9,1.1-2.8c1.5-0.2,3-0.3,4.6-0.6
		c2.1-0.4,4-0.2,5.8,0.9c2.5,1.6,4.9,3.4,7.6,4.7c3.5,1.8,5.4,5.2,8.4,7.4C278.6,75.8,279.5,76.2,280.8,76.9z"
        />
        <path
          d="M146.9,20.7c1.8,2.2,4.3,2.6,6.5,1.5c0.5-0.3,1.3-0.2,2-0.2c0.8,0,1.6,0,2.7,0c1.5,1.7,4.9,0.8,5.9-2.1
		c1.5-4.2,3.7-8,6.7-11.3c0.6-0.7,0.7-1.9,1.1-3c2.1,1.1,3.2,3,3.2,5.1c-0.1,4.5,1.9,8.3,3.3,12.5c1.7-1,2.2,0.2,2.9,1.3
		c0.3,0.6,1,1,1.5,1.5c0.6,0.6,1.5,1.3,1.5,1.9c0.1,1.5-0.2,3-0.6,4.5c-0.2,0.7-1.2,1.3-2,1.5c-1.2,0.4-1.9,1.1-2.4,2.2
		c-0.5,1-1.6,1.8-2.5,2.6c-1.2,1.1-2.8,1.8-3.7,3.1c-1.4,1.9-2.5,4.1-3.7,6.1c0.1,0.1,0.3,0.2,0.4,0.3c-0.7,0-1.5,0-2.2,0.1
		c-2.2,0.2-1.9-1.8-2.7-2.9c-1.1-1.6-2.3-3.1-3.1-4.8c-0.8-1.5-1.6-3.2-1.7-4.9c-0.1-1.1-0.1-1.8-1-2.4c-0.8-0.6-1-1.3-1.3-2.3
		c-0.2-1.1-1.7-2.1-2.9-2.6c-0.8-0.4-1.7-0.5-2.4-1.3c-0.6-0.7-1.9-1-3-1.2c-1.6-0.3-2.2-1-2.1-2.6
		C147.3,22.4,147.1,21.7,146.9,20.7z"
        />
        <path
          d="M194,241.1c0,1,0.3,2-0.1,2.6c-0.8,1.6-0.5,3.2-0.3,4.9c0.1,1.1,0,2.3,0,3.5c0,1.4,0.2,2.9,0,4.3c-0.3,2.2-1,4.1-3.3,5.3
		c-1.8,1-3.2,2.6-4.9,4c-0.1-0.1-0.6-0.3-1-0.5c0.1-0.5,0.3-1.1,0.1-1.4c-0.5-1,0-1.4,0.8-1.9c0.5-0.3,0.8-1.1,0.7-1.7
		c0-0.3-0.8-0.7-1.4-0.8c-0.5-0.2-1.1-0.3-1.7-0.2c-3.6,0.7-5.1-2-7.2-3.9c-1.8-1.6-3.5-3.5-6.3-3.5c-0.5,0-0.9-0.9-1.4-1
		c-1.2-0.4-2.5-0.6-3.8-0.9c-0.8-0.2-1.8-0.1-2.5-0.4c-2.1-0.8-4.2-0.7-6.4,0c0.1-1.9,0.1-3.7,0.3-5.5c0-0.3,1.3-0.7,2-0.6
		c2.7,0.4,5.4,0.9,8,1.4c1.8,0.3,3.6,0.6,5.3,1c0.4,0.1,0.8,0.6,1.3,1c0.9-0.6,2.8-0.6,2.1-2.7c0-0.1,0.9-0.9,1.3-0.8
		c1.3,0.2,2.2-0.1,3-1.1c0.2-0.3,0.9-0.5,1-0.4c0.8,1,1.6,0.5,2.3,0.1c1.2-0.6,2.1-0.5,3,0.5c1,1.1,2.8,1,4-0.1
		C190.4,241.2,191.9,241.2,194,241.1z"
        />
        <path
          d="M255.1,112.9c1.1-3.6-1.7-4.7-3.2-6.5c1.4-1.2,3.1-2.1,4.2-3.5c1.4-1.7-0.4-3.5-0.6-5.4c0.5-0.1,1-0.2,1.5-0.3
		c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.2,3.2,1.1,3.4,2.5c1.6,1.3,3.3,2.5,4.6,0.3c2.8,1.2,5.4,1.5,8.2-0.2c0.5-1.6,2.9-3,4.2-2.1
		c-0.7,1.5-1.4,3-1.9,4c0.6,0.7,1.1,1.1,1.3,1.6c0.6,1.3,1.5,1.3,2.9,1.1c1.9-0.3,2-2.3,3.5-2.7c0.9-0.2,1.8-0.3,2.7-0.6
		c1.6-0.6,3.8-0.2,4.7-2.3c0.5-1.1,2.1-1.1,3-0.3c0.3,0.3,0.7,0.5,1.6,0.8c-0.7,2.4-1.2,4.9-2.1,7.3c-0.8,2.3-0.9,2.3-2.4,1.4
		c-1.4,0.1-2.3,0.1-3.2,0.3c-1,0.3-2,0.7-2.8,1.4c-1.3,1-2.5,1.9-4.4,2c-1.7,0.1-2.9-0.4-4.2-1.5c-0.9-0.8-2.3-1.2-3.5-1.8
		c-1,1-2.1,2.1-3.4,3.4c-0.2-1.2,0.4-2.7-1.5-2.2c-0.4-2.5-2.8-0.6-3.9-1.7c-0.5-0.4-1.3-0.5-1.9-0.8c-2-1.3-2.9-1.1-4.6,0.6
		c-0.7,0.7-1.7,1-1.7,2.1c0,0.1-0.4,0.3-0.6,0.2c-1.4-0.1-2.2,0.3-2.4,1.8C257.3,111.8,256.1,112.2,255.1,112.9z"
        />
        <path
          d="M282.8,98.8c0.3-0.6,0.9-1.3,0.8-1.8c-0.1-0.9-2.2-1.5-3-1c-1.5,0.9-2.9,2-4.3,2.9c-0.5,0.3-1.1,0.6-1.5,0.6
		c-1.1-0.1-2.3-0.4-3.5-0.7c0.1-0.4,0.4-1,0.4-1.5c0-0.7-0.2-1.4-0.3-2.1c-0.1-0.8-1.2-1.5-0.1-2.5c0.5-0.5,0.2-1.8,0.3-3.1
		c2.9,1.7,4.8,0.8,6.6-1.2c0.7-0.8,2.2-0.9,3.2-1.2c-0.8,1.5-1.6,3-2.7,4.9c2.4,0.3,4.1,0.5,6,0.8c0.2,0.2,0.5,0.8,0.8,1.3
		c0.7-0.5,1.6-0.8,1.9-1.5c0.4-0.9,0.4-2,0.6-3.1c1.4,0.2,3.1,0.5,4.9,0.7c1.9,0.2,3.7,0.4,5.7,0.7c0.5,2,1,4.2,1.4,6.1
		c-1.7-0.1-3-0.3-4.3-0.3c-0.4,0-1,0.3-1.1,0.7c-0.9,2.1-2.7,2.2-4.6,2.1c-1.7-0.1-3.3,0.8-4.3,2.1c-0.6,0.8-1.5,1.3-2.3,2
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-1.6-0.3-3.1-0.4-4.7C282.5,98.7,282.6,98.7,282.8,98.8z"
        />
        <path
          d="M298.3,89.9c-1.1-0.3-1.9-0.6-2.7-0.7c-0.9-0.1-1.8,0.1-2.6,0.1c-1.4,0.1-2.8,0.1-3.9-1.2c-0.5-0.7-2.3,0-2.5,1.1
		c-0.2,1-0.2,2.1-0.3,2.8c-2-0.3-4-0.7-6.2-1.1c1-1.6,1.9-3.2,3-5c-2.6,0.2-4.7,0.8-6.5,2.1c-0.7,0.5-1.5,1-2.3,1.3
		c-0.6,0.2-1.6,0.3-1.5-1c0-0.2-0.4-0.4-0.6-0.7c3.3-1.5,4.1-4.9,5.5-7.9c0.9-1.9,2-2.3,4.2-1.7c3,0.8,5.9,2,9.2,1.1
		c2.4-0.6,4.7,1.4,5.4,3.9C297.1,85.3,297.6,87.3,298.3,89.9z"
        />
        <path
          d="M251.7,181.5c1.6-0.9,3.3-2.6,4.8-2.5c1.9,0.1,3.7,1.7,5.6,2.6c0.1,0,0,0.3,0,0.5c0,2.2,0.2,4.4,0,6.6
		c-0.1,1-0.8,2-1.4,2.9c-0.7,1.2-1.4,2.5-2.4,3.6c-1,1.1-0.7,2.9-2.5,3.8c-1.1,0.6-1.7,2.2-2.4,3.4c-0.4,0.7-0.7,1.3-1.8,1
		c-1.1-0.3-2.3-0.4-3.5-0.6c-0.2,0-0.5-0.2-0.5-0.4c-0.3-1.1-0.7-2.2-0.8-3.4c-0.2-2.4-0.1-2.4,2.2-2.7c0.4-0.1,0.9-0.3,1.1-0.7
		c1.1-1.8,2.3-3.5,3.2-5.4c0.5-1,1-2.2-0.3-3.2c-0.2-0.2-0.2-0.8-0.1-1.1C253.6,184.4,253.2,182.7,251.7,181.5z"
        />
        <path
          d="M237.6,214.8c-2.8,0.1-3.4,0.6-2.7,2.2c-1.2,0.2-2.1,0.7-3.4-0.3c-0.7-0.5-2.3,0.2-3.5,0.4c0-0.2,0-0.4,0-0.5
		c0.2-0.6,0.4-1.3,0.5-1.9c-0.8-0.2-1.6-0.4-2.4-0.6c-0.4-0.1-0.8-0.3-1.1-0.5c0.3-0.3,0.5-0.7,0.8-0.8c2.8-0.8,5.7-1.5,8.5-2.2
		c0.2,0,0.4-0.2,0.5-0.1c3.6,1.3,5.5-2.4,8.6-2.4c-0.5-2,0.7-4.9,2.8-6.9c0.7,1-0.2,2.8,1.9,2.9c1.3,0.1,2.5,0.5,3.7,0.7
		c0.1,1.2,0.6,2.8,0.3,4.2c-0.1,0.7-1.3,1.3-2.1,1.8c-1.4,0.9-3.1,1.5-4.4,2.5c-0.6,0.4-0.6,1.5-1,2.3c-0.2,0.5-0.6,1.2-0.9,1.2
		c-2.1,0.1-4.3,0.1-6.5,0.1C237.4,216.2,237.5,215.5,237.6,214.8z"
        />
        <path
          d="M281.1,111.8c1,0.4,1.8,0.7,2.9,1.1c0.5-0.2,1-1.2,2.2-0.3c0.5,0.4,2.8-0.6,3.3-1.5c1.8-3,4.6-1.6,6.9-2.3
		c0.1,0.2,0.3,0.3,0.4,0.5c-2.9,3.5-5.9,7-8.7,10.3c-4.5-2.6-8.9-5.1-13.4-7.7c1.1-1,2.2-2.2,4.2-2
		C280.1,110.1,281.1,110.6,281.1,111.8z"
        />
        <path
          d="M274.3,123.5c1.2-0.2,2.2-0.4,2.8-0.5c1.4-3.2,1.1-5.9-1.5-8.2c0.2-0.1,0.4-0.3,0.6-0.4c1.5,0.6,3,1,4.4,1.7
		c1.2,0.7,2.2,1.7,3.3,2.6c0.5,0.4,1,0.6,1.5,0.9c0.8,0.5,1.3,1.2,0.3,1.8c-2.5,1.3-3.7,3.7-5.1,5.9c-0.8,1.2-1.7,1.2-2.8,0.8
		c-0.8-0.3-1.5-0.6-1.5-1.7c0-0.5-0.8-1.1-1.3-1.6C274.9,124.3,274.6,123.9,274.3,123.5z"
        />
        <path
          d="M197.8,160c1.3,0,2.5,0,3.7,0c2,0,2.4,0.6,1.6,2.4c-0.1,0.3-0.2,0.6-0.3,1c-1.7,0-3.4,0-5.5,0
		C197.5,162.3,197.6,161.3,197.8,160z"
        />
        <path d="M61.8,114.3c-1.9,0.4-3.7,0.8-5.9,1.3C59.5,112.4,60,112.3,61.8,114.3z" />
      </g>
    </svg>
  );
};
export const BranchIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <g>
        <path
          d="M150,99.9c-37.3,0-74.6,0-111.9,0c-24.6,0-37.6-10.5-37.8-30.6C0,56,0,42.7,0.2,29.4C0.6,10.7,14,0,36.3,0
     C112-0.1,187.7-0.1,263.5,0c22.5,0,35.7,10.7,36.2,29.2c0.4,13.9,0.4,27.8,0,41.7c-0.5,18.4-14,28.9-36.6,28.9
     C225.4,99.9,187.7,99.9,150,99.9z"
        />
        <path
          d="M150.1,299.8c-6.9,0-13.9,0.2-20.8,0c-13.7-0.5-24.1-10.7-24.7-25.7c-0.7-16.9-0.7-34.1,0-51c0.6-14.7,10.9-25.1,24.1-25.4
     c14.1-0.4,28.3-0.3,42.4,0c13.5,0.3,23.8,10.8,24.4,26.2c0.7,16.6,0.7,33.4,0,50c-0.6,15.1-11,25.5-24.6,26
     C163.9,299.9,157,299.8,150.1,299.8z"
        />
        <path
          d="M45.8,299.8c-6.9,0-13.9,0.2-20.8,0c-14.2-0.5-24.6-12-24.8-27.8c-0.3-15.5-0.2-30.9,0-46.4c0.3-16.4,10.7-27.8,25.4-28
     c13.6-0.2,27.2-0.2,40.7,0c14.6,0.3,25,11.8,25.3,28.2c0.2,15.1,0.2,30.3,0,45.5c-0.2,16.8-10.7,28.2-25.9,28.5
     C59.1,299.8,52.5,299.8,45.8,299.8z"
        />
        <path
          d="M208.3,248.4c0-7.7-0.2-15.5,0-23.2c0.4-15.8,10.7-27.3,24.9-27.6c13.8-0.3,27.7-0.3,41.6,0c14.2,0.3,24.7,11.8,25,27.6
     s0.3,31.6,0,47.4c-0.4,15.3-10.7,26.8-24.5,27.2c-14.1,0.4-28.3,0.4-42.4,0c-14.1-0.4-24.2-12.2-24.5-28.1
     C208.1,263.9,208.3,256.1,208.3,248.4z"
        />
        <path
          d="M160.9,157.2c2.4-3,4.6-6.2,7.1-9c3.7-4,8.3-4.9,12.4-1.1c4.2,3.9,3.5,8.4-0.4,12.4c-7.8,7.9-15.6,15.7-23.5,23.5
     c-4.2,4.2-8.9,4.4-13.2,0.2c-7.9-7.7-15.7-15.6-23.5-23.5c-3.9-3.9-4.9-8.3-0.8-12.4c4-4,8.6-3.3,12.4,0.6c2.7,2.7,5.1,5.8,7.6,8.7
     c0.8-0.3,1.6-0.5,2.4-0.8c0-6.6,0-13.2,0-19.9c0-4.4,0.1-8.9,0-13.3c-0.2-5.9,1.3-10.9,8.2-11s8.6,4.7,8.5,10.7
     c-0.1,8.3,0,16.6,0,24.9c0,2.7,0,5.4,0,8.2C159.2,156.1,160.1,156.6,160.9,157.2z"
        />
      </g>
    </svg>
  );
};
export const CompanySubcidiaryIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      className="MuiSvgIcon-root"
      style={{ fontSize: 22, color: "#00000066" }}
    >
      <path
        d="M184.6,122.8c0-9.5,0-18.3,0-27.5c-23,0-45.6,0-68.8,0c0,9.1,0,18,0,27.6c-7.4,0-14.3,0-21.3,0c-9.5,0-19.2,0-28.7,0
	c-10,0-13.7-1.9-13.7-7.1c0-36.2,0-72.5,0-108.6c0-5.3,3.6-7.1,13.5-7.1C122,0,178.3,0,234.5,0c9.7,0,13.3,2,13.3,7.2
	c0,36.2,0,72.5,0,108.6c0,4.2-2.9,6.7-9.5,6.8C220.7,123,203,122.8,184.6,122.8z M107.8,26.9c0-4.8,0-9.3,0-13.9
	c-9.5,0-18.3,0-27.5,0c0,4.8,0,9.2,0,13.9C89.5,26.9,98.5,26.9,107.8,26.9z M136.3,12.9c0,4.9,0,9.4,0,13.9c9.5,0,18.5,0,27.3,0
	c0-4.9,0-9.3,0-13.9C154.5,12.9,145.7,12.9,136.3,12.9z M192.2,27.2c8.3,0,16.1,0.1,23.8-0.1c1.4,0,3.8-1,3.8-1.6
	c0.3-4.1,0.2-8.3,0.2-12.5c-9.8,0-18.7,0-27.8,0C192.2,17.7,192.2,22.3,192.2,27.2z M107.8,55.4c0-4.7,0-9.2,0-13.9
	c-9.3,0-18.3,0-27.6,0c0,4.8,0,9.1,0,13.9C89.5,55.4,98.5,55.4,107.8,55.4z M136.3,41.4c0,4.9,0,9.4,0,13.9c9.5,0,18.5,0,27.3,0
	c0-4.8,0-9.2,0-13.9C154.5,41.4,145.7,41.4,136.3,41.4z M220.2,55.4c0-4.2,0.2-8.2-0.2-12c0-0.7-1.9-2-3.1-2
	c-8.3-0.2-16.4-0.1-24.7-0.1c0,5.1,0,9.5,0,14.1C201.5,55.4,210.5,55.4,220.2,55.4z M107.8,84c0-5,0-9.4,0-14c-9.3,0-18.3,0-27.5,0
	c0,4.8,0,9.3,0,14C89.7,84,98.5,84,107.8,84z M163.8,70c-9.5,0-18.3,0-27.5,0c0,4.8,0,9.3,0,14c9.3,0,18.3,0,27.5,0
	C163.8,79.2,163.8,74.9,163.8,70z M219.8,84c0-4.9,0-9.3,0-14c-9.3,0-18.3,0-27.5,0c0,4.8,0,9.3,0,14C201.7,84,210.3,84,219.8,84z"
      />
      <path
        d="M49.3,299.9c0-5.7,0-10.8,0-16.3c-7,0-13.6,0-20.7,0c0,5.2,0,10.5,0,16.2c-7.8,0-15.1,0.2-22.4-0.1
	c-3.9-0.1-6.1-2.9-6.2-6.9c-0.1-6.7,0-13.5,0-20.2c0-17.3,0-34.6,0-51.9c0-7.6,1.9-9.1,10.2-9.1c19.5,0,39,0,58.6,0
	c6.7,0,8.9,2,8.9,8c0,24.3,0,48.7,0,72.9c0,5-2.6,7.3-8.2,7.4C62.9,299.9,56.5,299.9,49.3,299.9z M13.9,255.9c0,4.5-0.1,8.7,0.1,13
	c0,0.7,1.5,2,2.3,2c4.4,0.2,8.9,0.1,13.5,0.1c0-5.3,0-10.2,0-15.1C24.4,255.9,19.4,255.9,13.9,255.9z M14.2,226.8c0,5.2,0,10,0,14.7
	c5.4,0,10.5,0,15.6,0c0-5.1,0-9.9,0-14.7C24.4,226.8,19.4,226.8,14.2,226.8z M47.9,226.9c0,5,0,9.9,0,14.8c5.4,0,10.5,0,15.7,0
	c0-5,0-9.8,0-14.8C58.4,226.9,53.3,226.9,47.9,226.9z M63.5,270.8c0-5.2,0-10.1,0-14.9c-5.4,0-10.4,0-15.4,0c0,5.1,0,10,0,14.9
	C53.3,270.8,58.1,270.8,63.5,270.8z"
      />
      <path
        d="M160.3,299.9c0-5.8,0-10.9,0-16.3c-7,0-13.6,0-20.7,0c0,5.3,0,10.6,0,16.3c-7.7,0-14.9,0.2-22.1-0.1
	c-4.2-0.2-6.4-2.9-6.4-7.4c-0.1-16.3,0-32.5-0.1-48.8c0-8.1-0.1-16.2,0-24.2c0.1-5.8,2.3-7.8,8.6-7.9c20.3,0,40.5,0,60.8,0
	c5.7,0,8.4,2.2,8.4,7.3c0.1,24.5,0,49.2,0,73.7c0,4.8-2.7,7.2-7.9,7.2C174.2,299.9,167.6,299.9,160.3,299.9z M141.1,255.9
	c-5.5,0-10.5,0-16.1,0c0,4.5-0.1,8.8,0.1,13c0,0.6,0.9,1.9,1.6,1.9c4.8,0.1,9.6,0.1,14.5,0.1C141.1,265.5,141.1,260.9,141.1,255.9z
	 M125.3,226.8c0,5.1,0,9.9,0,14.9c5.3,0,10.4,0,15.7,0c0-5.1,0-10,0-14.9C135.6,226.8,130.7,226.8,125.3,226.8z M159,226.8
	c0,5.1,0,9.8,0,14.7c5.4,0,10.6,0,15.8,0c0-5.1,0-9.9,0-14.7C169.3,226.8,164.3,226.8,159,226.8z M174.6,270.8c0-5.2,0-10.1,0-14.9
	c-5.4,0-10.4,0-15.4,0c0,5.1,0,10,0,14.9C164.4,270.8,169.3,270.8,174.6,270.8z"
      />
      <path
        d="M271.6,299.9c0-5.7,0-10.8,0-16.3c-7,0-13.6,0-20.7,0c0,5.1,0,10.4,0,16c-7.9,0-15.4,0.3-22.9-0.1c-4.2-0.3-5.8-3.4-5.7-7.1
	c0-8,0-15.8,0-23.8c0-16.2,0-32.2,0-48.4c0-6.9,2-8.8,9.7-8.8c19.6,0,39.4,0,59,0c6.7,0,9,2,9,7.9c0,24.3,0,48.7,0,73
	c0,5-2.6,7.4-8.2,7.4C285.3,299.9,278.8,299.9,271.6,299.9z M252.1,270.9c0-5.5,0-10.2,0-15c-5.3,0-10.4,0-16,0
	c0,4.5-0.1,8.8,0.1,13c0,0.6,1.3,1.9,2,1.9C242.7,271,247.3,270.9,252.1,270.9z M252.1,241.7c0-5.2,0-10,0-14.7
	c-5.4,0-10.5,0-15.6,0c0,5,0,9.8,0,14.7C241.8,241.7,246.8,241.7,252.1,241.7z M270.1,241.6c5.2,0,10.3,0,15.7,0c0-5.1,0-10,0-14.8
	c-5.3,0-10.3,0-15.7,0C270.1,231.9,270.1,236.6,270.1,241.6z M270.3,255.9c0,5,0,10,0,14.7c5.4,0,10.5,0,15.6,0c0-5.1,0-9.9,0-14.7
	C280.4,255.9,275.5,255.9,270.3,255.9z"
      />
      <path
        d="M49.6,161.9c0,12.8,0,25.3,0,37.9c-4.1,0-7.8,0-12,0c-0.1-1.3-0.2-2.7-0.2-4c0-12.6,0-25.3,0-37.9c0-5.7,1.8-7.3,8.1-7.3
	c30.9,0,61.8,0,92.7,0c1.6,0,3.3,0,5.5,0c0-8.9,0-17.5,0-26.3c4.3,0,8,0,12.1,0c0.1,1.4,0.2,2.7,0.2,4c0,6.3,0.1,12.7,0,19
	c-0.1,2.6,0.8,3.4,3.8,3.4c13.2-0.1,26.3,0,39.5,0c18.4,0,36.9,0,55.3,0c6.1,0,7.9,1.6,7.9,7.1c0,12.8,0,25.5,0,38.3
	c0,1.2-0.1,2.4-0.2,3.8c-3.9,0-7.6,0-11.9,0c0-12.6,0-25.2,0-38c-31.7,0-62.8,0-94.3,0c0,12.7,0,25.2,0,37.9c-4.2,0-8,0-12.2,0
	c0-12.6,0-25,0-37.8C112.4,161.9,81.4,161.9,49.6,161.9z"
      />
    </svg>
  );
};
