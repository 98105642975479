import * as React from "react";
import { Create } from "react-admin";

import CommonForm from "../unknownAttendance/commonForm";

const AttendanceCreate = (props: any) => {

  return (
    <Create {...props}>
      <CommonForm redirect={'/unknown-attendance'} {...props} />
    </Create>
  );
};

export default AttendanceCreate;
