import React, { useState, FC, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  useTranslate,
  Button,
  useNotify,
  SelectInput,
  SimpleForm,
  useDataProvider,
  required,
  FormDataConsumer,
  LinearProgress,
  TextInput,
  DateTimeInput,
  BooleanInput,
} from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  useMediaQuery,
  Theme,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import DialogueBox from "../components/ConfirmDialogueBox";
import { toastError, toastInfo } from "../components/toast";
import { stringify } from "querystring";
import helperFunc from "../helperFuncs";
import moment from "moment";
import SearchableEmployeeSelectInput from "../components/SearchableEmployeeSelectInput";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
    marginRight: "8px",
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableHeadCenter: { padding: 10, textAlign: "center" },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  tableFlexWidth: {
    display: "flex",
    width: "350px",
    height: "65px",
    alignItems: "center",
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  editAllotmentBtn: {
    fontSize: 12,
    border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
  },
  disFlexCenter: {
    display: "flex",
    alignItems: "center",
  },
});

const QRAllotment: FC<FieldProps & { open: any; setOpen: any }> = ({
  record,
  type,
  open,
  setOpen,
}) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const dataProvider = useDataProvider();
  // const [open, setOpen] = useState(false);
  const [allotments, setAllotments] = useState(Array);

  const [noOfAllotments, setNoOfAllotments] = useState(0);
  const [isLoad, setLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [allotmentDialog, setAllotmentDialog] = useState({});
  const [QRId, setQRId] = useState();

  const fromDate = new Date();
  fromDate.setMinutes(fromDate.getMinutes() + 1);

  const newToDate = new Date();
  newToDate.setMinutes(newToDate.getMinutes() + 1);

  const toDate = moment(newToDate);
  toDate.add(3, "h");

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, allotment: any) => {
    setShowDialog(true);
    setIndex(index);
    setAllotmentDialog(allotment);
  };

  const handleClick = () => {
    setOpen(true);
    if (record && record.id) {
      let url = `visitor-qr-code/list`;
      dataProvider
        .callCustom(url, {
          filter: { employeeId: record?.id ? record.id : "" },
        })
        .then((response: any) => {
          if (response && response.data && response.data.allotments) {
            setAllotments(response.data.allotments);
            setNoOfAllotments(response?.data?.item?.noOfAllotments);
          }
        });
    }
  };

  const removeAllotment = (idx: any) => {
    let rData: any;

    rData = allotments.filter((s: any, sIdx: any) => idx === sIdx);

    let url;
    if (rData && rData.length > 0) {
      url = `visitor-qr-code/delete/${rData[0]._id ? rData[0]._id : QRId}`;
      dataProvider
        .callCustom(url, {})
        .then((response: any) => {
          toastInfo("QR removed successfully!");
          handleClick();
          setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
        })
        .catch((err: any) => {
          setLoader(false);
          toastError(err.message);
          return;
        });
    }
  };

  const handleViewQR = (idx: any) => {
    let rData: any;

    rData = allotments.filter((s: any, sIdx: any) => idx === sIdx);

    let url;
    if (rData && rData.length > 0) {
      url = `/q/${rData[0]._id ? rData[0]._id : QRId}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    if (record && record.id) {
      let url = `visitor-qr-code/list`;

      dataProvider
        .callCustom(url, {
          filter: { employeeId: record?.id ? record.id : "" },
        })
        .then((response: any) => {
          if (response && response.data && response.data.list) {
            setAllotments(response.data.list);
            setNoOfAllotments(response?.data?.list?.length);
          }
        });
    }
  }, [record, open]);
  const handleDialogClose = () => setOpen(false);

  const addAllotment = (data: any) => {
    let url = `visitor-qr-code/create`;

    if (data && (data.fromDate === "" || data.fromDate === undefined)) {
      toastError("Please select From Date");
      return;
    } else if (data && new Date(data.fromDate) < new Date()) {
      toastError("From Date must be  greater than today's Date");
      return;
    } else if (data && (data.toDate === "" || data.toDate === undefined)) {
      toastError("Please select To Date");
      return;
    } else if (data.toDate && new Date(data.fromDate) > new Date(data.toDate)) {
      toastError("To Date must be  greater than from Date");
      return;
    } else {
      setAllotments([
        ...allotments,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
        },
      ]);

      dataProvider
        .callCustom(url, {
          fromDate: data.fromDate,
          toDate: data.toDate,
          childCompanyId: record?.childCompanyId?._id,
          company: record?.company?._id,
          employee: record?.id ? record.id : "",
        })
        .then((response: any) => {
          setQRId(response?.data?.item?._id);
        });

      data.fromDate = "";
      data.toDate = "";
    }
  };

  let sortedArray = allotments
    .sort(
      (a: any, b: any) =>
        moment(a.fromDate).valueOf() - moment(b.fromDate).valueOf()
    )
    .reverse();

  return record ? (
    <div className={classes.root}>
      {(type && type === "list") || isXsmall ? (
        <span className={classes.noOfAllotments} onClick={handleClick}>
          {" "}
          {`Generate QR (${
            allotments && allotments.length > 0
              ? allotments.length
              : noOfAllotments
          })`}
        </span>
      ) : null}

      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Generate QR"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>Generate QR for {record.name}</DialogTitle>

        <DialogContent>
          <SimpleForm validate={() => true} toolbar={null}>
            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <DateTimeInput
                  source="fromDate"
                  resource="visitor-qr-code"
                  formClassName={classes.name}
                  defaultValue={fromDate}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <DateTimeInput
                  source="toDate"
                  resource="visitor-qr-code"
                  formClassName={classes.employee}
                  defaultValue={toDate}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <MaterialButton
                  onClick={() => addAllotment(formData)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="button"
                  className={isXsmall ? undefined : classes.allotmentBtn}
                >
                  Generate
                </MaterialButton>
              )}
            </FormDataConsumer>
            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      From Date
                    </TableCell>

                    <TableCell className={classes.tableHead}>To Date</TableCell>

                    <TableCell className={classes.tableHeadCenter}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allotments && allotments.length > 0
                    ? allotments.map((allotment: any, allotIdx: number) => {
                        return (
                          <TableRow key={allotIdx + 1}>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment?.fromDate
                                ? helperFunc.commonDateFormat(
                                    allotment.fromDate,
                                    "HH:mm"
                                  )
                                : "N/A"}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment?.toDate
                                ? helperFunc.commonDateFormat(
                                    allotment.toDate,
                                    "HH:mm"
                                  )
                                : "N/A"}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="center"
                            >
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(allotIdx, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                              <MaterialButton
                                onClick={() => handleViewQR(allotIdx)}
                                variant="outlined"
                                className={classes.editAllotmentBtn}
                                type="button"
                              >
                                View QR
                              </MaterialButton>

                              {/* <MaterialButton
                                onClick={() => handleIsApproved(allotIdx)}
                                variant="outlined"
                                className={classes.editAllotmentBtn}
                                type="button"
                                disabled={
                                  allotment?.isApprovedRequired ? false : true
                                }
                              >
                                Approve
                              </MaterialButton> */}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}

                  {allotments && allotments.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={3}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(index)}
              itemName={allotmentDialog}
            />
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? <LinearProgress /> : ""}
          <Button
            label="Close"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default QRAllotment;
