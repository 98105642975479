import React, { useState, useEffect } from "react";
import { useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import { attendanceExtraDuty } from "../helperStyle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useMediaQuery,
  Theme,
  Box,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import helperFunc from "../helperFuncs";
import authProvider from "../authProvider";
import ClearIcon from "@material-ui/icons/Clear";
import { toastError, toastInfo } from "../components/toast";

const BiometricReport = (props: any) => {
  interface res {
    list: any;
    datesData: any;
    filter: any;
    type: any;
  }
  interface Hres {
    childCompanyId: any;
    clientId: any;
    clientLocationId: any;
    company: any;
    employee: any;
    state: any;
    item: any;
  }
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  const useStyles = makeStyles(attendanceExtraDuty);
  const refresh = useRefresh();
  const user = helperFunc.getUserData();
  const dataProvider = useDataProvider();
  // const notify = useNotify();
  const classes = useStyles();
  const [loading, setLoading] = useState<any>(false);
  const [sendrequest, setSendRequest] = useState<any>(false);
  const [downloadLoading, setDownloadLoading] = useState<any>(false);
  const [sendApprovalLoading, setSendApprovalLoading] = useState<any>(false);
  const [responseData, setResponseData] = useState<res>({
    list: [],
    datesData: [],
    filter: {},
    type: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [downloadTypes, setDownloadTypes] = useState<any>({ downloadType: "" });
  const [headerData, setHeaderData] = useState<Hres>({
    childCompanyId: {},
    clientId: {},
    clientLocationId: {},
    company: {},
    employee: {},
    state: {},
    item: {},
  });

  const handleChange = (
    rowIndex: number,
    date: number,
    data: any,
    str: string,
    mainStr: any
  ) => {
    let editdataResponse: any = { ...responseData };
    editdataResponse.list[rowIndex][date + str] = data;
    if (mainStr) {
      editdataResponse.list[rowIndex][date + mainStr] = data;
    }
    editdataResponse.list[rowIndex]["isChanged"] = true;
    setResponseData(editdataResponse);
    // setData(() => setResponseData(editdataResponse));
  };

  const params = {
    id: props.id,
  };
  // useEffect(() => {
  //   dataProvider
  //     .getOne("attendence-approval", params)
  //     .then((response: any) => {
  //       setHeaderData(response.allData);
  //       setResponseData(response.data.reportData);
  //     })
  //     .catch((err: any) => {
  //       toastError(err.message);
  //     });
  // }, [sendrequest]);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  // React.useEffect(() => {
  //   if ((!isSmall || !isXSmall) && container) {
  //     container.innerText = "";
  //     container.innerText =
  //       authProvider.isMenuShow("attendenceApproval1", "update") ||
  //       authProvider.isMenuShow("attendenceApproval1", "create")
  //         ? headerData.item.isApproved === "Pending"
  //           ? "Edit Attendance Approval - 1"
  //           : "View Attendance Approval - 1"
  //         : "View Attendance Approval - 1";
  //   }
  //   return function cleanup() {
  //     if ((isSmall || isXSmall) && container) {
  //       container.innerText = "";
  //     }
  //   };
  // }, [container, headerData]);

  const handleClick = (rowIndex: number, data: any, str: any) => {
    if (data === "p" || data === "h") {
      data = data.toUpperCase();
    }
    let editdataResponse: any = { ...responseData };
    if (editdataResponse.list[rowIndex][str] !== undefined) {
      editdataResponse.list[rowIndex][str] = data;
      setResponseData(editdataResponse);
    } else {
      editdataResponse.list[rowIndex][str.toString()] = data;
      setResponseData(editdataResponse);
    }
  };
  const saveEditData = () => {
    responseData.list.forEach((el: any) => {
      if (el.isChanged) {
        responseData.datesData.forEach((date: any) => {
          helperFunc.timeToIsoTime(el, date);
        });
      }
    });

    setLoading(true);
    let payload: any = { reportData: responseData };
    setTimeout(function () {
      dataProvider
        .callCustom(`attendence-approval/update/${props.id}`, payload)
        .then((response: any) => {
          setLoading(false);
          toastInfo("updated successfully");
          // notify("updated successfully");
          setSendRequest((previousSendrequest: any) => !previousSendrequest);
          // refresh();
        })
        .catch((err: any) => {
          toastError(err.message);
        });
    }, 1000);
  };

  const exporterDetails = (value: any) => {
    setDownloadLoading(true);
    setDownloadTypes({
      ...downloadTypes,
      downloadType: value,
    });
    setDownloadLoading(false);
  };

  const handleClickMouse = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dataProvider
      .callCustom("ced-biometrics/list", {})
      .then((response: any) => {
        setResponseData(response?.data);
        // window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, []);

  // const handleSendApproval = () => {
  //   setSendApprovalLoading(true);
  //   let payload = {
  //     isInternalApproved: true,
  //   };
  //   dataProvider
  //     .callCustom(`attendence-approval/update/${headerData.item._id}`, payload)
  //     .then((response: any) => {
  //       setSendApprovalLoading(false);
  //       setSendRequest((previousSendrequest: any) => !previousSendrequest);
  //       toastInfo("Send For Approval successfully");
  //       // notify('Send For Approval successfully')
  //     })
  //     .catch((err: any) => {
  //       toastError(err.message);
  //       // notify(err.message, 'warning');
  //     });
  // };

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  return (
    <>
      {isXSmall ? (
        <h2
          style={{
            height: 40,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 15,
          }}
        >
          {authProvider.isMenuShow("attendenceApproval1", "update") ||
          authProvider.isMenuShow("attendenceApproval1", "create")
            ? headerData.item.isApproved === "Pending"
              ? "Edit Attendance Approval - 1"
              : "View Attendance Approval - 1"
            : "View Attendance Approval - 1"}
        </h2>
      ) : null}
      <div style={isXSmall ? { width: "100vh", overflow: "scroll" } : {}}>
        <div
          style={isXSmall ? { overflow: "scroll", width: "200vh" } : {}}
          className="container-fluid"
        >
          <div style={{ marginBottom: 20, marginTop: 5 }} className="row">
            {/* <div className="col-6">
              <table
                width={isXSmall ? window.screen.width : window.screen.width / 4}
                className={classes.table_first}
              >
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>Name And Address Of Contractor</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.company && headerData.company.name
                        ? headerData.company.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                {headerData !== undefined &&
                headerData.childCompanyId &&
                headerData.childCompanyId.licenseNumber ? (
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span>LIN&nbsp;</span>
                    </td>
                    <td className={classes.table_first_cell}>
                      <span>
                        {headerData.childCompanyId &&
                        headerData.childCompanyId.licenseNumber
                          ? headerData.childCompanyId.licenseNumber
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {headerData !== undefined &&
                headerData.childCompanyId &&
                headerData.childCompanyId.natureOfWork ? (
                  <tr>
                    <td className={classes.table_first_cell}>
                      <span>Nature Of Work</span>&nbsp;
                    </td>
                    <td className={classes.table_first_cell}>
                      <span>
                        {headerData.childCompanyId &&
                        headerData.childCompanyId.natureOfWork
                          ? headerData.childCompanyId.natureOfWork
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </table>
            </div> */}
          </div>

          <div
            style={
              isXSmall
                ? {
                    width: "150vh",
                    paddingRight: window.screen.width >= 375 ? 60 : 0,
                    maxWidth: "150vh",
                    overflow: "scroll",
                  }
                : {}
            }
            className="finalreport"
          >
            <TableContainer
              className={
                open ? classes.container : classes.closeSideBarcontainer
              }
            >
              <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        // left: 0,
                        position: "sticky",
                        width: "200px !important",
                        minWidth: "200px !important",
                        // wordBreak: "break-word",
                        background: "#e6e6e6",
                        zIndex: 999,
                        textAlign: "center",
                      }}
                      className={classes.header}
                    >
                      Training Partner Id
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 99,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        // wordBreak: "break-word",
                        background: "#e6e6e6",
                        textAlign: "center",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Batch Id
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 199,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        // wordBreak: "break-word",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Training Partner Name
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 299,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      BatchName
                    </TableCell>
                    {/* <TableCell
                      style={{
                        // left: 398,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 498,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Password
                    </TableCell> */}
                    <TableCell
                      style={{
                        // left: 598,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Punch Date
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 698,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Punch Time
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 798,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Create User ID
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 898,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Batch No
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 998,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Course Id
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 1098,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 1198,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Trainee ID
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 1298,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Trainee Name
                    </TableCell>
                    <TableCell
                      style={{
                        // left: 1398,
                        position: "sticky",
                        width: "100px !important",
                        minWidth: "100px !important",
                        background: "#e6e6e6",
                        zIndex: 999,
                      }}
                      className={classes.header}
                    >
                      Enrollment No
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseData &&
                    responseData.list &&
                    responseData.list.map(
                      (employeeData: any, rowIndex: number) => {
                        return (
                          <TableRow>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.TrainingPartnerID}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.BatchID}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.TrainingPartnerName}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.BatchName}
                            </TableCell>

                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <input
                                type="date"
                                // disabled={checkViewMode}
                                defaultValue={
                                  employeeData &&
                                  new Date(employeeData["PunchDate"])
                                    .toISOString()
                                    .split("T")[0]
                                }
                                // value={employeeData[date + "_inTimesCopy"]}
                                onChange={(e: any) => {
                                  handleChange(
                                    rowIndex,
                                    10,
                                    e.target.value,
                                    "_inTimesCopy",
                                    "_inTimes"
                                  );
                                }}
                                style={{
                                  width: "100px",
                                  // border: checkViewMode ? "none" : "",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <input
                                type="time"
                                // disabled={checkViewMode}
                                defaultValue={helperFunc.timeWithAmPm(
                                  employeeData["PunchTime"]
                                )}
                                // value={employeeData["PunchTime"]}
                                onChange={(e: any) => {
                                  handleChange(
                                    rowIndex,
                                    10,
                                    e.target.value,
                                    "_outTimesCopy",
                                    "_outTimes"
                                  );
                                }}
                                style={{
                                  width: "65px",
                                  // border: checkViewMode ? "none" : "",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.CreateUserID}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.BatchNo}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.Courseid}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.CourseName}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.TraineeID}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.TraineeName}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {employeeData?.EnrollmentNo}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  {responseData &&
                    ((responseData.list && responseData.list.length === 0) ||
                      !responseData.list) && (
                      <TableRow
                        className={classes.table}
                        style={{
                          textAlign: "center",
                          border: "solid 1px lightgrey",
                          borderRight: "solid 1px lightgrey",
                        }}
                      >
                        <TableCell colSpan={13} style={{ textAlign: "center" }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <div className="row">
            <div className={isXSmall ? "col-3" : "col-6"}>
              <table
                style={{ marginBottom: isXSmall ? 20 : 100 }}
                className={classes.table_first}
              >
                <tr>
                  <td>
                    <span>FOR OFFICE USE ONLY </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>PREPARED BY</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item.generatedBy &&
                      headerData.item.generatedBy.name
                        ? headerData.item.generatedBy.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.table_first_cell}>
                    <span>CHECKED AND AUTHORISED BY &nbsp;</span>
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData.item &&
                      headerData.item.approvedby &&
                      headerData.item.approvedby.name
                        ? headerData.item.approvedby.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className={classes.table_first_cell}>
                    <span>BRANCH </span>&nbsp;
                  </td>
                  <td className={classes.table_first_cell}>
                    <span>
                      {headerData &&
                      headerData.clientLocationId &&
                      headerData.clientLocationId.branchId &&
                      headerData.clientLocationId.branchId.name
                        ? headerData.clientLocationId.branchId.name
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div> */}
          <div
            style={{ margin: isXSmall ? 10 : 0, display: "block" }}
            className={isXSmall ? "" : "stickBottm"}
          >
            <Box component="div" className={classes.flexColTop}>
              <Box component="div" className={classes.flexColTop50Left}>
                <Box>
                  {(authProvider.isMenuShow("attendenceApproval1", "update") ||
                    authProvider.isMenuShow("attendenceApproval1", "create")) &&
                  headerData.item.isInternalApproved === false ? (
                    <Button
                      style={{ color: loading ? "black" : "white" }}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                      component="span"
                      onClick={() => saveEditData()}
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      SUBMIT WITH MANUAL CHANGE
                    </Button>
                  ) : null}
                </Box>
                <div>
                  <Button
                    style={{ color: downloadLoading ? "blac" : "white" }}
                    className={classes.downloadButton}
                    variant="contained"
                    color="primary"
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    // onClick={() => exporterDetails("xlsx")}
                    onMouseOver={handleClickMouse}
                    disabled={downloadLoading}
                  >
                    {downloadLoading && (
                      <CircularProgress size={25} thickness={2} />
                    )}
                    Download
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                  >
                    <MenuItem
                      onClick={() => {
                        exporterDetails("xlsx");
                      }}
                    >
                      Download as Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        exporterDetails("pdf");
                      }}
                    >
                      Download as PDF
                    </MenuItem>
                  </Menu>
                </div>
                <Box className={classes.mleft32}>
                  {(authProvider.isMenuShow("attendenceApproval1", "update") ||
                    authProvider.isMenuShow("attendenceApproval1", "create")) &&
                  headerData.item.isInternalApproved === false ? (
                    <Button
                      style={{ color: sendApprovalLoading ? "black" : "white" }}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton2}
                      component="span"
                      // onClick={() => handleSendApproval()}
                      disabled={sendApprovalLoading}
                    >
                      {sendApprovalLoading && (
                        <CircularProgress size={15} thickness={2} />
                      )}
                      SEND FOR INTERNAL APPROVAL
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default BiometricReport;
