import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import { FieldProps, Country } from "../types";
import FullNameField from "./FullNameField";

const LinkField: FC<FieldProps<Country>> = (props) =>
  props.record ? (
    <Link to={`/${props.resource}/${props?.record?.employee}`}>
      <FullNameField {...props} record={props.record} />
    </Link>
  ) : null;

LinkField.defaultProps = {
  source: "name",
  label: "Visitor Name",
  addLabel: true,
};

export default LinkField;
