import * as React from "react";
import { useState, useEffect } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useTranslate,
  Button,
  useRefresh,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  useDataProvider,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateInput,
} from "react-admin";
import BuildIcon from "@material-ui/icons/Build";
import moment from "moment";
import helperFunc from "../helperFuncs";
import { Device } from "../types";
// import { DateInput } from 'react-admin-date-inputs2';
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, useMediaQuery, Theme } from "@material-ui/core";
import MultiSelectEmployeeInput from "../components/MultiSelectEmployeeInput";
import { toastError, toastInfo } from "../components/toast";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  name: { display: "inline-block" },
  form: { alignContent: "center" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  iconCancelColor: {
    color: "#181818cf",
  },
});

interface State {
  device?: string;
  isLocal?: boolean;
  videoUrl?: string;
  rfid?: string;
  deviceData?: Device;
  employeeData?: {};
  company?: string;
  employees?: [];
  date?: [];
  employeesReport?: [];
}

const RegenrationReport = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const user = helperFunc.getUserData();
  // const notify = useNotify();
  const [state, setState] = useState<State>({});
  const [value, setValue] = useState<State>();
  //   const [employeesReport, setEmployeesReport] = useState();
  const apiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL;

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const empSearchUrl = "employees/search";
  useEffect(() => {
    dataProvider.getSearchList(empSearchUrl).then((response: any) => {
      setState((state) => ({
        ...state,
        employeesReport: response && response.data,
      }));
    });
  }, []);

  const { employeesReport } = state;

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    const { company, employees, date } = state;
    if (user && user.type == "super" && !company) {
      toastError("Company is Required!!!");
      // notify("Company is Required!!!", "warning");
    } else if (!employees) {
      toastError("Employee is Required!!!");
      // notify("Employee is Required!!!", "warning");
    } else if (!value) {
      toastError("Date is Required!!!");
      // notify("Date is Required!!!", "warning");
    } else {
      dataProvider
        .callCustomLocal(apiUrl + "/attendance-report/regenerate", {
          company: company,
          employees: employees,
          dates: value,
        })
        .then((response: any) => {
          if (response.data && response.data.success === true) {
            setOpen(false);
            toastInfo("Report Has been regenerated successfully.");
            // notify("Report Has been regenerated successfully.");
            refresh();
          }
        })
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
        });
    }
  };

  const HandleOnChange = (company: any) => {
    setState((state) => ({
      ...state,
      company: company,
    }));
  };

  const handleEmpChnage = (emp: any) => {
    setState((state) => ({
      ...state,
      employees: emp,
    }));
  };

  const handleDateChnage = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Button
        style={{
          padding: isXSmall ? "0px 2px 0px 0px" : "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        label="Regenerate"
        color="primary"
        component="span"
        onClick={handleClick}
      >
        <BuildIcon
          style={{
            height: "16px",
            marginRight: isXSmall ? "0em" : "0.2em",
            margin: isXSmall ? "1em" : "0em",
          }}
        />
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Registration"
        style={{ zIndex: 1 }}
      >
        <DialogTitle>Regenerate Report</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you wish to Regenerate Report for these Employees ?
          </Typography>

          <SimpleForm
            // validate={validateField}
            // formClassName={classes.form}
            // We override the redux-form name to avoid collision with the react-admin main form
            // form="post-quick-create"
            // We override the redux-form onSubmit prop to handle the submission ourselves
            // onSubmit={handleConfirm}
            // onSubmit={() => handleConfirm(record)}
            // We want no toolbar at all as we have our modal actions
            toolbar={null}
          >
            <div style={{ display: "flex" }}>
              {user && user.type === "super" ? (
                <ReferenceInput
                  source="company"
                  reference="companies"
                  validate={requiredValidate}
                  formClassName={classes.suggestions}
                  // helperText={false}
                  // filter={{ company: record.company, isMaster: true }}
                  onChange={HandleOnChange}
                >
                  <AutocompleteInput
                    formClassName={classes.suggestions}
                    optionText={(choice: any) => `${choice.name}`}
                    optionValue="_id"
                  />
                </ReferenceInput>
              ) : null}
              <div
                style={{
                  marginLeft: user && user.type === "super" ? "5px" : "0px",
                }}
              >
                <DateInput
                  // defaultValue={helperFunc.startOfMonth(0)}
                  // validate={[required(), checkFromDate]}
                  source="date"
                  label="label.date"
                  // alwaysOn
                  helperText={false}
                  options={{
                    format: helperFunc.datePickerFormat(),
                    clearable: true,
                  }}
                  value={value}
                  onChange={(e: any) => handleDateChnage(e)}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                />
              </div>
            </div>
            {/* <ReferenceArrayInput
                            resettable
                            label="Employees"
                            source="employee"
                            reference="employees"
                            filter={{ company: company, isActive: true }}
                            perPage={50000}
                            // helperText={false}
                            validate={requiredValidate}
                            onChange={handleEmpChnage}
                        >
                            <AutocompleteArrayInput resettable />
                        </ReferenceArrayInput> */}
            {/* <MultiSelectEmployeeInput parentCallback={handleEmpChnage} /> */}

            <AutocompleteArrayInput
              resettable
              label="Employees"
              source="employees"
              choices={employeesReport}
              // validate={requiredValidate}
              // className={formClassName}
              className={classes.root}
              onChange={handleEmpChnage}
              // fullWidth={isXsmall ? false : true}
            />

            {/* </div> */}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={handleConfirm}
          >
            <CheckCircleIcon />
          </Button>
          <Button
            label="Cancel"
            onClick={handleDialogClose}
            className={classes.iconCancelColor}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const requiredValidate = [required()];

export default RegenrationReport;
