import * as React from "react";
import { EditButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";

const AttendanceEditButton = (props: any) => {
  const useStyles = makeStyles((theme) => ({
    blueButton: {
      color: "blue",
    },
  }));

  const classes = useStyles();
  const user = helperFunc.getUserData();

  return (
    <>
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <EditButton
          label=""
          className={classes.blueButton}
          basePath={`/attendance/${props?.record?._id}`}
          record={props?.record}
        />
      ) : null}
    </>
  );
};

AttendanceEditButton.defaultProps = {
  source: "Action",
};

export default AttendanceEditButton;
