import React, { useEffect } from "react";
import {
  useDataProvider,
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  Button,
  useRedirect,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";
import { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import {
  useMediaQuery,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button as MaterialButton,
} from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import helperFunc from "../helperFuncs";
import ImportVideoButton from "../employees/videoImport";
import CloseIcon from "@material-ui/icons/Close";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { toastError } from "../components/toast";
import StudentSelectInput from "../components/StudentSelectInput";
import StreamSelectInput from "../components/StreamSelectInput";
import { styleData } from "../helperConst";
import CourseSelectInput from "../components/CourseSelectInput";
import { SectionTitle } from "../helperValidate";
import SemesterSelectInput from "../components/SemesterSelectInput";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const styles: Styles<Theme, any> = {
  url: { display: "inline-flex", marginLeft: 32 },
  flexDiv: { display: "flex" },
  inlineDiv: { display: "inline" },
  code: { display: "inline-block", marginLeft: 32 },
  name: { display: "inline-block" },
  flex: { display: "flex", width: "100%", alignItems: "flex-start" },
  pullRight: { float: "right" },
  inlineFlex: { display: "inline-flex" },
  inlineFlexRight: {
    display: "inline-flex",
    justifyContent: "start",
    marginLeft: 15,
  },
  removeBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  addBtn: { fontSize: 12, fontWeight: 500, marginLeft: 32, marginTop: 17 },
  hidden: { visibility: "hidden" },
  hide: { display: "none" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },

  title: {
    fontSize: 18,
    fontWeight: 700,
    paddingLeft: 15,
    paddingBottom: 0,
    color: "#000",
    textTransform: "capitalize",
  },
  label: {
    display: "inline-block",
    fontSize: 14,
    marginRight: 10,
    fontWeight: 500,
    color: "#000",
  },
  value: { display: "inline-block", fontWeight: 700 },
  myPopup: { display: "inline-block" },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  closeButton: { position: "absolute", top: 5, right: 0 },
};

interface State {
  data?: any;
  company?: "";
  studentData?: [];
  objStudent: {};
  URL?: string;
  state?: {};
  emptyValue?: false;
  errorData: [];
  isOpen: false;
  responseData: {
    totalCount: 0;
    successCount: 0;
    dataError: 0;
    companyName: "";
    deviceName: "";
  };
}
const useStyles = makeStyles(styles);

const StudentMultiRegistration = (props: any) => {
  // const form = useForm();
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const redirectUrl = useRedirect();
  //eslint-disable-next-line
  const [state, setState] = useState<State>(Object);
  const [company, setCompany] = useState(String);
  const [studentData, setStudentData] = useState(Array);
  const [URL, setURL] = useState(String);
  const dataProvider = useDataProvider();
  const [objStudent, setobjStudent] = useState({});
  const [emptyValue, setEmptyValue] = useState(Boolean);
  //eslint-disable-next-line
  const [isLoad, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objStream, setObjStream] = useState({});
  const [objCourse, setObjCourse] = useState({});
  const [objSemester, setObjSemester] = useState({});
  let [errorData, setErrorData] = useState([]);
  useEffect(() => {
    if (container) {
      container.innerText = "";
      container.innerText = "Student Registration";
    }
    return function cleanup() {
      if (container) {
        container.innerText = "";
      }
    };
  }, [container]);
  let [responseData, setResponseData] = useState({
    totalCount: 0,
    successCount: 0,
    dataError: 0,
    companyName: "",
    deviceName: "",
  });

  const headerArr = [
    { label: "Stream", key: "stream.name" },
    { label: "Course", key: "course.name" },
    { label: "Semester", key: "semester.name" },
    { label: "Student", key: "student.name" },
    { label: "URL", key: "url" },
    { label: "Action", key: "remove" },
  ];

  const PostToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton label="Register" />
    </Toolbar>
  );
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const user = helperFunc.getUserData();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setIsOpen(false);
    redirectUrl("studentMultiRegistration");
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const removeStudent = (idx: any) => {
    setStudentData(studentData.filter((s: any, sIdx: any) => idx !== sIdx));
  };
  const addStudent = (data: any) => {
    if (!data.stream) {
      toastError("Please Select Stream");
    } else if (!data.course) {
      toastError("Please Select Course");
    } else if (!data.semester) {
      toastError("Please Select Semester");
    } else if (data && (data.student === "" || data.student === undefined)) {
      toastError("Please Select Student");
      // notify("please select Student", "warning");
      return;
    } else if (data && (data.url === "" || data.url === undefined)) {
      toastError("Please add valid URL");
      // notify("please add valid URL", "warning");
      return;
    } else {
      let duplicate = false;
      studentData.filter((s: any, sIdx: any) => {
        if (s.studentId.id === data.student) {
          duplicate = true;
        }
      });
      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
      } else {
        setStudentData([
          ...studentData,
          {
            stream: objStream,
            course: objCourse,
            semester: objSemester,
            studentId: objStudent,
            url: data.url,
          },
        ]);
      }

      setObjStream({});
      setObjCourse({});
      setObjSemester({});
      data.url = "";
      setURL("");

      setEmptyValue(true);
      data.hideFiled = true;
      setTimeout(function () {
        setEmptyValue(false);
      }, 500);
    }
  };

  const videoUpload = (e: any) => {
    setLoader(true);
    let file;
    let data;
    let url;
    file = e.target.files && e.target.files[0];
    data = new FormData();

    data.append("file", file);
    dataProvider
      .callCustom("account/uploadVideo", data)
      .then((responseLocal: any) => {
        if (responseLocal.data.success === true) {
          if (
            responseLocal.data &&
            responseLocal.data.item &&
            responseLocal.data.item.video
          ) {
            url = uploadApiUrl + responseLocal.data.item.video;
            setURL(url);
          }
          setLoader(false);
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        setLoader(false);
      });
  };
  const saveForm = (formdata: any) => {
    if (
      user.type === "super" &&
      (formdata.company === "" || formdata.company === undefined)
    ) {
      toastError("Please Select Company");
    } else if (studentData && studentData.length === 0) {
      toastError("Please add at list one record");
    } else {
      const eData = studentData.map((data: any) => {
        return {
          stream: data.stream._id,
          course: data.course._id,
          semester: data.semester._id,
          studentId: data.studentId._id,
          url: data.url,
        };
      });

      const resource = "student/student_registration_multiple";
      dataProvider
        .callCustom(resource, {
          company: formdata.company ? formdata.company : "",
          studentData: eData,
        })
        .then((response: any) => {
          setIsOpen(true);
          setResponseData({
            totalCount: response && response.data && response.data.totalCount,
            dataError: response && response.data && response.data.faileCount,
            successCount:
              response && response.data && response.data.successCount,
            companyName:
              response &&
              response.data &&
              response &&
              response.data.companyName &&
              response.data.companyName[0].name,
            deviceName:
              response &&
              response.data &&
              response &&
              response.data.deviceName &&
              response.data.deviceName[0].camera_ID,
          });
          setErrorData(response?.data?.result?.student_IDs_status);
          setStudentData([]);
          formdata.company = "";
          setCompany("");
          // return redirectUrl('studentMultiRegistration');
        })
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
          setStudentData([]);
          formdata.company = "";
          setCompany("");
          return redirectUrl("studentMultiRegistration");
        });
      formdata.hideFiled = true;
    }
  };
  const streamData = (data: any) => {
    setObjStream(data);
    setObjCourse({});
    setObjSemester({});
  };
  const courseData = (data: any) => {
    setObjCourse(data);
    setObjSemester({});
  };
  const semesterData = (data: any) => {
    setObjSemester(data);
  };
  const dataManage = (data: any, type: any) => {
    if (type === "student") {
      setobjStudent(data);
    } else {
      setEmptyValue(false);
    }
  };
  const { data } = state;
  return (
    <div
      style={
        isXSmall
          ? {
              width: window.screen.width,
              overflowX: "scroll",
            }
          : {}
      }
    >
      <SimpleForm
        {...props}
        record={{ url: URL, company: company }}
        save={saveForm}
        toolbar={<PostToolbar />}
        {...props}
      >
        <CompanyReferenceInput></CompanyReferenceInput>
        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => (
                <StreamSelectInput
                  labelName="Stream *"
                  dataManage={streamData}
                  emptyValue={{ emptyValue }}
                ></StreamSelectInput>
              )}
            </FormDataConsumer>
          </div>
          <div style={isSmall || isXSmall ? undefined : styleData}>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <CourseSelectInput
                    filter={{ stream: formData.stream, isActive: true }}
                    formClassName={classes.code}
                    labelName="Course *"
                    objCourse={objCourse}
                    dataManage={courseData}
                  ></CourseSelectInput>
                );
              }}
            </FormDataConsumer>
          </div>
          <div style={isSmall || isXSmall ? undefined : styleData}>
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => (
                <SemesterSelectInput
                  filter={{
                    stream: formData.stream,
                    course: formData.course,
                    isActive: true,
                  }}
                  dataManage={semesterData}
                  labelName="Semester *"
                  objSemester={objSemester}
                ></SemesterSelectInput>
              )}
            </FormDataConsumer>
          </div>
        </div>

        <SectionTitle label="" />
        <Box className={isXSmall ? undefined : classes.flex}>
          <FormDataConsumer
            formClassName={isXSmall ? null : classes.inlineFlex}
          >
            {({ formData }: { formData: any }) => (
              <StudentSelectInput
                filter={{
                  isActive: true,
                  company: user.type === "super" ? formData.company : "",
                  stream: formData.stream,
                  course: formData.course,
                  semester: formData.semester,
                }}
                dataManage={dataManage}
                emptyValue={{ emptyValue }}
              ></StudentSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXSmall ? null : classes.url}>
            {({ formData }: { formData: any }) => (
              <TextInput
                // validate={requiredValidate}
                source="url"
                label="URL"
                className={isXSmall ? null : classes.url}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXSmall ? null : classes.url}>
            {({ formData }: { formData: any }) => (
              <MaterialButton
                onClick={() => addStudent(formData)}
                variant="contained"
                color="primary"
                className={isXSmall ? undefined : classes.addBtn}
                type="button"
              >
                Add
              </MaterialButton>
            )}
          </FormDataConsumer>
        </Box>
        <Box className={classes.flex}>
          <BooleanInput
            source="isVideoUpload"
            label="Is local upload"
            defaultValue={false}
          />
          <FormDataConsumer formClassName={classes.url}>
            {({ formData }: { formData: any }) =>
              formData.isVideoUpload ? (
                <ImportVideoButton onChange={videoUpload} />
              ) : null
            }
          </FormDataConsumer>
        </Box>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headerArr.map((day: any) => {
                  return (
                    <TableCell className={classes.tableHead} rowSpan={2}>
                      {day.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {studentData &&
                studentData.length > 0 &&
                studentData.map((item: any, index: number) => {
                  return (
                    <TableRow key={index + 1}>
                      {headerArr.map((data: any) => {
                        // return (
                        //   <TableCell
                        //     align="center"
                        //     className={classes.tableCell}
                        //   ></TableCell>
                        // );
                      })}
                      <TableCell className={classes.tableValue} align="left">
                        {item && item.stream && item.stream.name}
                      </TableCell>
                      <TableCell className={classes.tableValue} align="left">
                        {item && item.course && item.course.name}
                      </TableCell>
                      <TableCell className={classes.tableValue} align="left">
                        {item && item.semester && item.semester.name}
                      </TableCell>
                      <TableCell className={classes.tableValue} align="left">
                        {item && item.studentId && item.studentId.name}
                      </TableCell>
                      <TableCell className={classes.tableValue} align="left">
                        {item && item.url}
                      </TableCell>
                      <TableCell className={classes.tableValue} align="left">
                        {isXSmall ? (
                          <RemoveCircleOutlineIcon
                            onClick={() => removeStudent(index)}
                          />
                        ) : (
                          <Button
                            onClick={() => removeStudent(index)}
                            variant="outlined"
                            className={classes.removeBtn}
                            type="button"
                            label="Remove"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {studentData && studentData.length === 0 ? (
                <TableRow key="no studentData">
                  <TableCell
                    className={classes.tableValue}
                    colSpan={6}
                    align="center"
                  >
                    No data found.
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hide}
        />
      </SimpleForm>
      <Dialog
        fullScreen={fullScreen}
        className={classes.myPopup}
        aria-labelledby="simple-dialog-title"
        onClose={handleClose}
        open={isOpen}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">Student Register</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={classes.close} />
          </IconButton>
        </DialogTitle>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Company :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.companyName}
            </span>
          </div>
          <div className={classes.label}>
            Device:{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.deviceName}
            </span>
          </div>
        </Box>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Total :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.totalCount}
            </span>
          </div>
          <div className={classes.label}>
            Success:{" "}
            <span className={classes.label} style={{ color: "green" }}>
              {responseData.successCount}
            </span>
          </div>
          <div className={classes.label}>
            Error :{" "}
            <span className={classes.label} style={{ color: "red" }}>
              {responseData.dataError}
            </span>
          </div>
        </Box>
        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.theadFCol}>Sr no</TableCell>
                  <TableCell className={classes.theadCol}>Name</TableCell>
                  <TableCell className={classes.theadCol}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data: any, index: number) => (
                    <TableRow key={index + 1}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {data ? data.empName : ""}
                      </TableCell>
                      <TableCell align="left">{data ? data.msg : ""}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={errorData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </div>
  );
};

export default StudentMultiRegistration;
