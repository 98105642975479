
import DepartmentIcon from "@material-ui/icons/VerifiedUser";
import EmployeeDataRightList from "./EmployeeDataRightList";
import EmployeeDataRightCreate from "./EmployeeDataRightCreate";
import EmployeeDataRightEdit from "./EmployeeDataRightEdit";

export default {
  list: EmployeeDataRightList,
  create: EmployeeDataRightCreate,
  edit: EmployeeDataRightEdit,
  icon: DepartmentIcon,
};
