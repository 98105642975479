import React, { Fragment, useState, useEffect } from "react";
import { useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import { attendanceExtraDuty } from "../helperStyle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useMediaQuery,
  Theme,
  Box,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import helperFunc from "../helperFuncs";
import authProvider from "../authProvider";
import ClearIcon from "@material-ui/icons/Clear";
import { toastError } from "../components/toast";

const FinalEdit = (props: any) => {
  interface res {
    list: any;
    datesData: any;
    filter: any;
    type: any;
  }
  interface Hres {
    childCompanyId: any;
    clientId: any;
    clientLocationId: any;
    company: any;
    employee: any;
    state: any;
    item: any;
  }
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  const useStyles = makeStyles(attendanceExtraDuty);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const user = helperFunc.getUserData();
  // const notify = useNotify();
  const classes = useStyles();

  const [downloadLoading, setDownloadLoading] = useState<any>(false);
  const [sendApprovalLoading, setSendApprovalLoading] = useState<any>(false);
  const [responseData, setResponseData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [downloadTypes, setDownloadTypes] = useState<any>({ downloadType: "" });

  const params = {
    id: props.id,
  };
  const attendanceID =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.attendenceId
      ? props.location.state.attendenceId
      : "N/A";
  useEffect(() => {
    dataProvider
      .getOne("final-report", params)
      .then((response: any) => {
        setResponseData(response.data);
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, [props]);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  React.useEffect(() => {
    if (!(isSmall && isXSmall) && container) {
      container.innerText = "";
      container.innerText = `Final Report - ${attendanceID}`;
    }
    return function cleanup() {
      if (!(isSmall && isXSmall) && container) {
        container.innerText = "";
      }
    };
  }, [container, responseData]);

  const exporterDetails = (value: any) => {
    setDownloadLoading(true);
    setDownloadTypes({
      ...downloadTypes,
      downloadType: value,
    });
  };
  const handleClickMouse = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (downloadTypes.downloadType !== "") {
      dataProvider
        .callCustom("final-report/export", {
          _id: props.id,
          // activeSheet: `${tab1 === true ? "present" : "overTime"}`,
          downloadType: downloadTypes.downloadType,
        })
        .then((response: any) => {
          setDownloadLoading(false);
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        })
        .catch((err: any) => {
          setDownloadLoading(false);
          toastError(err.message);
        });
    }
  }, [downloadTypes]);

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  return (
    <React.Fragment>
      {isXSmall ? (
        <h2
          style={{
            height: 40,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 15,
          }}
        >{`Final Report - ${attendanceID}`}</h2>
      ) : null}
      {responseData && responseData.length && responseData.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            marginRight: isXSmall ? undefined : "350px",
          }}
        >
          No Record Found
        </p>
      ) : (
        <div
          style={
            isXSmall
              ? {
                  width: window.screen.width,
                  overflow: "scroll",
                  marginTop: "25px",
                }
              : { marginBottom: 60 }
          }
          className={isXSmall ? "" : classes.rtable}
        >
          <TableContainer
            className={
              isXSmall
                ? ""
                : open
                ? classes.finalContainer
                : classes.menuCloseContainer
            }
          >
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="center">
                    Employee ID
                  </TableCell>
                  <TableCell className={classes.header} align="center">
                    Name
                  </TableCell>
                  <TableCell className={classes.header} align="center">
                    Designation
                  </TableCell>
                  {user?.isClientConfiguration && (
                    <TableCell className={classes.header} align="center">
                      Location ID
                    </TableCell>
                  )}
                  <TableCell className={classes.header} align="center">
                    Branch Code
                  </TableCell>
                  {user?.isClientConfiguration && (
                    <TableCell className={classes.header} align="center">
                      Client Name
                    </TableCell>
                  )}

                  <TableCell className={classes.header} align="center">
                    Total mandays
                  </TableCell>
                  <TableCell className={classes.header} align="center">
                    Total OT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responseData &&
                  responseData.map((row: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center" component="th" scope="row">
                        {row.employeeCode}
                      </TableCell>
                      <TableCell align="center">{row.employeeName}</TableCell>
                      <TableCell align="center">
                        {row.designationName}
                      </TableCell>
                      {user?.isClientConfiguration && (
                        <TableCell align="center">
                          {row.clientLocationId}
                        </TableCell>
                      )}

                      <TableCell align="center">{row.branchCode}</TableCell>
                      {user?.isClientConfiguration && (
                        <TableCell align="center">{row.clientName}</TableCell>
                      )}

                      <TableCell align="center">{row.totalManDays}</TableCell>
                      <TableCell align="center">{row.totalOT}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {/* </Paper> */}
      <div
        style={{ margin: isXSmall ? 10 : 0, display: "block" }}
        className={isXSmall ? "" : "stickBottm"}
      >
        <Box component="div" className={classes.flexColTop}>
          <Box component="div" className={classes.flexColTop50Left}>
            <div>
              <Button
                style={{ color: "white" }}
                className={classes.downloadButton}
                variant="contained"
                color="primary"
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={
                  () => exporterDetails("xlsx")
                  // handleClickMouse
                }
                // onMouseOver={handleClickMouse}
                disabled={downloadLoading}
              >
                {downloadLoading && (
                  <CircularProgress size={15} thickness={2} />
                )}
                Download
              </Button>
              {/* <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                MenuListProps={{ onMouseLeave: handleClose }}
                            >
                                <MenuItem onClick={() => {
                                    exporterDetails("xlsx")
                                }}>Download as Excel</MenuItem>
                                <MenuItem onClick={() => {
                                    exporterDetails("pdf")
                                }}>Download as PDF</MenuItem>
                            </Menu> */}
            </div>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};
export default FinalEdit;
