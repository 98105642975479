import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  useNotify,
  SimpleForm,
  NumberInput,
  required,
  useDataProvider,
  minValue,
  maxValue,
  maxLength,
  number,
  FormDataConsumer,
  TextInput,
  LinearProgress,
  useRefresh,
} from "react-admin";
import { regex } from "ra-core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  useMediaQuery,
  Theme,
  TableHead,
  TableRow,
  Button as MaterialButton,
} from "@material-ui/core";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-final-form";
import { toastError, toastInfo } from "../components/toast";
import DialogueBox from "../components/ConfirmDialogueBox";
import MultiSelectDaysInput from "../components/MultiSelectDaysInput";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  name: { display: "inline-block" },
  shiftTextBtn: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  employee: { display: "inline-block", marginLeft: 32 },
  shiftDiv: { display: "inline-block", marginLeft: 32, width: "54%" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfShift: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  shiftMainBtn: { display: "inline-block", marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: 25 },
  editShiftBtn: {
    fontSize: 12,
    border: "1px solid",
    fontWeight: 500,
    marginRight: 10,
  },
  removeShiftBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
});

interface State {
  device?: string;
  isLocal?: boolean;
  emptyValue: false;
  shifts?: [];
  noOfShifts: number;
  updateShift?: any;
  record?: any;
  shiftData?: any;
}
const WorkplaceShift: FC<FieldProps> = ({
  updateShift,
  record,
  type,
  recordName,
  setRecordName,
}) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const [open, setOpen] = useState(false);
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [shifts, setShifts] = useState(Array);
  const [noOfShifts, setNoOfShifts] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [shiftData, setShiftData] = useState({
    _id: "",
    shiftName: "",
    timeOfReporting: "",
    inTime: "",
    outTime: "",
    workingDays: "",
    workingHours: "",
    lunchBreak: "",
    selectedWeekDays: [],
  });
  const [index, setIndex] = useState(Number);
  const [isLoad, setLoader] = useState(false);
  const [selectedWeekDays, setSelectedWeekDays] = useState();

  const form = useForm();

  const weekDaysObj: any = {
    0: "S",
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S",
  };

  const handleOpen = () => {
    setOpen(true);
    setShiftData({
      _id: "",
      shiftName: "",
      timeOfReporting: "",
      inTime: "",
      outTime: "",
      workingDays: "",
      workingHours: "",
      lunchBreak: "",
      selectedWeekDays: [],
    });
  };

  const handleConfirm = (record: any, shiftData: any) => {
    setLoader(true);

    record.shifts = shiftData ? shiftData : shifts;
    const resource = "workplaces";
    const id = record.id;
    dataProvider
      .update(resource, { data: { shifts: record.shifts }, id: id })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          setLoader(false);
          response.data.success = null;
          // handleDialogClose();
          // setRecordName(record.name);

          if (updateShift) {
            updateShift(shifts);
          }
          // handleDialogClose();
          toastInfo("Shift Updated successfully !");
          // notify("Shift Updated successfully !");
        }
        setShiftData({} as any);
      })
      .catch((err: any) => {
        setLoader(false);
        // handleDialogClose();
        toastError(err.message);
        return;
      });
  };
  const removeShift = (idx: any) => {
    let temparr = shifts.filter((s: any, sIdx: any) => idx !== sIdx);
    setShifts(temparr);
    handleConfirm(record, temparr);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleOpenClick = (index: any) => {
    setShowDialog(true);
    setIndex(index);
  };

  const validateTime = (timeString: any) => {
    if (timeString && timeString.includes(":")) {
      return timeString;
    } else {
      return timeString + ":00";
    }
  };
  const editShift = (idx: any) => {
    let shiftEdiData: any;
    shiftEdiData = [...shifts];

    setShiftData({
      _id: shiftEdiData && shiftEdiData[idx]?._id,
      shiftName: shiftEdiData && shiftEdiData[idx]?.name,
      timeOfReporting:
        shiftEdiData && validateTime(shiftEdiData[idx]?.timeOfReporting),
      inTime: shiftEdiData && validateTime(shiftEdiData[idx].inTime),
      outTime: shiftEdiData && validateTime(shiftEdiData[idx].outTime),
      workingDays: shiftEdiData && shiftEdiData[idx]?.workingDays,
      workingHours: shiftEdiData && shiftEdiData[idx]?.workingHours,
      lunchBreak: shiftEdiData && shiftEdiData[idx]?.lunchBreak,
      selectedWeekDays: shiftEdiData && shiftEdiData[idx]?.selectedWeekDays,
    });
    // setShifts(shiftEdiData);
    // shiftEdiData[idx].isEditMode = true ;
    setShifts(shifts.filter((s: any, sIdx: any) => idx !== sIdx));
  };

  useEffect(() => {
    if (record && record.shifts) {
      setShifts(record.shifts);
    }
    if (record && record.noOfShifts) {
      setNoOfShifts(record.noOfShifts);
    }
    if (record && record.name) {
      setRecordName(record.name);
    }
  }, [record]);

  const addShift = (data: any) => {
    if (data && (data.shiftName === "" || data.shiftName === undefined)) {
      toastError("Please add name");
      return;
    } else if (
      data &&
      (data.timeOfReporting === "" || data.timeOfReporting === undefined)
    ) {
      toastError("Please add Time of Reporting");

      return;
    } else if (data && (data.inTime === "" || data.inTime === undefined)) {
      toastError("Please add In Time");

      return;
    } else if (data && (data.outTime === "" || data.outTime === undefined)) {
      toastError("Please add Out Time");

      return;
    } else if (
      data &&
      (data.workingDays === "" || data.workingDays === undefined)
    ) {
      toastError("Please add Working Days");
      return;
    } else if (data && data.workingDays > 7) {
      toastError("Working Days should be equal or less than 7");
      return;
    } else if (data && data.workingDays.toString().length > 1) {
      toastError("Working Days should be in integer format");
      return;
    } else if (
      data &&
      (data.workingHours === "" || data.workingHours === undefined)
    ) {
      toastError("Please add Working Hours");
      return;
    } else if (data && data.workingHours > 24) {
      toastError("Working Hours should be equal or less than 24");
      return;
    } else if (data && data.lunchBreak > data && data.workingHours) {
      toastError("lunchBreak should be less than Working Hours");
      return;
    } else {
      let tempArr = shifts;
      let duplicate = false;
      shifts.filter((s: any, sIdx: any) => {
        if (s.name === data.shiftName) {
          duplicate = true;
        }
      });

      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
      } else {
        if (shiftData && shiftData._id && shiftData._id !== "") {
          tempArr.push({
            _id: shiftData._id,
            name: data.shiftName,
            timeOfReporting: data.timeOfReporting,
            inTime: data.inTime,
            outTime: data.outTime,
            workingDays: data.workingDays,
            workingHours: data.workingHours,
            lunchBreak: data.lunchBreak,
            selectedWeekDays: selectedWeekDays,
          });
        } else {
          tempArr.push({
            name: data.shiftName,
            timeOfReporting: data.timeOfReporting,
            inTime: data.inTime,
            outTime: data.outTime,
            workingDays: data.workingDays,
            workingHours: data.workingHours,
            lunchBreak: data.lunchBreak,
            selectedWeekDays: selectedWeekDays,
          });
        }
        setShifts(tempArr);
        data.shiftName = "";
        data.timeOfReporting = "";
        data.inTime = "";
        data.outTime = "";
        data.workingDays = "";
        data.workingHours = "";
        data.lunchBreak = "";
        data.selectedWeekDays = [];
        data.shiftDays = [];
      }

      handleConfirm(record, undefined);

      setShiftData({
        _id: "",
        shiftName: "",
        timeOfReporting: "",
        inTime: "",
        outTime: "",
        workingDays: "",
        workingHours: "",
        lunchBreak: "",
        selectedWeekDays: [],
      });

      // setSelectedWeekDays([]);
      // setEmptyValue(true);
      // setTimeout(function () {
      //   setEmptyValue(false);
      // }, 1000);
    }
    data = {};
  };
  const handleDialogClose = () => {
    setOpen(false);
    refresh();
  };

  const weekDaysData = (data: any) => {
    setSelectedWeekDays(data);
  };

  return record ? (
    <div className={classes.root}>
      {isXsmall ? (
        <span className={classes.shiftTextBtn} onClick={handleOpen}>
          {`Shift (${
            shifts && shifts.length > 0 ? shifts.length : noOfShifts
          })`}
        </span>
      ) : (
        <Button
          variant="contained"
          color="primary"
          label={`Shift (${
            shifts && shifts.length > 0 ? shifts.length : noOfShifts
          })`}
          onClick={handleOpen}
          className={classes.shiftMainBtn}
        ></Button>
      )}

      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Shifts"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>
          Shifts for {record?.name ? record.name : recordName}
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            onSubmit={() => handleConfirm(record, undefined)}
            toolbar={null}
            record={{
              shiftName: shiftData?.shiftName,
              timeOfReporting: shiftData?.timeOfReporting,
              workingDays: shiftData?.workingDays,
              workingHours: shiftData?.workingHours,
              lunchBreak: shiftData?.lunchBreak,
              inTime: shiftData?.inTime,
              outTime: shiftData?.outTime,
              // weekDays: selectedWeekDays,
            }}
          >
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="Name *"
                  source="shiftName"
                  formClassName={isXsmall ? null : classes.name}
                  // validate={nameValidate}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="Time Of Reporting *"
                  type="time"
                  source="timeOfReporting"
                  formClassName={isXsmall ? null : classes.name}
                  // validate={[required()]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="In Time *"
                  type="time"
                  source="inTime"
                  formClassName={isXsmall ? null : classes.name}
                  // validate={[required()]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  label="Out Time *"
                  type="time"
                  source="outTime"
                  formClassName={isXsmall ? null : classes.name}
                  // validate={[required()]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="Working Days *"
                  source="workingDays"
                  type="number"
                  min="1"
                  max="7"
                  formClassName={isXsmall ? null : classes.name}
                  validate={[number(), minValue(1), maxValue(7)]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="Working Hours *"
                  source="workingHours"
                  type="number"
                  min="1"
                  formClassName={isXsmall ? null : classes.name}
                  validate={[number(), minValue(1), maxValue(24)]}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="Lunch Break"
                  source="lunchBreak"
                  type="number"
                  min="0"
                  formClassName={isXsmall ? null : classes.name}
                  validate={[number(), minValue(1), maxValue(24)]}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.shiftDiv}
            >
              {({ formData }: { formData: any }) => (
                <MultiSelectDaysInput
                  dataManage={weekDaysData}
                  emptyValue={{ emptyValue }}
                  labelName="Shift Days"
                  defaultValue={shiftData && shiftData.selectedWeekDays}
                ></MultiSelectDaysInput>
              )}
            </FormDataConsumer>

            <div style={{ display: "inline" }}>
              <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                  <MaterialButton
                    onClick={() => {
                      addShift(formData);
                    }}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="button"
                    className={isXsmall ? undefined : classes.allotmentBtn}
                  >
                    Submit
                  </MaterialButton>
                )}
              </FormDataConsumer>
            </div>

            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Name</TableCell>
                    <TableCell className={classes.tableHead}>
                      Time Of Reporting
                    </TableCell>
                    <TableCell className={classes.tableHead}>in Time</TableCell>
                    <TableCell className={classes.tableHead}>
                      Out Time
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Working Days
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Working Hours
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Lunch Break
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Shift Days
                    </TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shifts && shifts.length > 0
                    ? shifts.map((shift: any, index: number) => (
                        <TableRow key={"tablerow" + index}>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {shift.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {shift.timeOfReporting}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {shift.inTime}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {" "}
                            {shift.outTime}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {" "}
                            {shift.workingDays}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {" "}
                            {shift.workingHours}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {" "}
                            {shift.lunchBreak}
                          </TableCell>

                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            {shift?.selectedWeekDays?.map((ele: any) => {
                              return `${weekDaysObj[ele]}`;
                            })}
                          </TableCell>
                          <TableCell
                            className={classes.tableValue}
                            align="left"
                          >
                            <div style={{ display: "flex" }}>
                              <MaterialButton
                                onClick={() => editShift(index)}
                                variant="outlined"
                                className={classes.editShiftBtn}
                                type="button"
                              >
                                Edit
                              </MaterialButton>
                              <MaterialButton
                                onClick={() => handleOpenClick(index)}
                                variant="outlined"
                                className={classes.removeShiftBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}

                  {shifts && shifts.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={9}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            label="Close"
            onClick={() => {
              handleDialogClose();
            }}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
        <DialogueBox
          handleCloseClick={handleCloseClick}
          open={showDialog}
          remove={() => removeShift(index)}
          // itemName={allotmentDialog}
        />
      </Dialog>
    </div>
  ) : null;
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default WorkplaceShift;
