import * as React from "react";
import { FC, memo, useState } from "react";
import {
  required,
  SelectInput,
  useDataProvider
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldProps, Visitor, Role } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  employeeType?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
  value?: number;
  filter?: {};
  validate?: {};
  helperText?: boolean;
}

const EmployeeTypeSelectInput: FC<Props> = ({ filter, validate, helperText }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("employee-type/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          employeeType: response && response.data,
        }));
      });
  }, [filter])


  const { employeeType } = state;

  return (
    <SelectInput
      resettable
      label="Employee Type"
      className={classes.root}
      source="employeeType"
      validate={validate ? requiredValidate : null}
      choices={employeeType}
      helperText={helperText ? helperText : false}
    />
  );
};
const requiredValidate = [required()];
EmployeeTypeSelectInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};

export default memo<Props>(EmployeeTypeSelectInput);
