import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  email,
  FileInput,
  ImageField,
  PasswordInput,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  useEditController,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
// import { Styles } from "@material-ui/styles/withStyles";
import { profileStyles } from "../helperStyle";
import authProvider from "../authProvider";
import { useLocation } from "react-router";
const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const validatePasswords = ({
  name,
  password,
  confirm_password,
}: {
  name: string;
  password: string;
  confirm_password: string;
}) => {
  const errors = {} as any;
  if (name && name.length > 50) {
    errors.name = ["Name Muse be smaller then 50 characters!!!"];
  }
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.customers.errors.password_mismatch"];
  }

  return errors;
};

const useStyles = makeStyles(profileStyles);
const ProfileEdit = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const controllerProps = useEditController(props);
  const location = useLocation();
  return isSmall ? (
    <Edit undoable={false} title="Update Profile" {...props}>
      <SimpleForm
        toolbar={<PostEditToolbar />}
        validate={validatePasswords}
        redirect={false}
      >
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            resource="profile"
            formClassName={classes.name}
            validate={requiredValidate}
          />
          <TextInput
            disabled
            source="email"
            resource="profile"
            validation={{ email: true }}
            formClassName={isXsmall ? classes.name : classes.code}
            validate={[required(), email()]}
          />
          {!authProvider.isHiddenField("/my-profile", "password", "isHide") && (
            <SectionTitle label="resources.customers.fieldGroups.password" />
          )}

          {!authProvider.isHiddenField("/my-profile", "password", "isHide") && (
            <PasswordInput source="password" formClassName={classes.password} />
          )}
          {!authProvider.isHiddenField(
            "/my-profile",
            "confirm-password",
            "isHide"
          ) && (
            <PasswordInput
              source="confirm_password"
              formClassName={classes.name}
            />
          )}

          <FileInput
            label="label.picture"
            multiple={false}
            resource="profile"
            source="profile_image"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </FileInput>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) =>
              formData &&
              formData.settings &&
              formData.profile_image &&
              !formData.profile_image.src ? (
                <img
                  style={{ width: 350, height: 200 }}
                  src={
                    controllerProps &&
                    controllerProps.record &&
                    controllerProps.record.settings &&
                    controllerProps.record.profile_image &&
                    controllerProps.record.profile_image.indexOf("base64") > -1
                      ? controllerProps.record.profile_image
                      : uploadApiUrl + controllerProps.record.profile_image
                  }
                />
              ) : null
            }
          </FormDataConsumer>
        </div>
      </SimpleForm>
    </Edit>
  ) : (
    <Edit undoable={false} title="Update Profile" {...props}>
      <SimpleForm
        toolbar={<PostEditToolbar />}
        validate={validatePasswords}
        redirect={false}
      >
        <TextInput
          autoFocus
          source="name"
          resource="profile"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          disabled
          source="email"
          resource="profile"
          validation={{ email: true }}
          formClassName={isXsmall ? classes.name : classes.code}
          validate={[required(), email()]}
        />
        {!authProvider.isHiddenField("/my-profile", "password", "isHide") && (
          <SectionTitle label="resources.customers.fieldGroups.password" />
        )}

        {!authProvider.isHiddenField("/my-profile", "password", "isHide") && (
          <PasswordInput source="password" formClassName={classes.password} />
        )}
        {!authProvider.isHiddenField(
          "/my-profile",
          "confirm-password",
          "isHide"
        ) && (
          <PasswordInput
            source="confirm_password"
            formClassName={isXsmall ? classes.name : classes.code}
          />
        )}

        <FileInput
          label="label.picture"
          multiple={false}
          resource="profile"
          source="profile_image"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </FileInput>
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) =>
            formData &&
            formData.settings &&
            formData.profile_image &&
            !formData.profile_image.src ? (
              <img
                style={{ width: 350, height: 200 }}
                src={
                  controllerProps &&
                  controllerProps.record &&
                  controllerProps.record.settings &&
                  controllerProps.record.profile_image &&
                  controllerProps.record.profile_image.indexOf("base64") > -1
                    ? controllerProps.record.profile_image
                    : uploadApiUrl + controllerProps.record.profile_image
                }
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const requiredValidate = [
  required(),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default ProfileEdit;
