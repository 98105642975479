import * as React from "react";
import { Edit } from "react-admin";
import { PostEditToolbar } from "../components/PostEditToolbar";
import CommonForm from "./CommonForm";

const CompanyEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Company"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};

export default CompanyEdit;
