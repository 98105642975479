import * as React from "react";
import { FC, useState, useEffect, Fragment } from "react";

import moment from "moment";
import jsonExport from "jsonexport/dist";
import helperFunc from "../helperFuncs";

import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import {
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  downloadCSV,
  TopToolbar,
  ExportButton,
  required,
  DateTimeInput,
  Datagrid,
  FunctionField,
  TextField,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  FilterList as FilterListIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import { Form } from "react-final-form";
import { stringify } from "query-string";
import { ListComponentProps } from "../types";
import { auth } from "../helperChoice";
import LinkField from "./LinkField";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { useGetFilterEmployee } from "../hooks/useGetFilterEmployee";

const approvalStatus: any = {
  reject: "Rejected",
  approve: "Approved",
  pending: "Pending",
  cancel: "Cancel",
};

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_from) {
        values.time_from = moment.utc(moment(values.time_from).utc()).format();
      }
      if (values.time_to) {
        values.time_to = moment.utc(moment(values.time_to).utc()).format();
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };
  const filterData = () => {
    const user = helperFunc.getUserData();

    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(1);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);

    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  useEffect(() => {
    if (data.location.search === "") data.setFilters(filterData(), []);
  }, []);

  const resetFilter = () => {
    data.setFilters(filterData(), []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.time_from > new Date()) {
      return "From date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.time_to > new Date()) {
      return "To date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="authType"
                      choices={auth}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="time_from"
                      validate={[required(), checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[required(), checkToDate]}
                      source="time_to"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
const ListActions = (props: any) => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <TopToolbar>
      <Button
        disabled={total === 0}
        size="small"
        color="primary"
        component={Link}
        to={{
          pathname: "/team-attendance-report",
          search: stringify({
            // skip: 0,
            // limit: 20,
            // sort: "asc",
            // sortBy: "name",
            filter: JSON.stringify({
              fromDate: helperFunc.subtractDaysFilter(7),
              toDate: helperFunc.addDaysFilter(0),
              employee: props?.employeeId,
            }),
          }),
        }}
      >
        <HistoryIcon style={{ marginRight: "0.2em" }} />
        History
      </Button>
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        name={isSmall ? null : "Export"}
      />
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
  userImg: {
    padding: 0,
    height: 55,
    width: 55,
    borderRadius: "30px",
  },
  textMerge: {
    width: "70px",
    height: "auto",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  toolTipWidth: {
    // width: "500px",
    position: "absolute",
    fontSize: "2em",
    padding: 0,
    textOverflow: "ellipsis",
  },
});

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      time,
      authType,
      employee,
      createdAt,
      attendanceChangeRequestTime,
      reason,
      requestStatus,
    } = record; // omit fields

    return {
      EmployeeName: employee?.name,
      "Last Status": authType,
      Date: helperFunc.commonDateFormat(record["time"], "HH:mm:ss"),
      "Change Request Time": attendanceChangeRequestTime,
      Reason: reason,
      Status: approvalStatus[requestStatus],
    };
  });
  jsonExport(recordsForExport, (err: any, csv: any) => {
    downloadCSV(csv, "Team Attendance History"); // download as 'xxxx.csv` file
  });
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    {helperFunc.getUserData().designation === "HR" ||
    helperFunc.getUserData().type === "super" ||
    helperFunc.getUserData().type === "company" ? (
      <SearchInput
        // label="Search Employee Name"
        source="employeeNameCode"
        parse={trimValue}
        alwaysOn
        placeholder="Search Employee Name"
      />
    ) : null}
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const PostPanel = (props: any) => {
  return (
    <table
      style={{ width: "95%", border: "1px solid #E6E6E6", marginLeft: "50px" }}
    >
      <tr style={{ background: "#E6E6E6", marginLeft: "10px" }}>
        <th style={{ padding: "3px" }}>Reason</th>
        <th>Status</th>
        <th>Time</th>
      </tr>
      <tr>
        <td style={{ padding: "3px" }}>
          {props && props.record && props.record.attendanceChangeRequestReason
            ? props.record.attendanceChangeRequestReason
            : "N/A"}
        </td>
        <td>
          {props && props.record && props.record.attendanceChangeRequestStatus
            ? approvalStatus[props.record.attendanceChangeRequestStatus]
            : "N/A"}
        </td>
        <td>
          {props && props.record && props.record.attendanceChangeRequestTime
            ? helperFunc.localDateAndTime(
                props.record.attendanceChangeRequestTime
              )
            : "N/A"}
        </td>
        <td></td>
      </tr>
    </table>
  );
};

const AttendanceList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const DEVICE_LOCATION = { in: "In", out: "Out" } as any;

  const filterEmployeeId = useGetFilterEmployee(props);

  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(0);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };

  return (
    <>
      <div className={classes.root}>
        {isXSmall ? (
          <h2
            style={{
              height: 55,
              fontSize: 30,
              backgroundColor: "#fff",
              padding: 10,
            }}
          >
            Attendance
          </h2>
        ) : null}
        {/* <ListResetFilterOnListChange /> */}
        <List
          className="list-view2"
          title="resources.teamAttendance.title"
          exporter={exporter}
          actions={
            <ListActions
              handleDrawerOpen={handleDrawerOpen}
              employeeId={filterEmployeeId}
            />
          }
          {...props}
          filterDefaultValues={filterData()}
          filters={
            <AttendanceFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          }
          perPage={20}
          pagination={
            <Pagination
              labelRowsPerPage="Records per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          }
          sort={{ field: "time", order: "DESC" }}
        >
          <Datagrid optimized>
            <FunctionField
              label="label.image"
              render={(record: any) => (
                <img
                  src={
                    record.image && uploadApiUrl && uploadApiUrl + record.image
                      ? uploadApiUrl && uploadApiUrl + record?.image
                      : "assets/img/emptyUser.png"
                  }
                  alt="My Team"
                  className={classes.userImg}
                />
              )}
            />
            {/* <LinkField /> */}

            <TextField
              label="Name"
              source="employee.name"
              sortBy="employee.name"
            />

            <FunctionField
              label="Last Status"
              sortBy="authType"
              render={(record: any) =>
                `${
                  record["authType"] ? DEVICE_LOCATION[record?.authType] : "N/A"
                }  `
              }
            />

            <TextField source="remark" />

            <FunctionField
              label="label.reasons"
              sortBy="attendanceChangeRequestReason"
              render={(record: any) => {
                const d = (
                  <Fragment>
                    <div
                      data-tip={record && record.reason}
                      data-for="reason"
                      className={
                        record && record.attendanceChangeRequestReason
                          ? classes.textMerge
                          : ""
                      }
                    >
                      {record?.attendanceChangeRequestReason ?? "N/A"}
                    </div>
                    {toolTip("reason")}
                  </Fragment>
                );
                return d;
              }}
            />
            {/* <ReactTooltip /> */}
            {toolTip("reason")}
            <FunctionField
              label="Request Status"
              sortBy="attendanceChangeRequestStatus"
              render={(record: any) =>
                `${
                  record && record.attendanceChangeRequestStatus
                    ? approvalStatus[record.attendanceChangeRequestStatus]
                    : "N/A"
                }`
              }
            />

            <FunctionField
              label="Date and Time"
              sortBy="time"
              render={(record: any) =>
                `${
                  record["time"]
                    ? helperFunc.commonDateFormat(record["time"], "HH:mm:ss")
                    : "N/A"
                }  `
              }
            />
          </Datagrid>
        </List>

        {/* <Route path="/teamattendance/:id">
          {({ match }: RouteChildrenProps<{ id: string }>) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

        

            // let data = JSON.parse(props?.location?.search);

            return (
              <Fragment>
                <div
                  className={open === true ? "overlay" : ""}
                  onClick={() => handleDrawerClose("fromlist")}
                />
              </Fragment>
            );
          }}
        </Route> */}
      </div>
    </>
  );
};

export default AttendanceList;
