import React from "react";
import { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import helperFunc from "../helperFuncs";
import {
  TopToolbar,
  ExportButton,
  useDataProvider,
  downloadCSV,
  FormDataConsumer,
  DateInput,
  required,
} from "react-admin";
import {
  Button,
  useMediaQuery,
  makeStyles,
  useTheme,
  Box,
  Drawer,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import RegenrationReport from "../attendanceReport/regeneration";
import AttandanceShowTable from "./AttendanceShowBackup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { toastError } from "../components/toast";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { Loader } from "semantic-ui-react";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckCircleOutlineTwoToneIcon from "@material-ui/icons/CheckCircleOutlineTwoTone";
import SearchableEmployeePublicSelectInput from "../components/SearchableEmployeePublicSelectInput";
import { Dropdown } from "../common";
import FilterPublic from "../common/FilterPublic";

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
  textRed: { color: "red" },
  userImg: {
    padding: 0,
    height: 55,
    width: 55,
    marginLeft: 10,
    borderRadius: "30px",
  },
  logo: { width: "100%", height: "inherit" },
  descriptionIcon: {
    transform: "rotate(180deg)",
    fontSize: 20,
  },
  smCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
  },
  lgCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  lgFilterButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "80px",
  },
  lgViewCsvButton: {
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  csvSpan: { paddingLeft: "0.5em" },
  exportButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  viewButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  exportIcon: {
    fontSize: 20,
  },
  viewIcon: {
    fontSize: 24,
  },
});

const MachineAttendanceReport = () => {
  const dataProvider = useDataProvider();
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const fromDate = moment(new Date())
    .subtract(1, "months")
    .format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");

  const [filterParams, setFilterParams] = React.useState({
    skip: page,
    limit: rowsPerPage,
    sortBy: "name",
    sort: "asc",
    filter: {
      fromDate: fromDate,
      toDate: toDate,
    },
  });
  const [formData, setFormData] = React.useState();
  const [filterData, setFilterData] = React.useState();

  const tableRef = useRef();

  const handleDefaultPage = (index) => {
    setPage(index);
  };
  const handleRowsPerPage = (index) => {
    setRowsPerPage(index);
  };

  const handleChangePage = (event, newPage) => {
    setFilterParams({
      ...filterParams,
      skip: newPage * rowsPerPage,
    });
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    handleRowsPerPage(parseInt(event.target.value));
    handleDefaultPage(0);
    setFilterParams({
      ...filterParams,
      limit: parseInt(event.target.value),
    });
  };

  var container = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  useEffect(() => {
    if (container) {
      container.innerText = "";
      container.innerText = "Attendance Report";
    }
    return function cleanup() {
      if (container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const url = "public/attendance-report/list";
    setLoading(true);
    dataProvider
      .callCustom(url, filterParams)
      .then((response) => {
        setListData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.forEach((error) => {
          toastError(err.message);
          if (error.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
  }, [filterParams, dataProvider]);

  const updatedData = listData?.list?.map((ele, index) => {
    return {
      department: ele?.departmentName ? ele?.departmentName : "N/A",
      name: ele?.name,
      designationName: ele?.designationName ? ele?.designationName : "N/A",
      dateStr: ele?.datestr ? helperFunc.commonDateFormat(ele?.datestr) : "N/A",
      machineInName: ele?.machineInName ? ele?.machineInName : "N/A",
      machineOutName: ele?.machineOutName ? ele?.machineOutName : "N/A",
      inTime: ele?.inTime ? helperFunc.getTimeOnly(ele?.inTime) : "N/A",
      outTime: ele?.outTime ? helperFunc.getTimeOnly(ele?.outTime) : "N/A",
      payBill: index + 1,

      visibilityIcon: <AttandanceShowTable record={ele} />,
    };
  });

  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const menuOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  const exporter = (records) => {
    const recordsForExport = listData?.list.map((record) => {
      const {
        company,
        dateStr,
        department,
        departmentData,
        designation,
        designationData,
        early,
        employee,
        employeeData,
        employeeType,
        employeeTypeData,
        inTime,
        isAbsent,
        late,
        outTime,
        role,
        shift,
        shiftData,
        stayTime,
        totalTime,
        _id,
        id,
        geoPoints,
        inLoc,
        outLoc,
        outMode,
        inMode,
        ...dataToExport
      } = record; // omit fields

      dataToExport["Employee Name"] =
        employeeData &&
        employeeData.name + " " + "(" + employeeData.employeeCode + ")"; // add a field
      // dataToExport.Code = employeeData && employeeData.employeeCode; // add a field
      dataToExport["Designation"] = designationData && designationData.name;
      dataToExport["Department"] = departmentData && departmentData.name;
      // dataToExport["Shift"] = shiftData && shiftData.name;
      dataToExport["Date"] = helperFunc.commonDateFormat(dateStr);
      dataToExport["In"] = inTime ? moment(inTime).format("hh:mm:ss A") : "N/A";
      dataToExport["Out"] = outTime
        ? moment(outTime).format("hh:mm:ss A")
        : "N/A";
      // dataToExport["In Mode"] = inMode ? mode[inMode] : "N/A";
      // dataToExport["Out Mode"] = outMode ? mode[outMode] : "N/A";
      dataToExport["Spent Hours"] = helperFunc.secondsToHms(stayTime);
      dataToExport["Total Hours"] = helperFunc.secondsToHms(totalTime);
      dataToExport["Absent"] = isAbsent ? "Yes" : "No";
      return dataToExport;
    });
    jsonExport(
      recordsForExport,
      {
        headers: [], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Attendance Report"); // download as 'xxxx.csv` file
      }
    );
  };

  const handleFilter = () => {
    setFilterParams({
      skip: page,
      limit: rowsPerPage,
      sortBy: "name",
      sort: "asc",
      filter: {
        fromDate: formData?.fromDate
          ? formData?.fromDate
          : filterParams?.filter?.fromDate,
        toDate: formData?.toDate
          ? formData?.toDate
          : filterParams?.filter?.toDate,
        employeeName: formData?.name,
      },
    });
  };
  const handleResetFilter = () => {
    setFilterParams({
      skip: page,
      limit: rowsPerPage,
      sortBy: "name",
      sort: "asc",
      filter: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
    setFormData({});
    setFilterData({});
  };

  return (
    <>
      {" "}
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="public-report">
          <div className="d-flex align-items-center justify-content-center">
            <h3>Machine Attendance Report</h3>
          </div>
          <FilterPublic
            formData={formData}
            setFormData={setFormData}
            filterData={filterData}
            setFilterData={setFilterData}
            handleFilter={handleFilter}
            handleResetFilter={handleResetFilter}
            filterParams={filterParams}
          />

          <MaterialTable
            title=""
            ref={tableRef}
            columns={[
              { title: "Name", field: "name" },
              {
                title: "Department",
                field: "department",
              },
              { title: "Designation", field: "designationName" },
              { title: "Pay Bill", field: "payBill" },
              { title: "Date", field: "dateStr" },
              { title: "Machine In", field: "machineInName" },
              { title: "In Time", field: "inTime" },
              { title: "Machine Out", field: "machineOutName" },
              { title: "Out Time", field: "outTime" },
              { title: "", field: "visibilityIcon" },
            ]}
            data={updatedData}
            options={{
              grouping: true,
              sorting: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              search: false,
              toolbar: false,
              paging: true,
            }}
            style={{
              maxWidth: menuOpen ? "calc(100vw )" : "",
              fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
              margin: "2px",
            }}
          />
          {/* 
          <FilterReport
            open={open}
            setOpen={setOpen}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          /> */}
          {/* <div style={{ width: "100%" }}>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500, 1000]}
              component="div"
              count={listData?.count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div> */}
        </div>
      )}
    </>
  );
};

export default MachineAttendanceReport;
