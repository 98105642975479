import * as React from "react";
import { FC, memo, useState } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
// import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
// import helperFunc from "../helperFuncs";
// const useStyles = makeStyles((theme) => ({
//     root: {
//         minWidth: "253px",
//     },
//     avatar: {
//         marginRight: theme.spacing(1),
//     },
//     company_id: {
//         display: "none",
//     },
// }));
interface State {
  states?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const MultiSelectStateInput: FC<Props> = ({ filter }) => {
  // const user = helperFunc.getUserData();
  // const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("states/multiple-search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          states: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { states } = state;

  return (
    <AutocompleteArrayInput
      resettable
      label="States"
      source="dataRights.state"
      choices={states}
    />
    // <ReferenceArrayInput
    //   resettable
    //   label="States"
    //   source="state"
    //   reference="states"
    //   // filter={{ company: formData.company, isActive: true }}
    // // validate={requiredValidate}
    // // formClassName={classes.referenceEmpArray}
    // >
    //   <AutocompleteArrayInput resettable />
    // </ReferenceArrayInput>
  );
};
// const requiredValidate = [required()];
MultiSelectStateInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectStateInput);
