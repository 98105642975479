import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Labeled } from "react-admin";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FieldProps, Attendance } from "../types";
import helperFunc from "../helperFuncs";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  redButton: {
    color: "red",
  },
  flex: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    flexDirection: "row",
  },
  flexChild: {
    display: "inline-block",
    width: "100%",
    padding: "0 15px",
    maxWidth: "100%",
  },
  twoCol: {
    display: "contents",
    width: "50%",
    overflow: "hidden",
    maxWidth: "50%",
  },
  twoColChild: { display: "inline-block", width: "100%", maxWidth: "50%" },
  blueButton: {
    color: "blue",
  },
  typeclass: {
    alignItems: "center",
  },
  lblName: {
    right: theme.spacing(0),
  },
  lblLocation: {
    right: theme.spacing(0),
  },
  lblDeLocation: {
    left: theme.spacing(1),
  },
  maskClass: {
    left: theme.spacing(0),
    right: theme.spacing(0),
  },
  overflowXHide: { overflowX: "hidden" },
  lblDate: {
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  mode: {
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  edit: {
    float: "right",
  },
}));

interface State {
  images?: string;
}

const AttandanceShow: FC<FieldProps<Attendance>> = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const mode: any = {
    mobile: "Mobile",
    edge: "Edge",
    manual: "Manual",
    cron: "Auto",
    auto: "Auto",
    Kiosk: "Kiosk",
    "turnout-A": "Turnout A",
  };
  const location: any = {
    in: "In",
    out: "Out",
  };

  return record ? (
    <div className={classes.root}>
      <IconButton aria-label="Show" onClick={handleClick}>
        <VisibilityIcon
          className={
            record && record.employee && record.employee.type === "visitor"
              ? classes.redButton
              : classes.blueButton
          }
        />
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          classes={classes}
          onClose={handleClose}
        >
          Attendance Detail
        </DialogTitle>

        <DialogContent dividers className="overflowXHide">
          <Box component="div" m={1} className={classes.flex}>
            <Box component="div" m={1} className={classes.twoCol}>
              <img
                style={{ alignItems: "center", height: "auto", width: "500px" }}
                src={uploadApiUrl + record.image}
                alt={record.image}
              />
            </Box>
            <Box component="div" m={1} className={classes.twoCol}>
              <Box component="div" m={1} className={classes.flexChild}>
                <Labeled label="Name" className={classes.twoColChild}>
                  <Typography component="div" id="name" align="left">
                    {record && record.employee && record.employee.name}
                  </Typography>
                </Labeled>
                <Labeled label="Date Time" className={classes.twoColChild}>
                  <Typography component="div" id="time" align="left">
                    {helperFunc.commonDateFormat(
                      record && record.time,
                      "HH:mm:ss"
                    )}
                    {/* {helperFunc.localDateAndTime(record && record.time)} */}
                  </Typography>
                </Labeled>

                <Labeled label="Entry" className={classes.twoColChild}>
                  <Typography component="div" id="location">
                    {record && location[record.authType]}
                  </Typography>
                </Labeled>

                <Labeled label="Mode" className={classes.twoColChild}>
                  <Typography component="div" id="time">
                    {record && mode[record.mode]}
                  </Typography>
                </Labeled>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export default AttandanceShow;
