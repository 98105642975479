import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  NumberInput,
  maxLength,
  number,
  DateInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Shift, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { maxValue, minValue } from "../TurnoutAdmin";
import { shiftStyles } from "../helperStyle";
import helperFunc from "../helperFuncs";

// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(shiftStyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.shifts.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <div style={{ display: "inline" }}>
        <TextInput
          autoFocus
          resource="shifts"
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
        />
        <TextInput
          source="code"
          resource="shifts"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={codeValidate}
          format={helperFunc.codeFormatter}
        />
      </div>
      <SectionTitle label={"Full Shift (24 Hours)"} />
      <div style={{ display: "inline" }}>
        <TextInput
          label="resources.shifts.fields.full_intime"
          source="full.inTime"
          type="time"
          resource="shifts"
          // options={{ format: 'HH:mm', ampm: false, clearable: true }}
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          label="resources.shifts.fields.full_outtime"
          type="time"
          source="full.outTime"
          resource="shifts"
          // options={{ format: 'HH:mm', ampm: false, clearable: true }}
          formClassName={isXsmall ? classes.name : classes.code}
          validate={requiredValidate}
        />
        <NumberInput
          label="resources.shifts.fieldGroups.working_hours"
          source="workingHours.full"
          resource="shifts"
          formClassName={classes.name}
          validate={[required(), number(), maxValue(24), minValue(0)]}
        />
      </div>

      <SectionTitle label={"Half Shift (24 Hours)"} />
      <div style={{ display: "inline" }}>
        <TextInput
          label="resources.shifts.fields.half_intime"
          source="half.inTime"
          type="time"
          // options={{ format: 'HH:mm', ampm: false, clearable: true }}
          resource="shifts"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          label="resources.shifts.fields.half_outtime"
          type="time"
          source="half.outTime"
          resource="shifts"
          // options={{ format: 'HH:mm', ampm: false, clearable: true }}
          formClassName={isXsmall ? classes.name : classes.code}
          validate={requiredValidate}
        />
        <NumberInput
          label="resources.shifts.fieldGroups.working_hours"
          source="workingHours.half"
          resource="shifts"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={[required(), number(), maxValue(24), minValue(0)]}
        />
      </div>
      <SectionTitle label="resources.shifts.fieldGroups.working_days" />
      <BooleanInput
        formClassName={classes.name}
        label="label.monday"
        source="workingDays.isMonday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.tuseday"
        source="workingDays.isTuseday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.wednesday"
        source="workingDays.isWednesday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.thursday"
        source="workingDays.isThursday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.friday"
        source="workingDays.isFriday"
        resource="shifts"
      />

      <BooleanInput
        formClassName={classes.name}
        label="label.saturday"
        source="workingDays.isSaturday"
        resource="shifts"
      />

      <BooleanInput
        formClassName={classes.name}
        label="label.sunday"
        source="workingDays.isSunday"
        resource="shifts"
      />

      <SectionTitle label="label.status" />

      <BooleanInput label="label.status" source="isActive" resource="shifts" />
    </SimpleForm>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.shifts.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        resource="shifts"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
      />
      <TextInput
        source="code"
        resource="shifts"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <SectionTitle label={"Full Shift (24 Hours)"} />
      <TextInput
        label="resources.shifts.fields.full_intime"
        source="full.inTime"
        type="time"
        resource="shifts"
        // options={{ format: 'HH:mm', ampm: false, clearable: true }}
        formClassName={classes.name}
        validate={requiredValidate}
      />
      <TextInput
        label="resources.shifts.fields.full_outtime"
        type="time"
        source="full.outTime"
        resource="shifts"
        // options={{ format: 'HH:mm', ampm: false, clearable: true }}
        formClassName={isXsmall ? classes.name : classes.code}
        validate={requiredValidate}
      />
      <NumberInput
        label="resources.shifts.fieldGroups.working_hours"
        source="workingHours.full"
        resource="shifts"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={[required(), number(), maxValue(24), minValue(0)]}
      />
      <SectionTitle label={"Half Shift (24 Hours)"} />
      <TextInput
        label="resources.shifts.fields.half_intime"
        source="half.inTime"
        type="time"
        // options={{ format: 'HH:mm', ampm: false, clearable: true }}
        resource="shifts"
        formClassName={classes.name}
        validate={requiredValidate}
      />
      <TextInput
        label="resources.shifts.fields.half_outtime"
        type="time"
        source="half.outTime"
        resource="shifts"
        // options={{ format: 'HH:mm', ampm: false, clearable: true }}
        formClassName={isXsmall ? classes.name : classes.code}
        validate={requiredValidate}
      />
      <NumberInput
        label="resources.shifts.fieldGroups.working_hours"
        source="workingHours.half"
        resource="shifts"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={[required(), number(), maxValue(24), minValue(0)]}
      />
      <SectionTitle label="resources.shifts.fieldGroups.working_days" />
      <BooleanInput
        formClassName={classes.name}
        label="label.monday"
        source="workingDays.isMonday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.tuseday"
        source="workingDays.isTuseday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.wednesday"
        source="workingDays.isWednesday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.thursday"
        source="workingDays.isThursday"
        resource="shifts"
      />
      <BooleanInput
        formClassName={classes.name}
        label="label.friday"
        source="workingDays.isFriday"
        resource="shifts"
      />

      <BooleanInput
        formClassName={classes.name}
        label="label.saturday"
        source="workingDays.isSaturday"
        resource="shifts"
      />

      <BooleanInput
        formClassName={classes.name}
        label="label.sunday"
        source="workingDays.isSunday"
        resource="shifts"
      />

      <SectionTitle label="label.status" />

      <BooleanInput label="label.status" source="isActive" resource="shifts" />
    </SimpleForm>
  );
};
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const ShiftTitle: FC<FieldProps<Shift>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
