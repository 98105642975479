import CourseList from "./courseList";
import { CommonCourse } from "./courseCommon";
import { CourseIcon } from "../svgIcon";

export default {
  list: CourseList,
  create: CommonCourse,
  edit: CommonCourse,
  icon: CourseIcon,
};
