import * as React from "react";
import { FC, useCallback, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  makeStyles,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useTranslate, Link } from "react-admin";
import DialogueBox from "../DialogueBox";
import { requestCardStyle } from "../../helperStyle";
import { useMediaQuery, Theme } from "@material-ui/core";

interface Props {
  value?: number;
  data?: any;
  data2?: any;
  actualData?: any;
  record?: any;
  isLoading?: boolean;
}

const useStyles = makeStyles(requestCardStyle);

const AttendanceCard: FC<Props> = ({ data = {}, data2 = [], isLoading }) => {
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const actualData = data;
  const dataObj: any = data2 || {};
  const headers = () => {
    if (activeSlice === "Pending Leave") {
      return [
        { label: "Leave Type", key: "leaveType.code" },
        { label: "From", key: "fromDateStr" },
        { label: "To", key: "toDateStr" },
        { label: "Leave Days", key: "leaveDays" },
      ];
    } else if (activeSlice === "Approved Leave") {
      return [
        { label: "Updated By", key: "updatedBy.name" },
        { label: "Leave Type", key: "leaveType.code" },
        { label: "Description", key: "description" },
        { label: "Leave Days", key: "leaveDays" },
        { label: "From", key: "fromDateStr" },
        { label: "To", key: "toDateStr" },
        { label: "Updated At", key: "UpdatedAt" },
      ];
    } else {
      return [
        { label: "Updated By", key: "updatedBy.name" },
        { label: "Leave Type", key: "leaveType.code" },
        { label: "Description", key: "description" },
        { label: "Leave Days", key: "leaveDays" },
        { label: "From", key: "fromDateStr" },
        { label: "To", key: "toDateStr" },
        { label: "Reason", key: "reason" },
        { label: "Updated At", key: "UpdatedAt" },
      ];
    }
  };

  const object: any = {
    "Pending Leave": "pending",
    "Rejected Leave": "rejected",
    "Approved Leave": "approved",
  };

  let dataBySlice = dataObj[object[activeSlice]];

  // const dataObj = {
  //   ...{ pending: 0, cancelled: 0, rejected: 0, approved: 0 },
  //   actualData,
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = useCallback((data: any) => {
    // setActiveIndex(index);
    setOpen(true);
    setActiveSlice(data);
    setPage(0);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <Card className={isXSmall ? classes.mobileRoot : classes.root}>
      <CardHeader title={translate("pos.dashboard.leave_app")} />
      <DialogueBox
        dialogueData={dataBySlice}
        open={open}
        handleClose={handleClose}
        activeSlice={activeSlice}
        headerArr={headers()}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={[]}
        isExport={false}
      />
      {isLoading ? (
        <span
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <>
          <Box className={classes.cardImg}>
            <img
              src="icons/leave_application.png"
              title={translate("pos.dashboard.general_detail")}
            ></img>
          </Box>
          <CardContent className={classes.card}>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to={`/leave-management?filter=%7B"isApprove"%3A"pending"%7D&order=DESC&page=1&perPage=20&sort=createdAt`}
                onClick={() => handleClickOpen("Pending Leave")}
              >
                Pending (
                {actualData && actualData.pending ? actualData.pending : 0})
              </Link>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to={`/leave-management?filter=%7B"isApprove"%3A"reject"%7D&order=DESC&page=1&perPage=20&sort=createdAt`}
                onClick={() => handleClickOpen("Rejected Leave")}
              >
                Rejected (
                {actualData && actualData.rejected ? actualData.rejected : 0})
              </Link>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Link
                // to={`/leave-management?filter=%7B"isApprove"%3A"approve"%7D&order=DESC&page=1&perPage=20&sort=createdAt`}
                onClick={() => handleClickOpen("Approved Leave")}
              >
                Approved (
                {actualData && actualData.approved ? actualData.approved : 0})
              </Link>
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default AttendanceCard;
