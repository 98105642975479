import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from '../states/commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';


const StateEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.states.page.edit"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};



export default StateEdit;
