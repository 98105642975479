import { toastError } from "./toast";

export const exporterDetails = (dataProvider: any, props: any) => {
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  dataProvider
    .callCustom(`${props.resource}/export`, {})
    .then((response: any) => {
      window.open(
        `${apiUrlUpload}${
          response &&
          response.data &&
          response.data.item &&
          response.data.item.fileName
        }`,
        "_blank"
      );
    })
    .catch((err: any) => {
      toastError(err.message);
    });
};
