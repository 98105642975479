import * as React from "react";
import { FC, memo, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";
interface State {
  shifts?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  updateShiftData?: any;
  emptyValue?: any;
  labelName?: any;
}

const WorkplaceShiftSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  updateShiftData,
  labelName,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  // let data = [];

  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("workplaces/workplace_shift_search", filter)
      .then((response: any) => {
        response &&
          response.data.forEach((item: any) => {
            item.name = item && item.name;
          });
        setState((state) => ({
          ...state,
          shifts: response && response.data,
          // newDeparments: response && response.data
        }));

        if (updateShiftData) {
          updateShiftData(response && response.data);
        }
      });
  }, [filter]);

  if (emptyValue && emptyValue.emptyValue) {
    form.change("shiftId", undefined);
  }

  const { shifts } = state;
  const check = (value: any) => {
    shifts?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "shiftId");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Shift"}
      disabled={record && record.mode === "auto" ? true : false}
      source="shiftId"
      validate={validate}
      choices={shifts}
      onChange={(value: any) => check(value.target)}
    />
  );
};
// const requiredValidate = [required()];
WorkplaceShiftSelectInput.defaultProps = {
  source: "shiftId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(WorkplaceShiftSelectInput);
