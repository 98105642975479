import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  useMediaQuery,
  Theme,
  useTheme,
  Drawer,
  makeStyles,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import {
  useDataProvider,
  FormDataConsumer,
  DateInput,
  TopToolbar,
} from "react-admin";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Loader } from "semantic-ui-react";

import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { Form } from "react-final-form";
import { useForm, useField } from "react-final-form-hooks";
import helperFunc from "../helperFuncs";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { attendanceSummaryStyle } from "../helperStyle";
import { toastError, toastInfo } from "../components/toast";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import moment from "moment";

const FilterReport = ({
  open,
  setOpen,
  filterParams,
  setFilterParams,
  handleDrawerOpen,
  page,
  rowsPerPage,
  count,
}: any) => {
  const onSubmit = (value: any) => {
    setFilterParams({
      ...filterParams,
      filter: {
        employee: value.employee,
        time_to: moment.utc(moment(value.time_to).utc()).format(),
        time_from: moment.utc(moment(value.time_from).utc()).format(),
        childCompanyId: value.childCompanyId,
      },
    });
    handleDrawerClose();
  };
  const validate: any = () => {};
  const useStyles = makeStyles((theme) => ({
    tableCell: { border: "solid 2px" },
    tableHeadCell: { border: "solid 2px", textAlign: "center" },
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    filter: {
      justifyContent: "flex-start",
    },
    smCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.8125rem !important",
    },
    lgCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.850rem !important",
      alignContent: "right !important",
    },
    exportIcon: {
      fontSize: 20,
    },
    csvSpan: { paddingLeft: "0.5em" },
  }));
  const theme = useTheme();
  const classes = useStyles();

  const { form, handleSubmit } = useForm({ onSubmit, validate });
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const drawerWidth = 400;

  const dataProvider = useDataProvider();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetFilter = () => {
    setFilterParams({
      skip: page,
      limit: rowsPerPage,
      sortBy: "name",
      sort: "asc",
      filter: {
        time_from: moment.utc(new Date()).startOf("month").toDate(),
        time_to: moment.utc(moment(new Date()).utc()).format(),
      },
    });

    handleDrawerClose();
  };
  const exporterDetails = () => {
    dataProvider
      .callCustom("employee-leave-report/export", {
        ...filterParams,
        limit: count,
      })
      .then((response: any) => {
        if (response && response.data && response.data.fileName) {
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };
  return (
    <>
      <div className="employee-leave-report">
        <TopToolbar>
          <div style={{ paddingRight: "15px", top: 0 }}>
            <Button
              color="primary"
              component="span"
              onClick={() => handleDrawerOpen()}
            >
              <FilterListIcon />
              {"Filter"}
            </Button>
            <Button
              className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
              color="primary"
              component="span"
              onClick={exporterDetails}
            >
              <GetAppIcon className={classes.exportIcon} />
              {!isSmall ? (
                <span className={classes.csvSpan}>Export</span>
              ) : null}
            </Button>
          </div>
        </TopToolbar>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className="employee-filter">
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => handleDrawerClose()}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            <Form onSubmit={onSubmit}>
              {(props: any) => (
                <form onSubmit={props.handleSubmit}>
                  <Box component="div">
                    <CompanyReferenceInput></CompanyReferenceInput>
                  </Box>

                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <EmployeeSelectInput
                            helperText={false}
                            filter={{
                              company: formData.company,
                              isActive: true,
                            }}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            source="time_from"
                            label="label.fromDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            initialValue=""
                            source="time_to"
                            label="label.toDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <div className="actionbutton">
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      onSubmit={() => handleSubmit}
                    >
                      Apply
                    </Button>
                    <Button variant="outlined" onClick={resetFilter}>
                      Reset
                    </Button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const EmployeeLeaveReport = (props: any): any => {
  const user = helperFunc.getUserData();

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const useStyles = makeStyles(attendanceSummaryStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [responseData, setResponseData] = useState({
    list: [],
    leaveTypes: [],
    holidayDates: [],
    count: "" as unknown as number,
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState({
    skip: page,
    limit: rowsPerPage,
    sortBy: "name",
    sort: "asc",
    filter: {
      time_from: moment.utc(new Date()).startOf("month").toDate(),
      time_to: moment.utc(moment(new Date()).utc()).format(),
    },
  });

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setFilterParams({
      ...filterParams,
      skip: newPage * rowsPerPage,
    });
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
    setFilterParams({
      ...filterParams,
      limit: parseInt(event.target.value, 10),
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Employee Leave Report";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const empLeaveReportUrl = "employee-leave-report/list";
  useEffect(() => {
    dataProvider
      .callCustom(empLeaveReportUrl, filterParams)
      .then((response: any) => {
        if (response) {
          setLoading(false);
          setResponseData(response.data);
        }
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, [filterParams, page]);

  const updatedWidth = "18%" as any;

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          style={{
            width: window.screen.width - updatedWidth,
            overflow: isXSmall ? "scroll" : "auto",
          }}
        >
          <FilterReport
            open={open}
            setOpen={setOpen}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            handleDrawerOpen={handleDrawerOpen}
            page={page}
            rowsPerPage={rowsPerPage}
            count={responseData?.count}
          />
          {/* <div>
        ALB: Allocated Leave Balance, UL: Utilized Leave, RL: Requested Leave,
        AB : Available Leave Balance
      </div> */}
          {isXSmall ? (
            <h2
              style={{
                height: 40,
                fontSize: 30,
                backgroundColor: "#fff",
                padding: 10,
              }}
            >
              Employee Leave Report
            </h2>
          ) : null}

          {responseData.list && responseData.list.length === 0 ? (
            <p
              style={{
                textAlign: "center",
                marginRight: isXSmall ? undefined : "350px",
              }}
            >
              No Record Found
            </p>
          ) : (
            <div className={isXSmall ? "" : classes.rtable}>
              <TableContainer className={isXSmall ? "" : classes.container}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          left: 0,
                          position: "sticky",
                          width: "125px !important",
                          minWidth: "125px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Name
                      </TableCell>

                      {responseData.list
                        ? responseData &&
                          responseData.leaveTypes &&
                          responseData.leaveTypes.map(
                            (leave: any, index: number) => {
                              return (
                                <TableCell
                                  className={classes.header}
                                  colSpan={4}
                                >
                                  {leave?.leaveName}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                      <TableCell
                        style={{
                          left: user.isClientConfiguration ? 300 : 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                      ></TableCell>
                      <TableCell
                        style={{
                          left: user.isClientConfiguration ? 300 : 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                      ></TableCell>
                    </TableRow>
                    <TableRow>
                      <></>
                      {responseData.list
                        ? responseData &&
                          responseData.leaveTypes &&
                          responseData.leaveTypes.map(
                            (leave: any, index: number) => {
                              return (
                                <>
                                  <TableCell className={classes.tableHead}>
                                    ALB
                                  </TableCell>

                                  <TableCell className={classes.tableHead}>
                                    UL
                                  </TableCell>

                                  <TableCell className={classes.tableHead}>
                                    RL
                                  </TableCell>
                                  <TableCell className={classes.tableHead}>
                                    AB
                                  </TableCell>
                                </>
                              );
                            }
                          )
                        : null}
                      <TableCell className={classes.tableHead}>WSL</TableCell>
                      <TableCell className={classes.tableHead}>TL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ justifyContent: "center" }}>
                    {responseData &&
                      responseData.list &&
                      responseData.list.map(
                        (employeeData: any, index: number) => {
                          return (
                            <TableRow hover role="checkbox">
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 300 : 0,
                                  position: "sticky",
                                  width: "125px !important",
                                  minWidth: "125px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCellEmp}
                              >
                                {employeeData && employeeData.name}
                              </TableCell>

                              {responseData !== undefined &&
                              responseData?.leaveTypes !== undefined
                                ? responseData?.leaveTypes.map(
                                    (leave: any, index: number) => {
                                      return (
                                        <>
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {`${
                                              employeeData[
                                                leave._id + "_leavesPerYear"
                                              ]
                                            }`}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {`${
                                              employeeData[
                                                leave._id + "_approved"
                                              ]
                                            }`}
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {`${
                                              employeeData[
                                                leave._id + "_requestCnt"
                                              ]
                                            }`}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}
                                          >
                                            {`${
                                              employeeData[
                                                leave._id + "_pending"
                                              ]
                                            }`}
                                          </TableCell>
                                        </>
                                      );
                                    }
                                  )
                                : null}
                              <TableCell className={classes.tableCell}>
                                {employeeData &&
                                  employeeData.withoutSystemLeave}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.totalLeave}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{
                            textAlign: "center",
                            marginRight: isXSmall ? undefined : "350px",
                          }}
                        >
                          <TableCell
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              marginRight: isXSmall ? undefined : "350px",
                            }}
                          >
                            No record found
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex">
                <div className={classes.noteTextColor} style={{ width: "50%" }}>
                  <div className="d-flex">
                    <div className={classes.w50}>
                      <span className={classes.textColor}>ALB</span>: Allocated
                      Leave Balance,
                    </div>
                    <div className={classes.w50}>
                      <span className={classes.textColor}>UL</span>: Utilized
                      Leave,
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className={classes.w50}>
                      <span className={classes.textColor}>RL</span>: Requested
                      Leave,
                    </div>
                    <div className={classes.w50}>
                      <span className={classes.textColor}>AB</span>: Available
                      Leave Balance,
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className={classes.w50}>
                      <span className={classes.textColor}>WSL</span>: Without
                      System Leave,
                    </div>
                    <div className={classes.w50}>
                      <span className={classes.textColor}>TL</span>: Total
                      Leave.
                    </div>
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={(responseData && responseData.count) || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}{" "}
    </>
  );
};
export default EmployeeLeaveReport;
