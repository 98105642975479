import ZoneList from "./ZoneList";
import ZoneEdit from "./ZoneEdit";
import ZoneCreate from "./ZoneCreate";
import { ZoneIcon } from "../svgIcon";

export default {
  list: ZoneList,
  create: ZoneCreate,
  edit: ZoneEdit,
  icon: ZoneIcon,
};
