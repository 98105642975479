import { InviteVisitorsForm } from "./InviteVisitorsCommon";
import { FacultyIcon } from "../svgIcon";
import InviteVisitorsList from "./inviteVisitorsList";
import ExaCommonForm from "./exaCommonForm";

export default {
  list: InviteVisitorsList,
  create: InviteVisitorsForm,
  edit: InviteVisitorsForm,
  icon: FacultyIcon,
};
