import React, { Fragment, useState } from "react";
import {
  TextField,
  Datagrid,
  BulkDeleteButton,
  List,
  FormDataConsumer,
  useDataProvider,
  useNotify,
  FunctionField,
  Pagination,
} from "react-admin";
import FinalReportLinkField from "./FinalReportLinkField";
import { Form } from "react-final-form";
import { useForm } from "react-final-form-hooks";
import { attendanceExtraDuty } from "../helperStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, useMediaQuery, Theme } from "@material-ui/core";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import StateSelectInput from "../components/StateSelectInput";
import helperFunc from "../helperFuncs";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { toastError } from "../components/toast";

const FinalReportList = (props: any) => {
  const approvalStatus: any = {
    Pending: "ATTENDANCE NOT SENT FOR APPROVAL",
    Approved: "APPROVED",
    Send_for_approval: "REQUEST SENT FOR APPROVAL",
    Send_for_rectification: "CLIENT ASKED FOR CORRECTION",
  };
  const onSubmit = (values: any) => {
    setViewLoading(true);
    // if (values && !values.clientId && !values.clientLocationId) {
    //   return toastError("Please Select Client and Client Location.");
    // } else if (values && !values.clientId) {
    //   return toastError("Please Select Client.");
    // } else if (values && !values.clientLocationId) {
    //   return toastError("Please Select Client Location.");
    // } else {

    let params;

    if (user?.isClientConfiguration) {
      params = {
        company: values.company || undefined,
        childCompanyId: values.childCompanyId || undefined,
        state: values.state || undefined,
        clientId: values.clientId || undefined,
        clientLocationId: values.clientLocationId || undefined,
        downloadType: "",
      };
    } else {
      params = {
        company: values.company || undefined,
        childCompanyId: values.childCompanyId || undefined,
        state: values.state || undefined,
        downloadType: "",
      };
    }

    setViewLoading(false);
    setFilterParams(params);
    // }
  };
  const useStyles = makeStyles(attendanceExtraDuty);
  const notify = useNotify();
  const { form, handleSubmit } = useForm({ onSubmit });
  const [viewlLoading, setViewLoading] = useState<any>(null);
  const classes = useStyles();
  const user = helperFunc.getUserData();
  const [filterParams, setFilterParams] = useState<any>({
    isInternalApproved: true,
  });
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const FinalReportFilter = () => {
    return (
      <React.Fragment>
        <Form initialValues={filterParams} onSubmit={onSubmit}>
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              <Box component="div">
                <CompanyReferenceInput></CompanyReferenceInput>
                <Box
                  flexGrow={1}
                  component="div"
                  className={isXSmall ? undefined : classes.flexCol}
                >
                  <Box
                    component="div"
                    className={isXSmall ? classes.mleft32 : undefined}
                  >
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <ChildCompanySelectInput
                          filter={{ isActive: true, company: formData.company }}
                        ></ChildCompanySelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                  <Box component="div" className={classes.mleft32}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <StateSelectInput
                          filter={{ isActive: true, company: formData.company }}
                        ></StateSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                  {user?.isClientConfiguration && (
                    <Box component="div" className={classes.mleft32}>
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ClientMasterSelectInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                              childCompanyId: formData.childCompanyId,
                            }}
                          ></ClientMasterSelectInput>
                        )}
                      </FormDataConsumer>
                    </Box>
                  )}
                </Box>
                {user?.isClientConfiguration && (
                  <Box
                    component="div"
                    className={isXSmall ? classes.mleft32 : undefined}
                  >
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <ClientLocationSelectInput
                          filter={{
                            isActive: true,
                            company: formData.company,
                            clientId: formData.clientId,
                          }}
                        ></ClientLocationSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                )}

                <Box className={isXSmall ? classes.mleft32 : undefined}>
                  <Button
                    variant="contained"
                    style={{ marginTop: "7px" }}
                    color="primary"
                    type="submit"
                    disabled={viewlLoading}
                    onSubmit={() => handleSubmit}
                  >
                    {viewlLoading && (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        style={{ color: "aliceblue" }}
                      />
                    )}
                    VIEW
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {isXSmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Final Report
        </h2>
      ) : null}
      <FinalReportFilter />
      <div
        style={{
          marginTop: isXSmall ? 0 : -35,
          width: isXSmall
            ? window.screen.width
            : !open
            ? window.screen.width - 70
            : window.screen.width - 250,
          overflowX: "scroll",
        }}
      >
        <List
          className="list-view2"
          bulkActionButtons={false}
          title="Final Report"
          sort={{ field: "createdAt", order: "DESC" }}
          perPage={20}
          filterDefaultValues={filterParams}
          {...props}
          exporter={false}
          pagination={
            <Pagination
              labelRowsPerPage="Records per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          }
        >
          <Datagrid>
            <FinalReportLinkField label="label.reportId" />
            <TextField source="childCompanyId.name" label="label.companySub" />
            {user?.isClientConfiguration && (
              <TextField source="clientId.name" label="label.client" />
            )}
            {user?.isClientConfiguration && (
              <TextField
                style={{
                  display: "inline-block",
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                source="clientLocationId.name"
                label="label.clientlocation"
              />
            )}

            <FunctionField
              label="label.fromDate"
              render={(record: any) =>
                `${
                  record["fromDate"]
                    ? helperFunc.commonDateFormat(record["fromDate"])
                    : "N/A"
                }  `
              }
            />
            <FunctionField
              label="label.toDate"
              render={(record: any) =>
                `${
                  record["toDate"]
                    ? helperFunc.commonDateFormat(record["toDate"])
                    : "N/A"
                }  `
              }
            />
            <TextField source="generatedBy.name" label="label.generatedBy" />
            <FunctionField
              label="label.generatedAt"
              sortBy="createdAt"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <FunctionField
              label="label.status"
              sortBy="isApproved"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record &&
                  record.isApproved &&
                  approvalStatus[record.isApproved]
                    ? approvalStatus[record.isApproved]
                    : "N/A"
                } `
              }
            />
            {/* <TextField source="isApproved" label="label.status" /> */}
            {/* <FunctionField
                            label="label.approvedBy"
                            // sortByOrder="DESC"
                            render={(record: any) => `${record && record.isApproved === "Approved" ? record.approvedby && record.approvedby.name ? record.approvedby.name : "N/A" : "N/A"} `}
                        /> */}
            <FunctionField
              label="label.approvedBy"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record && record.isApproved === "Approved"
                    ? record.approvedby && record.approvedby.name
                      ? record.approvedby.name
                      : "N/A"
                    : "N/A"
                } `
              }
            />
            <FunctionField
              label="label.approvalEmail"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record && record.isApproved === "Approved"
                    ? record.approvalEmail
                      ? record.approvalEmail
                      : "N/A"
                    : "N/A"
                } `
              }
            />

            <FunctionField
              label="label.approvedAt"
              sortBy="approvedAt"
              render={(record: any) =>
                `${
                  record && record.isApproved === "Approved"
                    ? record["approvedAt"]
                      ? helperFunc.commonDateFormat(
                          record["approvedAt"],
                          "HH:mm:ss"
                        )
                      : "N/A"
                    : "N/A"
                }  `
              }
            />
          </Datagrid>
        </List>
      </div>
    </React.Fragment>
  );
};
export default FinalReportList;
