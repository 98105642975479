import RegionList from "./RegionList";
import RegionEdit from "./RegionEdit";
import RegionCreate from "./RegionCreate";
import { RegionIcon } from "../svgIcon";

export default {
  list: RegionList,
  create: RegionCreate,
  edit: RegionEdit,
  icon: RegionIcon,
};
