import * as React from "react";
import { FC } from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { linkToRecord } from "ra-core";
// import { NumberField } from "react-admin";
import { Link } from "react-router-dom";
import { DatagridProps, Employee } from "../types";
import EmployeeLogo from "./EmployeeLogo";
// import RegistrationToolbar from "./RegistrationToolbar";
import FolderIcon from "@material-ui/icons/Folder";

import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import helperFunc from "../helperFuncs";
import { EditButton } from "react-admin";
import { stringify } from "query-string";
import authProvider from "../authProvider";
import GenerateQrEmployee from "./generateQrEmployee";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-2px",
  },
  gridList: {
    width: "100%",
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  menuButton: {
    color: "#00008b",
  },
  editButton: {
    top: "47px",
    color: "#00008b",
    right: "0px",
    width: "40px",
    height: "40px",
    position: "absolute",
    maxWidth: "40px",
    minWidth: "initial",
    marginTop: "-182px",
    borderRadius: "100%",
  },
  qrButton: {
    top: "47px",
    color: "#00008b",
    right: "35px",
    width: "25px",
    height: "25px",
    position: "absolute",
    maxWidth: "40px",
    minWidth: "initial",
    marginTop: "-176px",
    borderRadius: "30%",
    cursor: "pointer",
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
  width,
  nbItems = 20,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {times(nbItems, (key) => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadedGridList: FC<
  GridProps & {
    setOpen?: any;
    open?: any;
    employeeData: any;
    setEmployeeData: any;
  }
> = ({
  ids,
  data,
  basePath,
  width,
  open,
  setOpen,
  employeeData,
  setEmployeeData,
}) => {
  const classes = useStyles();
  if (!ids || !data) return null;

  const user = helperFunc.getUserData();

  const handleViewQR = (idx: any) => {
    setOpen(true);
    setEmployeeData(idx);
  };

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {ids.map((id) => (
          <GridListTile
          // // @ts-ignore
          // component={Link}
          // key={id}
          // to={linkToRecord(basePath, data[id].id)}
          >
            <EmployeeLogo record={data[id]}></EmployeeLogo>

            <GridListTileBar
              className={classes.tileBar}
              title={data[id].name}
              actionIcon={[
                user.designation === "HR" ||
                user.type === "super" ||
                user.type === "company" ||
                authProvider.isMenuShow("employees", "update") ? (
                  <EditButton
                    label=""
                    className={classes.editButton}
                    basePath={basePath}
                    record={data[id]}
                  />
                ) : null,

                user?.company?.isVisitorGatepass && (
                  <img
                    // label=""
                    className={classes.qrButton}
                    // basePath={basePath}
                    // record={data[id]}
                    src="icons/qrblue.png"
                    onClick={() => handleViewQR(data && data[id])}
                  />
                ),

                // <div style={{ margin: "-11px" }}>
                //   {/* {(user?.type === "super" ||
                //     (authProvider.isMenuShow("employee-document") &&
                //       user?.isDocumentManagement)) && ( */}
                //   <Button
                //     size="small"
                //     color="primary"
                //     component={Link}
                //     to={{
                //       pathname: "/employee-document",
                //       search: stringify({
                //         filter: JSON.stringify({
                //           employee: data[id].id,
                //         }),
                //         state: data[id].name,
                //       }),
                //     }}
                //   >
                //     <FolderIcon className={classes.menuButton} />
                //   </Button>
                //   {/* )} */}
                // </div>,
              ]}
              //   subtitle={data[id].company}
            />
          </GridListTile>
        ))}
      </MuiGridList>
      <GenerateQrEmployee open={open} setOpen={setOpen} record={employeeData} />
    </div>
  );
};

interface GridProps extends DatagridProps<Employee>, WithWidth {}

const GridList: FC<GridProps> = ({ loaded, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [employeeData, setEmployeeData] = React.useState(false);

  return loaded ? (
    <LoadedGridList
      {...props}
      setOpen={setOpen}
      open={open}
      employeeData={employeeData}
      setEmployeeData={setEmployeeData}
    />
  ) : (
    <LoadingGridList {...props} />
  );
};

export default withWidth()(GridList);
