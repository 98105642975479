import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "./CommonForm";

const NotificationCreate = (props: any) => {
  return (
    <Create {...props} title={"Add Alert"}>
      <CommonForm redirect={"/notifications"} {...props} />
    </Create>
  );
};

export default NotificationCreate;
