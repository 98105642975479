import React from "react";
import { required, maxLength, number, email } from "react-admin";
import { regex, useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";
import {
  regexOnlyNumber,
  regexSpecialChar,
  regexWhitespace,
} from "./helperConst";

export const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export const nameValidate = [
  required(),
  maxLength(50),
  regexWhitespace,
  // regex(/^[aA-zZ\s]+$/, "Invalid entry. Name accepts only characters"),
];
export const numberValidate = [
  required(),
  maxLength(10),
  regexOnlyNumber,
  // regex(/^[aA-zZ\s]+$/, "Invalid entry. Name accepts only characters"),
];

export const nameAlphaValidate = [
  required(),
  maxLength(50),
  regexWhitespace,
  regexSpecialChar,
];
export const spaceValidate = [
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export const validate = [required()];

export const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const mobValidate = [required(), number()];
export const altmobValidate = [number()];
export const validateEmail = [required(), email()];

export const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const PHONE_NEGLECT_REGEX = /^[7-9]\d{9}$/;
