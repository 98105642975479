import VisitorIcon from "@material-ui/icons/PersonOutline";
import LeaveReportList from "./LeaveReportList";
// import AttendanceEdit from "./AttendanceEdit";
// import VisitorCreate from "./VisitorCreate";

export default {
  list: LeaveReportList,
  // create: VisitorCreate,
  // edit: AttendanceEdit,
  icon: VisitorIcon,
};
