import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../clientAdmins/commonForm";


const ClientAdminCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonForm redirect={'/clientAdmins'} {...props} />
    </Create>
  );
};

export default ClientAdminCreate;
