export const myTeamArrayAttendanceHeader = [
  "my-team-statistics",
  "teamattendance",
  "team-attendance-report",
];
export const applicationArrayHeader = [
  "dateSettings",
  "reasons",
  'emailTemplates',
];

export const myTeamArrayLeaveHeader = ["teamLeaves", "teamrequests"];
export const leaveArrayHeader = ["leaveTypes", "holiday"];
export const staffArrayHeader = [
  "designations",
  "departments",
  "employees",
  "subAdmin",
];
export const companyArrayHeader = [
  "companyMasters",
  "countries",
  "zones",
  "regions",
  "states",
  "cities",
  "branchMasters",
  "workplaces",
  "clientMasters",
  "clientLocations",
];

export const leaveArray = [
  "leaves",
  "teamLeaves",
  "employee-leave-report",
  "employee-leave-balance-report",
];
export const attendanceArray = [
  "attendances",
  "unknownAttendance",
  "inviteVisitor",
  "updateRegister",
  "attendenceApproval1",
  "attendenceApproval2",
  "finalReports",
  "inviteVisitor",
];
export const visitorArray = ["inviteVisitor"];
export const aboutArray = ["aboutTurnout"];
export const masterConfigurationArray = [
  "companyMasters",
  "countries",
  "zones",
  "regions",
  "states",
  "cities",
  "branchMasters",
  "designations",
  "departments",
  "workplaces",
  "employeeType",
  "clientMasters",
  "clientLocations",
  "reasons",
  "emailTemplates",
  "employees",
  "subAdmin",
  "leaveTypes",
  "holiday",
  "document-type",
  "document-category",
  "roles",
  "employeeMultiRegistration",
  "dateSettings"
];
