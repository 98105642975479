import DepartmentList from "./DepartmentList";
import DepartmentEdit from "./DepartmentEdit";
import DepartmentCreate from "./DepartmentCreate";
import { DepartmentIcon } from "../svgIcon";

export default {
  list: DepartmentList,
  create: DepartmentCreate,
  edit: DepartmentEdit,
  icon: DepartmentIcon,
};
