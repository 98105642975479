import CityList from "./CityList";
import CityEdit from "./CityEdit";
import CityCreate from "./CityCreate";
import { CityIcon } from "../svgIcon";

export default {
  list: CityList,
  create: CityCreate,
  edit: CityEdit,
  icon: CityIcon,
};
