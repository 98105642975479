import * as React from "react";
import { FC, memo, useState } from "react";
import {
  SelectInput,
  useDataProvider
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "2530px",
  },
  name: { display: "inline-block" },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  employees?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
  value?: number;
  filter?: any;
  data?: [];
  validate?: {}
}

const EmployeeIdSelectInput: FC<Props> = ({ filter, record, validate }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  // let data = [];
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("employees/search", filter)
      .then((response: any) => {
        response && response.data.forEach((item: any) => {
          item.name = `${item.name}" "${(item.employeeCode)}`
        })
        setState((state) => ({
          ...state,
          employees: response && response.data,
          // newDeparments: response && response.data
        }));
      });
  }, [filter, dataProvider])

  const { employees } = state;

  return (
    <SelectInput
      resettable
      label="Employee"
      className={classes.name}
      source="employeeId"
      validate={validate}
      choices={employees}

    />
  );
};

EmployeeIdSelectInput.defaultProps = {
  source: "employees",
  label: "resources.customers.fields.name",
};

export default memo<Props>(EmployeeIdSelectInput);
