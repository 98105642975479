import * as React from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  maxLength,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CountryStyle } from "../helperStyle";
import { regex } from "ra-core";
import ZoneSelectInput from "../components/ZoneSelectInput";
import RegionSelectInput from "../components/RegionSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import StateSelectInput from "../components/StateSelectInput";
import CitiesSelectInput from "../components/CitiesSelectInput";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(CountryStyle);
const CommonForm = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/states/branches"
          ? "Update Branch"
          : "Add Branch"}
      </h2>
      <SimpleForm {...props}>
        {/* <SectionTitle label="resources.countries.page.create" /> */}
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.smallName}
            validate={nameValidate}
            label="Branch Name"
          />
          <TextInput
            source="code"
            formClassName={classes.smallCode}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
          <SectionTitle label="" />
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <ZoneSelectInput filter={{ isActive: true }}></ZoneSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) => (
              <RegionSelectInput
                filter={{ isActive: true, zoneId: formData.zoneId }}
              ></RegionSelectInput>
            )}
          </FormDataConsumer>
          <SectionTitle label="" />
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <StateSelectInput
                filter={{ isActive: true, regionId: formData.regionId }}
              ></StateSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) => (
              <CitiesSelectInput
                filter={{ isActive: true, state: formData.state }}
              ></CitiesSelectInput>
            )}
          </FormDataConsumer>
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.countries.page.create" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Branch Name"
      />
      <TextInput
        source="code"
        formClassName={classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <SectionTitle label="" />
      <FormDataConsumer formClassName={classes.name}>
        {({ formData }: { formData: any }) => (
          <ZoneSelectInput filter={{ isActive: true }}></ZoneSelectInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.code}>
        {({ formData }: { formData: any }) => (
          <RegionSelectInput
            filter={{ isActive: true, zoneId: formData.zoneId }}
          ></RegionSelectInput>
        )}
      </FormDataConsumer>
      <SectionTitle label="" />
      <FormDataConsumer formClassName={classes.name}>
        {({ formData }: { formData: any }) => (
          <StateSelectInput
            filter={{ isActive: true, regionId: formData.regionId }}
          ></StateSelectInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.code}>
        {({ formData }: { formData: any }) => (
          <CitiesSelectInput
            filter={{ isActive: true, state: formData.state }}
          ></CitiesSelectInput>
        )}
      </FormDataConsumer>

      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default CommonForm;
