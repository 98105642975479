import { UnknownAttendance } from "./../types";
import { TranslationMessages } from "ra-core";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    profile: "Profile",
    settings: "Settings",
    companyProfile: "Company Profile",
    language: "Language",
    about: "About Turnout",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      total_clients: "Total Companies",
      total_departments: "Total Departments",
      total_designations: "Total Designations",
      total_projects: "Total Projects",
      total_roles: "Total Roles",
      total_devices: "Total Devices",
      chart_clients: "Clients",
      chart_employees: "Employees",
      registered_employees: "Registered Employees",
      present_employees: "Present Employees",
      absent_employees: "Absent Employees",
      late_entry: "Today Late Entry",
      chart_projects: "Projects",
      chart_tasks: "Tasks",
      total_notifications: "Total Notifications",
      leaves: "Leaves",
      montly_attendance: "Attendance",
      leave_app: "Leave Application",
      employee_time: "Employee Time",
      general_detail: "General Detail",
      leave: "Leaves Balance",
      quick_links: "Quick Links",
      month_attendance: "Month Attendance",
      table_projects: "Notifications",
      table_events: "Upcoming events",
      table_leaves: "Upcoming leaves",
      table_total_attendance: "Today Attendance Report",
      team_leave_application: "Team Leave Application",
      holiday: "Holiday",
      offdays: "Offday",
      request: "Request",
      event: "Event",
      leave_type: "Leave Type",
      leave_balance: "Leave Balance",
      pay_slip: "Pay Slip",
      apply_leave: "Apply Leave",
      view_profile: "View Profile",
      pending_survey: "Pending Survey",
      employeeVehicle: "Employee Vehicle",
      vehicleAttendance: "Vehicle Attendance",
      unknownAttendance: "Not Recognized",
      total_heads: "Head Counts",
      pending_leave: "Pending Approval Leave",
      pending_request: "Pending Request",
      pending_vid_reg: "Pending Video Registration",
      today_total_leave: "Today Total Leave",
      chart_vis_emp: "Visitor Vs. Employee",
      chart_vid_reg: "Pending Registration",
      head_tree_graph: "Head Counts Tree Graph",
      read_more: "Read more",
      male_Female_Ratio: "Employees (Male/Female)",
      monthly_revenue: "Monthly Revenue",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      new_customers: "New Customers",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "Welcome to react-admin demo",
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        aor_button: "react-admin site",
        demo_button: "Source for this demo",
      },
    },
    menu: {
      sales: "Sales",
      catalog: "Catalog",
      customers: "Customers",
      masters: "Masters",
      clientMgt: "Company",
      college: "College",
      collegeReport: "College Reports",
      templates: "Templates",
      attendance: "Attendance Manage...",
      myTeam: "My Team",
      leaveManagement: "Leave Management",
      masterConfiguration: "Master Configuration",
      employee: "Employee",
      settings: "Setting",
      about: "About Turnout",
      attendanceVisitor: "Attendance & Visitor",
      faq: "FAQ",
      userManual: "User Manual",
      video: "Video",
      visitor: "Visitor Management",
    },
    card: {
      total_member: "Total Member",
      present_member: "Present Member",
      absent_member: "Absent Member",
      member_on_leave: "Member On Leave",
      total_person: "Total Person",
      total_employee: "Total Employee",
      employee_at_assembly_point: "Employee at Assembly Point",
      employee_inside_premise: "Employee Inside Premise",
      visitor_at_assembly_premise: "Visitor at Assembly Point",
      visitor_inside_premise: "Visitor Inside Premise",
      total_visitor: "Total Visitor on Site",
    },
  },

  label: {
    shifts: "Shift",
    location_id: "Location Id",
    billingCycleFrom: "Billing cycle from",
    billingCyleTo: "Billing cycle to",
    employeeLocation: "Employee Location",
    employeeRights: "Employee Rights",
    reports: "Reports",
    GneratedAt: "Gnerated At",
    otherDetails: "Other Details",
    code: "Code",
    isActive: "Status",
    active: "Active",
    isRegistred: "Register Status",
    inActive: "In Active",
    status: "Status",
    country: "Country",
    state: "State",
    city: "City",
    mobile: "Mobile No.",
    employeeCode: "Employee Code",
    alternateMobile: "Alternate Mobile No.",
    joiningDate: "Joining Date",
    marriageDate: "Marriage Date",
    address: "Address",
    dateOfBirth: "Date Of Birth",
    employeeType: "Employee Type",
    employeePeriod: "Employment Period",
    clubbingSandwich: "Clubbing/Sandwich/Holidays",
    leaveEncashment: "Leave Encasement",
    earnedLeaveCycle: "Earned Leave Cycle",
    client: "Client",
    clientlocation: "Client Location",
    male: "Male",
    female: "Female",
    other: "Other",
    married: "Married",
    single: "Single",
    divorced: "Divorced",
    widowed: "widowed",
    leaveType: "Leave Type",
    from: "From",
    to: "To",
    zone: "Zone",
    region: "Region",
    reasons: "Reason",
    remarks: "Remarks",
    approve: "Approved",
    reject: "Rejected",
    pending: "Pending",
    cancel: "Cancel",
    approvalStatus: "Approval Status",
    fullLeave: "Full Leave",
    halfLeave: "Half Leave",
    assessment_start_year: "Assessment Year Start",
    assessment_end_year: "Assessment Year End",
    NumberOFLeaves: "No. of Leaves",
    image: "Image",
    carryForward: "Carry Forward",
    sandwichRule: "Sandwich Rule",
    monday: "Monday",
    tuseday: "Tuesday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    zipCode: "Zip Code",
    latitude: "Latitude",
    longitude: "Longitude",
    timezone: "Timezone",
    radius: "Radius(in meter)",
    camera_ID: "Camera ID",
    date: "Date",
    dateTime: "Date and Time",
    time: "Time",
    hours: "Hours",
    minutes: "Minutes",
    device_location: "Device location",
    in: "In",
    out: "Out",
    confidence: "Confidence",
    isMask: "Is Mask",
    deviceType: "Device Type",
    "device-type": "Device Type",
    userType: "User Type",
    gender: "Gender",
    employee: "Employee",
    visitor: "Visitor",
    createdAt: "Created At",
    type: "Type",
    branch: "Branch",
    information: "Information",
    warning: "Warning",
    turnoutE: "Turnout E",
    turnoutR: "Turnout R",
    turnoutM: "Turnout M",
    turnoutA: "Turnout A",
    turnoutK: "Turnout K",
    turnoutC: "Turnout C",
    tokenExpire: "Token Expire Time (Min)",
    isIpCamera: "Is Ip Camera",
    isMaster: "Is Master",
    roles_permission: "Roles & Permissions",
    video_attendance_management: "Video Attendance Management",
    project_task_management: "Project & Task Management",
    leave_Management: "Leave Management",
    all: "All",
    generatedBy: "Generated By",
    generatedAt: "Generated At",
    approvedBy: "Approved By",
    approvalEmail: "Approval Email",
    approvedAt: "Approved At",
    company_Address: "Company Address",
    company: "Company",
    companySub: "Company (Subsidiary)",
    privacy_Policy: "Privacy Policy",
    license_of_User: "License of User",
    about_us: "About Us",
    employee_size: "Employee Size",
    visitor_size: "Visitor Size",
    contact_phone: "Contact phone",
    contact_email: "Contact email",
    titleTag: "Title tag",
    header_text: "Header text",
    introScreen: "Intro Screen",
    logo: "Logo",
    mask: "Mask",
    advertisement: "Advertisement",
    news_Feed: "News Feed",
    description: "Description",
    token_setting: "Token Settings",
    turnoutDevice: "Turnout Devices",
    title: "Title",
    url: "Url",
    device_id: "Device Id",
    picture: "Picture",
    fromDate: "From Date",
    toDate: "To Date",
    reportId: "Report Id",
    templateType: "Template type",
    leave: "Leave",
    forgotPassword: "Forgot Password",
    request: "Request",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    reporting: "Reporting",
    createdBy: "Created By",
    leaveDays: "Leave Days",
    cameraVenue: "Camera Venue",
    inOut: "Checked In/Checked Out",
    maritalStatus: "Marital Status",
    effectiveAfter: "Effective After",
  },

  resources: {
    employee: {
      title: "Employees",
    },

    visitor: {
      title: "Visitors",
    },
    mainAdminAttendance: {
      title: "MainAdminAttendance",
    },
    stream: {
      title: "Streams",
      name: "Stream",
    },
    course: {
      title: "Courses",
      name: "Course",
    },
    semester: {
      title: "Semesters",
      name: "Semester",
    },
    classroom: {
      title: "Classrooms",
      name: "Classroom",
    },
    subject: {
      title: "Subjects",
      name: "Subject",
    },
    faculty: {
      title: "Faculties",
      name: "Faculty",
    },
    student: {
      title: "Students",
      name: "Student",
    },
    deviceType: {
      title: "DeviceType",
      name: "DeviceType",
    },
    "device-type": {
      title: "DeviceType",
      name: "DeviceType",
    },
    cameraVenue: {
      title: "Camera Venue",
      name: "Camera Venue",
    },
    "camera-venue": {
      title: "Camera Venue",
      name: "Camera Venue",
    },
    timeslot: {
      title: "Timeslots",
      name: "Timeslot",
    },
    "assign-timeslot": {
      title: "Assign Timeslots",
      name: "Assign Timeslot",
    },
    assignTimeslot: {
      title: "Assign Timeslots",
      name: "Assign Timeslot",
    },
    "faculty-timetable": {
      title: "Assign Timeslots",
      name: "Assign Timeslot",
    },
    facultyTimetable: {
      title: "Faculty Timetable",
      name: "Faculty Timetable",
    },
    "college-attendance": {
      title: "College Attendances",
      name: "College Attendance",
    },
    collegeAttendance: {
      title: "College Attendances",
      name: "College Attendance",
    },
    studentwiseAttendanceReport: {
      title: "Studentwise Report",
      name: "Studentwise",
      navTitle: "Studentwise Attendance Report",
    },

    datewiseAttendanceReport: {
      title: "Datewise Report",
      name: "Datewise",
      navTitle: "Datewise Attendance Report",
    },
    lecturewiseAttendanceReport: {
      title: "Lecturewise Report",
      name: "Lecturewise",
      navTitle: "Lecturewise Attendance Report",
    },
    subjectwiseAttendanceReport: {
      title: "Subjectwise Report",
      name: "Subjectwise",
      navTitle: "Subjectwise Attendance Report",
    },
    todayTimetableReport: {
      title: "Today Timetable Report",
      name: "Today Timetable",
      navTitle: "Today Timetable Report",
    },
    studentMultiRegistration: {
      name: "Register |||| Register",
      title: "Student Register",
      create_title: "Create Student Register",
    },
    reasons: {
      name: "Reason",
      title: "Reason",
    },
    inOutRemarks: {
      name: "Remarks",
      title: "Remarks",
    },

    emailTemplate: {
      name: "Email Template |||| Email Templates",
      title: "Email Templates",
      create_title: "Create Email Template",
      edit_title: "Edit Email Template",
      page: {
        delete: "Delete Email Template",
        create: "Add Email Template",
        edit: "Update Email Template",
      },
    },
    holidayList: {
      name: "Holiday List|||| Holidays",
      title: "Holidays",
      create_title: "Create Holiday",
      edit_title: "Edit Holiday",
      page: {
        delete: "Delete Holiday",
        create: "Add Holiday",
        edit: "Update Holiday",
      },
    },
    finalList: {
      title: "Attendance Approval-2",
      edit_title: "Edit Attendance Approval-2",
      page: {
        delete: "Delete Holiday",
        create: "Add Holiday",
        edit: "Update Holiday",
      },
    },
    weekOffList: {
      name: "Saturday Off List|||| Saturday Off",
      title: "Saturday Off",
      create_title: "Create Saturday Off",
      edit_title: "Edit Saturday Off",
      page: {
        delete: "Delete Saturday Off",
        create: "Add Saturday Off",
        edit: "Update Saturday Off",
      },
    },
    sundayOffList: {
      name: "Sunday Off List|||| Sunday Off",
      title: "Sunday Off",
      create_title: "Create Sunday Off",
      edit_title: "Edit Sunday Off",
      page: {
        delete: "Delete Sunday Off",
        create: "Add Sunday Off",
        edit: "Update Sunday Off",
      },
    },
    employeeType: {
      name: "Employee Type |||| Employee Types",
      title: "Employee Types",
      create_title: "Create Employee Type",
      page: {
        delete: "Delete Employee Type",
        create: "Add Employee Type",
        edit: "Update Employee Type",
      },
    },

    contactUs: {
      name: "Contact Us",
      title: "Contact Us",
      create_title: "Create Employee Type",
      page: {
        delete: "Delete Contact Us",
        create: "Add Contact Us",
        edit: "Update Contact Us",
      },
    },
    leaveTypes: {
      name: "Leave Type |||| Leave Types",
      title: "Leave Types",
      create_title: "Create Leave Type",
      edit_title: "Edit Leave Type",
    },
    leaves: {
      name: "Leaves|||| Leaves",
      title: "Leaves",
      menuName: "My Leaves",
      leaveReport: "Leave Report",
      create_title: "Apply Leave",
      fields: {
        name: "Leave",
      },
      type: "Leave Type",
    },
    "leave-management": {
      name: "Leave",
      title: "Leaves",
      menuName: "My Leaves",
      leaveReport: "Leave Report",
      create_title: "Apply Leave",
      fields: {
        name: "Leave",
      },
      type: "Leave Type",
    },
    "leave-balance-history": {
      name: "Leave",
      title: "Leaves",
      menuName: "My Leave Balance History",
      leaveReport: "Leave Report",
      create_title: "Apply Leave",
      fields: {
        name: "Leave",
      },
      type: "Leave Type",
    },
    teamLeave: {
      name: "Team Leave",
      title: "Team Leaves",
      menuName: "My Leaves",
      leaveReport: "Leave Report",
      create_title: "Apply Leave",
      fields: {
        name: "Team Leave",
      },
      type: "Leave Type",
    },
    leaveReport: {
      name: "Leave Report",
      title: "Leave Report",

      leaveReport: "Leave Report",
      create_title: "Apply Leave",
      fields: {
        name: "Leave",
      },
      type: "Leave Type",
    },
    teamLeaves: {
      name: "Leaves|||| Leaves",
      title: "Team Leaves",
      // leaveReport: "Leave Report",
      create_title: "Create Team Leave",
      fields: {
        name: "Leave",
      },
    },
    leaveHistory: {
      name: "Leave History |||| Leave History",
      title: "Leave History",
      // leaveReport: "Leave Report",
      create_title: "Create Team Leave",
      fields: {
        name: "Leave",
      },
    },
    workplaces: {
      name: "Workplaces|||| Workplaces",
      title: "Workplaces",
      create_title: "Create Workplace",
      shiftUpdate: "Shift Updated successfully !",
      // fields: {
      //   name: "Leave",
      // },
    },
    employeeMultiRegistration: {
      name: "Employee Multi Registration",
      title: "Employee Register",
      create_title: "Create Employee Register",
    },
    auth: {
      fields: {
        username: "Email",
      },
    },
    profile: {
      title: "Profile",
      fields: {
        products: "Products",
      },
    },

    "sub-admin": {
      name: "Employee",
      title: "Sub Administrator",
      create_title: "Create Sub Administrator",
    },

    inviteVisitors: {
      name: "Invite Visitors ",
      title: "Invite Visitors",
      create_title: "Invite Visitors",
      visitor_create_message:
        " Thank you for your submission !! We've sent an QR code to your registered email address.",
    },
    emergencyEvacuation: {
      name: "Emergency",
      title: "Emergency",
      create_title: "Emergency",
    },
    "team-attendence-request": {
      name: "Team Requests",
      title: "Team Requests",
      create_title: "Team Requests",
    },
    attendenceRequestLog: {
      name: "Requests",
      title: "Requests",
      create_title: "Requests",
    },

    "document-category": {
      name: "Document Category",
      title: "Document Category",
      create_title: "Document Category",
    },
    "document-type": {
      name: "Document Type",
      title: "Document Type",
      create_title: "Document Type",
    },
    "employee-document": {
      name: "Employee Document ",
      title: "EmployeeDocument ",
      create_title: "Employee Document ",
    },
    "document-management": {
      name: "Document Management",
      title: "Document Management",
      create_title: "Document Management",
    },
    documentManagement: {
      name: "Document Management",
    },

    "invite-visitor": {
      name: "Invite Visitors ",
      title: "Invite Visitors",
      create_title: "Invite Visitors",
    },
    generateQR: {
      name: "Generate QR |||| GenerateQR",
      title: "Generate QR",
      create_title: "Generate QR",
    },
    employees: {
      name: "Employee ",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },

    inviteVisitorReport: { name: "Visitor Report" },

    employeeLeaveReport: { name: "Employee Leaves" },
    employeeLeaveReportNew: { name: "Employee Leaves" },
    leaveBalance: { name: "Leave Balance" },
    visitors: {
      name: "Visitor |||| Visitors",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
        report: "Visitor Report",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    visitorsVehicle: {
      name: "Visitor Vehicle |||| Visitor Vehicle",
    },
    customers: {
      name: "Customer |||| Customers",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
        addon_permission: "Add On",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    dashboard: {
      name: "Dahsboard ",
    },
    zones: {
      name: "Zone |||| Zones",
      page: {
        delete: "Delete Zone",
        create: "Add Zone",
        edit: "Update Zone",
      },
    },
    clientAdmins: {
      name: "Client Admin |||| Client Admins",
      page: {
        delete: "Delete Client Admin",
        create: "Add Client Admin",
        edit: "Update Client Admin",
      },
    },
    employeeDataRights: {
      name: "Employee Data Right |||| Employee Data Rights",
      page: {
        delete: "Delete Employee Data Right",
        create: "Add Employee Data Right",
        edit: "Update Employee Data Right",
      },
    },
    branches: {
      name: "Branch |||| Branches",
      page: {
        delete: "Delete Branch",
        create: "Add Branch",
        edit: "Update Branch",
      },
    },
    regions: {
      name: "Region |||| Regions",
      page: {
        delete: "Delete Region",
        create: "Create Region",
        edit: "Update Region",
      },
    },
    employeeAllotments: {
      name: "Employee Allotment |||| Employee Allotments",
      page: {
        delete: "Delete City",
        create: "Add New Employee Allotments",
        edit: "Update City",
      },
    },
    companyMaster: {
      name: "Subsidiary",
      title: "Subsidiary",
      page: {
        delete: "Delete Company Master",
        create: "Add Company Master",
        edit: "Update Company Master",
      },
    },
    companyMasters: {
      name: "Company (Subsidiary)",
      title: "Company (Subsidiary)",
      page: {
        delete: "Delete Company Master",
        create: "Add Company Master",
        edit: "Update Company Master",
      },
    },
    clientMaster: {
      name: "Client |||| Client",
      title: "Client",
      page: {
        delete: "Delete Client Master",
        create: "Add Client Master",
        edit: "Update Client Master",
      },
    },
    clientLocation: {
      name: "Client Location |||| Client Locations",
      title: "Client Locations",
      page: {
        delete: "Delete Client Location",
        create: "Add Client Location",
        edit: "Update Client Location",
      },
    },
    countries: {
      name: "Country |||| Countries",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
        create: "Add Country",
        edit: "Update Country",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    states: {
      name: "State |||| States",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
        isActive: "Status",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete State",
        create: "Add State",
        edit: "Update State",
      },
    },
    cities: {
      name: "City |||| Cities",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete City",
        create: "Add City",
        edit: "Update City",
      },
    },
    companies: {
      name: "Companies",
      fields: {
        commands: "Name",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        basic: "Basic Information",
        address: "Company Address",
        privacy: "Privacy Policy",
        license: "License of User",
        about: "About Us",
        contact: "Contact Information",
        credential: "Credential",
        white_label: "White Label",
        news_feed: "News Feed",
      },
      page: {
        delete: "Delete Company",
        create: "Add Company",
        edit: "Update Company",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    roles: {
      name: "Role |||| Roles",
      fields: {
        name: "Name",
        code: "Code",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Role",
        create: "Add Role",
        edit: "Update Role",
      },
    },
    dateSettings: { name: "Date Settings" },

    commands: {
      name: "Order |||| Orders",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      fields: {
        basket: {
          delivery: "Delivery",
          reference: "Reference",
          quantity: "Quantity",
          sum: "Sum",
          tax_rate: "Tax Rate",
          total: "Total",
          unit_price: "Unit Price",
        },
        customer_id: "Customer",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        total_gte: "Min amount",
        status: "Status",
        returned: "Returned",
      },
    },
    invoices: {
      name: "Invoice |||| Invoices",
      fields: {
        date: "Invoice date",
        customer_id: "Customer",
        command_id: "Order",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        total_gte: "Min amount",
        address: "Address",
      },
    },
    products: {
      name: "Poster |||| Posters",
      fields: {
        category_id: "Category",
        height_gte: "Min height",
        height_lte: "Max height",
        height: "Height",
        image: "Image",
        price: "Price",
        reference: "Reference",
        stock_lte: "Low Stock",
        stock: "Stock",
        thumbnail: "Thumbnail",
        width_gte: "Min width",
        width_lte: "Max width",
        width: "Width",
      },
      tabs: {
        image: "Image",
        details: "Details",
        description: "Description",
        reviews: "Reviews",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fields: {
        products: "Products",
      },
    },

    attendance: {
      name: "Attendance |||| Attendance",
      report: "Attendance Report ",
      detail: "Attendance Detail",
      request: "Attendance Request",
      requests: "Requests",
      page: {
        delete: "Delete Attendance",
        create: "Add Attendance",
        edit: "Update Attendance",
      },
    },
    myAttendance: { name: "My Attendance" },
    teamAttendance: {
      name: "Attendance ",
      report: "Attendance Report ",
      title: "Team Attendance ",
      page: {
        delete: "Delete Team Attendance",
        create: "Add Team Attendance",
        edit: "Update Team Attendance",
      },
    },

    vehicleAttendance: {
      name: "Vehicle Attendance |||| Vehicle Attendance",
      title: "Employee Vehicle Attendance",
      report: "Vehicle Attendance Report ",
      detail: "Vehicle Attendance Detail",
      request: "Vehicle Attendance Request",
      requests: "Requests",
      fields: {
        name: "Vehicle Attendance ",
      },
      page: {
        delete: "Delete Vehicle Attendance",
        create: "Add Vehicle Attendance",
        edit: "Update Vehicle Attendance",
      },
    },
    unknownAttendance: {
      name: "Not Recognized",
      title: "Not Recognized",
      report: "Unknown Report ",
      detail: "Unknown Detail",
      request: "Unknown Request",
      requests: "Requests",
      fields: {
        name: "Not Recognized",
      },
      page: {
        delete: "Delete Vehicle Attendance",
        create: "Add Vehicle Attendance",
        edit: "Update Vehicle Attendance",
      },
    },

    finalReport: {
      name: "Final Report",
      // report: "Attendance Report ",
      // detail: "Attendance Detail",
      // request: "Attendance Request",
      // requests: "Requests",
      // page: {
      //   delete: "Delete Attendance",
      //   create: "Add Attendance",
      //   edit: "Update Attendance",
      // },
    },
    teamAttendanceReport: {
      name: "Attendance History",
      // report: "Attendance Report ",
      // detail: "Attendance Detail",
      // request: "Attendance Request",
      // requests: "Requests",
      // page: {
      //   delete: "Delete Attendance",
      //   create: "Add Attendance",
      //   edit: "Update Attendance",
      // },
    },
    teamStatistics: {
      name: "Statistics",
      title: "Statistics",
      // report: "Attendance Report ",
      // detail: "Attendance Detail",
      // request: "Attendance Request",
      // requests: "Requests",
      // page: {
      //   delete: "Delete Attendance",
      //   create: "Add Attendance",
      //   edit: "Update Attendance",
      // },
    },
    todaysAttendance: {
      name: "Today's Attendance",
      title: "Today's Attendance",
      // report: "Attendance Report ",
      // detail: "Attendance Detail",
      // request: "Attendance Request",
      // requests: "Requests",
      // page: {
      //   delete: "Delete Attendance",
      //   create: "Add Attendance",
      //   edit: "Update Attendance",
      // },
    },

    designations: {
      name: "Designation |||| Designations",
      title: "Designation",
      page: {
        delete: "Delete Designation",
        create: "Add Designation",
        edit: "Update Designation",
      },
    },

    departments: {
      name: "Department |||| Departments",
      page: {
        delete: "Delete Department",
        create: "Add Department",
        edit: "Update Department",
      },
    },

    requests: {
      name: "Requests |||| Requests",
      menuName: "My Requests",
      page: {
        delete: "Delete Requets",
        create: "Add Requets",
        edit: "Update Requets",
      },
    },
    teamRequests: {
      name: "Requests",
      page: {
        delete: "Delete Requets",
        create: "Add Requets",
        edit: "Update Requets",
      },
      title: "Team Requests",
    },

    employeeVehicle: {
      name: "Vehicle |||| Vehicle",
      page: {
        delete: "Delete EmployeeVehicle",
        create: "Add EmployeeVehicle",
        edit: "Update EmployeeVehicle",
      },
    },

    notifications: {
      name: "Notifications |||| Notifications",
      page: {
        delete: "Delete Notification",
        create: "Add Notification",
        edit: "Update Notifications",
      },
    },
    notificationHistory: {
      name: "Notification History",
      page: {
        delete: "Delete Notification History",
        create: "Add Notification History",
        edit: "Update Notification History",
      },
    },
    alerts: {
      name: "Alerts",
      page: {
        delete: "Delete Alert",
        create: "Add Alert",
        edit: "Update Alert",
      },
    },

    notification: {
      name: "Notification |||| Notification",
      page: {
        delete: "Delete Notification",
        create: "Add Notification",
        edit: "Update Notification",
      },
    },
    lateNotification: {
      name: "Late Notification ",
      page: {
        delete: "Delete Notification",
        create: "Add Notification",
        edit: "Update Notification",
      },
    },

    shifts: {
      name: "Shift |||| Shifts",
      page: {
        delete: "Delete Shift",
        create: "Add Shift",
        edit: "Update Shift",
      },
      fieldGroups: {
        full_shift: "Full Shift",
        half_shift: "Half Shift",
        working_hours: "Working Hours",
        working_days: "Working Days",
      },
      fields: {
        full_intime: "In Time",
        full_outtime: "Out Time",
        half_intime: "In Time",
        half_outtime: "Out Time",
        full_shift: "Full Shift",
        half_shift: "Half Shift",
        command_id: "Order",
        product_id: "Product",
        date_gte: "Posted since",
        date_lte: "Posted before",
        date: "Date",
        comment: "Comment",
        rating: "Rating",
      },
    },

    reviews: {
      name: "Review |||| Reviews",
      amount: "1 review |||| %{smart_count} reviews",
      relative_to_poster: "Review on poster",
      detail: "Review detail",
      fields: {
        customer_id: "Customer",
        command_id: "Order",
        product_id: "Product",
        date_gte: "Posted since",
        date_lte: "Posted before",
        date: "Date",
        comment: "Comment",
        rating: "Rating",
      },
      action: {
        accept: "Accept",
        reject: "Reject",
      },
      notification: {
        approved_success: "Review approved",
        approved_error: "Error: Review not approved",
        rejected_success: "Review rejected",
        rejected_error: "Error: Review not rejected",
      },
    },
    devices: {
      name: "Device ",
      fields: {
        camera_ID: "Camera ID",
        device_location: "Device Location",
        camera_Url: "Camera Url",
        registration_frame: "Registration Frame",
        false_cnt: "False Count",
        response_time: "Response Time",
        client_name: "Client",
        mac_ipAddress: "Mac ID Address",
        API_key: "API Key",
        edgeURL: "Edge URL",
      },
      page: {
        delete: "Delete Device",
        create: "Add Device",
        edit: "Update Device",
      },
    },
    segments: {
      name: "Segments",
      fields: {
        customers: "Customers",
        name: "Name",
      },
      data: {
        compulsive: "Compulsive",
        collector: "Collector",
        ordered_once: "Ordered once",
        regular: "Regular",
        returns: "Returns",
        reviewer: "Reviewer",
      },
    },
    createSelfUser: {
      message:
        " Thank you for your submission !! Please contact to Administrator for further process.",
    },
  },

  SERVERMSG: {
    auth: {
      validation: {
        signup: {
          firstname_required: "First name is required",
          lastname_required: "Last name is required",
          email_required: "Email address is required",
          email_invalid: "Email address must be valid email address",
          email_exists: "The Email address you have provided already exists",
          phone_required: "Phone number is required",
          phone_exists: "Phone number you have provided already exists",
          password_required: "Password is required",
          confirm_password_required: "Confirm Password is required",
          accept_tnc_required: "Please accept terms & conditions",
          recaptcha_required: "Captcha verification is required.",
          confirm_password_not_matched:
            "Confirm Password must match with Password",
          SUCCESS:
            "You registration request has been submitted successfully, Our team will get back to you shortly.",
        },
        signin: {
          username_required: "Email address or phone number is required",
          password_required: "Password is required",
          username_invalid: "No user with that email/phone found",
          password_invalid: "Password is incorrect",
          account_inactive: "Your account is inactive.",
          password_maximum_attempted:
            "Too many failed attempts for login, please wait for next {{time}} minutes.",
        },
        reset: {
          username_required: "Email address or phone number is required",
          username_invalid: "No user with that email/phone found",
          otp_required: "OTP is required",
          otp_invalid: "OTP is incorrect",
          otp_expired: "OTP is expired",
          no_otp: "OTP not generated yet.",
          new_password_required: "New Password is required",
          confirm_password_required: "Confirm Password is required",
          wait_resend: "Please wait for 30 seconds before resending your OTP",
        },
      },
      template: {
        otp_sms: "The OTP to reset your password is: <%=code%>",
      },
    },

    common: {
      errors: {
        access_denied: "Access denied",
        internal_error: "Internal error occured, please contact administrator.",
        url_not_found: "URL not found",
        no_upload_path_defined: "Upload path is not defined for this server",
      },
      validation: {
        q_required: "Search Criteria is required",
        q_short:
          "Please broader your search criteria, minimum expencted length is 2",
        invalid_request: "The request you have made is invalid",
      },
    },
    middlewares: {
      token_parser: {
        validation: {
          token_invalid: "The token you have provided is invalid",
          token_expired: "The token you have provided is expired",
        },
      },
      user_middleware: {
        validation: {
          password_change_required: "Please change your password first",
        },
      },
      logger_middleware: {
        validation: {
          password_change_required: "Please change your password first",
        },
      },
    },
    account: {
      validation: {
        password_required: "Password is required",
        new_password_required: "New Password is required",
        confirm_password_required: "Confirm Password is required",
        password_invalid: "Invalid value provided for Password",
        profile: {
          not_found: "Profile not found",
          country_invalid: "Invalid value provided for country",
        },
      },
    },
    users: {
      validation: {
        name_required: "User name is required",
        firstname_required: "First name is required",
        lastname_required: "Last name is required",
        email_required: "Email is required",
        email_invalid: "Invalid value provided for email",
        email_exists: "Email you have provided is already exists",
        phone_exists: "Phone/Mobile number you have provided is already exists",
        phone_required: "Phone/Mobile number is required",
        type_invalid: "Invalid Type selected",
        type_required: "Type is required",
        password_required: "Password is required",
        role_invalid: "Invalid value provided for Role",
        role_required: "Role is required",
        address_required: "Address is required",
        address_invalid: "Invalid value provided for Address",
        country_required: "Country is required",
        country_invalid: "Invalid value provided for Country",
        state_required: "State is required",
        state_invalid: "Invalid value provided for State",
        district_required: "District is required",
        district_invalid: "Invalid value provided for District",
        city_required: "Village is required",
        city_invalid: "Invalid value provided for Village",
        line1_required: "Address is required",
        line1_invalid: "Invalid value provided for Address",
        is_active_invalid: "Invalid value provided for Is Active",
        not_found: "User not found",
      },
    },
    countries: {
      validation: {
        not_found: "Country not found",
        name_required: "Country name is required",
        alpha2_length_invalid: "Alpha2 code must be 2 charcters long",
        alpha2_exists: "Alpha2 code is already exist",
        alpha2_required: "Alpha2 code is required",
        alpha3_length_invalid: "Alpha3 code must be 3 charcters long",
        alpha3_exists: "Alpha3 code is already exist",
        alpha3_required: "Alpha3 code is required",
        numeric_invalid: "Numeric code must be numeric",
        numeric_required: "Numeric code is required",
        dialing_code_required: "Dialing code is required",
        is_active_required: "Is Active field is required",
        is_active_invalid: "Invalid value provided for Is Active",
        existed_in_states:
          "The country you are trying to delete is already assigned to {{count}} state(s), thus cannot be deleted.",
      },
    },
    cities: {
      validation: {
        not_found: "Village not found",
        name_required: "Village name is required",
        code_exists: "Code is already exists",
        code_required: "Code is required",
        district_invalid: "Invalid District selected",
        district_required: "District is required",
        is_active_required: "Is Active field is required",
        is_active_invalid: "Invalid value provided for Is Active",
      },
    },
    states: {
      validation: {
        not_found: "State not found",
        name_required: "State name is required",
        name_exists: "Name is already exist",
        code_exists: "Code is already exist",
        code_required: "Code is required",
        is_active_required: "Is Active field is required",
        is_active_invalid: "Invalid value provided for Is Active",
        existed_in_districts:
          "The state you are trying to delete is already assigned to {{count}} district(s), thus cannot be deleted.",
      },
    },
    roles: {
      validation: {
        name_required: "Role name is required",
        code_required: "Role code is required",
        code_exists: "Role code already exists, please choose other one",
        not_found: "Role not found",
      },
    },
    site_preferences: {
      validation: {
        name_required: "",
        heading_required: "",
        contact_invalid: "",
        contact_required: "",
        contact_name_required: "",
        contact_email_required: "",
        contact_mobile_required: "",
        contact_designation_required: "",
        contact_address_required: "",
        customer_care_required: "",
        customer_care_numbers_invalid: "",
        customer_care_numbers_required: "",
        customer_care_emails_invalid: "",
        customer_care_emails_required: "",
        default_timezone_required: "",
        smtp_host_required: "",
        smtp_port_required: "",
        smtp_user_required: "",
        smtp_pass_required: "",
        smtp_secure_invalid: "",
        smtp_secure_required: "",
        sms_url_invalid: "",
        sms_url_required: "",
        sms_method_invalid: "",
        sms_method_required: "",
        sms_username_field_required: "",
        sms_username_required: "",
        sms_password_field_required: "",
        sms_password_required: "",
        sms_message_field_required: "",
        sms_other_invalid: "",
        sms_other_required: "",
        sms_template_required: "",
      },
    },
    timezones: {
      validation: {
        name_required: "Timezone name is required",
        code_required: "Code is required",
        offset_invalid: "Invalid value provided for Offset",
        offset_required: "Offset is required",
        is_active_invalid: "Invalid value provided for Is Active",
        is_active_required: "Is Active field is required",
        code_exists: "Code is already exist",
        not_found: "Timezone not found",
      },
    },
    ERROR: {
      API_FAIL_ISSUE:
        "There is some issue with fetching the data from server, Please contact Site Admin.",
    },
    ra: {
      validation: {
        required: "required",
        invalid_form:
          "Your form is not valid. Please make sure all fields are filled correctly.",
      },

      message: {
        invalid_form: "The form is not valid",
      },
    },
  },
};

export default customEnglishMessages;
