import React, { useState } from "react";
import {
  TextInput,
  required,
  PasswordInput,
  NumberInput,
  BooleanInput,
  Button,
  FormDataConsumer,
  useDataProvider,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle, numberValidate } from "../helperValidate";
import { Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { toastError, toastInfo } from "../components/toast";

type Props = {};

const useStyles = makeStyles({
  name: { display: "inline-block" },
  code: { display: "inline-block", marginLeft: 32 },
  emailButton: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    marginRight: "76px",
  },
  ml48: { display: "inline-block", marginLeft: 48 },
});

const SMTP = (props: Props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmail = (formData: any) => {
    dataProvider
      .callCustom(`settings/test-mail`, formData.smtpConfig)
      .then((response: any) => {
        toastInfo("Email sent successfully");
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  return (
    <>
      <SectionTitle label="" />
      <span>
        <Typography>
          Manage email notifications by providing the mail server details and
          when you want to get notified.
        </Typography>
      </span>
      <div
      // validate={validatePasswords}
      >
        <BooleanInput
          defaultValue={false}
          label="Enable email notifications:"
          source="smtpConfig.emailNotifications"
          helperText={false}
        />
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="SMTP Server"
                source="smtpConfig.server"
                validate={requiredValidate}
                style={{ width: "832px", marginLeft: "48px" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="Port"
                source="smtpConfig.port"
                validate={numberValidate}
                style={{ width: "832px", marginLeft: "48px" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="Username"
                source="smtpConfig.username"
                validate={requiredValidate}
                style={{ width: "832px", marginLeft: "48px" }}
                autoComplete="off"
                options={{ autoComplete: "off" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div>
          <PasswordInput
            label="Password"
            source="smtpConfig.password"
            validate={requiredValidate}
            style={{ width: "832px", marginLeft: "48px" }}
            autoComplete="off"
            options={{ autoComplete: "off" }}
          />
        </div>
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="From"
                source="smtpConfig.from"
                validate={requiredValidate}
                style={{ width: "832px", marginLeft: "48px" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="To"
                source="smtpConfig.to"
                validate={requiredValidate}
                style={{ width: "832px", marginLeft: "48px" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div>
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <TextInput
                label="Subject"
                source="smtpConfig.subject"
                validate={requiredValidate}
                style={{ width: "832px", marginLeft: "48px" }}
              />
            )}
          </FormDataConsumer>
        </div>
        <div className={classes.ml48}>
          <BooleanInput
            source="smtpConfig.secure"
            label="Secure"
            defaultValue={false}
          />
        </div>

        <FormDataConsumer className={classes.emailButton}>
          {({ formData }: { formData: any }) => (
            <div className={classes.emailButton}>
              <Button
                label="Send Test Email"
                onClick={() => handleEmail(formData)}
                className={classes.emailButton}
              >
                <EmailIcon />
              </Button>
            </div>
          )}
        </FormDataConsumer>
        {/* </div> */}
      </div>
    </>
  );
};

const requiredValidate = [required()];

export default SMTP;
