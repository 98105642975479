import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";

interface State {
  classrooms?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  labelName?: string;
  formData?: any;
  semesterChange?: any;
  streamChange?: any;
  courseChange?: any;
}

const ClassroomSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  propsUrl,
  helperText,
  labelName,
  semesterChange,
  streamChange,
  courseChange,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const searchUrl = "classroom/search";

  useEffect(() => {
    if (filter && filter.semester) {
      dataProvider
        .getSearchList(propsUrl ? propsUrl : searchUrl, filter)
        .then((response: any) => {
          response &&
            response.data.forEach((item: any) => {
              item.name = `${item.name}`;
            });
          setState((state) => ({
            ...state,
            classrooms: response && response.data,
          }));
        });
    }
  }, [filter.semester]);

  if (semesterChange || streamChange || courseChange) {
    form.change("classroom", undefined);
  }

  const { classrooms } = state;
  const check = (value: any) => {
    classrooms?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "classroom");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Classroom"}
      disabled={record && record.mode === "auto" ? true : false}
      source="classroom"
      validate={validate}
      choices={classrooms}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
    />
  );
};

ClassroomSelectInput.defaultProps = {
  source: "classroom",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ClassroomSelectInput);
