import React, { useState } from "react";
import { FC } from "react";
import { Link, useDataProvider } from "react-admin";
import FullNameField from "./FullNameField";
import { toastError } from "../components/toast";

const LinkField = (props: any) => {
  const dataProvider = useDataProvider();
  const { resource, id, basePath, open, setEditData } = props;

  const getData = () => {
    open();
    const resourcePath = `${resource}/get/${props.record._id}`;
    dataProvider
      .callCustom(resourcePath, {})
      .then((response: any) => {
        setEditData(response && response.data && response.data.item);
      })
      .catch((err: any) => {
        toastError(err);
      });
    setEditData([]);
  };

  return props.record ? (
    <Link to={`/${props.resource}/${props.record._id}`} onClick={getData}>
      <FullNameField {...props} />
    </Link>
  ) : null;
};

LinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default LinkField;
