import * as React from "react";
import { FC, useState } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  RadioButtonGroupInput,
  maxLength,
  FormDataConsumer,
  DateInput,
  useDataProvider,
  NumberInput,
} from "react-admin";
import { useRedirect } from "ra-core";
import { Typography, useMediaQuery, Button } from "@material-ui/core";

import CompanyReferenceInput from "./CompanyReferenceInput";

import { makeStyles, Theme } from "@material-ui/core/styles";

import { requestStyles } from "../helperStyle";

import RequestSelectInput from "../components/RequestSelectInput";
import { toastError, toastInfo } from "../components/toast";
import moment from "moment";
// import { requestTypeObj } from "../helperChoice";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(requestStyles);
const CommonForm = (props: any) => {
  const statusType: any = {
    early_check_out: "Early Check Out",
    late_check_in: "Late Check In",
    missed_checkin: "Missed Check In",
    missed_checkout: "Missed Check Out",
    in_between_check_out: "In Between Check Out",
    other: "Other",
    over_time: "Over Time",
    procurement: "Procurement",
    attendancechangerequest: "Attendance Change Request",
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const [value, setValue] = useState() as any;
  const [formData, setFormData] = useState() as any;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const today = new Date().toISOString().slice(0, 10);

  const fromDateTime = moment(
    formData?.date + " " + value?.time,
    "YYYY-MM-DD HH:mm"
  );

  const toDateTime = moment(
    formData?.date + value?.betWeenTime,
    "YYYY-MM-DD HH:mm"
  );

  const saveForm = (formData: any) => {
    let payLoad = {
      subject: statusType[formData?.reasons],
      requestType: formData?.reasons,
      reason: formData?.reason,
      fromTime: fromDateTime,
    } as any;

    if (formData?.reasons === "in_between_check_out") {
      payLoad = { ...payLoad, toTime: toDateTime };
    } else if (formData?.reasons === "over_time") {
      payLoad = { ...payLoad, estimateHours: formData?.estimateHours };
    } else {
      payLoad = { ...payLoad };
    }

    const url = "request/create";

    if (formData?.reasons === undefined || formData?.reasons === "") {
      toastError("Request Types is required");
    } else if (formData?.date === undefined || formData?.date === "") {
      toastError("Date is required");
    } else if (value?.time === undefined || value?.time === "") {
      toastError("Time is required");
    } else if (
      formData?.reasons === "in_between_check_out" &&
      (value?.betWeenTime === undefined || value?.betWeenTime === "")
    ) {
      toastError("To Time is required");
    } else if (formData?.reason === undefined || formData?.reason === "") {
      toastError("Reason is required");
    } else {
      dataProvider
        .callCustom(url, payLoad)
        .then((response: any) => {
          toastInfo("Request Created Successfully");
          redirect("attendenceRequestLog/list");
        })
        .catch((err: any) => {
          err.response.data.errors.forEach((error: any) => {
            toastError(err.message);
            if (error.response.status === 401) {
              window.location.replace("/login");
            }
          });
        });
    }
  };

  return (
    <div className="form-content">
      <SimpleForm {...props} toolbar={null}>
        <CompanyReferenceInput></CompanyReferenceInput>

        <SectionTitle label="" />

        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            setFormData(formData),
            (
              <RequestSelectInput
                labelName="Request Types"
                validate={[required()]}
                helperText={false}
              />
            )
          )}
        </FormDataConsumer>

        {formData?.reasons === "in_between_check_out" ? (
          <span>From</span>
        ) : (
          <SectionTitle label="" />
        )}

        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <div>
              <DateInput
                source="date"
                style={{
                  width: "257px",
                  paddingBottom: "0px",
                  marginTop: "0px",
                }}
                // className={classes.name}
                validate={[required()]}
                defaultValue={today}
                helperText={false}
              />
            </div>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <div
              style={{
                transition:
                  "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: "8px",
                width: "88%",
                marginLeft: "32px",
                position: "relative",
                top: "10px",
                borderBottom: "1px solid #757575",
              }}
            >
              <input
                type="time"
                onChange={(ev) => setValue({ ...value, time: ev.target.value })}
                min="00:00:00"
                max="23:59:59"
                step="1"
                style={{
                  minWidth: "200px",
                  border: "none",
                  background: "#0000",
                }}
                // value={value}
              />
            </div>
          )}
        </FormDataConsumer>
        {formData?.reasons === "in_between_check_out" && <span>To</span>}
        {formData?.reasons === "in_between_check_out" && (
          <div className="d-flex">
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <div>
                  <DateInput
                    source="date"
                    style={{ width: "257px" }}
                    // className={classes.name}
                    validate={[required()]}
                    defaultValue={today}
                    helperText={false}
                    disabled={true}
                  />
                </div>
              )}
            </FormDataConsumer>
            <div>
              <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                  <div
                    style={{
                      transition:
                        "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      padding: "8px",
                      width: "88%",
                      marginLeft: "32px",
                      position: "relative",
                      top: "5px",
                      borderBottom: "1px solid #757575",
                    }}
                  >
                    {/* <span style={{ color: "#3f51b5", marginRight: "13px" }}>
                      To Time * :{" "}
                    </span> */}

                    <input
                      type="time"
                      onChange={(ev) =>
                        setValue({ ...value, betWeenTime: ev.target.value })
                      }
                      min="00:00:00"
                      max="23:59:59"
                      step="1"
                      style={{
                        minWidth: "200px",
                        border: "none",
                        background: "#0000",
                      }}
                      // value={value}
                    />
                  </div>
                )}
              </FormDataConsumer>
            </div>
          </div>
        )}

        {formData?.reasons === "over_time" && (
          <div className="d-flex">
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <div>
                  <NumberInput
                    source="estimateHours"
                    label="Estimate hours"
                    style={{ width: "257px" }}
                    // className={classes.name}
                    validate={[required()]}
                    helperText={false}
                  />
                </div>
              )}
            </FormDataConsumer>
          </div>
        )}

        <SectionTitle label="" />
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <div>
              <TextInput
                disabled={
                  props && Object.keys(props.record).length === 0 ? false : true
                }
                source="reason"
                style={{ width: "410px" }}
                validate={[required()]}
                helperText={false}
              />
            </div>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveForm(formData)}
                // className={classes.button}
              >
                Save
              </Button>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </div>
  );
};

// const RequestTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

// const requiredValidate = [required()];

export default CommonForm;
