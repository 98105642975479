import StateList from "./StateList";
import StateEdit from "./StateEdit";
import StateCreate from "./StateCreate";
import { StateIcon } from "../svgIcon";

export default {
  list: StateList,
  create: StateCreate,
  edit: StateEdit,
  icon: StateIcon,
};
