import React from "react";

interface MyComponentProps {
  headerLabel: string;
}

const MenuHeader: React.FC<MyComponentProps> = ({ headerLabel }) => {
  return (
    <h6 className="sub-menu-header">
      {headerLabel}
      <hr className="p-0 m-1 menu-hr-w"></hr>
    </h6>
  );
};
export default MenuHeader;
