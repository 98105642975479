import * as React from "react";
import { FC, useState, useCallback } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Typography, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, downloadCSV } from "react-admin";
import { todayTotalLateEntriesStyle } from "../../../helperStyle";
import DialogueBox from "../../DialogueBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import helperFunc from "../../../helperFuncs";
import jsonExport from "jsonexport/dist";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
}
const useStyles = makeStyles(todayTotalLateEntriesStyle);

const ChartVideoRegistration: FC<Props> = (data) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");
  const dataObj = data.data || {};
  const data2Obj: any = data.data2 || {};
  const loading = data.isLoading;
  const keys = Object.keys(dataObj);
  const values = Object.values(dataObj);
  const dataArr: any = [];
  const translate = useTranslate();
  const classes = useStyles();

  const object2: any = {
    "Late Employee Count": "lateEmployeeData",
    "Employee On Time": "onTimeEmployeeData",
    "On Leave Count": "absentEmployeeData",
  };

  let dataBySlice = data2Obj[object2[activeSlice]];

  const objLabel: any = {
    employeeOnTime: "Employee On Time",
    lateEmpCnt: "Late Employee Count",
    onLeaveCount: "On Leave Count",
    userCount: "User Count",
  };

  keys.forEach((key, index) => {
    const newLabel = !!objLabel[key] ? objLabel[key] : key;
    dataArr.push({
      name: newLabel,
      value: values[index],
    });
  });

  const COLORS = ["#D63330", "#1E77B4", "#FFBB28", "#FF8042"];
  const headers = () => {
    if (
      activeSlice === "Late Employee Count" ||
      activeSlice === "Employee On Time"
    ) {
      return [
        { label: "Name", key: "name" },
        { label: "Time", key: "Time" },
      ];
    } else {
      return [
        { label: "Name", key: "name" },
        { label: "Status", key: "Leave" },
      ];
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = useCallback((data: any, index: any) => {
    setOpen(true);
    setActiveSlice(data.name);
    handleDefaultPage(0);
    handleRowsPerPage(10);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const Sampleexporter = () => {
    const recordsForExport = dataBySlice.map((record: any) => {
      if (activeSlice === "On Leave Count") {
        return {
          Name: record?.name,
          Status: "Leave",
        };
      } else {
        return {
          Name: record?.name,
          Time: helperFunc.localDateAndTime(record?.time),
        };
      }
    });
    jsonExport(recordsForExport, (err: any, csv: any) => {
      downloadCSV(csv, `${activeSlice}csv`); // download as 'xxxx.csv` file
    });
  };

  return (
    <div
      className={
        isXSmall
          ? classes.mobileCard
          : isSmall
          ? classes.smallCard
          : classes.card
      }
    >
      <Typography component="h6" className={classes.title}>
        {translate("pos.dashboard.late_entry")}
      </Typography>
      <DialogueBox
        dialogueData={dataBySlice}
        open={open}
        handleClose={handleClose}
        activeSlice={activeSlice}
        headerArr={headers()}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={Sampleexporter}
        isExport={true}
      />

      {loading ? (
        <span
          style={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <div
          className={
            isXSmall
              ? classes.pieDivMobile
              : isSmall
              ? classes.pieDivSmall
              : classes.pieDiv
          }
        >
          {dataArr && dataArr.length > 0 ? (
            <ResponsiveContainer width={300} height={220}>
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={dataArr}
                  fill="#8884d8"
                  label
                  outerRadius={55}
                  onClick={handleClickOpen}
                  // style = {{heeight:"200px"}}
                >
                  {dataArr.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                // layout="vertical"
                // verticalAlign="top"
                // align="left"
                // wrapperStyle={{ top: 0 }}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <h4
              style={{
                width: "87%",
                height: "200px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              No Entry Found
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default ChartVideoRegistration;
