import { DepartmentIcon } from "../svgIcon";
import { CommonEmployeeDocument } from "./employeeDocumentCommon";
import EmployeeDocumentList from "./EmployeeDocumentList";

export default {
  list: EmployeeDocumentList,
  create: CommonEmployeeDocument,
  edit: CommonEmployeeDocument,
  icon: DepartmentIcon,
};
