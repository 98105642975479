import React, { useState } from "react";
import {
  SimpleForm,
  useTranslate,
  required,
  FormDataConsumer,
  BooleanInput,
  SelectInput,
  DateTimeInput,
} from "react-admin";
import { Typography, useMediaQuery } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import { deviceLocation } from "../helperChoice";
import { attendanceStyles } from "../helperStyle";
// import { DateTimeInput } from "react-admin-date-inputs2";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import WorkplaceIdSelectInput from "../components/WorkplaceIdSelectInput";
import WorkplaceShiftSelectInput from "../components/WorkplaceShiftSelectInput";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SmFormHeader } from "../components/SmFormHeader";
import { validate } from "../helperValidate";

export const validatePasswords = ({ time }: { time: string }) => {
  const errors = {} as any;
  if (time && new Date(time) >= new Date()) {
    errors.time = "Date Should be smaller then current date";
  }
  return errors;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(attendanceStyles);
const CommonForm = (props: any) => {
  const classes = useStyles();
  let [update, setupdate] = useState(false);
  let newClass = classes;
  const user = helperFunc.getUserData();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const empSearchByWorkPlace = "workplaces/employee_search";
  useDeepCompareEffect(() => {
    if (isSmall) {
      classes.code = newClass.name;
    } else {
      classes.code = newClass.code;
    }
    setupdate(update ? false : true);
  }, [isSmall, classes]);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  return (
    <>
      <SmFormHeader {...props} />

      <div className="form-content">
        {" "}
        <SimpleForm {...props} validate={validatePasswords}>
          {user && user.type === "super" ? (
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => (
                <CompanyReferenceInput
                  record={formData}
                ></CompanyReferenceInput>
              )}
            </FormDataConsumer>
          ) : null}
          <SectionTitle label="" />
          {user?.isClientConfiguration && (
            <>
              <FormDataConsumer formClassName={classes.name}>
                {({ formData }: { formData: any }) => (
                  <ClientMasterSelectInput
                    filter={{ isActive: true, company: formData.company }}
                    // validate={validate}
                  ></ClientMasterSelectInput>
                )}
              </FormDataConsumer>
              <FormDataConsumer formClassName={classes.code}>
                {({ formData }: { formData: any }) => (
                  <ClientLocationSelectInput
                    filter={{
                      clientId: formData.clientId,
                      isActive: true,
                      company: formData.company,
                    }}
                    // validate={validate}
                  ></ClientLocationSelectInput>
                )}
              </FormDataConsumer>
            </>
          )}

          <FormDataConsumer
            formClassName={
              user?.isClientConfiguration ? classes.code : classes.name
            }
          >
            {({ formData }: { formData: any }) => (
              <WorkplaceIdSelectInput
                filter={{
                  clientLocationId: formData.clientLocationId,
                  isActive: true,
                  company: formData.company,
                }}
                validate={validate}
              ></WorkplaceIdSelectInput>
            )}
          </FormDataConsumer>
          {!isSmall ? <SectionTitle label="" /> : null}
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <EmployeeSelectInput
                propsUrl={empSearchByWorkPlace}
                filter={{
                  workplaceId: formData.workplaceId,
                  isActive: true,
                  company: formData.company,
                }}
                validate={validate}
              ></EmployeeSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) => (
              <WorkplaceShiftSelectInput
                filter={{
                  workplaceId: formData.workplaceId,
                  isActive: true,
                  company: formData.company,
                }}
                validate={validate}
              ></WorkplaceShiftSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.code}>
            {({ formData }: { formData: any }) => (
              <SelectInput
                resettable
                disabled={formData.mode === "auto" ? true : false}
                label="label.device_location"
                reference="authType"
                source="authType"
                validate={validate}
                formClassName={classes.code}
                choices={deviceLocation}
              />
            )}
          </FormDataConsumer>
          <SectionTitle label="" />
          <DateTimeInput
            // formClassName={classes.name}
            label="Date and Time"
            source="time"
            // validate={[required()]}
            options={{
              format: "DD/MM/YYYY, HH:mm:ss",
              ampm: true,
              clearable: true,
            }}
            validate={validate}
            providerOptions={{ utils: MomentUtils }}
          />

          {/* <DateTimeInput
          source="time"
          label="End time"
          options={{
            format: "DD/MM/YYYY, HH:mm:ss",
            ampm: false,
            clearable: true,
          }}
        /> */}

          {/* <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                style={{ marginTop: "9px" }}
              >
                <DateTimePicker
                  variant="inline"
                  openTo="date"
                  views={["year", "month", "date"]}
                  label="Year and Month"
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ marginLeft: "0px" }}
                  autoOk={true}
                  //   InputProps={{
                  //     disableCloseOnSelect: true
                  //   }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </FormDataConsumer> */}
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={selectedDate}
            disablePast
            onChange={handleDateChange}
            label="Date"
            showTodayButton
            resource = "attendance"
            
          />
        </MuiPickersUtilsProvider> */}

          {/* <LocalizationProvider dateAdapter={moment}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
          />
        </LocalizationProvider> */}

          {/* <input type="datetime" name="validFrom"/> */}

          <SectionTitle label="" />
          {user.clientDetail && !user.clientDetail.isMask ? (
            <h3>Is Mask </h3>
          ) : null}
          {user.clientDetail && !user.clientDetail.isMask ? (
            <h4>Not subscribed</h4>
          ) : (
            <FormDataConsumer formClassName={isXsmall ? null : classes.zipcode}>
              {({ formData }: { formData: any }) => (
                <BooleanInput
                  disabled={formData.mode === "auto" ? true : false}
                  formClassName={isXsmall ? null : classes.code}
                  source="isMask"
                  label="label.isMask"
                  defaultValue={true}
                />
              )}
            </FormDataConsumer>
          )}
        </SimpleForm>
      </div>
    </>
  );
};

export default CommonForm;
