import NotificationList from "./NotificationList";
import NotificationEdit from "./NotificationEdit";
import NotificationCreate from "./NotificationCreate";
import { NotificationIcon } from "../svgIcon";

export default {
  list: NotificationList,
  // create: NotificationCreate,
  // edit: NotificationEdit,
  icon: NotificationIcon,
};
