import * as React from "react";
import { FC } from "react";
import {
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  FormDataConsumer,
  // AutocompleteInput,
  // DateInput,
  downloadCSV,
  useRefresh,
  CreateButton,
  TopToolbar,
  ExportButton,
  required,
  DateTimeInput,
  Datagrid,
  FunctionField,
  TextField,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import DescriptionIcon from "@material-ui/icons/Description";
import { Route, RouteChildrenProps } from "react-router-dom";
import { ListComponentProps } from "../types";
import GridList from "./GridList";

import jsonExport from "jsonexport/dist";
import ImportButton from "../attendance/import";
import { deviceType, gender, auth, userType } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import authProvider from "../authProvider";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import AttandanceShow from "./AttendanceShow";
import AttendanceEditButton from "./AttendanceEditButton";
import EditMenu from "./EditMenu";
import RemarkLinkFiled from "./RemarkLinkField";
import RemarkButtonField from "./RemarkButtonField";

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_from) {
        values.time_from = moment.utc(moment(values.time_from).utc()).format();
      }
      if (values.time_to) {
        values.time_to = moment.utc(moment(values.time_to).utc()).format();
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };
  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(1);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const resetFilter = () => {
    data.setFilters(filterData(), []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.time_from > new Date()) {
      return "From date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.time_to > new Date()) {
      return "To date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  const user = helperFunc.getUserData();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="authType"
                      choices={auth}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="mode"
                      choices={deviceType}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="time_from"
                      validate={[required(), checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[required(), checkToDate]}
                      source="time_to"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
const ListActions = (props: any) => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
    setListView,
    listView,
  } = props;
  const user = helperFunc.getUserData();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const handleView = () => {
    setListView(!listView);
  };

  const useStyles = makeStyles({
    root: {
      marginTop: "0em",
    },
    media: {
      height: 140,
    },
    title: {
      paddingBottom: "0.5em",
    },
    actionSpacer: {
      display: "flex",
      justifyContent: "space-around",
    },
    drawerPaper: {
      zIndex: 100,
    },
    textRed: { color: "red" },
    userImg: {
      padding: 0,
      height: 55,
      width: 55,
      marginLeft: 10,
      borderRadius: "30px",
    },
    logo: { width: "100%", height: "inherit" },
    descriptionIcon: {
      transform: "rotate(180deg)",
      fontSize: 20,
    },
    smCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.8125rem !important",
    },
    lgCsvButton: {
      // padding: "4px 5px !important",
      fontSize: " 0.8125rem !important",
      minWidth: "126px",
    },
    lgViewCsvButton: {
      fontSize: " 0.8125rem !important",
      minWidth: "126px",
    },
    csvSpan: { paddingLeft: "0.5em" },
    exportButton: {
      padding: "0 !important",
      fontSize: 20,
      marginTop: "0.3em",
    },
    viewButton: {
      padding: "0 !important",
      fontSize: 20,
      marginTop: "0.3em",
    },
    exportIcon: {
      fontSize: 20,
    },
    viewIcon: {
      fontSize: 24,
    },
  });

  const classes = useStyles();

  return (
    <TopToolbar>
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ||
      authProvider.isMenuShow("attendances", "create") ? (
        <CreateButton basePath={basePath} />
      ) : null}
      <Button
        color="primary"
        component="span"
        onClick={handleView}
        style={{ marginTop: "-3px" }}
      >
        {listView ? (
          <ViewComfyIcon className={classes.viewIcon} />
        ) : (
          <ListIcon className={classes.viewIcon} />
        )}
        {!isSmall ? (
          <span className={classes.csvSpan}>{listView ? "Grid" : "List"}</span>
        ) : null}
      </Button>
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        name={isSmall ? null : "Export"}
      />
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <ImportButton {...props} />
      ) : null}
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <Button
          className="btnfilter"
          color="primary"
          component="span"
          onClick={Sampleexporter}
          style={
            isSmall
              ? {
                  fontSize: "0.8125rem",
                }
              : {
                  fontSize: "0.8125rem",
                  minWidth: "126px",
                }
          }
        >
          <DescriptionIcon
            style={{ transform: "rotate(180deg)", fontSize: 20 }}
          />
          {!isXSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>Sample CSV</span>
          ) : null}
        </Button>
      ) : null}
    </TopToolbar>
  );
};

const Sampleexporter = () => {
  const records = [
    {
      employee_code: "As",
      camera_ID: "camara001",
      date: "08/17/2020",
      hour: "12",
      min: "10",
      device_location: "in",
    },
    {
      employee_code: "As",
      camera_ID: "camara001",
      date: "08/17/2020",
      hour: "13",
      min: "10",
      device_location: "out",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record; // omit fields
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "employee_code",
        "camera_ID",
        "date",
        "hour",
        "min",
        "device_location",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "sampleAttendanceCsv"); // download as 'xxxx.csv` file
    }
  );
  // handleClose()
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
  userImg: {
    padding: 0,
    height: 24,
    width: 24,
    borderRadius: "30px",
  },
  menuButton: {
    color: "#00008b",
  },
  action: {
    textAlign: "center",
  },
});

// const Aside = () => (
//     <div style={{ width: 200, margin: "1em" }}>
//         <Typography variant="h6">Post details</Typography>
//         <Typography variant="body2">
//             Posts will only be published one an editor approves them
//     </Typography>
//     </div>
// );

const exporter = (records: any) => {
  const modes: any = {
    mobile: "Mobile",
    edge: "Edge",
    manual: "Manual",
    cron: "Cron",
    auto: "Auto",
    Kiosk: "Kiosk",
  };
  const devLocation: any = {
    in: "In",
    out: "Out",
  };
  const recordsForExport = records.map((record: any) => {
    const {
      time,
      authType,
      isRequested,
      fileData,
      isSucceed,
      employee,
      gateway,
      description,
      camera_ID,
      isDeleted,
      image,
      mode,
      isActive,
      device,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      lat,
      long,
      mask,
      confidence,
      isMask,
      workplaceId,
      clientLocationId,
      clientId,
      childCompanyId,
      shiftData,
      wokplaceData,
      ...dataToExport
    } = record; // omit fields
    dataToExport["Employee Name"] =
      employee && employee.name ? employee.name : null;
    dataToExport["Employee Type"] =
      employee && employee.type ? employee.type.toUpperCase() : null;
    dataToExport["Child Company"] = childCompanyId && childCompanyId.name;
    dataToExport["Child"] = clientId && clientId.name;
    dataToExport["Client Location"] = clientLocationId && clientLocationId.name;
    dataToExport["Workplace"] = workplaceId && workplaceId.name;
    dataToExport["Shift"] = shiftData && shiftData.name;
    dataToExport["Device"] = devLocation[authType];
    dataToExport["Time"] = helperFunc.commonDateFormat(time, "HH:mm:ss");
    dataToExport["Mode"] = modes[mode];
    // dataToExport["Confidence"] = confidence;
    // dataToExport.createdAt = helperFunc.isoToDate(record.createdAt); // add a field
    dataToExport["Is Mask"] = isMask ? "Yes" : "No";
    dataToExport["Status"] = isActive ? "Active" : "In Active"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Attendance Report"); // download as 'xxxx.csv` file
    }
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput
      // label="Search Employee Name"
      source="employeeNameCode"
      parse={trimValue}
      alwaysOn
      placeholder="Search Employee Name"
    />

    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const AttendanceList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [listView, setListView] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickedEmp, setClickedEmp] = React.useState();
  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const DEVICE_LOCATION = { in: "In", out: "Out" } as any;
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const classes = useStyles();
  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(7);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const user = helperFunc.getUserData();

  return (
    <div className={classes.root}>
      {isXSmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Attendance
        </h2>
      ) : null}
      <Route path="/attendance/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <>
              <div
                className={open === true ? "overlay" : ""}
                onClick={() => handleDrawerClose("fromlist")}
              />
              <List
                className="list-view2"
                exporter={exporter}
                actions={
                  <ListActions
                    handleDrawerOpen={handleDrawerOpen}
                    setListView={setListView}
                    listView={listView}
                  />
                }
                {...props}
                filterDefaultValues={filterData()}
                filters={
                  <AttendanceFilter
                    {...props}
                    handleDrawerClose={handleDrawerClose}
                    open={open}
                  />
                }
                perPage={20}
                pagination={
                  <Pagination
                    labelRowsPerPage="Records per page"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                  />
                }
                sort={{ field: "time", order: "DESC" }}
              >
                {listView ? (
                  <Datagrid optimized>
                    <FunctionField
                      label="label.image"
                      render={(record: any) => (
                        <img
                          src={
                            record?.image &&
                            uploadApiUrl &&
                            uploadApiUrl + record.image
                              ? uploadApiUrl && uploadApiUrl + record?.image
                              : "assets/img/emptyUser.png"
                          }
                          alt="Attendance"
                          className={classes.userImg}
                          // onError={(e: any) => {
                          //   e.target.src = "assets/img/Noimagefound.jpg";
                          // }}
                        />
                      )}
                    />
                    {/* <LinkField /> */}
                    <FunctionField
                      label="Name"
                      sortBy="employee.name"
                      render={(record: any) =>
                        `${
                          record["employee"] ? record?.employee?.name : "N/A"
                        }  `
                      }
                    />
                    <FunctionField
                      label="Last Status"
                      sortBy="authType"
                      render={(record: any) =>
                        `${
                          record["authType"]
                            ? DEVICE_LOCATION[record?.authType]
                            : "N/A"
                        }  `
                      }
                    />
                    <FunctionField
                      label="Type"
                      // sortBy="type"
                      render={(record: any) =>
                        `${
                          record?.employeeType?.name
                            ? record?.employeeType?.name
                            : record && record.employee && record.employee?.type
                        }  `
                      }
                    />
                    {authProvider?.isHiddenField(
                      props?.location?.pathname,
                      "remark",
                      "isHide"
                    ) ? (
                      ""
                    ) : (
                      <TextField source="remark" />
                    )}

                    <FunctionField
                      label="label.date"
                      sortBy="time"
                      render={(record: any) =>
                        `${
                          record["time"]
                            ? helperFunc.commonDateFormat(
                                record["time"],
                                "HH:mm:ss"
                              )
                            : "N/A"
                        }  `
                      }
                    />

                    {authProvider?.isHiddenField(
                      props?.location?.pathname,
                      "action",
                      "isHide"
                    ) ? (
                      <FunctionField
                        textAlign="center"
                        label="Remarks"
                        // sortBy="time"
                        // className={classes.action}
                        render={(record: any) => (
                          <div className="d-flex align-content-center justify-content-center">
                            <RemarkButtonField {...props} record={record} />
                          </div>
                        )}
                      />
                    ) : (
                      <FunctionField
                        label="Action"
                        textAlign="center"
                        // sortBy="time"
                        className={classes.action}
                        render={(record: any) => (
                          <div className="d-flex align-content-center justify-content-center">
                            <AttandanceShow {...props} record={record} />

                            <AttendanceEditButton {...props} record={record} />

                            <RemarkLinkFiled record={record} />
                          </div>
                        )}
                      />
                    )}
                  </Datagrid>
                ) : (
                  <GridList
                    selectedRow={
                      isMatch
                        ? parseInt((match as any).params.id, 10)
                        : undefined
                    }
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    clickedEmp={clickedEmp}
                    setClickedEmp={setClickedEmp}
                  />
                )}
              </List>
            </>
          );
        }}
      </Route>
    </div>
  );
};

export default AttendanceList;
