import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../employeeVehicle/commonForm";


const EmployeeVehicleCreate = (props: any) => {


  return (
    <Create {...props}>
      <CommonForm redirect={'/employeeVehicle'} {...props} />
    </Create>
  );
};


export default EmployeeVehicleCreate;
