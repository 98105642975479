import * as React from "react";
import { Button } from "react-admin";
import {
  Dialog,
  DialogTitle,
  Button as MaterialButton,
  DialogContent,
  makeStyles,
} from "@material-ui/core";

import ChangeAttendance from "./ChangeAttendance";
import Remarks from "./Remarks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 24px 0px 18px",
  },
  p0: { padding: 0 },
}));

const AttendanceDialogueBox = (props: any) => {
  const { activeMenu, clickedEmp, open, handleCloseClick } = props;

  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={handleCloseClick}>
        <DialogTitle className={classes.root}>{activeMenu}</DialogTitle>

        <DialogContent className={classes.p0}>
          {activeMenu === "Change Attendance" ? (
            <ChangeAttendance
              handleCloseClick={handleCloseClick}
              clickedEmp={clickedEmp}
            />
          ) : activeMenu === "Remarks" ? (
            <Remarks
              handleCloseClick={handleCloseClick}
              clickedEmp={clickedEmp}
            />
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AttendanceDialogueBox;
