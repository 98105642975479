import * as React from "react";
import { useState, FC } from "react";
import {
  Card,
  Grid,
  Box,
  Typography,
  CardHeader,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { EmployeeLeave } from "../../types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import leaveDefault from "../icons/leaveDefault.png";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

interface Props {
  leaves?: EmployeeLeave[];
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "30%",
    padding: 5,
    marginRight: "1em",
  },
  mobileRoot: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  col6: {
    flex: "0 0 auto",
    width: "calc(49.3% - 6.2em)",
    padding: 0,
    marginRight: "1em",
    display: "inline-grid",
  },
  mobileCol6: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  cost: {
    marginRight: "3em",
    color: theme.palette.text.primary,
  },
  cost2: {
    marginRight: "2em",
    color: theme.palette.text.primary,
  },
  headerText: {
    fontSize: 12,
    marginRight: "1em",
    color: theme.palette.text.primary,
  },
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  menu: {
    padding: "0px 12px 0px 12px",
    // border: "1px solid #6c757d",
    marginLeft: "18px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const LeavesData: FC<Props> = ({ leaves = [], isLoading }: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuData, setMenuData]: any = useState();
  const handleOpenMenu = (event: any, menuData: any) => {
    setAnchorEl(event.currentTarget);
    setMenuData(menuData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card className={isXSmall || isXSmall ? classes.mobileCol6 : classes.col6}>
      <CardHeader title={translate("pos.dashboard.leave")} />
      {isLoading ? (
        <span
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : leaves && leaves.length === 0 ? (
        <h4
          style={{
            height: "200px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          No Balance Found
        </h4>
      ) : leaves && leaves.length > 0 ? (
        <Grid
          container
          wrap="nowrap"
          style={{
            width: "auto",
            overflow: "auto",
            maxHeight: 156,
          }}
        >
          {(isLoading ? Array.from(new Array(3)) : leaves).map(
            (item: any, index: any) => {
              return (
                <Box
                  key={index}
                  width={110}
                  marginRight={0}
                  my={5}
                  marginTop={0}
                  onClick={(e: any) => handleOpenMenu(e, item)}
                >
                  {item ? (
                    <Box
                      style={{
                        border: "1px solid #6c757d9e",
                        alignItems: "center",
                        padding: "6px",
                        borderRadius: "7px",
                        height: "140px",
                        width: "100px",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        style={{ width: 100, height: 65 }}
                        alt={item.title}
                        src={
                          item && item.image
                            ? `${uploadApiUrl}${item.image}`
                            : leaveDefault
                        }
                      />
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "blue",
                          paddingTop: "3px",
                        }}
                      >
                        {item && item.usedLeave
                          ? item.usedLeave + "/" + item.entitledLeave
                          : 0 + "/" + item.entitledLeave}
                        {item ? (
                          // <Box pr={2}>
                          <Typography
                            gutterBottom
                            variant="body2"
                            style={{ textAlign: "center" }}
                          >
                            {item.name}
                          </Typography>
                        ) : (
                          // </Box>
                          <Box pt={0.5}>
                            <Skeleton />
                            <Skeleton width="60%" />
                          </Box>
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    <Skeleton variant="rect" width={100} height={65} />
                  )}
                </Box>
              );
            }
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PopoverClasses={{
              paper: classes.menu,
            }}
            // MenuListProps={{ onMouseLeave: handleClose }}
          >
            {menuData?.isNotDependentOnLeaveBalance === false ? (
              <Box>
                <Box>
                  <span style={{ color: "#0a9ed3" }}>
                    <b>Entitled Leave</b>
                  </span>{" "}
                  : {menuData?.entitledLeave}
                </Box>
                <Box>
                  <span style={{ color: "#895847" }}>
                    <b>Applied Leave</b>{" "}
                  </span>{" "}
                  : {menuData?.pendingLeave}
                </Box>
                <Box>
                  <span style={{ color: "#ff0a0acc" }}>
                    {" "}
                    <b>Availed Leave</b>
                  </span>{" "}
                  : {menuData?.usedLeave}
                </Box>
                <Box>
                  <span style={{ color: "green" }}>
                    {" "}
                    <b>Available Leave</b>
                  </span>{" "}
                  :{" "}
                  {menuData?.entitledLeave -
                    menuData?.usedLeave -
                    menuData?.pendingLeave}
                </Box>
              </Box>
            ) : (
              <Box>
                <Box>
                  <span style={{ color: "#895847" }}>
                    <b>Applied Leave</b>{" "}
                  </span>{" "}
                  : {menuData?.pendingLeave}
                </Box>
                <Box>
                  <span style={{ color: "#ff0a0acc" }}>
                    {" "}
                    <b>Availed Leave</b>
                  </span>{" "}
                  : {menuData?.usedLeave}
                </Box>
              </Box>
            )}
          </Menu>
        </Grid>
      ) : null}
    </Card>
  );
};

export default LeavesData;
