import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  useTranslate,
  TextInput,
  required,
  maxLength,
  BooleanInput,
  FormDataConsumer,
  useNotify,
  useDataProvider,
  useRedirect,
} from "react-admin";
import {
  Typography,
  useMediaQuery,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EmployeeAllotmentStyle } from "../helperStyle";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import WorkplaceIdSelectInput from "../components/WorkplaceIdSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import { toastError, toastInfo } from "../components/toast";
import helperFunc from "../helperFuncs";

interface State {
  allotments?: [];
  objCompany: {};
  objEmployee: {};
  emptyValue: false;
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(EmployeeAllotmentStyle);

const CommonForm = (props: any) => {
  // const notify = useNotify();
  const redirectUrl = useRedirect();
  const dataProvider = useDataProvider();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const [objCompany, setObjCompany] = useState({});
  const [objEmployee, setObjEmployee] = useState({});
  const [emptyValue, setEmptyValue] = useState(Boolean);
  let [allotments, setAllotments] = useState(
    props.record.allotments ? props.record.allotments : []
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const user = helperFunc.getUserData();
  const { resource, redirect, id } = props;

  const dataManage = (data: any, type: any) => {
    if (type === "childCompany") {
      setObjCompany(data);
    } else if (type === "employee") {
      setObjEmployee(data);
    } else {
      setEmptyValue(false);
    }
  };
  useEffect(() => {
    if (props.record && props.record.allotments) {
      setAllotments(props.record.allotments);
    }
  }, [props.record]);
  const addAllotment = (data: any) => {
    if (
      data &&
      (data.childCompanyId === "" || data.childCompanyId === undefined)
    ) {
      toastError("Please Select Company (Subsidiary)");
      // notify("please select Company (Subsidiary)", "warning");
      return;
    } else if (data && (data.employee === "" || data.employee === undefined)) {
      toastError("please Select Employee");
      // notify("please select Employee", "warning");
      return;
    } else {
      setAllotments([
        ...allotments,
        {
          companyId: objCompany,
          employeeId: objEmployee,
        },
      ]);
      setObjCompany({});
      setObjEmployee({});
      setEmptyValue(true);
      setTimeout(function () {
        setEmptyValue(false);
      }, 1000);
      // setEmptyValue(false);
    }
  };
  const removeAllotment = (idx: any) => {
    setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
  };
  const saveForm = (params: any) => {
    params.allotments = allotments.map((data: any) => {
      return { companyId: data.companyId._id, employeeId: data.employeeId._id };
    });
    if (!id) {
      dataProvider
        .create(resource, { data: params })
        .then((response: any) => {
          toastInfo("Element created Successfully.");
          // notify("Element created Successfully.");
          return redirectUrl(redirect);
        })
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
        });
    } else {
      dataProvider
        .update(resource, { data: params, id: id })
        .then((response: any) => {
          if (response && response.data && response.data.success) {
            toastInfo("Element update Successfully.");
            // notify("Element update Successfully.");
            response.data.success = null;
            return redirectUrl("/employeeAllotments");
          }
        })
        .catch((err: any) => {
          toastError(err.message);
          return;
        });
    }
  };
  return isSmall ? (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.countries.page.create" /> */}
      <div style={{ display: "inline" }}>
        <CompanyReferenceInput></CompanyReferenceInput>
        {user?.isClientConfiguration && (
          <>
            <FormDataConsumer formClassName={isXsmall ? null : classes.company}>
              {({ formData }: { formData: any }) => (
                <ClientMasterSelectInput
                  filter={{ isActive: true, company: formData.company }}
                ></ClientMasterSelectInput>
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <ClientLocationSelectInput
                  filter={{
                    clientId: formData.clientId,
                    isActive: true,
                    company: formData.company,
                  }}
                ></ClientLocationSelectInput>
              )}
            </FormDataConsumer>
          </>
        )}

        <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
          {({ formData }: { formData: any }) => (
            <WorkplaceIdSelectInput
              filter={{
                clientLocationId: formData.clientLocationId,
                isActive: true,
                company: formData.company,
              }}
            ></WorkplaceIdSelectInput>
          )}
        </FormDataConsumer>

        <SectionTitle label="Allocate Employee" />
        <FormDataConsumer formClassName={isXsmall ? null : classes.company}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
              dataManage={dataManage}
              emptyValue={{ emptyValue }}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
          {({ formData }: { formData: any }) => (
            <EmployeeSelectInput
              filter={{
                isActive: true,
                childCompanyId: formData.childCompanyId,
                company: formData.company,
              }}
              dataManage={dataManage}
              emptyValue={{ emptyValue }}
            ></EmployeeSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
          {({ formData }: { formData: any }) => (
            <Button
              onClick={() => addAllotment(formData)}
              variant="outlined"
              size="small"
              color="primary"
              type="button"
            >
              Allocate
            </Button>
          )}
        </FormDataConsumer>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  Company Name
                </TableCell>
                <TableCell className={classes.tableHead}>
                  Employee Name
                </TableCell>
                <TableCell className={classes.tableHead}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allotments.map((allotment: any, index: number) => (
                <TableRow key={index + 1}>
                  <TableCell className={classes.tableValue} align="left">
                    {allotment.companyId ? allotment.companyId.name : ""}
                  </TableCell>
                  <TableCell className={classes.tableValue} align="left">
                    {allotment.employeeId ? allotment.employeeId.name : ""}
                  </TableCell>
                  <TableCell className={classes.tableValue} align="left">
                    <Button
                      onClick={() => removeAllotment(index)}
                      variant="outlined"
                      className="m-15 danger"
                      type="button"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="zones"
          defaultValue={true}
        />
      </div>
      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  ) : (
    <SimpleForm {...props} save={saveForm}>
      {/* <SectionTitle label="resources.employeeAllotments.page.create" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      {user?.isClientConfiguration && (
        <>
          {" "}
          <FormDataConsumer formClassName={isXsmall ? null : classes.company}>
            {({ formData }: { formData: any }) => (
              <ClientMasterSelectInput
                filter={{ isActive: true, company: formData.company }}
              ></ClientMasterSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
            {({ formData }: { formData: any }) => (
              <ClientLocationSelectInput
                filter={{
                  clientId: formData.clientId,
                  isActive: true,
                  company: formData.company,
                }}
              ></ClientLocationSelectInput>
            )}
          </FormDataConsumer>
        </>
      )}

      <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
        {({ formData }: { formData: any }) => (
          <WorkplaceIdSelectInput
            filter={{
              clientLocationId: formData.clientLocationId,
              isActive: true,
              company: formData.company,
            }}
          ></WorkplaceIdSelectInput>
        )}
      </FormDataConsumer>

      <SectionTitle label="Allocate Employee" />
      <FormDataConsumer formClassName={isXsmall ? null : classes.company}>
        {({ formData }: { formData: any }) => (
          <ChildCompanySelectInput
            filter={{ isActive: true, company: formData.company }}
            dataManage={dataManage}
            emptyValue={{ emptyValue }}
          ></ChildCompanySelectInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
        {({ formData }: { formData: any }) => (
          <EmployeeSelectInput
            filter={{
              isActive: true,
              childCompanyId: formData.childCompanyId,
              company: formData.company,
            }}
            dataManage={dataManage}
            emptyValue={{ emptyValue }}
          ></EmployeeSelectInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={isXsmall ? null : classes.employee}>
        {({ formData }: { formData: any }) => (
          <Button
            className={classes.allotmentBtn}
            onClick={() => addAllotment(formData)}
            variant="outlined"
            size="small"
            color="primary"
            type="button"
          >
            Allocate
          </Button>
        )}
      </FormDataConsumer>
      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Company Name</TableCell>
              <TableCell className={classes.tableHead}>Employee Name</TableCell>
              <TableCell className={classes.tableHead}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allotments.map((allotment: any, index: number) => (
              <TableRow key={index + 1}>
                <TableCell className={classes.tableValue} align="left">
                  {allotment.companyId ? allotment.companyId.name : ""}
                </TableCell>
                <TableCell className={classes.tableValue} align="left">
                  {allotment.employeeId ? allotment.employeeId.name : ""}
                </TableCell>
                <TableCell className={classes.tableValue} align="left">
                  <Button
                    onClick={() => removeAllotment(index)}
                    variant="outlined"
                    className={classes.removeAllotmentBtn}
                    type="button"
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TextInput type="hidden" source="hiddenVal" className={classes.hidden} />
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="zones"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidate = [required(), maxLength(50)];
const codeValidate = [required(), maxLength(20)];
const requiredValidate = [required()];

export default CommonForm;
