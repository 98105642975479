import DeviceList from "./DeviceList";
import DeviceEdit from "./DeviceEdit";
import DeviceCreate from "./DeviceCreate";
import DeviceIcon from "@material-ui/icons/DeveloperBoard";
export default {
  list: DeviceList,
  create: DeviceCreate,
  edit: DeviceEdit,
  icon: DeviceIcon,
};
