import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "../departments/commonForm";
import { PostEditToolbar } from '../components/PostEditToolbar';

const DepartmentEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Department"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};
export default DepartmentEdit;
