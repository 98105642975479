import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  // CSSProperties,
} from "react";
import {
  useVersion,
  useDataProvider,
  // Loading,
  // Error,
  // useQuery,
  useNotify,
  Link,
} from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";

// import Welcome from "./Welcome";
// import MonthlyRevenue from "./MonthlyRevenue";
// import NbNewOrders from "./NbNewOrders";
// import PendingOrders from "./PendingOrders";
// import PendingReviews from "./PendingReviews";
// import NewCustomers from "./NewCustomers";
// import OrderChart from "./OrderChart";
import TotalClients from "./TotalClients";
import TotalDepartments from "./TotalDepartments";
import TotalDesignations from "./TotalDesignations";
import TotalDevices from "./TotalDevices";
import TotalNotifications from "./TotalNotifications";
// import TotalProjects from "./TotalProjects";
import TotalRoles from "./TotalRoles";
import ChartClients from "./ChartClients";
import ChartEmployees from "./ChartEmployees";
// import ChartProjects from "./ChartProjects";
import ListClients from "./ListClients";
import { Customer, Company } from "../types";
import { toastError } from "../components/toast";

// interface OrderStats {
//   revenue: number;
//   nbNewOrders: number;
//   pendingOrders: Order[];
// }

interface CustomerData {
  [key: string]: Customer;
}

interface State {
  // nbNewOrders?: number;
  // nbPendingReviews?: number;
  // pendingOrders?: Order[];
  // pendingOrdersCustomers?: CustomerData;
  // pendingReviews?: Review[];
  // pendingReviewsCustomers?: CustomerData;
  // recentOrders?: Order[];
  // revenue?: number;
  ClientCount?: number;
  DepartmentCount?: number;
  DesignationCount?: number;
  DeviceCount?: number;
  Notifications?: number;
  // ProjectCount?: number;
  RolesCount?: number;
  ClientsChartData?: {};
  EmployeesChartData?: {};
  // ProjectsChartData?: {};
  recentCompanyList?: Company[];
  // cardsCountData?: {};
}

const styles = {
  flex: { display: "flex" },
  flexXSmall: { display: "flex", marginRight: "15px", marginLeft: "15px" },
  flexCol: { display: "flex", marginTop: "2em" },
  mobileFlexCol: {
    display: "flex",
    marginTop: "2em",
    width: window.screen.width - 10,
  },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
};

const SuperDashboard: FC = () => {
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  React.useEffect(() => {
    if (!isSmall && !isXSmall && container && container.innerText) {
      container.innerText = "";
      container.innerText = "Dashboard";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container && container.innerText) {
        container.innerText = "";
      }
    };
  }, [container]);
  const [state, setState] = useState<State>({});
  const version = useVersion();
  // const notify = useNotify();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const fetchCardsCount = useCallback(async () => {
    await dataProvider
      .callCustom("dashboard/cardsCount", {})
      .then((response: any) => {
        setState((state) => ({
          ...state,
          ClientCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.clientCount,

          Headcounts:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.headcounts,
          DepartmentCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.departmentCount,
          DesignationCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.designationCount,
          DeviceCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.deviceCount,
          Notifications:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.notifications,
          ProjectCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.projectCount,
          RolesCount:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.rolesCount,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            // localStorage.clear()

            window.location.replace("/login");
          }
          // throw new Error(error.msg);
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchDiagramDahboardData = useCallback(async () => {
    await dataProvider
      .callCustom("dashboard/diagramDahboardData", {})
      .then((response: any) => {
        setState((state) => ({
          ...state,
          ClientsChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.clients,
          EmployeesChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.employees,
          ProjectsChartData:
            response &&
            response.data &&
            response.data.item &&
            response.data.item.projects,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            // localStorage.clear()

            window.location.replace("/login");
          }
          // throw new Error(error.msg);
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider]);

  const fetchCompanyListDahboardData = useCallback(async () => {
    await dataProvider
      .getList("companies", {
        pagination: { page: 1, perPage: 7 },
        sort: { field: "createdAt", order: "DESC" },
      })
      .then((response: any) => {
        setState((state) => ({
          ...state,
          recentCompanyList: response.data,
        }));
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (err.response.status === 401) {
            // localStorage.clear()

            window.location.replace("/login");
          }
        });
      });

    setState((state) => ({ ...state }));
  }, [dataProvider]);

  useEffect(() => {
    fetchCardsCount();
    fetchDiagramDahboardData();
    fetchCompanyListDahboardData();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    // cardsCountData,
    ClientCount,
    DepartmentCount,
    DesignationCount,
    DeviceCount,
    Notifications,
    // ProjectCount,
    RolesCount,
    ClientsChartData,
    EmployeesChartData,
    // ProjectsChartData,
    recentCompanyList,
    // nbNewOrders,
    // nbPendingReviews,
    // pendingOrders,
    // pendingOrdersCustomers,
    // pendingReviews,
    // pendingReviewsCustomers,
    // revenue,
    // recentOrders,
  } = state;

  return isXSmall ? (
    <div style={styles.flexXSmall}>
      <div style={styles.leftCol}>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalClients value={ClientCount} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalDepartments value={DepartmentCount} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalDesignations value={DesignationCount} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalNotifications value={Notifications} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalRoles value={RolesCount} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <TotalDevices value={DeviceCount} />
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <ChartClients data={ClientsChartData}></ChartClients>
        </div>
        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <ChartEmployees data={EmployeesChartData}></ChartEmployees>
        </div>

        <div
          style={
            isSmall
              ? styles.mobileFlexCol
              : isXSmall
              ? styles.mobileFlexCol
              : styles.flexCol
          }
        >
          <ListClients companies={recentCompanyList}></ListClients>
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div className="super-dash" style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flexCol}>
          <TotalClients value={ClientCount} />
          <TotalDepartments value={DepartmentCount} />
        </div>
        <div style={styles.flexCol}>
          <TotalDesignations value={DesignationCount} />
          <TotalNotifications value={Notifications} />
        </div>
        <div style={styles.flexCol}>
          <TotalRoles value={RolesCount} />
          <TotalDevices value={DeviceCount} />
        </div>
        <div style={styles.flexCol}>
          <ChartClients data={ClientsChartData}></ChartClients>

          <ChartEmployees data={EmployeesChartData}></ChartEmployees>
        </div>

        <div style={styles.flexCol}>
          <ListClients companies={recentCompanyList}></ListClients>
        </div>
      </div>
    </div>
  ) : (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flex}>
          <TotalClients value={ClientCount} />
          <TotalDepartments value={DepartmentCount} />
          <TotalDesignations value={DesignationCount} />
        </div>
        <div style={styles.flexCol}>
          <TotalNotifications value={Notifications} />

          <TotalRoles value={RolesCount} />
          <TotalDevices value={DeviceCount} />
        </div>
        <div style={styles.flexCol}>
          <ChartClients data={ClientsChartData}></ChartClients>

          <ChartEmployees data={EmployeesChartData}></ChartEmployees>
        </div>

        <div style={styles.flexCol}>
          <ListClients companies={recentCompanyList}></ListClients>
        </div>
      </div>
    </div>
  );
};

export default SuperDashboard;
