import AttendanceList from "./AttendanceList";
import { CommonAttendance } from "./attedanceCommon";
import { CollegeAttendanceIcon } from "../svgIcon";

export default {
  list: AttendanceList,
  create: CommonAttendance,
  edit: CommonAttendance,
  icon: CollegeAttendanceIcon,
};
