import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateField, EditButton, useTranslate } from "react-admin";
import { Identifier } from "ra-core";
import { Stream } from "../types";
import { mobileGridStyle } from "../helperStyle";

const useStyles = makeStyles(mobileGridStyle);

interface Props {
  ids?: Identifier[];
  data?: { [key: string]: Stream };
  basePath?: string;
  resource?: string;
}

const MobileGrid: FC<Props> = ({ ids, data, basePath, resource }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h5>{`${data[id].name} `}</h5>
                <h6>{`${data[id].code} `}</h6>
                <EditButton
                  resource={resource}
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate("label.createdAt")} : &nbsp;
              <DateField record={data[id]} source="createdAt" type="date" />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
