export const useGetTableDataHook = () => {
  const getReportData = (reportData: any, index: any) => {
    return [
      {
        headerName: "Sr.No",
        value: index + 1,
        left: 0,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Company Name",
        value: reportData?.childCompanyId?.name,
        left: 200,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Emp Code",
        value: reportData?.employeeCode,
        left: 300,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Emp Name",
        value: reportData?.name,
        isDark: true,
        left: 400,
        zIndex: 9999,
        isSticky: true,
        bodyZIndex: 999,
      },
      {
        headerName: "Designation",
        value: reportData?.designation?.name,
        left: 500,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Department",
        value: reportData?.department?.name,
        left: 600,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Reporting Manager",
        value: reportData?.reportingManager?.name,
        left: 700,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Working days in Month",
        value: reportData?.workingDays,
        left: 800,
        zIndex: 999,
        isSticky: false,
      },
      {
        headerName: "Paid Holidays",
        value: reportData?.holiday ? reportData?.holiday : 0,
        left: 800,
        zIndex: 999,
        isSticky: false,
      },
    ];
  };

  const getLeaveData = (employeeData: any, leave: any) => {
    if (leave?.isNotDependentOnLeaveBalance) {
      return [
        {
          fieldName: "Taken",
          value:
            employeeData && employeeData[leave?._id + "_approved"]
              ? employeeData && employeeData[leave?._id + "_approved"]
              : 0,
        },
        {
          fieldName: "Pending",
          value:
            employeeData && employeeData[leave?._id + "_approved"]
              ? employeeData && employeeData[leave?._id + "_approved"]
              : 0,
        },
      ];
    } else
      return [
        {
          fieldName: "Opening",

          value:
            employeeData && employeeData[leave?._id + "_openingBalance"]
              ? employeeData && employeeData[leave?._id + "_openingBalance"]
              : 0,
        },
        {
          fieldName: "Credited",

          value:
            employeeData && employeeData[`${leave?._id}_monthlyCredit`]
              ? employeeData && employeeData[`${leave?._id}_monthlyCredit`]
              : 0,
        },
        {
          fieldName: "Total Balance",
          value:
            employeeData && employeeData[`${leave?._id}_totalBalance`]
              ? employeeData && employeeData[`${leave?._id}_totalBalance`]
              : 0,
        },
        {
          fieldName: "Taken",

          value:
            employeeData && employeeData[leave?._id + "_approved"]
              ? employeeData && employeeData[leave?._id + "_approved"]
              : 0,
        },
        {
          fieldName: "Closing",

          value:
            employeeData && employeeData[leave?._id + "_closingBalance"]
              ? employeeData && employeeData[leave?._id + "_closingBalance"]
              : 0,
        },
      ];
  };

  return { getReportData, getLeaveData };
};
