import * as React from "react";
import { FC } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import { stringify } from "query-string";

import employees from "../employees";
import { FieldProps, Company } from "../types";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const LinkToRelatedEmployees: FC<FieldProps<Company>> = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: "/employees",
        search: stringify({
          page: 1,
          perPage: 25,
          sort: "id",
          order: "DESC",
          filter: JSON.stringify({ company: record.id }),
        }),
      }}
      className={classes.link}
      title={`${translate("resources.employee.title")}`}
    >
      <employees.icon className={classes.icon} />
    </Button>
  ) : null;
};

export default LinkToRelatedEmployees;
