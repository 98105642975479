import React, { useEffect, useState } from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  TextInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  FunctionField,
  Button as ReactButton,
  DateInput,
  useDataProvider,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import helperFunc from "../helperFuncs";
import { trimValue, validate } from "../helperValidate";
import { filterStyle, listActionsStyle } from "../helperStyle";
import { isActive } from "../helperChoice";
import LinkField from "../components/LinkField";
import MobileGrid from "../components/MobileGrid";
import { exporterDetails } from "../components/export";
import { SmListHeader } from "../components/SmFormHeader";

const PostFilterForm = (data: any) => {
  const useStyles = makeStyles(filterStyle);
  const theme = useTheme();
  const classes = useStyles();
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };

  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <TextInput
                      source="code"
                      helperText={false}
                      resettable
                      validate={validate}
                      format={helperFunc.codeFormatter}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      allowEmpty={true}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                      resettable
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      label="label.createdAt"
                      source="createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      allowEmpty={true}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const { className, basePath, handleDrawerOpen } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(listActionsStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  return (
    <TopToolbar className={isXsmall ? classes.flexrow : className}>
      <CreateButton basePath={basePath} />
      <Button
        className={classes.filterButton}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon className={classes.filterIcon} />
        {isSmall ? null : "Filter"}
      </Button>
      <Button
        className={classes.exportButton}
        color="primary"
        component="span"
        onClick={() => exporterDetails(dataProvider, props)}
      >
        <GetAppIcon style={{ fontSize: 20 }} />
        {isSmall ? null : "Export"}
      </Button>
    </TopToolbar>
  );
};

const ClassroomFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const PostPanel = (props: any) => {
  const dataProvider = useDataProvider();
  const [history, setHistory] = useState({
    success: false,
    count: 0,
    list: [],
  });
  const [showData, setShowData] = useState(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const params = {
    _id: props.id,
  };
  const header = ["Stream", "Course", "Semester", "Created At"];

  useEffect(() => {
    if (showData === false) {
      dataProvider
        .callCustom(`${props.resource}/getClassroomHistory`, params)
        .then((response: any) => {
          if (response) {
            if (response.data && response.data.success) {
              setHistory(response.data);
            }
            setShowData(true);
          }
        })
        .catch((err: any) => {
          setShowData(true);
        });
    }
  }, [props]);

  if (showData === false) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <TableContainer component={Paper}>
        {history.list.length === 0 ? (
          <TableCell
            style={{ textAlign: isXsmall ? "center" : "right", width: "42em" }}
          >
            No Data Found
          </TableCell>
        ) : (
          <Table size="small" aria-label="a dense table">
            <TableHead>
              {" "}
              <TableRow>
                {header.map((item: any) => {
                  return <TableCell align="left">{item}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {history.list.map((el: any, i: any) => (
                <TableRow key={i}>
                  <TableCell align="left">
                    {el && el.stream && el.stream.name ? el.stream.name : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {el && el.course && el.course.name ? el.course.name : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {el && el.semester && el.semester.name
                      ? el.semester.name
                      : "N/A"}
                  </TableCell>

                  <TableCell align="left">
                    {el && el.createdAt
                      ? helperFunc.commonDateFormat(el.createdAt, "HH:mm:ss")
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  }
};

const ClassroomList = (props: any) => {
  const [open, setOpen] = useState(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  return (
    <>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <SmListHeader {...props} />
      <List
        className="list-view2"
        {...props}
        filters={
          isSmall ? (
            <ClassroomFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <ClassroomFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        bulkActionButtons={false}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid expand={<PostPanel {...props} />} optimized>
            <LinkField />
            <TextField source="code" format={helperFunc.codeFormatter} />
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField
              source="isActive"
              label="label.status"
              valueLabelTrue="Active"
              valueLabelFalse="In-active"
              emptyText="N/A"
            />
          </Datagrid>
        )}
      </List>
    </>
  );
};

export default ClassroomList;
