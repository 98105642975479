import * as React from "react";
import { FC, memo, useState } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";

interface State {
  zones?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const MultiSelectZoneInput: FC<Props> = ({ filter }) => {
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider.getSearchList("zones/search", filter).then((response: any) => {
      setState((state) => ({
        ...state,
        zones: response && response.data,
      }));
    });
  }, [filter]);

  const { zones } = state;

  return (
    <AutocompleteArrayInput
      resettable
      label="Zones"
      source="dataRights.zoneId"
      choices={zones}
      helperText={false}
    />
    // <ReferenceArrayInput
    //   resettable
    //   label="Zones"
    //   source="zoneId"
    //   reference="zones"
    // //   choices={zones}
    //   // filter={{ company: formData.company, isActive: true }}
    // // validate={requiredValidate}
    // // formClassName={classes.referenceEmpArray}
    // >
    //   <AutocompleteArrayInput resettable />
    // </ReferenceArrayInput>
  );
};
MultiSelectZoneInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectZoneInput);
