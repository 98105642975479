import React, { useState, FC, useEffect } from "react";
import {
  useTranslate,
  Button,
  SimpleForm,
  useDataProvider,
  required,
  FormDataConsumer,
  LinearProgress,
  DateInput,
  AutocompleteArrayInput,
} from "react-admin";
import {
  Typography,
  useMediaQuery,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import { toastError } from "../components/toast";
import helperFunc from "../helperFuncs";
import moment from "moment";
import ChildCompanyIntialSelectInput from "../components/ChildCompanyIntialSelectInput";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
    marginRight: "8px",
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableHeadCenter: { padding: 10, textAlign: "center" },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  tableFlexWidth: {
    display: "flex",
    width: "350px",
    height: "65px",
    alignItems: "center",
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  editAllotmentBtn: {
    fontSize: 12,
    border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
  },
  disFlexCenter: {
    display: "flex",
    alignItems: "center",
  },
});

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const SaturdayAllotment = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { open, setGenerateDialogueOpen } = props;

  const requiredValidate = [required()];

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [allotments, setAllotments] = useState() as any;

  const [isLoad, setLoader] = useState(false);

  const [QRId, setQRId] = useState();

  const fromDate = new Date();
  fromDate.setMinutes(fromDate.getMinutes() + 1);

  const newToDate = new Date();
  newToDate.setMinutes(newToDate.getMinutes() + 1);

  const toDate = moment(newToDate);
  toDate.add(3, "h");

  const handleDialogClose = () => setGenerateDialogueOpen(false);

  const addAllotment = (data: any) => {
    let url = `weekOffList/generate`;

    if (data?.childCompanyId === "" || data?.childCompanyId === undefined) {
      toastError("Please select Subsidiary");
      return;
    } else if (data && (data.fromDate === "" || data.fromDate === undefined)) {
      toastError("Please select From Date");
      return;
    } else if (data && (data.toDate === "" || data.toDate === undefined)) {
      toastError("Please select To Date");
      return;
    } else if (data.toDate && new Date(data.fromDate) > new Date(data.toDate)) {
      toastError("To Date must be  greater than from Date");
      return;
    } else if (data?.days?.length === 0) {
      toastError("Please add WeekDay(s)");
      return;
    } else {
      dataProvider
        .callCustom(url, {
          fromDate: data.fromDate,
          toDate: data.toDate,
          childCompanyId: data?.childCompanyId,
          days: data?.days,
        })
        .then((response: any) => {
          handleDialogClose();
        })
        .catch((err: any) => {
          toastError(err);
        });
    }
  };

  const user = helperFunc.getUserData();

  const weekDays = [
    {
      name: "Sat",
      id: "0",
    },
    {
      name: "Sun",
      id: "1",
    },
  ];
  const weekDays2 = ["0", "1"];

  const currentYear = moment().format("YYYY");
  const firstDayOfYear = moment(`${currentYear}-01-01`).format("YYYY-MM-DD");
  const lastDayOfYear = moment(`${currentYear}-12-31`).format("YYYY-MM-DD");

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Generate Saturday"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>Generate Saturday</DialogTitle>

        <DialogContent>
          <SimpleForm validate={() => true} toolbar={null}>
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <ChildCompanyIntialSelectInput
                  label="Subsidiary"
                  filter={{
                    isActive: true,
                  }}
                  // defaultValue={user?.company?._id}
                  validate={requiredValidate}
                ></ChildCompanyIntialSelectInput>
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <DateInput
                  source="fromDate"
                  label="label.fromDate"
                  helperText={false}
                  options={{
                    format: helperFunc.datePickerFormat(),
                    clearable: true,
                  }}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                  defaultValue={firstDayOfYear}
                  validate={requiredValidate}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <DateInput
                  source="toDate"
                  label="label.toDate"
                  helperText={false}
                  options={{
                    format: helperFunc.datePickerFormat(),
                    clearable: true,
                  }}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                  defaultValue={lastDayOfYear}
                  validate={requiredValidate}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <AutocompleteArrayInput
                  resettable
                  label="Weekday(s)"
                  source="days"
                  choices={weekDays}
                  defaultValue={weekDays2}
                  className={classes.root}
                  fullWidth={isXsmall ? false : true}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <MaterialButton
                  onClick={() => addAllotment(formData)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="button"
                  className={isXsmall ? undefined : classes.allotmentBtn}
                >
                  Generate
                </MaterialButton>
              )}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? <LinearProgress /> : ""}
          <Button
            label="Close"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SaturdayAllotment;
