import WeekOffList from "./WeekOffList";
import WeekOffEdit from "./WeekOffEdit";
import WeekOffCreate from "./WeekOffCreate";
import { WeekOffIcon } from "../svgIcon";

export default {
  list: WeekOffList,
  // create: WeekOffCreate,
  // edit: WeekOffEdit,
  icon: WeekOffIcon,
};
