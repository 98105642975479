import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import CompanyIcon from "@material-ui/icons/Business";
import helperFunc from "../../helperFuncs";
import AttendanceLogo from "../../attendance/AttendanceLogo";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery, Theme } from "@material-ui/core";
interface Props {
  data?: [];
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    // display: 'flex',
    width: "100%",
    marginRight: "1em",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRoot: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  newRoot: {
    margin: "2px",
  },
  // Newroot: { width: 25, maxWidth: 25, maxHeight: 25 },
  media: {
    width: 280,
  },
  gridList: {
    // width: "50%",
    margin: 0,
    flex: 1,
    display: "flex",
  },
  mobileGridList: {
    width: "100%",
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  cost: {
    marginRight: "1em",
    color: theme.palette.text.primary,
  },
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  rightAlignedCell: { textAlign: "right" },
  boldCell: { fontWeight: "bold" },
}));
// const AttendanceListData: FC<ListComponentProps<{ id: string }>> = (data, props) => {
const AttendanceListData: FC<Props> = ({ data = [], isLoading }) => {
  const location: any = {
    in: "In",
    out: "Out",
  };
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  const keys = Object.keys(data);
  const values = Object.values(data);
  const dataArr: any = [];

  keys.forEach((key, index) => {
    dataArr.push(values[index]);
  });
  const getColsForWidth = (width: Breakpoint) => {
    if (width === "xs") return 2;
    if (width === "sm") return 3;
    if (width === "md") return 4;
    if (width === "lg") return 5;
    return 6;
  };
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <Card className={isXSmall ? classes.mobileRoot : classes.root}>
      <CardHeader title="Today's Attendance" />
      {isLoading ? (
        <span
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <>
          <div className={classes.newRoot}>
            {dataArr && dataArr.length > 0 ? (
              <MuiGridList
                cellHeight={180}
                cols={getColsForWidth(isSmall ? "xs" : "md")}
                // className={isSmall ? classes.mobileGridList : classes.gridList}
              >
                {dataArr !== null &&
                  dataArr.map((id: any, index: number) => {
                    if (index < 10) {
                      return (
                        <GridListTile
                        // @ts-ignore
                        // component={Link}
                        // key={id}
                        // to={linkToRecord(basePath, data[id].id)}
                        >
                          <AttendanceLogo record={id}> </AttendanceLogo>

                          <GridListTileBar
                            className={classes.tileBar}
                            // title={id.employee.name}
                            subtitle={
                              id &&
                              id.time &&
                              location[id.authType] +
                                "  " +
                                "Time" +
                                "  " +
                                ":" +
                                helperFunc.commonDateFormat(
                                  id && id.time,
                                  "HH:mm:ss"
                                )
                            }
                            // actionIcon={<AttandanceShow record={data[id]}></AttandanceShow>}
                          />
                        </GridListTile>
                      );
                    }
                  })}
              </MuiGridList>
            ) : (
              <h4
                style={{
                  width: "100%",
                  height: "200px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                No Attendance Found
              </h4>
            )}
          </div>
          {dataArr && dataArr.length > 0 ? (
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button
                size="small"
                color="primary"
                component={Link}
                to={{
                  pathname: "/attendance",
                }}
                className={classes.link}
                title={`${translate("pos.dashboard.read_more")}`}
              >
                <CompanyIcon style={{ paddingRight: "0.5em" }} />
                {translate("pos.dashboard.read_more")}
              </Button>
            </CardActions>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default AttendanceListData;
