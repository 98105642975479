import { parse } from "querystring";

export const useGetFilterEmployee = (props: any) => {
  let employeeId;
  if (props?.location?.search !== "") {
    employeeId = parse(props.location.search) as any;
  }

  let filter;

  if (employeeId && employeeId["?filter"]) {
    filter = JSON.parse(employeeId["?filter"]);
  }

  const filterEmployeeId = filter?.employee;
  return filterEmployeeId;
};
