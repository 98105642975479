import React, { useState, useEffect } from "react";
import { useDataProvider, FormDataConsumer } from "react-admin";
import {
  Box,
  Button,
  useMediaQuery,
  Theme,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import { Form } from "react-final-form";
import { SectionTitle, validate } from "../helperValidate";
import { styleData } from "../helperConst";
import { assignTimeslotShowStyle } from "../helperStyle";
import {
  AddDialogueBox,
  RemoveDialogueBox,
  ViewDialogueBox,
} from "./dialogueBox";
import { toastError } from "../components/toast";
import { SmFormHeader } from "../components/SmFormHeader";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import ClassroomSelectInput from "../components/ClassroomSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const AssignTimeslot = (props: any): any => {
  const [timeId, setTimeId] = useState("");
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [timeslotData, setTimeslotData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isLoad, setLoader] = useState(false);
  const [subjectId, setSubjectId] = useState(false);
  const [facultyId, setFacultyId] = useState(false);
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [semesterChange, setSemesterChange] = useState(Boolean);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(assignTimeslotShowStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const headerArr = [
    { label: "Monday", key: "monday" },
    { label: "Tuesday", key: "tuesday" },
    { label: "Wednesday", key: "wednesday" },
    { label: "Thursday", key: "thursday" },
    { label: "Friday", key: "friday" },
    { label: "Saturday", key: "saturday" },
    { label: "Sunday", key: "sunday" },
  ];
  let container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };
  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };
  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Assign Timeslot";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const handleViewDialogClose = () => {
    setViewDialogOpen(false);
  };
  const handleViewDialogOpen = (id: any, subject: any, faculty: any) => {
    setViewDialogOpen(true);
    setTimeId(id);
    setSubjectId(subject);
    setFacultyId(faculty);
  };
  const handleAddDialogClose = () => {
    setDialogOpen(false);
  };
  const handleAddDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleRemoveDialogOpen = (id: any) => {
    setRemoveDialogOpen(true);
    setTimeId(id);
  };
  const handleRemoveDialogClose = (index: any) => {
    setRemoveDialogOpen(false);
  };

  const onSubmit = (formdata: any) => {
    if (!formdata.stream) {
      toastError("Please Select Stream");
    } else if (!formdata.course) {
      toastError("Please Select Course");
    } else if (!formdata.semester) {
      toastError("Please Select Semester");
    } else if (!formdata.classroom) {
      toastError("Please Select Classroom");
    } else {
      setLoader(true);
      const resourcePath = `assign-timeslot/timetable-list`;
      dataProvider
        .callCustom(resourcePath, {
          stream: formdata && formdata.stream ? formdata.stream : null,
          course: formdata && formdata.course ? formdata.course : null,
          semester: formdata && formdata.semester ? formdata.semester : null,
          classroom: formdata && formdata.classroom ? formdata.classroom : null,
        })
        .then((response: any) => {
          setLoader(false);
          setTimeslotData(response && response.data && response.data.list);
        })
        .catch((err: any) => {
          setLoader(false);
        });
    }
  };

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <SmFormHeader {...props} />
      <Form onSubmit={onSubmit}>
        {(props: any) => (
          <form>
            <div
              className={
                isXSmall
                  ? classes.exSmFlexrow
                  : isSmall
                  ? classes.smFlexrow
                  : classes.flexrow
              }
            >
              <Button
                className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
                color="primary"
                component="span"
                onClick={handleAddDialogOpen}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                <span className={isSmall ? classes.csvSpan : ""}>Create</span>
              </Button>
            </div>

            <Box
              component="div"
              className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
            >
              <CompanyReferenceInput></CompanyReferenceInput>
            </Box>
            <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
              <div>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <StreamSelectInput
                      labelName="Stream *"
                      dataManage={streamData}
                    ></StreamSelectInput>
                  )}
                </FormDataConsumer>
              </div>
              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => {
                    return (
                      <CourseSelectInput
                        filter={{ stream: formData.stream, isActive: true }}
                        validate={validate}
                        formClassName={classes.code}
                        streamChange={streamChange}
                        dataManage={courseData}
                      ></CourseSelectInput>
                    );
                  }}
                </FormDataConsumer>
              </div>
            </div>
            <SectionTitle label="" />
            <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
              <div>
                <FormDataConsumer formClassName={classes.name}>
                  {({ formData }: { formData: any }) => (
                    <SemesterSelectInput
                      filter={{
                        stream: formData.stream,
                        course: formData.course,
                        isActive: true,
                      }}
                      validate={validate}
                      dataManage={semesterData}
                      streamChange={streamChange}
                      courseChange={courseChange}
                    ></SemesterSelectInput>
                  )}
                </FormDataConsumer>
              </div>

              <div style={isSmall || isXSmall ? undefined : styleData}>
                <FormDataConsumer>
                  {({ formData }: { formData: any }) => (
                    <ClassroomSelectInput
                      filter={{
                        stream: formData.stream,
                        course: formData.course,
                        semester: formData.semester,
                        isActive: true,
                      }}
                      // labelName="Classroom *"
                      validate={validate}
                      streamChange={streamChange}
                      courseChange={courseChange}
                      semesterChange={semesterChange}
                      // emptyValue={emptyValue}
                    ></ClassroomSelectInput>
                  )}
                </FormDataConsumer>
              </div>
            </div>
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(formData)}
                  >
                    {isLoad ? (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        className={classes.loadDivColor}
                      />
                    ) : (
                      "VIEW"
                    )}
                  </Button>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <ViewDialogueBox
                    open={viewDialogOpen}
                    close={handleViewDialogClose}
                    id={timeId}
                    listApi={onSubmit}
                    formdata={formData}
                    subjectId={subjectId}
                    facultyId={facultyId}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <AddDialogueBox
                    open={dialogOpen}
                    close={handleAddDialogClose}
                    formData={formData.stream}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <RemoveDialogueBox
                    close={handleRemoveDialogClose}
                    open={removeDialogOpen}
                    listApi={onSubmit}
                    id={timeId}
                    formdata={formData}
                  />
                );
              }}
            </FormDataConsumer>
          </form>
        )}
      </Form>

      {timeslotData && timeslotData.length === 0 ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smrtable : classes.rtable}>
          <p className={classes.noteTextColor}>
            * To Select/Update Details of Timeslot click on Particular Timeslot.
          </p>
          <>
            <TableContainer className={isXSmall ? "" : classes.container}>
              <Table className={classes.mtTable} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headerArr.map((day: any) => {
                      return (
                        <TableCell className={classes.header} rowSpan={2}>
                          {day.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {timeslotData &&
                    timeslotData.map((employeeData: any, index: number) => {
                      return (
                        <>
                          <TableRow>
                            {headerArr.map((item: any) => {
                              return (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {employeeData && employeeData[item.key] ? (
                                    <button
                                      onClick={() =>
                                        handleViewDialogOpen(
                                          employeeData[item.key]._id,
                                          employeeData[item.key].subjectId,
                                          employeeData[item.key].facultyId
                                        )
                                      }
                                      className={
                                        employeeData &&
                                        employeeData[item.key].faculty
                                          ? classes.textColor
                                          : employeeData &&
                                            employeeData[item.key].breakTitle
                                          ? classes.breakTextColor
                                          : classes.timeText
                                      }
                                      disabled={
                                        employeeData[item.key].breakTitle
                                      }
                                    >
                                      {employeeData[item.key].time}
                                      {employeeData &&
                                      employeeData[item.key].faculty ? (
                                        <>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {employeeData[item.key].subject}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            {employeeData[item.key].faculty}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.tablecellTextColor
                                            }
                                          >
                                            ({employeeData[item.key].classroom})
                                          </Typography>
                                        </>
                                      ) : (
                                        <div
                                          className={classes.tablecellTextColor}
                                        >
                                          {employeeData[item.key].breakTitle}
                                        </div>
                                      )}
                                    </button>
                                  ) : (
                                    "N/A"
                                  )}
                                  {employeeData &&
                                  employeeData[item.key].time ? (
                                    <IconCancel
                                      className={classes.iconCancel}
                                      onClick={() =>
                                        handleRemoveDialogOpen(
                                          employeeData[item.key]._id
                                        )
                                      }
                                    />
                                  ) : null}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
      )}
    </div>
  );
};
export default AssignTimeslot;
