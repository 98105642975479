import * as React from "react";
import { FC } from "react";
import {
  Typography,
  useMediaQuery,
  Theme,
  Card,
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { leaveTypeDonatChartStyle } from "../../../helperStyle";
import leaveApplicationImg from "../../icons/leave_application.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

interface Props {
  value?: number;
  data?: [];
  length?: number;
  isLoading?: boolean;
  data2?: [];
}

const useStyles = makeStyles(leaveTypeDonatChartStyle);

const LeaveTypeDonatChart: FC<Props> = (data) => {
  const [leaveData, setLeaveData] = React.useState([]);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const classes = useStyles();
  // const dataObj = data.data;
  const dataObj = data.data2;
  // || [];
  const loading = data.isLoading;

  // let dataArr: any = [];
  // React.useEffect(() => {
  //   // dataArr = [{ name: "No Record", value: 0 }]
  //   if (dataObj && dataObj.length > 0) {
  //     dataObj.forEach((item: any) => {
  //

  //       dataArr.push({
  //         name: item.leaveName,
  //         value: item.code,
  //       });
  //     });
  //   }

  // setLeaveData(dataArr);
  // }, [dataObj]);

  let dataArr: any = [];
  React.useEffect(() => {
    // dataArr = [{ name: "No Record", value: 0 }]
    if (dataObj && dataObj.length > 0) {
      dataObj.forEach((item: any) => {
        dataArr.push({
          name: item.name,
          value: item.entitledLeave,
          balance: item.usedLeave,
          image: item.image,
        });
      });
    }

    setLeaveData(dataArr);
  }, [dataObj]);

  // const keys = Object.keys(dataObj);
  // const values = Object.values(dataObj);

  // keys.forEach((key, index) => {
  //     const newLabel = !!objLabel[key] ? objLabel[key] : key;
  //     dataArr.push({
  //         name: newLabel,
  //         value: values[index],
  //     });
  // });
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <Card
      className={
        isXSmall
          ? classes.mobileCard
          : isSmall
          ? classes.smallCard
          : classes.card
      }
    >
      <Typography component="h6" className={classes.title}>
        {/* {translate("pos.dashboard.head_tree_graph")} */}
        Leave Type Distribution
      </Typography>
      <br />
      {loading ? (
        <span
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: isXSmall ? 50 : 0,
          }}
        >
          <CircularProgress />
        </span>
      ) : leaveData && leaveData.length > 0 ? (
        <Grid
          container
          wrap="nowrap"
          className={isXSmall ? classes.smallContainer : classes.container}
        >
          {(loading ? Array.from(new Array(3)) : leaveData).map(
            (item: any, index: any) => (
              <Box key={index} width={125} marginRight={1} my={5} marginTop={2}>
                {item ? (
                  <Box
                    style={{
                      border: "1px solid #6c757d9e",
                      alignItems: "center",
                      padding: "6px",
                      borderRadius: "7px",
                      height: "100px",
                      width: "100px",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      style={{ width: 100, height: 65 }}
                      alt={item.title}
                      src={
                        item && item.image
                          ? `${uploadApiUrl}${item.image}`
                          : leaveApplicationImg
                      }
                      // source={{ uri: uploadUrl + leave.image }}
                    />
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "blue",
                        paddingTop: "3px",
                      }}
                    >
                      {item.balance + "/" + item.value}
                    </Typography>
                  </Box>
                ) : (
                  <Skeleton variant="rect" width={100} height={65} />
                )}
                {item ? (
                  <Box pr={2}>
                    <Typography
                      gutterBottom
                      variant="body2"
                      style={{ textAlign: "center" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                ) : (
                  <Box pt={0.5}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
              </Box>
              // </Box>
            )
          )}
        </Grid>
      ) : (
        // <ResponsiveContainer width={300} height={250}>
        //   <PieChart
        //     style={{ left: isXSmall ? 20 : 40 }}
        //     width={150}
        //     height={30}
        //   >
        //     <Pie
        //       innerRadius={35}
        //       outerRadius={45}
        //       dataKey="value"
        //       isAnimationActive={false}
        //       data={leaveData}
        //       fill="#8884d8"
        //       label
        //     >
        //       {leaveData.map((entry: any, index: any) => (
        //         <Cell
        //           key={`cell-${index}`}
        //           fill={COLORS[index % COLORS.length]}
        //         />
        //       ))}
        //     </Pie>
        //     <Legend />
        //     <Tooltip />
        //   </PieChart>
        // </ResponsiveContainer>
        <h4
          style={{
            height: "150px",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Record Found
        </h4>
      )}
    </Card>
  );
};

export default LeaveTypeDonatChart;
