import * as React from "react";
import { FC, memo, useState, useEffect } from "react";

import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { FieldProps, Visitor, Role } from "../types";
import useDeepCompareEffect from "use-deep-compare-effect";
const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    maxWidth: "240px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  childCompanys?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  validate?: any;
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  labelName?: any;
  textWrap?: boolean;
  defaultValue?: any;
  disabled?: any;
}

const ChildCompanySelectInput: FC<Props> = ({
  helperText,
  filter,
  validate,
  dataManage,
  emptyValue,
  labelName,
  textWrap,
  defaultValue,
  disabled,
}) => {
  const form = useForm();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();

  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("companyMasters/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          childCompanys: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { childCompanys } = state;

  if (emptyValue && emptyValue.emptyValue) {
    form.change("childCompanyId", undefined);
    form.change("hiddenVal", "valueChange");
  }

  const check = (value: any) => {
    childCompanys?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "childCompany");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Company (Subsidiary)"}
      className={textWrap ? classes.root : ""}
      source="childCompanyId"
      validate={validate}
      choices={childCompanys}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
      initialValue={defaultValue}
      disabled={disabled ? disabled : false}
    />
  );
};

ChildCompanySelectInput.defaultProps = {
  source: "childCompanyId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ChildCompanySelectInput);
