import * as React from "react";
import { FC } from "react";
import { SimpleForm, TextInput, email, useTranslate, required, BooleanInput, maxLength, FormDataConsumer, PasswordInput } from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import DesignationSelectInput from '../components/DesignationSelectInput';
import RoleSelectInput from '../components/RoleSelectInput';
import ClientLocationSelectInput from '../components/ClientLocationSelectInput';
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { departmentstyles } from '../helperStyle';
import { number, minLength } from "../TurnoutAdmin";

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};


const useStyles = makeStyles(departmentstyles);
const CommonForm = (props: any) => {
    const isXsmall = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("xs")
    );
    const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
    const classes = useStyles();
    return (
        isSmall ? <SimpleForm {...props}>
            {/* <SectionTitle label="resources.departments.page.edit" /> */}
            <CompanyReferenceInput></CompanyReferenceInput>
            <TextInput
                autoFocus
                resource="zones"
                source="name"
                formClassName={classes.name}
                validate={nameValidate}
            />
            <TextInput
                label="Code"
                source="employeeCode"
                formClassName={classes.code}
                style={{ width: '220px' }}
                validate={codeValidate}
            />
            <TextInput
                type="email"
                source="email"
                formClassName={classes.code}
                validate={validateEmail}
            />
            <SectionTitle label="" />

            <TextInput
                source="phone"
                formClassName={classes.name}
                validate={phoneValidate}
            />
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <ChildCompanySelectInput validate={requiredValidate} filter={{ isActive: true }}></ChildCompanySelectInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <ClientMasterSelectInput filter={{ isActive: true, childCompanyId: formData.childCompanyId }}></ClientMasterSelectInput>
                )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                    <ClientLocationSelectInput filter={{ isActive: true, clientId: formData.clientId }}></ClientLocationSelectInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={classes.code}>
                {({ formData }: { formData: any }) => (
                    <RoleSelectInput
                        filter={{ company: formData.company, isActive: true }}
                    ></RoleSelectInput>

                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <DesignationSelectInput validate={requiredValidate} filter={{ isActive: true }}></DesignationSelectInput>
                )}
            </FormDataConsumer>
            <SectionTitle label="" />
            {props && Object.keys(props.record).length === 0 ? <PasswordInput source="password" validate={[required()]} formClassName={classes.name} /> : null}
            {props && Object.keys(props.record).length === 0 ? <PasswordInput source="confirm_password" formClassName={isXsmall ? null : classes.code} /> : null}
            <BooleanInput
                label="label.status"
                source="isActive"
                defaultValue={true}
            />
        </SimpleForm>
            :
            <SimpleForm {...props}>
                {/* <SectionTitle label="resources.zones.page.edit" /> */}
                <CompanyReferenceInput></CompanyReferenceInput>
                <TextInput
                    autoFocus
                    source="name"
                    formClassName={classes.name}
                    validate={nameValidate}
                />
                <TextInput
                    label="Code"
                    source="employeeCode"
                    formClassName={classes.code}
                    style={{ width: '220px' }}
                    validate={codeValidate}
                />
                <TextInput
                    type="email"
                    source="email"
                    formClassName={classes.code}
                    validate={validateEmail}
                />
                <SectionTitle label="" />
                <TextInput
                    source="phone"
                    formClassName={classes.name}
                    validate={phoneValidate}
                />
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <ChildCompanySelectInput validate={requiredValidate} filter={{ isActive: true }}></ChildCompanySelectInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <ClientMasterSelectInput filter={{ isActive: true, childCompanyId: formData.childCompanyId }}></ClientMasterSelectInput>
                    )}
                </FormDataConsumer>
                <SectionTitle label="" />
                <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                    {({ formData }: { formData: any }) => (
                        <ClientLocationSelectInput filter={{ isActive: true, clientId: formData.clientId }}></ClientLocationSelectInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={classes.code}>
                    {({ formData }: { formData: any }) => (
                        <RoleSelectInput
                            filter={{ company: formData.company, isActive: true }}
                        ></RoleSelectInput>

                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <DesignationSelectInput validate={requiredValidate} filter={{ isActive: true }}></DesignationSelectInput>
                    )}
                </FormDataConsumer>
                <SectionTitle label="" />
                {props && Object.keys(props.record).length === 0 ? <PasswordInput source="password" validate={[required()]} formClassName={classes.name} /> : null}
                {props && Object.keys(props.record).length === 0 ? <PasswordInput source="confirm_password" formClassName={isXsmall ? null : classes.code} /> : null}

                <BooleanInput
                    label="label.status"
                    source="isActive"
                    defaultValue={true}
                />
            </SimpleForm>

    );
};
const codeValidate = [required(), maxLength(20),  regex(/.*\S.*/, "Only whitespaces are not allowed"),];
const nameValidate = [required(), maxLength(50),  regex(/.*\S.*/, "Only whitespaces are not allowed"),];
const phoneValidate = [required(), maxLength(10), number(), minLength(10)];
const validateEmail = [required(), email()];
// const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
