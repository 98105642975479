import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, useDataProvider, useNotify } from "react-admin";
import { useSelector } from "react-redux";
import helperFunc from "./../../../helperFuncs";
import { employeeBehaviourStyle } from "../../../helperStyle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography, useMediaQuery, Theme, Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { AppState } from "../../../types";
import useDeepCompareEffect from "use-deep-compare-effect";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { DatePicker } from "@material-ui/pickers";
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateInput } from "react-admin-date-inputs2";
// import DatePicker from 'react-date-picker';
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "material-ui-pickers";
import { toastError } from "../../../components/toast";

interface Props {
  data?: [];
}
interface State {
  employees?: [];
  graphData?: [];
}

const useStyles = makeStyles(employeeBehaviourStyle);

const EmployeeBehaviour: FC<Props> = () => {
  const user = helperFunc.getUserData();
  // const notify = useNotify();

  const [value, setValue] = React.useState(null);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const translate = useTranslate();
  const [state, setState] = React.useState<State>({});
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(false);
  const [selectedDate, handleDateChange] =
    React.useState<MaterialUiPickersDate | null>(new Date());
  let open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("employees/search", { isActive: true })
      .then((response: any) => {
        response &&
          response.data.forEach((item: any) => {
            item.name = `${item.name} (${item.employeeCode})`;
          });
        setState((state) => ({
          ...state,
          employees: response && response.data,
          // newDeparments: response && response.data
        }));
      });
  }, [dataProvider]);

  useDeepCompareEffect(() => {
    setLoading(true);
    dataProvider
      .callCustom("dashboard/behaviour_graph", {
        fromDate: helperFunc.startOfMonthAccessment(selectedDate, "MM-DD-YYYY"),
        toDate: helperFunc.lastDayOfMonthAccessment(selectedDate),
        employeeId: value,
      })
      .then((response: any) => {
        setLoading(false);
        setState((state) => ({
          ...state,
          graphData:
            response && response.data.employeeAttendenceData
              ? response.data.employeeAttendenceData.map(
                  (element: any, i: any) => {
                    if (response.data.employeeAttendenceData.length - 1) {
                      element.yTime = 24;
                    }
                    element.time = helperFunc.hourAndMin(element.time);
                    element.Time = element.time;

                    return element;
                  }
                )
              : [],
        }));
      })
      .catch((err: any) => {
        setLoading(false);
        err.response.data.errors.forEach((error: any) => {
          // notify(error.msg, "warning");
          toastError(err.message);
          if (err.response.status === 401) {
            window.location.replace("/");
          }
        });
      });
  }, [dataProvider, value, selectedDate]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let res = payload[0].value.split(".");
      return (
        <div style={{ background: "#ffffff", border: 1, borderColor: "grey" }}>
          <p className="label">{`Date - ${label}`}</p>
          <p className="label">{`In Time - ${res[0]}:${res[1]}`}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const { employees, graphData } = state;

  // const handleDateChange = (date: Date | null) => {
  //   setSelectedDate(date);
  // };

  // const handleDateChnage = (e: any) => {
  //   setValue(e.target.value);
  // };

  return (
    <Card
      className={
        isXSmall
          ? classes.mobileRoot
          : isSmall
          ? classes.smallRoot
          : classes.root
      }
    >
      <div className="col-12" style={{ display: "flex", marginTop: "10px" }}>
        <Typography component="h6" className={classes.title}>
          {`Monthly Attendance Behaviour`}
        </Typography>

        {/* <DatePicker
          variant="inline"
          openTo="year"
          views={["year", "month"]}
          label="Year and Month"
          helperText="Start from year selection"
          // value={selectedDate}
          selected={selectedDate}
          onChange={(date: Date) => setSelectedDate(date!)}
        /> */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around" style={{ marginTop: "9px" }}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={["year", "month"]}
              label="Year and Month"
              value={selectedDate}
              onChange={handleDateChange}
              className={
                isXSmall ? classes.selectDatePosSmall : classes.selectDatePos
              }
              autoOk={true}
              //   InputProps={{
              //     disableCloseOnSelect: true
              //   }}
            />
          </Grid>
        </MuiPickersUtilsProvider>

        {/* {user.designation === "HR" ? ( */}
        <Autocomplete
          // value={value}
          onChange={(event: any, newValue: any) => {
            setValue(newValue && newValue._id ? newValue._id : "");
            setLoading(true);
          }}
          // inputValue={inputValue}
          // onInputChange={(event, newInputValue) => {

          //     setInputValue(newInputValue);
          // }}
          getOptionLabel={(option: any) => (option ? option.name : "")}
          id="controllable-states-demo"
          options={employees ? employees : []}
          style={{ width: 600 }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Please Select Employee"
              label="Employee"
              variant="outlined"
            />
          )}
        />
        {/* ) : null} */}
      </div>
      {loading ? (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            marginTop: 175,
            height: "15em",
          }}
        >
          <CircularProgress />{" "}
        </span>
      ) : graphData && graphData.length > 0 ? (
        <ResponsiveContainer
          width={isXSmall ? 1000 : open ? 1000 : 1200}
          height={400}
        >
          <LineChart
            data={graphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 10,
            }}
          >
            <Legend
              wrapperStyle={{ fontSize: "30px", fontWeight: "bold" }}
              verticalAlign="top"
              height={56}
            />
            <CartesianGrid />
            <XAxis
              dataKey="dateStr"
              label={{ value: "Date", position: "insideBottom", dy: 15 }}
            />
            <YAxis
              label={{ value: "Hours", angle: -90, position: "insideLeft" }}
              dataKey="yTime"
            />
            <Tooltip content={<CustomTooltip />} />
            <Line dataKey="Time" />
          </LineChart>
        </ResponsiveContainer>
      ) : user.designation === "HR" && graphData && graphData.length > 0 ? (
        <h4
          style={{
            width: "100%",
            height: "15em",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          Please Select Employee
        </h4>
      ) : (
        <h4
          style={{
            width: "100%",
            height: "15em",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          No Record Found
        </h4>
      )}
    </Card>
  );
};

export default EmployeeBehaviour;
