import * as React from "react";
import { FC, Fragment } from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  // DateField,
  Pagination,
  // ReferenceInput,
  // SearchInput,
  // SelectInput,
  // AutocompleteInput,
  // DateInput,
  downloadCSV,
  useRefresh,
  FormDataConsumer,
  required,
  TopToolbar,
  ExportButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FunctionField,
  DateInput,
  TextInput,
  regex,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { ListComponentProps } from "../types";
import jsonExport from "jsonexport/dist";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import moment from "moment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import MultiSelectEmployeeInput from "../components/MultiSelectEmployeeInput";

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  React.useEffect(() => {
    data.setFilters();
  }, [data]);
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters(
      {
        fromDate: helperFunc.startOfMonth(1),
        toDate: helperFunc.addDays(1),
      },
      []
    );
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {/* <Box mr={2}></Box> */}
                    {helperFunc.getUserData().type === "super" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <CompanyReferenceInput
                            helperText={false}
                          ></CompanyReferenceInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <TextInput
                      source="code"
                      helperText={false}
                      resettable={true}
                      validate={[
                        regex(/.*\S.*/, "Only whitespaces are not allowed"),
                      ]}
                      format={helperFunc.codeFormatter}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type == "super" ||
                    helperFunc.getUserData().type == "company" ||
                    helperFunc.getUserData().designation == "HR" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <MultiSelectEmployeeInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          />
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          defaultValue={helperFunc.startOfMonth(1)}
                          validate={[required()]}
                          label="label.fromDate"
                          source="fromDate"
                          helperText={false}
                          alwaysOn
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          defaultValue={helperFunc.addDays(0)}
                          validate={[required()]}
                          label="label.toDate"
                          source="toDate"
                          helperText={false}
                          alwaysOn
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    filters,
    showFilter,
    displayedFilters,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    // marginTop: "1em",
  },
  name: { display: "inline-block" },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
});

// const Aside = () => (
//   <div style={{ width: 200, margin: "1em" }}>
//     <Typography variant="h6">Post details</Typography>
//     <Typography variant="body2">
//       Posts will only be published one an editor approves them
//     </Typography>
//   </div>
// );

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      company,
      dateStr,
      department,
      departmentData,
      designation,
      designationData,
      early,
      employee,
      employeeData,
      employeeType,
      employeeTypeData,
      inTime,
      isAbsent,
      late,
      outTime,
      role,
      shift,
      shiftData,
      stayTime,
      totalTime,
      _id,
      id,
      geoPoints,
      ...dataToExport
    } = record; // omit fields

    dataToExport.Name = employeeData && employeeData.name; // add a field
    // dataToExport.Code = employeeData && employeeData.employeeCode; // add a field
    dataToExport.Date = helperFunc.commonDateFormat(dateStr);
    dataToExport.In = inTime;
    dataToExport.Out = outTime;
    dataToExport.InLocation =
      geoPoints && geoPoints.in && geoPoints.in.location;
    dataToExport.OutLocation =
      geoPoints && geoPoints.out && geoPoints.out.location;
    dataToExport.StayTime = stayTime;
    dataToExport.TotalTime = totalTime;
    dataToExport.Absent = isAbsent ? "Yes" : "No";
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Visitors Report"); // download as 'xxxx.csv` file
    }
  );
};

export const VisitorReportFilter = (props: any) => (
  <Filter {...props}>
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const VisitorReportList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const classes = useStyles();
  const refresh = useRefresh();

  //     refresh();
  //   }, 2 * 60000); // runs every 2 * 60 seconds.

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <div className={classes.root}>
      <Route path="/attendance/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Fragment>
              {isXSmall ? (
                <h2
                  style={{
                    height: 40,
                    fontSize: 30,
                    backgroundColor: "#fff",
                    padding: 10,
                  }}
                >
                  Visitor Report
                </h2>
              ) : null}
              <div
                className={open === true ? "overlay" : ""}
                onClick={() => handleDrawerClose("fromlist")}
              />
              <div
                style={
                  isXSmall
                    ? {
                        width: window.screen.width,
                        overflowX: "scroll",
                      }
                    : {}
                }
              >
                <List
                  className="list-view2"
                  bulkActionButtons={false}
                  title="resources.visitors.fields.report"
                  exporter={exporter}
                  actions={
                    <ListActions
                      {...props}
                      handleDrawerOpen={handleDrawerOpen}
                    />
                  }
                  {...props}
                  filters={
                    <VisitorReportFilter
                      {...props}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                  }
                  perPage={20}
                  filterDefaultValues={{
                    fromDate: helperFunc.startOfMonth(1),
                    toDate: helperFunc.addDays(0),
                    company: helperFunc.selectedCompany(),
                  }}
                  pagination={
                    <Pagination
                      labelRowsPerPage="Records per page"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                    />
                  }
                  sort={{ field: "createdAt", order: "ASC" }}
                >
                  <Datagrid>
                    <TextField
                      source="employeeData.name"
                      label="Visitor Name"
                    />
                    {/* <TextField
                    source="employeeData.employeeCode"
                    label="Visitor Code"
                  /> */}

                    <FunctionField
                      label="Date"
                      sortBy="dateStr"
                      // sortByOrder="DESC"
                      render={(record: any) =>
                        `${
                          record.dateStr
                            ? helperFunc.commonDateFormat(record.dateStr)
                            : "N/A"
                        }  `
                      }
                    />
                    <FunctionField
                      label="In"
                      sortBy="inTime"
                      // sortByOrder="DESC"
                      render={(record: any) =>
                        record.inTime
                          ? `${moment(record.inTime).format("HH:mm:ss")}  `
                          : ""
                      }
                    />
                    <FunctionField
                      label="Out"
                      sortBy="outTime"
                      // sortByOrder="DESC"
                      render={(record: any) =>
                        record.outTime
                          ? `${moment(record.outTime).format("HH:mm:ss")}  `
                          : ""
                      }
                    />
                    {/* <TextField source="inTime" label="In" sortable={false} /> */}
                    {/* <TextField source="outTime" label="Out" sortable={false} /> */}
                    <TextField
                      source="geoPoints.in.location"
                      label="In Location"
                      sortable={false}
                    />
                    <TextField
                      source="geoPoints.out.location"
                      label="Out Location"
                      sortable={false}
                    />

                    <TextField
                      source="stayTime"
                      label="Spent Hours"
                      sortable={false}
                    />
                    <TextField
                      source="totalTime"
                      label="Total Hours"
                      sortable={false}
                    />
                    {/* <TextField source="status" /> */}
                  </Datagrid>
                </List>
              </div>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default VisitorReportList;
