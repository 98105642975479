import * as React from "react";
import { Create } from "react-admin";

import CommonForm from "../attendance/commonForm";

const AttendanceCreate = (props: any) => {

  return (
    <Create {...props}>
      <CommonForm redirect={'/attendance'} {...props} />
    </Create>
  );
};

export default AttendanceCreate;


