import LeaveEdit from "../leave/LeaveEdit";
import { LeaveForm } from "../leave/leaveCommon";
import { LeaveIcon } from "../svgIcon";
import TeamLeaveList from "./TeamLeaveList";

export default {
  list: TeamLeaveList,
  icon: LeaveIcon,
  edit: LeaveForm,
};
