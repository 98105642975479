import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "../leaveTabPanel/commonForm";
import { PostEditToolbar } from '../components/PostEditToolbar';


const TeamLeaveEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Team Leave"} {...props}>
      <CommonForm toolbar={<PostEditToolbar deleteButton={true} />}  {...props} />
    </Edit>
  );
};

export default TeamLeaveEdit;
