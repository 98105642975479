import React, { Fragment, useState } from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  downloadCSV,
  TopToolbar,
  FunctionField,
  Button as ReactButton,
  ExportButton,
  DateTimeInput,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import jsonExport from "jsonexport/dist";
import helperFunc from "../helperFuncs";
import { approvalStatus, requestTypeArr } from "../helperChoice";
import ReactTooltip from "react-tooltip";
import MobileGrid from "../components/MobileGrid";
import BulkDeleteButton from "../components/BulkDeleteButton";

import { filterStyle, listActionsStyle } from "../helperStyle";
import { trimValue } from "../helperValidate";
import { SmListHeader } from "../components/SmFormHeader";
import RequestLinkField from "./RequestLinkField";

const approvalStatusLeave: any = {
  reject: "Rejected",
  approve: "Approved",
  pending: "Pending",
  cancel: "Cancel",
};
const statusType: any = {
  early_check_out: "Early Check Out",
  late_check_in: "Late Check In",
  missed_checkin: "Missed Check In",
  missed_checkout: "Missed Check Out",
  in_between_check_out: "In Between Check Out",
  other: "Other",
  over_time: "Over Time",
  procurement: "Procurement",
  attendancechangerequest: "Attendance Change Request",
};

const PostFilterForm = (data: any) => {
  const useStyles = makeStyles(filterStyle);
  const theme = useTheme();
  const classes = useStyles();
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.fromTime > allValues.toTime) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.fromTime > allValues.toTime) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };

  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div className={classes.divForm}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      // allowEmpty={false}
                      source="requestStatus"
                      label="label.approvalStatus"
                      choices={approvalStatus}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      source="requestType"
                      label="Request Type"
                      choices={requestTypeArr}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="fromTime"
                      validate={[checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[checkToDate]}
                      source="toTime"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

// const exporter = (records: any) => {
//   const recordsForExport = records.map((record: any) => {
//     const { employee, reason, requestStatus } = record; // omit fields
//     return {
//       Name: employee.name,
//       Reason: reason,
//       Status: approvalStatus[requestStatus],
//       Date: helperFunc.commonDateFormat(record["name"], "HH:mm:ss"),
//     };
//   });
//   jsonExport(recordsForExport, (err: any, csv: any) => {
//     downloadCSV(csv, "TeamRequestsCsv"); // download as 'xxxx.csv` file
//   });
// };

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      employee,
      isApprove,
      requestType,
      requestStatus,
      fromTime,
      toTime,
      reason,
    } = record; // omit fields

    return {
      Name: employee.name,
      "Request Type": statusType[requestType],
      "From Date Time": helperFunc.commonDateFormat(fromTime, "HH:mm:ss"),
      "To Date Time": helperFunc.commonDateFormat(toTime, "HH:mm:ss"),
      Reason: reason,
      Status: approvalStatusLeave[requestStatus],
    };
  });
  jsonExport(recordsForExport, (err: any, csv: any) => {
    downloadCSV(csv, "Team Requests Report"); // download as 'xxxx.csv` file
  });
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    handleDrawerOpen,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(listActionsStyle);
  const classes = useStyles();

  return (
    <TopToolbar className={isXsmall ? classes.flexrow : className}>
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        className={classes.filterButton}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon className={classes.filterIcon} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const StreamFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeName" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const useStyles = makeStyles((theme) => ({
  userImg: { padding: 0, height: 24, width: 24, borderRadius: "30px" },
  textMerge: {
    width: "70px !important",
    minWidth: "70px",
    height: "auto",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  toolTipWidth: {
    width: "230px",
    fontSize: "50px",
    padding: 0,
  },
}));

const RequestList = (props: any) => {
  const [open, setOpen] = useState(false);
  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const statusType: any = {
    early_check_out: "Early Check Out",
    late_check_in: "Late Check In",
    missed_checkin: "Missed Check In",
    missed_checkout: "Missed Check Out",
    in_between_check_out: "In Between Check Out",
    other: "Other",
    over_time: "Over Time",
    procurement: "Procurement",
    attendancechangerequest: "Attendance Change Request",
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();

  // NOTE Title Change block

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };

  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <SmListHeader {...props} />
      <div className="team-request-table">
        <List
          className="list-view2"
          exporter={exporter}
          {...props}
          filters={
            <StreamFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          }
          sort={{ field: "createdAt", order: "DESC" }}
          perPage={20}
          pagination={
            <Pagination
              labelRowsPerPage="Records per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          }
          bulkActionButtons={<PostBulkActionButtons />}
          actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
          title="resources.team-attendence-request.title"
        >
          {isXsmall ? (
            <MobileGrid />
          ) : (
            <Datagrid optimized>
              <FunctionField
                label="label.image"
                render={(record: any) => (
                  <img
                    src={
                      record &&
                      uploadApiUrl &&
                      uploadApiUrl + record?.employee?.profile_image
                        ? uploadApiUrl &&
                          uploadApiUrl + record?.employee?.profile_image
                          ? uploadApiUrl + record?.employee?.profile_image
                          : uploadApiUrl + record?.auth?.image
                        : "assets/img/emptyUser.png"
                    }
                    alt="My Team"
                    className={classes.userImg}
                    onError={(e: any) => {
                      e.target.src = "assets/img/emptyUser.png"; // some replacement image
                    }}
                  />
                )}
              />
              <TextField
                source="employee.name"
                label="Name"
                className={classes.textMerge}
              />
              <FunctionField
                label="Request Type"
                sortBy="requestType"
                render={(record: any) =>
                  `${
                    record["requestType"]
                      ? statusType[record?.requestType]
                      : "N/A"
                  }  `
                }
              />
              <FunctionField
                label="From Date & Time"
                sortBy="fromTime"
                render={(record: any) =>
                  `${
                    record?.attendanceChangeRequestTime
                      ? helperFunc.commonDateFormat(
                          record.attendanceChangeRequestTime,
                          "HH:mm:ss"
                        )
                      : record.fromTime
                      ? helperFunc.commonDateFormat(record.fromTime, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />

              <FunctionField
                label="To Date & Time"
                sortBy="toTime"
                render={(record: any) =>
                  `${
                    record?.toTime
                      ? helperFunc.commonDateFormat(record.toTime, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />

              <FunctionField
                label="label.reasons"
                sortBy="reason"
                render={(record: any) => {
                  const d = (
                    <Fragment>
                      <div
                        data-tip={record.reason}
                        data-for="reason"
                        className={
                          record && record.reason ? classes.textMerge : ""
                        }
                      >
                        {record && record.reason ? record.reason : "N/A"}
                      </div>
                      {toolTip("reason")}
                    </Fragment>
                  );
                  return d;
                }}
              />

              <RequestLinkField />
            </Datagrid>
          )}
        </List>
      </div>
    </div>
  );
};

export default RequestList;
