import * as React from "react";
// import { useState} from "react";
import { withTypes } from "react-final-form";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
// import {useDataProvider} from "react-admin";
import { lightTheme } from "./themes";
import { createMuiTheme, makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
export const styles: Styles<Theme, any> = {
  card: {
    fontFamily: "Roboto , Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    display: "flex",
    width: "100%",
    minHeight: "450px",
    textAlign: "justify",
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
    flexDirection: "column",
    paddingTop: "10px",
  },
  title: {
    padding: "10px",
    width: "100%",
    fontSize: "1.5rem",
  },
  flex: { display: "flex", width: "100%" },
  flexCol: {
    display: "flex",
  },

  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { width: "100%", padding: "100px" },
  rightCol: { width: "60%", padding: "10px", textAlign: "center" },
  flexTop: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBottom: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
  },
  singleCol: {
    marginTop: "4em",
    marginBottom: "2em",
    marginLeft: "1em",
    marginRight: "1em",
  },
  ml10: { marginLeft: "10px" },
};

// const renderInput = ({
//     meta: { touched, error } = { touched: false, error: undefined },
//     input: { ...inputProps },
//     ...props
// }) => (
//         <TextField
//             error={!!(touched && error)}
//             helperText={touched && error}
//             {...inputProps}
//             {...props}
//             fullWidth
//         />
//     );

interface FormValues {
  username?: string;
}

const { Form } = withTypes<FormValues>();
interface State {
  data?: any;
}
const useStyles = makeStyles(styles);
const PrivacyPolicy = () => {
  const classes = useStyles();
  // const dataProvider = useDataProvider();
  // const [state, setState] = useState<State>({});
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  // useEffect(() => {
  //     dataProvider.callCustomLocal("http://104.237.4.152:9128/api/cms/public/about-us")
  //         .then((response: any) => {
  //             setState((state) => ({
  //                 ...state,
  //                 data: response && response.data && response.data.item,
  //             }));
  //         });
  // }, []);

  // const { data } = state;
  {
    return isXSmall ? (
      <div className={classes.card}>
        <Box component="div" className={classes.flexTop}>
          <Box component="div" className={classes.flexCol}>
            <Typography component="h4" className={classes.title}>
              Turnout Privacy Policy
            </Typography>
          </Box>
        </Box>
        <Box component="div" className={classes.singleCol}>
          <Box component="div" textAlign="left">
            <div>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Rydot Infotech Private Limited respects your privacy and is
                  committed to helping you understand what data we collect, why

                  we collect it, and what we do with it. Please spend some time
                  carefully reading the below-mentioned Privacy Policies.

                </span>
              </p>
              <p>&nbsp;</p>

              <p>
                <strong>1. Services covered by this Policy</strong>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  Rydot Infotech Private Limited currently operates the{" "}
                  <strong>Turnout</strong>

                  mobile application (the “App”) as well as the website(the
                  "Website" and, together with the App, the “Services”). This
                  Privacy Policy covers your use of the Services.
                </span>
              </p>

              <p>
                <strong>2. Information We Collect From You</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.1. We may collect personal information (e.g., name, email
                  address, photos) from you through the Services in the ways
                  discussed in this Policy. We may also receive your personal

                  information from partners/clients. You might not be able to
                  access some portions of the Services if you choose not to
                  provide personal information when asked.

                </span>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.2. We may collect the physical location of your device to
                  record and publish information on your position to the
                  partner. We may collect this information by, for example,

                  using satellite, cell phone towers, or WiFi signals. The
                  ability to utilize the Services may be affected by whether you
                  choose to allow or refuse such collection and use of your
                  device's location in particular circumstances.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  2.3. The purpose of access control with images and photographs
                  is to grant entrance to highly confidential office space only
                  to the authorized people or employees of the company. With the
                  help of the Turnout application, its users or employees of our
                  partner can get access to these locations. Due to this, we
                  share the user’s submitted photograph or image to the
                  organization/companies with whom you are a guest, employee, or
                  contractor, together with additional information (such as your
                  user name, email address, and location).

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  2.4. The following are just a few examples of how we might use
                  or divulge the personal data we gather from you if we deem it
                  necessary or permissible to do so:
                  <ul style={{ marginLeft: "10px" }}>
                    <li>• To protect people or property,</li>
                    <li>• To protect our services, rights, or property, </li>
                    <li>• To comply with legal requirements, </li>
                    <li>
                      • Respond to court orders, requests from the authorities,
                      and orders from other public and governmental agencies.
                    </li>
                  </ul>

                </span>
              </p>

              <p>
                <strong>3. Registration</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  3.1. To use the Services you need to register by providing us
                  with your email address. We may also collect information
                  including phone number, name, date of birth, blood group,
                  marital status, address, and gender.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  3.2. In order to make information available on the App and the
                  Website, we might ask you for information like a profile
                  photo, name and last name, user name, and email address. You
                  may not be able to access all the content and features without
                  registering.

                </span>
              </p>

              <p>
                <strong>4. Emails and Push Notifications</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  We may contact you and/or send you electronic communications
                  using email. If you do not want to receive emails from us, you
                  can always opt-out by following the unsubscribe instructions
                  provided in such emails. Please note that even if you opt-out
                  from receiving communications, you may still receive
                  administrative communications, such as push notifications
                  about your account activities (e.g. account confirmations,
                  password changes, etc.), and any other important account
                  amendment details. We may also send you to push notifications
                  regarding the same. You can disable push notifications in your
                  mobile device’s settings.
                </span>
              </p>

              <p>
                <strong>5. Non-personal information we collect from you</strong>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  5.1. A lot of the time, when you use the Services, we will
                  routinely get some non-personal information. We gather this
                  data to make sure the Services work properly. We might also
                  collect information about your browser or device, pixel tags,
                  information through cookies, app usage data, and other
                  technologies, and aggregated information. This information may
                  include: App usage information, including the date and time
                  your device's app connects to our servers and what documents
                  and information have been transferred to the app based on your
                  device's unique identifier. When you download and use the App,
                  we may also get information that is automatically collected by
                  your browser, device, or the App itself. We may collect
                  computer type (Windows or Macintosh), Media Access Control
                  (MAC) address, device manufacturer and model, Internet browser
                  version and type, screen resolution, the domain name of your
                  Internet service provider, the operating system you are using,
                  language, and the name and version of the Services (such as
                  the App) you are using, and your "click path" through the
                  Sites or the App; IP address, which we may use for purposes
                  such as calculating usage levels, administering the Services,
                  and diagnosing server problems. Your IP address may allow us
                  to determine your general location.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  5.2. We might utilize technology, such as cookies, to
                  accomplish this. Unless you give us or have given us personal
                  information in the past, your access to our Services and the
                  information given through all these technologies will be
                  anonymized.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  5.3. When you voluntarily give it, we may also gather
                  non-personal information, such as your preferred means of
                  contact.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  5.4. We may combine personal data that, when combined, does
                  not personally identify you or any other service user. For
                  instance, we may combine personal data to determine the
                  proportion of our users who belong to a specific zip code.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  5.5. Except as compelled by the applicable law to do
                  differently, we may use and disclose non-personal information
                  for any purpose. In certain circumstances, we might merge this
                  data with personal data. Your account information might be
                  connected to the partner's server, for instance, if you have
                  an account with our Services. If we combine the data, we will
                  treat it as personal data as long as it is combined.

                </span>
              </p>

              <p>
                <strong>
                  6. Information on cookies and related technology
                </strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  We may use cookies to control user sessions, save preferences,
                  track data, and manage language settings. Whether or not you
                  register with us, cookies may still be utilized. Small text
                  files known as "cookies" are delivered by a web server to your
                  hard drive and then saved on your computer. A cookie can
                  gather data such as the time and date of your visit, your
                  browsing history, and your preferences. Normally, you can set
                  your browser to refuse cookies. However, refusing to accept
                  cookies may prevent you from using some Website features. For
                  instance, you can have login issues.

                </span>
              </p>

              <p>
                <strong>

                  7. Our promise to protect the personal data we have gathered

                </strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  To protect personal information within our organization, we
                  try to utilize acceptable organizational, technical, and
                  administrative methods. However, no website or Internet
                  transmission is 100% safe. As a result, we can't promise that
                  there won't ever be an intrusion, hack, data loss, or another
                  breach. The App and Services are used at the user's own risk.
                  We kindly request that you take precautions to protect the
                  privacy of your data by memorizing your password or storing it
                  in a secure location.

                </span>
              </p>
              <p>
                <strong>8. Third Parties</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  The privacy, information, or other practices of any third
                  party, including any third party running any website or
                  service to which the Services link, are not covered by this
                  privacy statement, and we are not liable for them.
                </span>
              </p>
              <p>
                <strong>9. Transfer of Personal Data</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  Your data may need to be transferred to and/or stored at a
                  location outside of your country of residence for both
                  processing and storage purposes. You consent to the sending,
                  keeping, and/or processing of your data if you accept this
                  privacy statement.

                </span>
              </p>
              <p>
                <strong>10. Notice of changes to the Policy</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  By alerting registered users via email or the App of the
                  existence of a new Policy and/or by publishing the new Policy
                  on the Services, we retain the right to alter this Policy at
                  any time. Your continued use of our services following the
                  posting of any changes to the Policy will signify your
                  acceptance of and commitment to be bound by those changes. All
                  changes to the Policy will take effect when they are posted.

                </span>
              </p>
            </div>
          </Box>
        </Box>
        {/* <Box component="div" textAlign="center" className={classes.singleCol}>
                    <Box textAlign="center" component="div" className={classes.flexCol}>
                        <Typography
                            component="div"
                            id="footer-copyright"
                            // className={classes.copyright}
                            align="left"
                            dangerouslySetInnerHTML={{ __html: data && data.footer }}
                        >
                        </Typography>
                    </Box>
                </Box> */}
      </div>
    ) : isSmall ? (
      <div className={classes.card}>
        <Box component="div" className={classes.singleCol} textAlign="center">
          <Box component="div" className={classes.flexTop}>
            <Box component="div" className={classes.flexCol}>
              <Typography component="h4" className={classes.title}>
                Turnout Privacy Policy
              </Typography>
            </Box>
          </Box>

          <div>
            <p>
              <span style={{ fontWeight: 400 }}>
                Rydot Infotech Private Limited respects your privacy and is
                committed to helping you understand what data we collect, why we

                collect it, and what we do with it. Please spend some time
                carefully reading the below-mentioned Privacy Policies.

              </span>
            </p>
            <p>&nbsp;</p>

            <p>
              <strong>1. Services covered by this Policy</strong>
            </p>

            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                Rydot Infotech Private Limited currently operates the{" "}
                <strong>Turnout</strong>

                mobile application (the “App”) as well as the website(the
                "Website" and, together with the App, the “Services”). This
                Privacy Policy covers your use of the Services.
              </span>
            </p>

            <p>
              <strong>2. Information We Collect From You</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>
                2.1. We may collect personal information (e.g., name, email
                address, photos) from you through the Services in the ways
                discussed in this Policy. We may also receive your personal

                information from partners/clients. You might not be able to
                access some portions of the Services if you choose not to
                provide personal information when asked.

              </span>
            </p>

            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>
                2.2. We may collect the physical location of your device to
                record and publish information on your position to the partner.
                We may collect this information by, for example, using

                satellite, cell phone towers, or WiFi signals. The ability to
                utilize the Services may be affected by whether you choose to
                allow or refuse such collection and use of your device's
                location in particular circumstances.

              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                2.3. The purpose of access control with images and photographs
                is to grant entrance to highly confidential office space only to

                the authorized people or employees of the company. With the help
                of the Turnout application, its users or employees of our
                partner can get access to these locations. Due to this, we share
                the user’s submitted photograph or image to the
                organization/companies with whom you are a guest, employee, or
                contractor, together with additional information (such as your
                user name, email address, and location).
              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                2.4. The following are just a few examples of how we might use
                or divulge the personal data we gather from you if we deem it
                necessary or permissible to do so:
                <ul style={{ marginLeft: "10px" }}>
                  <li>• To protect people or property,</li>
                  <li>• To protect our services, rights, or property, </li>
                  <li>• To comply with legal requirements, </li>
                  <li>
                    • Respond to court orders, requests from the authorities,
                    and orders from other public and governmental agencies.
                  </li>
                </ul>

              </span>
            </p>

            <p>
              <strong>3. Registration</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>
                3.1. To use the Services you need to register by providing us
                with your email address. We may also collect information
                including phone number, name, date of birth, blood group,
                marital status, address, and gender.
              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                3.2. In order to make information available on the App and the
                Website, we might ask you for information like a profile photo,
                name and last name, user name, and email address. You may not be
                able to access all the content and features without registering.

              </span>
            </p>

            <p>
              <strong>4. Emails and Push Notifications</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>
                We may contact you and/or send you electronic communications
                using email. If you do not want to receive emails from us, you
                can always opt-out by following the unsubscribe instructions
                provided in such emails. Please note that even if you opt-out
                from receiving communications, you may still receive
                administrative communications, such as push notifications about
                your account activities (e.g. account confirmations, password
                changes, etc.), and any other important account amendment
                details. We may also send you to push notifications regarding
                the same. You can disable push notifications in your mobile
                device’s settings.
              </span>
            </p>

            <p>
              <strong>5. Non-personal information we collect from you</strong>
            </p>

            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                5.1. A lot of the time, when you use the Services, we will
                routinely get some non-personal information. We gather this data
                to make sure the Services work properly. We might also collect
                information about your browser or device, pixel tags,
                information through cookies, app usage data, and other
                technologies, and aggregated information. This information may
                include: App usage information, including the date and time your
                device's app connects to our servers and what documents and
                information have been transferred to the app based on your
                device's unique identifier. When you download and use the App,
                we may also get information that is automatically collected by
                your browser, device, or the App itself. We may collect computer
                type (Windows or Macintosh), Media Access Control (MAC) address,
                device manufacturer and model, Internet browser version and
                type, screen resolution, the domain name of your Internet
                service provider, the operating system you are using, language,
                and the name and version of the Services (such as the App) you
                are using, and your "click path" through the Sites or the App;
                IP address, which we may use for purposes such as calculating
                usage levels, administering the Services, and diagnosing server
                problems. Your IP address may allow us to determine your general
                location.

              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                5.2. We might utilize technology, such as cookies, to accomplish
                this. Unless you give us or have given us personal information
                in the past, your access to our Services and the information
                given through all these technologies will be anonymized.

              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                5.3. When you voluntarily give it, we may also gather
                non-personal information, such as your preferred means of
                contact.

              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                5.4. We may combine personal data that, when combined, does not
                personally identify you or any other service user. For instance,
                we may combine personal data to determine the proportion of our
                users who belong to a specific zip code.

              </span>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                5.5. Except as compelled by the applicable law to do
                differently, we may use and disclose non-personal information
                for any purpose. In certain circumstances, we might merge this
                data with personal data. Your account information might be
                connected to the partner's server, for instance, if you have an
                account with our Services. If we combine the data, we will treat
                it as personal data as long as it is combined.

              </span>
            </p>

            <p>
              <strong>6. Information on cookies and related technology</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                We may use cookies to control user sessions, save preferences,
                track data, and manage language settings. Whether or not you
                register with us, cookies may still be utilized. Small text
                files known as "cookies" are delivered by a web server to your
                hard drive and then saved on your computer. A cookie can gather
                data such as the time and date of your visit, your browsing
                history, and your preferences. Normally, you can set your
                browser to refuse cookies. However, refusing to accept cookies
                may prevent you from using some Website features. For instance,
                you can have login issues.

              </span>
            </p>

            <p>
              <strong>

                7. Our promise to protect the personal data we have gathered

              </strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                To protect personal information within our organization, we try
                to utilize acceptable organizational, technical, and
                administrative methods. However, no website or Internet
                transmission is 100% safe. As a result, we can't promise that
                there won't ever be an intrusion, hack, data loss, or another
                breach. The App and Services are used at the user's own risk. We
                kindly request that you take precautions to protect the privacy
                of your data by memorizing your password or storing it in a
                secure location.

              </span>
            </p>
            <p>
              <strong>8. Third Parties</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                The privacy, information, or other practices of any third party,
                including any third party running any website or service to
                which the Services link, are not covered by this privacy
                statement, and we are not liable for them.

              </span>
            </p>
            <p>
              <strong>9. Transfer of Personal Data</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                Your data may need to be transferred to and/or stored at a
                location outside of your country of residence for both
                processing and storage purposes. You consent to the sending,
                keeping, and/or processing of your data if you accept this
                privacy statement.

              </span>
            </p>
            <p>
              <strong>10. Notice of changes to the Policy</strong>
            </p>
            <p style={{ marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>

                By alerting registered users via email or the App of the
                existence of a new Policy and/or by publishing the new Policy on
                the Services, we retain the right to alter this Policy at any
                time. Your continued use of our services following the posting
                of any changes to the Policy will signify your acceptance of and
                commitment to be bound by those changes. All changes to the
                Policy will take effect when they are posted.

              </span>
            </p>
          </div>
        </Box>
      </div>
    ) : (
      <div className={classes.card}>
        <Box component="div" className={classes.flexTop}>
          <Box component="div" className={classes.flexCol}>
            <Typography component="h2" className={classes.title}>
              Turnout Privacy Policy
            </Typography>
          </Box>
        </Box>

        <Box component="div" className={classes.flex}>
          <Box component="div" className={classes.leftCol}>
            <div>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Rydot Infotech Private Limited respects your privacy and is
                  committed to helping you understand what data we collect, why

                  we collect it, and what we do with it. Please spend some time
                  carefully reading the below-mentioned Privacy Policies.
                </span>
              </p>
              <p>&nbsp;</p>

              <p>
                <strong>1. Services covered by this Policy</strong>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>

                  Rydot Infotech Private Limited currently operates the{" "}
                  <strong>Turnout</strong>
                  mobile application (the “App”) as well as the website(the
                  "Website" and, together with the App, the “Services”). This
                  Privacy Policy covers your use of the Services.
                </span>
              </p>

              <p>
                <strong>2. Information We Collect From You</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.1. We may collect personal information (e.g., name, email
                  address, photos) from you through the Services in the ways
                  discussed in this Policy. We may also receive your personal
                  information from partners/clients. You might not be able to
                  access some portions of the Services if you choose not to
                  provide personal information when asked.
                </span>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.2. We may collect the physical location of your device to
                  record and publish information on your position to the
                  partner. We may collect this information by, for example,
                  using satellite, cell phone towers, or WiFi signals. The
                  ability to utilize the Services may be affected by whether you
                  choose to allow or refuse such collection and use of your
                  device's location in particular circumstances.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.3. The purpose of access control with images and photographs
                  is to grant entrance to highly confidential office space only
                  to the authorized people or employees of the company. With the
                  help of the Turnout application, its users or employees of our
                  partner can get access to these locations. Due to this, we
                  share the user’s submitted photograph or image to the
                  organization/companies with whom you are a guest, employee, or
                  contractor, together with additional information (such as your
                  user name, email address, and location).
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  2.4. The following are just a few examples of how we might use
                  or divulge the personal data we gather from you if we deem it
                  necessary or permissible to do so:
                  <ul style={{ marginLeft: "10px" }}>
                    <li>• To protect people or property,</li>
                    <li>• To protect our services, rights, or property, </li>
                    <li>• To comply with legal requirements, </li>
                    <li>
                      • Respond to court orders, requests from the authorities,
                      and orders from other public and governmental agencies.
                    </li>
                  </ul>
                </span>
              </p>

              <p>
                <strong>3. Registration</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  3.1. To use the Services you need to register by providing us
                  with your email address. We may also collect information
                  including phone number, name, date of birth, blood group,
                  marital status, address, and gender.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  3.2. In order to make information available on the App and the
                  Website, we might ask you for information like a profile
                  photo, name and last name, user name, and email address. You
                  may not be able to access all the content and features without
                  registering.
                </span>
              </p>

              <p>
                <strong>4. Emails and Push Notifications</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  We may contact you and/or send you electronic communications
                  using email. If you do not want to receive emails from us, you
                  can always opt-out by following the unsubscribe instructions
                  provided in such emails. Please note that even if you opt-out
                  from receiving communications, you may still receive
                  administrative communications, such as push notifications
                  about your account activities (e.g. account confirmations,
                  password changes, etc.), and any other important account
                  amendment details. We may also send you to push notifications
                  regarding the same. You can disable push notifications in your
                  mobile device’s settings.
                </span>
              </p>

              <p>
                <strong>5. Non-personal information we collect from you</strong>
              </p>

              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  5.1. A lot of the time, when you use the Services, we will
                  routinely get some non-personal information. We gather this
                  data to make sure the Services work properly. We might also
                  collect information about your browser or device, pixel tags,
                  information through cookies, app usage data, and other
                  technologies, and aggregated information. This information may
                  include: App usage information, including the date and time
                  your device's app connects to our servers and what documents
                  and information have been transferred to the app based on your
                  device's unique identifier. When you download and use the App,
                  we may also get information that is automatically collected by
                  your browser, device, or the App itself. We may collect
                  computer type (Windows or Macintosh), Media Access Control
                  (MAC) address, device manufacturer and model, Internet browser
                  version and type, screen resolution, the domain name of your
                  Internet service provider, the operating system you are using,
                  language, and the name and version of the Services (such as
                  the App) you are using, and your "click path" through the
                  Sites or the App; IP address, which we may use for purposes
                  such as calculating usage levels, administering the Services,
                  and diagnosing server problems. Your IP address may allow us
                  to determine your general location.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  5.2. We might utilize technology, such as cookies, to
                  accomplish this. Unless you give us or have given us personal
                  information in the past, your access to our Services and the
                  information given through all these technologies will be
                  anonymized.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  5.3. When you voluntarily give it, we may also gather
                  non-personal information, such as your preferred means of
                  contact.

                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  5.4. We may combine personal data that, when combined, does
                  not personally identify you or any other service user. For
                  instance, we may combine personal data to determine the
                  proportion of our users who belong to a specific zip code.
                </span>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  5.5. Except as compelled by the applicable law to do
                  differently, we may use and disclose non-personal information
                  for any purpose. In certain circumstances, we might merge this
                  data with personal data. Your account information might be
                  connected to the partner's server, for instance, if you have
                  an account with our Services. If we combine the data, we will
                  treat it as personal data as long as it is combined.
                </span>
              </p>

              <p>
                <strong>
                  6. Information on cookies and related technology
                </strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  We may use cookies to control user sessions, save preferences,
                  track data, and manage language settings. Whether or not you
                  register with us, cookies may still be utilized. Small text
                  files known as "cookies" are delivered by a web server to your
                  hard drive and then saved on your computer. A cookie can
                  gather data such as the time and date of your visit, your
                  browsing history, and your preferences. Normally, you can set
                  your browser to refuse cookies. However, refusing to accept
                  cookies may prevent you from using some Website features. For
                  instance, you can have login issues.
                </span>
              </p>

              <p>
                <strong>

                  7. Our promise to protect the personal data we have gathered
                </strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  To protect personal information within our organization, we
                  try to utilize acceptable organizational, technical, and
                  administrative methods. However, no website or Internet
                  transmission is 100% safe. As a result, we can't promise that
                  there won't ever be an intrusion, hack, data loss, or another
                  breach. The App and Services are used at the user's own risk.
                  We kindly request that you take precautions to protect the
                  privacy of your data by memorizing your password or storing it
                  in a secure location.
                </span>
              </p>
              <p>
                <strong>8. Third Parties</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  The privacy, information, or other practices of any third
                  party, including any third party running any website or
                  service to which the Services link, are not covered by this
                  privacy statement, and we are not liable for them.
                </span>
              </p>
              <p>
                <strong>9. Transfer of Personal Data</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  Your data may need to be transferred to and/or stored at a
                  location outside of your country of residence for both
                  processing and storage purposes. You consent to the sending,
                  keeping, and/or processing of your data if you accept this
                  privacy statement.
                </span>
              </p>
              <p>
                <strong>10. Notice of changes to the Policy</strong>
              </p>
              <p style={{ marginLeft: "10px" }}>
                <span style={{ fontWeight: 400 }}>
                  By alerting registered users via email or the App of the
                  existence of a new Policy and/or by publishing the new Policy
                  on the Services, we retain the right to alter this Policy at
                  any time. Your continued use of our services following the
                  posting of any changes to the Policy will signify your
                  acceptance of and commitment to be bound by those changes. All
                  changes to the Policy will take effect when they are posted.
                </span>
              </p>
            </div>
          </Box>
        </Box>
      </div>
    );
  }
};

// PrivacyPolicy.propTypes = {
//   authProvider: PropTypes.func,
//   previousRoute: PropTypes.string,
// };

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const OtpRequestWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <PrivacyPolicy {...props} />
  </ThemeProvider>
);

export default OtpRequestWithTheme;
