import * as React from "react";
import { FC } from "react";
import { EditButton, Pagination, List, downloadCSV } from "react-admin";
import { ListControllerProps } from "ra-core";
import inflection from "inflection";
import Mask from "../companies/Mask.png";
import Unmask from "../companies/unmask.png";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  useMediaQuery,
  Theme,
} from "@material-ui/core";

import LinkToRelatedProducts from "./LinkToRelatedProducts";
import LinkToRelatedVisitors from "./LinkToRelatedVisitors";
import LinkToRelatedEmployees from "./LinkToRelatedEmployees";

import {} from "@material-ui/core/styles";
import { Company } from "../types";
import jsonExport from "jsonexport/dist";
import helperFunc from "../helperFuncs";
import CompanyLogo from "./CompanyLogo";
import moment from "moment";
const useStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
  title: {
    paddingBottom: "1em",
    height: 80,
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  Card: {
    width: 300,
    margin: "auto",
  },
  Media: {
    /*height: 140,
    width: '100%',*/
    objectFit: "cover",
  },
});

const CompanyGrid: FC<ListControllerProps<Company>> = (props) => {
  const classes = useStyles(props);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const { data, ids } = props;
  return ids ? (
    <Grid container spacing={2} className={classes.root}>
      {ids.map((id) => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Card>
            <CompanyLogo record={data[id]} />

            <CardContent className={classes.title}>
              <Typography component="h3" align="center">
                {data[id] &&
                  data[id].name &&
                  inflection.humanize(data[id].name)}
                {/* {data[id].name} */}
              </Typography>
            </CardContent>
            <CardActions classes={{ spacing: classes.actionSpacer }}>
              {/* <LinkToRelatedProducts record={data[id]} /> */}
              <LinkToRelatedVisitors record={data[id]} />
              <LinkToRelatedEmployees record={data[id]} />
              <EditButton basePath="/companies" record={data[id]} />
              <img
                src={data[id].isMask ? Mask : Unmask}
                // className={classes.media}
                alt={data[id].isMask ? Mask : Unmask}
              />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      assign_addons,
      isDeleted,
      documents,
      email,
      maritalStatus,
      isActive,
      marriageDate,
      isNewUser,
      isRegistred,
      lastLogin,
      metaInfo,
      userType,
      registration_start,
      type,
      settings,
      role,
      phone,
      phoneVerification,
      emailVerification,
      gender,
      forgotOTP,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      ...dataToExport
    } = record; // omit fields
    dataToExport.createdAt = helperFunc.commonDateFormat(createdAt, "HH:mm:ss"); // add a field
    dataToExport.status = isActive ? "Yes" : "No"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: ["name", "createdAt", "status"], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Companies Report"); // download as 'xxxx.csv` file
    }
  );
};

const CompanyList = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <>
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Companies
        </h2>
      ) : null}
      <List
        className="list-view2"
        exporter={exporter}
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 60, 80, 100]}
          />
        }
        component="div"
        // actions={true}
      >
        {/* 
          // @ts-ignore */}
        <CompanyGrid />
      </List>
    </>
  );
};

export default CompanyList;
