import RoleList from "./RoleList";
import { RoleIcon } from "../svgIcon";
import { RoleFormCommon } from "./RoleFormCommon";

export default {
  list: RoleList,
  create: RoleFormCommon,
  edit: RoleFormCommon,
  icon: RoleIcon,
};
