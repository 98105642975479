import React from "react";

import {
  Dialog,
  DialogTitle,
  Button as MaterialButton,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { TextInput, SimpleForm, FormDataConsumer, Button } from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { toastError } from "../components/toast";

type Props = { open: boolean; close: any; recordId: any; handleApproval: any };

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 24px 0px 18px",
  },
  p0: { padding: 0 },
}));

const ReasonDialogueBox = (props: Props) => {
  const { open, close, recordId, handleApproval } = props;
  const classes = useStyles();

  const saveForm = (formData: any) => {
    if (formData?.reason === undefined || formData?.reason === "")
      toastError("Please add Reason for Reject");
    else handleApproval(recordId, "reject", formData);
  };
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle className={classes.root}>Reason</DialogTitle>

      <DialogContent className={classes.p0}>
        <SimpleForm validate={() => true} toolbar={null}>
          <FormDataConsumer>
            {({ formData }: { formData: any }) => (
              <TextInput source="reason" label="Reason *" />
            )}
          </FormDataConsumer>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <Button label="Save" onClick={() => saveForm(formData)}>
                    <CheckCircleIcon />
                  </Button>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }: { formData: any }) => {
                return (
                  <Button
                    label="ra.action.cancel"
                    onClick={close}
                    style={{ color: "#181818cf" }}
                  >
                    <IconCancel />
                  </Button>
                );
              }}
            </FormDataConsumer>
          </div>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default ReasonDialogueBox;
