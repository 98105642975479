import * as React from "react";
import { FC, useState } from "react";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  useDataProvider,
  required,
} from "react-admin";
import employees from "../employees";
import { FieldProps, Visitor, Device } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { toastError, toastInfo } from "../components/toast";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  mobileRoot: { textAlign: "left" },
  name: { display: "inline-block" },
  header: { padding: 5 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
});

interface State {
  device?: string;
}

const RegistrationToolbar: FC<FieldProps<Visitor>> = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [newopen, setNewOpen] = useState(false);
  const refresh = useRefresh();
  // const notify = useNotify();
  const [state, setState] = useState<State>({});
  const [isValidate, setEnable] = useState(false);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => setOpen(false);
  const handleVideoDialogClose = () => setNewOpen(false);

  const handleConfirm = (record: any) => {
    const { device } = state;

    dataProvider
      .getOne("devices", {
        id: device,
      })
      .then((response: any) => {
        if (response.data) {
          const deviceData = response.data;
          dataProvider
            .callCustomLocal(deviceData.edgeURL + "/details_reg", {
              person_ID: record.id,
            })
            .then((responseLocal: any) => {
              if (responseLocal.data.success === true) {
                setNewOpen(true);
              }
            });
        }
      });

    setOpen(false);
  };

  const HandleOnChange = (device: any) => {
    if (!!device && device !== "") {
      setEnable(true);
    }

    setState((state) => ({
      ...state,
      device: device,
    }));
  };

  const handleConfirmVideo = (id: any) => {
    const { device } = state;

    dataProvider
      .getOne("devices", {
        id: device,
      })
      .then((response: any) => {
        if (response.data) {
          const deviceData = response.data;
          var isUploaded = true;
          dataProvider
            .callCustomLocal(deviceData.edgeURL + "/registration", {
              person_ID: id,
            })
            .then((responseLocal: any) => {
              if (responseLocal.data.success === true) {
                if (responseLocal.data.isUpdated === 0) {
                  isUploaded = false;
                }
                dataProvider
                  .callCustom("employees/employee_registration", {
                    person_ID: id,
                    deviceId: device,
                    imagedata: responseLocal.data.imagedata,
                    isRegistred: true,
                    isUploaded: isUploaded,
                  })
                  .then((response: any) => {
                    toastInfo("Employee register successfully.");
                    // notify("Employee register successfully.");
                    refresh();
                  });
                if (isUploaded === false) {
                  dataProvider
                    .callCustomLocal(deviceData.edgeURL + "/UploadModels", {})
                    .then((responseLocal: any) => {
                      if (responseLocal.data.success === true) {
                        toastInfo(responseLocal.data.msg);
                        // notify(responseLocal.data.msg);
                        refresh();
                      } else {
                        toastError(responseLocal.data.msg);
                        // notify(responseLocal.data.msg, "warning");
                      }
                    });
                }
              } else {
                if (responseLocal.data.imagedata) {
                  const responseLocalData = responseLocal.data;
                  dataProvider
                    .getOne("visitors", { id: responseLocalData.imagedata })
                    .then((responseLocalEMP: any) => {
                      if (responseLocalEMP.data.success == true) {
                        toastError(
                          responseLocal.data.msg +
                            " " +
                            responseLocalEMP.data.item.name
                        );
                      } else {
                        toastError(responseLocal.data.msg);
                        // notify(responseLocal.data.msg, "warning");
                      }
                    });
                } else {
                  toastInfo(responseLocal.data.msg);
                  // notify(responseLocal.data.msg);
                }
              }
            });
        }
      });

    setNewOpen(false);
  };

  const validateField = ({ values }: { values: any }) => {
    const errors = {} as any;
    // if (password && confirm_password && password !== confirm_password) {
    //   errors.confirm_password = ["resources.customers.errors.password_mismatch"];
    // }

    return errors;
  };

  return record ? (
    <div className={isXsmall ? classes.mobileRoot : classes.root}>
      {isXsmall ? (
        <employees.icon
          onClick={handleClick}
          style={{ fontSize: 30, color: "#283593", textAlign: "center" }}
        />
      ) : (
        <Button
          variant="contained"
          color="primary"
          label="Registration"
          onClick={handleClick}
        >
          <employees.icon className={classes.icon} />
        </Button>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Registration"
        style={{ zIndex: 1 }}
      >
        <DialogTitle>Registration</DialogTitle>
        <DialogContent>
          <Typography className={isXsmall ? classes.header : undefined}>
            Are you sure you wish to register this Visitor ?
          </Typography>
          <SimpleForm
            validate={validateField}
            // We override the redux-form name to avoid collision with the react-admin main form
            // form="post-quick-create"
            // We override the redux-form onSubmit prop to handle the submission ourselves
            // onSubmit={handleConfirm}
            onSubmit={() => handleConfirm(record)}
            // We want no toolbar at all as we have our modal actions
            toolbar={null}
          >
            <ReferenceInput
              source="device._id"
              reference="devices"
              validate={requiredValidate}
              formClassName={classes.suggestions}
              filter={{ company: record.company, isMaster: true }}
              onChange={HandleOnChange}
            >
              <AutocompleteInput
                formClassName={classes.suggestions}
                optionText={(choice: Device) =>
                  `${choice.camera_ID} - ${choice.device_location}`
                }
                optionValue="_id"
              />
            </ReferenceInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={() => handleConfirm(record)}
          >
            <CheckCircleIcon />
          </Button>
          <Button
            label="Cancel"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={newopen}
        title="Video Registration"
        content="Click 'START', Once you ready to start video registration process!"
        confirm="START"
        cancel="Cancel"
        onConfirm={() => handleConfirmVideo(record.id)}
        onClose={handleVideoDialogClose}
      />
    </div>
  ) : null;
};

const requiredValidate = [required()];

export default RegistrationToolbar;
