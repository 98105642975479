import React, { useRef, useCallback } from "react";
import Webcam from "react-webcam";
import {
  Button,
  useDataProvider,
  required,
  maxLength,
  regex,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";

import { streamStyle } from "../helperStyle";

const useStyles = makeStyles(streamStyle);
export const CaptureDialogueBox = (props: any) => {
  const { open, imgSrc, setImgSrc, setOpenCaptureDialogue } = props;

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const classes = useStyles();
  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    let imageSrc: any;
    imageSrc = webcamRef?.current?.getScreenshot();
    setImgSrc(imageSrc);
    setOpenCaptureDialogue(false);
  }, [webcamRef, setImgSrc]);

  const close = () => {
    setOpenCaptureDialogue(false);
    setImgSrc("");
  };

  return (
    <div className={classes.dialogContentDiv}>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Cap"
        style={{ zIndex: 1 }}
        className="dialogWidth3"
      >
        <DialogTitle className={classes.pb0}>
          <span className={classes.headTitle}>Capture Image</span>
        </DialogTitle>
        <DialogContent>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={500}
            height={250}
            // onUserMediaError={() => window.alert("cant access your camera")}
          />
          {/* {imgSrc && (
            <img
              width="500"
              height="250"
              src={imgSrc}
              alt="No Visitor Image Found"
            />
          )} */}
          {/* </div> */}
        </DialogContent>
        <DialogActions>
          {/* {viewLoading ? (
            <LinearProgress />
          ) : ( */}
          <Button label="Save" onClick={capture} className="my-save-button">
            <CheckCircleIcon />
          </Button>
          {/* )} */}
          <Button label="Cancel" onClick={close} style={{ color: "#181818cf" }}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
