import AttendanceList from "./AttendanceList";
import AttendanceEdit from "./AttendanceEdit";
import AttendanceCreate from "./AttendanceCreate";
import { AttendanceIcon } from "../svgIcon";

export default {
  list: AttendanceList,
  create: AttendanceCreate,
  edit: AttendanceEdit,
  icon: AttendanceIcon,
};
