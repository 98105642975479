import React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider, Loading } from "react-admin";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FieldProps, Attendance } from "../types";
import GridList from "./GridList";

// import { MyMapComponent } from '../components/map';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    maxWidth: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  lblName: {
    right: theme.spacing(0),
  },
  attendaceShowPopup: { maxWidth: 600 },
  lblLocation: {
    left: theme.spacing(1),
  },
  lblDate: {
    left: theme.spacing(1),
  },
}));

interface State {
  data?: any;
  attendanceData?: any;
}

const AttandanceShow: FC<FieldProps<Attendance>> = (record: any) => {
  let inLoc = record?.record?.inLoc ? record.record.inLoc : {};
  let outLoc = record?.record?.outLoc ? record.record.outLoc : {};
  inLoc.location = "In";
  outLoc.location = "Out";
  let location: any = [];
  let InLocation: boolean = true;
  location = [inLoc, outLoc, ...location];
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [apicall, setApiCall] = useState(false);

 

  const dataProvider = useDataProvider();
  const [state, setState] = useState<State>({});

  if (apicall && record && record.record && record.record.name) {
    if (record.record.datestr) {
      var res = record.record.datestr.split("-");
      var year = parseInt(res[0]);
      var month = parseInt(res[1]);
      var date = parseInt(res[2]);

      let data = {
        filter: {
          employeeName: record.record.name,
          time_from: new Date(year, month - 1, date, 0, 0, 0, 0),
          time_to: new Date(year, month - 1, date, 23, 59, 59, 999),
        },
        limit: 100000,
        skip: 0,
        sort: "desc",
        sortBy: "time",
      };
      const cardsCount = dataProvider
        .callCustom("public/attendance-report/detail/list", data)
        .then((response: any) => {
          setState((state) => ({
            ...state,
            attendanceData: response.data,
          }));
        });

      setApiCall(false);
    }
  }

  const { attendanceData } = state;

  const handleClick = () => {
    setOpen(true);
    setApiCall(true);
  };
  const handleClose = () => setOpen(false);

  const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      width: "100%",
    },
  }))(MuiDialogActions);
  return record ? (
    <div className={classes.root}>
      {/* {record && record.record && record.record.isAbsent === false ? ( */}
      <IconButton aria-label="Show" color="primary" onClick={handleClick}>
        <VisibilityIcon />
      </IconButton>
      {/* ) : null} */}

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          classes={classes}
          onClose={handleClose}
          style={{ width: 400 }}
        >
          Attendance Detail
        </DialogTitle>
        <DialogContent className={classes.attendaceShowPopup} dividers>
          {/* <MyMapComponent isMarkerShown={true} inLocation={state} record={location} /> */}
          {record?.apicall ? (
            <Loading loadingPrimary="Loading" />
          ) : attendanceData && attendanceData.count === 0 ? (
            "No Record Found"
          ) : (
            <GridList
              selectedRow={
                attendanceData && attendanceData.list
                  ? attendanceData.list
                  : undefined
              }
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export default AttandanceShow;
