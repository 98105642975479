import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import {
  useDataProvider,
  showNotification,
  useRefresh,
  useNotify,
} from "react-admin";
import { useMediaQuery, Theme, Button } from "@material-ui/core";
import { toastError, toastInfo } from "../components/toast";

const ImportButton = connect(undefined, { showNotification })((props: any) => {
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("text-button-file")
        : null;
  }
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  // const notify = useNotify();

  const handleChange = (e: any) => {
    let file = e.target.files && e.target.files[0];
    const { resource } = props;
    let data = new FormData();
    container.value = "";
    // e.target.files[0] = undefined;
    // file = null;
    // if (file) {
    data.append("file", file);
    dataProvider
      .callCustom(resource + "/import_attendence", data)
      .then((responseLocal: any) => {
        if (responseLocal.data.success === true) {
          toastInfo("Data has been imported Successfully.");
          // notify("Data has been imported Successfully.");
          refresh();
          // e.target.files[0] = null;
          // data = new FormData();
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        refresh();
        // e.target.files[0] = null;
        // data = new FormData();
      });
    // }
  };
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <>
      <input
        type="file"
        id="text-button-file"
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleChange}
      />
      <label htmlFor="text-button-file" className="btnfilter mb-0">
        <Button
          className="p-0"
          color="primary"
          component="span"
          style={{
            fontSize: "0.8125rem",
          }}
        >
          <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: 20 }} />
          {!isXSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>Import</span>
          ) : null}
        </Button>
      </label>
    </>
  );
});

export default connect()(ImportButton);
