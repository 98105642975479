import React from "react";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckCircleOutlineTwoToneIcon from "@material-ui/icons/CheckCircleOutlineTwoTone";
import { Dropdown } from "../common";

const FilterPublic = (props) => {
  const {
    formData,
    setFormData,
    filterData,
    setFilterData,
    handleFilter,
    handleResetFilter,
    filterParams,
  } = props;
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="d-flex align-items-center ">
      <div>
        <span>Name</span>

        <div>
          <input
            className="public-date-input"
            type="text"
            name="name"
            id="name"
            onChange={handleOnChange}
            value={formData?.name}
          ></input>
        </div>
      </div>

      <div>
        <span for="fromDate">From Date</span>

        <div>
          <input
            className="public-date-input"
            type="date"
            name="fromDate"
            id="fromDate"
            onChange={handleOnChange}
            value={formData?.fromDate}
            defaultValue={filterParams?.filter?.fromDate}
          ></input>
        </div>
      </div>
      <div>
        <span for="toDate">To Date</span>

        <div>
          <input
            className="public-date-input"
            type="date"
            name="toDate"
            id="toDate"
            onChange={handleOnChange}
            value={formData?.toDate}
            defaultValue={filterParams?.filter?.toDate}
          ></input>
        </div>
      </div>

      <div className="d-flex align-items-center ml-1 mt-4">
        <CheckCircleOutlineTwoToneIcon
          style={{ color: "#283593", cursor: "pointer" }}
          onClick={handleFilter}
        />
        <HighlightOffRoundedIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={handleResetFilter}
        />
      </div>
    </div>
  );
};

export default FilterPublic;
