import * as React from "react";
import { FC, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor } from "../types";
import helperFunc from '../helperFuncs'
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));

interface Props extends FieldProps<Visitor> { }

const TotalTimeField: FC<Props> = ({ record }) => {
    const classes = useStyles();
    return record && record.totalTime ? (
        <div className={classes.root}>
            {helperFunc.secondsToHms(record.totalTime)}
        </div>
    ) : null;
};

TotalTimeField.defaultProps = {
    source: "name",
    label: "resources.customers.fields.name",
};

export default memo<Props>(TotalTimeField);
