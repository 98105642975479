import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  maxLength,
  number,
  minLength,
  email,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import FullNameField from "./FullNameField";
import { Leave, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import { clientStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const validatedate = ({
  fromDate,
  toDate,
}: {
  fromDate: string;
  toDate: string;
}) => {
  const errors = {} as any;

  if (fromDate && new Date(fromDate) < new Date()) {
    errors.fromDate = "From date must be greater than current date";
  }
  if (fromDate && toDate && new Date(toDate) < new Date(fromDate)) {
    errors.toDate = "To date must be greater than From date";
  }
  return errors;
};

const useStyles = makeStyles(clientStyle);
const CommonForm = (props: any) => {
  const classes = useStyles();
  // const user = helperFunc.getUserData();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      {/* <h2 style={{ display: 'flex', fontSize: 25, backgroundColor: '#fff', padding: 10 }}>{props && props.location && props.location.pathname !== "/clientMasters/create" ? "Update Client" : "Add Client"}</h2> */}
      <SimpleForm
        {...props}
        validate={
          props && Object.keys(props.record).length === 0 ? validatedate : null
        }
      >
        <CompanyReferenceInput></CompanyReferenceInput>
        <SectionTitle label="" />
        <TextInput
          autoFocus
          resource="clientMaster"
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
          label="Client Name"
        />
        <FormDataConsumer formClassName={isSmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true }}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={isSmall ? null : classes.code}>
          {({ formData }: { formData: any }) => (
            <TextInput
              autoFocus
              source="employerName"
              formClassName={classes.name}
              validate={nameValidate}
            />
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <TextInput
          type="email"
          source="email"
          formClassName={classes.name}
          validate={validateEmail}
        />
        <TextInput
          source="phone"
          formClassName={isSmall ? classes.name : classes.code}
          // validate={phoneValidate}
        />
        <TextInput
          // disabled={props && Object.keys(props.record).length === 0 ? false : true}
          source="websiteDetails"
          resource="clientMaster"
          formClassName={isSmall ? classes.name : classes.code}
        />
        <SectionTitle label="" />
        <TextInput
          // disabled={props && Object.keys(props.record).length === 0 ? false : true}
          label="label.address"
          resource="clientMaster"
          source="address"
          fullWidth
          formClassName={isSmall ? classes.mobileAddress : classes.address}
          validate={resoneValidate}
        />
        <SectionTitle label="" />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="region"
          defaultValue={true}
          formClassName={classes.name}
        />
      </SimpleForm>
    </>
  );
};

// const LeaveTitle: FC<FieldProps<Leave>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const phoneValidate = [maxLength(10), number(), minLength(10)];
const validateEmail = [email()];
// const requiredValidate = [required()];
// const subjectValidate = [required(), maxLength(50)];
const resoneValidate = [
  required(),
  maxLength(500),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
