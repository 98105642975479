import * as React from "react";
import { Button } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const DialogueBox = (props: any) => {
  const handleClick = () => {
    props.remove();
    props.handleCloseClick();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCloseClick}
        aria-label="Are you sure?"
      >
        <DialogTitle>Deleting Image</DialogTitle>
        <DialogContent>
          <div style={{ margin: window.screen.width < 460 ? 15 : 0 }}>
            Are you sure you want to delete this{" "}
            {props.label ? props.label : "Image"}?
          </div>
          <div></div>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValidate}
            type="submit"
            label="Confirm"
            onClick={handleClick}
          >
            <CheckCircleIcon />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={props.handleCloseClick}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogueBox;
