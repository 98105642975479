import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { required, AutocompleteArrayInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  Branch?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  isXsmall?: boolean;
}

const ClientMasterArraySelectInput: FC<Props> = ({ isXsmall, filter }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("clientMasters/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          Branch: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { Branch } = state;

  return (
    <AutocompleteArrayInput
      label="Clients"
      source="clientIds"
      reference="clientMasters"
      resettable
      className={classes.root}
      // validate={requiredValidate}
      choices={Branch}
      fullWidth={isXsmall ? false : true}
    />
  );
};
const requiredValidate = [required()];
ClientMasterArraySelectInput.defaultProps = {
  source: "clientIds",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ClientMasterArraySelectInput);
