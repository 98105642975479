import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  Button,
  useNotify,
  SelectInput,
  SimpleForm,
  useDataProvider,
  required,
  FormDataConsumer,
  LinearProgress,
  TextInput,
  NumberInput,
} from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  useMediaQuery,
  Theme,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// import DialogueBox from "./ConfirmDialogueBox";

import { toastError, toastInfo } from "../components/toast";
import { Item } from "semantic-ui-react";
import DialogueBox from "../components/ConfirmDialogueBox";
import WorkplaceIdSelectInput from "../components/WorkplaceIdSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import CompanyReferenceInput from "./CompanyReferenceInput";
import { deviceLocation } from "../helperChoice";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  divCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "18px",
    marginTop: "-18px",
  },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: 0, marginLeft: 18 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  editAllotmentBtn: {
    fontSize: 12,
    border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
});

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

interface Props {
  open: any;
  setOpen: any;
  count: number;
  setCount: any;
  listCompanyData: any;
  setListCompanyData: any;
  allotments: any;
  setAllotments: any;
  companyId: string;
  setCompanyId: any;
  flag: string;
  index: number;
  companyName: any;
  setCompanyName: any;
}

const MultiDeviceDialogue: FC<Props> = ({
  open,
  setOpen,
  allotments,
  setAllotments,
  setListCompanyData,
  listCompanyData,
  companyId,
  setCompanyId,
  flag,
  index,
  companyName,
  setCompanyName,
}) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [isLoad, setLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [allotmentDialog, setAllotmentDialog] = useState({});
  const [objWorkplaceData, setObjWorkplaceData] = useState() as any;
  const [rowIndex, setRowIndex] = useState() as any;
  const [editForm, setEditForm] = useState() as any;
  const [editData, setEditData] = useState({
    _id: "",
    company: "",
    locationName: "",
    authType: "",
    cameraURL: "",
    usb: "",
    fps: "",
    x1: "",
    x2: "",
    y1: "",
    y2: "",
    workplace: "",
  }) as any;

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, allotment: any) => {
    setRowIndex(index);
    setShowDialog(true);
    setAllotmentDialog(allotment);
  };

  const removeAllotment = (idx: any) => {
    setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
  };

  const handleDialogClose = () => setOpen(false);

  const addAllotment = (data: any) => {
    if (data && (data.locationName === "" || data.locationName === undefined)) {
      toastError("Please add Location Name");
      return;
    } else if (data && (data.authType === "" || data.authType === undefined)) {
      toastError("Please add Check In/Check Out");
      return;
    } else if (
      data &&
      (data.cameraURL === "" || data.cameraURL === undefined)
    ) {
      toastError("Please add Camera URL");
      return;
    } else if (
      data &&
      (data.workplaceId === "" || data.workplaceId === undefined)
    ) {
      toastError("Please add Workplace");
      return;
    } else if (data && (data.usb === "" || data.usb === undefined)) {
      toastError("Please add USB Port");
      return;
    } else {
      let duplicate = false;

      allotments.filter((s: any, sIdx: any) => {
        if (s.cameraURL === data.cameraURL) {
          duplicate = true;
        }
      });

      if (duplicate) {
        toastError("Duplicate Camera Url Entry is not allowed");
      } else {
        setAllotments([
          ...allotments,
          {
            locationName: data.locationName,
            cameraURL: data.cameraURL,
            authType: data.authType,
            workplaceId: objWorkplaceData,
            usb: data.usb,
            fps: data.fps,
            x1: data.x1,
            x2: data.x2,
            y1: data.y1,
            y2: data.y2,
            workPlaceName: objWorkplaceData?.name,
          },
        ]);
      }

      data.locationName = "";
      data.cameraURL = "";
      data.authType = "";
      data.workplaceId = "";
      data.usb = "";
      data.fps = "";
      data.x1 = "";
      data.x2 = "";
      data.y1 = "";
      data.y2 = "";
      data.workPlaceName = "";
      setEditData({});
      setEditForm(false);
    }
  };

  const workplaceData = (data: any) => {
    setObjWorkplaceData(data);
  };

  const childCompanyData = (data: any) => {
    setCompanyName(data.name);
    setCompanyId(data.id);
  };

  const handleConfirm = () => {
    if (companyName !== "" && companyName !== undefined) {
      if (flag !== "" && flag === "edit") {
        let dummy = listCompanyData;
        dummy[index].companyId = companyId;
        dummy[index].cameraDetails = allotments;
        dummy[index].companyName = companyName;
        setListCompanyData(dummy);
      } else {
        setListCompanyData([
          ...listCompanyData,
          {
            companyName: companyName,
            companyId: companyId,
            cameraDetails: allotments,
          },
        ]);
      }
      handleDialogClose();
    } else {
      toastError("Please add company");
    }
  };

  const dataArr = [
    {
      name: "Location Name",
      key: "locationName",
    },
    { name: "Auth Type", key: "authType" },
    { name: "Camera URL", key: "cameraURL" },
    { name: "Workplace", key: "workplaceId" },
    { name: "USB Port", key: "usb" },
    { name: "FPS", key: "fps" },
    { name: "Crop Coordinates", key: "cropCoordinates" },
  ];

  const handleEdit = (index: number) => {
    setEditForm(true);

    let updatedData: any;
    updatedData = [...allotments];

    setEditData({
      _id: updatedData && updatedData[index]?._id,
      company: updatedData && updatedData[index]?.company,
      locationName: updatedData && updatedData[index]?.locationName,
      authType: updatedData && updatedData[index]?.authType,
      cameraURL: updatedData && updatedData[index]?.cameraURL,
      usb: updatedData && updatedData[index]?.usb,
      fps: updatedData && updatedData[index]?.fps,
      x1: updatedData && updatedData[index]?.x1,
      x2: updatedData && updatedData[index]?.x2,
      y1: updatedData && updatedData[index]?.y1,
      y2: updatedData && updatedData[index]?.y2,
      workplace: updatedData && updatedData[index]?.workplaceId,
    });

    setAllotments(allotments.filter((s: any, sIdx: any) => index !== sIdx));
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Vehicle Allotments"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>Add Company Details</DialogTitle>

        <DialogContent>
          <SimpleForm
            validate={() => true}
            toolbar={null}
            record={{
              company: editData?.company,
              locationName: editData?.locationName,
              authType: editData?.authType,
              cameraURL: editData?.cameraURL,
              usb: editData?.usb,
              fps: editData?.fps,
              x1: editData?.x1,
              x2: editData?.x2,
              y1: editData?.y1,
              y2: editData?.y2,
              workplaceId: editData?.workplace?._id,
            }}
          >
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <CompanyReferenceInput
                  helperText={false}
                  filter={{ isActive: true }}
                  dataManage={childCompanyData}
                  labelName="Company"
                  defaultValue={
                    flag !== undefined && flag === "edit" ? companyId : ""
                  }
                  disabled={flag !== undefined && flag === "edit"}
                ></CompanyReferenceInput>
              )}
            </FormDataConsumer>
            <SectionTitle label="Camera" />

            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  autoFocus
                  source="locationName"
                  resource="device"
                  label="Location Name*"
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={classes.employee}>
              {({ formData }: { formData: any }) => (
                <SelectInput
                  resettable
                  label="label.inOut"
                  source="authType"
                  resource="device"
                  formClassName={classes.employee}
                  choices={deviceLocation}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={classes.employee}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  autoFocus
                  source="cameraURL"
                  resource="device"
                  label="Camera URL*"
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <WorkplaceIdSelectInput
                  filter={{
                    isActive: true,
                    company: formData.company ? formData.company : companyId,
                  }}
                  dataManage={workplaceData}
                ></WorkplaceIdSelectInput>
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="USB Port*"
                  source="usb"
                  resource="device"
                  formClassName={classes.employee}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="FPS"
                  source="fps"
                  resource="device"
                  formClassName={classes.employee}
                  // defaultValue={"[0, 0, 0, 0]"}
                />
              )}
            </FormDataConsumer>

            <SectionTitle label="Crop Coordinates" />

            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="x1"
                  source="x1"
                  resource="device"
                  formClassName={classes.name}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="x2"
                  source="x2"
                  resource="device"
                  formClassName={classes.employee}
                />
              )}
            </FormDataConsumer>

            <SectionTitle label="" />

            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="y1"
                  source="y1"
                  resource="device"
                  formClassName={classes.name}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <NumberInput
                  label="y2"
                  source="y2"
                  resource="device"
                  formClassName={classes.employee}
                />
              )}
            </FormDataConsumer>

            <SectionTitle label="" />

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.divCenter}
            >
              {({ formData }: { formData: any }) => (
                <MaterialButton
                  onClick={() => addAllotment(formData)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="button"
                  className={isXsmall ? undefined : classes.allotmentBtn}
                >
                  Allocate
                </MaterialButton>
              )}
            </FormDataConsumer>
            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {dataArr?.map((item: any, index) => (
                      <TableCell key={index + 1} className={classes.tableHead}>
                        {item.name}
                      </TableCell>
                    ))}
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allotments.length > 0
                    ? allotments?.map((allotment: any, index: number) => {
                        return (
                          <TableRow key={index + 1}>
                            {dataArr?.map((item: any) => {
                              if (item.key === "cropCoordinates") {
                                return (
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >{`[${allotment.x1},
                                    ${allotment.x2},
                                    ${allotment.y1},
                                    ${allotment.y2}
                                  ]`}</TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >
                                    {allotment &&
                                    item &&
                                    allotment[item.key] &&
                                    allotment[item.key].name
                                      ? allotment[item.key].name
                                      : allotment[item.key]
                                      ? allotment[item.key]
                                      : ""}
                                  </TableCell>
                                );
                              }
                            })}

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {!editForm && (
                                <MaterialButton
                                  onClick={() => handleEdit(index)}
                                  variant="outlined"
                                  className={classes.editAllotmentBtn}
                                  type="button"
                                >
                                  Edit
                                </MaterialButton>
                              )}

                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}

                  {allotments?.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={8}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>

            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(rowIndex)}
              itemName={allotmentDialog}
            />
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              type="submit"
              label="Save"
              onClick={() => handleConfirm()}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Close"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiDeviceDialogue;
