import * as React from "react";
import { FC, memo, useState } from "react";
import {
  required,
  SelectInput,
  useDataProvider
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  workplace?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
  value?: number;
  filter?: {};
}

const WorkplaceSelectInput: FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider.getSearchList("workplaces/search", filter).then((response: any) => {
      setState((state) => ({
        ...state,
        workplace: response && response.data,
      }));
    });
  }, [filter])


  const { workplace } = state;

  return (
    <SelectInput
      label="Workplaces"
      className={classes.root}
      source="workplaces"
      validate={requiredValidate}
      choices={workplace}
    />
  );
};
const requiredValidate = [required()];
WorkplaceSelectInput.defaultProps = {
  source: "workplace",
  label: "resources.customers.fields.name",
};

export default memo<Props>(WorkplaceSelectInput);
