export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    RaMenuItemLink: {
      active: {
        border: "1px solid #0e0e0e1f !important",
        borderRadius: "8px !important",
        background: "#74787b0f !important",
      },
    },
  },
};
