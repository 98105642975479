import * as React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  maxLength,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { designationstyles } from "../helperStyle";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(designationstyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/designations/create"
          ? "Update Designation"
          : "Add Designation"}
      </h2>
      <SimpleForm {...props}>
        {/* <SectionTitle label="resources.designations.page.edit" /> */}
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="designations"
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Designation Name"
          />
          <TextInput
            source="code"
            resource="designations"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="designations"
          defaultValue="true"
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.designations.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        resource="designations"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Designation Name"
      />
      <TextInput
        source="code"
        resource="designations"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="designations"
        defaultValue="true"
      />
    </SimpleForm>
  );
};
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

export default CommonForm;
