import React, { FC } from "react";
import SuperDashboard from "./SuperDashboard";
import CompanyDashboard from "./CompanyDashboard";
import EmployeeDashboard from "./EmployeeDashboard";
import HrDashboard from "./HrDashboard";
import helperFunc from "./../helperFuncs";
import Login from "../layout/Login";

const Dashboard = () => {
  const user = helperFunc.getUserData();

  

  let currentUser = user !== null && user.type !== null ? user.type : "none";
  if (user !== null && user.type !== null && user.type === "employee") {
    if (user.employeeType !== null) {
      currentUser = user.employeeType.code;
    }
    // if (!!user.designation) {
    //   const designation = user.designation.toLowerCase();
    //   switch (designation) {
    //     case "hr":
    //       currentUser = "hr";
    //       break;
    //     case "pm":
    //       currentUser = "pm";
    //       break;
    //     default:
    //       currentUser = "employee";
    //       break;
    //   }
    // }
  }
  switch (currentUser) {
    case "super":
      return <SuperDashboard></SuperDashboard>;
    case "company":
      return <CompanyDashboard></CompanyDashboard>;
    case "ad":
      return <CompanyDashboard></CompanyDashboard>;
    case "em":
      return <EmployeeDashboard></EmployeeDashboard>;
    case "hr":
      return <HrDashboard></HrDashboard>;
    case "none":
      return <Login> </Login>;
    default:
      return <EmployeeDashboard></EmployeeDashboard>;
  }
};

export default Dashboard;
