import * as React from "react";
import { Link, useDataProvider } from "react-admin";

import { toastError, toastInfo } from "../components/toast";

const RequestLinkField = (props: any) => {
  const [status, setStatus] = React.useState() as any;
  const dataProvider = useDataProvider();
  const approvalStatus: any = {
    cancel: "Cancel",
  };

  const handleCancel = (record: any) => {
    dataProvider
      .callCustomLocalOne("attendenceRequestLog/updatestatus/" + record?._id, {
        requestStatus: "cancel",
      })
      .then((response: any) => {
        setStatus(true);
        toastInfo("Request cancelled Successfully");
      });
  };

  return (
    <>
      {status?.requestStatus ? (
        approvalStatus[status?.requestStatus]
      ) : props?.record?.requestStatus === "pending" ? (
        <Link onClick={(event: any) => handleCancel(props.record)}>Cancel</Link>
      ) : (
        approvalStatus[props?.record?.requestStatus]
      )}
    </>
  );
};

RequestLinkField.defaultProps = {
  source: "Action",
  sortable: false,
};

export default RequestLinkField;
