import * as React from "react";
import { Button } from "react-admin";
import {
  Dialog,
  DialogTitle,
  Button as MaterialButton,
  DialogContent,
  makeStyles,
} from "@material-ui/core";

import Remarks from "./Remarks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 24px 0px 18px",
  },
  p0: { padding: 0 },
}));

const DialogueBox = (props: any) => {
  const {
    responseData,
    setResponseData,
    leaveBalanceObj,
    open,
    handleCloseClick,
    setLeaveBalanceObj,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={handleCloseClick}>
        <DialogTitle
          className={classes.root}
        >{`${leaveBalanceObj?.employeeName}'s ${leaveBalanceObj?.leaveName}`}</DialogTitle>

        <DialogContent className={classes.p0}>
          <Remarks
            handleCloseClick={handleCloseClick}
            responseData={responseData}
            setResponseData={setResponseData}
            leaveBalanceObj={leaveBalanceObj}
            setLeaveBalanceObj={setLeaveBalanceObj}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogueBox;
