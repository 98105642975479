import DocumentCategoryList from "./DocumentCategoryList";
import { DepartmentIcon } from "../svgIcon";
import { DocumentCategoryCommon } from "./DocumentCategoryCreate";
import { DocumentCategoryIcon } from "../svgIcon/svgIconCollege";

export default {
  list: DocumentCategoryList,
  create: DocumentCategoryCommon,
  edit: DocumentCategoryCommon,
  icon: DocumentCategoryIcon,
};
