import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from '../employeeType/commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';


const CountryEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Employee-type"} {...props} >
      <CommonForm toolbar={<PostEditToolbar />}  {...props} />
    </Edit>
  );
};
export default CountryEdit;
