import * as React from "react";
import { FC } from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { EditButton } from "react-admin";
import { DatagridProps, Visitor } from "../types";
import AttendanceLogo from "./AttendanceLogo";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Identifier } from "ra-core";
import AttandanceShow from "./AttendanceShow";
import helperFunc from "../helperFuncs";
import EditMenu from "./EditMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-2px",
  },
  editButton: {
    top: "45px",
    color: "#00008b",
    right: "30px",
    position: "absolute",
    marginTop: " -155px",
    maxWidth: " 40px",
    height: "40px",
    borderRadius: "100%",
    width: "40px",
    minWidth: " initial",
  },
  menuButton: {
    marginTop: "-200px",
    color: "#00008b",
  },
  gridList: {
    width: "100%",
    margin: 0,
  },
  mobileGridList: {
    width: window.screen.width,
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  icon: {
    color: "white",
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  mb5: {
    marginBottom: "5px",
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
  width,
  nbItems = 20,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={
          getColsForWidth(width) === 2
            ? classes.mobileGridList
            : classes.gridList
        }
      >
        {times(nbItems, (key) => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadedGridList: FC<GridProps> = ({
  ids,
  data,
  basePath,
  width,
  anchorEl,
  setAnchorEl,
  clickedEmp,
  setClickedEmp,
}) => {
  const location: any = {
    in: "In",
    out: "Out",
  };

  const classes = useStyles();
  if (!ids || !data) return null;
  const user = helperFunc.getUserData();

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={
          getColsForWidth(width) === 2
            ? classes.mobileGridList
            : classes.gridList
        }
      >
        {ids !== null &&
          ids.map((id) => (
            <GridListTile>
              <AttendanceLogo record={data[id]}> </AttendanceLogo>

              <GridListTileBar
                className={classes.tileBar}
                title={data[id] && data[id].employee && data[id].employee.name}
                subtitle={
                  <span>
                    <div style={{ marginBottom: "5px" }}>
                      {data[id] &&
                        data[id].time &&
                        location[data[id].authType] +
                          ":" +
                          helperFunc.commonDateFormat(
                            data[id] && data[id].time,
                            "HH:mm:ss"
                          )}
                    </div>
                    <div style={{ textTransform: "capitalize" }}>
                      Type :{" "}
                      {data[id]?.employeeType?.name
                        ? data[id]?.employeeType?.name
                        : data[id] &&
                          data[id].employee &&
                          data[id].employee?.type}
                    </div>
                  </span>
                }
                actionIcon={[
                  user.designation === "HR" ||
                  user.type === "super" ||
                  user.type === "company" ? (
                    <EditButton
                      label=""
                      className={
                        user.isChangeAttendance || user.isAttendanceRemark
                          ? classes.editButton
                          : classes.menuButton
                      }
                      basePath="/attendance"
                      record={data[id]}
                    />
                  ) : null,
                  (user.isChangeAttendance || user.isAttendanceRemark) && (
                    <EditMenu
                      className={classes.menuButton}
                      setAnchorEl={setAnchorEl}
                      record={data && data[id]}
                      setClickedEmp={setClickedEmp}
                      anchorEl={anchorEl}
                      clickedEmp={clickedEmp}
                    />
                  ),
                  <AttandanceShow record={data[id]} />,
                ]}
              />
            </GridListTile>
          ))}
      </MuiGridList>
    </div>
  );
};

interface GridProps extends DatagridProps<Visitor>, WithWidth {
  selectedRow?: Identifier;
  anchorEl?: any;
  setAnchorEl?: any;
  clickedEmp?: any;
  setClickedEmp?: any;
}

const GridList: FC<GridProps> = ({ loaded, ...props }) =>
  loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);
