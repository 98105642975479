import * as React from "react";
import { FC, memo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { FieldProps, Visitor, Role } from "../types";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  clientLocations?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: any;
  formClassName?: any;
  dataManage?: any;
  emptyValue?: any;
  validate?: any;
  helperText?: boolean;
  labelName?: string;
}

const ClientLocationSelectInput: FC<Props> = ({
  helperText,
  filter,
  emptyValue,
  dataManage,
  formClassName,
  validate,
  labelName,
}) => {
  const form = useForm();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("clientLocations/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          clientLocations: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { clientLocations } = state;

  if (emptyValue && emptyValue.emptyValue) {
    form.change("clientLocationId", undefined);
    form.change("hiddenVal", "valueChange");
  }

  const check = (value: any) => {
    clientLocations?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          // dataManage(data, 'clientLocation');
        }
      }
    });
  };
  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Client Location"}
      className={classes.root}
      source="clientLocationId"
      validate={validate}
      choices={clientLocations}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
    />
  );
};

ClientLocationSelectInput.defaultProps = {
  source: "zoneId",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ClientLocationSelectInput);
