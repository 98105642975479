import * as React from "react";
import {
  BooleanField,
  TextField,
  // SimpleForm,
  Datagrid,
  // DateField,
  // DateInput,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  // ReferenceInput,
  // AutocompleteInput,
  // TextInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  useDeleteMany,
  Button as ReactButton,
  DateInput,
  required,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import DepartmentLinkField from "./DepartmentLinkField";
import MobileGrid from "./MobileGrid";
import jsonExport from "jsonexport/dist";
import ImportButton from "../components/import";
import DescriptionIcon from "@material-ui/icons/Description";
// import { Company } from "../types";
// import moment from 'moment';
import EmployeeAllotment from "./EmployeeAllotment";
import { Fragment } from "react";
import { isActive } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { workPlaceStyle } from "../helperStyle";
import ImportButtonShift from "../components/importShift";
import classnames from "classnames";
import ActionDelete from "@material-ui/icons/Delete";
import MultiDeleteTable from "../components/ErrorTable";
import { Menu } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from "@material-ui/icons/GetApp";
import DepartmentIcon from "@material-ui/icons/HomeWork";

import { Link } from "react-router-dom";
import { toastError } from "../components/toast";
const useStyles = makeStyles(workPlaceStyle);

const BulkDeleteButton = ({ resource, selectedIds }: any) => {
  const [deleteMany, { data, loading, error }] = useDeleteMany(
    resource,
    selectedIds
  );
  return (
    <React.Fragment>
      <MultiDeleteTable data={data} resource={resource} />
      <ReactButton
        disabled={loading}
        align="right"
        onClick={deleteMany}
        label={"Delete"}
        className={classnames("ra-delete-button")}
        key="button"
      >
        <ActionDelete />
      </ReactButton>
    </React.Fragment>
  );
};

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (
        helperFunc.createdAtDateFormat(values.createdAt) >
        helperFunc.createdAtDateFormat(new Date())
      ) {
        toastError("Create date cannot be greater then current date");
      } else {
        if (values.createdAt) {
          // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
          var dates = helperFunc.filterCreatedAt(values.createdAt);
          values.createdAt_from = dates.time_from;
          values.createdAt_to = dates.time_to;
        } else {
          values.createdAt_from = undefined;
          values.createdAt_to = undefined;
        }
        if (values.isActive === "true") {
          values.isActive = true;
        } else if (values.isActive === "false") {
          values.isActive = false;
        }
        data.setFilters(values);
        data.handleDrawerClose("fromFilter");
      }
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };

  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      // allowEmpty={false}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      // validate={[checkFromDate]}
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    filters,
    showFilter,
    displayedFilters,
    handleDrawerOpen,
    open,
  } = props;
  const user = helperFunc.getUserData();
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openNav = Boolean(anchorEl);
  const openNavImport = Boolean(anchorEl2);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickImport = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseImport = () => {
    setAnchorEl2(null);
  };
  return (
    <div style={{ display: "block", width: isXsmall ? "100%" : "70%" }}>
      <TopToolbar className={isXsmall ? classes.flexrow : className}>
        <CreateButton basePath={basePath} />
        <Button
          style={{
            padding: "3px 0px 0px 0px",
            fontSize: "13px",
          }}
          color="primary"
          component="span"
          onClick={() => handleDrawerOpen()}
        >
          <FilterListIcon style={{ marginRight: "0.2em" }} />
          {isSmall ? null : "Filter"}
        </Button>

        {/* 
        {user.type === "super" || user.type === "company" ? (
          <Button
            color="primary"
            component="span"
            onClick={Sampleexporter}
            style={
              isSmall
                ? {
                    fontSize: "0.8125rem",
                  }
                : {
                    fontSize: "0.8125rem",
                    minWidth: "126px",
                  }
            }
          >
            <DescriptionIcon
              style={{ transform: "rotate(180deg)", fontSize: 20 }}
            />
            {!isSmall ? (
              <span style={{ paddingLeft: "0.5em" }}>Sample CSV</span>
            ) : null}
          </Button>
        ) : null} */}

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          name={isSmall ? null : "Export"}
        />
        {user.type === "super" || user.type === "company" ? (
          <div
            style={
              isSmall
                ? {
                    fontSize: "0.8125rem",
                  }
                : {
                    fontSize: "0.8125rem",
                    minWidth: "82px",
                  }
            }
          >
            <Button
              id="demo-positioned-button"
              aria-controls="demo-positioned-menu"
              aria-haspopup="true"
              aria-expanded={openNavImport ? "true" : undefined}
              onClick={handleClickImport}
            >
              <Typography
                style={{
                  fontSize: "0.8125rem",
                  color: "#3f51b5",
                  marginTop: "-1px",
                }}
              >
                <GetAppIcon
                  style={{
                    transform: "rotate(180deg)",
                    fontSize: 20,
                    marginRight: "0.2em",
                    marginTop: "-5px",
                  }}
                />
                {!isXsmall ? <span>Import </span> : null}
              </Typography>
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl2}
              open={openNavImport}
              onClose={handleCloseImport}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => <ImportButton {...props} />}
                style={{ padding: "0px" }}
              >
                <ImportButton
                  {...props}
                  handleCloseImport={handleCloseImport}
                />
              </MenuItem>
              <MenuItem
                onClick={() => (
                  <ImportButtonShift entryPoint="shift" {...props} />
                )}
                style={{ padding: "0px", marginLeft: "6px" }}
              >
                <ImportButtonShift entryPoint="shift" {...props} />
              </MenuItem>
            </Menu>
          </div>
        ) : null}

        {user.type === "super" || user.type === "company" ? (
          <div style={{ right: 250 }}>
            <Button
              id="demo-positioned-button"
              aria-controls="demo-positioned-menu"
              aria-haspopup="true"
              aria-expanded={openNav ? "true" : undefined}
              onClick={handleClick}
            >
              <Typography
                style={{
                  fontSize: "0.8125rem",
                  color: "#3f51b5",
                  marginTop: "-1px",
                }}
              >
                <DescriptionIcon
                  style={{ transform: "rotate(180deg)", fontSize: 18 }}
                />
                {!isXsmall ? (
                  <span style={{ paddingLeft: "0.5em" }}>Sample CSV </span>
                ) : null}
              </Typography>
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openNav}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleClose} style={{ padding: "0px" }}>
                <Button
                  color="primary"
                  component="span"
                  onClick={Sampleexporter}
                  style={
                    isSmall
                      ? {
                          fontSize: "0.8125rem",
                        }
                      : {
                          fontSize: "0.8125rem",
                          minWidth: "126px",
                        }
                  }
                >
                  <DescriptionIcon
                    style={{ transform: "rotate(180deg)", fontSize: 20 }}
                  />

                  <span style={{ paddingLeft: "0.5em" }}>Workplace</span>
                </Button>
              </MenuItem>
              <MenuItem style={{ padding: "0px" }}>
                {/* {user.type === "super" || user.type === "company" ? ( */}
                <Button
                  color="primary"
                  component="span"
                  onClick={ShiftSampleexporter}
                  style={
                    isSmall
                      ? {
                          fontSize: "0.8125rem",
                        }
                      : {
                          fontSize: "0.8125rem",
                          minWidth: "82px",
                        }
                  }
                >
                  <DescriptionIcon
                    style={{
                      transform: "rotate(180deg)",
                      fontSize: 20,
                      marginTop: "-4px",
                    }}
                  />
                  <span style={{ paddingLeft: "0.5em" }}>Shift</span>
                </Button>
                {/* ) : null} */}
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}

        {/* {user.type === "super" || user.type === "company" ? (
          <Button
            color="primary"
            component="span"
            onClick={ShiftSampleexporter}
            style={
              isSmall
                ? {
                    fontSize: "0.8125rem",
                  }
                : {
                    fontSize: "0.8125rem",
                    minWidth: "126px",
                  }
            }
          >
            <DescriptionIcon
              style={{ transform: "rotate(180deg)", fontSize: 20 }}
            />
            {!isSmall ? (
              <span style={{ paddingLeft: "0.5em" }}>Shift CSV</span>
            ) : null}
          </Button>
        ) : null} */}
      </TopToolbar>
    </div>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const WorkplaceFilter = (props: any) => (
  <Filter style={{ width: 0 }} {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
    {/* <TextInput source="code" /> */}
  </Filter>
);
const Sampleexporter = () => {
  const records = [
    {
      name: "NameExample",
      childCompany: "Rydotoffice",
      client: "rydot",
      clientLocation: "rydotinfotech",
      latitude: "122456",
      longitude: "213456",
      radius: "50",
      ot: "true",
      address: "AddressExample",
      zipcode: "395006",
      status: "true",
    },
    {
      name: "NameExample1",
      childCompany: "Rydotoffice",
      client: "rydot",
      clientLocation: "rydotinfotech",
      latitude: "",
      longitude: "",
      radius: "100",
      ot: "true",
      address: "AddressExample1",
      zipcode: "395006",
      status: "true",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record;
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "name",
        "childCompany",
        "client",
        "clientLocation",
        "latitude",
        "longitude",
        "radius",
        "ot",
        "address",
        "zipcode",
        "status",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "SampleWorkplaceCsv"); // download as 'xxxx.csv` file
    }
  );
};

const ShiftSampleexporter = () => {
  const records = [
    {
      name: "mornings",
      timeOfReporting: "10:00:00",
      inTime: "10:00:00",
      outTime: "10:00:00",
      workingDays: "5",
      workingHours: "10",
      lunchBreak: "1",
      workplace: "workplace",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record;
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "name",
        "timeOfReporting",
        "inTime",
        "outTime",
        "workingDays",
        "workingHours",
        "lunchBreak",
        "workplace",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "SampleShiftCsv"); // download as 'xxxx.csv` file
    }
  );
};

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      address,
      city,
      company,
      country,
      createdAt,
      createdBy,
      id,
      isActive,
      isDeleted,
      lat,
      lng,
      name,
      state,
      updatedAt,
      updatedBy,
      zipcode,
      __v,
      _id,
      radius,
      clientId,
      clientLocationId,
      ot,
      dutyHrs,
      workingDays,
      timeOfReporting,
      attendanceCycle,
      noOfAllotments,
      childCompanyId,
      shifts,
      regionId,
      zoneId,
      noOfShifts,
      ...dataToExport
    } = record; // omit fields
    dataToExport["Name"] = name;
    dataToExport["Client"] = clientId && clientId.name;
    dataToExport["Client Location"] = clientLocationId && clientLocationId.name;
    dataToExport["Chile Company"] = childCompanyId && childCompanyId.name;
    dataToExport["OT"] = ot ? "Yes" : "No"; // add a field
    dataToExport["Address"] = address;
    dataToExport["Zip code"] = zipcode;
    dataToExport["Latitude"] = lat;
    dataToExport["Longitude"] = lng;
    dataToExport["Radius"] = radius;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Status"] = isActive ? "Active" : "Inactive"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Workspace Report"); // download as 'xxxx.csv` file
    }
  );
};

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);
const WorkplaceList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Workplaces
        </h2>
      ) : null}
      <List
        className="list-view2"
        undoable={false}
        exporter={exporter}
        {...props}
        filters={
          isSmall ? (
            <WorkplaceFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <WorkplaceFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        // aside={<WorkplaceListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <DepartmentLinkField />
            {user.type === "super" ? (
              <TextField label="Company" source="company.name" />
            ) : null}
            <TextField source="lat" label="Latitude" />
            <TextField source="lng" label="Longitude" />
            {/* <TextField source="noOfAllotments" label="No Of Allotments" /> */}
            <FunctionField
              label="No Of Allotments"
              sortBy="noOfAllotments"
              // sortByOrder="DESC"
              render={(record: any) => (
                <EmployeeAllotment
                  type="list"
                  record={record}
                ></EmployeeAllotment>
              )}
            />
            <TextField source="noOfShifts" label="No Of Shifts" />
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField source="isActive" label="label.status" />
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default WorkplaceList;
