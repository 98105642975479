import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  Button,
  useNotify,
  SelectInput,
  SimpleForm,
  useDataProvider,
  required,
  FormDataConsumer,
  LinearProgress,
  useRefresh,
  AutocompleteArrayInput,
} from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  useMediaQuery,
  Theme,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WorkplaceIdSelectInput from "../components/WorkplaceIdSelectInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import WorkplaceShiftSelectInput from "../components/WorkplaceShiftSelectInput";
import SearchableEmployeeSelectInput from "../components/SearchableEmployeeSelectInput";
import DialogueBox from "../components/ConfirmDialogueBox";
import { toastError, toastInfo } from "../components/toast";
import { useSearchApiHook } from "../hooks/useSearchApiHook";
import MultiSelectShiftInput from "../components/MultiSelectShiftInput";
import { SectionTitle } from "../helperValidate";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  shiftDiv: { display: "inline-block", marginLeft: 32, width: "54%" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: 25 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
});

const EmployeeAllotment: FC<FieldProps> = ({
  updateAllotment,
  record,
  type,
  childCompany,
}) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [allotments, setAllotments] = useState([] as any);
  const [workplaceAllocate, setWorkplaceAllocate] = useState(Array);
  const [objShiftDataDropDown, setObjShiftDataDropDown] = useState(Array);
  const [objCompany, setObjCompany] = useState({});
  const [objEmployee, setObjEmployee] = useState({});
  const [objShift, setObjShift] = useState({});
  const [objMultiShift, setObjMultiShift] = useState({});
  const [noOfAllotments, setNoOfAllotments] = useState(0);
  const [isLoad, setLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [allotmentDialog, setAllotmentDialog] = useState({});
  const [multiShiftChoice, setMultiShiftChoice] = useState([]);

  const user = helperFunc?.getUserData();

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, allotment: any) => {
    setShowDialog(true);
    setIndex(index);
    setAllotmentDialog(allotment);
  };

  useEffect(() => {
    dataProvider
      .getSearchList("workplaces/workplace_shift_search", {
        workplaceId: record?._id,
      })
      .then((response: any) => {
        setMultiShiftChoice(response?.data);
      });
  }, [record?._id]);

  const handleClick = () => {
    setOpen(true);
    if (record && record.id) {
      let url;
      if (type && type === "employee") {
        url = `employees/get_allocate_workplace/${record ? record.id : ""}`;
      } else {
        url = `workplaces/getallotmentdata/${record ? record.id : ""}`;
      }

      dataProvider.callCustom(url).then((response: any) => {
        if (
          response &&
          response.data &&
          response.data.allotments &&
          type &&
          type !== "employee"
        ) {
          setAllotments(response.data.allotments);
          setNoOfAllotments(response?.data?.item?.noOfAllotments);
        }
        // setWorkplaceAllocate

        if (
          response &&
          response.data &&
          response.data.list &&
          type &&
          type === "employee"
        ) {
          const workplaceAllocate = response.data.list.map((item: any) => {
            return {
              workplace: { id: item._id, name: item.name },
              client: item.clientId,
              clientLocation: item.clientLocationId,
              allotmentsId: item?.allotments._id,
              shifts: item?.shifts,
              allotedShifts: item.allotments.allottedShifts,
            };
          });
          setWorkplaceAllocate(workplaceAllocate);

          setNoOfAllotments(response?.data?.count);
        }
      });
    }
  };
  const handleConfirm = (record: any) => {
    if (type && type === "employee") {
      setOpen(false);
    } else {
      if (allotments) {
        setLoader(true);
        record.allotments = allotments;
        const resource = "workplaces";
        const id = record.id;
        dataProvider
          .update(resource, { data: { allotments: record.allotments }, id: id })
          .then((response: any) => {
            if (response && response.data) {
              setLoader(false);
              response.data.success = null;
              handleDialogClose();
              if (updateAllotment) {
                updateAllotment(allotments);
              }
              toastInfo("Employee updated successfully!");
              refresh();
            }
          })
          .catch((err: any) => {
            toastError(err.message);
            return;
          });
      } else {
        toastError("No Allotments Found");
      }
    }
  };
  const removeAllotment = (idx: any) => {
    if (type && type === "employee") {
      let rData: any;
      rData = workplaceAllocate.filter((s: any, sIdx: any) => idx === sIdx);
      // setWorkplaceAllocate(workplaceAllocate.filter((s: any, sIdx: any) => idx === sIdx));
      let url;
      if (rData && rData.length > 0) {
        url = `employees/remove_allocate_workplace/${rData[0].workplace.id}`;

        dataProvider
          .callCustom(url, { allotmentsId: rData[0].allotmentsId })
          .then((response: any) => {
            toastInfo("Workplace removed successfully!");
            handleClick();
          })
          .catch((err: any) => {
            setLoader(false);
            toastError(err.message);
            return;
          });
      }
      // workplaceAllocate
    } else {
      setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
    }
  };
  const updateShift = (data: any, ind: any) => {
    let shiftData: any;

    objShiftDataDropDown.map((item: any) => {
      if (item._id === data.value) {
        shiftData = item;
      }
    });

    const allotmentData = allotments.map((item: any, index: any) => {
      if (index === ind) {
        item.shiftId = shiftData;
        return item;
      } else {
        return item;
      }
    });
    setAllotments(allotmentData);
  };
  const updateShiftData = (data: any, type: any) => {
    setObjShiftDataDropDown(data);
  };
  const dataManage = (data: any, type: any) => {
    if (type === "childCompany") {
      setObjCompany(data);
    } else if (type === "employee") {
      setObjEmployee(data);
    } else if (type === "shiftId") {
      setObjShift(data);
    } else if (type === "shifts") {
      setObjMultiShift(data);
    } else {
      setEmptyValue(false);
    }
  };

  const updateMultiShift = (data: any, ind: any) => {
    const shiftArr = [] as any;
    data.map((id: any) => {
      multiShiftChoice.map((ele: any, index: any) => {
        if (ele._id === id) {
          shiftArr.push({ id: ele._id, name: ele.name });
        }
      });
    });

    const allotmentData = allotments.map((item: any, index: any) => {
      if (index === ind) {
        item.allottedShifts = shiftArr;
        return item;
      } else {
        return item;
      }
    });

    setAllotments(allotmentData);
  };

  useEffect(() => {
    if (record && record.allotments) {
      setAllotments(record.allotments);
    }
    if (record && record.noOfAllotments) {
      setNoOfAllotments(record.noOfAllotments);
    }
  }, [record]);
  const handleDialogClose = () => {
    setOpen(false);
    setAllotments({} as any);
  };

  const addAllotment = (data: any) => {
    if (
      type &&
      type !== "employee" &&
      data &&
      (data.childCompanyId === "" || data.childCompanyId === undefined)
    ) {
      toastError("Please Select Company");
      return;
    } else if (
      type &&
      type !== "employee" &&
      data &&
      (data.employee === "" || data.employee === undefined)
    ) {
      toastError("Please Select Employee");
      return;
    } else if (
      type &&
      type === "employee" &&
      user?.isClientConfiguration &&
      data &&
      (data.clientId === "" || data.clientId === undefined)
    ) {
      toastError("Please Select Client");
      return;
    } else if (
      type &&
      type === "employee" &&
      user?.isClientConfiguration &&
      data &&
      (data.clientLocationId === "" || data.clientLocationId === undefined)
    ) {
      toastError("Please Select Client Location");
      return;
    } else if (
      type &&
      type === "employee" &&
      data &&
      (data.workplaceId === "" || data.workplaceId === undefined)
    ) {
      toastError("Please Select Workplace");
      return;
    } else if (
      // type &&
      // type === "employee" &&
      data &&
      (data.shiftId === "" || data.shiftId === undefined)
    ) {
      toastError("Please select Shift");
      return;
    } else {
      let duplicate = false;
      if (type && type === "employee") {
        workplaceAllocate.filter((s: any, sIdx: any) => {
          if (s.workplace.id === data.workplaceId) {
            duplicate = true;
          }
        });

        if (duplicate) {
          toastError("Duplicate Entry is not allowed");
        } else {
          let params;

          user.isClientConfiguration
            ? (params = {
                employeeId: record?._id,
                childCompanyId: childCompany ? childCompany : null,
                workplaceId: data?.workplaceId,
                clientId: data?.clientId,
                clientLocationId: data?.clientLocationId,
                shiftId: data?.shiftId,
                allottedShifts: objMultiShift,
              })
            : (params = {
                employeeId: record?._id,
                childCompanyId: childCompany ? childCompany : null,
                workplaceId: data?.workplaceId,
                shiftId: data?.shiftId,
                allottedShifts: objMultiShift,
              });

          const url = "employees/employee_workplace_allocate";
          dataProvider
            .callCustom(url, params)
            .then((response: any) => {
              toastInfo("Workplace Allocated successfully!");
              handleClick();
            })
            .catch((err: any) => {
              setLoader(false);
              toastError(err.message);
              return;
            });
        }

        data.allottedShifts = [];
        setEmptyValue(true);
        setTimeout(function () {
          setEmptyValue(false);
        }, 1000);
      } else {
        allotments.filter((s: any, sIdx: any) => {
          if (
            s.companyId._id === data.childCompanyId &&
            s.employeeId._id === data.employee
          ) {
            duplicate = true;
          }
        });

        if (duplicate) {
          toastError("Duplicate Entry is not allowed");
        } else {
          setAllotments([
            ...allotments,
            {
              companyId: objCompany,
              employeeId: objEmployee,
              shiftId: objShift,
            },
          ]);
        }
        setObjCompany({});
        setObjEmployee({});
        setObjShift({});

        setEmptyValue(true);
        // data.reset()
        setTimeout(function () {
          setEmptyValue(false);
        }, 1000);
      }
    }
  };

  return record ? (
    <div className={classes.root}>
      {type && type === "employee" ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.allotmentMainBtn}
          label={`Allot Workplace (${
            allotments && allotments.length > 0
              ? allotments.length
              : noOfAllotments
          })`}
          onClick={handleClick}
        ></Button>
      ) : null}
      {(type && type === "list") || isXsmall ? (
        <span className={classes.noOfAllotments} onClick={handleClick}>
          {" "}
          {`Allocate Employees (${
            allotments && allotments.length > 0
              ? allotments.length
              : noOfAllotments
          })`}
        </span>
      ) : null}
      {type && type === "workplace" ? (
        <Button
          variant="contained"
          color="primary"
          label={`Allot Employees (${
            allotments && allotments.length > 0
              ? allotments.length
              : noOfAllotments
          })`}
          onClick={handleClick}
        ></Button>
      ) : null}
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Employee Allotments"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        {type && type !== "employee" ? (
          <DialogTitle>Employee Allotments for {record.name}</DialogTitle>
        ) : null}
        {type && type === "employee" ? (
          <DialogTitle>Workplace Allotments for {record.name}</DialogTitle>
        ) : null}

        <DialogContent>
          <SimpleForm
            onSubmit={() => handleConfirm(record)}
            validate={() => true}
            toolbar={null}
          >
            {type && type === "employee" && user?.isClientConfiguration ? (
              <FormDataConsumer
                formClassName={
                  isXsmall
                    ? classes.isSmallCompany
                    : isSmall
                    ? classes.isSmallCompany
                    : classes.company
                }
              >
                {({ formData }: { formData: any }) => (
                  <ClientMasterSelectInput
                    emptyValue={{ emptyValue }}
                    filter={{ isActive: true }}
                    labelName="Client *"
                  ></ClientMasterSelectInput>
                )}
              </FormDataConsumer>
            ) : null}
            {type && type === "employee" && user.isClientConfiguration ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.employee}
              >
                {({ formData }: { formData: any }) => (
                  <ClientLocationSelectInput
                    emptyValue={{ emptyValue }}
                    dataManage={dataManage}
                    filter={{
                      clientId:
                        formData && formData.clientId
                          ? formData.clientId
                          : null,
                      isActive: true,
                    }}
                    labelName="Client Location *"
                  ></ClientLocationSelectInput>
                )}
              </FormDataConsumer>
            ) : null}

            {type && type === "employee" ? (
              <FormDataConsumer
                formClassName={
                  isXsmall
                    ? null
                    : user.isClientConfiguration
                    ? classes.employee
                    : classes.company
                }
              >
                {({ formData }: { formData: any }) => (
                  <WorkplaceIdSelectInput
                    filter={{
                      clientLocationId: formData.clientLocationId,
                      isActive: true,
                    }}
                    emptyValue={{ emptyValue }}
                    labelName="Workplaces *"
                  ></WorkplaceIdSelectInput>
                )}
              </FormDataConsumer>
            ) : null}
            {type && type === "employee" ? <SectionTitle label="" /> : null}
            {type && type === "employee" ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.company}
              >
                {({ formData }: { formData: any }) => (
                  <WorkplaceShiftSelectInput
                    filter={{ workplaceId: formData.workplaceId }}
                    dataManage={dataManage}
                    emptyValue={{ emptyValue }}
                    labelName={"Shift *"}
                  ></WorkplaceShiftSelectInput>
                )}
              </FormDataConsumer>
            ) : null}
            {type && type === "employee" ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.shiftDiv}
              >
                {({ formData }: { formData: any }) => (
                  <MultiSelectShiftInput
                    filter={{ workplaceId: formData.workplaceId }}
                    dataManage={dataManage}
                    emptyValue={{ emptyValue }}
                  ></MultiSelectShiftInput>
                )}
              </FormDataConsumer>
            ) : null}
            {type && type !== "employee" ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.company}
              >
                {({ formData }: { formData: any }) => {
                  return (
                    <ChildCompanySelectInput
                      filter={{ isActive: true }}
                      dataManage={dataManage}
                      emptyValue={{ emptyValue }}
                      labelName="Company (Subsidiary) *"
                      // validate={companyValidate}
                      textWrap={true}
                    ></ChildCompanySelectInput>
                  );
                }}
              </FormDataConsumer>
            ) : null}
            {type && type !== "employee" ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.employee}
              >
                {({ formData }: { formData: any }) => (
                  <SearchableEmployeeSelectInput
                    filter={{
                      isActive: true,
                      childCompanyId: formData.childCompanyId,
                    }}
                    dataManage={dataManage}
                    emptyValue={{ emptyValue }}
                    // validate={employeeValidate}
                  ></SearchableEmployeeSelectInput>
                )}
              </FormDataConsumer>
            ) : null}
            {type && type !== "employee" ? (
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.employee}
              >
                {({ formData }: { formData: any }) => (
                  <WorkplaceShiftSelectInput
                    filter={{ workplaceId: record.id }}
                    dataManage={dataManage}
                    emptyValue={{ emptyValue }}
                    updateShiftData={updateShiftData}
                    labelName={"Shift *"}
                    // validate={shiftValidate}
                  ></WorkplaceShiftSelectInput>
                )}
              </FormDataConsumer>
            ) : null}
            <div className="inline">
              <FormDataConsumer
                formClassName={isXsmall ? null : classes.employee}
              >
                {({ formData }: { formData: any }) => (
                  <MaterialButton
                    onClick={() => addAllotment(formData)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="button"
                    className={isXsmall ? undefined : classes.allotmentBtn}
                  >
                    Allocate
                  </MaterialButton>
                )}
              </FormDataConsumer>
            </div>
            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {type &&
                    type === "employee" &&
                    user?.isClientConfiguration ? (
                      <TableCell className={classes.tableHead}>
                        Client Name
                      </TableCell>
                    ) : null}
                    {type &&
                    type === "employee" &&
                    user?.isClientConfiguration ? (
                      <TableCell className={classes.tableHead}>
                        Client Location
                      </TableCell>
                    ) : null}
                    {type && type === "employee" ? (
                      <TableCell className={classes.tableHead}>
                        Workplace
                      </TableCell>
                    ) : null}
                    {type && type === "employee" ? (
                      <TableCell className={classes.tableHead}>Shift</TableCell>
                    ) : null}
                    {type && type !== "employee" ? (
                      <TableCell className={classes.tableHead}>
                        Company (Subsidiary)
                      </TableCell>
                    ) : null}
                    {type && type !== "employee" ? (
                      <TableCell className={classes.tableHead}>
                        Employee Name
                      </TableCell>
                    ) : null}
                    {type && type !== "employee" ? (
                      <TableCell className={classes.tableHead}>Shift</TableCell>
                    ) : null}
                    <TableCell className={classes.tableHead}>Shifts</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {type &&
                  type !== "employee" &&
                  allotments &&
                  allotments.length > 0
                    ? allotments.map((allotment: any, index: number) => {
                        const updatedShift = allotment?.allottedShifts?.map(
                          (shift: any) => {
                            if (shift.id) {
                              return shift.id;
                            } else {
                              return shift;
                            }
                          }
                        );

                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.companyId
                                ? allotment.companyId.name
                                : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.employeeId
                                ? allotment.employeeId.name
                                : ""}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <SelectInput
                                key={index + 1}
                                label=""
                                source={"shiftId" + index}
                                defaultValue={allotment.shiftId._id}
                                disabled={
                                  record && record.mode === "auto"
                                    ? true
                                    : false
                                }
                                choices={objShiftDataDropDown}
                                onChange={(value: any) =>
                                  updateShift(value.target, index)
                                }
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <AutocompleteArrayInput
                                fullWidth
                                resettable
                                label={"Shift(s)"}
                                source={"allottedShifts" + index}
                                choices={multiShiftChoice}
                                onChange={(value: any, name: any) =>
                                  updateMultiShift(value, index)
                                }
                                defaultValue={updatedShift}
                              />
                              {/* <MultiSelectShiftInput
                                filter={{ workplaceId: record._id }}
                                dataManage={dataManage}
                                defaultValue={updatedShift}
                                source={"allottedShifts" + index}
                                // emptyValue={{ emptyValue }}
                              ></MultiSelectShiftInput> */}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove{" "}
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                  {type &&
                  type === "employee" &&
                  workplaceAllocate &&
                  workplaceAllocate.length > 0
                    ? workplaceAllocate.map((allotment: any, index: number) => {
                        return (
                          <TableRow key={index + 1}>
                            {user?.isClientConfiguration && (
                              <TableCell
                                className={classes.tableValue}
                                align="left"
                              >
                                {allotment.client ? allotment.client.name : ""}
                              </TableCell>
                            )}
                            {user?.isClientConfiguration && (
                              <TableCell
                                className={classes.tableValue}
                                align="left"
                              >
                                {allotment.clientLocation
                                  ? allotment.clientLocation.name
                                  : ""}
                              </TableCell>
                            )}

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.workplace
                                ? allotment.workplace.name
                                : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.shifts ? allotment.shifts.name : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment?.allotedShifts?.map((shift: any) => {
                                return shift.name ? (
                                  <div className="chip-display">
                                    {shift.name}
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                  {allotments &&
                  allotments.length === 0 &&
                  workplaceAllocate &&
                  workplaceAllocate.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={6}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(index)}
              itemName={allotmentDialog}
            />
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              // disabled={!isValidate}
              type="submit"
              label="Save"
              onClick={() => handleConfirm(record)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Cancel"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default EmployeeAllotment;
