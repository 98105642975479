import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
  DateInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import helperFunc from "../helperFuncs";
import { LeaveTypes, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { leaveTypestyles } from "../helperStyle";
// import { DateInput } from "react-admin-date-inputs";
import ClientMasterArraySelectInput from "../components/ClientMasterArraySelectInput";
import StateArraySelectInput from "../components/StateArraySelectInput";
import MultiSelectChildCompany from "../components/MultiSelectChildCompany";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export const validatePasswords = ({
  assessmentYearStartOn,
  assessmentEndOn,
}: {
  assessmentYearStartOn: any;
  assessmentEndOn: any;
}) => {
  const errors = {} as any;
  var date = new Date(assessmentYearStartOn);
  date.setDate(date.getDate() + 365);
  if (assessmentEndOn && assessmentEndOn! < date) {
    errors.assessmentEndOn =
      "Assessment Year End Date should be greater then 1 year";
  }

  return errors;
};

const useStyles = makeStyles(leaveTypestyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const user = helperFunc.getUserData();

  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/weekOffList/create"
          ? "Update Sunday Off"
          : "Add Sunday Off"}
      </h2>
      <SimpleForm {...props} validate={validatePasswords}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="weekOffList"
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Sunday Name"
          />
          <DateInput
            label="label.date"
            source="date"
            resource="weekOffList"
            options={{ format: helperFunc.datePickerFormat(), clearable: true }}
            formClassName={isXsmall ? classes.name : classes.code}
            validate={requiredValidate}
            inputProps={{
              min: "1900-08-10",
              max: "3000-08-20",
            }}
          />
          {/* clientMasters */}
          {user.type === "super" || user.type === "company" ? (
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <MultiSelectChildCompany
                  filter={{ isActive: true, company: formData.company }}
                ></MultiSelectChildCompany>
              )}
            </FormDataConsumer>
          ) : null}

          <FormDataConsumer>
            {({ formData }: { formData: any }) => (
              <ClientMasterArraySelectInput
                filter={{
                  isActive: true,
                  company: formData.company,
                  childCompanyIds: formData.childCompanyIds,
                }}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }: { formData: any }) => (
              <StateArraySelectInput
                filter={{ isActive: true, company: formData.company }}
              />
            )}
          </FormDataConsumer>
          <BooleanInput
            label="label.status"
            source="isActive"
            resource="weekOffList"
            defaultValue={true}
            formClassName={isXsmall ? classes.name : classes.code}
          />
        </div>
      </SimpleForm>
    </React.Fragment>
  ) : (
    <SimpleForm {...props} validate={validatePasswords}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        resource="weekOffList"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Sunday Name"
      />

      <DateInput
        label="label.date"
        source="date"
        resource="weekOffList"
        options={{ format: helperFunc.datePickerFormat(), clearable: true }}
        formClassName={isXsmall ? classes.name : classes.code}
        validate={requiredValidate}
        inputProps={{
          min: "1900-08-10",
          max: "3000-08-20",
        }}
      />
      {user.type === "super" || user.type === "company" ? (
        <FormDataConsumer>
          {({ formData }: { formData: any }) => (
            <MultiSelectChildCompany
              filter={{ isActive: true, company: formData.company }}
            ></MultiSelectChildCompany>
          )}
        </FormDataConsumer>
      ) : null}
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <ClientMasterArraySelectInput
            filter={{
              isActive: true,
              company: formData.company,
              childCompanyIds: formData.childCompanyIds,
            }}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <StateArraySelectInput
            filter={{ isActive: true, company: formData.company }}
          />
        )}
      </FormDataConsumer>
      <SectionTitle label="" />
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="weekOffList"
        defaultValue={true}
        formClassName={classes.name}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [required(), maxLength(20)];
const LeaveTitle: FC<FieldProps<LeaveTypes>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
