import * as React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  BooleanInput,
} from "react-admin";
import { regex } from "ra-core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { maxLength } from "../TurnoutAdmin";
import StateSelectInput from "../components/StateSelectInput";
import { cityStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(cityStyle);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props && props.location && props.location.pathname !== "/cities/create"
          ? "Update City"
          : "Add City"}
      </h2>
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            return (
              <StateSelectInput
                filter={{ isActive: true, company: formData.company }}
                validate={requiredValidate}
              ></StateSelectInput>
            );
          }}
        </FormDataConsumer>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            source="name"
            formClassName={classes.name}
            validate={nameValidation}
            label="City Name"
          />
          <TextInput
            source="code"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidation}
            format={helperFunc.codeFormatter}
          />
        </div>
        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  ) : (
    <SimpleForm {...props}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => {
          return (
            <StateSelectInput
              filter={{ isActive: true, company: formData.company }}
              validate={requiredValidate}
            ></StateSelectInput>
          );
        }}
      </FormDataConsumer>
      <TextInput
        autoFocus
        source="name"
        formClassName={classes.name}
        validate={nameValidation}
        label="City Name"
      />
      <TextInput
        source="code"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidation}
        format={helperFunc.codeFormatter}
      />
      <BooleanInput
        source="isActive"
        label="label.status"
        defaultValue={true}
      />
    </SimpleForm>
  );
};

const nameValidation = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidation = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const requiredValidate = [required()];

export default CommonForm;
