import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import authProvider from "../authProvider";
import { Notification } from "react-admin";
import { useTranslate as authTrans } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";
import { lightTheme } from "./themes";
import { toastError } from "../components/toast";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(/auth-bg.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 400,
    marginTop: "4em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  otp?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Reset = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  // const notify = useNotify();
  const location = useLocation<{ nextPathname: string } | null>();
  const resetPassword = authProvider.resetPassword;
  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    resetPassword(
      auth,
      location.state ? location.state.nextPathname : "/"
    ).catch((error: Error) => {
      setLoading(false);
      toastError(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message
      );
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.otp) {
      errors.otp = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img src="/logo.png" alt="" />
                {/* <Avatar className={classes.icon}>
                                    <LockIcon />
                                    
                                </Avatar> */}
              </div>
              {/* <div className={classes.hint}>
                                Hint: demo / demo
                            </div> */}
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="otp"
                    // @ts-ignore
                    component={renderInput}
                    label="OTP"
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("ra.auth.password")}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {/* {translate("ra.auth.su")} */}
                  Submit
                </Button>
              </CardActions>
              {/* <Link to={`/forgotPassword`}>Forgot password</Link> */}
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Reset.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const ResetWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Reset {...props} />
  </ThemeProvider>
);

export default ResetWithTheme;
