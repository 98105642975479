import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastInfo = (message: any) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  toast.clearWaitingQueue();
};
export const toastError = (message: any) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  toast.clearWaitingQueue();
};
