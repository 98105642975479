import * as React from "react";
import { FC, useState, useCallback } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import {
  Typography,
  useMediaQuery,
  Theme,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import helperFunc from "./../helperFuncs";
import DialogueBox from "./DialogueBox";
interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    display: "flex",
    width: "100%",
    height: "300px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  mobileCard: {
    display: "flex",
    width: "100%",
    height: "180px",

    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  title: {
    padding: "20px",
    fontSize: "1.5rem",
  },
  mobTitle: {
    padding: "10px",
    fontSize: "1rem",
  },
  closeButton: { position: "absolute", top: 5, right: 0 },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  myPopup: { display: "inline-block" },
});

const ChartEmployees: FC<Props> = (data) => {
  const [activeSlice, setActiveSlice] = useState("");
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const user = helperFunc.getUserData();
  let currentUser = user !== null && user.type !== null ? user.type : "none";
  if (user !== null && user.type !== null && user.type === "employee") {
    if (user.employeeType !== null) {
      currentUser = user.employeeType.code;
    }
  }
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data.data || {};
  const data2Obj: any = data.data2 || {};

  const keys = Object.keys(dataObj);
  const values = Object.values(dataObj);
  const dataArr: any = [];
  keys.forEach((key, index) => {
    if (key === "activeUser") {
      key = "Active User";
    } else if (key === "inActiveUser") {
      key = "In Active User";
    }
    dataArr.push({
      name: key,
      value: values[index],
    });
  });
  const COLORS = ["#D63330", "#1E77B4", "#FFBB28", "#FF8042"];
  const headerArr = [
    { label: "Name", key: "name" },
    { label: "Code", key: "employeeCode" },
    { label: "Email", key: "email" },
  ];
  const object2: any = {
    "Active User": "activeUser",
    "In Active User": "inActiveUser",
  };

  let dataBySlice = data2Obj[object2[activeSlice]];

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = useCallback((data: any, index: any) => {
    setOpen(true);
    setActiveSlice(data.name);
    // setPage(0);
    handleDefaultPage(0);
    handleRowsPerPage(10);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <div
      className={
        isSmall
          ? classes.mobileCard
          : isXSmall
          ? classes.mobileCard
          : classes.card
      }
    >
      <Typography
        component={isSmall ? "h5" : "h2"}
        className={isSmall ? classes.mobTitle : classes.title}
      >
        {translate("pos.dashboard.chart_employees")}
      </Typography>

      {helperFunc.getUserData().type === "company" ? (
        <DialogueBox
          dialogueData={dataBySlice}
          open={open}
          handleClose={handleClose}
          activeSlice={activeSlice}
          headerArr={headerArr}
          page={page}
          handleDefaultPage={handleDefaultPage}
          rowsPerPage={rowsPerPage}
          handleRowsPerPage={handleRowsPerPage}
          exportData={[]}
          isExport={false}
        />
      ) : null}
      {data.isLoading ? (
        <span
          style={{
            height: "300px",
            width: "800px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <ResponsiveContainer
          width={isXSmall ? 200 : currentUser === "super" ? 400 : 800}
        >
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={dataArr}
              fill="#8884d8"
              label
              onClick={handleClickOpen}
            >
              {dataArr.map((entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ChartEmployees;
