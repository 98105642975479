/**********************************************************************************************************************
 * Copyright (C) 2020 RyDOT Infotech Pvt. Ltd - All Rights Reserved                                                   *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of RyDOT Infotech Pvt. Ltd and its partners,         *
 * if any. The intellectual and technical concepts contained herein are proprietary to RyDOT Infotech Pvt. Ltd and its*
 * partners and may be covered by their and Foreign Patents, patents in process, and are protected by trade secret or *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from RyDOT Infotech Pvt. Ltd.                                                 *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { getMonth } from "date-fns";
import moment from "moment";
const FileSaver = require("file-saver");
const axios = require("axios");
const helperFunc = {
  getWhiteLabeling: function () {
    const user = JSON.parse(localStorage.getItem("userdata"));
    const defaultData = {
      header_text: "Turnout",
      header_title:
        "Face Recognition Based Attendance, Visitor management and Access Control System",
      logo: "/turnout-white.png",
      favicon: "white-favicon.png",
    };
    if (!!user && user.type && user.type === "super") {
      return defaultData;
    } else {
      const clientSettings = JSON.parse(localStorage.getItem("clientSettings"));
      if (clientSettings) {
        const clientData = defaultData;
        clientData["header_text"] = clientSettings["header_text"]
          ? clientSettings["header_text"]
          : clientData["header_text"];
        clientData["header_title"] = clientSettings["header_title"]
          ? clientSettings["header_title"]
          : clientData["header_title"];
        clientData["footer_text"] = clientSettings["footer_text"]
          ? clientSettings["footer_text"]
          : clientData["footer_text"];
        clientData["logo"] = clientSettings["logo"]
          ? clientSettings["logo"]
          : clientData["logo"];
        clientData["turnoutDevice"] = clientSettings["turnoutDevice"]
          ? clientSettings["turnoutDevice"]
          : clientData["turnoutDevice"];
        return clientData;
      }
    }
    return defaultData;
  },
  updateLocalStorage: function (objData) {
    let clientSettings = this.getWhiteLabeling();
    clientSettings = { ...clientSettings, ...objData };
    localStorage.setItem("clientSettings", JSON.stringify(clientSettings));
    this.setWhiteLabeling(clientSettings);
  },
  setWhiteLabeling: function (objData) {
    setTimeout(() => {
      // setup page favicon
      // ReactDOM.render(
      // 	<div>
      // 		<Favicon url={"" + objData.logo} />
      // 	</div>
      // 	, document.getElementById('root'));
      //Setup page title
      document.title = objData.header_text; //Document Title
      const footerLabeling = document.getElementById("footer-branding");
      if (!!objData.footer_text) {
        if (footerLabeling && footerLabeling.innerHTML) {
          footerLabeling.innerHTML = objData.footer_text; //Footer
        }
      }
      document.getElementById("favicon").href = objData.logo
        ? objData.logo
        : "favicon.ico";
      objData &&
        objData.logo &&
        document &&
        document.getElementById("branding-logo") &&
        document.getElementById("branding-logo").setAttribute &&
        document
          .getElementById("branding-logo")
          .setAttribute("src", objData.logo); //Branding Logo
      objData &&
        objData.header_title &&
        document &&
        document.getElementById("branding-logo") &&
        document.getElementById("branding-logo").setAttribute &&
        document
          .getElementById("branding-logo")
          .setAttribute("title", objData.header_title); //Branding Logo
      objData &&
        objData.header_title &&
        document &&
        document.getElementById("branding-logo") &&
        document.getElementById("branding-logo").setAttribute &&
        document
          .getElementById("branding-logo")
          .setAttribute("alt", objData.header_title); //Branding Logo
    }, 100);
  },
  clearFooter: function () {
    const footerLabeling = document.getElementById("footer-branding");
    if (footerLabeling && footerLabeling.innerHTML) {
      footerLabeling.innerHTML = ""; //Footer
    }
    // document.title = "Face Recognition Based Attendance And Smart HRM System" //"Turnout Management System";
    document.getElementById("favicon").href = "favicon.ico"; //FavIcon
    document.getElementById("branding-logo") &&
      document.getElementById("branding-logo").setAttribute("src", ""); //Branding Logo
    document.getElementById("branding-logo") &&
      document.getElementById("branding-logo").setAttribute("title", ""); //Branding Logo
    document.getElementById("branding-logo") &&
      document.getElementById("branding-logo").setAttribute("alt", ""); //Branding Logo
  },
  getUserData: function () {
    const user = JSON.parse(localStorage.getItem("userdata"));
    return user;
  },

  formatDate: function (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  },

  attendanceformatDate: function (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  },

  isoToDate: function (time) {
    let gmtData = time.substring(0, 19);
    var s = new Date(gmtData).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
    return s;
  },

  addhours: function (time, hour, min) {
    var dt = new Date(time);
    dt.setHours(dt.getHours() + hour);
    dt.setMinutes(dt.getMinutes() + min);
    return dt.toISOString();
  },

  addDays: function (days, compare) {
    // Add days to given date
    var date = new Date();
    date.setDate(date.getDate() - days);

    // if (compare) {
    // var date = new Date();
    // var time = moment(date).format('YYYY-MM-DD');
    // var res = time.split("-");
    // var year = parseInt(res[0]);
    // var month = parseInt(res[1]);
    // var date = parseInt(res[2]);
    // return new Date(year, month - 1, date, 0, 0, 0, 0);
    // } else {

    return moment(date).format("YYYY-MM-DD");
    // }
  },

  addDaysFilter: function (days, compare) {
    // Add days to given date
    var date = new Date();
    date.setDate(date.getDate() - days);

    // if (compare) {
    // var date = new Date();
    // var time = moment(date).format('YYYY-MM-DD');
    // var res = time.split("-");
    // var year = parseInt(res[0]);
    // var month = parseInt(res[1]);
    // var date = parseInt(res[2]);
    // return new Date(year, month - 1, date, 0, 0, 0, 0);
    // } else {
    return moment.utc(moment(date).utc()).format();
    //  moment(date).format("YYYY-MM-DD");
    // }
  },

  subtractDays: function (days) {
    // Add days to given date
    var date = new Date();
    date.setDate(date.getDate() - days);
    return moment(date).format("YYYY-MM-DD");
  },
  subtractDaysFilter: function (days) {
    // Add days to given date
    var date = new Date();
    date.setDate(date.getDate() - days);
    return moment.utc(moment(date).utc()).format();
  },

  validURL: function (str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  },

  secondsToHms: function (d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    h = h + "";
    m = m + "";

    h = h && h.length == 1 ? "0" + h : h;
    m = m && m.length == 1 ? "0" + m : m;

    if (h && h.length > 2) {
      return d;
    } else {
      return h + ":" + m;
    }
  },

  localDateAndTime: function (dateAndTime) {
    return (
      new Date(dateAndTime).toLocaleDateString() +
      "-" +
      new Date(dateAndTime).toLocaleTimeString()
    );
  },

  currentMonth: function () {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return monthNames[d.getMonth()];
  },
  createdAtDateFormat: function (date) {
    return moment(date).format("DD-MM-YYYY");
  },
  filterCreatedAt: function (createdAtTime) {
    var time = moment(createdAtTime).format("YYYY-MM-DD");
    var res = time.split("-");
    var year = parseInt(res[0]);
    var month = parseInt(res[1]);
    var date = parseInt(res[2]);
    var values = {
      time_from: new Date(year, month - 1, date, 0, 0, 0, 0),
      time_to: new Date(year, month - 1, date, 23, 59, 59, 999),
    };
    return values;
  },
  filterTimeFrom: function (timeFrom) {
    var time = moment(timeFrom).format("YYYY-MM-DD,HH:mm:ss");
    var res = time.split("-");
    var year = parseInt(res[0]);
    var month = parseInt(res[1]);
    var date = parseInt(res[2]);
    var values = {
      time_from: new Date(year, month - 1, date, 0, 0, 0, 0),
      time_to: new Date(year, month - 1, date, 23, 59, 59, 999),
    };
    return values;
  },

  selectedCompany: function () {
    let companyId = localStorage.getItem("companyId");
    return companyId;
  },
  selectedEmployee: function () {
    let employeeId = localStorage.getItem("employeeId");
    return employeeId;
  },

  getChildCompany: function () {
    const childCompany = localStorage.getItem("childCompany");
    return childCompany;
  },

  commonDateFormat: function (date, timeformat) {
    let dateFormat = localStorage.getItem("dateFormat");
    if (timeformat) {
      return moment(date).format(
        dateFormat
          ? dateFormat + " , " + timeformat
          : "DD/MM/YYYY" + " , " + timeformat
      );
    } else {
      return moment(date).format(dateFormat ? dateFormat : "DD/MM/YYYY");
    }
  },
  commonDayMonthFormat: function (date, timeformat) {
    let dateFormat = localStorage.getItem("dateFormat");
    if (timeformat) {
      return moment(date).format("DD/MM" + " , " + timeformat);
    } else {
      return moment(date).format("DD/MM");
    }
  },

  datePickerFormat: function (timeformat) {
    let pickerFormat = localStorage.getItem("pickerFormat");
    if (timeformat) {
      return pickerFormat
        ? pickerFormat + "," + timeformat
        : "dd/MM/yyyy" + "," + timeformat;
    } else {
      return pickerFormat ? pickerFormat : "dd/MM/yyyy";
    }
  },

  arrayToStr: function (array, byValue) {
    let tempArray = [];
    array &&
      array.length > 0 &&
      array.map((arrValue) => {
        tempArray.push(`( ${arrValue[byValue]} )`);
      });
    return tempArray.toString();
  },

  dateArray: function (newStartDate, newEndDate) {
    var dayInMiliSeconds = 1000 * 60 * 60 * 24;
    var startDate = new Date(newStartDate);
    var endDate = new Date(newEndDate);
    var dates = [];
    var diff = (endDate.getTime() - startDate.getTime()) / dayInMiliSeconds;
    for (var i = 0; i <= diff; i++) {
      dates.push(
        new Date(startDate.getTime() + dayInMiliSeconds * i)
          .toISOString()
          .slice(0, 10)
      );
    }
    return dates;
  },

  timeWithAmPm: function (date) {
    let isoDate = date;
    let result;
    if (isoDate === null || !isoDate) {
      return null;
    } else {
      let time = isoDate.match(/\d\d:\d\d/);
      if (time && time[0]) {
        let finalTime = time && time[0].split(":");
        var hours = finalTime[0]; // gives the value in 24 hours format
        // var AmOrPm = hours >= 12 ? 'pm' : 'am';
        // hours = (hours % 12) || 12;
        var minutes = finalTime[1];
        result = hours + ":" + minutes;
      } else {
        return date;
      }
    }

    return result;
  },

  timeToIsoTime: function (item, date) {
    item.isChanged = false;
    var time = moment(date).format("YYYY-MM-DD");
    var res = time.split("-");
    var year = parseInt(res[0]);
    var month = parseInt(res[1]);
    var newDate = parseInt(res[2]);
    let inTime = item[date + "_inTimesCopy"];
    let outTime = item[date + "_outTimesCopy"];

    if (inTime) {
      let res = inTime.split(":");
      let hour = parseInt(res[0]);
      let min = parseInt(res[1]);
      // let res1 = res[1].split(" ");
      // let amp = res1[1];
      // if (amp === "pm") {
      //     hour = hour + 12
      // }
      item[date + "_inTimes"] = new Date(
        year,
        month - 1,
        newDate,
        hour + 5,
        min + 30,
        0,
        0
      ).toISOString();
    }
    if (outTime) {
      let res = outTime.split(":");
      let hour = parseInt(res[0]);
      let min = parseInt(res[1]);
      // let res1 = res[1].split(" ");
      // let amp = res1[1];
      // if (amp === "pm") {
      //     hour = hour + 12
      // }
      item[date + "_outTimes"] = new Date(
        year,
        month - 1,
        newDate,
        hour + 5,
        min + 30,
        0,
        0
      ).toISOString();
    }
  },

  dateDifference: function (first, second) {
    if (first && second) {
      const date1 = new Date(first);
      const date2 = new Date(second);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
  },
  imageDownloader: function (serverUrl, imageName) {
    axios
      .get(serverUrl, {
        responseType: "arraybuffer",
        headers: {},
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/image",
        });
        FileSaver.saveAs(blob, imageName);
      });
  },

  hourAndMin: function (time) {
    const newTime = new Date(time);
    const hour = newTime.getHours() + "";
    const min =
      newTime.getMinutes() <= 9
        ? "0" + newTime.getMinutes()
        : newTime.getMinutes() + "";
    return hour + "." + min;
  },

  // const startOfMonth = (date: any) => {
  //     var today: any = date
  //     let mm = String(today.getMonth() + 1).padStart(2, '0')
  //     let yyyy = today.getFullYear();
  //     let firstDate = yyyy + '-' + mm + '-' + "01";
  //     return firstDate
  // }

  // const currentDate = () => {
  //     var today: any = new Date();
  //     var dd = String(today.getDate()).padStart(2, '0');
  //     var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //     var yyyy = today.getFullYear();
  //     return today = yyyy + '-' + mm + '-' + dd;
  // }

  lastDayOfMonth: function () {
    let today = new Date();
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return moment(lastDayOfMonth).format("MM-DD-YYYY");
  },
  startOfMonth: function (day, format) {
    var date = new Date();
    // var time = moment(
    //   new Date(date.getFullYear(), date.getMonth(), day)
    // ).format("YYYY-MM-DD");
    // var res = time.split("-");
    // var year = parseInt(res[0]);
    // var month = parseInt(res[1]);
    // var date = parseInt(res[2]);
    // let startDayOfMonth = new Date(
    //   time.getFullYear(),
    //   time.getMonth()
    // );
    // return moment(startDayOfMonth).format(format ? format : "YYYY-MM-DD");
    return moment(new Date(date.getFullYear(), date.getMonth(), day)).format(
      format ? format : "YYYY-MM-DD"
    );
  },

  lastDayOfMonthAccessment: function (selectedDate, format) {
    let today = new Date();
    let lastDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    );
    return moment(lastDayOfMonth).format("MM-DD-YYYY");
  },
  startOfMonthAccessment: function (selectedDate, format) {
    // var date = new Date();
    // var time = moment(new Date(date.getFullYear(), date.getMonth(), day)).format('YYYY-MM-DD')
    // var res = time.split("-");
    // var year = parseInt(res[0]);
    // var month = parseInt(res[1]);
    // var date = parseInt(res[2]);
    let startDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth()
    );
    return moment(startDayOfMonth).format(format ? format : "YYYY-MM-DD");
    // return moment(new Date(day.getFullYear(), day.getMonth(), day)).format(format ? format : 'YYYY-MM-DD')
  },

  getTimeOnly: function (date) {
    return moment(date).format("HH:mm:ss");
  },

  getUtcDate: function (time) {
    return moment.utc(moment(time).utc()).format();
  },
  getUtcStartOfMonthDate: function () {
    return moment.utc(new Date()).startOf("month").toDate();
  },

  codeFormatter: (value) => value?.toUpperCase(),
};

export default helperFunc;
