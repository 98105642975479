import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  // DateInput,
  NumberInput,
  maxLength,
  number,
  ImageField,
  ImageInput,
  FormDataConsumer,
  DateInput,
  SelectInput,
  AutocompleteArrayInput,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import { maxValue, minValue } from "../TurnoutAdmin";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import helperFunc from "../helperFuncs";
import { LeaveTypes, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { leaveTypestyles } from "../helperStyle";

import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { joiningConfirmDate } from "../helperChoice";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export const validatePasswords = ({
  assessmentYearStartOn,
  assessmentEndOn,
}: {
  assessmentYearStartOn: any;
  assessmentEndOn: any;
}) => {
  const errors = {} as any;

  var date = new Date(assessmentYearStartOn);
  date.setDate(date.getDate() + 364);
  var validatedDate = moment(date).format("YYYY-MM-DD");
  if (assessmentEndOn && assessmentEndOn! < date) {
    errors.assessmentEndOn =
      "Assessment Year End Date should be greater then 1 year";
  }

  return errors;
};

const genderArr = [
  { id: "m", name: "Male" },
  { id: "f", name: "Female" },
];
const marriageArr = [
  { id: "married", name: "Married" },
  { id: "single", name: "Single" },
  { id: "divorced", name: "Divorced" },
  { id: "widowed", name: "Widowed" },
];

const employeeTypeArr = [
  { id: "regular", name: "Regular" },
  { id: "intern", name: "Intern" },
  { id: "trainee", name: "Trainee" },
  { id: "contractual", name: "Contractual" },
];

const useStyles = makeStyles(leaveTypestyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/EmployeeLeaveMaster/create"
          ? "Update Leave Type"
          : "Add Leave Type"}
      </h2>
      <SimpleForm {...props} validate={validatePasswords}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="leaves"
            source="leaveName"
            formClassName={classes.name}
            validate={nameValidate}
          />
          <TextInput
            source="code"
            resource="leaves"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />

          <NumberInput
            label="label.NumberOFLeaves"
            resource="leaves"
            source="leavesPerYear"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={[requiredValidate, number(), minValue(1), maxValue(99)]}
          />

          <SectionTitle label="" />
          <DateInput
            label="label.assessment_start_year"
            resource="leaves"
            source="assessmentYearStartOn"
            formClassName={classes.name}
            options={{ format: helperFunc.datePickerFormat(), clearable: true }}
            validate={requiredValidate}
            inputProps={{
              min: "1900-08-10",
              max: "3000-08-20",
            }}
          />
          <DateInput
            label="label.assessment_end_year"
            source="assessmentEndOn"
            resource="leaves"
            options={{ format: helperFunc.datePickerFormat(), clearable: true }}
            formClassName={isXsmall ? classes.name : classes.code}
            validate={requiredValidate}
            inputProps={{
              min: "1900-08-10",
              max: "3000-08-20",
            }}
          />
          <BooleanInput
            label="label.sandwichRule"
            source="sandwichRule"
            resource="leaves"
            defaultValue="true"
            formClassName={isXsmall ? classes.name : classes.code}
          />

          <SectionTitle label="" />

          <ImageInput
            label="label.image"
            multiple={false}
            source="image"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData.image && !formData.image.src ? (
                <img
                  style={{ width: 350, height: 200 }}
                  src={
                    props && props.record && props.record.image
                      ? uploadApiUrl + props.record.image
                      : ""
                  }
                />
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />
          {/* <BooleanInput
                            label="label.carryForward"
                            source="carriedForward"
                            resource="leaves"
                            defaultValue="true"
                            formClassName={classes.name}
                        /> */}
          <BooleanInput
            label="label.status"
            source="isActive"
            resource="leaves"
            defaultValue="true"
            formClassName={isXsmall ? classes.name : classes.code}
          />
          <BooleanInput
            label="Is PTO"
            source="isPTO"
            defaultValue={false}
            formClassName={isXsmall ? classes.name : classes.code}
          />

          <BooleanInput
            label="Is Not Dependent On Leave Balance"
            source="isNotDependentOnLeaveBalance"
            defaultValue={false}
            formClassName={isXsmall ? classes.name : classes.code}
          />
        </div>
      </SimpleForm>
    </React.Fragment>
  ) : (
    <div className="form-content">
      <SimpleForm {...props} validate={validatePasswords}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              filter={{ isActive: true, company: formData.company }}
              helperText={false}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <br />
        <TextInput
          autoFocus
          resource="leaves"
          source="leaveName"
          formClassName={classes.name}
          validate={nameValidate}
        />
        <TextInput
          source="code"
          resource="leaves"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={codeValidate}
          format={helperFunc.codeFormatter}
        />
        <NumberInput
          label="label.NumberOFLeaves"
          resource="leaves"
          source="leavesPerYear"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={[requiredValidate, number(), minValue(1), maxValue(99)]}
        />
        <SectionTitle label="" />
        <DateInput
          options={{ format: helperFunc.datePickerFormat(), clearable: true }}
          label="label.assessment_start_year"
          resource="leaves"
          source="assessmentYearStartOn"
          formClassName={classes.name}
          validate={requiredValidate}
          inputProps={{
            min: "1900-08-10",
            max: "3000-08-20",
          }}
        />
        <DateInput
          options={{ format: helperFunc.datePickerFormat(), clearable: true }}
          label="label.assessment_end_year"
          source="assessmentEndOn"
          resource="leaves"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={requiredValidate}
          inputProps={{
            min: "1900-08-10",
            max: "3000-08-20",
          }}
        />
        <SectionTitle label="" />
        <div className="d-flex">
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <div className="leave-type-switch">
                <BooleanInput
                  label="label.gender"
                  source="isBasedOnGender.status"
                  resource="leaves"

                  // formClassName={classes.name}
                />
              </div>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData?.isBasedOnGender?.status && (
                <div
                  style={{
                    marginLeft: "124px",
                    width: "500px",
                    minWidth: "500px",
                  }}
                >
                  <AutocompleteArrayInput
                    resettable
                    label="label.gender"
                    source="isBasedOnGender.availableFor"
                    choices={genderArr}
                    fullWidth
                    className="leaveType-input"
                  />
                </div>
              )
            }
          </FormDataConsumer>
        </div>

        <SectionTitle label="" />
        <div className="d-flex">
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <div className="leave-type-switch">
                <BooleanInput
                  label="label.maritalStatus"
                  source="isBasedOnMaritalStatus.status"
                  resource="leaves"
                  // formClassName={classes.name}
                />
              </div>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData?.isBasedOnMaritalStatus?.status && (
                <div
                  style={{
                    marginLeft: "124px",
                    width: "500px",
                    minWidth: "500px",
                  }}
                >
                  <AutocompleteArrayInput
                    resettable
                    label="label.maritalStatus"
                    source="isBasedOnMaritalStatus.availableFor"
                    choices={marriageArr}
                    fullWidth
                  />
                </div>
              )
            }
          </FormDataConsumer>
        </div>
        <div className="d-flex">
          <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
            {({ formData }: { formData: any }) => (
              <div className="leave-type-switch">
                <BooleanInput
                  label="label.effectiveAfter"
                  source="leaveEffectiveAfterRequired.status"
                  // resource="leaves"
                  // formClassName={classes.name}
                />
              </div>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData?.leaveEffectiveAfterRequired?.status && (
                <div
                  style={{
                    // marginLeft: "50px",
                    width: "70px",
                    minWidth: "70px",
                    display: "flex",
                    marginLeft: "124px",
                  }}
                  className="day-width"
                >
                  <NumberInput
                    label=""
                    // resource="leaves"
                    source="leaveEffectiveAfterRequired.leaveEffectiveDays"
                    // formClassName={isXsmall ? classes.name10 : classes.code}
                    className="day-width"
                    validate={[
                      requiredValidate,
                      number(),
                      minValue(1),
                      // maxValue(99),
                    ]}
                  />

                  <div className="d-flex mb-18 ml-1">
                    <Typography className="mr-1">Days</Typography>
                    <Typography className="mr-1">from</Typography>
                  </div>

                  <SelectInput
                    label=""
                    className={classes.root}
                    source="leaveEffectiveAfterRequired.leaveEffectiveEvent"
                    // validate={requiredValidate}
                    choices={joiningConfirmDate}
                  />

                  {/* <AutocompleteArrayInput
                    resettable
                    label="label.maritalStatus"
                    source="isBasedOnMaritalStatus.availableFor"
                    choices={marriageArr}
                    fullWidth
                  /> */}
                </div>
              )
            }
          </FormDataConsumer>
        </div>

        {/* <SectionTitle label="" />

    <div className="d-flex">
      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <BooleanInput
            label="label.employeeType"
            source="isBasedOnEmployeeType.status"
            resource="leaves"
            formClassName={classes.name}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) =>
          formData?.isBasedOnEmployeeType?.status && (
            <div style={{ marginLeft: "11.5em" }}>
              <AutocompleteArrayInput
                resettable
                label="label.employeeType"
                source="isBasedOnEmployeeType.availableFor"
                choices={employeeTypeArr}
              />
            </div>
          )
        }
      </FormDataConsumer>
    </div>

    <SectionTitle label="" />

    <div className="d-flex">
      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) => (
          <BooleanInput
            label="label.employeePeriod"
            source="isActive"
            resource="leaves"
            formClassName={classes.name}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
        {({ formData }: { formData: any }) =>
          formData?.employeePeriod?.status && (
            <div style={{ marginLeft: "11.5em" }}>
              <TextInput
                source="phone"
                label="No of Months"
                // disabled
                // fullWidth
                formClassName={classes.name}
              />
            </div>
          )
        }
      </FormDataConsumer>
    </div> */}

        {/* <SectionTitle label="" />
    <BooleanInput
      label="label.carryForward"
      source="carriedForward"
      resource="leaves"
      formClassName={classes.name}
    /> */}

        {/* <SectionTitle label="" />
        <BooleanInput
          label="label.clubbingSandwich"
          source="sandwichRule"
          resource="leaves"
          formClassName={classes.name}
        /> */}
        {/* <SectionTitle label="" />
    <BooleanInput
      label="label.leaveEncashment"
      source="isActive"
      resource="leaves"
      formClassName={classes.name}
    /> */}
        {/* <SectionTitle label="" />

    <div className="d-flex">
      <BooleanInput
        label="label.earnedLeaveCycle"
        source="isActive"
        resource="leaves"
        formClassName={classes.name}
      />

      <div style={{ marginLeft: "11.5em" }}>
        <AutocompleteArrayInput
          resettable
          label="label.earnedLeaveCycle"
          source="dataRights.branchId"
          choices={genderArr}
        />
      </div>
    </div> */}

        <SectionTitle label="" />
        <BooleanInput
          label="label.sandwichRule"
          source="sandwichRule"
          resource="leaves"
          defaultValue="true"
          formClassName={classes.name}
        />
        <SectionTitle label="" />
        <BooleanInput
          label="IsPTO"
          source="isPTO"
          defaultValue={false}
          formClassName={classes.name}
        />

        <SectionTitle label="" />
        <BooleanInput
          label="Is Not Dependent On Leave Balance"
          source="isNotDependentOnLeaveBalance"
          defaultValue={false}
          formClassName={classes.name}
        />
        <SectionTitle label="" />

        <ImageInput
          label="label.image"
          multiple={false}
          source="image"
          accept="image/*"
          placeholder={
            <p>
              Drop a picture to <span style={{ color: "blue" }}>upload </span>,
              or <span style={{ color: "blue" }}>click </span>to select it.
            </p>
          }
          classes={{ dropZone: classes.dropZone, p: classes.pTag }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <div>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData.image && !formData.image.src ? (
                <div>
                  <img
                    alt=""
                    // style={{ width: 350, height: 200 }}
                    src={
                      props &&
                      props.record &&
                      props.record.image &&
                      props.record.image.indexOf("base64") > -1
                        ? props.record.image
                        : uploadApiUrl + props.record.image
                    }
                  />
                </div>
              ) : null
            }
          </FormDataConsumer>
        </div>

        <SectionTitle label="" />

        <BooleanInput
          label="label.status"
          source="isActive"
          resource="leaves"
          defaultValue="true"
          formClassName={classes.name}
        />
      </SimpleForm>
    </div>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];

const requiredValidate = [required()];

export default CommonForm;
