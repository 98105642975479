import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, EmployeeVehicle } from "../types";

const EmployeeVehicleLinkField: FC<FieldProps<EmployeeVehicle>> = (props) =>
  props.record ? (
    <Link to={`/employeeVehicle/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

EmployeeVehicleLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default EmployeeVehicleLinkField;
