import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import helperFunc from "../helperFuncs";
import {
  TopToolbar,
  ExportButton,
  useDataProvider,
  downloadCSV,
  FormDataConsumer,
  DateInput,
  required,
} from "react-admin";
import {
  Button,
  useMediaQuery,
  makeStyles,
  useTheme,
  Box,
  Drawer,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import RegenrationReport from "../attendanceReport/regeneration";
import AttandanceShowTable from "./AttendanceShowBackup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { toastError } from "../components/toast";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { Loader } from "semantic-ui-react";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { useForm, useField } from "react-final-form-hooks";
import CompanyReferenceInput from "../components/CompanyReferenceInput";

import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import MultipleEmployeeTypeSelectInput from "../components/MultiSelectEmployeeInput";
import authProvider from "../authProvider";

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
  textRed: { color: "red" },
  userImg: {
    padding: 0,
    height: 55,
    width: 55,
    marginLeft: 10,
    borderRadius: "30px",
  },
  logo: { width: "100%", height: "inherit" },
  descriptionIcon: {
    transform: "rotate(180deg)",
    fontSize: 20,
  },
  smCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
  },
  lgCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  lgFilterButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "80px",
  },
  lgViewCsvButton: {
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  csvSpan: { paddingLeft: "0.5em" },
  exportButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  viewButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  exportIcon: {
    fontSize: 20,
  },
  viewIcon: {
    fontSize: 24,
  },
});

const FilterReport = ({
  open,
  setOpen,
  filterParams,
  setFilterParams,
  handleDrawerOpen,
  page,
  rowsPerPage,
  count,
  formData,
  setFormData,
}) => {
  const onSubmit = (value) => {
    let updatedFilter;
    if (helperFunc.getChildCompany() === true) {
      updatedFilter = {
        employee: value?.employee,
        fromDate: value?.fromDate,
        // ? formData?.fromDate
        // : helperFunc.startOfMonth(0)
        toDate: value?.toDate,
        // ? formData?.toDate
        // : helperFunc.addDays(0, "forReport"),
      };
    } else {
      updatedFilter = {
        employee: value?.employee,
        fromDate: value?.fromDate,
        // ? formData?.fromDate
        // : helperFunc.startOfMonth(0),
        toDate: value?.toDate,
        // ? formData?.toDate
        // : helperFunc.addDays(0, "forReport"),
        childCompanyId: value?.childCompanyId,
      };
    }
    setFilterParams({
      ...filterParams,
      filter: updatedFilter,
    });
    handleDrawerClose();
  };
  const validate = () => {};
  const useStyles = makeStyles((theme) => ({
    tableCell: { border: "solid 2px" },
    tableHeadCell: { border: "solid 2px", textAlign: "center" },
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    filter: {
      justifyContent: "flex-start",
    },
    smCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.8125rem !important",
    },
    lgCsvButton: {
      padding: "4px 5px !important",
      fontSize: " 0.850rem !important",
      alignContent: "right !important",
    },
    exportIcon: {
      fontSize: 20,
    },
    csvSpan: { paddingLeft: "0.5em" },
  }));
  const theme = useTheme();
  const classes = useStyles();

  const { form, handleSubmit } = useForm({ onSubmit, validate });
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const drawerWidth = 400;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetFilter = (e, data) => {
    data.fromDate = helperFunc.startOfMonth(0);
    data.toDate = helperFunc.addDays(0, "forReport");
    form.change("fromDate", helperFunc.startOfMonth(0));
    form.change("toDate", helperFunc.addDays(0, "forReport"));
    form.change("hiddenVal", "valueChange");
    setFilterParams({
      skip: page,
      limit: rowsPerPage,
      sortBy: "name",
      sort: "asc",
      filter: {
        fromDate: helperFunc.startOfMonth(0),
        toDate: helperFunc.addDays(0, "forReport"),
      },
    });
    setEmptyValue(true);
    setTimeout(function () {
      setEmptyValue(false);
    }, 1000);
    handleDrawerClose();
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className="employee-filter">
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => handleDrawerClose()}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            <Form onSubmit={onSubmit}>
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box component="div">
                    <CompanyReferenceInput></CompanyReferenceInput>
                  </Box>

                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer>
                        {({ formData }) => (
                          <ChildCompanySelectInput
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                            helperText={false}
                            emptyValue={{ emptyValue }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2}>
                      <FormDataConsumer>
                        {({ formData }) => (
                          <MultipleEmployeeTypeSelectInput
                            helperText={false}
                            filter={{
                              company: formData.company,
                              isActive: true,
                              childCompanyId: formData.childCompanyId,
                            }}
                            emptyValue={{ emptyValue }}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            source="fromDate"
                            label="label.fromDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                            defaultValue={filterParams?.filter?.fromDate}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-start" mb={1}>
                    <Box component="span" mr={2}>
                      <FormDataConsumer formClassName={classes.root} alwaysOn>
                        {({ formData }) => (
                          <DateInput
                            options={{
                              format: helperFunc.datePickerFormat(),
                              clearable: true,
                            }}
                            source="toDate"
                            label="label.toDate"
                            alwaysOn
                            helperText={false}
                            inputProps={{
                              min: "1900-08-10",
                              max: "3000-08-20",
                            }}
                            defaultValue={filterParams?.filter?.toDate}
                          />
                        )}
                      </FormDataConsumer>
                      {/* <div
                        style={{
                          transition:
                            "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                          padding: "8px",
                          width: "100%",
                          // marginLeft: "32px",
                          position: "relative",
                          // top: "10px",
                          borderBottom: "1px solid #757575",
                        }}
                      >
                        <div>
                          <span>To Date</span>
                          <input
                            className="public-date-input"
                            type="date"
                            name="toDate"
                            id="toDate"
                            onChange={handleOnChange}
                            value={formData?.toDate}
                            defaultValue={filterParams?.filter?.toDate}
                            style={{
                              minWidth: "240px",
                              border: "none",
                              background: "#0000",
                            }}
                          ></input>
                        </div>
                      </div> */}
                    </Box>
                  </Box>
                  <FormDataConsumer formClassName={classes.root} alwaysOn>
                    {({ formData }) => (
                      <div className="actionbutton">
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          onSubmit={handleSubmit}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={(e) => resetFilter(e, formData)}
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </FormDataConsumer>
                </form>
              )}
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const AttendanceReportTable = (props) => {
  const dataProvider = useDataProvider();
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [filterParams, setFilterParams] = useState({
    skip: page,
    limit: rowsPerPage,
    sortBy: "name",
    sort: "asc",
    filter: {
      fromDate: helperFunc.startOfMonth(0),
      toDate: helperFunc.addDays(0, "forReport"),
    },
  });
  const [formData, setFormData] = useState();

  const tableRef = useRef();

  const handleDefaultPage = (index) => {
    setPage(index);
  };
  const handleRowsPerPage = (index) => {
    setRowsPerPage(index);
  };

  const handleChangePage = (event, newPage) => {
    setFilterParams({
      ...filterParams,
      skip: newPage * rowsPerPage,
    });
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
    setFilterParams({
      ...filterParams,
      limit: parseInt(event.target.value, 10),
    });
  };

  var container = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  useEffect(() => {
    if (container) {
      container.innerText = "";
      container.innerText = "Attendance Report";
    }
    return function cleanup() {
      if (container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const url = "attendance-report/list";
    // setLoading(true);
    dataProvider
      .callCustom(url, filterParams)
      .then((response) => {
        setListData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        err.response.data.errors.forEach((error) => {
          toastError(err.message);
          if (error.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
  }, [filterParams, page]);

  const updatedData = listData?.list?.map((ele) => {
    return {
      department: ele?.departmentData?.name ? ele?.departmentData?.name : "N/A",
      name: ele?.employeeData?.name,
      designation: ele?.designationData?.name
        ? ele?.designationData?.name
        : "N/A",
      dateStr: ele?.dateStr ? helperFunc.commonDateFormat(ele?.dateStr) : "N/A",
      in: ele?.inTime
        ? helperFunc.commonDateFormat(ele?.inTime, "HH:mm:ss")
        : "N/A",
      out: ele?.outTime
        ? helperFunc.commonDateFormat(ele?.outTime, "HH:mm:ss")
        : "N/A",
      spentHours: ele?.stayTime
        ? helperFunc.secondsToHms(ele?.stayTime)
        : "N/A",
      totalHours: ele?.totalTime
        ? helperFunc.secondsToHms(ele?.totalTime)
        : "N/A",
      isAbsent:
        ele?.isAbsent === true ? (
          <span className="false-color">No</span>
        ) : (
          <span className="true-color">Yes</span>
        ),
      visibilityIcon: <AttandanceShowTable record={ele} />,
    };
  });

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const menuOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const newExportReport =
    process.env.REACT_APP_DATA_PROVIDER_IS_NEW_ATTENDANCE_REPORT_EXPORT;

  const exporter = (records) => {
    const recordsForExport = listData?.list.map((record) => {
      const {
        company,
        dateStr,
        department,
        departmentData,
        designation,
        designationData,
        early,
        employee,
        employeeData,
        employeeType,
        employeeTypeData,
        inTime,
        isAbsent,
        late,
        outTime,
        role,
        shift,
        shiftData,
        stayTime,
        totalTime,
        _id,
        id,
        geoPoints,
        inLoc,
        outLoc,
        outMode,
        inMode,
        ...dataToExport
      } = record; // omit fields
      if (newExportReport === "true") {
        dataToExport["Employee Name"] =
          employeeData &&
          employeeData.name + " " + "(" + employeeData.employeeCode + ")";
        dataToExport["Date"] = helperFunc.commonDateFormat(dateStr);
        dataToExport["In"] = inTime
          ? moment(inTime).format("hh:mm:ss A")
          : "N/A";
        dataToExport["Out"] = outTime
          ? moment(outTime).format("hh:mm:ss A")
          : "N/A";
        return dataToExport;
      } else {
        dataToExport["Employee Name"] =
          employeeData &&
          employeeData.name + " " + "(" + employeeData.employeeCode + ")"; // add a field
        // dataToExport.Code = employeeData && employeeData.employeeCode; // add a field
        dataToExport["Designation"] = designationData && designationData.name;
        dataToExport["Department"] = departmentData && departmentData.name;
        // dataToExport["Shift"] = shiftData && shiftData.name;
        dataToExport["Date"] = helperFunc.commonDateFormat(dateStr);
        dataToExport["In"] = inTime
          ? moment(inTime).format("hh:mm:ss A")
          : "N/A";
        dataToExport["Out"] = outTime
          ? moment(outTime).format("hh:mm:ss A")
          : "N/A";
        // dataToExport["In Mode"] = inMode ? mode[inMode] : "N/A";
        // dataToExport["Out Mode"] = outMode ? mode[outMode] : "N/A";
        dataToExport["Spent Hours"] = helperFunc.secondsToHms(stayTime);
        dataToExport["Total Hours"] = helperFunc.secondsToHms(totalTime);
        dataToExport["Absent"] = isAbsent ? "Yes" : "No";
        return dataToExport;
      }
    });
    jsonExport(
      recordsForExport,
      {
        headers: [], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Attendance"); // download as 'xxxx.csv` file
      }
    );
  };

  const columns = [
    {
      title: "Department",
      field: "department",
    },
    { title: "Name", field: "name" },
    { title: "Designation", field: "designation" },
    { title: "Date", field: "dateStr" },
    { title: "In", field: "in" },
    { title: "Out", field: "out" },

    {
      title: "Spent Hours",
      field: "spentHours",
      type: "numeric",
      hidden: authProvider?.isHiddenField(
        props?.location?.pathname,
        "stayTime",
        "isHide"
      )
        ? true
        : false,
    },
    { title: "Total Hours", field: "totalHours" },
    { title: "Is Present", field: "isAbsent" },

    {
      title: "",
      field: "visibilityIcon",
      hidden: authProvider?.isHiddenField(
        props?.location?.pathname,
        "viewIcon",
        "isHide"
      )
        ? true
        : false,
    },
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <div style={{ marginTop: "-18px" }}>
            <TopToolbar>
              <div>
                <Button
                  className={
                    isSmall ? classes.smCsvButton : classes.lgFilterButton
                  }
                  color="primary"
                  component="span"
                  onClick={() => handleDrawerOpen()}
                >
                  <FilterListIcon />
                  {"Filter"}
                </Button>
              </div>
              <Button
                className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
                color="primary"
                component="span"
                onClick={exporter}
              >
                <GetAppIcon className={classes.exportIcon} />
                {!isSmall ? (
                  <span className={classes.csvSpan}>Export</span>
                ) : null}
              </Button>
              <RegenrationReport />
            </TopToolbar>{" "}
          </div>

          <FilterReport
            open={open}
            setOpen={setOpen}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            handleDrawerOpen={handleDrawerOpen}
            page={page}
            rowsPerPage={rowsPerPage}
            count={listData?.count}
            formData={formData}
            setFormData={setFormData}
          />

          <MaterialTable
            title=""
            ref={tableRef}
            columns={columns}
            data={updatedData}
            options={{
              grouping: true,
              sorting: true,
              pageSize: 100,
              pageSizeOptions: [100, 200, 500, 1000],
              search: false,
              toolbar: false,
              paging: false,
            }}
            style={{
              maxWidth: menuOpen ? "calc(100vw - 256px)" : "",
              fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
            }}
          />

          {/* <FilterReport
            open={open}
            setOpen={setOpen}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          /> */}
          <div style={{ width: "100%" }}>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500, 1000]}
              component="div"
              count={listData?.count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AttendanceReportTable;
