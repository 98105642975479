import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";

import CardIcon from "./CardIcon";

interface Props {
  value?: number;
  data?: JSON;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    borderBottom: "4px solid #4caf50",
  },
  title: {},
});

const TotalNotifications: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <CardIcon Icon={NotificationsIcon} bgColor="#4caf50" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate("pos.dashboard.total_notifications")}
        </Typography>
        <Typography variant="h5" component="h2">
          {value}
        </Typography>
      </Card>
    </div>
  );
};

export default TotalNotifications;
