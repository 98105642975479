import React, { useEffect, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import {
  useDataProvider,
  showNotification,
  useRefresh,
  useNotify,
  useUnselectAll,
  useTranslate,
} from "react-admin";
import {
  useMediaQuery,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { importStyle } from "../helperStyle";
import CloseIcon from "@material-ui/icons/Close";
import { toastInfo } from "./toast";
interface State {
  isOpen: false;
  errorData: [];
  responseData: {
    totalCount: 0;
    successCount: 0;
    dataError: 0;
  };
}
const useStyles = makeStyles(importStyle);
const MultiDeleteTable = (props: any) => {
  let { resource } = props;
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("text-button-file")
        : null;
  }
  // const version = useVersion();

  const classes = useStyles();
  const translate = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const unselectAll = useUnselectAll();
  const [page, setPage] = React.useState(0);
  let [errorData, setErrorData] = useState([]);
  let [responseData, setResponseData] = useState({
    totalCount: 0,
    successCount: 0,
    dataError: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const refresh = useRefresh();
  // const notify = useNotify();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (props.data && props.data.success === true) {
      if (props.data.dataError > 0) {
        setIsOpen(true);
        setResponseData({
          totalCount: props.data.totalCount,
          successCount: props.data.successCount,
          dataError: props.data.dataError,
        });
        setErrorData(props.data.mainErrorData);
        unselectAll(resource);
      } else {
        unselectAll(resource);
        refresh();
        toastInfo("Records has been deleted Successfully.");
        // notify("Records has been deleted Successfully.");

        // refresh();
      }
      props.data.success = null;
    }
  }, [props]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setIsOpen(false);
    refresh();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const resourceName = translate(`resources.${resource}.name`, resource);
  return (
    <Dialog
      fullScreen={fullScreen}
      className={classes.myPopup}
      aria-labelledby="simple-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6">{resourceName} Data Delete</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon className={classes.close} />
        </IconButton>
      </DialogTitle>
      <Box display="block" displayPrint="none" m={2}>
        <div className={classes.label}>
          Total :{" "}
          <span className={classes.label} style={{ color: "#000" }}>
            {responseData.totalCount}
          </span>
        </div>
        <div className={classes.label}>
          Success:{" "}
          <span className={classes.label} style={{ color: "green" }}>
            {responseData.successCount}
          </span>
        </div>
        <div className={classes.label}>
          Error :{" "}
          <span className={classes.label} style={{ color: "red" }}>
            {responseData.dataError}
          </span>
        </div>
      </Box>
      <Paper>
        <TableContainer>
          <Table aria-label="simple table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.theadFCol}>Sr no</TableCell>
                <TableCell className={classes.theadCol}>Name</TableCell>
                <TableCell className={classes.theadCol}>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data: any, index: number) => (
                  <TableRow key={index + 1}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">
                      {data ? data.element : ""}
                    </TableCell>
                    <TableCell align="left">
                      {data ? (data.success ? "Deleted" : data.error) : ""}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={errorData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Dialog>
  );
};

export default MultiDeleteTable;
