import React, { useEffect, useState, FC } from "react";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
} from "react-admin";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { useMediaQuery, Theme } from "@material-ui/core";
import helperFunc from "../helperFuncs";
import about from "../about";
import devices from "../devices";
import countries from "../countries";
import zones from "../zones";
import regions from "../regions";
import branches from "../branches";
import companyMasters from "../companyMaster";
import clientMasters from "../clientMaster";
import clientLocations from "../clientLocation";
import workplaces from "../workplaces";
import states from "../states";
import cities from "../cities";
import SubMenu from "./SubMenu";
import { AppState } from "../types";
import companies from "../companies";
import roles from "../roles";
import designations from "../designations";
import departments from "../departments";
import leaveType from "../leaveTypes";
import leavesTabPanel from "../leaveTabPanel";
import authProvider from "../authProvider";
import visitors from "../visitors";
import attendence from "../attendance";
import requests from "../requests";
import employees from "../employees";
import employeeMultiRegistration from "../employeeMultiRegistration";
import employeeVehicle from "../employeeVehicle";
import vehicleAttendance from "../vehicleAttendance";
import stream from "../stream";
import course from "../course";
import collegeAttendance from "../collegeAttendance";
import semester from "../semester";
import classroom from "../classroom";
import subject from "../subject";
import faculty from "../faculty";
import student from "../student";
import timeslot from "../timeslot";
import assignTimeslot from "../assignTimeslot";
import cameraVenue from "../cameraVenue";
import facultyTimetable from "../facultyTimetable";
import datewiseAttedanceReport from "../datewiseAttedanceReport";
import studentwiseAttendanceReport from "../studentwiseAttendanceReport";
import lecturewiseAttendanceReport from "../lecturewiseAttendanceReport";
import subjectwiseAttendanceReport from "../subjectwiseAttedanceReport";
import todayTimetableReport from "../todayTimetableReport";
import contactUs from "../contactUs";
import unknownAttendance from "../unknownAttendance";
import leaveTabPanel from "../leaveTabPanel";
import holidaysList from "../holidaysList";
import WeekOffsList from "../WeekOffsList";
import SundayOffsList from "../SundayOffsList";
import notifications from "../notifications";
import emailTemplates from "../emailTemplates";
import {
  CollegeIcon,
  CollegeReportIcon,
  AttendanceApproval1Icon,
  AttendanceApproval2Icon,
  MasterIcon,
  EmployeeTypesIcon,
  CompanyIcon,
  FinalReportIcon,
  RegisterIcon,
  DashboardIcon,
  DocumentManagementIcon,
  MasterConfigurationIcon,
} from "../svgIcon";
import teamAttendance from "../teamAttendance";
import Leave from "../leave";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MenuHeader } from "../common";
import Attendance from "../attendance";
import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from "@material-ui/icons/Description";
import MovieIcon from "@material-ui/icons/Movie";
import DocumentCategory from "../documentCategory";
import DocumentType from "../documentType";
import DocumentManagement from "../documentManagement";
import {
  myTeamArrayAttendanceHeader,
  myTeamArrayLeaveHeader,
  leaveArrayHeader,
  staffArrayHeader,
  companyArrayHeader,
  leaveArray,
  attendanceArray,
  visitorArray,
  masterConfigurationArray,
  applicationArrayHeader,
  aboutArray,
} from "../helperArray";

type MenuName =
  | "menuMasters"
  | "menuCollege"
  | "menuCollegeReport"
  | "menuCatalog"
  | "menuSales"
  | "menuCustomers"
  | "menuReport"
  | "menuTemplates"
  | "menuAttendances"
  | "menuMyTeam"
  | "menuLeave"
  | "menuSetting"
  | "menuAbout";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  var container: any = null;
  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  const [openId, setOpenId] = useState() as any;
  const [headerTitle, setHeaderTitle] = useState("");
  const { permissions } = usePermissions();

  const [state, setState] = useState({
    menuCatalog: false,
    menuMasters: false,
    menuCollege: false,
    menuCollegeReport: false,
    menuSales: false,
    menuCustomers: false,
    menuReport: false,
    menuTemplates: false,
    menuAttendances: false,
    menuMyTeam: false,
    menuLeave: false,
    menuSetting: false,
    menuAbout: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const user = helperFunc.getUserData();

  let open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName, index: any) => {
    if (openId === index) setOpenId("");
    else setOpenId(index);
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleMenuClick = () => {
    setOpenId("");
  };

  useEffect(() => {}, [headerTitle]);
  const changeText = (label: any) => {
    setHeaderTitle(label);
  };

  const filterValue = (menuItem: any) =>
    user?.permissions?.filter((ele: any) => {
      const newStr = ele.replace(".list", "");
      return menuItem?.includes(newStr);
    });

  const finalReportCheck = process.env.REACT_APP_DATA_PROVIDER_IS_FINAL_REPORT;

  return (
    <div className="menu-width">
      <DashboardMenuItem
        primaryText={"Dashboard"}
        name="Dashboard"
        to="/dashboard"
        onClick={handleMenuClick}
        sidebarIsOpen={open}
        icon={<DashboardIcon />}
      />

      {/* NOTE New Submenu For College */}
      {(user && user.isCollege) || permissions === "super" ? (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuCollege", 0)}
            isOpen={openId === 0}
            sidebarIsOpen={open}
            name="pos.menu.college"
            icon={<CollegeIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/college-attendance`}
              primaryText={translate(`resources.attendance.name`, {
                smart_count: 2,
              })}
              leftIcon={<collegeAttendance.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/stream`}
              primaryText={translate(`resources.stream.name`, {
                smart_count: 2,
              })}
              leftIcon={<stream.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/course`}
              primaryText={translate(`resources.course.name`, {
                smart_count: 2,
              })}
              leftIcon={<course.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/semester`}
              primaryText={translate(`resources.semester.name`, {
                smart_count: 2,
              })}
              leftIcon={<semester.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/classroom`}
              primaryText={translate(`resources.classroom.name`, {
                smart_count: 2,
              })}
              leftIcon={<classroom.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/subject`}
              primaryText={translate(`resources.subject.name`, {
                smart_count: 2,
              })}
              leftIcon={<subject.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/faculty`}
              primaryText={translate(`resources.faculty.name`, {
                smart_count: 2,
              })}
              leftIcon={<faculty.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/student`}
              primaryText={translate(`resources.student.name`, {
                smart_count: 2,
              })}
              leftIcon={<student.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {permissions === "super" && (
              <MenuItemLink
                to={`/studentMultiRegistration`}
                primaryText={translate(
                  `resources.studentMultiRegistration.name`,
                  {
                    smart_count: 2,
                  }
                )}
                leftIcon={
                  <span className="icon-s tur-employeeMultiRegistration"></span>
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            <MenuItemLink
              to={`/timeslot`}
              primaryText={translate(`resources.timeslot.name`, {
                smart_count: 2,
              })}
              leftIcon={<timeslot.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/assign-timeslot`}
              primaryText={translate(`resources.assignTimeslot.name`, {
                smart_count: 2,
              })}
              leftIcon={<assignTimeslot.icon />}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/faculty-timetable`}
              primaryText={translate(`resources.facultyTimetable.name`, {
                smart_count: 2,
              })}
              leftIcon={<facultyTimetable.icon />}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/camera-venue`}
              primaryText={translate(`resources.cameraVenue.name`, {
                smart_count: 2,
              })}
              leftIcon={<cameraVenue.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
              to={`/devices`}
              primaryText={translate(`resources.devices.name`, {
                smart_count: 2,
              })}
              leftIcon={<devices.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </div>
      ) : null}
      {(user && user.isCollege) || permissions === "super" ? (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuCollegeReport", 1)}
            isOpen={openId === 1}
            sidebarIsOpen={open}
            name="pos.menu.collegeReport"
            icon={<CollegeReportIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/datewiseAttendanceReport`}
              primaryText={translate(
                `resources.datewiseAttendanceReport.name`,
                {
                  smart_count: 2,
                }
              )}
              leftIcon={<datewiseAttedanceReport.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/studentwiseAttendanceReport`}
              primaryText={translate(
                `resources.studentwiseAttendanceReport.name`,
                {
                  smart_count: 2,
                }
              )}
              leftIcon={<studentwiseAttendanceReport.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/lecturewiseAttendanceReport`}
              primaryText={translate(
                `resources.lecturewiseAttendanceReport.name`,
                {
                  smart_count: 2,
                }
              )}
              leftIcon={<lecturewiseAttendanceReport.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/subjectwiseAttendanceReport`}
              primaryText={translate(
                `resources.subjectwiseAttendanceReport.name`,
                {
                  smart_count: 2,
                }
              )}
              leftIcon={<subjectwiseAttendanceReport.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/todayTimetableReport`}
              primaryText={translate(`resources.todayTimetableReport.name`, {
                smart_count: 2,
              })}
              leftIcon={<todayTimetableReport.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </div>
      ) : null}

      {/* ANCHOR only  Super */}
      {permissions === "super" && (
        <MenuItemLink
          to={`/contact-us`}
          primaryText={translate(`resources.contactUs.name`, {
            smart_count: 2,
          })}
          leftIcon={<contactUs.icon />}
          onClick={handleMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions === "super" && (
        <MenuItemLink
          to={`/companies`}
          primaryText={translate(`resources.companies.name`, {
            smart_count: 2,
          })}
          leftIcon={<companies.icon />}
          onClick={handleMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions === "super" && (
        <MenuItemLink
          to={`/attendance-requests`}
          primaryText={translate(`resources.attendance.requests`, {
            smart_count: 2,
          })}
          leftIcon={<requests.icon />}
          onClick={handleMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions === "super" && (
        <MenuItemLink
          to={`/devices`}
          primaryText={translate(`resources.devices.name`, {
            smart_count: 2,
          })}
          leftIcon={<devices.icon />}
          onClick={handleMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {/* NOTE >>>>  Sub Menu For Attendance >>>> */}
      {permissions === "super" || filterValue(attendanceArray)?.length > 0 ? (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuAttendances", 2)}
            isOpen={openId === 2}
            sidebarIsOpen={open}
            name="pos.menu.attendance"
            icon={<Attendance.icon />}
            dense={dense}
          >
            {authProvider.isMenuShow("attendances") && (
              <MenuItemLink
                to={`/attendance`}
                primaryText={translate(`resources.attendance.name`, {
                  smart_count: 2,
                })}
                leftIcon={<attendence.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {/* {(user?.type === "super" ||
              (authProvider.isMenuShow("unknownAttendance") &&
                user?.isUnknownAttendance)) && (
              <MenuItemLink
                to={`/unknown-attendance`}
                primaryText={translate(`resources.unknownAttendance.name`, {
                  smart_count: 2,
                })}
                leftIcon={<unknownAttendance.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )} */}
            {authProvider.isMenuShow("requests") && (
              <MenuItemLink
                to={`/attendenceRequestLog`}
                primaryText={translate(`resources.requests.menuName`, {
                  smart_count: 2,
                })}
                leftIcon={<requests.icon />}
                onClick={() => [onMenuClick, changeText("Requests")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {permissions === "super" ||
            filterValue(myTeamArrayAttendanceHeader)?.length > 0 ? (
              <MenuHeader headerLabel="My Team" />
            ) : (
              ""
            )}

            {authProvider.isMenuShow("my-team-statistics") && (
              <MenuItemLink
                to={`/my-team-statistics`}
                primaryText={translate(`resources.teamStatistics.name`, {
                  smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("teamattendance") && (
              <MenuItemLink
                to={`/teamattendance`}
                primaryText={translate(`resources.teamAttendance.name`, {
                  smart_count: 2,
                })}
                leftIcon={<attendence.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("team-attendance-report") && (
              <MenuItemLink
                to={`/team-attendance-report`}
                primaryText={translate(`resources.teamAttendanceReport.name`, {
                  smart_count: 2,
                })}
                leftIcon={<FinalReportIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("teamrequests") && (
              <MenuItemLink
                to={`/team-attendence-request`}
                primaryText={translate(`resources.teamRequests.name`, {
                  smart_count: 2,
                })}
                leftIcon={<requests.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            <MenuHeader headerLabel="Reports" />
            {authProvider.isMenuShow("attendances") && (
              <MenuItemLink
                to={`/attendance-report`}
                primaryText={translate(`resources.attendance.name`, {
                  smart_count: 2,
                })}
                leftIcon={<attendence.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("updateRegister") && (
              <MenuItemLink
                primaryText={"Update Register"}
                to={`/attendance-summary`}
                leftIcon={<RegisterIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("attendenceApproval1") && (
              <MenuItemLink
                primaryText={"Attendance Approval-1"}
                to={`/attendance-approval`}
                leftIcon={<AttendanceApproval1Icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("attendenceApproval2") && (
              <MenuItemLink
                primaryText={"Attendance Approval-2"}
                to={`/attendence-approval`}
                leftIcon={<AttendanceApproval2Icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("finalReports", "list") &&
              finalReportCheck === "true" && (
                <MenuItemLink
                  to={`/final-report`}
                  primaryText={translate(`resources.finalReport.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<FinalReportIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
          </SubMenu>
        </div>
      ) : (
        ""
      )}

      {/* NOTE >>>>  Sub Menu For Leave >>>> */}
      {permissions === "super" || filterValue(leaveArray)?.length > 0 ? (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuLeave", 4)}
            isOpen={openId === 4}
            sidebarIsOpen={open}
            name="pos.menu.leaveManagement"
            icon={<Leave.icon />}
            dense={dense}
          >
            {authProvider.isMenuShow("leaves") && (
              <MenuItemLink
                to={`/leave-management`}
                primaryText={translate(`resources.leaves.menuName`, {
                  smart_count: 2,
                })}
                leftIcon={<leaveTabPanel.icon />}
                onClick={() => [onMenuClick, changeText("Leaves")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {/* {authProvider.isMenuShow("leaves") && ( */}
            <MenuItemLink
              to={`/leave-balance-history`}
              primaryText={translate(`Leave Balance History`, {
                smart_count: 2,
              })}
              leftIcon={<leaveTabPanel.icon />}
              onClick={() => [onMenuClick, changeText("Leave Balance History")]}
              sidebarIsOpen={open}
              dense={dense}
            />
            {/* )} */}

            {permissions === "super" ||
            filterValue(myTeamArrayLeaveHeader)?.length > 0 ? (
              <MenuHeader headerLabel="My Team" />
            ) : (
              ""
            )}

            {authProvider.isMenuShow("teamLeaves") && (
              <MenuItemLink
                to={`/teamLeave`}
                primaryText={translate(`resources.teamLeaves.name`, {
                  smart_count: 2,
                })}
                leftIcon={<attendence.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("teamLeaves") && (
              <MenuItemLink
                to={`/team-leave-balance-history`}
                primaryText={translate(`Leave Balance History`, {
                  smart_count: 2,
                })}
                leftIcon={<attendence.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            <MenuHeader headerLabel="Reports" />
            {/* {authProvider.isMenuShow("leaves") && (
              <MenuItemLink
                to={`/leave-report`}
                primaryText={translate(`resources.leaves.leaveReport`, {
                  smart_count: 2,
                })}
                leftIcon={<leaveTabPanel.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )} */}
            {/* {authProvider.isMenuShow("employee-leave-report") && (
              <MenuItemLink
                to={`/employee-leave-report`}
                primaryText={translate(`resources.employeeLeaveReport.name`, {
                  smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )} */}
            {authProvider.isMenuShow("employee-leave-balance-report") && (
              <MenuItemLink
                to={`/employee-leave-balance-report`}
                primaryText={translate(
                  `resources.employeeLeaveReportNew.name`,
                  {
                    smart_count: 2,
                  }
                )}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
          </SubMenu>
        </div>
      ) : (
        ""
      )}

      {/* NOTE >>>>  Sub Menu For Visitor >>>> */}
      {permissions === "super" || filterValue(visitorArray)?.length > 0 ? (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuAttendances", 3)}
            isOpen={openId === 3}
            sidebarIsOpen={open}
            name="pos.menu.visitor"
            icon={<visitors.icon />}
            dense={dense}
          >
            {authProvider.isMenuShow("inviteVisitor") && (
              <MenuItemLink
                to={`/invite-visitor`}
                primaryText={translate(`resources.visitor.title`, {
                  smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            <MenuHeader headerLabel="Reports" />

            {authProvider.isMenuShow("inviteVisitor") && (
              <MenuItemLink
                to={`/visitor-qr-code`}
                primaryText={translate(`resources.inviteVisitorReport.name`, {
                  smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
          </SubMenu>
        </div>
      ) : (
        ""
      )}

      {/* NOTE Sub Menu For Emergency Configuration */}

      {(user?.type === "super" ||
        authProvider.isMenuShow("emergency-evacuation")) && (
        <MenuItemLink
          to={`/emergency-evacuation`}
          primaryText={translate(`resources.emergencyEvacuation.title`, {
            smart_count: 2,
          })}
          leftIcon={<requests.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {/* NOTE Sub Menu For Master Configuration */}
      {(permissions === "super" ||
        filterValue(masterConfigurationArray)?.length > 0) && (
        <div>
          <SubMenu
            handleToggle={() => handleToggle("menuMasters", 5)}
            isOpen={openId === 5}
            sidebarIsOpen={open}
            name="pos.menu.masterConfiguration"
            icon={<MasterConfigurationIcon />}
            dense={dense}
          >
            {permissions === "super" ||
            filterValue(companyArrayHeader)?.length > 0 ? (
              <MenuHeader headerLabel="Company" />
            ) : (
              ""
            )}

            {authProvider.isMenuShow("companyMasters") && (
              <MenuItemLink
                to={`/companyMasters`}
                primaryText={translate(`resources.companyMaster.name`, {
                  smart_count: 2,
                })}
                leftIcon={<companyMasters.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {permissions === "super" && (
              <MenuItemLink
                to={`/employee-type`}
                primaryText={translate(`resources.employeeType.name`, {
                  smart_count: 2,
                })}
                leftIcon={<EmployeeTypesIcon />}
                onClick={() => [
                  onMenuClick,
                  changeText(
                    translate(`resources.employeeType.name`, {
                      smart_count: 2,
                    })
                  ),
                ]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("countries") && (
              <MenuItemLink
                to={`/countries`}
                primaryText={translate(`resources.countries.name`, {
                  smart_count: 2,
                })}
                leftIcon={<countries.icon />}
                onClick={() => [onMenuClick, changeText("Countires")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("zones") && (
              <MenuItemLink
                to={`/zones`}
                primaryText={translate(`resources.zones.name`, {
                  smart_count: 2,
                })}
                leftIcon={<zones.icon />}
                onClick={() => [onMenuClick, changeText("Zones")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("regions") && (
              <MenuItemLink
                to={`/regions`}
                primaryText={translate(`resources.regions.name`, {
                  smart_count: 2,
                })}
                leftIcon={<regions.icon />}
                onClick={() => [onMenuClick, changeText("Regions")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("states") && (
              <MenuItemLink
                to={`/states`}
                primaryText={translate(`resources.states.name`, {
                  smart_count: 2,
                })}
                leftIcon={<states.icon />}
                onClick={() => [onMenuClick, changeText("States")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("cities") && (
              <MenuItemLink
                to={`/cities`}
                primaryText={translate(`resources.cities.name`, {
                  smart_count: 2,
                })}
                leftIcon={<cities.icon />}
                onClick={() => [onMenuClick, changeText("Cities")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("branchMasters") && (
              <MenuItemLink
                to={`/branches`}
                primaryText={translate(`resources.branches.name`, {
                  smart_count: 2,
                })}
                leftIcon={<branches.icon />}
                onClick={() => [onMenuClick, changeText("Branches")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("workplaces") && (
              <MenuItemLink
                to={`/workplaces`}
                primaryText={translate(`resources.workplaces.name`, {
                  smart_count: 2,
                })}
                leftIcon={<workplaces.icon />}
                onClick={() => [onMenuClick, changeText("Workplaces")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {/* {(permissions === "super" || (user && user.type === "company")) && ( */}
            {authProvider.isMenuShow("roles") && (
              <MenuItemLink
                to={`/roles`}
                primaryText={translate(`resources.roles.name`, {
                  smart_count: 2,
                })}
                leftIcon={<roles.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {/* )} */}
            {authProvider.isMenuShow("clientMasters") &&
              user?.isClientConfiguration && (
                <MenuItemLink
                  to={`/clientMasters`}
                  primaryText={translate(`resources.clientMaster.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<clientMasters.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            {authProvider.isMenuShow("clientLocations") &&
              user?.isClientConfiguration && (
                <MenuItemLink
                  to={`/clientLocations`}
                  primaryText={translate(`resources.clientLocation.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<clientLocations.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}

            {permissions === "super" ||
            filterValue(staffArrayHeader)?.length > 0 ? (
              <MenuHeader headerLabel="Staff" />
            ) : (
              ""
            )}

            {authProvider.isMenuShow("designations") && (
              <MenuItemLink
                to={`/designations`}
                primaryText={translate(`resources.designations.name`, {
                  smart_count: 2,
                })}
                leftIcon={<designations.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("designations") && (
              <MenuItemLink
                to={`/organization-chart`}
                primaryText={translate(`Organization Chart`, {
                  smart_count: 2,
                })}
                leftIcon={<about.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("departments") && (
              <MenuItemLink
                to={`/departments`}
                primaryText={translate(`resources.departments.name`, {
                  smart_count: 2,
                })}
                leftIcon={<departments.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {(user?.type === "super" ||
              authProvider.isMenuShow("document-category")) && (
              <MenuItemLink
                to={`/document-category`}
                primaryText={translate(`resources.document-category.name`, {
                  smart_count: 2,
                })}
                leftIcon={<DocumentCategory.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {(user.type === "super" ||
              authProvider.isMenuShow("document-type")) && (
              <MenuItemLink
                to={`/document-type`}
                primaryText={translate(`resources.document-type.name`, {
                  smart_count: 2,
                })}
                leftIcon={<DocumentType.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("employees") && (
              <MenuItemLink
                to={`/employees`}
                primaryText={translate(`resources.employees.name`, {
                  smart_count: 2,
                })}
                leftIcon={<employees.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {(authProvider.isMenuShow("employeeMultiRegistration", "list") ||
              permissions === "super") && (
              <MenuItemLink
                to={`/employeeMultiRegistration`}
                primaryText={translate(
                  `resources.employeeMultiRegistration.name`,
                  {
                    smart_count: 2,
                  }
                )}
                leftIcon={
                  <span className="icon-s tur-employeeMultiRegistration"></span>
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("subAdmin") ? (
              <MenuItemLink
                to={`/sub-admin`}
                primaryText={translate(`resources.employee.title`, {
                  smart_count: 2,
                })}
                leftIcon={<employeeMultiRegistration.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : (
              ""
            )}

            {permissions === "super" ||
            filterValue(leaveArrayHeader)?.length > 0 ? (
              <MenuHeader headerLabel="Leave" />
            ) : (
              ""
            )}

            {authProvider.isMenuShow("leaveTypes") && (
              <MenuItemLink
                to={`/EmployeeLeaveMaster`}
                primaryText={translate(`resources.leaveTypes.name`, {
                  smart_count: 2,
                })}
                leftIcon={<leaveType.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("employee-leave-balance") && (
              <MenuItemLink
                to={`/employee-leave-balance`}
                primaryText={translate(`resources.leaveBalance.name`, {
                  smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("holiday") && (
              <MenuItemLink
                to={`/holidayList`}
                primaryText={translate(`resources.holidayList.name`, {
                  smart_count: 2,
                })}
                leftIcon={<holidaysList.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("holiday") && (
              <MenuItemLink
                to={`/weekOffList`}
                primaryText={translate(`resources.weekOffList.name`, {
                  smart_count: 2,
                })}
                leftIcon={<WeekOffsList.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("holiday") && (
              <MenuItemLink
                to={`/sundayOff`}
                primaryText={translate(`resources.sundayOffList.name`, {
                  smart_count: 2,
                })}
                leftIcon={<SundayOffsList.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {permissions === "super" ||
              (filterValue(applicationArrayHeader)?.length > 0 && (
                <MenuHeader headerLabel="Application" />
              ))}
            {authProvider.isMenuShow("dateSettings") && (
              <MenuItemLink
                to={`/date-settings`}
                primaryText={translate(`resources.dateSettings.name`, {
                  smart_count: 2,
                })}
                leftIcon={<roles.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

            {authProvider.isMenuShow("reasons") && (
              <MenuItemLink
                to={`/reasons`}
                primaryText={translate(`resources.inOutRemarks.name`, {
                  smart_count: 2,
                })}
                leftIcon={<branches.icon />}
                onClick={() => [onMenuClick, changeText("In Out Remarks")]}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {authProvider.isMenuShow("emailTemplates") && (
              <MenuItemLink
                to={`/email-template`}
                primaryText={translate(`pos.menu.templates`, {
                  smart_count: 2,
                })}
                leftIcon={<emailTemplates.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
          </SubMenu>
        </div>
      )}
      {(user?.type === "super" ||
        (authProvider.isMenuShow("employee-document") &&
          user?.isDocumentManagement)) && (
        <MenuItemLink
          to={`/document-management`}
          primaryText={translate(`resources.documentManagement.name`, {
            smart_count: 2,
          })}
          leftIcon={<DocumentManagement.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {(permissions === "super" || filterValue(aboutArray)?.length > 0) && (
        <SubMenu
          handleToggle={() => handleToggle("menuAbout", 6)}
          isOpen={openId === 6}
          sidebarIsOpen={open}
          name="pos.menu.about"
          icon={<about.icon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/about-turnout`}
            primaryText={translate(`pos.about`, {
              smart_count: 2,
            })}
            leftIcon={<about.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {permissions === "super" ? (
            <>
              <MenuHeader headerLabel="Help" />
              <MenuItemLink
                to={`/about-turnout`}
                primaryText={translate(`pos.menu.faq`, {
                  smart_count: 2,
                })}
                leftIcon={
                  <HelpIcon style={{ fontSize: "25px", color: "#00000066" }} />
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={`/about-turnout`}
                primaryText={translate(`pos.menu.userManual`, {
                  smart_count: 2,
                })}
                leftIcon={
                  <DescriptionIcon
                    style={{ fontSize: "25px", color: "#00000066" }}
                  />
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              <MenuItemLink
                to={`/about-turnout`}
                primaryText={translate(`pos.menu.video`, {
                  smart_count: 2,
                })}
                leftIcon={
                  <MovieIcon style={{ fontSize: "25px", color: "#00000066" }} />
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </>
          ) : (
            ""
          )}
        </SubMenu>
      )}

      {/* <SubMenu
        handleToggle={() => handleToggle("menuAbout", 6)}
        isOpen={openId === 7}
        sidebarIsOpen={open}
        name="Organization Chart"
        icon={<about.icon />}
        dense={dense}
      > */}

      {/* </SubMenu> */}

      {isXSmall && logout}
    </div>
  );
};

export default Menu;
