import DepartmentIcon from "@material-ui/icons/Group";
import AlertList from "./AlertList";
import AlertCreate from "./AlertCreate";
import AlertEdit from "./AlertEdit";

export default {
  list: AlertList,
  create: AlertCreate,
  edit: AlertEdit,
  icon: DepartmentIcon,
};
