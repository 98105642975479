import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  email,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import FullNameField from "./FullNameField";
import { LeaveTypes, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { leaveTypestyles } from "../helperStyle";
import ZoneSelectInput from "../components/ZoneSelectInput";
import RegionSelectInput from "../components/RegionSelectInput";
import BranchelectInput from "../components/BranchSelectInput";
import StateSelectInput from "../components/StateSelectInput";
import CitiesSelectInput from "../components/CitiesSelectInput";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export const validatePasswords = ({
  assessmentYearStartOn,
  assessmentEndOn,
}: {
  assessmentYearStartOn: any;
  assessmentEndOn: any;
}) => {
  const errors = {} as any;
  var date = new Date(assessmentYearStartOn);
  date.setDate(date.getDate() + 365);
  if (assessmentEndOn && assessmentEndOn! < date) {
    errors.assessmentEndOn =
      "Assessment Year End Date should be greater then 1 year";
  }

  return errors;
};

const useStyles = makeStyles(leaveTypestyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  let [update, setupdate] = React.useState(false);
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  let classes = useStyles();
  let newClass = classes;
  React.useEffect(() => {
    if (isSmall || isXsmall) {
      classes.code = newClass.name;
      setupdate(false);
    } else {
      classes.code = newClass.code;
      setupdate(true);
    }
  }, [isSmall, classes, isXsmall]);

  const billingCycleData = [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "13", name: "13" },
    { id: "14", name: "14" },
    { id: "15", name: "15" },
    { id: "16", name: "16" },
    { id: "17", name: "17" },
    { id: "18", name: "18" },
    { id: "19", name: "19" },
    { id: "20", name: "20" },
    { id: "21", name: "21" },
    { id: "22", name: "22" },
    { id: "23", name: "23" },
    { id: "24", name: "24" },
    { id: "25", name: "25" },
    { id: "26", name: "26" },
    { id: "27", name: "27" },
    { id: "28", name: "28" },
    { id: "29", name: "29" },
    { id: "30", name: "30" },
    { id: "31", name: "31" },
  ];

  return (
    <>
      {/* <h2 style={{ display: 'flex', fontSize: 25, backgroundColor: '#fff', padding: 10 }}>{props && props.location && props.location.pathname !== "/clientLocations/create" ? "Update Client Location" : "Add Client Location"}</h2> */}
      <SimpleForm {...props} validate={validatePasswords}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <TextInput
          autoFocus
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
          label="Client Location Name"
        />
        <FormDataConsumer
          formClassName={classes.code}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <ChildCompanySelectInput
              formClassName={classes.name}
              filter={{ isActive: true }}
            ></ChildCompanySelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={classes.code}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <ClientMasterSelectInput
              filter={{
                isActive: true,
                childCompanyId: formData.childCompanyId,
              }}
            ></ClientMasterSelectInput>
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <TextInput source="clientAdminName" formClassName={classes.name} />
        <TextInput
          type="email"
          source="email"
          formClassName={classes.code}
          validate={validateEmail}
        />
        <TextInput source="designation" formClassName={classes.code} />
        <SectionTitle label="" />
        <TextInput
          label="label.location_id"
          source="locationId"
          formClassName={classes.name}
        />
        <SelectInput
          resettable
          label="label.billingCycleFrom"
          formClassName={classes.code}
          source="billingCycle.from"
          choices={billingCycleData}
        />
        <SelectInput
          resettable
          label="label.billingCyleTo"
          formClassName={classes.code}
          source="billingCycle.to"
          choices={billingCycleData}
        />
        <SectionTitle label="" />
        <FormDataConsumer
          formClassName={classes.name}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => <ZoneSelectInput />}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={classes.code}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <RegionSelectInput
              filter={{ zoneId: formData.zoneId, isActive: true }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={classes.code}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <StateSelectInput
              filter={{ regionId: formData.regionId, isActive: true }}
            />
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <FormDataConsumer
          formClassName={classes.name}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <CitiesSelectInput
              filter={{ state: formData.state, isActive: true }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={classes.code}
          style={{ width: "220px" }}
        >
          {({ formData }: { formData: any }) => (
            <BranchelectInput
              filter={{
                city:
                  formData.city && formData.city._id
                    ? formData.city._id
                    : formData.city,
                isActive: true,
              }}
            />
          )}
        </FormDataConsumer>
        <TextInput
          label="label.address"
          source="clientLocationAddress"
          formClassName={classes.code}
        />

        <SectionTitle label="" />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="region"
          defaultValue={true}
          formClassName={classes.name}
        />
      </SimpleForm>
    </>
  );
};
const nameValidate = [
  required(),
  maxLength(100),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const validateEmail = [email()];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const LeaveTitle: FC<FieldProps<LeaveTypes>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
