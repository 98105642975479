import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { streamStyle } from "../helperStyle";
import {
  codeValidate,
  nameValidate,
  SectionTitle,
  validate,
} from "../helperValidate";
import { styleData, user } from "../helperConst";
import { SmFormHeader } from "../components/SmFormHeader";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import StreamSelectInput from "../components/StreamSelectInput";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(streamStyle);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <SmFormHeader {...props} />
      <SimpleForm {...props}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput record={formData}></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <StreamSelectInput validate={validate}></StreamSelectInput>
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <TextInput
              autoFocus
              source="name"
              formClassName={classes.name}
              validate={nameValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              source="code"
              formClassName={classes.code}
              validate={codeValidate}
              format={helperFunc.codeFormatter}
            />
          </div>
        </div>

        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </>
  );
};

export default CommonForm;
