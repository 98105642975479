import * as React from "react";
// import { FC } from "react";
import { Edit } from "react-admin";
import { PostEditToolbar } from '../components/PostEditToolbar';
import CommonFrom from '../cities/commonForm';

const CityEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.cities.page.edit"} {...props}>
      <CommonFrom toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};

export default CityEdit;
