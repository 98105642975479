import * as React from "react";
import { Edit } from "react-admin";

import CommonForm from "../leaveTypes/commonForm";
import { PostEditToolbar } from "../components/PostEditToolbar";

const LeaveEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"resources.leaveTypes.edit_title"} {...props}>
      <CommonForm
        toolbar={<PostEditToolbar titleSource="leaveName" />}
        {...props}
      />
    </Edit>
  );
};

export default LeaveEdit;
