import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import DeviceIcon from "@material-ui/icons/DeveloperBoard";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate, Link } from "react-admin";
import CardIcon from "../dashboard/CardIcon";
import { absentMember } from "../img";

interface Props {
  value?: number;
  data?: JSON;
  title?: string;
  src?: string;
  bgColor?: string;
  onClick?: any;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    // border: "1px solid #2a379785",
    // minHeight: 52,
    height: 100,
  },
  activeCard: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    border: "1px solid #2a379785",
    // minHeight: 52,
    height: 100,
  },
  title: {},
  card2: {
    float: "left",
    margin: "-20px 20px 0 15px",
    zIndex: 100,
    borderRadius: 3,
  },
  icon: {
    float: "right",
    width: 54,
    height: 54,
    padding: 14,
    color: "#fff",
  },
  activeBorder: {
    color: "1px solid #2a379785",
  },
});

const Cards: FC<Props> = ({ value, title, src, bgColor, onClick }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.main} onClick={onClick}>
      <Link>
        <Card className={classes.card2} style={{ backgroundColor: bgColor }}>
          <img
            className={classes.icon}
            src={src}
            // style={{ width: 100, height: 65 }}
            // alt={item.title}
            // src={
            //   item && item.image ? `${uploadApiUrl}${item.image}` : leaveDefault
            // }
          />
        </Card>
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary">
            {translate(title)}
          </Typography>
          <Typography variant="h5" component="h2">
            {value}
          </Typography>
        </Card>
      </Link>
    </div>
  );
};

export default Cards;
