import React, { useState } from "react";
import { useDataProvider } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { collegeReportStyle } from "../helperStyle";
import DialogueBox from "./DialogueBox";
import CommonReportForm from "../components/commonReportForm";
import { toastError } from "../components/toast";

const DatewiseAttendanceReport = (props: any) => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const useStyles = makeStyles(collegeReportStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const headerArr = ["Sr.No", "Date", "Present", "Absent"];
  const [responseData, setResponseData] = useState([]);
  const [viewlLoading, setViewLoading] = useState<any>(null);
  const [params, setParamsData] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [dataArr, setDataArr] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeStatus, setActiveStatus] = useState(String);

  const handleClickOpen = (data: any, status: any) => {
    setActiveStatus(status);
    setViewLoading(true);
    setOpen(true);
    handleDefaultPage(0);
    handleRowsPerPage(10);
    dataProvider
      .callCustom(`college-report/list`, {
        date: data,
        status: status,
        filter: params,
      })
      .then((response: any) => {
        setDataArr(response && response.data && response.data.data);
        setViewLoading(false);
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  return (
    <div className={isXSmall ? classes.smRootDiv : classes.rootDiv}>
      <DialogueBox
        loading={viewlLoading}
        dialogueData={dataArr}
        status={activeStatus}
        open={open}
        close={handleClose}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
      />
      <CommonReportForm
        apiName={"report2"}
        responseData={setResponseData}
        resource={props.resource}
        formdata={setParamsData}
      />

      {(responseData && responseData.length === 0) ||
      responseData === undefined ? (
        <Typography
          className={isXSmall ? classes.smNoRecordFound : classes.noRecordFound}
        >
          No Data Found
        </Typography>
      ) : (
        <div className={isXSmall ? classes.smTableDiv : classes.rtable}>
          <p className={classes.noteTextColor}>
            * To get List of Present/Absent students click on Count of
            Present/Absent
          </p>
          <>
            <TableContainer
              className={
                isXSmall ? classes.smtableContainer : classes.dateContainer
              }
            >
              <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headerArr && headerArr.length > 0
                      ? headerArr.map((item: any) => {
                          return (
                            <TableCell className={classes.header}>
                              {item}
                            </TableCell>
                          );
                        })
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {responseData &&
                    responseData.map((data: any, rowIndex: number) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {rowIndex + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              {new Date(data.date).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                data.present
                                  ? classes.greyTableCell
                                  : classes.tableCell
                              }
                              onClick={() =>
                                handleClickOpen(data.date, "Present")
                              }
                            >
                              {data.present}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                data.absent
                                  ? classes.greyTableCell
                                  : classes.tableCell
                              }
                              onClick={() =>
                                handleClickOpen(data.date, "Absent")
                              }
                            >
                              {data.absent}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
      )}
    </div>
  );
};
export default DatewiseAttendanceReport;
