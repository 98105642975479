import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Labeled } from "react-admin";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FieldProps, Attendance } from "../types";
import helperFunc from "../helperFuncs";
import GetAppIcon from "@material-ui/icons/GetApp";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  blueButton: {
    color: "blue",
    cursor: "pointer",
    marginRight: "10px",
  },
  downloadButton: {
    color: "blue",
    cursor: "pointer",
  },

  edit: {
    float: "right",
  },
}));

const AttandanceShow: FC<FieldProps<Attendance>> = ({ record }) => {
  const classes = useStyles();
  const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const handleClick = () => {
    window.open(`${apiUrlUpload}${record?.fileUrl}`, "_blank");
  };

  return record ? (
    <div className={classes.root}>
      {/* <IconButton aria-label="Show"> */}
      <VisibilityIcon className={classes.blueButton} onClick={handleClick} />
      <GetAppIcon
        onClick={() =>
          helperFunc.imageDownloader(
            `${uploadApiUrl + record?.fileUrl}`,
            record?.fileUrl
          )
        }
        className={classes.downloadButton}
      />
      {/* </IconButton> */}
    </div>
  ) : null;
};

export default AttandanceShow;
