import * as React from "react";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Employee } from "../types";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  root: { width: 25, maxWidth: 25, maxHeight: 25 },
  media: {
    width: "250px",
    height: "250px",
    objectFit: "cover",
  },
  logo: {
    width: "250px",
    height: "inherit",
  },
  logo2: {
    width: "100%",
    height: "110px",
  },
});
interface State {
  images?: string;
}
const AttendanceLogo: FC<FieldProps<Employee>> = ({ record }) => {
  const classes = useStyles();
  if (record && record.image) {
    return (
      <img
        src={
          record.image && uploadApiUrl
            ? uploadApiUrl + record.image
            : uploadApiUrl && uploadApiUrl + record
        }
        alt="Attendance"
        className={classes.logo}
      />
    );
  } else {
    if (record?.mode === "kiosk-QR") {
      return (
        <img
          src="assets/img/QRCodeSample.jpg"
          alt="visitorAttendance"
          className={classes.logo2}
        />
      );
    } else
      return (
        <img
          src="assets/img/emptyUser.png"
          alt="Attendance"
          className={classes.logo}
        />
      );
  }
};

export default AttendanceLogo;
