import WorkplaceList from "./WorkplaceList";
// import WorkplacesEdit from "./WorkplaceEdit";
// import WorkplaceCreate from "./WorkplaceCreate";
import { WorkplaceIcon } from "../svgIcon";
import { CommonWorkplaces } from "./WorkplacesCommon";

export default {
  list: WorkplaceList,
  create: CommonWorkplaces,
  edit: CommonWorkplaces,
  icon: WorkplaceIcon,
};
