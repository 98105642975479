import * as React from "react";
// import { FC } from "react";
import { Edit } from "react-admin";
import CommonForm from '../shifts/commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';

const ShiftEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Shift"} {...props}>
      <CommonForm toolbar={<PostEditToolbar />} {...props} />
    </Edit>
  );
};

export default ShiftEdit;
