import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslate, Link } from "react-admin";

import CardIcon from "./CardIcon";

interface Props {
  value?: number;
  data?: JSON;
}

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    borderBottom: "4px solid #ff9800",
  },
  title: {},
});

const TotalRoles: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Link to="/roles">
        <CardIcon Icon={AccessibilityNewIcon} bgColor="#ff9800" />
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary">
            {translate("pos.dashboard.total_roles")}
          </Typography>
          <Typography variant="h5" component="h2">
            {value}
          </Typography>
        </Card>
      </Link>
    </div>
  );
};

export default TotalRoles;
