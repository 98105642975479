import * as React from "react";
import { Create, Edit, useTranslate } from "react-admin";
import { useRedirect } from "ra-core";
import CommonForm from "./commonForm";
import { toastInfo } from "../components/toast";

export const SubAdminForm = (props: any) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { basePath, location, resource } = props;

  const resourceName = translate(`resources.${resource}.name`, resource);
  const onSuccess = () => {
    location && location.pathname === `/${resource}/create`
      ? toastInfo(`Employee created successfully`)
      : toastInfo(`Employee updated successfully`);
    redirect("list", basePath);
  };
  return (
    <>
      {location && location.pathname === `/${resource}/create` ? (
        <Create title={`Create Employee`} {...props} onSuccess={onSuccess}>
          <CommonForm redirect={`/${resource}`} {...props} />
        </Create>
      ) : (
        <Edit
          undoable={false}
          title={`Edit Employee`}
          {...props}
          onSuccess={onSuccess}
        >
          <CommonForm {...props} />
        </Edit>
      )}
    </>
  );
};
