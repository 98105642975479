import React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, Country } from "../types";

const ReasonLinkField: FC<FieldProps<Country>> = (props) =>
  props.record ? (
    <Link to={`/reasons/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

ReasonLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default ReasonLinkField;
