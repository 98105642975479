import CountryList from "./CountryList";
import CountryEdit from "./CountryEdit";
import CountryCreate from "./CountryCreate";
import { CountryIcon } from "../svgIcon";

export default {
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
  icon: CountryIcon,
};
