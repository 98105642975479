import React, { Fragment } from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  TextInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  FunctionField,
  Button as ReactButton,
  DateInput,
  useDataProvider,
  FormDataConsumer,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import jsonExport from "jsonexport/dist";
import { isActive } from "../helperChoice";
import { filterStyle, listActionsStyle } from "../helperStyle";
import { spaceValidate, trimValue } from "../helperValidate";
import helperFunc from "../helperFuncs";
import { exporterDetails } from "../components/export";
import { SmListHeader } from "../components/SmFormHeader";
import LinkField from "../components/LinkField";
import MobileGrid from "../components/MobileGrid";
import BulkDeleteButton from "../components/BulkDeleteButton";
import ImportButton from "../components/import";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const useStyles = makeStyles(filterStyle);
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div className={classes.divForm}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <TextInput
                      source="code"
                      helperText={false}
                      resettable
                      validate={spaceValidate}
                      format={helperFunc.codeFormatter}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <StreamSelectInput helperText={false}></StreamSelectInput>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <CourseSelectInput
                          helperText={false}
                          filter={{
                            isActive: true,
                            stream: formData.stream,
                          }}
                        ></CourseSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <SemesterSelectInput
                          helperText={false}
                          filter={{
                            isActive: true,
                            stream: formData.stream,
                            course: formData.course,
                          }}
                        ></SemesterSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      allowEmpty={true}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                      resettable
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      label="label.createdAt"
                      source="createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      allowEmpty={true}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const { className, basePath, handleDrawerOpen } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(listActionsStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  return (
    <TopToolbar className={isXsmall ? classes.flexrow : className}>
      <CreateButton basePath={basePath} />
      <Button
        className={classes.filterButton}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon className={classes.filterIcon} />
        {isSmall ? null : "Filter"}
      </Button>
      <Button
        className={classes.exportButton}
        color="primary"
        component="span"
        onClick={() => exporterDetails(dataProvider, props)}
      >
        <GetAppIcon className={classes.exportIcon} />
        {isSmall ? null : "Export"}
      </Button>
      <ImportButton basePath={basePath} {...props} />

      <Button
        className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
        color="primary"
        component="span"
        onClick={Sampleexporter}
      >
        <DescriptionIcon className={classes.descriptionIcon} />
        {!isSmall ? <span className={classes.csvSpan}>Sample CSV</span> : null}
      </Button>
    </TopToolbar>
  );
};

const StudentFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const Sampleexporter = () => {
  const records = [
    {
      student_name: "NameExample",
      student_code: "CodeExample",
      stream_name: "StreamExample",
      course_name: "CourseExample",
      semester_name: "SemesterExample",
      classRoom_name: "ClassroomExample",
      father_name: "FatherNameExample",
      mother_name: "MotherNameExample",
      parent_contact: "ParentContactExample",
      permanent_address: "PermanentAddressExample",
      current_address: "PermanentAddressExample",
      gender: "m",
      bloodGroup: "AB",
      phone: "1234567890",
      email: "aaa@gmail.com",
      birthdate: "01/01/2000",
      status: "true",
    },
    {
      student_name: "NameExample1",
      student_code: "CodeExample1",
      stream_name: "StreamExample1",
      course_name: "CourseExample1",
      semester_name: "SemesterExample1",
      classRoom_name: "ClassroomExample1",
      father_name: "fatherNameExample1",
      mother_name: "MotherNameExample1",
      parent_contact: "ParentContactExample1",
      permanent_address: "PermanentAddressExample1",
      current_address: "PermanentAddressExample1",
      gender: "f",
      bloodGroup: "O",
      phone: "9876543210",
      email: "bbb@gmail.com",
      birthdate: "01/01/2000",
      status: "true",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record;

    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "student_name",
        "student_code",
        "stream_name",
        "course_name",
        "semester_name",
        "classRoom_name",
        "father_name",
        "mother_name",
        "parent_contact",
        "permanent_address",
        "current_address",
        "gender",
        "bloodGroup",
        "phone",
        "email",
        "birthdate",
        "status",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "SampleStudentCsv"); // download as 'xxxx.csv` file
    }
  );
};

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const StudentList = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <SmListHeader {...props} />
      <List
        className="list-view2"
        {...props}
        filters={
          <StudentFilter
            {...props}
            handleDrawerClose={handleDrawerClose}
            open={open}
          />
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        // aside={<CountryListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <LinkField />
            <TextField source="code" format={helperFunc.codeFormatter} />
            <TextField source="stream.name" label="Stream" />
            <TextField source="course.name" label="Course" />
            <TextField source="semester.name" label="Semester" />
            <TextField source="classroom.name" label="Classroom" />
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField
              source="isActive"
              label="label.status"
              valueLabelTrue="Active"
              valueLabelFalse="In-active"
              emptyText="N/A"
            />
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default StudentList;
