import React from "react";
import { Menu, MenuItem } from "@material-ui/core";

const PopOver = (props: any) => {
  const { options, anchorEl, handleClose } = props;
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        },
      }}
    >
      {options.map(
        (option: any) =>
          option?.isPermission && (
            <MenuItem
              key={option?.label}
              onClick={() => handleClose(option?.label)}
            >
              {option?.label}
            </MenuItem>
          )
      )}
    </Menu>
  );
};
export default PopOver;
