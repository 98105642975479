import StreamList from "./streamList";
import { CommonStream } from "./streamCommon";
import { StreamIcon } from "../svgIcon";

export default {
  list: StreamList,
  create: CommonStream,
  edit: CommonStream,
  icon: StreamIcon,
};
