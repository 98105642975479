import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";
import { stringify } from "query-string";
import FullNameField from "./FullNameField";
import { FieldProps, Department } from "../types";
import { parse } from "querystring";

const EmployeeDocumentLinkField: FC<FieldProps<Department>> = (props: any) => {
  let employeeId;
  if (props?.location?.search !== "") {
    employeeId = parse(props.location.search) as any;
  }

  let filter;

  if (employeeId && employeeId["?filter"]) {
    filter = JSON.parse(employeeId["?filter"]);
  }

  const filterEmployeeId = filter?.employee;
  return props.record ? (
    <Link
      to={{
        pathname: `/${props.resource}/${props.record._id}`,
        state: { id: filterEmployeeId, name: employeeId?.state },
      }}
    >
      <FullNameField {...props} />
    </Link>
  ) : null;
};

EmployeeDocumentLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default EmployeeDocumentLinkField;
