import * as React from "react";
import { FC, memo, useState } from "react";

import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { FieldProps, Visitor, Company } from "../types";
import helperFunc from "../helperFuncs";
import useDeepCompareEffect from "use-deep-compare-effect";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  company?: Company[];
}

interface Props {
  helperText?: boolean;
  labelName?: string;
  defaultValue?: string;
  filter?: any;
  dataManage?: any;
  disabled?: any;
}

interface Props extends FieldProps<Visitor> {}

const CompanyReferenceInput: FC<Props> = ({
  labelName,
  helperText,
  filter,
  dataManage,
  defaultValue,
  disabled,
}) => {
  const form = useForm();
  const user = helperFunc.getUserData();
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();

  useDeepCompareEffect(() => {
    if (user && user.type === "super") {
      dataProvider
        .getSearchList("companies/search", { filter })
        .then((response: any) => {
          setState((state: any) => ({
            ...state,
            company: response && response.data,
            data: true,
          }));
        });
    }
  }, [dataProvider]);
  const { company } = state;

  const check = (value: any) => {
    company?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "company");
        }
      }
    });
  };

  return user && user.type === "super" ? (
    <SelectInput
      resettable
      onChange={(value: any) => check(value.target)}
      className={classes.root}
      label={labelName ? labelName : "Company"}
      source="company"
      helperText={helperText}
      choices={company}
      validate={requiredValidate}
      id="CompanyReferenceInput"
      defaultValue={defaultValue}
      disabled={disabled}
    />
  ) : null;
};
const requiredValidate = [required()];
CompanyReferenceInput.defaultProps = {
  source: "company",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CompanyReferenceInput);
