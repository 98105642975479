import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../emailTemplates/commonForm";


const EmailTemplateCreate = (props: any) => {
  return (
    <Create {...props} title="resources.emailTemplate.create_title">
      <CommonForm redirect={'/email-template'} {...props} />
    </Create>
  );
};

export default EmailTemplateCreate;
