import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider, ReferenceInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";
const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    maxWidth: "240px",
  },
}));

interface State {
  streams?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  emptyValue?: any;
  helperText?: boolean;
  labelName?: string;
  textWrap?: boolean;
  defaultValue?: string;
}

const ActiveTypeSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  emptyValue,
  propsUrl,
  helperText,
  labelName,
  textWrap,
  defaultValue,
}) => {
  const [state, setState] = useState<State>({});
  const form = useForm();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const reasonsSearchUrl = "reasons/search";

  // useEffect(() => {
  //   dataProvider
  //     .getSearchList(propsUrl ? propsUrl : reasonsSearchUrl, filter)
  //     .then((response: any) => {
  //       response &&
  //         response.data.forEach((item: any) => {
  //           item.name = `${item.name}`;
  //         });
  //       setState((state) => ({
  //         ...state,
  //         streams: response && response.data,
  //       }));
  //     });
  // }, [filter]);

  // if (emptyValue && emptyValue.emptyValue) {
  //   form.change("stream", undefined);
  // }
  const choices = [
    {
      name: "OPENID",
      id: "openId",
    },
    {
      name: "SAML",
      id: "saml",
    },
    {
      name: "LDAP",
      id: "ldap",
    },
  ];

  const check = (value: any) => {
    choices?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "type");
        }
      }
    });
  };

  return (
    <SelectInput
      label={labelName ? labelName : "Type"}
      source="activeDirectory.type"
      validate={validate}
      choices={choices}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
      className={textWrap ? classes.root : ""}
      // id="ReasonSelectInput"
      // initialValue={"10"}
    />
  );
};

ActiveTypeSelectInput.defaultProps = {
  source: "name",
  label: "resources.customers.fields.name",
};

export default memo<Props>(ActiveTypeSelectInput);
