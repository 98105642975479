import * as React from "react";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor } from "../types";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles({
  root: { width: 25, maxWidth: 25, maxHeight: 25 },
  media: {
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    objectPosition: "center center",
    width: "100%",
  },
});

const AttendanceLogo: FC<FieldProps<Visitor>> = ({ record }) => {
  const classes = useStyles();
  return record && record ? (
    <img
      src={record && record.image ? uploadApiUrl + record.image : record.image}
      className={classes.media}
      alt={record.name}
    />
  ) : null;
};

export default AttendanceLogo;
