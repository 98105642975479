import * as React from "react";
import { Link, useDataProvider } from "react-admin";

import { PopOver } from "../common";
import { toastError, toastInfo } from "../components/toast";
import ReasonDialogueBox from "../teamRequests/reasonDialogueBox";
import helperFunc from "../helperFuncs";

import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AttendanceDialogueBox from "./AttendanceDialogueBox";

const RequestLinkField = (props: any) => {
  const { record } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState("");
  const [dialogueOpen, setDialogueOpen] = React.useState(false);
  const [clickedEmp, setClickedEmp] = React.useState(false);

  const user = helperFunc.getUserData();

  const options = [
    { label: "Change Attendance", isPermission: user.isChangeAttendance },
    { label: "Remarks", isPermission: user.isAttendanceRemark },
  ];

  const handleClick = (event: any, record: any) => {
    setClickedEmp(record);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option: any) => {
    setAnchorEl(null);
    if (typeof option !== "object") {
      setActiveMenu(option);
      handleDialogueOpen();
    } else {
      setActiveMenu("");
    }
  };

  const handleDialogueOpen = () => {
    setDialogueOpen(true);
  };
  const handleDialogueClose = () => {
    setDialogueOpen(false);
  };

  return (
    <>
      {user.isChangeAttendance || user.isAttendanceRemark ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          style={{ color: "blue" }}
          onClick={(event) => handleClick(event, record)}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        ""
      )}

      <PopOver
        options={options}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <AttendanceDialogueBox
        open={dialogueOpen}
        activeMenu={activeMenu}
        setAnchorEl={setAnchorEl}
        handleCloseClick={handleDialogueClose}
        clickedEmp={clickedEmp}
      ></AttendanceDialogueBox>
    </>
  );
};

RequestLinkField.defaultProps = {
  source: "",
};

export default RequestLinkField;
