import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from './commonForm';
import { PostEditToolbar } from '../components/PostEditToolbar';


const EmployeeVehicleEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Vehicle"} {...props}>
      <CommonForm toolbar={<PostEditToolbar titleSource="createdBy" />} {...props} />
    </Edit>
  );
};


export default EmployeeVehicleEdit;
