import * as React from "react";
import { Create } from "react-admin";
import CommonFrom from '../cities/commonForm';


const CityCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonFrom redirect={'/cities'} {...props} />
    </Create>
  );
};


export default CityCreate;
