import * as React from "react";
import { SaveButton, Toolbar } from "react-admin";
import DeleteButtonWithConfirmation from "./DeleteButtonWithConfirmation";
import { toastInfo } from "./toast";

const onSuccess = () => {
  toastInfo(`Record deleted successfully`);
};

export const PostEditToolbar = (props: any) => (
  <div style={{ display: window.screen.width < 460 ? "flex" : "" }}>
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButtonWithConfirmation
        undoable={false}
        titleSource={props && props.titleSource ? props.titleSource : "name"}
        resource={props.resource}
        onSuccess={onSuccess}
      />
    </Toolbar>
  </div>
);
