import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "./commonForm";

const WeekOffCreate = (props: any) => {
  return (
    <Create {...props} title="resources.sundayOffList.create_title">
      <CommonForm redirect={'/sundayOff'} {...props} />
    </Create>
  );
};


export default WeekOffCreate;
