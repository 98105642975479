import React from 'react'
import { Link } from 'react-router-dom'


interface Props {
    
}

const Footer = (props: Props) => {
    return (
        <React.Fragment>
        <footer className="footer-section">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-footer wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div className="footer-logo text-center">
                    <img
                      src="assets/img/Turnout-logo.svg"
                      alt="logo"
                      width="180"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-footer wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <ul className="footer-social text-center">
                    <li>
                      <a href="#" className="tw">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="fb">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="pin">
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="ln">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-footer text-center wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
                >
                  <h3 className="mb-1">Reach Out Now!</h3>
                  <ul className="footer-contact-info text-center">
                    {/* <li><span>Email:</span> <a href="mailto:
                           info@rydotinfotech.com">
                                            info@turnout.com</a></li>
                                        <li><span>Phone:</span>
                                         <a href="tel:+44587154756">(+91) 7698 855 855</a></li> */}
                  </ul>
                  <div className="right-bar-icon d-block ml-md-4 ml-lg-4 ml-xl-4 ml-0 pr-0  text-center">
                    <Link className="theme-btn btn-login " to="/contact">
                      Request a Demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="copyright text-center wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <p>
                    &copy; {`${new Date().getFullYear()} All Rights Reserved.`}{" "}
                    <span>Turnout</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </React.Fragment>
    )
}

export default Footer
