import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { departmentstyles } from "../helperStyle";
import helperFunc from "../helperFuncs";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(departmentstyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props && props.location && props.location.pathname !== "/zones/create"
          ? "Update Zone"
          : "Add Zone"}
      </h2>
      <SimpleForm {...props}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <div style={{ display: "inline" }}>
          <TextInput
            autoFocus
            resource="zones"
            source="name"
            formClassName={classes.name}
            validate={nameValidate}
            label="Zone Name"
          />
          <TextInput
            source="code"
            resource="zones"
            formClassName={isXsmall ? classes.name : classes.code}
            validate={codeValidate}
            format={helperFunc.codeFormatter}
          />
        </div>
        {/* <TextInput
                        // fullWidth
                        label="Reporting Email Id"
                        type="email"
                        source="reportingEmail"
                    // formClassName={classes.code}
                    // validate={validateEmail}
                    /> */}
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="zones"
          defaultValue={true}
        />
      </SimpleForm>
    </React.Fragment>
  ) : (
    <SimpleForm {...props}>
      {/* <SectionTitle label="resources.zones.page.edit" /> */}
      <CompanyReferenceInput></CompanyReferenceInput>
      <TextInput
        autoFocus
        resource="zones"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Zone Name"
      />
      <TextInput
        source="code"
        resource="zones"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      {/* <TextInput
        // fullWidth
        style={{ width: "600px" }}
        label="Reporting Email Id"
        type="email"
        source="reportingEmail"
        // formClassName={classes.code}
        // validate={validateEmail}
      /> */}
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="zones"
        defaultValue={true}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
