import ShiftList from "./ShiftList";
import ShiftEdit from "./ShiftEdit";
import ShiftCreate from "./ShiftCreate";

import ShiftIcon from "@material-ui/icons/Schedule";

export default {
  list: ShiftList,
  create: ShiftCreate,
  edit: ShiftEdit,
  icon: ShiftIcon,
};
