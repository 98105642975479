import * as React from "react";
import { FC } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  // Link,
  // IconButton,
  // CardMedia,
  Box,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import helperFunc from "./../../helperFuncs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery, Theme } from "@material-ui/core";
interface Props {
  value?: number;
  data?: any;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    width: "30%",
    padding: 5,
    marginRight: "1em",
    height: "265px",
  },
  mobileRoot: {
    width: window.screen.width - 28,
    // height: "265px",
    padding: "5px",
    marginRight: "1em",
  },
  card: {
    flex: 1,
    overflow: "inherit",
    // textAlign: "right",
    padding: 16,
    // width: "50%",
    // paddingTop: 38
    // minHeight: 52,
  },
  cardImg: {
    // flex: 1,
    float: "left",
    overflow: "inherit",
    // textAlign: "right",
    padding: 16,
    // width: "40%",
    minHeight: 52,
  },
  chart: {
    // marginLeft: "3em",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    marginTop: "8px",
    marginLeft: "8px",
  },
  text: {
    // marginLeft: "3em",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    alignContent: "flex-start",
  },
}));

const EmployeeTimeCard: FC<Props> = (data) => {
  const translate = useTranslate();
  const classes = useStyles();
  const actualData = data.data;
  const loading = data.isLoading;
  // const dataObj = {
  //   ...{ shift_time: { inTime: 0, outTime: 0 }, in_time: "" },
  //   actualData,
  // };
  // const preventDefault = (event: React.SyntheticEvent) =>
  //   event.preventDefault();
  // const theme = useTheme();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <Card className={isXSmall ? classes.mobileRoot : classes.root}>
      <CardHeader title={translate("pos.dashboard.employee_time")} />
      {loading ? (
        <span
          style={{
            width: "100%",
            height: "125px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <div className={classes.chart}>
          {/* <CardContent className={classes.card}> */}
          <Box className={classes.cardImg}>
            <img
              src="icons/employee_time.png"
              title={translate("pos.dashboard.employee_time")}
            ></img>
          </Box>
          <div className={classes.text}>
            <Typography variant="subtitle1" color="textSecondary">
              Shift Time:{" "}
              {actualData && actualData.shift_time
                ? actualData.shift_time.inTime
                : 0}{" "}
              TO{" "}
              {actualData && actualData.shift_time
                ? actualData.shift_time.outTime
                : 0}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              In Time:{" "}
              {actualData &&
              actualData.in_time &&
              helperFunc.isoToDate(actualData.in_time) !== "Invalid Date"
                ? helperFunc.commonDateFormat(actualData.in_time, "HH:mm:ss")
                : 0}
            </Typography>
          </div>
          {/* </CardContent> */}
        </div>
      )}
    </Card>
  );
};

export default EmployeeTimeCard;
