// in src/comments.js
import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";

import { DateField, EditButton, useTranslate } from "react-admin";
import { Identifier } from "ra-core";
import { Country } from "../types";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  root: { margin: "1em" },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",

  },
  cardTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContent: {
    ...theme.typography.body1,
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  ids?: Identifier[];
  data?: { [key: string]: Country };
  basePath?: string;
}

const MobileGrid: FC<Props> = ({ ids, data, basePath }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h5>{`${data[id].leaveName} `}</h5>
                <h6>{`${data[id].code} `}</h6>
                <EditButton
                  resource="countries"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate("label.createdAt")} :
              &nbsp;
              <DateField record={data[id]} source="createdAt" type="date" />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
