import * as React from "react";
import { Create, Edit, useTranslate } from "react-admin";
import { useRedirect } from "ra-core";
import CommonForm from "./commonForm";
import { PostEditToolbar } from "../components/PostEditToolbar";
import { toastInfo } from "../components/toast";

export const CommonCourse = (props: any) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { basePath, location, resource } = props;
  const resourceName = translate(`resources.${resource}.name`, resource);
  const onSuccess = () => {
    location && location.pathname === `/${resource}/create`
      ? toastInfo(`${resourceName} created successfully`)
      : toastInfo(`${resourceName} updated successfully`);
    redirect("list", basePath);
  };
  return (
    <>
      {location && location.pathname === `/${resource}/create` ? (
        <Create {...props} onSuccess={onSuccess}>
          <CommonForm redirect={`/${resource}`} {...props} />
        </Create>
      ) : (
        <Edit
          undoable={false}
          title={`Edit ${resourceName}`}
          {...props}
          onSuccess={onSuccess}
        >
          <CommonForm toolbar={<PostEditToolbar />} {...props} />
        </Edit>
      )}
    </>
  );
};
