import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "./CommonForm";

const DeviceCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonForm redirect={'/devices'} {...props} />
    </Create>
  );
};

export default DeviceCreate;
