import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";
import { Link } from "react-admin";
import {
  Button,
  Box,
  useMediaQuery,
  // IconButton
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
// import TextField from "@material-ui/core/TextField";
import { createMuiTheme, makeStyles, Theme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import { Notification } from "react-admin";
import { useTranslate as authTrans, TextInput } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";
import { lightTheme } from "./themes";
// import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import helperFunc from "../helperFuncs";
import { toastError } from "../components/toast";

const isDefaultImage = process.env.REACT_APP_DATA_PROVIDER_IS_DEFAULT_IMAGE;

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    background: `${
      isDefaultImage === "true" ? "url(/auth-bg.jpg)" : "url(/zucampus.jpeg)"
    }`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 315,
    marginTop: "0em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },

  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  inputBox: {
    padding: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  actionNew: {
    textAlign: "right",
    padding: "0 1em 1em 1em",
  },
  icon: { paddingRight: "0.25em" },
  links: {
    display: "inline-flex",
    alignItems: "center",
  },
  copyright: {
    position: "fixed",
    // width: 100%;
    left: 0,
    bottom: "-16px",
    fontSize: "13px",
    alignSelf: "bottom",
    backgroundColor: "#424242",

    color: "#ffffff",
    height: "48px",
    padding: "7px 0px",
    // bottom: 0,
    top: "auto",
    zIndex: 1100,
    width: "100%",
    textAlign: "center",
  },
  mobileCopyright: {
    position: "absolute",
    left: 0,
    bottom: "-15px",
    fontSize: "13px",

    backgroundColor: "#424242",

    color: "#ffffff",
    // height: "30px",
    padding: "7px 0px",
    // bottom: 0,
    top: "auto",
    // position: "fixed",
    zIndex: 1100,
    width: "100%",
    textAlign: "center",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextInput
    error={!!(touched && error)}
    // helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
  footerLabeling?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  helperFunc.clearFooter();
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  // const customTranslate = authTrans();
  const classes = useStyles();
  // const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, "/dashboard").catch((error: Error) => {
      setLoading(false);
      toastError(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message
      );
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <div className={classes.main}>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Link to="/">
                    <img src="/turnout-blue.png" alt="" />
                  </Link>
                  {/* <Avatar className={classes.icon}>
                                    <LockIcon />
                                    
                                </Avatar> */}
                </div>
                {/* <div className={classes.hint}>
                                Hint: demo / demo
                            </div> */}
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      id="username"
                      name="username"
                      // @ts-ignore
                      component={renderInput}
                      label={"Email or Phone or Employee Code"}
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      id="password"
                      name="password"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    ></Field>
                    {/* <IconButton aria-label="Show" color="primary" >
                      <VisibilityIcon />
                    </IconButton> */}
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    id="submit"
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
                <Box component="div" className={classes.actionNew}>
                  <Button
                    id="forgot"
                    size="small"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: "/forgotPassword",
                    }}
                    className={classes.links}
                  >
                    <HelpIcon className={classes.icon}></HelpIcon>
                    Forgot password
                  </Button>
                </Box>
              </Card>
              <Notification />
            </div>
          </form>
        )}
      />
      <p className={isSmall ? classes.mobileCopyright : classes.copyright}>
        Copyright ©{new Date().getFullYear()} All Rights Reserved | Designed And
        Developed By{" "}
        <a
          style={{ color: "#ADD8E6", textDecoration: "none" }}
          href="http://demo.turnout.cloud"
        >
          Turnout
        </a>
      </p>
    </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
