import React, { FC, Fragment, useState } from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  DateInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  email,
} from "react-admin";
import { useMediaQuery, Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { streamStyle } from "../helperStyle";
import {
  altmobValidate,
  codeValidate,
  mobValidate,
  nameValidate,
  SectionTitle,
  validate,
} from "../helperValidate";
import { styleData, user } from "../helperConst";
import { SmFormHeader } from "../components/SmFormHeader";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import { Employee, FieldProps } from "../types";
import RegistrationToolbar from "./RegistrationToolbar";
import DeRegistrationToolbar from "./DeRegistrationToolbar";
import ClassroomSelectInput from "../components/ClassroomSelectInput";
import { bloodGroup, gender } from "../helperChoice";
import helperFunc from "../helperFuncs";

const useStyles = makeStyles(streamStyle);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [semesterChange, setSemesterChange] = useState(Boolean);

  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };
  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };
  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };
  return (
    <Fragment>
      <SmFormHeader {...props} />
      {props.location &&
      props.location.pathname !== `/${props.resource}/create` ? (
        <Box
          component="div"
          className={
            isXsmall ? classes.mobileInlineButton : classes.inlineButton
          }
        >
          {props && Object.keys(props.record).length !== 0 ? (
            <EmployeeRegistration
              record={props && props.record}
            ></EmployeeRegistration>
          ) : null}
        </Box>
      ) : null}
      <SimpleForm {...props}>
        {user && user.type === "super" ? (
          <FormDataConsumer formClassName={classes.name}>
            {({ formData }: { formData: any }) => (
              <CompanyReferenceInput record={formData}></CompanyReferenceInput>
            )}
          </FormDataConsumer>
        ) : null}
        <SectionTitle label="" />
        <FormDataConsumer formClassName={classes.name}>
          {({ formData }: { formData: any }) => (
            <StreamSelectInput
              validate={validate}
              dataManage={streamData}
            ></StreamSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={isXsmall ? classes.name : classes.code}
        >
          {({ formData }: { formData: any }) => (
            <CourseSelectInput
              validate={validate}
              filter={{ stream: formData.stream }}
              dataManage={courseData}
              streamChange={streamChange}
            ></CourseSelectInput>
          )}
        </FormDataConsumer>
        <SectionTitle label="" />
        <FormDataConsumer
          formClassName={isXsmall ? classes.name : classes.name}
        >
          {({ formData }: { formData: any }) => (
            <SemesterSelectInput
              validate={validate}
              filter={{ stream: formData.stream, course: formData.course }}
              courseChange={courseChange}
              streamChange={streamChange}
              dataManage={semesterData}
              // emptyValue={emptyValue}
            ></SemesterSelectInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.code}>
          {({ formData }: { formData: any }) => (
            <ClassroomSelectInput
              filter={{
                stream: formData.stream,
                course: formData.course,
                semester: formData.semester,
                isActive: true,
              }}
              validate={validate}
              streamChange={streamChange}
              courseChange={courseChange}
              semesterChange={semesterChange}
            ></ClassroomSelectInput>
          )}
        </FormDataConsumer>

        <SectionTitle label="" />
        <TextInput
          autoFocus
          source="name"
          label="Name"
          formClassName={classes.name}
          validate={nameValidate}
          helperText={false}
        />
        <TextInput
          source="code"
          label="Enrollment No."
          formClassName={classes.code}
          validate={codeValidate}
          helperText={false}
          format={helperFunc.codeFormatter}
        />
        <RadioButtonGroupInput
          source="gender"
          formClassName={isXsmall ? null : classes.code}
          fullWidth
          choices={gender}
          helperText={false}
          validate={validate}
        />
        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <DateInput
              autoFocus
              source="birthdate"
              label="Date of Birth"
              formClassName={classes.name}
              validate={nameValidate}
              inputProps={{
                min: "1900-08-10",
                max: "3000-08-20",
              }}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              label="label.mobile"
              source="phone"
              formClassName={classes.name}
              validate={mobValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <AutocompleteInput
              source="bloodGroup"
              formClassName={isXsmall ? null : classes.code}
              choices={bloodGroup}
              // validate={validate}
            />
          </div>
        </div>

        <SectionTitle label="" />
        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <TextInput
              autoFocus
              source="fatherName"
              label="Father Name"
              formClassName={classes.code}
              // validate={nameValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              source="motherName"
              label="Mother Name"
              formClassName={classes.code}
              // validate={nameValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              source="parentContact"
              label="Parent's Contact No."
              formClassName={classes.code}
              validate={altmobValidate}
            />
          </div>
        </div>

        <div className={isSmall ? classes.inlineDiv : classes.flexDiv}>
          <div>
            <TextInput
              fullWidth
              autoFocus
              source="currentAddress"
              label="Current Address"
              formClassName={classes.name}
              validate={nameValidate}
            />
          </div>
          <div style={isSmall || isXsmall ? undefined : styleData}>
            <TextInput
              source="permanentAddress"
              label="Permanent Address"
              formClassName={classes.code}
              // validate={nameValidate}
            />
          </div>
        </div>

        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          fullWidth={isXsmall ? null : true}
          formClassName={isXsmall ? null : classes.email}
          validate={[email()]}
        />

        <BooleanInput
          source="isActive"
          label="label.status"
          defaultValue={true}
        />
      </SimpleForm>
    </Fragment>
  );
};

const EmployeeRegistration: FC<FieldProps<Employee>> = (record: any) => {
  return record && record.record && record.record.isRegistred ? (
    <DeRegistrationToolbar record={record} />
  ) : (
    <RegistrationToolbar record={record} />
  );
};

export default CommonForm;
