import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";

import FullNameField from "./FullNameField";
import { FieldProps, City } from "../types";

const CityLinkField: FC<FieldProps<City>> = (props) =>
  props.record ? (
    <Link to={`/cities/${props.record._id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

CityLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default CityLinkField;
