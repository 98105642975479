import * as React from "react";
import { Create } from "react-admin";
import CommonForm from "../departments/commonForm";


const DepartmentCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonForm redirect={'/departments'} {...props} />
    </Create>
  );
};

export default DepartmentCreate;
