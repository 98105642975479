export const isActive = [
  { id: "true", name: "label.active" },
  { id: "false", name: "label.inActive" },
];

export const isRegistred = [
  { id: true, name: "Registered" },
  { id: false, name: "Not Registered" },
];
export const gender = [
  { id: "m", name: "label.male" },
  { id: "f", name: "label.female" },
  // { id: "o", name: "label.other" },
];
export const alertNotification = [
  { id: "email", name: "Email" },
  { id: "inapp", name: "In APP" },
];
export const vehicleType = [
  { id: "twoWheeler", name: "Two Wheeler" },
  { id: "fourWheeler", name: "Four Wheeler" },
  { id: "none", name: "None" },
];
export const auth = [
  { id: "in", name: "In" },
  { id: "out", name: "Out" },
];

export const bloodGroup = [
  { id: "A+", name: "A+" },
  { id: "A-", name: "A-" },
  { id: "AB+", name: "AB+" },
  { id: "AB-", name: "AB-" },
  { id: "B+", name: "B+" },
  { id: "B-", name: "B-" },
  { id: "O+", name: "O+" },
  { id: "O-", name: "O-" },
];

export const maritalStatus = [
  { id: "married", name: "label.married" },
  { id: "single", name: "label.single" },
  { id: "divorced", name: "label.divorced" },
  { id: "widowed", name: "label.widowed" },
];

export const weekDays = [
  { id: "0", name: "Sun" },
  { id: "1", name: "Mon" },
  { id: "2", name: "Tue" },
  { id: "3", name: "Wed" },
  { id: "4", name: "Thu" },
  { id: "5", name: "Fri" },
  { id: "6", name: "Sat" },
];

export const approvalStatus = [
  { id: "approve", name: "label.approve" },
  { id: "reject", name: "label.reject" },
  { id: "pending", name: "label.pending" },
  { id: "cancel", name: "label.cancel" },
];
export const aprroverStatus = [
  { id: "approve", name: "label.approve" },
  { id: "cancel", name: "label.cancel" },
];
export const rejectedStatus = [{ id: "reject", name: "label.reject" }];

export const leaveType = [
  { id: "fullLeave", name: "label.fullLeave" },
  { id: "halfLeave", name: "label.halfLeave" },
];
export const halfLeaveType = [
  { id: "firstHalf", name: "First Half" },
  { id: "secondHalf", name: "Second Half" },
];

export const deviceLocation = [
  { id: "in", name: "label.in" },
  { id: "out", name: "label.out" },
];
export const requestTypeArr = [
  { id: "early_check_out", name: "Early Check Out" },
  { id: "late_check_in", name: "Late Check In" },
  { id: "missed_checkin", name: "Missed Check In" },
  { id: "missed_checkout", name: "Missed Check Out" },
  { id: "in_between_check_out", name: "In Between Check Out" },
  { id: "procurement", name: "Procurement" },
  { id: "over_time", name: "Over Time" },
  { id: "attendancechangerequest", name: "Attendance Change Request" },
  { id: "other", name: "Other" },
];

export const deviceType = [
  { id: "mobile", name: "Mobile" },
  { id: "edge", name: "Edge" },
  { id: "manual", name: "Manual" },
  { id: "auto", name: "Auto" },
  { id: "Kiosk", name: "Kiosk" },
  { id: "turnout-A", name: "Turnout A" },
];

export const userType = [
  { id: "employee", name: "label.employee" },
  { id: "visitor", name: "label.visitor" },
];

export const type = [
  { id: "information", name: "label.information" },
  { id: "warning", name: "Warning" },
];

export const allDevices = [
  { id: "turnoutE", name: "label.turnoutE" },
  { id: "turnoutR", name: "label.turnoutR" },
  { id: "turnoutA", name: "label.turnoutA" },
  { id: "turnoutK", name: "label.turnoutK" },
];

export const turnoutE = [{ id: "turnoutE", name: "label.turnoutE" }];

export const turnoutR = [{ id: "turnoutR", name: "label.turnoutR" }];
export const turnoutA = [{ id: "turnoutA", name: "label.turnoutA" }];
export const turnoutC = [{ id: "turnoutC", name: "label.turnoutC" }];

export const turnoutDevices = [
  // { _id: 'attandance_manual', name: 'Manual Attendance Module' },
  { id: "turnoutE", name: "label.turnoutE" },
  { id: "turnoutR", name: "label.turnoutR" },
  { id: "turnoutA", name: "label.turnoutA" },
  { id: "all", name: "label.all" },
];

export const addons = [
  // { _id: 'attandance_manual', name: 'Manual Attendance Module' },
  { _id: "attandance_auto", name: "label.video_attendance_management" },
  { _id: "project", name: "label.project_task_management" },
  { _id: "leave", name: "label.leave_Management" },
];

export const templateType = [
  { id: "alerts", name: "Alerts" },
  { id: "approvedattendance", name: "Approved attendance" },
  { id: "askforrectification", name: "Ask for rectification" },
  { id: "contactus", name: "Contact Us" },
  { id: "forgotpassword", name: "label.forgotPassword" },
  { id: "leave", name: "label.leave" },
  { id: "leaveapprove", name: "Leave Approve" },
  { id: "leavecancel", name: "Leave Cancel" },
  { id: "leaveedit", name: "Leave Edit" },
  { id: "leavereject", name: "Leave Reject" },
  { id: "leavereply", name: "Leave reply" },
  { id: "request", name: "label.request" },
  { id: "requestforapproval", name: "Request for approval" },
  { id: "thankyou", name: "Thank you" },
  { id: "visitorQrCode", name: "Visitor QR Code" },
  { id: "visitorqrreject", name: "Visitor QR Code Reject" },
  { id: "invitevisitor", name: "Invite Visitor Link" },
  { id: "selfemployeecreated", name: "Self Employee Create" },
];

export const leaveTypeStatus = [
  { id: "approve", name: "label.approve" },
  { id: "reject", name: "label.reject" },
  { id: "pending", name: "label.pending" },
  { id: "cancel", name: "label.cancel" },
];
export const joiningConfirmDate = [
  { id: "dateOfJoining", name: "Date of Joining" },
  { id: "dateOfConfirm", name: "Date of Confirmation" },
];
