import React, { useEffect } from "react";
import { useMediaQuery, Theme } from "@material-ui/core";

const usePageTitleHook = (title: string) => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = `${title}`;
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);
  return {};
};

export default usePageTitleHook;
