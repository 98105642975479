import * as React from "react";
import { useState, useEffect } from "react";
import { Admin, Resource, TranslationProvider } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import "./App.css";
import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
import about from "./about";
import orders from "./orders";
import products from "./products";
import invoices from "./invoices";
import categories from "./categories";
import companies from "./companies";
import stream from "./stream";
import course from "./course";
import semester from "./semester";
import classroom from "./classroom";
import subject from "./subject";
import faculty from "./faculty";
import student from "./student";
import cameraVenue from "./cameraVenue";
import timeslots from "./timeslot";
import assignTimeslot from "./assignTimeslot";
import facultyTimetable from "./facultyTimetable";
import collegeAttedance from "./collegeAttendance";
import studentwiseAttendanceReport from "./studentwiseAttendanceReport";
import datewiseAttendanceReport from "./datewiseAttedanceReport";
import lecturewiseAttendanceReport from "./lecturewiseAttendanceReport";

import subjectwiseAttendanceReport from "./subjectwiseAttedanceReport";
import todayTimetableReport from "./todayTimetableReport";
import studentMultiRegistration from "./studentMultiRegistration";
import clientMaster from "./clientMaster";
import clientLocation from "./clientLocation";
import roles from "./roles";
import reviews from "./reviews";
import zones from "../src/zones";
import regions from "../src/regions";
import countries from "./countries";
import states from "./states";
import cities from "./cities";
import departments from "./departments";
import designations from "./designations";
import profile from "./profile";
import settings from "./settings";
import shifts from "./shifts";
import visitors from "./visitors";
import employees from "./employees";
import leaveTypes from "./leaveTypes";
import attendance from "./attendance";
import vehicleAttendance from "./vehicleAttendance";
import unknownAttendance from "./unknownAttendance";
import attendanceReport from "./attendanceReport";
import devices from "./devices";
import requests from "./requests";
import employeeVehicle from "./employeeVehicle";
import userType from "./employeeType";
import notifications from "./notifications";
import notification from "./notification";
import workplaces from "./workplaces";
import leaveReport from "./leaveReport";
import attendanceRequest from "./attendanceRequest";
import dataProviderFactory from "./dataProvider";
import fakeServerFactory from "./fakeServer";
import { createBrowserHistory } from "history";
import visitorReport from "./visitorReport";
import emailTemplates from "./emailTemplates";
import holidaysList from "./holidaysList";
import WeekOffsList from "./WeekOffsList";
import SundayOffList from "./SundayOffsList";
import FinalReportList from "./FinalReports";
import companyMaster from "./companyMaster";
// import { MuiPickersUtilsProvider } from 'material-ui-pickers';
// import DateFnsUtils from '@date-io/date-fns';
import branches from "./branches";
import employeeAllotments from "./employeeAllotments";
import clientAdmins from "./clientAdmins";
import employeeDataRights from "./employeeDataRights";
import employeeMultiRegistration from "./employeeMultiRegistration";
import attendanceReportDetails from "./attendenceSummaryReports";
import extraDutyReport from "./extraDutyReport";
import LandingPageFirst from "../src/layout/landingPageFirst";
import ContactUs from "../src/contactUs";
import LeaveHistory from "../src/leaveHistory";
import FinalReport from "../src/finalRepot";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Menu } from "./layout";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import subAdmin from "./subAdmin";
import inviteVisitors from "./inviteVisitors";
import inviteVisitorReports from "./inviteVisitorReports";
import reason from "./reason";
import teamAttendance from "./teamAttendance";
import teamAttendanceReport from "./teamAttendanceReport";

import myTeam from "./myTeam";

import teamRequests from "./teamRequests";
import employeeLeaveReports from "./employeeLeaveReports";
import alerts from "./alerts";
import leave from "./leave";
import teamLeave from "./teamLeave";
import TabPanel from "./designations/TabPanel";
import designationsTabPanel from "./designationsTabPanel";
import emergency from "./emergency";
import deviceAlerts from "./deviceAlerts";
import documentCatogory from "./documentCategory";
import documentCategory from "./documentCategory";
import documentType from "./documentType";
import employeeDocument from "./employeeDocument";
import documentManagement from "./documentManagement";
import biometricReport from "./biometricReport";
import MyTheme from "./layout/theme/myTheme";
import deleteEmployee from "./deleteEmployee";
import leaveBalance from "./leaveBalance";
import leaveReportsNew from "./leaveReportsNew";
import leaveBalanceHistory from "./leaveBalanceHistory";
import teamLeaveBalanceHistory from "./teamLeaveBalanceHistory";

// import VisitorVehicleReport from "./visitorVehicleReport";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");
const history = createBrowserHistory();
const App = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    let restoreFetch;
    const fetchDataProvider = async () => {
      restoreFetch = await fakeServerFactory(
        process.env.REACT_APP_DATA_PROVIDER || ""
      );
      const dataProviderInstance = await dataProviderFactory(
        process.env.REACT_APP_DATA_PROVIDER || ""
      );
      setDataProvider(
        // GOTCHA: dataProviderInstance can be a function
        () => dataProviderInstance
      );
    };

    fetchDataProvider();

    return restoreFetch;
  }, []);

  if (!dataProvider) {
    return (
      // <div className="loader-container">
      //   <div id="newLoader"></div>
      //   {/* <div className="loader">Loading...</div> */}
      //   {/* <div className="lds-ring">
      //     <div></div>
      //     <div></div>
      //     <div></div>
      //     <div></div>
      //   </div> */}
      // </div>
      <div id="preloader">
        <div className="preload-content">
          <div id="dream-load"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer limit={1} />

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Admin
          ready={LandingPageFirst}
          title=""
          // customSagas={[closeSidebarSaga]}
          history={history}
          dataProvider={dataProvider}
          customReducers={{ theme: themeReducer }}
          customRoutes={customRoutes}
          authProvider={authProvider}
          menu={Menu}
          loginPage={Login}
          layout={Layout}
          i18nProvider={i18nProvider}
          // theme={MyTheme}
        >
          {/* <Resource name="customers" {...visitors} /> */}
          <Resource name="about" {...about} />
          <Resource
            name="employeeMultiRegistration"
            {...employeeMultiRegistration}
          />
          <Resource name="commands" {...orders} options={{ label: "Orders" }} />
          <Resource name="invoices" {...invoices} />
          <Resource name="products" {...products} />
          <Resource name="categories" {...categories} />
          <Resource name="reviews" {...reviews} />
          <Resource name="countries" {...countries} />
          <Resource name="reasons" {...reason} />
          <Resource name="zones" {...zones} />
          <Resource name="regions" {...regions} />
          <Resource name="clientAdmins" {...clientAdmins} />
          <Resource name="employeeDataRights" {...employeeDataRights} />
          <Resource name="employeeAllotments" {...employeeAllotments} />
          <Resource name="branches" {...branches} />
          <Resource name="states" {...states} />
          <Resource name="cities" {...cities} />
          <Resource name="companies" {...companies} />
          <Resource name="companyMasters" {...companyMaster} />
          <Resource name="zones" {...zones} />
          <Resource name="regions" {...regions} />
          <Resource name="roles" {...roles} />
          <Resource name="clientMasters" {...clientMaster} />
          <Resource name="clientLocations" {...clientLocation} />
          <Resource name="departments" {...departments} />
          <Resource name="designations" {...designationsTabPanel} />
          <Resource name="profile" {...profile} />
          <Resource name="settings" {...settings} />
          <Resource name="shifts" {...shifts} />
          <Resource name="visitors" {...visitors} />
          <Resource name="employees" {...employees} />
          <Resource name="alerts" {...alerts} />
          <Resource name="notification-history" {...notifications} />
          <Resource name="late-notification" {...notification} />
          <Resource name="EmployeeLeaveMaster" {...leaveTypes} />
          <Resource name="leave-management" {...leave} />
          <Resource name="leave-balance-history" {...leaveBalanceHistory} />
          <Resource name="attendance" {...attendance} />
          <Resource name="teamattendance" {...teamAttendance} />
          <Resource name="my-team-statistics" {...myTeam} />
          <Resource name="emergency-evacuation" {...emergency} />
          <Resource name="employee-vehicle-attandence" {...vehicleAttendance} />
          <Resource name="unknown-attendance" {...unknownAttendance} />
          <Resource name="attendance-report" {...attendanceReport} />
          <Resource name="leave-report" {...leaveReport} />
          <Resource name="visitor-report" {...visitorReport} />
          {/* <Resource name="visitor-vehicle-report" {...VisitorVehicleReport} /> */}
          <Resource name="attendance-requests" {...attendanceRequest} />
          <Resource name="devices" {...devices} />
          <Resource name="attendenceRequestLog" {...requests} />
          <Resource name="team-attendence-request" {...teamRequests} />
          <Resource name="employeeVehicle" {...employeeVehicle} />
          <Resource name="employee-type" {...userType} />
          <Resource name="workplaces" {...workplaces} />
          <Resource name="email-template" {...emailTemplates} />
          <Resource name="holidayList" {...holidaysList} />
          <Resource name="weekOffList" {...WeekOffsList} />
          <Resource name="sundayOff" {...SundayOffList} />
          <Resource name="attendence-approval" {...FinalReportList} />
          <Resource name="attendance-summary" {...attendanceReportDetails} />
          <Resource name="attendance-approval" {...extraDutyReport} />
          <Resource name="team-attendance-report" {...teamAttendanceReport} />
          <Resource name="contact-us" {...ContactUs} />
          <Resource name="teamLeave" {...teamLeave} />
          <Resource
            name="team-leave-balance-history"
            {...teamLeaveBalanceHistory}
          />
          <Resource name="leave-history" {...LeaveHistory} />
          <Resource name="final-report" {...FinalReport} />
          <Resource name="stream" {...stream} />
          <Resource name="course" {...course} />
          <Resource name="semester" {...semester} />
          <Resource name="classroom" {...classroom} />
          <Resource name="subject" {...subject} />
          <Resource name="faculty" {...faculty} />
          <Resource name="student" {...student} />
          <Resource name="camera-venue" {...cameraVenue} />
          <Resource name="timeslot" {...timeslots} />
          <Resource name="assign-timeslot" {...assignTimeslot} />
          <Resource name="faculty-timetable" {...facultyTimetable} />
          <Resource name="college-attendance" {...collegeAttedance} />

          <Resource
            name="datewiseAttendanceReport"
            {...datewiseAttendanceReport}
          />
          <Resource
            name="studentwiseAttendanceReport"
            {...studentwiseAttendanceReport}
          />
          <Resource
            name="lecturewiseAttendanceReport"
            {...lecturewiseAttendanceReport}
          />
          <Resource
            name="subjectwiseAttendanceReport"
            {...subjectwiseAttendanceReport}
          />
          <Resource name="todayTimetableReport" {...todayTimetableReport} />

          <Resource name="visitor-qr-code" {...inviteVisitorReports} />
          <Resource
            name="studentMultiRegistration"
            {...studentMultiRegistration}
          />

          <Resource name="sub-admin" {...subAdmin} />
          <Resource name="invite-visitor" {...inviteVisitors} />
          <Resource name="employee-leave-report" {...employeeLeaveReports} />

          <Resource name="employee-leave-balance-report" {...leaveReportsNew} />
          <Resource name="employee-leave-balance" {...leaveBalance} />

          <Resource name="device-alerts" {...deviceAlerts} />

          {/* Document Management */}
          <Resource name="document-category" {...documentCategory} />
          <Resource name="document-type" {...documentType} />
          <Resource name="employee-document" {...employeeDocument} />
          <Resource name="document-management" {...documentManagement} />
          <Resource name="ced-biometrics" {...biometricReport} />
          <Resource name="employees-trash" {...deleteEmployee} />

          {/* <Resource name="main-admin" {...ma} /> */}

          {/* <div>
          <AppFooterBar customReducers={{ theme: themeReducer }} />
        </div> */}
        </Admin>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default App;
