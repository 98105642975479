import * as React from "react";
import { FC, Fragment } from "react";
import {
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  FormDataConsumer,
  // AutocompleteInput,
  // DateInput,
  downloadCSV,
  useRefresh,
  CreateButton,
  TopToolbar,
  ExportButton,
  required,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  DateTimeInput,
  useRedirect,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { Route, RouteChildrenProps } from "react-router-dom";
import { ListComponentProps } from "../types";
import GridList from "./GridList";
import jsonExport from "jsonexport/dist";
import { auth } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_from) {
        values.time_from = moment.utc(moment(values.time_from).utc()).format();
      }
      if (values.time_to) {
        values.time_to = moment.utc(moment(values.time_to).utc()).format();
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };
  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(1);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const resetFilter = () => {
    data.setFilters(filterData(), []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.time_from > new Date()) {
      return "From date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.time_to > new Date()) {
      return "To date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  const user = helperFunc.getUserData();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="authType"
                      choices={auth}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="time_from"
                      validate={[required(), checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[required(), checkToDate]}
                      source="time_to"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
const ListActions = (props: any) => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const user = helperFunc.getUserData();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const redirect = useRedirect();

  const handleTeamHistory = () => {
    redirect("attendence-approval");
  };
  return (
    <TopToolbar>
      {/* {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ||
      authProvider.isMenuShow("vehicleAttendance", "create") ? (
        <CreateButton basePath={basePath} />
      ) : null} */}
      {
        <Button
          className="btnfilter"
          color="primary"
          component="span"
          onClick={handleTeamHistory}
          style={
            isSmall
              ? {
                  fontSize: "0.8125rem",
                }
              : {
                  fontSize: "0.8125rem",
                  minWidth: "126px",
                }
          }
        >
          {/* <DescriptionIcon
            style={{ transform: "rotate(180deg)", fontSize: 20 }}
          /> */}
          {!isXSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>History</span>
          ) : null}
        </Button>
      }
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        name={isSmall ? null : "Export"}
      />
      {/* {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <ImportButton {...props} />
      ) : null} */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
});

// const Aside = () => (
//     <div style={{ width: 200, margin: "1em" }}>
//         <Typography variant="h6">Post details</Typography>
//         <Typography variant="body2">
//             Posts will only be published one an editor approves them
//     </Typography>
//     </div>
// );

const exporter = (records: any) => {
  // const modes: any = {
  //   mobile: "Mobile",
  //   edge: "Edge",
  //   manual: "Manual",
  //   cron: "Cron",
  //   auto: "Auto",
  // };
  const devLocation: any = {
    in: "In",
    out: "Out",
  };
  const recordsForExport = records.map((record: any) => {
    const {
      time,
      authType,
      isRequested,
      fileData,
      isSucceed,
      employee,
      gateway,
      description,
      camera_ID,
      isDeleted,
      image,
      mode,
      isActive,
      device,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      lat,
      long,
      mask,
      confidence,
      isMask,
      workplaceId,
      clientLocationId,
      clientId,
      childCompanyId,
      shiftData,
      wokplaceData,
      lng,
      carNumber,
      status,
      ...dataToExport
    } = record; // omit fields
    dataToExport["Employee Name"] =
      employee && employee.name ? employee.name : null;
    dataToExport["Employee Type"] =
      employee && employee.type ? employee.type.toUpperCase() : null;
    dataToExport["Child Company"] = childCompanyId && childCompanyId.name;
    // dataToExport["Child"] = clientId && clientId.name;
    // dataToExport["Client Location"] = clientLocationId && clientLocationId.name;
    // dataToExport["Workplace"] = workplaceId && workplaceId.name;
    // dataToExport["Shift"] = shiftData && shiftData.name;
    dataToExport["Device"] = devLocation[authType];
    dataToExport["Time"] = helperFunc.commonDateFormat(time, "HH:mm:ss");
    // dataToExport["Mode"] = modes[mode];
    // dataToExport["Confidence"] = confidence;
    // dataToExport.createdAt = helperFunc.isoToDate(record.createdAt); // add a field
    // dataToExport["Is Mask"] = isMask ? "Yes" : "No";
    // dataToExport["Status"] = isActive ? "Active" : "In Active"; // add a field
    // dataToExport["Register Status"] = isRegistred
    //   ? "Registered"
    //   : "Not Registered";
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Unknown Attendance Report"); // download as 'xxxx.csv` file
    }
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    {helperFunc.getUserData().designation === "HR" ||
    helperFunc.getUserData().type === "super" ||
    helperFunc.getUserData().type === "company" ? (
      <SearchInput source="employeeNameCode" parse={trimValue} alwaysOn />
    ) : null}
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const AttendanceList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = React.useState(false);
  // const [dialogueOpen, setDialogueOpen] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [page, setPage] = React.useState(0);
  // const [activeIndex, setActiveIndex] = React.useState(0);
  const [activeSlice, setActiveSlice] = React.useState("");

  // const handleClose = () => {
  //   setDialogueOpen(false);
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const classes = useStyles();
  // const refresh = useRefresh();
  //   setInterval(function () {
  //     refresh();
  //   }, 2 * 60000); // runs every 2 * 60 seconds.

  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(1);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);

    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  // const headers = () => {
  //   return [
  //     { label: "Date", key: "date" },
  //     { label: "Status", key: "Leave" },
  //   ];
  // };
  // const handleDefaultPage = (index: number) => {
  //   setPage(index);
  // };

  // const handleRowsPerPage = (index: number) => {
  //   setRowsPerPage(index);
  // };

  const handleClickOpen = (data: any, index: any) => {
    // e.preventDefault();
    // setActiveIndex(index);
    // setDialogueOpen(true);
    setActiveSlice(data);
    // setPage(0);
  };

  return (
    <>
      <div className={classes.root}>
        {isXSmall ? (
          <h2
            style={{
              height: 55,
              fontSize: 30,
              backgroundColor: "#fff",
              padding: 10,
            }}
          >
            Attendance
          </h2>
        ) : null}

        <Route path="/employee-vehicle-attandence/:id">
          {({ match }: RouteChildrenProps<{ id: string }>) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

            return (
              <Fragment>
                <div
                  className={open === true ? "overlay" : ""}
                  onClick={() => handleDrawerClose("fromlist")}
                />

                <List
                  className="list-view2"
                  title="resources.unknownAttendance.title"
                  exporter={exporter}
                  actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
                  {...props}
                  filterDefaultValues={filterData()}
                  filters={
                    <AttendanceFilter
                      {...props}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                  }
                  perPage={20}
                  pagination={
                    <Pagination
                      labelRowsPerPage="Records per page"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                    />
                  }
                  sort={{ field: "time", order: "DESC" }}
                >
                  <GridList
                    selectedRow={
                      isMatch
                        ? parseInt((match as any).params.id, 10)
                        : undefined
                    }
                  />
                </List>
              </Fragment>
            );
          }}
        </Route>
      </div>
      {/* <DialogueBox
        dialogueData={[]}
        open={dialogueOpen}
        handleClose={handleClose}
        activeSlice={activeSlice}
        headerArr={headers()}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
      /> */}
    </>
  );
};

export default AttendanceList;
