import * as React from "react";
import { SimpleForm,  useTranslate, required, BooleanInput, FormDataConsumer } from "react-admin";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import EmployeeIdSelectInput from "../components/EmployeeIdSelectInput";
import MultiSelectChildCompany from "../components/MultiSelectChildCompany";
import MultiSelectClientMaster from "../components/MultiSelectClientMaster";
import MultiSelectCityInput from "../components/MultiSelectCityInput";
import MultiSelectStateInput from "../components/MultiSelectStateInput";
import MultiSelectZoneInput from "../components/MultiSelectZoneInput";
import MultiSelectRegionInput from "../components/MultiSelectRegionInput";
import MultiSelectBranchInput from "../components/MultiSelectBranchInput";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { employeeDataRightsStyles } from '../helperStyle';

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};


const useStyles = makeStyles(employeeDataRightsStyles);
const CommonForm = (props: any) => {
    const isXsmall = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("xs")
    );
    const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
    const classes = useStyles();
    return (
        isSmall ? <SimpleForm {...props}>
            {/* <SectionTitle label="resources.departments.page.edit" /> */}
            <CompanyReferenceInput></CompanyReferenceInput>
            <EmployeeIdSelectInput filter={{ isActive: true }} validate={requiredValidate}></EmployeeIdSelectInput>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectChildCompany filter={{ isActive: true }}></MultiSelectChildCompany>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectClientMaster filter={{ isActive: true }}></MultiSelectClientMaster>
                )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectZoneInput filter={{ isActive: true }}></MultiSelectZoneInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectRegionInput filter={{ isActive: true, zoneId: formData.zoneId }}></MultiSelectRegionInput>
                )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectStateInput filter={{ isActive: true }}></MultiSelectStateInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectCityInput filter={{ isActive: true }}></MultiSelectCityInput>
                )}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                {({ formData }: { formData: any }) => (
                    <MultiSelectBranchInput filter={{ isActive: true }}></MultiSelectBranchInput>
                )}
            </FormDataConsumer>
            <BooleanInput
                label="label.status"
                source="isActive"
                resource="zones"
                defaultValue={true}
            />
        </SimpleForm>
            :
            <SimpleForm {...props}>
                {/* <SectionTitle label="resources.zones.page.edit" /> */}
                <CompanyReferenceInput></CompanyReferenceInput>
                <EmployeeIdSelectInput filter={{ isActive: true }} validate={requiredValidate}></EmployeeIdSelectInput>
                <SectionTitle label="" />
                <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectChildCompany filter={{ isActive: true }}></MultiSelectChildCompany>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectClientMaster filter={{ isActive: true, childCompanyId:formData.childCompanyId }}></MultiSelectClientMaster>
                    )}
                </FormDataConsumer>
                <SectionTitle label="" />
                <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectZoneInput filter={{ isActive: true }}></MultiSelectZoneInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectRegionInput filter={{ isActive: true, zoneId: formData.zoneId }}></MultiSelectRegionInput>
                    )}
                </FormDataConsumer>
                <SectionTitle label="" />
                <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectStateInput filter={{ isActive: true, regionId: formData.regionId }}></MultiSelectStateInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName={isXsmall ? null : classes.code}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectCityInput filter={{ isActive: true, state: formData.state }}></MultiSelectCityInput>
                    )}
                </FormDataConsumer>
                <SectionTitle label="" />
                <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
                    {({ formData }: { formData: any }) => (
                        <MultiSelectBranchInput filter={{ isActive: true, city: formData.city }}></MultiSelectBranchInput>
                    )}
                </FormDataConsumer>
                <BooleanInput
                    label="label.status"
                    source="isActive"
                    resource="zones"
                    defaultValue={true}
                />
            </SimpleForm>

    );
};
// const nameValidate = [required(), maxLength(50)];
// const codeValidate = [required(), maxLength(20)];
// const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
//     record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
