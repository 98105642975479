import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Theme,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import { useDataProvider } from "react-admin";
import { Loader } from "semantic-ui-react";
import helperFunc from "../helperFuncs";
import FilterReport from "./Filter";
import { usePageTitleHook, useGetTableDataHook } from "./hooks";
import { attendanceSummaryStyle } from "../helperStyle";
import { toastError } from "../components/toast";

const EmployeeLeaveReport = (props: any): any => {
  const updatedWidth = "18%" as any;
  const user = helperFunc.getUserData();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(attendanceSummaryStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const {} = usePageTitleHook("Employee Leave Report");

  const [responseData, setResponseData] = useState({
    list: [],
    leaveTypes: [],
    holidayDates: [],
    count: "" as unknown as number,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({
    skip: page,
    limit: rowsPerPage,
    sortBy: "name",
    sort: "asc",
    filter: {
      time_from: moment.utc(new Date()).startOf("month").toDate(),
      time_to: moment.utc(moment(new Date()).utc()).format(),
      isActive: true,
    },
  });

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setFilterParams({
      ...filterParams,
      skip: newPage * rowsPerPage,
    });
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
    setFilterParams({
      ...filterParams,
      limit: parseInt(event.target.value, 10),
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const empLeaveReportUrl = "employee-leave-balance-report/list";
  useEffect(() => {
    dataProvider
      .callCustom(empLeaveReportUrl, filterParams)
      .then((response: any) => {
        if (response) {
          setLoading(false);
          setResponseData(response.data);
        }
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  }, [filterParams, page]);

  const { getReportData, getLeaveData } = useGetTableDataHook();

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          style={{
            width: window.screen.width - updatedWidth,
            overflow: isXSmall ? "scroll" : "auto",
          }}
        >
          <FilterReport
            open={open}
            setOpen={setOpen}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            handleDrawerOpen={handleDrawerOpen}
            page={page}
            rowsPerPage={rowsPerPage}
            count={responseData?.count}
          />

          {isXSmall ? (
            <h2
              style={{
                height: 40,
                fontSize: 30,
                backgroundColor: "#fff",
                padding: 10,
              }}
            >
              Employee Leave Report
            </h2>
          ) : null}

          {responseData.list && responseData.list.length === 0 ? (
            <p
              style={{
                textAlign: "center",
                marginRight: isXSmall ? undefined : "350px",
              }}
            >
              No Record Found
            </p>
          ) : (
            <div className={isXSmall ? "" : classes.rtable}>
              <TableContainer className={isXSmall ? "" : classes.container}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {getReportData("", "")?.map((empData) => {
                        return (
                          <TableCell
                            style={{
                              left: 0,
                              position: "sticky",

                              width: "125px !important",
                              minWidth: "125px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              textAlign: "center",
                              zIndex: empData?.zIndex,
                            }}
                            className={classes.header}
                            rowSpan={7}
                          >
                            {empData?.headerName}
                          </TableCell>
                        );
                      })}

                      {responseData.list
                        ? responseData &&
                          responseData.leaveTypes &&
                          responseData.leaveTypes.map(
                            (leave: any, index: number) => {
                              return (
                                <TableCell
                                  className={classes.header}
                                  colSpan={
                                    leave?.isNotDependentOnLeaveBalance ? 2 : 5
                                  }
                                  style={{
                                    left: 0,
                                    position: "sticky",

                                    width: "125px !important",
                                    minWidth: "125px !important",
                                    wordBreak: "break-word",
                                    background: "#e6e6e6",
                                    textAlign: "center",
                                    zIndex: 999,
                                  }}
                                >
                                  {leave?.leaveName}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                    <TableRow>
                      <></>
                      {responseData.list
                        ? responseData &&
                          responseData.leaveTypes &&
                          responseData.leaveTypes.map(
                            (leave: any, index: number) => {
                              return (
                                <>
                                  {getLeaveData("", leave)?.map(
                                    (headData: any) => {
                                      return (
                                        <TableCell
                                          style={{
                                            left: 0,
                                            position: "sticky",

                                            width: "125px !important",
                                            minWidth: "125px !important",
                                            wordBreak: "break-word",
                                            background: "#e6e6e6",
                                            textAlign: "center",
                                            zIndex: 999,
                                            top: 46,
                                          }}
                                          className={classes.tableHead}
                                        >
                                          {headData?.fieldName}
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ justifyContent: "center" }}>
                    {responseData &&
                      responseData.list &&
                      responseData.list.map(
                        (employeeData: any, index: number) => {
                          return (
                            <TableRow hover role="checkbox">
                              {getReportData(employeeData, index)?.map(
                                (empData) => {
                                  return (
                                    <TableCell
                                      style={{
                                        left: user.isClientConfiguration
                                          ? 300
                                          : 0,
                                        position: empData?.isSticky
                                          ? "sticky"
                                          : "relative",
                                        width: "125px !important",
                                        minWidth: "125px !important",
                                        wordBreak: "break-word",
                                        background: empData?.isDark
                                          ? "#e6e6e6"
                                          : "",
                                        zIndex: empData?.bodyZIndex,
                                      }}
                                      align="left"
                                      className={classes.tableCellEmp}
                                    >
                                      {empData?.value}
                                    </TableCell>
                                  );
                                }
                              )}

                              {responseData !== undefined &&
                              responseData?.leaveTypes !== undefined
                                ? responseData?.leaveTypes.map(
                                    (leave: any, index: number) => {
                                      return (
                                        <>
                                          {getLeaveData(
                                            employeeData,
                                            leave
                                          )?.map((bodyData: any) => {
                                            return (
                                              <TableCell
                                                align="left"
                                                className={classes.tableCell}
                                                style={{
                                                  left: user.isClientConfiguration
                                                    ? 300
                                                    : 0,
                                                  position: "sticky",
                                                  width: "125px !important",
                                                  minWidth: "125px !important",
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {bodyData?.value}
                                              </TableCell>
                                            );
                                          })}
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{
                            textAlign: "center",
                            marginRight: isXSmall ? undefined : "350px",
                          }}
                        >
                          <TableCell
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              marginRight: isXSmall ? undefined : "350px",
                            }}
                          >
                            No record found
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex">
                <div style={{ width: "100%" }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={(responseData && responseData.count) || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}{" "}
    </>
  );
};
export default EmployeeLeaveReport;
