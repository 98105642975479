import * as React from "react";
import { FC } from "react";
import { Link } from "react-admin";
import { FieldProps, Zone } from "../types";

const EmployeeAllotmentLinkField: FC<FieldProps<Zone>> = (props) =>
    props.record && props.record.workplacesId ? (
        <Link to={`/employeeAllotments/${props.record._id}`}>
            {
                <div >{props.record.workplacesId.name}</div>
            }
        </Link>
    ) : null;

EmployeeAllotmentLinkField.defaultProps = {
    source: "workplacesId.name",
    label: "Workplace",
    addLabel: true,
};

export default EmployeeAllotmentLinkField;
