import * as React from "react";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Labeled } from "react-admin";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FieldProps, Attendance } from "../types";
import helperFunc from "../helperFuncs";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  lblName: {
    right: theme.spacing(0),
  },
  lblLocation: {
    left: theme.spacing(2),
    right: theme.spacing(2),
    margin: 5,
  },
  lblDate: {
    left: theme.spacing(1),
  },
}));

const AttandanceShow: FC<FieldProps<Attendance>> = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // const handleConfirm = (id: any) => {

  //   setOpen(false);
  // };

  // const styles = (theme: any) => ({
  //   root: {
  //     margin: 0,
  //   },
  //   closeButton: {
  //     position: "absolute",
  //     right: theme.spacing(1),
  //     top: theme.spacing(1),
  //     color: theme.palette.grey[500],
  //   },
  // });

  const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  // const DialogActions = withStyles((theme) => ({
  //   root: {
  //     margin: 0,
  //     padding: theme.spacing(1),
  //   },
  // }))(MuiDialogActions);
  return record ? (
    <div className={classes.root}>
      <IconButton aria-label="Show" color="primary" onClick={handleClick}>
        <VisibilityIcon />
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle
          id="simple-dialog-title"
          classes={classes}
          onClose={handleClose}
        >
          Attendance Request
        </DialogTitle>
        <DialogContent dividers>
          <Box component="div" m={1}>
            <h4>Request Body</h4>
            <img
              style={{ alignItems: "center", height: "500px", width: "500px" }}
              src={
                record &&
                record.requestBody &&
                uploadApiUrl + record.requestBody.image_data
              }
              alt={
                record &&
                record.requestBody &&
                uploadApiUrl + record.requestBody.image_data
              }
            />
            <Typography component="div" id="name" align="left">
              {record &&
                record.responseBody &&
                record.responseBody.errors &&
                record.responseBody.errors.map((item: any) => {
                  return item.msg;
                })}
            </Typography>

            <Labeled label="Latitude" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record && record.requestBody && record.requestBody.lat}
              </Typography>
            </Labeled>
            <Labeled label="Longitude" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record && record.requestBody && record.requestBody.long}
              </Typography>
            </Labeled>
          </Box>
          <Box component="div" m={1}>
            <Labeled label="Api Route" className={classes.lblLocation}>
              <Typography component="div" id="location" align="center">
                {record && record.api_route}
              </Typography>
            </Labeled>
          </Box>

          <Box component="div" m={1}>
            <h4>Request Header</h4>
            <Labeled label="Accept" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders.accept}
              </Typography>
            </Labeled>
            <Labeled label="Accept Encoding" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["accept-encoding"]}
              </Typography>
            </Labeled>
            <Labeled label="Cache Control" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["cache-control"]}
              </Typography>
            </Labeled>
            <Labeled label="Connection" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["connection"]}
              </Typography>
            </Labeled>
            <Labeled label="Content Length" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["content-length"]}
              </Typography>
            </Labeled>
            <Labeled label="Content Type" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["content-type"]}
              </Typography>
            </Labeled>
            <Labeled label="Host" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["host"]}
              </Typography>
            </Labeled>
            <Labeled label="User Agent" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["user-agent"]}
              </Typography>
            </Labeled>
            <Labeled label="Postman Token" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["postman-token"]}
              </Typography>
            </Labeled>
            <Labeled label="X Access Token" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.requestHeaders &&
                  record.requestHeaders["x-access-token"]}
              </Typography>
            </Labeled>
          </Box>
          <Box component="div" m={1}>
            <h4>Response Body</h4>
            <Labeled label="Auth Type" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record && record.responseBody && record.responseBody.authType}
              </Typography>
            </Labeled>
            <Labeled label="Is Mask" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record && record.responseBody && record.responseBody.isMask
                  ? "Yes"
                  : "No"}
              </Typography>
            </Labeled>
            <Labeled label="Time" className={classes.lblLocation}>
              <Typography component="div" id="location">
                {record &&
                  record.responseBody &&
                  record.responseBody.time &&
                  helperFunc.commonDateFormat(
                    record.responseBody.time,
                    "HH:mm:ss"
                  )}
              </Typography>
            </Labeled>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export default AttandanceShow;
