import * as React from "react";
import {
  useDataProvider,
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  Button,
  useNotify,
  useRedirect,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";
import { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import {
  useMediaQuery,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button as MaterialButton,
} from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import helperFunc from "./../helperFuncs";
import ImportVideoButton from "../employees/videoImport";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { FormSpy, useForm } from "react-final-form";
import { toastError } from "../components/toast";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

export const styles: Styles<Theme, any> = {
  employee: { display: "inline-flex" },
  url: { display: "inline-flex", marginLeft: 32 },
  flex: { display: "flex", width: "100%", alignItems: "flex-start" },
  pullRight: { float: "right" },
  inlineFlex: { display: "inline-flex" },
  inlineFlexRight: {
    display: "inline-flex",
    justifyContent: "start",
    marginLeft: 15,
  },
  removeBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
    marginLeft: 15,
  },
  addBtn: { fontSize: 12, fontWeight: 500, marginLeft: 15, marginTop: 17 },
  hidden: { visibility: "hidden" },
  hide: { display: "none" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },

  title: {
    fontSize: 18,
    fontWeight: 700,
    paddingLeft: 15,
    paddingBottom: 0,
    color: "#000",
    textTransform: "capitalize",
  },
  label: {
    display: "inline-block",
    fontSize: 14,
    marginRight: 10,
    fontWeight: 500,
    color: "#000",
  },
  value: { display: "inline-block", fontWeight: 700 },
  myPopup: { display: "inline-block" },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  closeButton: { position: "absolute", top: 5, right: 0 },
};

interface State {
  data?: any;
  company?: "";
  employeeData?: [];
  objEmployee: {};
  URL?: string;
  state?: {};
  emptyValue?: false;
  errorData: [];
  isOpen: false;
  responseData: {
    totalCount: 0;
    successCount: 0;
    dataError: 0;
    companyName: "";
    deviceName: "";
  };
}
const useStyles = makeStyles(styles);

const EmployeeMultiRegistration = (props: any) => {
  // const form = useForm();
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const classes = useStyles();
  const redirectUrl = useRedirect();
  //eslint-disable-next-line
  const [state, setState] = useState<State>(Object);
  const [company, setCompany] = useState(String);
  const [employeeData, setEmployeeData] = useState(Array);
  const [URL, setURL] = useState(String);
  const dataProvider = useDataProvider();
  const [objEmployee, setObjEmployee] = useState({});
  const [emptyValue, setEmptyValue] = useState(Boolean);
  //eslint-disable-next-line
  const [isLoad, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [errorData, setErrorData] = useState([]);
  React.useEffect(() => {
    if (container) {
      container.innerText = "";
      container.innerText = "Employee Registration";
    }
    return function cleanup() {
      if (container) {
        container.innerText = "";
      }
    };
  }, [container]);
  let [responseData, setResponseData] = useState({
    totalCount: 0,
    successCount: 0,
    dataError: 0,
    companyName: "",
    deviceName: "",
  });

  const PostToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton label="Register" />
    </Toolbar>
  );
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  // const notify = useNotify();
  const user = helperFunc.getUserData();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setIsOpen(false);
    redirectUrl("employeeMultiRegistration");
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const removeEmployee = (idx: any) => {
    setEmployeeData(employeeData.filter((s: any, sIdx: any) => idx !== sIdx));
  };
  const addEmployee = (data: any) => {
    if (data && (data.employee === "" || data.employee === undefined)) {
      toastError("Please Select Employee");
      // notify("please select Employee", "warning");
      return;
    } else if (data && (data.url === "" || data.url === undefined)) {
      toastError("Please add valid URL");
      // notify("please add valid URL", "warning");
      return;
    } else {
      let duplicate = false;
      employeeData.filter((s: any, sIdx: any) => {
        if (s.employeeId.id === data.employee) {
          duplicate = true;
        }
      });
      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
        // notify("Duplicate Entry is not allowed", "warning");
      } else {
        setEmployeeData([
          ...employeeData,
          {
            employeeId: objEmployee,
            url: data.url,
          },
        ]);
      }
      data.url = "";
      setURL("");

      setEmptyValue(true);
      data.hideFiled = true;
      // form && form.change("hideFiled", permiData.length + permissions.length + 1);
      setTimeout(function () {
        setEmptyValue(false);
      }, 500);
    }
  };
  const dataManage = (data: any, type: any) => {
    if (type === "employee") {
      setObjEmployee(data);
    } else {
      setEmptyValue(false);
    }
  };
  const SectionTitle = ({ label }: { label: string }) => {
    // const classes = useStyles();
    return (
      <div>
        <Box component="div" className={classes.inlineFlex}>
          <Typography variant="h6" gutterBottom>
            {label}
          </Typography>
        </Box>
      </div>
    );
  };
  const videoUpload = (e: any) => {
    setLoader(true);
    let file;
    let data;
    let url;
    file = e.target.files && e.target.files[0];
    data = new FormData();

    data.append("file", file);
    dataProvider
      .callCustom("account/uploadVideo", data)
      .then((responseLocal: any) => {
        if (responseLocal.data.success === true) {
          //   setEnable(true);
          if (
            responseLocal.data &&
            responseLocal.data.item &&
            responseLocal.data.item.video
          ) {
            url = uploadApiUrl + responseLocal.data.item.video;
            setURL(url);
          }
          setLoader(false);
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
        setLoader(false);
      });
  };
  const saveForm = (formdata: any) => {
    if (
      user.type === "super" &&
      (formdata.company === "" || formdata.company === undefined)
    ) {
      toastError("Please Select Company");
      // notify("Please Select Company", "warning");
    } else if (employeeData && employeeData.length === 0) {
      toastError("Please add at list one record");
      // notify("Please add at list one record", "warning");
    } else {
      const eData = employeeData.map((data: any) => {
        return { employeeId: data.employeeId._id, url: data.url };
      });

      const resource = "employees/employee_registration_multiple";
      dataProvider
        .callCustom(resource, {
          company: formdata.company ? formdata.company : "",
          employeeData: eData,
        })
        .then((response: any) => {
          setIsOpen(true);
          setResponseData({
            totalCount: response && response.data && response.data.totalCount,
            dataError: response && response.data && response.data.faileCount,
            successCount:
              response && response.data && response.data.successCount,
            companyName:
              response &&
              response.data &&
              response &&
              response.data.companyName &&
              response.data.companyName[0].name,
            deviceName:
              response &&
              response.data &&
              response &&
              response.data.deviceName &&
              response.data.deviceName[0].camera_ID,
          });
          setErrorData(response?.data?.result?.employee_IDs_status);
          setEmployeeData([]);
          formdata.company = "";
          setCompany("");
          // return redirectUrl('employeeMultiRegistration');
        })
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
          setEmployeeData([]);
          formdata.company = "";
          setCompany("");
          return redirectUrl("employeeMultiRegistration");
        });
      formdata.hideFiled = true;
    }
  };
  const { data } = state;
  return (
    <div
      style={
        isXSmall
          ? {
              width: window.screen.width,
              overflowX: "scroll",
            }
          : {}
      }
    >
      <SimpleForm
        {...props}
        record={{ url: URL, company: company }}
        save={saveForm}
        toolbar={<PostToolbar />}
        {...props}
      >
        <CompanyReferenceInput></CompanyReferenceInput>
        {/* <ArrayInput source="employeeData">
                    <SimpleFormIterator  >
                        <FormDataConsumer formClassName={isXSmall ? null : classes.employee}>
                            {({ formData }: { formData: any }) => (
                                <EmployeeIdSelectInput filter={{ isActive: true }} validate={requiredValidate} ></EmployeeIdSelectInput>
                            )}
                        </FormDataConsumer>
                        <TextInput
                            source="employeeData.url"
                            label="URL"
                            formClassName={classes.url}
                            validate={requiredValidate}
                        />
                    </SimpleFormIterator>
                </ArrayInput> */}
        <SectionTitle label="Employee Registration" />
        <Box className={isXSmall ? undefined : classes.flex}>
          <FormDataConsumer formClassName={isXSmall ? null : classes.employee}>
            {({ formData }: { formData: any }) => (
              <EmployeeSelectInput
                filter={{
                  isActive: true,
                  company: user.type === "super" ? formData.company : "",
                }}
                dataManage={dataManage}
                emptyValue={{ emptyValue }}
              ></EmployeeSelectInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXSmall ? null : classes.url}>
            {({ formData }: { formData: any }) => (
              <TextInput
                // validate={requiredValidate}
                source="url"
                label="URL"
                className={isXSmall ? null : classes.url}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer formClassName={isXSmall ? null : classes.url}>
            {({ formData }: { formData: any }) => (
              <MaterialButton
                onClick={() => addEmployee(formData)}
                variant="outlined"
                color="primary"
                className={isXSmall ? undefined : classes.addBtn}
                type="button"
              >
                Add
              </MaterialButton>
            )}
          </FormDataConsumer>
        </Box>
        <Box className={classes.flex}>
          <BooleanInput
            source="isVideoUpload"
            label="Is local upload"
            defaultValue={false}
          />
          <FormDataConsumer formClassName={classes.url}>
            {({ formData }: { formData: any }) =>
              formData.isVideoUpload ? (
                <ImportVideoButton onChange={videoUpload} />
              ) : null
            }
          </FormDataConsumer>
        </Box>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Employee</TableCell>
                <TableCell className={classes.tableHead}>URL</TableCell>
                <TableCell className={classes.tableHead}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData &&
                employeeData.length > 0 &&
                employeeData.map((item: any, index: number) => (
                  <TableRow key={index + 1}>
                    <TableCell className={classes.tableValue} align="left">
                      {item && item.employeeId && item.employeeId.name}
                    </TableCell>
                    <TableCell className={classes.tableValue} align="left">
                      {item && item.url}
                    </TableCell>
                    <TableCell className={classes.tableValue} align="left">
                      {isXSmall ? (
                        <RemoveCircleOutlineIcon
                          onClick={() => removeEmployee(index)}
                        />
                      ) : (
                        <Button
                          onClick={() => removeEmployee(index)}
                          variant="outlined"
                          className={classes.removeBtn}
                          type="button"
                          label="Remove"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {employeeData && employeeData.length === 0 ? (
                <TableRow key="no employeeData">
                  <TableCell
                    className={classes.tableValue}
                    colSpan={4}
                    align="left"
                  >
                    No record found.
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TextInput
          type="hidden"
          source="hideFiled"
          formClassName={classes.hide}
        />
      </SimpleForm>
      <Dialog
        fullScreen={fullScreen}
        className={classes.myPopup}
        aria-labelledby="simple-dialog-title"
        onClose={handleClose}
        open={isOpen}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">Employee Register</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon className={classes.close} />
          </IconButton>
        </DialogTitle>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Company :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.companyName}
            </span>
          </div>
          <div className={classes.label}>
            Device:{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.deviceName}
            </span>
          </div>
        </Box>
        <Box display="block" displayPrint="none" m={2}>
          <div className={classes.label}>
            Total :{" "}
            <span className={classes.label} style={{ color: "#000" }}>
              {responseData.totalCount}
            </span>
          </div>
          <div className={classes.label}>
            Success:{" "}
            <span className={classes.label} style={{ color: "green" }}>
              {responseData.successCount}
            </span>
          </div>
          <div className={classes.label}>
            Error :{" "}
            <span className={classes.label} style={{ color: "red" }}>
              {responseData.dataError}
            </span>
          </div>
        </Box>
        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.theadFCol}>Sr no</TableCell>
                  <TableCell className={classes.theadCol}>Name</TableCell>
                  <TableCell className={classes.theadCol}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data: any, index: number) => (
                    <TableRow key={index + 1}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {data ? data.empName : ""}
                      </TableCell>
                      <TableCell align="left">{data ? data.msg : ""}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={errorData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </div>
  );
};
const requiredValidate = [required()];
export default EmployeeMultiRegistration;
