import * as React from "react";

import {
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
  FormDataConsumer,
  DateInput,
  Link,
} from "react-admin";

import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import { stringify } from "query-string";

import helperFunc from "../helperFuncs";

import GridList from "./GridList";
import jsonExport from "jsonexport/dist";
import ImportButton from "../employees/import";
import DescriptionIcon from "@material-ui/icons/Description";
import { isActive, gender, isRegistred } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";

import EmployeeTypeSelectInput from "../components/EmployeeTypeSelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import authProvider from "../authProvider";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { Delete } from "@material-ui/icons";

// const useQuickFilterStyles = makeStyles((theme) => ({
//   root: {
//     marginBottom: theme.spacing(3),
//   },
// }));
// const useStyles = makeStyles({
//   root: {
//     marginTop: "1em",
//   },
//   media: {
//     height: 140,
//   },
//   title: {
//     paddingBottom: "0.5em",
//   },
//   actionSpacer: {
//     display: "flex",
//     justifyContent: "space-around",
//   },
// });

const PostFilterForm = (
  // displayedFilters: any,
  // filterValues: any,
  // setFilters: any,
  // hideFilter: any,
  // open
  data: any
) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      if (values.isActive === "true") {
        values.isActive = true;
      } else if (values.isActive === "false") {
        values.isActive = false;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getChildCompany() === "true" ? (
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            helperText={false}
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <EmployeeTypeSelectInput
                      helperText={false}
                      validate={false}
                      filter={{ isActive: true }}
                    ></EmployeeTypeSelectInput>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="gender"
                      choices={gender}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      label="label.isActive"
                      source="isActive"
                      choices={isActive}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      label="label.isRegistred"
                      source="isRegistred"
                      choices={isRegistred}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const user = helperFunc.getUserData();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <TopToolbar className={className}>
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ||
      authProvider.isMenuShow("employees", "create") ? (
        <CreateButton basePath={basePath} />
      ) : null}

      {user?.type === "super" || user?.type === "company" ? (
        <Button
          disabled={total === 0}
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/employees-trash",
          }}
        >
          <Delete style={{ marginRight: "0.2em", fontSize: "18px" }} />
          {isSmall ? null : "Trash"}
        </Button>
      ) : (
        ""
      )}

      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <ImportButton {...props} />
      ) : null}
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <Button
          className="btnfilter"
          color="primary"
          component="span"
          onClick={Sampleexporter}
          style={
            isSmall
              ? {
                  fontSize: "0.8125rem",
                }
              : {
                  fontSize: "0.8125rem",
                  minWidth: "126px",
                }
          }
        >
          <DescriptionIcon
            style={{ transform: "rotate(180deg)", fontSize: 20 }}
          />
          {!isXSmall ? (
            <span style={{ paddingLeft: "0.5em" }}>Sample CSV</span>
          ) : null}
        </Button>
      ) : null}
    </TopToolbar>
  );
};

const Sampleexporter = () => {
  const records = [
    {
      name: "NameExample",
      code: "CodeExample",
      gender: "m",
      mobileNo: "1234567890",
      alternateNo: "0987654321",
      joiningDate: "12/12/2020",
      address: "address",
      dateOfBirth: "10/11/1995",
      country: "India",
      state: "gujarat",
      bloodGroup: "a+",
      maritalStatus: "",
      employeeType: "EMPLOYEE",
      shift: "morning",
      department: "Research and Development",
      role: "admin",
      designation: "developer",
      isActiveWorkplace: "true",
      workplace: "ahmedabad",
      email: "test@gmail.com",
      password: "123456",
      confirmPassword: "123456",
      status: "true",
      childCompany: "Groupl sub 3",
      zone: "WEST",
      region: "WEST",
      city: "Ahmedabad",
      branch: "Mithakhadi",
    },
    {
      name: "NameExample1",
      code: "CodeExample1",
      gender: "f",
      mobileNo: "1134567890",
      alternateNo: "1987654321",
      joiningDate: "12/12/2020",
      address: "address1",
      dateOfBirth: "11/11/1995",
      country: "India",
      state: "gujarat",
      bloodGroup: "a+",
      maritalStatus: "",
      employeeType: "EMPLOYEE",
      shift: "morning",
      department: "Research and Development",
      role: "admin",
      designation: "developer",
      isActiveWorkplace: "true",
      workplace: "ahmedabad",
      email: "test1@gmail.com",
      password: "123456",
      confirmPassword: "123456",
      status: "true",
      childCompany: "Groupl sub 2",
      zone: "WEST",
      region: "WEST",
      city: "Ahmedabad",
      branch: "Mithakhadi",
    },
  ];

  const recordsForExport = records.map((record: any) => {
    const { ...dataToExport } = record; // omit fields

    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [
        "name",
        "code",
        "gender",
        "mobileNo",
        "alternateNo",
        "joiningDate",
        "address",
        "dateOfBirth",
        "country",
        "state",
        "bloodGroup",
        "maritalStatus",
        "employeeType",
        "shift",
        "department",
        "role",
        "designation",
        "isActiveWorkplace",
        "workplace",
        "email",
        "password",
        "confirmPassword",
        "status",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "SampleEmployeeCsv"); // download as 'xxxx.csv` file
    }
  );
  // handleClose()
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const EmployeeFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeNameCode" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
    {/* <EmployeeTypeSelectInput filter={{ isActive: true }}  ></EmployeeTypeSelectInput> */}
  </Filter>
);

const NoRecordFound = (props: any) => {
  return (
    <>
      <TopToolbar>
        <CreateButton basePath={props.basePath} />

        {/* {user.type === "super" || user.type === "company" ? ( */}
        <ImportButton {...props} />
        {/* ) : null} */}
      </TopToolbar>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ border: "1px solid #80808026" }}
      >
        <p>No employee yet. Do you want to add one? </p>
      </div>
    </>
  );
};

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const {
      name,
      numberOfMaxUser,
      numberOfMaxVisitor,
      numberOfUserCreated,
      numberOfVisitorCreated,
      isDeleted,
      isActive,
      isRegistred,
      birthdate,
      employeeCode,
      permissions,
      shift,
      alternatePhone,
      forgotOTP,
      registration_start,
      gender,
      employeeType,
      bloodGroup,
      _id,
      isNewUser,
      type,
      userType,
      role,
      settings,
      maritalStatus,
      phoneVerification,
      phone,
      marriageDate,
      metaInfo,
      lastLogin,
      department,
      designation,
      documents,
      email,
      emailVerification,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      device,
      isActiveWorkplace,
      workplaces,
      workplace,
      joiningDate,
      idealTokenExpireTime,
      tokenExpireTime,
      isNewsFeed,
      isAdvertisement,
      isMask,
      rfId,
      isTurnoutR,
      isTurnoutM,
      isTurnoutE,
      mobileDeviceInfo,
      profile_image,
      address,
      turnoutDevice,
      country,
      state,
      city,
      zoneId,
      branchId,
      childCompanyId,
      regionId,
      companyLogo,
      ...dataToExport
    } = record; // omit fields
    const user = helperFunc.getUserData();
    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["Employee Name"] = name;
    // dataToExport["Employee Code"] = employeeCode; // add a field
    dataToExport["Email"] = email; // add a field
    dataToExport["Address"] = address && address.lines; // add a field
    dataToExport["Country"] = country && country.name; // add a field
    dataToExport["State"] = state && state.name; // add a field
    dataToExport["Phone"] = phone; // add a field
    dataToExport["Alternate Phone"] = alternatePhone; // add a field
    dataToExport["Gender"] =
      gender === "m"
        ? "Male"
        : gender === "f"
        ? "Female"
        : gender === "o"
        ? "Other"
        : "N/A";
    dataToExport["BloodGroup"] = bloodGroup;
    dataToExport["Birth Date"] = helperFunc.commonDateFormat(birthdate);
    dataToExport["Joining Date"] = helperFunc.commonDateFormat(joiningDate);
    dataToExport["Type"] = type;
    dataToExport["Marital Status"] = maritalStatus;
    dataToExport["Role"] = role && role.name;
    dataToExport["Department"] = department && department.name;
    dataToExport["Designation"] = designation && designation.name;
    dataToExport["Shift"] = shift && shift.name;
    dataToExport["City"] = city && city.name;
    dataToExport["Zone"] = zoneId && zoneId.name;
    dataToExport["Branch"] = branchId && branchId.name;
    dataToExport["Child Company"] = childCompanyId && childCompanyId.name;
    dataToExport["Region"] = regionId && regionId.name;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Status"] = isActive ? "Active" : "In Active";
    dataToExport["Register Status"] = isRegistred
      ? "Registered"
      : "Not Registered"; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Employees Report"); // download as 'xxxx.csv` file
    }
  );
};

const EmployeeList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Employees
        </h2>
      ) : null}
      <List
        className="list-view2"
        exporter={exporter}
        {...props}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        filters={
          <EmployeeFilter
            {...props}
            handleDrawerClose={handleDrawerClose}
            open={open}
          />
        }
        empty={<NoRecordFound {...props} />}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        sort={{ field: "name", order: "ASC" }}
      >
        <GridList />
      </List>
    </div>
  );
};

export default EmployeeList;
