import * as React from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  SelectInput,
  SearchInput,
  Pagination,
  downloadCSV,
  BulkDeleteButton,
  TopToolbar,
  ExportButton,
  CreateButton,
  FormDataConsumer,
  DateInput,
} from "react-admin";
import {
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Theme,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import helperFunc from "../helperFuncs";
import EmployeeVehicleLinkField from "./EmployeeVehicleLinkField";
import MobileGrid from "./MobileGrid";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from "react-admin-date-inputs";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getChildCompany() === "true" ? (
                      <FormDataConsumer>
                        {({ formData }: { formData: any }) => (
                          <ChildCompanySelectInput
                            helperText={false}
                            filter={{
                              isActive: true,
                              company: formData.company,
                            }}
                          ></ChildCompanySelectInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>
                {/* <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                    />
                  </Box>
                </Box> */}
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const EmployeeVehicleFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeName" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
    {/* <TextInput source="code" /> */}
    {/* <SelectInput
      allowEmpty={false}
      source="isApprove"
      label="label.approvalStatus"
      choices={approvalStatus}
    /> */}
    {/* <ReferenceInput source="company"
      label="Client" reference="companies">
      <AutocompleteInput optionText={(choice: Company) => `${choice.name}`} />
    </ReferenceInput> */}
    {/* <DateInput source="createdAt" /> */}
  </Filter>
);

const PostBulkActionButtons = (props: any) => (
  <BulkDeleteButton undoable={false} {...props} />
);

const exporter = (records: any) => {
  // const approvalStatusLeave: any = {
  //   reject: "Rejected",
  //   approve: "Approved",
  //   pending: "Pending",
  // };
  const recordsForExport = records.map((record: any) => {
    const {
      employee,
      approvedBy,
      authId,
      company,
      createdAt,
      createdBy,
      brand,
      type,
      isActive,
      isApprove,
      isDeleted,
      isSolved,
      mailTo,
      rejectBy,
      carNumber,
      updatedAt,
      __v,
      _id,
      updatedBy,
      id,
      name,
      childCompanyId,
      ...dataToExport
    } = record; // omit fields
    // dataToExport["Employee ID"] = employee && employee.employeeCode;
    const user = helperFunc.getUserData();
    dataToExport["Employee Name"] = employee && employee.name;
    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["Company (Subsidiary)"] =
      childCompanyId && childCompanyId.name;
    dataToExport["Car Number"] = carNumber;
    dataToExport["Brand"] = brand;
    dataToExport["Type"] = type;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    // dataToExport["Approval Status"] = approvalStatusLeave[isApprove]; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Employee Vehicle Report"); // download as 'xxxx.csv` file
    }
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    // filters,
    // showFilter,
    // displayedFilters,
    handleDrawerOpen,
    // open
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const EmployeeVehicleList = (props: any) => {
  // const approvalStatusLeave: any = {
  //   reject: "Rejected",
  //   approve: "Approved",
  //   pending: "Pending",
  // };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  return (
    <div
    // style={{
    //   width: isXsmall ? window.screen.width : window.screen.width / 1.25,
    // }}
    >
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      {isXsmall ? (
        <h2
          style={{
            height: 55,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Employee Vehicle
        </h2>
      ) : null}
      <List
        className="list-view2"
        // style={{
        //   width: isXsmall ? window.screen.width : window.screen.width / 1.25,
        // }}
        exporter={exporter}
        {...props}
        actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
        filters={
          isSmall ? (
            <EmployeeVehicleFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          ) : (
            <EmployeeVehicleFilter
              {...props}
              handleDrawerClose={handleDrawerClose}
              open={open}
            />
          )
        }
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        bulkActionButtons={<PostBulkActionButtons />}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        // aside={<RequestListAside />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            {/* <EmployeeIdLinkField /> */}
            <EmployeeVehicleLinkField />
            {/* <TextField label="Employee Name" source="employerName" /> */}
            {user.type === "super" ? (
              <TextField label="Company" source="company.name" />
            ) : null}
            <TextField
              label="Company (Subsidiary)"
              source="childCompanyId.name"
            />

            <TextField source="carNumber" />
            <TextField source="brand" />
            <TextField source="type" />
            {/* <TextField label="Client" source="company.name" /> */}
            {/* <FunctionField
                label="label.createdAt"
                sortBy="createdAt"
                // sortByOrder="DESC"
                render={(record: any) => `${record["createdAt"] ? helperFunc.commonDateFormat(record["createdAt"], "HH:mm:ss") : "N/A"}  `}
              /> */}
            {/* <FunctionField
                label="label.approvalStatus"
                sortBy="isApprove"
                // sortByOrder="DESC"
                render={(record: any) => `${approvalStatusLeave[record && record.isApprove]} `}
              /> */}
          </Datagrid>
        )}
      </List>
    </div>
  );
};

export default EmployeeVehicleList;
