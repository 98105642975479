import * as React from "react";
import { FC } from "react";
import { Route, RouteChildrenProps } from "react-router-dom";
import { Form } from "react-final-form";
import {
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  FormDataConsumer,
  CreateButton,
  TopToolbar,
  required,
  DateInput,
} from "react-admin";
import { useRedirect } from "ra-core";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { ListComponentProps } from "../types";
import authProvider from "../authProvider";
import GridList from "./GridList";
import helperFunc from "../helperFuncs";
import { trimValue } from "../helperValidate";
import { auth, deviceType } from "../helperChoice";
import { filterStyle, listActionsStyle } from "../helperStyle";
import { SmListHeader } from "../components/SmFormHeader";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";

const PostFilterForm = (data: any) => {
  const useStyles = makeStyles(filterStyle);
  const theme = useTheme();
  const classes = useStyles();
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_from) {
        values.time_from = moment(values.time_from).format("YYYY-MM-DD");
      }
      if (values.time_to) {
        values.time_to = moment(values.time_to).format("YYYY-MM-DD");
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };
  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDays(7);
    myFilters["time_to"] = helperFunc.addDays(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const resetFilter = () => {
    data.setFilters(filterData(), []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.time_from > new Date()) {
      return "From date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.time_to > new Date()) {
      return "To date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div className={classes.divForm}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <StreamSelectInput helperText={false}></StreamSelectInput>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <CourseSelectInput
                          helperText={false}
                          filter={{
                            isActive: true,
                            stream: formData.stream,
                          }}
                        ></CourseSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <SemesterSelectInput
                          helperText={false}
                          filter={{
                            isActive: true,
                            stream: formData.stream,
                            course: formData.course,
                          }}
                        ></SemesterSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="authType"
                      choices={auth}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      source="device"
                      label="label.deviceType"
                      // allowEmpty={true}
                      choices={deviceType}
                      helperText={false}
                    />
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      validate={[required(), checkFromDate]}
                      source="time_from"
                      label="label.fromDate"
                      alwaysOn
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      validate={[required(), checkToDate]}
                      source="time_to"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
const ListActions = (props: any) => {
  const { basePath, handleDrawerOpen } = props;
  const user = helperFunc.getUserData();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(listActionsStyle);
  const classes = useStyles();
  return (
    <TopToolbar>
      {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ||
      authProvider.isMenuShow("collegeAttendance", "create") ? (
        <CreateButton basePath={basePath} />
      ) : null}
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon className={classes.filterIcon} />
        {isSmall ? null : "Filter"}
      </Button>
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeNameCode" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const AttendanceList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = React.useState(false);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(filterStyle);
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const redirect = useRedirect();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDays(7);
    myFilters["time_to"] = helperFunc.addDays(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  return (
    <div className={classes.root}>
      <SmListHeader {...props} />
      {token ? (
        <Route path="/collegeAttendance/:id">
          {({ match }: RouteChildrenProps<{ id: string }>) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

            return (
              <>
                <div
                  className={open === true ? "overlay" : ""}
                  onClick={() => handleDrawerClose("fromlist")}
                />
                <List
                  className="list-view2"
                  actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
                  {...props}
                  filterDefaultValues={filterData()}
                  filters={
                    <AttendanceFilter
                      {...props}
                      handleDrawerClose={handleDrawerClose}
                      open={open}
                    />
                  }
                  perPage={20}
                  pagination={
                    <Pagination
                      labelRowsPerPage="Records per page"
                      rowsPerPageOptions={[10, 20, 50, 100]}
                    />
                  }
                  sort={{ field: "time", order: "DESC" }}
                >
                  <GridList
                    selectedRow={
                      isMatch
                        ? parseInt((match as any).params.id, 10)
                        : undefined
                    }
                  />
                </List>
              </>
            );
          }}
        </Route>
      ) : null}
    </div>
  );
};

export default AttendanceList;
