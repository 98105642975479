import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import { Table, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { streamStyle } from "../helperStyle";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(streamStyle);
const ViewQrPage = (props: any) => {
  const [printData, setPrintData] = useState({} as any);

  let id = {} as any;

  const dataProvider = useDataProvider();
  const classes = useStyles();

  id = useParams();

  const printCount = localStorage.getItem("printCount");

  useEffect(() => {
    const resourcePath = `public/get-qr-code/${id?.id}`;
    dataProvider
      .callCustom(resourcePath)
      .then((response: any) => {
        setPrintData(response?.data?.item);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    document.title = `${printData?.company?.name} Visitor Pass ${printCount}`;
  });

  const handlePrint = (event: any) => {
    event.preventDefault();
    window.print();
  };

  const dataArr = {
    details: [
      { value: printData?.employee?.name },
      { value: printData?.employee?.phone },
    ],
  };

  return (
    <>
      <div className="container-fluid print-view">
        <div className="row">
          <>
            <TableContainer className={classes.tablePrint}>
              <Table className="print-table">
                <TableRow>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>Gate Pass</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>{printData?.company?.name}</h4>
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell colSpan={2} className="text-center ">
                    <h4>Date of issue : {dates}</h4>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell colSpan={2} style={{ textAlign: "center" }}>
                    <img
                      src={printData.qrCode}
                      height="125px"
                      width="187px"
                      alt="qrCode"
                    />
                  </TableCell>
                </TableRow>
                {dataArr.details.map((ele) => {
                  return (
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                        {ele.value}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {/* <TableRow>
                  <TableCell>Whom To Meet</TableCell>
                  <TableCell>{printData?.invitedEmployeeId?.name}</TableCell>
                  <TableCell className="last-cell">
                    {printData?.company?.name} <br /> Authorized Sign
                  </TableCell>
                </TableRow> */}
              </Table>
            </TableContainer>
          </>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center bottom-buttons mt-10 hide-print-btn">
        <a
          className="btn btn-primary text-white mb-2"
          href="javascript:void()"
          onClick={handlePrint}
        >
          <span className="fa fa-print mr-2"></span>
          Print
        </a>
      </div>
    </>
  );
};
export default ViewQrPage;
