import React, { Fragment, useEffect, useState } from "react";
import {
  BooleanField,
  TextField,
  Datagrid,
  List,
  Pagination,
  TopToolbar,
  FunctionField,
  Button as ReactButton,
  SearchInput,
  Filter,
} from "react-admin";
import { useMediaQuery, Theme, makeStyles, Button } from "@material-ui/core";
import { trimValue } from "../helperValidate";
import { listActionsStyle } from "../helperStyle";
import helperFunc from "../helperFuncs";
import LinkField from "./LinkField";
import CreateDialogueBox from "./createDialogueBox";
import EditDialogueBox from "./editDialogueBox";
import MobileGrid from "../components/MobileGrid";
import BulkDeleteButton from "../components/BulkDeleteButton";
import { SmListHeader } from "../components/SmFormHeader";

const ListActions = (props: any) => {
  const { className } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const useStyles = makeStyles(listActionsStyle);
  const classes = useStyles();
  return (
    <TopToolbar className={isXsmall ? classes.flexrow : className}>
      <Button
        className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
        color="primary"
        component="span"
        onClick={props.handleDialogOpen}
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
        {!isSmall ? (
          <span className={classes.csvSpan}>Create New Timeslot</span>
        ) : null}
      </Button>
    </TopToolbar>
  );
};

const PostBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);
const TimeslotFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" parse={trimValue} alwaysOn />
  </Filter>
);

const TimeslotList = (props: any) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editData, setEditData] = useState([]);
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const createDialogClose = () => {
    setShowCreateDialog(false);
  };
  const createDialogOpen = () => {
    setShowCreateDialog(true);
  };
  const editDialogClose = () => {
    setShowEditDialog(false);
  };
  const editDialogOpen = () => {
    setShowEditDialog(true);
  };

  return (
    <div>
      <SmListHeader {...props} />
      <List
        className="list-view2"
        exporter={null}
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={20}
        pagination={
          <Pagination
            labelRowsPerPage="Records per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        }
        filters={<TimeslotFilter {...props} />}
        bulkActionButtons={<PostBulkActionButtons />}
        actions={<ListActions handleDialogOpen={createDialogOpen} />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <LinkField
              source="name"
              label="Time"
              open={editDialogOpen}
              setEditData={setEditData}
              {...props}
            />
            <TextField source="stream" label="Stream" />
            <BooleanField
              source="isBreak"
              label="Is Break"
              valueLabelTrue="Yes"
              valueLabelFalse="No"
              emptyText="N/A"
            />
            <FunctionField
              label="Break Title"
              render={(record: any) =>
                `${record["breakTitle"] ? record["breakTitle"] : "N/A"}  `
              }
            />
            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            <BooleanField
              source="isActive"
              label="label.status"
              valueLabelTrue="Active"
              valueLabelFalse="In-active"
              emptyText="N/A"
            />
          </Datagrid>
        )}
      </List>
      <CreateDialogueBox
        {...props}
        open={showCreateDialog}
        close={createDialogClose}
      />
      <EditDialogueBox
        {...props}
        open={showEditDialog}
        close={editDialogClose}
        editData={editData}
      />
    </div>
  );
};

export default TimeslotList;
