import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  Button,
  useNotify,
  SelectInput,
  SimpleForm,
  useDataProvider,
  required,
  FormDataConsumer,
  LinearProgress,
  TextInput,
  regex,
} from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  useMediaQuery,
  Theme,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";
import DialogueBox from "./ConfirmDialogueBox";
import { toastError, toastInfo } from "./toast";
import { regexWhitespace } from "../helperConst";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
});

interface State {
  device?: string;
  isLocal?: boolean;
  emptyValue: false;
  allotments?: [];
  objCompany: {};
  objEmployee: {};
  objShift: {};
  noOfAllotments: number;
  updateAllotment?: any;
  objShiftDataDropDown?: any;
}
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const VehicleAllotment: FC<FieldProps> = ({
  updateAllotment,
  record,
  type,
  childCompany,
}) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [emptyValue, setEmptyValue] = useState(Boolean);
  const [allotments, setAllotments] = useState(Array);

  const [noOfAllotments, setNoOfAllotments] = useState(0);
  const [isLoad, setLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [index, setIndex] = useState(Number);
  const [allotmentDialog, setAllotmentDialog] = useState({});
  const [vehicleId, setVehicleId] = useState();

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleOpenClick = (index: any, allotment: any) => {
    setShowDialog(true);
    setIndex(index);
    setAllotmentDialog(allotment);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const removeAllotment = (idx: any, record: any) => {
    let rData: any;

    rData = allotments.filter((s: any, sIdx: any) => idx === sIdx);

    let url;
    if (rData && rData.length > 0) {
      url = `employeeVehicle/delete/${rData[0]._id ? rData[0]._id : vehicleId}`;
      dataProvider
        .callCustom(url, {})
        .then((response: any) => {
          toastInfo("Vehicle removed successfully!");
          handleClick();
          setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
        })
        .catch((err: any) => {
          setLoader(false);
          toastError(err.message);
          return;
        });
    } else {
      setAllotments(allotments.filter((s: any, sIdx: any) => idx !== sIdx));
    }
  };

  useEffect(() => {
    if (record && record.id) {
      let url = `employeeVehicle/list`;

      dataProvider
        .callCustom(url, {
          filter: { employeeId: record?.id ? record.id : "" },
        })
        .then((response: any) => {
          if (response && response.data && response.data.list) {
            setAllotments(response.data.list);
            setNoOfAllotments(response?.data?.list?.length);
          }
        });
    }
  }, [record]);

  const handleDialogClose = () => setOpen(false);

  const addAllotment = (data: any) => {
    let url = `employeeVehicle/create`;

    if (data && (data.carNumber === "" || data.carNumber === undefined)) {
      toastError("Please add Vehicle Number");
      return;
    } else if (data?.carNumber && /.*\S.*/.test(data.carNumber) === false) {
      return;
    }
    // else if (
    //   data?.carNumber &&
    //   /[A-Z]{2}\s[0-9|\s]{1,2}\s[A-Z|\s]{1,2}\s[0-9]{1,4}/.test(
    //     data.carNumber
    //   ) === false
    // ) {
    //   toastError("Please add valid Vehicle Number");
    //   return;
    // }
    else {
      let duplicate = false;

      allotments.filter((s: any, sIdx: any) => {
        if (s.carNumber === data.carNumber) {
          duplicate = true;
        }
      });

      if (duplicate) {
        toastError("Duplicate Entry is not allowed");
      } else {
        setAllotments([
          ...allotments,
          {
            carNumber: data.carNumber,
            brand: data.brand,
            type: data.type,
          },
        ]);

        dataProvider
          .callCustom(url, {
            carNumber: data.carNumber,
            brand: data.brand,
            type: data.type,
            childCompanyId: record?.childCompanyId?._id,
            company: record?.company?._id,
            employee: record?.id ? record.id : "",
          })
          .then((response: any) => {
            setVehicleId(response?.data?.item?._id);
            toastInfo("Vehicle allocate Successfully");
          })
          .catch((err: any) => {
            toastError(err.message);
          });
      }

      data.carNumber = "";
      data.brand = "";
      data.type = "";
    }
  };

  return record ? (
    <div className={classes.root}>
      {(type && type === "list") || isXsmall ? (
        <span className={classes.noOfAllotments} onClick={handleClick}>
          {`Allocate Vehicles (${
            allotments && allotments.length > 0
              ? allotments.length
              : noOfAllotments
          })`}
        </span>
      ) : null}

      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Vehicle Allotments"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>Vehicle Allotments for {record.name}</DialogTitle>

        <DialogContent>
          <SimpleForm
            // onSubmit={() => addAllotment(record)}
            validate={() => true}
            toolbar={null}
          >
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  autoFocus
                  source="carNumber"
                  resource="employeeVehicle"
                  emptyValue={{ emptyValue }}
                  label="Vehicle Number*"
                  validate={regexWhitespace}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  source="brand"
                  resource="employeeVehicle"
                  formClassName={classes.name}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <TextInput
                  source="type"
                  resource="employeeVehicle"
                  formClassName={classes.name}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <MaterialButton
                  onClick={() => addAllotment(formData)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="button"
                  className={isXsmall ? undefined : classes.allotmentBtn}
                >
                  Allocate
                </MaterialButton>
              )}
            </FormDataConsumer>
            <TableContainer
              className={isXsmall ? classes.isSmallTable : classes.table}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      Car Number
                    </TableCell>

                    <TableCell className={classes.tableHead}>Brand</TableCell>

                    <TableCell className={classes.tableHead}>Type</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allotments && allotments.length > 0
                    ? allotments.map((allotment: any, index: number) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.carNumber ? allotment.carNumber : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.brand ? allotment.brand : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              {allotment.type ? allotment.type : ""}
                            </TableCell>

                            <TableCell
                              className={classes.tableValue}
                              align="left"
                            >
                              <MaterialButton
                                onClick={() =>
                                  handleOpenClick(index, allotment)
                                }
                                variant="outlined"
                                className={classes.removeAllotmentBtn}
                                type="button"
                              >
                                Remove
                              </MaterialButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}

                  {allotments && allotments.length === 0 ? (
                    <TableRow key="no Allotments">
                      <TableCell
                        className={classes.tableValue}
                        colSpan={5}
                        align="center"
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogueBox
              handleCloseClick={handleCloseClick}
              open={showDialog}
              remove={() => removeAllotment(index, record)}
              itemName={allotmentDialog}
            />
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            ""
            // <Button
            //   type="submit"
            //   label="Save"
            //   onClick={() => handleConfirm(record)}
            //   className="my-save-button"
            // >
            //   <CheckCircleIcon />
            // </Button>
          )}
          <Button
            label="Close"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

// const vehicleValidate = [
//   required(),
//   regex(
//     /[A-Z]{2}\s[0-9|\s]{1,2}\s[A-Z|\s]{1,2}\s[0-9]{1,4}/,
//     "Vehicle Number not valid"
//   ),
// ];

export default VehicleAllotment;
