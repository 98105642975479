import * as React from "react";
import { FC, memo, useState} from "react";
import {
    required,
    SelectInput,
    useDataProvider,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from 'react-final-form';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { FieldProps, Visitor, Role } from "../types";
import helperFunc from "../helperFuncs";
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "253px",
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    company_id: {
        display: "none",
    },
}));
interface State {
    designations?: Role[];
}
interface Props extends FieldProps<Visitor> { }

interface Props {
    value?: number;
    filter?: {};
    validate?: {};
    helperText?: boolean;
    dataManage?: any;
}

const DesignationSelectInput: FC<Props> = ({ helperText, validate, filter, dataManage }) => {
    const form = useForm();
    const classes = useStyles();
    const [state, setState] = useState<State>({});
    const dataProvider = useDataProvider();
    useDeepCompareEffect(() => {
        dataProvider
            .getSearchList("designations/search", filter)
            .then((response: any) => {
                setState((state) => ({
                    ...state,
                    designations: response && response.data,
                }));
            });
    }, [filter])


    const { designations } = state;

    const check = (value: any) => {
        designations?.map((data: any) => {
            if (data.id === value.value) {
                if(dataManage) {
                    dataManage(data, 'designation');
                    form.change('dataRights.zoneId', undefined);
                    form.change('dataRights.regionId', undefined);
                    form.change('dataRights.state', undefined);
                    form.change('dataRights.city', undefined);
                    form.change('dataRights.branchId', undefined);
                    form.change('reportingManager', undefined);
                }
            }
        })
    };

    return (
        <SelectInput
            resettable
            label="Designations"
            className={classes.root}
            source="designation"
            validate={validate}
            choices={designations}
            helperText={helperText}
            onChange={(value: any) => (check(value.target))}
        />
    );
};
DesignationSelectInput.defaultProps = {
    source: "designations",
    label: "resources.customers.fields.name",
};

export default memo<Props>(DesignationSelectInput);
