import React, { useState } from "react";
import {
  FormDataConsumer,
  SimpleForm,
  Button,
  useDataProvider,
  TextInput,
  required,
  regex,
  useRefresh,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ReasonSelectInput from "../components/ReasonSelectInput";
import { toastError, toastInfo } from "../components/toast";
import { color } from "highcharts";

const Remarks = (props: any) => {
  const {
    responseData,
    setResponseData,
    leaveBalanceObj,
    handleCloseClick,
    setLeaveBalanceObj,
  } = props;

  const saveForm = () => {
    if (
      leaveBalanceObj?.remarks === undefined ||
      leaveBalanceObj?.remarks === ""
    ) {
      toastError("Remarks is required");
    } else {
      let editDataResponse: any = { ...responseData };
      editDataResponse.list[leaveBalanceObj?.rowIndex]["leaveTypesData"][
        leaveBalanceObj?.leave + "_balance"
      ] =
        leaveBalanceObj?.newValue !== undefined &&
        leaveBalanceObj?.newValue !== ""
          ? Number(leaveBalanceObj?.newValue)
          : 0;
      editDataResponse.list[leaveBalanceObj?.rowIndex]["leaveTypesData"][
        leaveBalanceObj?.leave + "_remarks"
      ] = leaveBalanceObj?.remarks;
      setResponseData(editDataResponse);
      handleCloseClick();
    }
  };

  const handleMinus = (event: any) => {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  };

  const handleChange = (e: any) => {
    setLeaveBalanceObj({ ...leaveBalanceObj, [e.target.name]: e.target.value });
  };

  return (
    <SimpleForm validate={() => true} toolbar={null}>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <div className="d-flex justify-content-space-between mb-1">
            Old Leave Balance :{" "}
            <input
              disabled
              value={leaveBalanceObj?.oldValue}
              min={0}
              style={{
                width: "150px",
                borderRadius: "5px",
              }}
            ></input>
          </div>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <div className="d-flex justify-content-space-between mb-1">
            New Leave Balance :{" "}
            <input
              type="number"
              value={leaveBalanceObj?.newValue}
              defaultValue="0"
              onKeyPress={handleMinus}
              min={0}
              name="newValue"
              onChange={(e: any) => handleChange(e)}
              style={{
                width: "150px",
                border: "1px solid #0c0e1894",
                borderRadius: "5px",
              }}
            ></input>
          </div>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <div className="d-flex justify-content-space-between mb-1">
            <span>
              Remarks<span style={{ color: "red" }}>*</span> :
            </span>

            <input
              value={leaveBalanceObj?.remarks}
              name={"remarks"}
              onChange={(e: any) => handleChange(e)}
              placeholder="Remarks"
              style={{
                width: "150px",
                border: "1px solid #0c0e1894",
                borderRadius: "5px",
              }}
            ></input>
          </div>
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <div className="d-flex justify-content-space-between mb-1">
            <Button label="Save" onClick={saveForm}>
              <CheckCircleIcon />
            </Button>

            <Button
              label="ra.action.cancel"
              onClick={handleCloseClick}
              style={{ color: "#181818cf" }}
            >
              <IconCancel />
            </Button>
          </div>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default Remarks;
