import * as React from "react";
import { FC, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { FieldProps, Shift } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

interface Props extends FieldProps<Shift> {}

const FullNameField: FC<Props> = ({ record }) => {
  const classes = useStyles();
  return record ? <div className={classes.root}>{record.name}</div> : null;
};

FullNameField.defaultProps = {
  source: "name",
  label: "resources.customers.fields.name",
};

export default memo<Props>(FullNameField);
