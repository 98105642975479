import * as React from "react";
import {
  Typography,
  useMediaQuery,
  Theme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  makeStyles,
  Dialog,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { chartVideoRegistrationStyle } from "../helperStyle";

const DialogueBox = (props: {
  open: any;
  close: any;
  page: any;
  handleDefaultPage: any;
  rowsPerPage: number;
  handleRowsPerPage: any;
  dialogueData: any;
  status: string;
  loading: any;
}) => {
  const {
    close,
    page,
    open,
    handleDefaultPage,
    rowsPerPage,
    handleRowsPerPage,
    dialogueData,
    status,
    loading,
  } = props;
  const useStyles = makeStyles(chartVideoRegistrationStyle);
  const classes = useStyles();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const headerArr: any = [
    { label: "Name", key: "name" },
    { label: "Stream", key: "stream.name" },
    { label: "Course", key: "course.name" },
    { label: "Semester", key: "semester.name" },
    { label: "Classroom", key: "classroom.name" },
  ];

  const handleChangePage = (event: any, newPage: any) => {
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        className={classes.myPopup}
        aria-labelledby="simple-dialog-title"
        onClose={close}
        open={open}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">{status}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              {loading ? (
                <span className={classes.loadingDiv}>
                  <CircularProgress />
                </span>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.theadFCol}>Sr.No</TableCell>
                      {headerArr && headerArr.length > 0
                        ? headerArr.map((item: any, index: number) => {
                            return (
                              <TableCell className={classes.theadFCol}>
                                {item.label}
                              </TableCell>
                            );
                          })
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dialogueData && dialogueData.length > 0 ? (
                      dialogueData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data: any, index: number) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell>{index + 1}</TableCell>
                              {headerArr.map((item: any) => {
                                let array = [];
                                if (item.key.indexOf(".") > -1) {
                                  array = item.key.split(".");
                                  return (
                                    <TableCell>
                                      {data &&
                                      data[array[0]][array[1]] !== undefined
                                        ? data[array[0]][array[1]]
                                        : item.key}
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell>
                                    {data && data[item.key] !== undefined
                                      ? data[item.key]
                                      : item.key
                                      ? item.key
                                      : "N/A"}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No Record Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={(dialogueData && dialogueData.length) || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </div>
  );
};

export default DialogueBox;
