import ReasonList from "./ReasonList";
import { CountryIcon } from "../svgIcon";
import { CommonReason } from "./commonReason";

export default {
  list: ReasonList,
  create: CommonReason,
  edit: CommonReason,
  icon: CountryIcon,
};
