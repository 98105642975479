import * as React from "react";
import { Create } from "react-admin";
import CommonForm from '../shifts/commonForm';

const ShiftCreate = (props: any) => {
  return (
    <Create {...props}>
      <CommonForm redirect={'/shifts'}  {...props} />
    </Create >
  );
};

export default ShiftCreate;
