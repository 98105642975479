import React from "react";
import { Button, required, maxLength, regex } from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import { streamStyle } from "../helperStyle";

const useStyles = makeStyles(streamStyle);
export const VerifyDialogueBox = (props: any) => {
  const { open, close, message } = props;

  const classes = useStyles();

  return (
    <div className={classes.dialogContentDiv}>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Cap"
        className="dialogWidth4"
      >
        <DialogContent
          style={{ fontSize: "30px", color: "green", margin: "8px" }}
        >
          {message}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button label="Close" onClick={close} style={{ color: "#181818cf" }}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
