import * as React from "react";
import { FC, memo, useState } from "react";
import { required, SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "253px",
    // marginLeft: 30
  },
  name: { display: "inline-block" },
  avatar: {
    marginRight: theme.spacing(1),
  },
  company_id: {
    display: "none",
  },
}));
interface State {
  city?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const CitiesSelectInput: FC<Props> = ({ filter, formClassName }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();

  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("cities/search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          city: response && response.data,
        }));
      });
  }, [filter, dataProvider]);

  const { city } = state;

  return (
    <SelectInput
      resettable
      label="City"
      className={formClassName ? formClassName : classes.root}
      source="city"
      validate={requiredValidate}
      choices={city}
    />
  );
};
const requiredValidate = [required()];
CitiesSelectInput.defaultProps = {
  source: "city",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CitiesSelectInput);
