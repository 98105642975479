import ClientLocationList from "./ClientLocationList";
import { ClientLocationIcon } from "../svgIcon";
import { ClientLocationFormCommon } from "./ClientLocationFormCommon";

export default {
  list: ClientLocationList,
  create: ClientLocationFormCommon,
  edit: ClientLocationFormCommon,
  icon: ClientLocationIcon,
};
