import React from "react";
import { FC, useCallback } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  maxLength,
  ImageField,
  ImageInput,
  FormDataConsumer,
  BooleanInput,
  email,
  number,
  minLength,
} from "react-admin";
import { regex } from "ra-core";
import { regexWhitespace, regexSpecialChar } from "../helperConst";
import { Typography, useMediaQuery } from "@material-ui/core";
import FullNameField from "./FullNameField";
import { Leave, FieldProps, LeaveTypes, BehalfOf } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import helperFunc from "../helperFuncs";
import { approvalStatus } from "../helperChoice";
import { companyMasterStyle } from "../helperStyle";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FileUpload from "./FileUpload";
import { useDropzone } from "react-dropzone";

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const validatedate = ({
  fromDate,
  toDate,
}: {
  fromDate: string;
  toDate: string;
}) => {
  const errors = {} as any;

  if (fromDate && new Date(fromDate) < new Date()) {
    errors.fromDate = "From date must be greater than current date";
  }
  if (fromDate && toDate && new Date(toDate) < new Date(fromDate)) {
    errors.toDate = "To date must be greater than From date";
  }
  return errors;
};

const useStyles = makeStyles(companyMasterStyle);
const CommonForm = (props: any) => {
  const classes = useStyles();
  const user = helperFunc.getUserData();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const extraSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.down("lg"));
  const isXLarge = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xl")
  );

  return isSmall ? (
    <>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/companyMasters/create"
          ? "Update Company Master"
          : "Add Company Master"}
      </h2>
      <SimpleForm
        {...props}
        validate={
          props && Object.keys(props.record).length === 0 ? validatedate : null
        }
      >
        <CompanyReferenceInput></CompanyReferenceInput>
        <SectionTitle label="" />
        <TextInput
          autoFocus
          resource="clientMaster"
          label="Subsidiary Name"
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
        />
        <TextInput
          type="email"
          source="emailId"
          formClassName={extraSmall ? classes.name : classes.code}
          validate={validateEmail}
        />

        <TextInput
          source="phoneNo"
          formClassName={extraSmall ? classes.name : classes.code}
          // validate={phoneValidate}
        />
        <SectionTitle label="" />
        <div style={{ display: "inline" }}>
          {/* <TextInput
          // disabled={props && Object.keys(props.record).length === 0 ? false : true}
          source="websiteDetails"
          resource="clientMaster"
          formClassName={classes.name}
          validate={subjectValidate}
        /> */}
          <TextInput
            label="LIN Number"
            source="licenseNumber"
            formClassName={classes.name}
          />
          <TextInput source="natureOfWork" formClassName={classes.code} />
          <TextInput source="companyShortName" formClassName={classes.code} />
          <TextInput
            // disabled={props && Object.keys(props.record).length === 0 ? false : true}
            label="label.address"
            resource="clientMaster"
            source="address"
            fullWidth
            formClassName={classes.address}
            validate={reasonValidate}
          />

          <ImageInput
            // formClassName={isXsmall ? classes.imageClass : null}
            style={{ width: "50%" }}
            label="Logo"
            multiple={false}
            source="companyLogo"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ formData }: { formData: any }) =>
              formData.companyLogo && !formData.companyLogo.src ? (
                <img
                  src={
                    props && props.record && props.record.companyLogo
                      ? uploadApiUrl + props.record.companyLogo
                      : ""
                  }
                />
              ) : null
            }
          </FormDataConsumer>
          <SectionTitle label="" />
          <BooleanInput
            label="label.status"
            source="isActive"
            resource="region"
            defaultValue={true}
            formClassName={classes.name}
          />
        </div>
      </SimpleForm>
    </>
  ) : (
    <div className="form-content">
      {" "}
      <SimpleForm
        {...props}
        validate={
          props && Object.keys(props.record).length === 0 ? validatedate : null
        }
      >
        <CompanyReferenceInput></CompanyReferenceInput>
        <SectionTitle label="" />
        <TextInput
          autoFocus
          resource="clientMaster"
          source="name"
          label="Subsidiary Name"
          formClassName={classes.name}
          validate={nameValidate}
        />
        <TextInput
          type="email"
          source="emailId"
          formClassName={classes.code}
          validate={validateEmail}
        />

        <TextInput
          source="phoneNo"
          formClassName={classes.code}
          // validate={phoneValidate}
        />

        <SectionTitle label="" />

        <TextInput
          label="LIN Number"
          source="licenseNumber"
          formClassName={classes.name}
        />
        <TextInput source="natureOfWork" formClassName={classes.code} />
        <TextInput source="companyShortName" formClassName={classes.code} />
        {isXLarge && <SectionTitle label="" />}
        <TextInput
          // disabled={props && Object.keys(props.record).length === 0 ? false : true}
          label="label.address"
          resource="clientMaster"
          source="address"
          fullWidth
          formClassName={classes.address}
          validate={reasonValidate}
        />

        <ImageInput
          formClassName={classes.imageClass}
          style={{ width: "50%" }}
          label="Logo"
          multiple={false}
          source="companyLogo"
          accept="image/*"
          placeholder={
            <p>
              Drop a picture to <span style={{ color: "blue" }}>upload </span>,
              or <span style={{ color: "blue" }}>click </span>to select it.
            </p>
          }
          classes={{ dropZone: classes.dropZone, p: classes.pTag }}
          // input={{
          //   style: {
          //     border: "2px dashed #aaa",
          //     borderRadius: "5px",
          //     padding: "1rem",
          //     backgroundColor: "#f9f9f9",
          //   },
          // }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.companyLogo && !formData.companyLogo.src ? (
              <img
                style={{ width: 350, height: 200 }}
                src={
                  props && props.record && props.record.companyLogo
                    ? uploadApiUrl + props.record.companyLogo
                    : ""
                }
              />
            ) : null
          }
        </FormDataConsumer>
        <SectionTitle label="" />
        <BooleanInput
          label="label.status"
          source="isActive"
          resource="region"
          defaultValue={true}
          formClassName={classes.name}
        />
      </SimpleForm>
    </div>
  );
};

const nameValidate = [required(), maxLength(50), regexWhitespace];

const phoneValidate = [
  required(),
  maxLength(20),
  number(),
  minLength(10),
  regexWhitespace,
];
const validateEmail = [required(), email(), maxLength(320), regexWhitespace];

const reasonValidate = [required(), maxLength(320), regexWhitespace];

export default CommonForm;
