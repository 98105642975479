import * as React from "react";
import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
  FormDataConsumer,
  ImageInput,
  ImageField,
  useDataProvider,
  FileInput,
  Button,
  useRedirect,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { Department, FieldProps } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { departmentstyles } from "../helperStyle";
import { SelectInput } from "../common";
import { toastError } from "../components/toast";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const useStyles = makeStyles(departmentstyles);

const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { resource, id, location, basePath } = props;
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [fileData, setFileData] = React.useState();

  const handleClick = (e: any, employeeId: any) => {
    let imgData = new FormData();
    imgData?.append("file", e, e?.name);

    if (employeeId !== undefined) {
      dataProvider
        .callCustom(`employee-document/upload/${employeeId}`, imgData)
        .then((response: any) => {
          setFileData(response?.data?.item?.file);
        })
        .catch((err: any) => console.log(err));
    } else {
      toastError("Please Select Employee");
    }
  };

  const handleViewDocs = (fileUrl: any) => {
    window.open(`${uploadApiUrl}${fileUrl}`, "_blank");
  };

  const saveForm = (value: any) => {
    delete value?.fileUrl;

    const resourcePath =
      location && location.pathname === `/${resource}/create`
        ? `${resource}/create`
        : `${resource}/update/${id}`;
    dataProvider
      .callCustom(resourcePath, { ...value, fileUrl: fileData })
      .then((response: any) => redirect(basePath))
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  return (
    <SimpleForm {...props} save={saveForm}>
      <Typography className={classes.text}>
        {props?.location?.state?.name}
      </Typography>
      <CompanyReferenceInput></CompanyReferenceInput>
      <FormDataConsumer alwaysOn>
        {({ formData }: { formData: any }) => (
          <TextInput
            source="employeeId"
            title=""
            formClassName={isXsmall ? classes.name : classes.name}
            defaultValue={props?.location?.state?.id}
            disabled={true}
            style={{ display: "none" }}
          />
        )}
      </FormDataConsumer>

      <SectionTitle label="" />

      {/* <TextInput
        source="fileName"
        formClassName={isXsmall ? classes.name : classes.name}
        validate={codeValidate}
      /> */}

      <div className="d-flex ">
        <SelectInput
          filter={{ isActive: true }}
          label="Category"
          source="documentCategoryId"
          apiName="document-category"
        />

        <div style={{ marginLeft: "32px" }}>
          <SelectInput
            filter={{ isActive: true }}
            label="Document Type"
            source="documentTypeId"
            apiName="document-type"
          />
        </div>
      </div>

      <SectionTitle label="" />

      <FormDataConsumer>
        {({ formData }: { formData: any }) => {
          return (
            <FileInput
              multiple={false}
              source="fileUrl"
              label="Images/Documents (Max. 20MB)"
              accept="image/png, image/jpeg,application/pdf,.doc,.docx"
              placeholder={
                <p>
                  Drop a picture to{" "}
                  <span style={{ color: "blue" }}>upload </span>, or{" "}
                  <span style={{ color: "blue" }}>click </span>to select it.
                </p>
              }
              onChange={(e: any) => handleClick(e, formData?.employeeId)}
              classes={{ dropZone: classes.dropZone, p: classes.pTag }}
            >
              <ImageField source="src" title="title" />
            </FileInput>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer formClassName={classes.name}>
        {({ formData }: { formData: any }) =>
          formData && formData.fileUrl && !formData.fileUrl.src ? (
            <img
              style={{ width: 350, height: 200 }}
              alt={
                props && props.record && props.record.fileUrl
                  ? uploadApiUrl + props.record.fileUrl
                  : ""
              }
              onClick={() => handleViewDocs(props.record.fileUrl)}
              src={
                props && props.record && props.record.fileUrl
                  ? uploadApiUrl + props.record.fileUrl
                  : ""
              }
            />
          ) : null
        }
      </FormDataConsumer>

      <SectionTitle label="" />

      <BooleanInput
        label="label.status"
        source="isActive"
        resource="departments"
        defaultValue={true}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [required(), maxLength(20)];
const DepartmentTitle: FC<FieldProps<Department>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
