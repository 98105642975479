import SemesterList from "./semesterList";
import { CommonSemester } from "./commonSemester";
import { SemesterIcon } from "../svgIcon";

export default {
  list: SemesterList,
  create: CommonSemester,
  edit: CommonSemester,
  icon: SemesterIcon,
};
