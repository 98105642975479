import * as React from "react";
import { FC, useState } from "react";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useDataProvider,
} from "react-admin";
import employees from "../employees";
import { FieldProps, Visitor } from "../types";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { toastError, toastInfo } from "../components/toast";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  mobileRoot: { textAlign: "left" },
  button: { backgroundColor: "#f44336" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const DeRegistrationToolbar: FC<FieldProps<Visitor>> = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  // const notify = useNotify();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (id: any) => {
    dataProvider
      .getOne("employees", {
        id: id,
      })
      .then((response: any) => {
        if (response.data) {
          const employeeData = response.data;
          dataProvider
            .callCustomLocal(employeeData.device.edgeURL + "/deregistration", {
              traget_ID: id,
            })
            .then((responseLocal: any) => {
              if (responseLocal.data.success === true) {
                dataProvider
                  .callCustom("employees/employee_de_registration", {
                    person_ID: id,
                    isRegistred: false,
                  })
                  .then((response: any) => {
                    toastInfo("Visitor de-register successfully.");
                    // notify("Visitor de-register successfully.");
                    refresh();
                  });
              } else {
                toastError(responseLocal.data.msg);
                // notify(responseLocal.data.msg, "warning");
              }
            });
        }
      });

    setOpen(false);
  };

  return record ? (
    <div className={isXsmall ? classes.mobileRoot : classes.root}>
      {isXsmall ? (
        <employees.icon
          onClick={handleClick}
          style={{ fontSize: 30, color: "red", textAlign: "center" }}
        />
      ) : (
        <Button
          variant="contained"
          label="De-Registration"
          onClick={handleClick}
          align="right"
          className={classes.button}
        >
          <employees.icon className={classes.icon} />
        </Button>
      )}
      <Confirm
        isOpen={open}
        title="De-Registration"
        content="Are you sure you wish to De-register this Visitor ?"
        onConfirm={() => handleConfirm(record.id)}
        onClose={handleDialogClose}
      />
    </div>
  ) : null;
};

export default DeRegistrationToolbar;
