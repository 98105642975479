import * as React from "react";
import { Create } from "react-admin";
import CommonForm from '../employeeAllotments/commonForm';


const EmployeeAllotmentCreate = (props: any) => {
  return (
    <Create {...props} >
      <CommonForm redirect={'/employeeAllotments'}  {...props} />
    </Create>
  );
};


export default EmployeeAllotmentCreate;
