import React from "react";
import PropTypes from "prop-types";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Button, useMediaQuery, Theme } from "@material-ui/core";
import { lightTheme } from "./themes";

interface Props {}

const VisitorManagement = (props: Props) => {
  var advantageboxRef1 = React.useRef(null);
  var container: any = null;

  const handleMouseEnter = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.add("active");
    container = null;
  };

  const handleMouseOut = (id: string) => {
    if (container === null) {
      container =
        typeof document !== "undefined" ? document.getElementById(id) : null;
    }
    container.classList.remove("active");
    container = null;
  };
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <div className="inner-page visitor-management dropdown-page">
        {/* <div id="preloader">
          <div classNameName="preload-content">
            <div id="dream-load"></div>
          </div>
        </div> */}
        <Header />
        {/* <!--Header Section End--> */}
        {/* <!-- Breadcrumb Start --> */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-shape"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inn">
                  <div
                    // onMouseOver={() => handleMouseEnter("advantage1")}
                    // onMouseOut={() => handleMouseOut("advantage1")}
                    className="section-title wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <h2>
                      <span>VISITOR MANAGEMENT</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Breadcrumb End --> */}
        <section
          className="section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-left">
                  <h2>
                    <span>Visitors Management System </span> <span></span>
                  </h2>
                  <h3 className="mb-3">
                    <span className="underline">Based On Touchless AI</span>
                  </h3>
                  <p>
                    In any organization visitors management and their resource
                    access is very crucial for security and collaborations
                    prospective. During this pandemic situation employees and
                    workplace protection is also the most important aspect for
                    every organization. To fulfill this need touchless visitors
                    and employees management will be the future of modern
                    organizations. With the Turnout both employees and visitors
                    can check-in safely, reducing the risk of infections.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src="assets/img/visitor-1.png"
                  alt="Visitors Management System"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-section  turnout-s section_100 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">Key Features</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage1")}
                  onMouseOut={() => handleMouseOut("advantage1")}
                  id="advantage1"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-registration"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>On the fly touchless visitor registration.</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage2")}
                  onMouseOut={() => handleMouseOut("advantage2")}
                  id="advantage2"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-notification"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Host notification for acceptance or decline.</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage3")}
                  onMouseOut={() => handleMouseOut("advantage3")}
                  id="advantage3"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="tur-integration"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Easy integration with existing webhooks.</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage4")}
                  onMouseOut={() => handleMouseOut("advantage4")}
                  id="advantage4"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-real-time"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Real time list of on premises visitors.</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage5")}
                  onMouseOut={() => handleMouseOut("advantage5")}
                  id="advantage5"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-host-visitor"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>
                      Notification to reception and host for visitors longer
                      stay.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage6")}
                  onMouseOut={() => handleMouseOut("advantage6")}
                  id="advantage6"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-touchless-notification"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Touchless check-out and notification.</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  ref={advantageboxRef1}
                  className="service-box web-box wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  onMouseOver={() => handleMouseEnter("advantage7")}
                  onMouseOut={() => handleMouseOut("advantage7")}
                  id="advantage7"
                >
                  <div className="web-icon tai-icon-center mr-2 ml-0">
                    <i className="turnout1-visitor-maintain"></i>
                  </div>
                  <div className="service-title text-left">
                    <h3>Maintain pre-registered visitors for quick go in.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="turnout-s section_100 wow fadeInUp pb-0"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="about-top-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="about-bottom-shape">
            <img src="assets/img/about-shape.png" alt="about shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-left text-center">
                  <h2>
                    <span className="underline">How It Works</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <img src="assets/img/how-it-works.png" alt="How it works" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Footer Start --> */}
        <Footer />
      </div>
    </React.Fragment>
  );
};

VisitorManagement.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const VisitorManagementWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <VisitorManagement {...props} />
  </ThemeProvider>
);

export default VisitorManagementWithTheme;
