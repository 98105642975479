import * as React from "react";
import { FC, memo, useState, useEffect } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps, Visitor, Role } from "../types";
import { useForm } from "react-final-form";
const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    maxWidth: "240px",
  },
}));

interface State {
  courses?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  propsUrl?: any;
  value?: number;
  filter?: any;
  data?: [];
  validate?: {};
  dataManage?: any;
  helperText?: boolean;
  labelName?: string;
  objCourse?: any;
  objSubject?: any;
  objSemester?: any;
  streamChange?: any;
  textWrap?: boolean;
}

const CourseSelectInput: FC<Props> = ({
  filter,
  record,
  validate,
  dataManage,
  propsUrl,
  helperText,
  labelName,
  objCourse,
  objSubject,
  objSemester,
  streamChange,
  textWrap,
}) => {
  const form = useForm();
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  const searchUrl = "course/search";
  const classes = useStyles();

  useEffect(() => {
    if (filter && filter.stream) {
      dataProvider
        .getSearchList(propsUrl ? propsUrl : searchUrl, filter)
        .then((response: any) => {
          response &&
            response.data.forEach((item: any) => {
              item.name = `${item.name}`;
            });

          setState((state) => ({
            ...state,
            courses: response && response.data,
          }));
        });
    }
  }, [filter.stream]);

  useEffect(() => {
    if (objCourse && Object.keys(objCourse).length == 0)
      form.change("course", undefined);
    if (objSemester && Object.keys(objSemester).length == 0)
      form.change("semester", undefined);
    if (objSubject && Object.keys(objSubject).length == 0)
      form.change("subject", undefined);
  }, [filter]);

  if (streamChange) {
    form.change("course", undefined);
  }

  const { courses } = state;

  const check = (value: any) => {
    courses?.map((data: any) => {
      if (data.id === value.value) {
        if (dataManage) {
          dataManage(data, "course");
        }
      }
    });
  };

  return (
    <SelectInput
      resettable
      label={labelName ? labelName : "Course"}
      disabled={record && record.mode === "auto" ? true : false}
      source="course"
      validate={validate}
      choices={courses}
      onChange={(value: any) => check(value.target)}
      helperText={helperText}
      className={textWrap ? classes.root : ""}
      id="CourseSelectInput"
    />
  );
};

CourseSelectInput.defaultProps = {
  source: "course",
  label: "resources.customers.fields.name",
};

export default memo<Props>(CourseSelectInput);
