import React, { FC } from "react";
import {
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  BooleanInput,
  maxLength,
} from "react-admin";
import { regex } from "ra-core";
import { Typography, useMediaQuery } from "@material-ui/core";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import FullNameField from "./FullNameField";
import { LeaveTypes, FieldProps, Employee } from "../types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { leaveTypestyles } from "../helperStyle";
import ZoneSelectInput from "../components/ZoneSelectInput";
import helperFunc from "../helperFuncs";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export const validatePasswords = ({
  assessmentYearStartOn,
  assessmentEndOn,
}: {
  assessmentYearStartOn: any;
  assessmentEndOn: any;
}) => {
  const errors = {} as any;
  var date = new Date(assessmentYearStartOn);
  date.setDate(date.getDate() + 365);
  if (assessmentEndOn && assessmentEndOn! < date) {
    errors.assessmentEndOn =
      "Assessment Year End Date should be greater then 1 year";
  }

  return errors;
};

const useStyles = makeStyles(leaveTypestyles);
const CommonForm = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return isSmall ? (
    <React.Fragment>
      <h2
        style={{
          display: "flex",
          fontSize: 25,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        {props &&
        props.location &&
        props.location.pathname !== "/regions/create"
          ? "Update Region"
          : "Add Region"}
      </h2>
      <SimpleForm {...props} validate={validatePasswords}>
        <CompanyReferenceInput></CompanyReferenceInput>
        <SectionTitle label="" />
        <ZoneSelectInput />
        <SectionTitle label="" />
        <TextInput
          autoFocus
          resource="region"
          source="name"
          formClassName={classes.name}
          validate={nameValidate}
          label="Region Name"
        />

        <TextInput
          source="code"
          resource="zones"
          formClassName={isXsmall ? classes.name : classes.code}
          validate={codeValidate}
          format={helperFunc.codeFormatter}
        />

        <BooleanInput
          label="label.status"
          source="isActive"
          resource="region"
          defaultValue={true}
          formClassName={isXsmall ? classes.name : classes.code}
        />
      </SimpleForm>
    </React.Fragment>
  ) : (
    <SimpleForm {...props} validate={validatePasswords}>
      <CompanyReferenceInput></CompanyReferenceInput>
      <ZoneSelectInput />
      <SectionTitle label="" />
      <TextInput
        autoFocus
        resource="regions"
        source="name"
        formClassName={classes.name}
        validate={nameValidate}
        label="Region Name"
      />
      <TextInput
        source="code"
        resource="regions"
        formClassName={isXsmall ? classes.name : classes.code}
        validate={codeValidate}
        format={helperFunc.codeFormatter}
      />
      <SectionTitle label="" />
      <BooleanInput
        label="label.status"
        source="isActive"
        resource="region"
        defaultValue={true}
        formClassName={classes.name}
      />
    </SimpleForm>
  );
};
const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const codeValidate = [
  required(),
  maxLength(20),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
const LeaveTitle: FC<FieldProps<LeaveTypes>> = ({ record }) =>
  record ? <FullNameField record={record} /> : null;

const requiredValidate = [required()];

export default CommonForm;
