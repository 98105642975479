import RequestList from "./teamRequestList";
import { CommonStream } from "./streamCommon";
import { StreamIcon } from "../svgIcon";

export default {
  list: RequestList,
  // create: CommonStream,
  // edit: CommonStream,
  icon: StreamIcon,
};
