import * as React from "react";
import { Edit } from "react-admin";
import CommonForm from "./CommonForm";
import { PostEditToolbar } from "../components/PostEditToolbar";

const DeviceEdit = (props: any) => {
  return (
    <Edit undoable={false} title={"Edit Devices"} {...props}>
      <CommonForm
        toolbar={<PostEditToolbar titleSource="API_key" />}
        {...props}
      />
    </Edit>
  );
};

export default DeviceEdit;
