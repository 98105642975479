import EmployeeAllotmentIcon from "@material-ui/icons/PersonAdd";
import EmployeeAllotmentList from "./EmployeeAllotmentList";
import EmployeeAllotmentCreate from "./EmployeeAllotmentCreate";
import EmployeeAllotmentEdit from "./EmployeeAllotmentEdit";

export default {
  list: EmployeeAllotmentList,
  create: EmployeeAllotmentCreate,
  edit: EmployeeAllotmentEdit,
  icon: EmployeeAllotmentIcon,
};
