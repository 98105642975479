import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  FormDataConsumer,
  Button,
  useDataProvider,
  useRefresh,
  LinearProgress,
  TextInput,
  required,
  maxLength,
  regex,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";

import { streamStyle } from "../helperStyle";
import { SectionTitle, validate } from "../helperValidate";

const useStyles = makeStyles(streamStyle);
export const OtpDialogueBox = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const classes = useStyles();
  const [paramsData, setParamsData] = useState() as any;

  const [viewLoading, setViewLoading] = useState(false);

  const { close, open, saveForm } = props;

  return (
    <div className={classes.dialogContentDiv}>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Otp Validation"
        style={{ zIndex: 1 }}
        className="dialogWidth2"
      >
        <DialogTitle className={classes.pb0}>
          <span className={classes.headTitle}>OTP Validation</span>
        </DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <SimpleForm {...props} toolbar={null}>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => {
                setParamsData(formData);
                return (
                  <TextInput
                    source="otp"
                    resource="invite-visitor"
                    formClassName={classes.name}
                  />
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          {viewLoading ? (
            <LinearProgress />
          ) : (
            <Button
              label="Save"
              onClick={() => saveForm(paramsData)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button label="Cancel" onClick={close} style={{ color: "#181818cf" }}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const nameValidate = [
  required(),
  maxLength(50),
  regex(/.*\S.*/, "Only whitespaces are not allowed"),
];
