import React, { useState } from "react";

import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import { translate, crudDelete, startUndoable } from "ra-core";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Button, useDataProvider, useRedirect } from "react-admin";
import { toastError, toastInfo } from "./toast";

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
});

const DeleteButtonWithConfirmation = (props) => {
  const {
    basePath,
    classes,
    className,
    dispatchCrudDelete,
    label,
    record,
    resource,
    startUndoable,
    translate,
    undoable,
    titleSource,
    divClassName,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDelete = (event) => {
    event.preventDefault();

    setShowDialog(false);

    if (undoable) {
      startUndoable(
        crudDelete(resource, record.id, record, basePath, redirect)
      );
    } else {
      let url = `${resource}/delete/${record.id}`;
      dataProvider
        .callCustom(url, {})
        .then((response) => {
          toastInfo("Record deleted successfully!");
          redirect("list", basePath);
        })
        .catch((err) => {
          toastError(err.message);
          return;
        });
    }
  };

  return (
    <div
      style={{ marginLeft: "auto" }}
      className={divClassName ? divClassName : ""}
    >
      <Button
        align="right"
        onClick={handleClick}
        label={label}
        className={classnames(
          "ra-delete-button",
          classes.deleteButton,
          className
        )}
        key="button"
      >
        <ActionDelete />
      </Button>
      <Dialog
        // fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Are you sure?"
      >
        <DialogTitle>
          Deleting{" "}
          {titleSource
            ? record[titleSource] && record[titleSource].name
              ? record[titleSource].name
              : record[titleSource]
            : record.name
            ? record.name
            : "– unnamed –"}
          {resource === "leave-management" || resource === "teamLeave"
            ? "'s Leave"
            : null}
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: window.screen.width < 460 ? 15 : 0 }}>
            Are you sure you want to delete this entry?{" "}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            label="Confirm"
            className={classnames(
              "ra-delete-button",
              classes.deleteButton,
              className
            )}
            key="button"
          >
            <CheckCircleIcon />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: "list",
  undoable: true,
};

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate,
  withStyles(styles)
)(DeleteButtonWithConfirmation);
