import React from "react";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

export const MyMapComponent = compose(
    withProps({
        /**
         * Note: create and replace your own key in the Google console.
         * https://console.developers.google.com/apis/dashboard
         * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
         */
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQU_3FGtB4sQZE5GO4g8OT4wZrRm5H6kg&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px`, width: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => {
    let lat = props.record && props.record.record && props.record.record.lat ? parseFloat(props.record.record.lat) : 23.0283;
    let lng = props.record && props.record.record && props.record.record.long ? parseFloat(props.record.record.long) : 72.5587;
    return (
        <GoogleMap defaultZoom={12} defaultCenter={{ lat: props.record && props.record.record && props.record.record.lat ? parseFloat(props.record.record.lat) : 23.0283, lng: props.record && props.record.record && props.record.record.long ? parseFloat(props.record.record.long) : 72.5587 }}>
            {props.isMarkerShown &&
                <Marker position={{ lat: lat, lng: lng }} />
            }
        </GoogleMap>
    )
});
