import React from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";

import { smFormHeaderStyle } from "../helperStyle";

const useStyles = makeStyles(smFormHeaderStyle);
export const SmFormHeader = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const classes = useStyles();
  const { resource, location } = props;
  const resourceName = translate(`resources.${resource}.name`, resource);
  return (
    <div>
      {isSmall ? (
        <h2 className={classes.text}>
          {location && location.pathname !== `/${resource}/create`
            ? `Update ${resourceName}`
            : `Add ${resourceName}`}
        </h2>
      ) : null}
    </div>
  );
};
export const SmListHeader = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();
  const classes = useStyles();
  const { resource } = props;
  const resourceName = translate(`resources.${resource}.title`, resource);

  return (
    <div>
      {isXsmall ? <h2 className={classes.textList}>{resourceName}</h2> : null}
    </div>
  );
};
