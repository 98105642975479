import * as React from "react";
import { FC, memo, useState } from "react";
import { useDataProvider, AutocompleteArrayInput } from "react-admin";
// import { makeStyles } from "@material-ui/core/styles";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FieldProps, Visitor, Role } from "../types";

interface State {
  cities?: Role[];
}
interface Props extends FieldProps<Visitor> {}

interface Props {
  value?: number;
  filter?: {};
}

const MultiSelectCityInput: FC<Props> = ({ filter }) => {
  const [state, setState] = useState<State>({});
  const dataProvider = useDataProvider();
  useDeepCompareEffect(() => {
    dataProvider
      .getSearchList("cities/multiple-search", filter)
      .then((response: any) => {
        setState((state) => ({
          ...state,
          cities: response && response.data,
        }));
      });
  }, [filter]);

  const { cities } = state;

  return (
    <AutocompleteArrayInput
      resettable
      label="Cities"
      source="dataRights.city"
      choices={cities}
    />
    // <ReferenceArrayInput
    //     resettable
    //     label="Cities"
    //     source="city"
    //     reference="cities"
    // // filter={{ company: formData.company, isActive: true }}
    // // validate={requiredValidate}
    // // formClassName={classes.referenceEmpArray}
    // >
    //     <AutocompleteArrayInput resettable />
    // </ReferenceArrayInput>
  );
};

MultiSelectCityInput.defaultProps = {
  source: "states",
  label: "resources.customers.fields.name",
};

export default memo<Props>(MultiSelectCityInput);
