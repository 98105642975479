import * as React from "react";
import {
  TextField,
  Datagrid,
  Filter,
  List,
  Pagination,
  SelectInput,
  SearchInput,
  downloadCSV,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  DateInput,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
// import TeamLeaveLinkField from "./TeamLeaveLinkField";
import MobileGrid from "./MobileGrid";
import jsonExport from "jsonexport/dist";
import { approvalStatus } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
// import { DateInput } from 'react-admin-date-inputs';
import CompanyReferenceInput from "../components/CompanyReferenceInput";

const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.createdAt) {
        // values.createdAt = helperFunc.createdAtDateFormat(values.createdAt);
        var dates = helperFunc.filterCreatedAt(values.createdAt);
        values.createdAt_from = dates.time_from;
        values.createdAt_to = dates.time_to;
      } else {
        values.createdAt_from = undefined;
        values.createdAt_to = undefined;
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters({}, []);
    data.handleDrawerClose("fromFilter");
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <CloseIcon onClick={() => data.handleClose()} /> */}
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      // <ReferenceInput source="company" label="label.client" reference="companies">
                      //   <SelectInput
                      //     resettable={true}
                      //     filter={{ isActive: true }}
                      //     optionText={(choice: Company) => `${choice.name}`}
                      //     helperText={false}
                      //   />
                      // </ReferenceInput>
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      label="label.approvalStatus"
                      source="isApprove"
                      choices={approvalStatus}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <DateInput
                      source="createdAt"
                      label="label.createdAt"
                      helperText={false}
                      options={{
                        format: helperFunc.datePickerFormat(),
                        clearable: true,
                      }}
                      inputProps={{
                        min: "1900-08-10",
                        max: "3000-08-20",
                      }}
                    />
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    // filters,
    // showFilter,
    // displayedFilters,
    handleDrawerOpen,
    // open
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar className={className}>
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* <ImportButton basePath={basePath} {...props} /> */}
    </TopToolbar>
  );
};

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

const PostPanel = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const mode: any = {
    mobile: "Mobile",
    edge: "Edge",
    manual: "Manual",
    cron: "Cron",
    auto: "Auto",
  };
  return (
    <table style={{ width: isXsmall ? "100vh" : "100%" }}>
      <tr>
        {/* <th>Designation</th>
        <th>Department</th>
        <th>In Mode</th>
        <th>In Latitude</th>
        <th>In Longitude</th>
        <th>Out Mode</th>
        <th>Out Latitude</th>
        <th>Out Longitude</th> */}
      </tr>
      <tr>
        {/* <td>{props && props.record && props.record.designationData && props.record.designationData.name ? props.record.designationData.name : "N/A"}</td>
        <td>{props && props.record && props.record.departmentData && props.record.departmentData.name ? props.record.departmentData.name : "N/A"}</td>
        <td>{props && props.record && props.record.inMode ? mode[props.record.inMode] : "N/A"}</td>
        <td>{props && props.record && props.record.inLoc && props.record.inLoc.lat ? props.record.inLoc.lat : "N/A"}</td>
        <td>{props && props.record && props.record.inLoc && props.record.inLoc.lng ? props.record.inLoc.lng : "N/A"}</td>
        <td>{props && props.record && props.record.outMode ? mode[props.record.outMode] : "N/A"}</td>
        <td>{props && props.record && props.record.outLoc && props.record.outLoc.lat ? props.record.outLoc.lat : "N/A"}</td>
        <td>{props && props.record && props.record.outLoc && props.record.outLoc.lng ? props.record.outLoc.lng : "N/A"}</td> */}
      </tr>
    </table>
  );
};

const LeaveFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="employeeName" parse={trimValue} alwaysOn />
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const exporter = (records: any) => {
  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
  };
  const recordsForExport = records.map((record: any) => {
    const {
      name,
      leaveType,
      leaveDays,
      isApprove,
      approvedBy,
      mailFrom,
      subject,
      fromDate,
      rejectBy,
      description,
      toDate,
      notifyTo,
      sandwichRule,
      isDeleted,
      isActive,
      permissions,
      _id,
      createdBy,
      company,
      createdAt,
      updatedAt,
      __v,
      id,
      updatedBy,
      employee,
      ...dataToExport
    } = record; // omit fields
    const user = helperFunc.getUserData();
    if (user && user.type === "super") {
      dataToExport["Client"] = company && company.name;
    }
    dataToExport["Name"] = employee && employee.name; // add a field
    dataToExport["Subject"] = subject;
    dataToExport["Leave Type"] = leaveType && leaveType.leaveName;
    dataToExport["From Date"] = helperFunc.commonDateFormat(fromDate); // add a field
    dataToExport["To Date"] = helperFunc.commonDateFormat(toDate); // add a field
    dataToExport["Reason"] = description;
    dataToExport["Created At"] = helperFunc.commonDateFormat(
      createdAt,
      "HH:mm:ss"
    ); // add a field
    dataToExport["Approval Status"] = approvalStatusLeave[isApprove]; // add a field
    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Team Leaves Report"); // download as 'xxxx.csv` file
    }
  );
};

const TeamLeaveList = (props: any) => {
  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
  };
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const user = helperFunc.getUserData();
  return (
    <div>
      <div
        className={open === true ? "overlay" : ""}
        onClick={() => handleDrawerClose("fromlist")}
      />
      <div
        style={
          isXsmall
            ? {
                width: window.screen.width,
                overflowX: "scroll",
              }
            : {}
        }
      >
        <List
          className="list-view2"
          exporter={exporter}
          bulkActionButtons={false}
          {...props}
          filters={
            isSmall ? (
              <LeaveFilter
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            ) : (
              <LeaveFilter
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            )
          }
          sort={{ field: "createdAt", order: "DESC" }}
          perPage={20}
          pagination={
            <Pagination
              labelRowsPerPage="Records per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          }
          title="resources.leaveHistory.title"
          actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
          // aside={<TeamLeaveListAside />}
        >
          {/* {isXsmall ? (
          <MobileGrid />
        ) : ( */}
          <Datagrid expand={<PostPanel {...props} />} optimized>
            {/* <TeamLeaveLinkField /> */}
            <TextField label="Name" source="employee.name" />
            {user.type === "super" ? (
              <TextField label="Company" source="company.name" />
            ) : null}
            <TextField label="label.createdBy" source="createdBy.name" />

            <FunctionField
              label="label.createdAt"
              sortBy="createdAt"
              // sortByOrder="DESC"
              render={(record: any) =>
                `${
                  record["createdAt"]
                    ? helperFunc.commonDateFormat(
                        record["createdAt"],
                        "HH:mm:ss"
                      )
                    : "N/A"
                }  `
              }
            />
            {/* <FunctionField
                label="label.approvalStatus"
                sortBy="isApprove"
                // sortByOrder="DESC"
                render={(record: any) => `${approvalStatusLeave[record && record.isApprove]} `}
              /> */}
          </Datagrid>
          {/* )} */}
        </List>
      </div>
    </div>
  );
};

export default TeamLeaveList;
