import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  FormDataConsumer,
  Button,
  useDataProvider,
  useRefresh,
  LinearProgress,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";
import { useRedirect } from "ra-core";
import { streamStyle } from "../helperStyle";
import { SectionTitle, validate } from "../helperValidate";
import { toastError, toastInfo } from "../components/toast";
import MultiSelectTimeslot from "../components/MultiSelectTimeslotInput";
import MultiSelectWeekdaysInput from "../components/MultiSelectWeekdaysInput";
import SubjectSelectInput from "../components/DefaultSubjectSelectInput";
import FacultySelectInput from "../components/DefaultFacultySelectInput";
import StreamSelectInput from "../components/StreamSelectInput";
import CourseSelectInput from "../components/CourseSelectInput";
import SemesterSelectInput from "../components/SemesterSelectInput";
import ClassroomSelectInput from "../components/ClassroomSelectInput";

const useStyles = makeStyles(streamStyle);
export const AddDialogueBox = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const [paramsData, setParamsData] = useState() as any;
  const [isLoad, setLoader] = useState(false);
  const [streamChange, setStreamChange] = useState(Boolean);
  const [courseChange, setCourseChange] = useState(Boolean);
  const [semesterChange, setSemesterChange] = useState(Boolean);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { close, open } = props;

  const saveForm = (data: any) => {
    if (data.stream === undefined || data.stream === null) {
      toastError("Please select Stream");
    } else if (data.course === undefined || data.course === null) {
      toastError("Please select Course");
    } else if (data.semester === undefined || data.semester === null) {
      toastError("Please select Semester");
    } else if (data.classroom === undefined || data.classroom === null) {
      toastError("Please select Classroom");
    } else if (data.weekDays === undefined || data.weekDays === null) {
      toastError("Please select Weekday(s)");
    } else if (data.timeslotIds === undefined || data.timeslotIds === null) {
      toastError("Please select Timeslot(s)");
    } else {
      setLoader(true);
      dataProvider
        .callCustom("assign-timeslot/create", {
          timeslots: data && data.timeslotIds ? data.timeslotIds : null,
          weekdays: data && data.weekDays ? data.weekDays : null,
          stream: data && data.stream ? data.stream : null,
          classroom: data && data.classroom ? data.classroom : null,
          semester: data && data.semester ? data.semester : null,
          course: data && data.course ? data.course : null,
        })
        .then((response: any) => {
          setLoader(false);
          close();
          redirect("assign-timeslot/list");
          toastInfo(`Assign Timeslot added Succesfully`);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  const streamData = (data: any) => {
    setStreamChange(true);
    setTimeout(function () {
      setStreamChange(false);
    }, 1000);
  };
  const courseData = (data: any) => {
    setCourseChange(true);
    setTimeout(function () {
      setCourseChange(false);
    }, 1000);
  };
  const semesterData = (data: any) => {
    setSemesterChange(true);
    setTimeout(function () {
      setSemesterChange(false);
    }, 1000);
  };

  return (
    <div className={classes.dialogContentDiv}>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Timeslot Allotments"
        style={{ zIndex: 1 }}
        className="dialogWidth"
      >
        {" "}
        <DialogTitle className={classes.pb0}>
          {" "}
          <span className={classes.headTitle}>Assign Timeslots</span>
        </DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <SimpleForm {...props} toolbar={null}>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <StreamSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                    }}
                    dataManage={streamData}
                  ></StreamSelectInput>
                );
              }}
            </FormDataConsumer>

            <SectionTitle label="" />
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <CourseSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                    }}
                    streamChange={streamChange}
                    dataManage={courseData}
                  ></CourseSelectInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <SemesterSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                      course: formData.course,
                    }}
                    dataManage={semesterData}
                    streamChange={streamChange}
                    courseChange={courseChange}
                  ></SemesterSelectInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isXSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <ClassroomSelectInput
                    validate={validate}
                    filter={{
                      isActive: true,
                      company: formData.company,
                      stream: formData.stream,
                      course: formData.course,
                      semester: formData.semester,
                    }}
                    streamChange={streamChange}
                    courseChange={courseChange}
                    semesterChange={semesterChange}
                  ></ClassroomSelectInput>
                );
              }}
            </FormDataConsumer>

            <SectionTitle label="" />
            <FormDataConsumer formClassName={isSmall ? "" : classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <Box className={classes.flexDiv}>
                    <MultiSelectWeekdaysInput
                      validate={validate}
                      filter={{ isActive: true }}
                    ></MultiSelectWeekdaysInput>
                  </Box>
                );
              }}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                setParamsData(formData);
                return (
                  <Box className={classes.flexDiv}>
                    <MultiSelectTimeslot
                      validate={validate}
                      filter={{ isActive: true, stream: formData.stream }}
                    ></MultiSelectTimeslot>
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              label="Save"
              onClick={() => saveForm(paramsData)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Cancel"
            onClick={close}
            className={classes.iconCancelColor}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ViewDialogueBox = (props: any) => {
  const [paramsData, setParamsData] = useState({});
  const [isLoad, setLoader] = useState(false);
  const [subjectChange, setSubjectChange] = useState(Boolean);
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { id, listApi, close, open, subjectId, facultyId, formdata } = props;

  const saveFormLecture = (data: any) => {
    if (data.subject === undefined || data.subject === null) {
      toastError("Please select Subject");
    } else if (data.faculty === undefined || data.faculty === null) {
      toastError("Please select Faculty");
    } else {
      setLoader(true);
      const params = {
        // company: data.company ? data.company : "",
        subject: data && data.subject ? data.subject : null,
        faculty: data && data.faculty ? data.faculty : null,
      };
      dataProvider
        .update("assign-timeslot", { data: params, id: id })
        .then((response: any) => {
          setLoader(false);
          listApi(formdata);
          close();
          toastInfo(`Assign Lectures Succesfully`);
          refresh();
        })
        .catch((err: any) => {
          toastError(err.message);
          setTimeout(() => {
            setLoader(false);
          }, 3000);
        });
    }
  };

  const subjectData = (data: any) => {
    setSubjectChange(true);
    setTimeout(function () {
      setSubjectChange(false);
    }, 1000);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-label="Assign Lectures"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle className={classes.pb0}>
          {" "}
          <span className={classes.headTitle}>Assign Lectures</span>
        </DialogTitle>
        <DialogContent>
          <SimpleForm {...props} toolbar={null}>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                return (
                  <Box className={classes.flexDiv}>
                    <SubjectSelectInput
                      validate={validate}
                      filter={{
                        isActive: true,
                        stream: formdata && formdata.stream,
                        course: formdata && formdata.course,
                      }}
                      defaultValue={subjectId}
                      dataManage={subjectData}
                    ></SubjectSelectInput>
                  </Box>
                );
              }}
            </FormDataConsumer>
            <SectionTitle label="" />
            <FormDataConsumer formClassName={classes.name}>
              {({ formData }: { formData: any }) => {
                setParamsData(formData);
                return (
                  <Box className={classes.flexDiv}>
                    <FacultySelectInput
                      validate={validate}
                      filter={{
                        isActive: true,
                        stream: formdata && formdata.stream,
                        course: formdata && formdata.course,
                        subject: formData.subject,
                      }}
                      defaultValue={facultyId}
                      subjectChange={subjectChange}
                    ></FacultySelectInput>
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button
              label="Save"
              onClick={() => saveFormLecture(paramsData)}
              className="my-save-button"
            >
              <CheckCircleIcon />
            </Button>
          )}
          <Button
            label="Cancel"
            onClick={close}
            className={classes.iconCancelColor}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const RemoveDialogueBox = (props: any) => {
  const [isLoad, setLoader] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { close, open, id, listApi, formdata } = props;
  const remove = () => {
    setLoader(true);
    dataProvider
      .delete("assign-timeslot", { id: id })
      .then((response: any) => {
        setLoader(false);
        listApi(formdata);
        close();
        toastInfo(`Assign Lectures deleted Succesfully`);
        refresh();
      })
      .catch((err: any) => {
        setLoader(false);
        console.log(err);
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={close} aria-label="Are you sure?">
        <DialogTitle>Deleting Lecture</DialogTitle>
        <div style={{ margin: window.screen.width < 460 ? 15 : 0 }}>
          <p style={{ marginRight: "25px", marginLeft: "25px" }}>
            Are you sure you want to delete this Lecture?
          </p>
        </div>
        <DialogActions>
          {isLoad ? (
            <LinearProgress />
          ) : (
            <Button type="submit" label="Confirm" onClick={remove}>
              <CheckCircleIcon />
            </Button>
          )}

          <Button
            label="ra.action.cancel"
            onClick={close}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
