import * as React from "react";
import { FC, useState, useCallback } from "react";
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, downloadCSV } from "react-admin";
import helperFunc from "../../helperFuncs";
import DialogueBox from "../DialogueBox";
import { useMediaQuery, Theme, CardHeader, Card } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsonExport from "jsonexport/dist";

interface Props {
  value?: number;
  data?: {};
  data2?: {};
  isLoading?: boolean;
  isTwoCard?: boolean;
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    width: "30%",
    padding: 5,
    marginRight: "1em",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
  },
  col6: {
    flex: "0 0 auto",
    width: "calc(49.3% - 6.2em)",
    padding: 0,
    marginRight: "1em",
  },
  col6Med: {
    flex: "0 0 auto",
    width: "calc(48.9% - 0.2em)",
    padding: 0,
    marginRight: "1em",
  },
  col6Small: {
    flex: "0 0 auto",
    width: "500px",
    padding: 0,
    marginRight: "1em",
  },
  mobileCol6: {
    // flex: 1,
    // width: "30%",
    // padding: 5,
    // marginRight: "1em",
    // height: "265px",
    width: window.screen.width - 28,
    height: "265px",
    padding: "5px",
    marginRight: "1em",
    overflowX: "auto",
  },
  card: {
    // display: "flex",
    // width: "50%",
    // height: "250px",
    // right: 30,
    marginRight: "1em",
    background: "#ffffff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    paddingBottom: "20px",
  },
  // mobileCard: {
  //   // display: "flex",
  //   width: window.screen.width - 28,
  //   // height: "400px",

  //   marginRight: "1em",
  //   background: "#ffffff",
  //   boxShadow:
  //     "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  //   borderRadius: "4px",
  //   paddingBottom: "20px",
  // },
  title: {
    display: "flex",
    flex: 1,
    padding: "20px",
    fontSize: "1.5rem",
  },
  chart: {
    // marginLeft: "3em",
    flex: 1,
    display: "flex",
    float: "left",
    justifyContent: "center",
    // overflow: "inherit",
    // textAlign: "center",
    alignContent: "center",
    marginLeft: "11px",
    textAlign: "start",
  },
  closeButton: { position: "absolute", top: 5, right: 0 },
  theadFCol: { fontSize: 14, color: "#000", fontWeight: 700, minWidth: 50 },
  theadCol: { fontSize: 14, color: "#000", fontWeight: 700 },
  myPopup: { display: "inline-block" },
});

const ChartAttendance: FC<Props> = (data) => {
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlice, setActiveSlice] = useState("");
  const translate = useTranslate();
  const classes = useStyles();
  const dataObj = data.data || {};
  const data2Obj: any = data.data2 || {};
  const loading = data.isLoading;
  const keys = Object.keys(dataObj);
  const values = Object.values(dataObj);
  const dataArr: any = [];
  const attendanceValues: any = {
    present: "Present",
    absent: "Absent",
    attended_holidays: "Attended Holidays",
    holiday: "Holidays",
    employeePresentData: "Employee PresentData",
  };

  const object2: any = {
    Present: "employeePresentData",
    "Attended Holidays": "attended_holidaysData",
    Holidays: "holidaysData",
    Absent: "absentData",
  };

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  keys.forEach((key, index) => {
    if (
      key === "absent" ||
      key === "present" ||
      key === "attended_holidays" ||
      key === "holiday"
    ) {
      dataArr.push({
        name: attendanceValues[key],
        value: values[index],
      });
    }
  });

  const COLORS = ["#1E77B4", "#D63330", "#FFBB28", "#FF8042"];

  const headers = () => {
    if (activeSlice === "Present" || activeSlice === "Attended Holidays") {
      return [
        { label: "Date", key: "date" },
        { label: "Total Hours", key: "spentHours" },
        { label: "In", key: "InTime" },
        { label: "Out", key: "OutTime" },
        { label: "IS Late", key: "IsLate" },
      ];
    } else if (activeSlice === "Holidays") {
      return [
        { label: "Name", key: "name" },
        { label: "Date", key: "dateStr" },
      ];
    } else {
      return [
        { label: "Date", key: "date" },
        { label: "Status", key: "Leave" },
      ];
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = useCallback((data: any, index: any) => {
    setActiveIndex(index);
    setOpen(true);
    setActiveSlice(data.name);
    setPage(0);
  }, []);

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };
  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  let dataBySlice = data2Obj[object2[activeSlice]];
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const Sampleexporter = () => {
    const recordsForExport = dataBySlice.map((record: any) => {
      return headers()?.map((ele: any) => {
        return { [ele.label]: record[ele.key] };
      });
    });

    jsonExport(
      recordsForExport,

      (err: any, csv: any) => {
        downloadCSV(csv, `${activeSlice}csv`); // download as 'xxxx.csv` file
      }
    );
  };

  return (
    <Card
      className={
        isXSmall
          ? classes.mobileCol6
          : isSmall
          ? classes.col6Small
          : data.isTwoCard
          ? classes.col6Med
          : classes.col6
      }
    >
      <DialogueBox
        dialogueData={dataBySlice}
        open={open}
        handleClose={handleClose}
        activeSlice={activeSlice}
        headerArr={headers()}
        page={page}
        handleDefaultPage={handleDefaultPage}
        rowsPerPage={rowsPerPage}
        handleRowsPerPage={handleRowsPerPage}
        exportData={Sampleexporter}
        isExport={true}
      />

      <CardHeader
        title={
          helperFunc.currentMonth() +
          " " +
          translate("pos.dashboard.montly_attendance")
        }
      />
      {loading ? (
        <span
          style={{
            width: "100%",
            height: "125px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </span>
      ) : dataArr && dataArr.length === 0 ? (
        <h4
          style={{
            height: "150px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          No Attendance Found
        </h4>
      ) : (
        <div className={classes.chart}>
          <PieChart
            style={{ bottom: 20, marginLeft: 0 }}
            height={200}
            width={320}
          >
            <Pie
              dataKey="value"
              activeIndex={activeIndex}
              isAnimationActive={false}
              data={dataArr}
              fill="#8884d8"
              label
              outerRadius={60}
              onClick={handleClickOpen}
            >
              {dataArr.map((entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              layout="vertical"
              verticalAlign="top"
              align="left"
              wrapperStyle={{ top: 40, marginLeft: "0px", float: "left" }}
            />
          </PieChart>
        </div>
      )}
    </Card>
  );
};

export default ChartAttendance;
