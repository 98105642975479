import * as React from "react";
import { useState } from "react";
import {
  Typography,
  useMediaQuery,
  Theme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  makeStyles,
  Dialog,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { useDataProvider, downloadCSV } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { chartVideoRegistrationStyle } from "../helperStyle";
import helperFunc from "../helperFuncs";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactTooltip from "react-tooltip";
const DialogueBox = (props: {
  dialogueData: any;
  open: any;
  handleClose: any;
  activeSlice: any;
  headerArr: any;
  page: any;
  handleDefaultPage: any;
  rowsPerPage: number;
  handleRowsPerPage: any;
  exportData: any;
  isExport: boolean;
}) => {
  const {
    handleClose,
    dialogueData,
    activeSlice,
    headerArr,
    page,
    open,
    handleDefaultPage,
    rowsPerPage,
    handleRowsPerPage,
    exportData,
    isExport,
  } = props;
  const useStyles = makeStyles(chartVideoRegistrationStyle);
  const dataProvider = useDataProvider();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const classes = useStyles();

  const toolTip = (id: any) => {
    return (
      <ReactTooltip
        effect="solid"
        place="bottom"
        id={id}
        type="dark"
        className={classes.toolTipWidth}
        textColor="black"
        backgroundColor="#E6E6E6"
      />
    );
  };

  const approvalStatusLeave: any = {
    reject: "Rejected",
    approve: "Approved",
    pending: "Pending",
    cancel: "Cancel",
  };
  const statusType: any = {
    early_check_out: "Early Check Out",
    late_check_in: "Late Check In",
    missed_checkin: "Missed Check In",
    missed_checkout: "Missed Check Out",
    in_between_check_out: "In Between Check Out",
    other: "Other",
    over_time: "Over Time",
    procurement: "Procurement",
    attendancechangerequest: "Attendance Change Request",
  };
  const isLateIconsData: any = {
    True: CloseIcon,
    False: CheckIcon,
  };
  const handleChangePage = (event: any, newPage: any) => {
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        className={classes.myPopup}
        aria-labelledby="simple-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6">
            {activeSlice}{" "}
            {isExport && (
              <Button
                className={classes.exportButton}
                color="primary"
                component="span"
                onClick={exportData}
              >
                <GetAppIcon className={classes.exportIcon} />
                {/* {!isSmall ? (
                <span className={classes.csvSpan}>Sample CSV</span>
              ) : null} */}
              </Button>
            )}
          </Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Paper>
          <TableContainer>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  {activeSlice && headerArr && headerArr.length > 0
                    ? headerArr.map((item: any) => {
                        return (
                          <TableCell className={classes.theadFCol}>
                            {item.label}
                          </TableCell>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogueData && dialogueData?.length > 0 ? (
                  dialogueData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data: any, index: number) => {
                      return (
                        <TableRow key={index + 1}>
                          {headerArr?.map((item: any) => {
                            let array = [];
                            if (item?.key?.indexOf(".") > -1) {
                              array = item?.key?.split(".");
                              return (
                                <TableCell>
                                  {data &&
                                  data[array[0]] &&
                                  data[array[0]][array[1]] !== undefined
                                    ? data[array[0]][array[1]]
                                    : item.key}
                                </TableCell>
                              );
                            }
                            data["Time"] = helperFunc.commonDateFormat(
                              data["time"],
                              "HH:mm:ss"
                            );
                            data["CreatedAt"] = helperFunc.commonDateFormat(
                              data["createdAt"],
                              "HH:mm:ss"
                            );
                            data["UpdatedAt"] = data["UpdatedAt"]
                              ? helperFunc.commonDateFormat(
                                  data["updatedAt"],
                                  "HH:mm:ss"
                                )
                              : "N/A";
                            data["InTime"] = data["InTime"]
                              ? helperFunc.commonDateFormat(
                                  data["inTime"],
                                  "HH:mm:ss"
                                )
                              : "N/A";
                            data["OutTime"] = data["outTime"]
                              ? helperFunc.commonDateFormat(
                                  data["outTime"],
                                  "HH:mm:ss"
                                )
                              : "N/A";

                            const isLateData = (item: any) => {
                              if (item === "true") {
                                return "Yes";
                              } else {
                                return "No";
                              }
                            };
                            data["IsLate"] = isLateData(data["isLate"]);

                            data["ApprovalStatus"] = data["isApprove"]
                              ? approvalStatusLeave[data["isApprove"]]
                              : (data["ApprovalStatus"] = data["requestStatus"]
                                  ? approvalStatusLeave[data["requestStatus"]]
                                  : "Pending");

                            data["reason"] = data["reason"]
                              ? data["reason"]
                              : "N/A";
                            data["RequestType"] = data["requestType"]
                              ? statusType[data["requestType"]]
                              : "N/A";

                            return (
                              <TableCell
                                data-tip={
                                  data && data[item.key] !== undefined
                                    ? data[item.key].toString()
                                    : item?.key
                                    ? item.key.toString()
                                    : "N/A"
                                }
                                data-for={
                                  data && data[item.key] !== undefined
                                    ? data[item.key].toString()
                                    : item?.key
                                    ? item.key.toString()
                                    : "N/A"
                                }
                                align="left"
                              >
                                <div className={classes.textMerge}>
                                  {data && data[item.key] !== undefined
                                    ? data[item.key]
                                    : item?.key
                                    ? item.key
                                    : "N/A"}
                                </div>

                                {toolTip(
                                  data && data[item.key] !== undefined
                                    ? data[item.key].toString()
                                    : item?.key
                                    ? item.key.toString()
                                    : "N/A"
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={headerArr && headerArr.length}
                      style={{
                        textAlign: "center",
                        // marginRight: isXSmall ? undefined : "350px",
                      }}
                    >
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={(dialogueData && dialogueData.length) || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    </div>
  );
};

export default DialogueBox;
